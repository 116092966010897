import React from "react";
import styles from "../components/EmailAttachedFile.module.css";
import { renderFilePictureUploadLink } from "../../../helpers/cases";
import classNames from "classnames";

function EmailAttachedFile({ type, name, fileUrl, fileImage, ext, size }) {
  const classes = classNames(
    styles.emailAttachedFile,
    type === "image" && styles.images
  );

  return (
    <a
      className={classes}
      href={fileUrl}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className={styles.fileInfo}>
        <span className={styles.preview}>
          {type === "image" && <img src={fileImage} alt={name} />}
          {type !== "image" && (
            <img src={renderFilePictureUploadLink(ext)} alt={name} />
          )}
        </span>

        <div className={styles.info}>
          <span className={styles.name}>
            <span className={styles.nameBlock}>{name.split(".", 1)}</span>.
            <span className={styles.extBlock}>{ext}</span>
          </span>
          {size > 1024000 && (
            <span className={styles.size}>
              {Math.round(size / 1024 / 1024)} mb
            </span>
          )}
          {size < 1024000 && (
            <span className={styles.size}>{Math.round(size / 1024)} kb</span>
          )}
        </div>
      </div>
    </a>
  );
}

export default EmailAttachedFile;
