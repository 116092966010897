import React from "react";
import styles from "./SectionTitle.module.css";

function SectionTitle({ title, desc }) {
  return (
    <div className={styles.section}>
      <div className={styles.title}>{title}</div>
      {desc && <div className={styles.desc}>{desc}</div>}
    </div>
  );
}

export default SectionTitle;
