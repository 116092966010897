import React, { useCallback } from "react";
import {Textarea} from "design-system-react";

function TextareaField({ form, field, ...props }) {
  const handleClear = useCallback(() => {
    form.setFieldValue(field.name, "");
  }, [form, field.name]);

  const error = form.errors[field.name];

  return (
    <Textarea
      validation={error && "error"}
      message={error}
      onClearClick={handleClear}
      {...field}
      {...props}
    />
  );
}

export default TextareaField;
