import React, { useEffect, useCallback } from "react";
import { FastField } from "formik";
import LanguageDropdown from "components/LanguageDropdown";
import { GutterField, Row, Col } from "design-system-react";
import { connect } from "react-redux";
import InputField from "components/InputField";
import TextareaField from "components/TextareaField";
import DefaultLanguage from "components/DefaultLanguage";
import { translation_default } from "helpers/translations";
import { api } from "utils/widgetClient";
import { setSetup, setWelcome } from "modules/widgetSetup";
import useFormSubmission from "hooks/useFormSubmission";

function printLanguage(lang) {
  switch (lang) {
    case "ru":
      return "Russian";
    case "en":
      return "English";
    case "kz":
      return "Kazakh";
    default:
      return "English";
  }
}

function WidgetWelcomeFormContainer({
  values,
  dirty,
  submitDisabled,
  setSubmitDisabled,
  handleSubmit,
  setWelcome,
  setSetup,
  setup
}) {
  const handleSubmitForm = useFormSubmission(submitDisabled, handleSubmit);
  const isAdditional =
    setup.introduce.languages && setup.introduce.languages.length > 0;
  const defaultLanguage = setup.introduce.defaultLanguage || "en";

  useEffect(() => {
    setWelcome(values);
  }, [setWelcome, values]);

  if (!dirty && !submitDisabled) {
    setSubmitDisabled(true);
  }

  if (dirty && submitDisabled) {
    setSubmitDisabled(false);
  }

  const handleUpdateTranslations = useCallback(
    ({ toggle }) => {
      api.widget
        .updateTranslations(setup.id, values.translations)
        .ready.then(response => {
          toggle();
          setSetup(response.data.data);
        });
    },
    [values, setup.id, setSetup]
  );

  return (
    <form onSubmit={handleSubmitForm}>
      <GutterField>
        <Row>
          <Col gutterRight={isAdditional && 70}>
            <DefaultLanguage>
              <FastField
                name="helloMessage"
                label="Say hello"
                placeholder={translation_default.helloMessage[defaultLanguage]}
                component={InputField}
              />
            </DefaultLanguage>
          </Col>
          {isAdditional && (
            <LanguageDropdown
              handleUpdate={handleUpdateTranslations}
              defaultLanguage={defaultLanguage}
            >
              {setup.introduce.languages.map(field => {
                return (
                  <GutterField key={field}>
                    <FastField
                      name={`translations.helloMessage.${[field]}`}
                      label={printLanguage(field)}
                      placeholder={translation_default.helloMessage[field]}
                      component={InputField}
                    />
                  </GutterField>
                );
              })}
            </LanguageDropdown>
          )}
        </Row>
      </GutterField>
      <GutterField>
        <Row>
          <Col gutterRight={isAdditional && 70}>
            <DefaultLanguage>
              <FastField
                name="teamMessage"
                label="Team online message"
                placeholder={translation_default.teamMessage[defaultLanguage]}
                component={TextareaField}
              />
            </DefaultLanguage>
          </Col>
          {isAdditional && (
            <LanguageDropdown
              handleUpdate={handleUpdateTranslations}
              defaultLanguage={defaultLanguage}
            >
              {setup.introduce.languages.map(field => {
                return (
                  <GutterField key={field}>
                    <FastField
                      name={`translations.teamMessage.${[field]}`}
                      label={printLanguage(field)}
                      placeholder={translation_default.teamMessage[field]}
                      component={TextareaField}
                    />
                  </GutterField>
                );
              })}
            </LanguageDropdown>
          )}
        </Row>
      </GutterField>
      <GutterField>
        <Row>
          <Col gutterRight={isAdditional && 70}>
            <DefaultLanguage>
              <FastField
                name="offlineMessage"
                label="Team offline message"
                placeholder={
                  translation_default.offlineMessage[defaultLanguage]
                }
                component={TextareaField}
              />
            </DefaultLanguage>
          </Col>
          {isAdditional && (
            <LanguageDropdown
              handleUpdate={handleUpdateTranslations}
              defaultLanguage={defaultLanguage}
            >
              {setup.introduce.languages.map(field => {
                return (
                  <GutterField key={field}>
                    <FastField
                      name={`translations.offlineMessage.${[field]}`}
                      label={printLanguage(field)}
                      placeholder={translation_default.offlineMessage[field]}
                      component={TextareaField}
                    />
                  </GutterField>
                );
              })}
            </LanguageDropdown>
          )}
        </Row>
      </GutterField>
    </form>
  );
}

const mapStateToProps = state => {
  return {
    setup: state.widgetSetup.current
  };
};

const mapDispatchToProps = dispatch => ({
  setSetup: setup => dispatch(setSetup(setup)),
  setWelcome: welcome => dispatch(setWelcome(welcome))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WidgetWelcomeFormContainer);
