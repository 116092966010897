import { useEffect } from "react";
import {
  WS_EVENT_CASE_CREATED,
  WS_EVENT_CASE_UPDATED,
  WS_EVENT_MESSAGE_CREATED
} from "../utils/websocket";
import { subscribe, unsubscribe } from "utils/websocket";
import { CASE_NEW } from "../helpers/cases";
import history from "../utils/history";
import {
  currentUserIdSelector,
  newCaseNotificationSoundSelector,
  newMessageNotificationSoundSelector
} from "selectors/teamMember";
import { useSelector } from "react-redux";

let notification = null;
const TAG = "app_notification";

export default function useNotifications() {
  const userId = useSelector(currentUserIdSelector);

  const newCaseNotificationSound = useSelector(
    newCaseNotificationSoundSelector
  );

  const newMessageNotificationSound = useSelector(
    newMessageNotificationSoundSelector
  );

  useEffect(() => {
    function notify(tag, body, url, audioUrl) {
      updateTitle();

      if (audioUrl) {
        const audio = new Audio(audioUrl);
        audio.play();
      }

      notification = new Notification("Hugery", {
        body,
        tag
      });

      notification.addEventListener("click", e => {
        window.focus();
        history.push(url);
      });
    }

    function handleCase(data) {
      if (data.status === CASE_NEW) {
        notify(
          TAG,
          "New case from " + data.endUser.name,
          "/cases/unassigned/" + data.id,
          newCaseNotificationSound && "/sounds/new-case.mp3"
        );
      }
    }

    function handleMessage(data) {
      const newMessageAtCase =
        data.endUser &&
        data.cases.assignedTeamMember &&
        data.cases.assignedTeamMember.id === userId;

      if (newMessageAtCase) {
        notify(
          TAG,
          "New message from " + data.endUser.name,
          "/cases/assigned_to_me/" + data.cases.id,
          newMessageNotificationSound && "/sounds/new-message.mp3"
        );
      }

      const caseAssignedToTeamMember =
        data.bot &&
        data.casesEvents.length > 0 &&
        data.casesEvents[0].targetTeamMember &&
        data.casesEvents[0].targetTeamMember.id === userId &&
        data.casesEvents[0].initiatorTeamMember &&
        data.casesEvents[0].initiatorTeamMember.id !== userId;

      if (caseAssignedToTeamMember) {
        notify(
          TAG,
          "New case from " + data.cases.endUser.name,
          "/cases/assigned_to_me/" + data.cases.id,
          newCaseNotificationSound && "/sounds/new-case.mp3"
        );
      }
    }

    function handleWindowFocus() {
      updateTitle(true);

      if (notification) {
        notification.close();
      }
    }

    function updateTitle(clear = false) {
      if (clear) {
        document.title = document.title.replace(/^\(+([0-9]+)\)/gi, "");
        return;
      }
      let counter = 1;
      let match = document.title.match(/^\(+([0-9]+)\)/i);
      if (match) {
        counter = Number(match[1]) + 1;
      }
      if (!document.hasFocus()) {
        let title = document.title.replace(/^\(+([0-9]+)\)/gi, "");
        document.title = "(" + counter + ") " + title;
      }
    }

    if (!("Notification" in window)) {
      console.log("This browser does not support notifications.");
    } else {
      Notification.requestPermission();
    }

    subscribe(WS_EVENT_CASE_CREATED, handleCase);
    subscribe(WS_EVENT_CASE_UPDATED, handleCase);
    subscribe(WS_EVENT_MESSAGE_CREATED, handleMessage);
    window.addEventListener("focus", handleWindowFocus);

    return () => {
      unsubscribe(WS_EVENT_CASE_CREATED, handleCase);
      unsubscribe(WS_EVENT_CASE_UPDATED, handleCase);
      unsubscribe(WS_EVENT_MESSAGE_CREATED, handleMessage);
      window.removeEventListener("focus", handleWindowFocus);
    };
  }, [newCaseNotificationSound, newMessageNotificationSound, userId]);

  return {};
}
