import { useRef, useState, useEffect } from "react";

export default function useRenderDelay(bool, timeIn, timeOut) {
  const [isRender, setRender] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const timer = useRef(null);

  useEffect(() => {
    if (bool) {
      setRender(true);
      timer.current = setTimeout(() => setOpen(true), timeIn);
    } else {
      setOpen(false);
      timer.current = setTimeout(() => setRender(false), timeOut);
    }

    return () => {
      clearTimeout(timer.current);
    };
  }, [bool, timeIn, timeOut]);

  return { isRender, isOpen };
}
