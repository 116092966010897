import React, { useMemo } from "react";

function View({ children, ...props }) {
  const styles = useMemo(() => {
    return {
      ...props
    };

    // eslint-disable-next-line
  }, [...Object.values(props)]);

  return <div style={styles}>{children}</div>;
}

export default View;
