import React from "react";
import { Row, Col } from "design-system-react";
import MessageListContainer from "pages/Cases/containers/MessageListContainer";
import DetailsContainer from "pages/Cases/containers/DetailsContainer";
import { CASE_NEW } from "../../../helpers/cases";
import CaseLayout from "./CaseLayout";
import DropFileContainer from "../containers/DropFileContainer";
import MessageListReplyingMessage from "./MessageListReplyingMessage";
import MessageFormContainer from "../containers/MessageFormContainer";
import CaseNavPanelContainer from "../containers/CaseNavPanelContainer";

function Case({
  caseId,
  caseLoading,
  activeMessageId,
  assignLoading,
  status,
  channelType,
  showMessageForm,
  isOpenSidePanel,
  toggleSidePanel,
  hideDetails,
  bulkActions,
  onAssign,
  onUnassign,
  onClose,
  onClosePanel
}) {
  return (
    <Row column fullHeight>
      <Col auto>
        <CaseNavPanelContainer
          caseLoading={caseLoading}
          caseId={caseId}
          assignLoading={assignLoading}
          toggleDetailsSidePanel={toggleSidePanel}
          bulkActions={bulkActions}
          onAssign={onAssign}
          onUnassign={onUnassign}
          onClose={onClose}
          onClosePanel={onClosePanel}
        />
      </Col>
      <Col justified>
        <CaseLayout
          renderMessages={
            <DropFileContainer>
              <Row fullHeight column>
                <Col justified>
                  <MessageListContainer
                    caseId={caseId}
                    activeMessageId={activeMessageId}
                    channelType={channelType}
                  />
                </Col>
                {status === CASE_NEW && (
                  <Col auto>
                    <MessageListReplyingMessage />
                  </Col>
                )}
                {showMessageForm && (
                  <Col auto>
                    <MessageFormContainer
                      caseId={caseId}
                      channelType={channelType}
                    />
                  </Col>
                )}
              </Row>
            </DropFileContainer>
          }
          renderDetails={
            hideDetails ? null : (
              <DetailsContainer
                caseId={caseId}
                caseLoading={caseLoading}
                isOpenSidePanel={isOpenSidePanel}
                toggleSidePanel={toggleSidePanel}
              />
            )
          }
        />
      </Col>
    </Row>
  );
}

export default Case;
