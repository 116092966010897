import React, { useState, useCallback } from "react";
import TeamsCreateModalContainer from "./TeamsCreateModalContainer";
import TeamsNavPanelContainer from "./TeamsNavPanelContainer";
import { Row, Col } from "design-system-react";
import TeamsListContainer from "./TeamsListContainer";

function TeamsContainer({
  locationKey,
  view,
  activeMenuItem,
  isSubmenuOpened,
  onSubMenuToggle
}) {
  const [isCreateModalOpen, setCreateModalOpen] = useState(false);

  const toggleCreateModal = useCallback(() => {
    setCreateModalOpen(!isCreateModalOpen);
  }, [isCreateModalOpen]);

  return (
    <Row fullHeight column>
      <Col auto>
        <TeamsNavPanelContainer
          onSubMenuToggle={onSubMenuToggle}
          isSubmenuOpened={isSubmenuOpened}
          toggleCreateModal={toggleCreateModal}
          activeView={view}
          activeMenuItem={activeMenuItem}
        />
      </Col>
      <Col justified>
        <TeamsListContainer locationKey={locationKey} view={view} />
      </Col>
      <TeamsCreateModalContainer
        view={view}
        isOpen={isCreateModalOpen}
        toggle={toggleCreateModal}
      />
    </Row>
  );
}

export default TeamsContainer;
