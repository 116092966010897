import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import ToggableLayout from "components/ToggableLayout";
import SubMenuContainer from "pages/Cases/containers/SubMenuContainer";
import CasesLayout from "pages/Cases/components/CasesLayout";
import CaseContainer from "pages/Cases/containers/CaseContainer";
import { addMessage, UNASSIGNED_VIEW_NAME } from "modules/pages/cases";
import history from "utils/history";
import { message } from "schemes";
import {
  subscribe,
  unsubscribe,
  WS_EVENT_MESSAGE_CONTENT_UPDATED,
  WS_EVENT_MESSAGE_CREATED,
  WS_EVENT_VIEW_UPDATED
} from "utils/websocket";
import {
  addCaseId,
  addCaseIds,
  addMessageId,
  removeCaseId,
  setCaseIds
} from "../../modules/pages/newcases";
import {
  updateEntityAttributes,
  updateEntityRelations
} from "../../modules/data";
import { dispatchUpdatedDataToStore } from "../../utils/client";
import { messageContent, view } from "../../schemes";
import CasesContainer from "./containers/CasesContainer";

function Cases({ match, addMessageId }) {
  const [filter, setFilter] = useState({ filters: [] });
  const [selectedCases, setSelectedCases] = useState([]);
  const [bulkActions, setBulkActions] = useState(false);

  const activeCaseId = match.params.case;
  const activeViewName = match.params.view || UNASSIGNED_VIEW_NAME;
  const activeMessageId = new URLSearchParams(window.location.search).get(
    "messageId"
  );

  useEffect(() => {
    setFilter({ filters: [] });
    setSelectedCases([]);
    setBulkActions(false);
  }, [activeViewName]);

  useEffect(() => {
    document.title = "Cases";
  }, []);

  useEffect(() => {
    subscribe(WS_EVENT_MESSAGE_CREATED, handleWebsocketMessage);
    subscribe(WS_EVENT_VIEW_UPDATED, handleWebsocketViewUpdated);
    subscribe(
      WS_EVENT_MESSAGE_CONTENT_UPDATED,
      handleWebsocketMessageContentUpdate
    );

    return () => {
      unsubscribe(WS_EVENT_MESSAGE_CREATED, handleWebsocketMessage);
      unsubscribe(WS_EVENT_VIEW_UPDATED, handleWebsocketViewUpdated);
      unsubscribe(
        WS_EVENT_MESSAGE_CONTENT_UPDATED,
        handleWebsocketMessageContentUpdate
      );
    };
  });

  const handleWebsocketViewUpdated = useCallback(data => {
    dispatchUpdatedDataToStore(data, [view]);
  }, []);

  const handleWebsocketMessage = useCallback(
    data => {
      dispatchUpdatedDataToStore(data, message);

      if (activeCaseId === data.cases.id) {
        addMessageId(data.id);
      }
    },
    [activeCaseId, addMessageId]
  );

  const handleWebsocketMessageContentUpdate = useCallback(data => {
    dispatchUpdatedDataToStore(data, messageContent);
  }, []);

  const handleClosePanel = useCallback(() => {
    history.push({
      pathname: "/cases/" + activeViewName,
      state: {
        preventCaseReloading: true
      }
    });
  }, [activeViewName]);

  const renderSubmenu = useCallback(() => {
    return (
      <SubMenuContainer
        activeViewName={activeViewName}
        activeCaseId={activeCaseId}
      />
    );
  }, [activeViewName, activeCaseId]);

  const renderContent = useCallback(
    container => {
      return (
        <CasesLayout
          isActiveCase={activeCaseId}
          renderCases={
            <CasesContainer
              activeCaseId={activeCaseId}
              activeViewName={activeViewName}
              toggleSubmenu={container.toggleSubmenu}
              isSubmenuOpened={container.isSubmenuOpened}
              filter={filter}
              onFilter={filter => {
                setFilter(filter);
              }}
              selectedCases={selectedCases}
              onSelect={ids => {
                setSelectedCases(ids);
              }}
              bulkActions={bulkActions}
              onBulkActionsToggle={enabled => {
                setBulkActions(enabled);

                if (!enabled) {
                  setSelectedCases([]);
                }
              }}
            />
          }
          renderCase={
            <CaseContainer
              caseId={activeCaseId}
              activeViewName={activeViewName}
              activeMessageId={activeMessageId}
              onCollapseButtonClick={container.toggleSubmenu}
              onClosePanel={handleClosePanel}
              bulkActions={bulkActions}
              filter={filter}
            />
          }
        />
      );
    },
    [
      activeViewName,
      activeCaseId,
      activeMessageId,
      filter,
      selectedCases,
      bulkActions,
      handleClosePanel
    ]
  );

  return (
    <ToggableLayout
      renderSubmenu={renderSubmenu}
      renderContent={renderContent}
    />
  );
}

const mapDispatchToProps = dispatch => ({
  setCaseIds: ids => dispatch(setCaseIds(ids)),
  addCaseIds: ids => dispatch(addCaseIds(ids)),
  addCaseId: id => dispatch(addCaseId(id)),
  removeCaseId: id => dispatch(removeCaseId(id)),
  addMessage: (caseId, message) => dispatch(addMessage(caseId, message)),
  addMessageId: id => dispatch(addMessageId(id)),
  updateEntityAttributes: (entityType, entityId, attributes) =>
    dispatch(updateEntityAttributes(entityType, entityId, attributes)),
  updateEntityRelations: (
    entityType,
    entityId,
    relationshipName,
    relationshipType,
    relationshipEntityId
  ) =>
    dispatch(
      updateEntityRelations(
        entityType,
        entityId,
        relationshipName,
        relationshipType,
        relationshipEntityId
      )
    )
});

export default connect(
  null,
  mapDispatchToProps
)(Cases);
