import { connect } from "react-redux";
import { CASE_CLOSED } from "modules/pages/cases";
import styles from "pages/Cases/components/CaseNavPanel.module.css";
import NavPanel from "components/NavPanel";
import NavPanelContent from "components/NavPanel/NavPanelContent";
import NavPanelContentItem from "components/NavPanel/NavPanelContentItem";
import { Button, Tooltip } from "design-system-react";
import AssignDropdownContainer from "../../../containers/AssignDropdownContainer";
import DetailsButtonWrapper from "../../../components/DetailsButtonWrapper/DetailsButtonWrapper";
import ProfileButton from "../../../components/ProfileButton";
import NavPanelEndUserContainer from "./NavPanelEndUserContainer";

function CaseNavPanelContainer({
  caseId,
  caseLoading,
  endUserId,
  assignLoading,
  showCloseButton,
  showAssignDropdown,
  toggleDetailsSidePanel,
  bulkActions,
  onAssign,
  onUnassign,
  onClose,
  onClosePanel
}) {
  return (
    <NavPanel borderBottom>
      <NavPanelContent>
        {!caseLoading && (
          <NavPanelContentItem>
            <NavPanelEndUserContainer endUserId={endUserId} />
          </NavPanelContentItem>
        )}
      </NavPanelContent>
      <NavPanelContent>
        {!caseLoading && (
          <>
            <NavPanelContentItem>
              <div style={{ marginRight: "12px" }}>
                <DetailsButtonWrapper>
                  <ProfileButton profileButtonAction={toggleDetailsSidePanel} />
                </DetailsButtonWrapper>
              </div>
            </NavPanelContentItem>

            {showAssignDropdown && !bulkActions && (
              <NavPanelContentItem>
                <div style={{ width: "220px", position: "relative" }}>
                  <AssignDropdownContainer
                    caseId={caseId}
                    assignLoading={assignLoading}
                    onAssign={onAssign}
                    onUnassign={onUnassign}
                  />
                </div>
              </NavPanelContentItem>
            )}

            {showCloseButton && !bulkActions && (
              <NavPanelContentItem>
                <div style={{ marginLeft: "12px" }}>
                  <Button id="close_case" outline onClick={onClose}>
                    Close
                    <Tooltip size="sm" position="bottom">
                      Mark Case as Closed
                    </Tooltip>
                  </Button>
                </div>
              </NavPanelContentItem>
            )}
          </>
        )}
        <NavPanelContentItem>
          <div
            id="close_conversation"
            className={styles.close}
            onClick={onClosePanel}
          >
            <i className="ico-close" />
            <Tooltip size="sm" position="bottom" offsetX={-40}>
              Close conversation tab
            </Tooltip>
          </div>
        </NavPanelContentItem>
      </NavPanelContent>
    </NavPanel>
  );
}

const mapStateToProps = (state, props) => {
  const caseItem = state.entities.cases && state.entities.cases[props.caseId];

  let showCloseButton = false;
  let showAssignDropdown = false;

  if (
    caseItem &&
    caseItem.status !== CASE_CLOSED &&
    (caseItem.assignedTeamMember === state.auth.userId ||
      !caseItem.assignedTeamMember)
  ) {
    showCloseButton = true;
  }

  if (caseItem && caseItem.status !== CASE_CLOSED) {
    showAssignDropdown = true;
  }

  return {
    showCloseButton,
    showAssignDropdown,
    endUserId: caseItem && caseItem.endUser
  };
};

export default connect(mapStateToProps)(CaseNavPanelContainer);
