import React, { useCallback } from "react";
import {
  PopupHeader,
  Popup,
  PopupBody,
  PopupFooter,
  Button,
  GutterField
} from "design-system-react";
import { useDispatch } from "react-redux";
import { api } from "utils/client";
import { FormError } from "utils/formError";
import { Field, Formik } from "formik";
import InputField from "components/InputField";
import { addSalesCardSetupId } from "modules/pages/settingsSalesCardSetups";
import TextareaField from "components/TextareaField";
import UploadInputField from "components/UploadInputField";
import View from "components/View";

function AddFormContainer({ toggle, isOpen, close }) {
  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    (values, { setSubmitting, setErrors }) => {
      api.salesCardSetup
        .create(values)
        .ready.then(response => {
          dispatch(addSalesCardSetupId(response.data.data.id));
          close();
        })
        .catch(e => {
          if (e instanceof FormError) {
            setErrors(e.errors);
          }
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    // eslint-disable-next-line
    [addSalesCardSetupId, close]
  );

  return (
    <Formik
      enableReinitialize
      initialValues={{
        whatsappTemplateMessage:
          "Send this message to start conversation: {{TEAM_MEMBER_PIN}}"
      }}
      onSubmit={handleSubmit}
      render={props => (
        <Popup isOpen={isOpen} onClose={toggle}>
          <PopupHeader>Add business card settings</PopupHeader>
          <PopupBody>
            <GutterField>
              <Field
                name="title"
                type="text"
                label="Settings title (e.g. My settings)"
                component={InputField}
              />
            </GutterField>
            <GutterField>
              <Field
                name="companyName"
                type="text"
                label="Company name"
                component={InputField}
              />
            </GutterField>
            <GutterField>
              <Field
                name="logo"
                label="Company logo"
                component={UploadInputField}
              />
            </GutterField>
            <GutterField>
              <Field
                name="greetingTitle"
                type="text"
                label="Greeting"
                component={InputField}
              />
            </GutterField>
            <Field
              name="greetingMessage"
              type="text"
              label="Message"
              component={TextareaField}
            />
            <View
              fontSize="12px"
              color="#aaa"
              paddingTop={6}
              paddingBottom={12}
            >
              Placeholders: {"{{TEAM_MEMBER_NAME}}"}
            </View>
            <Field
              name="whatsappTemplateMessage"
              type="text"
              label="WhatsApp Template Message"
              component={TextareaField}
            />
            <View fontSize="12px" color="#aaa" paddingTop={6}>
              Placeholders: {"{{TEAM_MEMBER_PIN}}"}, {"{{TEAM_MEMBER_NAME}}"}
            </View>
          </PopupBody>
          <PopupFooter>
            <Button
              onClick={props.submitForm}
              processing={props.isSubmitting}
              // disabled={submitDisabled}
            >
              Add
            </Button>
            <Button outline={true} onClick={toggle}>
              Cancel
            </Button>
          </PopupFooter>
        </Popup>
      )}
    />
  );
}

export default AddFormContainer;
