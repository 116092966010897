import React from "react";
import { Avatar } from "design-system-react";
import styles from "./TeamUsersListContact.module.css";
import { extractFirstLetters } from "utils/string";
import classNames from "classnames";
import PropTypes from "prop-types";
import { TEAM_MEMBER_STATUS_PENDING } from "../../../helpers/teamMembers";

function TeamUsersListContact({
  avatar,
  color,
  name,
  email,
  confirmed,
  status
}) {
  const avatarClass = classNames(styles.avatar);
  return (
    <div className={styles.content}>
      <div className={avatarClass}>
        <Avatar
          size="size48"
          text={extractFirstLetters(name, 2)}
          color={color}
          image={avatar}
          pending={status === TEAM_MEMBER_STATUS_PENDING}
        />
      </div>
      <div className={confirmed && styles.info}>
        {status === "enabled" && <div className={styles.name}>{name}</div>}
        <span className={styles.email}>{email}</span>
      </div>
    </div>
  );
}

TeamUsersListContact.propTypes = {
  avatar: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
  confirmed: PropTypes.bool,
  status: PropTypes.string
};

TeamUsersListContact.defaultTypes = {
  confirmed: false
};

export default TeamUsersListContact;
