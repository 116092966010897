import React from "react";
import styles from "./DropFileWrapper.module.css";
import PropTypes from "prop-types";

function DropFileWrapper({ children, dropZoneProps }) {
  return (
    <div className={styles.wrapper} {...dropZoneProps}>
      {children}
    </div>
  );
}

DropFileWrapper.propTypes = {
  dropZoneProps: PropTypes.object.isRequired
};

export default DropFileWrapper;
