import React from "react";
import styles from "./EmptyState.module.css";
import PropTypes from "prop-types";

function EmptyState({ title, children }) {
  return (
    <div className={styles.container}>
      <span className={styles.title}>{title}</span>
      <span className={styles.desc}>{children}</span>
    </div>
  );
}

EmptyState.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string
};

export default EmptyState;
