import { withNamespace } from "utils/namespace";

const type = withNamespace("teamMemberExtraFields");

// action types
export const SET_EXTRA_FIELDS = type("SET_EXTRA_FIELDS");

let initialState = {
  ids: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_EXTRA_FIELDS:
      return {
        ...state,
        ids: action.payload.ids
      };
    default:
      return state;
  }
}

// actions
export function setTeamMemberExtraFieldIds(ids) {
  return {
    type: SET_EXTRA_FIELDS,
    payload: {
      ids
    }
  };
}
