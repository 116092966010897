import React from "react";
import { Avatar, Preloader } from "design-system-react";
import { extractFirstLetters } from "utils/string";
import classNames from "classnames";
import styles from "pages/Cases/components/MessageListEmailItem.module.css";
import { connect } from "react-redux";
import EmailAttachedFile from "../components/EmailAttachedFile";

function OutgoingEmailMessageListItemContainer({
  message,
  endUser,
  currentUserAvatar,
  currentUserName,
  currentUserNameColor,
  caseItem,
  attachmentCount
}) {
  const classes = classNames(styles.messageListEmailItem, styles.outgoing);

  const textRows = message.content.split("\n") || [];

  return (
    <div className={classNames(styles.messageListEmail)}>
      <div className={classes}>
        <div className={styles.emailHeader}>
          <div className={styles.sendIcon}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="8"
              viewBox="0 0 8 8"
            >
              <path
                id="Rounded_Rectangle_999"
                data-name="Rounded Rectangle 999"
                className="cls-1"
                fill="#7d7e80"
                d="M451.829,746.829a0.494,0.494,0,0,1-.7,0L445,740.7v5.8a0.5,0.5,0,0,1-1,0v-7h0a0.49,0.49,0,0,1,.125-0.321h0a0.32,0.32,0,0,1,.016-0.039c0.01-.01.027-0.006,0.038-0.015h0A0.49,0.49,0,0,1,444.5,739h7a0.5,0.5,0,0,1,0,1h-5.8l6.13,6.13A0.494,0.494,0,0,1,451.829,746.829Z"
                transform="translate(-444 -739)"
              />
            </svg>
          </div>

          <div className={styles.infoWrapper}>
            <div className={styles.avatarBlock}>
              {(currentUserAvatar || currentUserName) && (
                <Avatar
                  size="size40"
                  color={currentUserNameColor}
                  image={currentUserAvatar}
                  text={extractFirstLetters(currentUserName, 2)}
                />
              )}
            </div>

            <div className={styles.infoBlock}>
              {caseItem.defaultSubject !== null && (
                <div className={styles.subject}>
                  {"Re: " + caseItem.defaultSubject}
                </div>
              )}

              {(currentUserAvatar || currentUserName) && (
                <div className={styles.senderInfo}>
                  {currentUserName} replied to
                  {endUser.channelFields && (
                    <a
                      href={"mailto:" + endUser.channelFields.email}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {"<" + endUser.channelFields.email + ">"}
                    </a>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className={styles.date}>
            <Preloader size="12" color="#807d7f" />
          </div>
        </div>
        {textRows.map((text, index) => (
          <>
            {text}
            {textRows.length - 1 !== index && <br />}
          </>
        ))}

        <div className={styles.emailFooter}>
          {attachmentCount > 0 && (
            <>
              <div className={styles.attachmentText}>
                <img src="/images/files/file.svg" alt="" />
                <>
                  {attachmentCount}
                  {attachmentCount <= 2 && " Attachment"}
                  {attachmentCount > 2 && " Attachments"}
                </>
              </div>

              <div className={styles.attachments}>
                {message.files.map(file => {
                  return (
                    <EmailAttachedFile
                      type={file.type}
                      name={file.name}
                      ext={file.ext.match(/[0-9a-z]+$/).toString()}
                      size={file.size}
                    />
                  );
                })}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state, props) => {
  const endUserId = state.entities.cases[props.message.caseId].endUser;
  const endUser = state.entities.endUsers[endUserId];
  const caseItem = state.entities.cases[props.message.caseId];
  const currentUser = state.entities.teamMembers[state.auth.userId];

  return {
    endUser,
    currentUserAvatar: currentUser.avatar,
    currentUserName: currentUser.name,
    currentUserNameColor: currentUser.nameColor,
    caseItem,
    attachmentCount: props.message.files.length
  };
};

export default connect(mapStateToProps)(OutgoingEmailMessageListItemContainer);
