import { connect } from "react-redux";
import TeamMemberTeam from "../components/TeamMemberTeam";

const mapStateToProps = (state, props) => {
  const team = state.entities.teams[props.teamId];

  return {
    teamTitle: team.title,
    teamColor: team.color
  };
};

export default connect(mapStateToProps)(TeamMemberTeam);
