import React from "react";
import { Preloader, Col } from "design-system-react";
import classNames from "classnames";
import styles from "./SettingsVkModalWrap.module.css";

function SettingsVkModalWrap({ isLoading, children }) {
  return (
    <span className={styles.content}>
      {isLoading && (
        <span className={styles.loader}>
          <Col align="center" justified>
            <Preloader size="32" color="#807d7f" />
          </Col>
        </span>
      )}
      <div className={classNames(isLoading && styles.isLoading)}>
        {children}
      </div>
    </span>
  );
}

export default SettingsVkModalWrap;
