import React, { useState, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { api, setToken } from "../../utils/client";
import { setUserId } from "../../modules/auth";
import { Formik } from "formik";
import { FormError } from "../../utils/formError";
import ConfirmInviteForm from "./components/ConfirmInviteForm";
import ConfirmInviteFormContainer from "./containers/ConfirmInviteFormContainer";
import { ALERT_ERROR, showAlert } from "../../utils/alertManager";

function ConfirmInvite({ match, setUserId }) {
  const [checkLoading, setCheckLoading] = useState(true);
  const token = match.params.token;

  const handleSubmit = useCallback(
    (values, { setErrors, setSubmitting }) => {
      api.teamMembers
        .confirmInvite(values.token, values.name, values.password)
        .ready.then(response => {
          const email = response.data.data.email;

          api.teamMembers
            .signIn(email, values.password, true)
            .ready.then(response => {
              const id = response.data.data.id;
              const token = response.data.data.token;

              localStorage.setItem("token", token);
              localStorage.setItem("userId", id);

              setToken(token);
              setUserId(id);
            })
            .finally(() => {
              setSubmitting(false);
            });
        })
        .catch(e => {
          if (e instanceof FormError) {
            setErrors(e.errors);

            if (e.errors.invitationToken) {
              showAlert(ALERT_ERROR, "Invitation token is not valid");
            }
          }
          setSubmitting(false);
        });
    },
    [setUserId]
  );

  // invalid token
  useEffect(() => {
    api.teamMembers.checkToken(token).ready.finally(() => {
      setCheckLoading(false);
    });
  }, [token]);

  if (checkLoading) {
    return null;
  }

  return (
    <ConfirmInviteForm>
      <Formik
        initialValues={{
          token,
          name: "",
          password: ""
        }}
        onSubmit={handleSubmit}
        component={ConfirmInviteFormContainer}
      />
    </ConfirmInviteForm>
  );
}

const mapDispatchToProps = dispatch => ({
  setUserId: id => dispatch(setUserId(id))
});

export default connect(
  null,
  mapDispatchToProps
)(ConfirmInvite);
