import React, { useEffect, useCallback, useState } from "react";
import { connect } from "react-redux";
import {
  Table,
  TableHeader,
  TableHeaderColumn,
  TableBody,
  TableHeaderColumnMenu,
  Col,
  Preloader,
  Scrollbar
} from "design-system-react";
import { api } from "utils/client";
import {
  setTeamMemberTeamIds,
  removeTeamMemberTeamId
} from "modules/pages/team";
import EmptyState from "components/EmptyState";
import {
  MODAL_DEFAULT,
  MODAL_ERROR,
  MODAL_PROCESS,
  MODAL_SUCCESS,
  showConfirmModal,
  updateConfirmModal
} from "utils/modalManager";
import { CommonError } from "utils/commonError";
import TeamMemberItemContainer from "./TeamMemberItemContainer";

function TeamUserListContainer({
  teamMemberIds,
  teamId,
  setTeamMemberTeamIds,
  removeTeamMemberTeamId,
                                 currentUserRoleName,
                                 currentUserTeamIds
}) {
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    api.teams
      .members(teamId)
      .ready.then(response => {
        setTeamMemberTeamIds(response.data.data.map(member => member.id));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [teamId, setTeamMemberTeamIds]);

  const handleDeleteFromTeam = useCallback(
    (memberId, title) => {
      const modalId = showConfirmModal(
        MODAL_DEFAULT,
        "Are you sure you want to delete " + title + "?",
        {
          actionButtonText: "Delete",
          onAction: () => {
            updateConfirmModal(modalId, MODAL_PROCESS, "Deleting...");

            api.teams
              .removeMember(teamId, { teamMember: memberId })
              .ready.then(() => {
                removeTeamMemberTeamId(memberId);
                updateConfirmModal(
                  modalId,
                  MODAL_SUCCESS,
                  "Team was successfully deleted"
                );
              })
              .catch(e => {
                const errorMessage = "Ooops, something went wrong";
                updateConfirmModal(
                  modalId,
                  MODAL_ERROR,
                  e instanceof CommonError ? e.error : errorMessage,
                  { cancelButtonText: "Okay" }
                );
              });
          }
        }
      );
    },
    [teamId, removeTeamMemberTeamId]
  );

  if (isLoading) {
    return (
      <Col align="center" justified>
        <Preloader size="32" color="#807d7f" />
      </Col>
    );
  }

  if (teamMemberIds.length === 0) {
    return (
      <Col align="center" verticalAlign="center" justified>
        <EmptyState title="No team members added">
          Currently you have no team members <br /> in this team
        </EmptyState>
      </Col>
    );
  }

  const currentUserIsMemberTeam = currentUserTeamIds.indexOf(teamId) !== -1;

  return (
    <Scrollbar>
      <Table>
        <TableHeader>
          <TableHeaderColumn justified fixed />
          <TableHeaderColumn>Added</TableHeaderColumn>
          <TableHeaderColumn>Role</TableHeaderColumn>
          <TableHeaderColumnMenu />
        </TableHeader>
        <TableBody>
          {teamMemberIds
            // .filter(i => i.deletedAt === null)
            .map(id => {
              return (
                <TeamMemberItemContainer
                  teamMemberId={id}
                  currentUserRoleName={currentUserRoleName}
                  currentUserIsMemberTeam={currentUserIsMemberTeam}
                  handleDeleteFromTeam={handleDeleteFromTeam}
                />
              );
            })}
        </TableBody>
      </Table>
    </Scrollbar>
  );
}

function mapStateToProps(state) {
  const currentUser = state.entities.teamMembers[state.auth.userId];

  return {
    teamMemberIds: state.pages.team.userIds,
    currentUserRoleName: state.entities.roles[currentUser.role].name,
    currentUserTeamIds: currentUser.teams,
  };
}

const mapDispatchToProps = dispatch => ({
  setTeamMemberTeamIds: ids => dispatch(setTeamMemberTeamIds(ids)),
  removeTeamMemberTeamId: id => dispatch(removeTeamMemberTeamId(id))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamUserListContainer);
