import { useCallback } from "react";
import { printQualification } from "helpers/qualifications";
import PropTypes from "prop-types";
import {
  can,
  END_USER_CHANGE_QUALIFICATION_TO_CUSTOMER_PERMISSION,
  END_USER_CHANGE_QUALIFICATION_TO_LEAD_PERMISSION
} from "../../../utils/permissions";
import { Select } from "antd";

function DetailUserQualification({
  qualification,
  size,
  onQualificationChange
}) {
  const handleChangeQualification = useCallback(
    qualification => {
      onQualificationChange(qualification);
    },
    [onQualificationChange]
  );

  return (
    <Select
      defaultValue={printQualification(qualification)}
      style={{ width: 215 }}
      onChange={handleChangeQualification}
    >
      {qualification !== "customer" &&
        can(END_USER_CHANGE_QUALIFICATION_TO_CUSTOMER_PERMISSION) && (
          <Select.Option value="customer">
            {printQualification("customer")}
          </Select.Option>
        )}
      {qualification !== "lead" &&
        can(END_USER_CHANGE_QUALIFICATION_TO_LEAD_PERMISSION) && (
          <Select.Option value="lead">
            {printQualification("lead")}
          </Select.Option>
        )}
    </Select>
  );
}

DetailUserQualification.propTypes = {
  qualification: PropTypes.string.isRequired,
  onQualificationChange: PropTypes.func.isRequired
};

export default DetailUserQualification;
