import { CALL_API } from "utils/api";
import { withNamespace } from "utils/namespace";

const type = withNamespace("pages/cases/caseItem");

// action types
export const ASSIGN_REQUEST = type("ASSIGN_REQUEST");
export const ASSIGN_SUCCESS = type("ASSIGN_SUCCESS");
export const ASSIGN_FAILURE = type("ASSIGN_FAILURE");

let initialState = {
  assignLoading: false
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ASSIGN_REQUEST:
      return {
        assignLoading: true
      };
    case ASSIGN_SUCCESS:
      return {
        assignLoading: false
      };
    case ASSIGN_FAILURE:
      return {
        assignLoading: false
      };

    default:
      return state;
  }
}

// actions
export function fetchCase(caseId, params) {
  return dispatch => {
    return dispatch({
      [CALL_API]: {
        endpoint: "/cases/" + caseId,
        options: {
          method: "get",
          params: params
        }
      }
    });
  };
}

export function assign(caseId, userId, teamId, uuid) {
  return dispatch => {
    // dispatch(
    //   addOutgoingMessage(caseId, {
    //     caseId,
    //     uuid: uuid,
    //     event: "assigned",
    //     targetUserId: userId,
    //     createdAt: Math.floor(new Date().getTime() / 1000)
    //   })
    // );

    return dispatch({
      [CALL_API]: {
        endpoint: "/cases/" + caseId + "/assign",
        options: {
          method: "post",
          data: {
            targetUser: userId,
            uuid: uuid,
            team: teamId
          }
        },
        types: {
          request: ASSIGN_REQUEST,
          success: ASSIGN_SUCCESS,
          failure: ASSIGN_FAILURE
        },
        payload: {
          caseId
        }
      }
    });
  };
}

export function unassign(caseId, uuid) {
  return dispatch => {
    // dispatch(
    //   addOutgoingMessage(caseId, {
    //     caseId,
    //     uuid: uuid,
    //     event: "unassigned",
    //     createdAt: Math.floor(new Date().getTime() / 1000)
    //   })
    // );

    return dispatch({
      [CALL_API]: {
        endpoint: "/cases/" + caseId + "/unassign",
        options: {
          method: "post",
          data: {
            uuid: uuid
          }
        },
        payload: {
          caseId
        },
        types: {
          request: ASSIGN_REQUEST,
          success: ASSIGN_SUCCESS,
          failure: ASSIGN_FAILURE
        }
      }
    });
  };
}

export function close(caseId, uuid) {
  return dispatch => {
    // dispatch(
    //   addOutgoingMessage(caseId, {
    //     caseId,
    //     uuid: uuid,
    //     event: "closed",
    //     createdAt: Math.floor(new Date().getTime() / 1000)
    //   })
    // );

    return dispatch({
      [CALL_API]: {
        endpoint: "/cases/" + caseId + "/close",
        options: {
          method: "post",
          data: {
            uuid: uuid
          }
        }
      }
    });
  };
}
