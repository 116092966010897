import {
  can,
  CHANNEL_DELETE_PERMISSION,
  CHANNEL_RESTORE_PERMISSION,
  CHANNEL_UPDATE_PERMISSION
} from "../utils/permissions";

export const CHANNEL_TYPE_FACEBOOK = "facebook";
export const CHANNEL_TYPE_WHATSAPP = "whatsapp";
export const CHANNEL_TYPE_TYNTEC_WHATSAPP = "tyntecWhatsapp";
export const CHANNEL_TYPE_TELEGRAMBOT = "telegrambot";
export const CHANNEL_TYPE_VIBER = "viber";
export const CHANNEL_TYPE_VK = "vk";
export const CHANNEL_TYPE_BLINGER = "messenger";
export const CHANNEL_TYPE_EMAIL = "email";

export const INTEGRATION_TYPE_SLACK = "slack";

const CHANNEL_LABEL_FACEBOOK = "Facebook Messenger";
const CHANNEL_LABEL_WHATSAPP = "WhatsApp";
const CHANNEL_LABEL_TYNTEC_WHATSAPP = "WhatsApp";
const CHANNEL_LABEL_TELEGRAMBOT = "Telegram";
const CHANNEL_LABEL_VIBER = "Viber";
const CHANNEL_LABEL_VK = "VK";
const CHANNEL_LABEL_BLINGER = "Blinger Messenger";
const CHANNEL_LABEL_EMAIL = "Email";

const INTEGRATION_LABEL_SLACK = "Slack";

const LABELS_BY_TYPE = {
  [CHANNEL_TYPE_FACEBOOK]: CHANNEL_LABEL_FACEBOOK,
  [CHANNEL_TYPE_WHATSAPP]: CHANNEL_LABEL_WHATSAPP,
  [CHANNEL_TYPE_TYNTEC_WHATSAPP]: CHANNEL_LABEL_TYNTEC_WHATSAPP,
  [CHANNEL_TYPE_TELEGRAMBOT]: CHANNEL_LABEL_TELEGRAMBOT,
  [CHANNEL_TYPE_VIBER]: CHANNEL_LABEL_VIBER,
  [CHANNEL_TYPE_VK]: CHANNEL_LABEL_VK,
  [CHANNEL_TYPE_BLINGER]: CHANNEL_LABEL_BLINGER,
  [CHANNEL_TYPE_EMAIL]: CHANNEL_LABEL_EMAIL,
  [INTEGRATION_TYPE_SLACK]: INTEGRATION_LABEL_SLACK
};

const EDITABLE_CHANNELS = [CHANNEL_TYPE_EMAIL];

const NOT_RESTORABLE_CHANNELS = [CHANNEL_TYPE_WHATSAPP];

const CHANNEL_ACTION_EDIT = { key: "edit", label: "Edit" };
const CHANNEL_ACTION_DELETE = { key: "delete", label: "Delete" };
const CHANNEL_ACTION_RESTORE = { key: "restore", label: "Restore" };

export function printChannel(channel) {
  return LABELS_BY_TYPE[channel] || "";
}

export function printChannelStatus(deleted) {
  return !!deleted ? "Deleted" : "Enabled";
}

export function channelActionItems(type, enabled, deletedAt) {
  let actions = [];

  const deleteCondition = can(CHANNEL_DELETE_PERMISSION) && !deletedAt;
  const restoreCondition =
    can(CHANNEL_RESTORE_PERMISSION) &&
    !!deletedAt &&
    isNotRestorableChannel(type);
  const editCondition =
    can(CHANNEL_UPDATE_PERMISSION) && !deletedAt && isEditableChannel(type);

  if (editCondition) {
    actions.push(CHANNEL_ACTION_EDIT);
  }

  if (deleteCondition) {
    actions.push(CHANNEL_ACTION_DELETE);
  }

  if (restoreCondition) {
    actions.push(CHANNEL_ACTION_RESTORE);
  }

  return actions;
}

export function isNeedToRenderNewCase(channel) {
  return channel === CHANNEL_TYPE_EMAIL || channel === CHANNEL_TYPE_WHATSAPP;
}

function isEditableChannel(type) {
  return EDITABLE_CHANNELS.includes(type);
}

function isNotRestorableChannel(type) {
  return !NOT_RESTORABLE_CHANNELS.includes(type);
}
