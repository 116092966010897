import React, { PureComponent } from "react";

class Preloader extends PureComponent {
  render() {
    return (
      <div className="preloader-container">
        <img src={"/images/preloader.svg"} alt="" />
      </div>
    );
  }
}

export default Preloader;
