import React from "react";
import { connect } from "react-redux";
import {
  SubMenu,
  SubMenuHeader,
  SubMenuContent,
  SubMenuList,
  SubMenuListCategory
} from "design-system-react";
import {
  can,
  VIEW_TEAM_MEMBERS_PERMISSION,
  VIEW_TEAMS_PERMISSION
} from "../utils/permissions";
import TeamMembersSubMenuItem from "./TeamMembersSubMenuItem";

function TeamMembersSubMenuContainer({
  teamMemberViews,
  teamViews,
  activeMenuItem,
  activeViewId,
  teamsIds,
  teamMembersIds
}) {
  return (
    <SubMenu>
      <SubMenuHeader>
        {activeMenuItem.startsWith("/team-members") ? "Team member" : "Team"}
      </SubMenuHeader>
      {teamMembersIds.length > 0 && can(VIEW_TEAM_MEMBERS_PERMISSION) && (
        <SubMenuContent>
          <SubMenuListCategory>Members</SubMenuListCategory>
          <SubMenuList>
            {teamMembersIds.map(id => {
              return (
                <TeamMembersSubMenuItem
                  key={id}
                  viewId={id}
                  activeMenuItem={activeMenuItem}
                  link={"/team-members/"}
                />
              );
            })}
          </SubMenuList>
        </SubMenuContent>
      )}
      {teamsIds.length > 0 && can(VIEW_TEAMS_PERMISSION) && (
        <SubMenuContent>
          <SubMenuListCategory>Teams</SubMenuListCategory>
          <SubMenuList>
            {teamsIds.map(id => {
              return (
                <TeamMembersSubMenuItem
                  key={id}
                  viewId={id}
                  activeMenuItem={activeMenuItem}
                  link={"/teams/"}
                />
              );
            })}
          </SubMenuList>
        </SubMenuContent>
      )}
    </SubMenu>
  );
}

const mapStateToProps = (state, props) => {
  const activeViewId = state.teamViews.ids.filter(id => {
    const view = state.entities.views[id];
    return view && view.name === props.activeViewName;
  })[0];

  return {
    teamsIds: state.teamViews.ids,
    teamMembersIds: state.teamMemberViews.ids,
    activeViewId: activeViewId
  };
};

export default connect(mapStateToProps)(TeamMembersSubMenuContainer);
