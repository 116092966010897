import React from "react";
import PropTypes from "prop-types";
import { can } from "utils/permissions";

const Can = ({ children, permission, payload }) => {
  if (!can(permission, payload)) {
    return null;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

Can.propTypes = {
  permission: PropTypes.string.isRequired,
  payload: PropTypes.object
};

export default Can;
