import { useCallback } from "react";
import { api } from "utils/client";
import uuidv4 from "utils/uuidv4";
import { Alert, message, Typography, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

export default function useDeleteTeamMember() {
  const deleteTeamMember = useCallback(
    (teamMemberId, teamMemberName, onDelete) => {
      const id = uuidv4();

      message.loading({
        content: "Please wait...",
        key: id,
        duration: 0
      });

      const ownerCountRequest = api.teamMembers.ownerCount(teamMemberId).ready;
      const viewRequest = api.views.oneByName(
        teamMemberId,
        "cases",
        "assigned_to_me"
      ).ready;

      Promise.all([ownerCountRequest, viewRequest])
        .then(responses => {
          message.destroy(id);

          const ownerCount = responses[0].data;
          const assignedCasesCount = responses[1].data.data.count;

          let infoMessage = "";

          if (ownerCount === 0 && assignedCasesCount > 0) {
            infoMessage =
              "You can't delete this account because it has " +
              assignedCasesCount +
              " opened case(s).";
          } else if (ownerCount > 0 && assignedCasesCount === 0) {
            infoMessage =
              "You can't delete this account because it owns " +
              ownerCount +
              " contact(s).";
          } else if (ownerCount > 0 && assignedCasesCount > 0) {
            infoMessage =
              "You can't delete this account because it has " +
              assignedCasesCount +
              " opened case(s) and owns " +
              ownerCount +
              " contact(s).";
          }

          Modal.confirm({
            title: "Delete user",
            content: (
              <>
                <Typography.Paragraph>
                  Delete the user account {teamMemberName}?
                </Typography.Paragraph>
                {(assignedCasesCount > 0 || ownerCount > 0) && (
                  <Alert type="info" message={infoMessage} />
                )}
              </>
            ),
            icon: <ExclamationCircleOutlined />,
            okButtonProps: {
              disabled: assignedCasesCount > 0 || ownerCount > 0
            },
            onOk: () => {
              const id = uuidv4();

              message.loading({
                content: "Deleting...",
                key: id,
                duration: 0
              });

              api.teamMembers
                .delete(teamMemberId)
                .ready.then(() => {
                  onDelete && onDelete();
                  // removeTeamMemberId(member.id);

                  // api.views.teamMembers();

                  message.success({
                    content: "User was deleted successfully.",
                    key: id
                  });
                })
                .catch(e => {
                  message.error({
                    content: "Something went wrong.",
                    key: id
                  });
                });
            }
          });
        })
        .catch(() => {
          message.error({
            content: "Something went wrong.",
            key: id
          });
        });
    },
    // eslint-disable-next-line
    []
  );

  return deleteTeamMember;
}
