import React from "react";
import { Field } from "formik";
import {
  Row,
  Col,
  GutterField,
  DropdownItemWithAvatar,
  Avatar
} from "design-system-react";
import useFormSubmission from "hooks/useFormSubmission";
import SelectField from "components/SelectField";
import { extractFirstLetters } from "../../../utils/string";

function AddTeamUserModalFormContainer({
  submitDisabled,
  setSubmitDisabled,
  handleSubmit,
  teamMembers,
  dirty
}) {
  const handleFormSubmit = useFormSubmission(submitDisabled, handleSubmit);

  if (!dirty) {
    if (!submitDisabled) {
      setSubmitDisabled(true);
    }
  } else if (submitDisabled) {
    setSubmitDisabled(false);
  }

  return (
    <form onSubmit={handleFormSubmit}>
      <Row>
        <Col>
          <GutterField>
            <Field
              name="teamMember"
              label="Members"
              component={SelectField}
              disabled={teamMembers.length === 0}
              items={teamMembers}
              renderItem={item => (
                <DropdownItemWithAvatar
                  renderAvatar={
                    <Avatar
                      size="size24"
                      color={item.color}
                      text={extractFirstLetters(item.name, 1)}
                      image={item.avatar}
                    />
                  }
                >
                  {item.name}
                </DropdownItemWithAvatar>
              )}
            />
          </GutterField>
        </Col>
      </Row>
    </form>
  );
}

export default AddTeamUserModalFormContainer;
