import React from "react";
import { connect } from "react-redux";
import {
  QuickReply,
  QuickReplyHeader,
  QuickReplyContainer as Container,
  Button
} from "design-system-react";
import { createSelector } from "reselect";
import QuickReplyItemContainer from "./QuickReplyItemContainer";

function QuickReplyContainer({
  isOpen,
  toggle,
  caseId,
  quickReplies,
  filter,
  toggleCreateModal,
  onReplySelect
}) {
  let replies = [];

  if (isOpen) {
    replies = filter
      ? quickReplies.filter(reply =>
          reply.title.toLowerCase().startsWith(
            filter
              .substring(1, filter.length)
              .trim()
              .toLowerCase()
          )
        )
      : quickReplies;
  }

  return (
    <QuickReply isOpen={isOpen}>
      <QuickReplyHeader
        renderButton={
          <Button outline onClick={toggleCreateModal}>
            Create
          </Button>
        }
      >
        Quick replies
      </QuickReplyHeader>
      <Container>
        {replies.map(reply => (
          <QuickReplyItemContainer
            title={reply.title}
            text={reply.text}
            onReplySelect={onReplySelect}
          />
        ))}
      </Container>
    </QuickReply>
  );
}

const getReplies = createSelector(
  [state => state.entities.quickReplies, state => state.quickReplies.ids],
  (quickReplies, ids) => {
    return ids.map(id => quickReplies[id]);
  }
);

const mapStateToProps = state => {
  return {
    quickReplies: getReplies(state)
  };
};

export default connect(mapStateToProps)(QuickReplyContainer);
