import { withNamespace } from "utils/namespace";

const type = withNamespace("notifications");

// action types
export const ADD_NOTIFICATION = type("ADD_NOTIFICATION");
export const REMOVE_NOTIFICATION = type("REMOVE_NOTIFICATION");
export const SET_NOTIFICATIONS = type("SET_NOTIFICATIONS");
export const ADD_PUSH_NOTIFICATION = type("ADD_PUSH_NOTIFICATION");
export const REMOVE_PUSH_NOTIFICATION = type("REMOVE_PUSH_NOTIFICATION");
export const SET_PUSH_NOTIFICATIONS = type("SET_PUSH_NOTIFICATIONS");
export const SET_UNSEEN = type("SET_UNSEEN");

let initialState = {
  notificationGroups: [],
  pushNotificationGroups: [],
  unseen: localStorage.getItem("notifications") === "true" || false
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_NOTIFICATIONS:
      return {
        ...state,
        notificationGroups: action.payload.groups || []
      };
    case ADD_NOTIFICATION:
      const newNotificationGroup = {
        groupId: action.payload.group,
        notificationId: action.payload.notificationId
      };

      let notificationGroupUpdated = false;
      let notificationGroups = state.notificationGroups.map(group => {
        if (group.groupId === action.payload.group) {
          notificationGroupUpdated = true;
          return newNotificationGroup;
        }
        return group;
      });

      if (!notificationGroupUpdated) {
        notificationGroups = [newNotificationGroup, ...notificationGroups];
      }

      return {
        ...state,
        notificationGroups
      };
    case REMOVE_NOTIFICATION:
      return {
        ...state,
        notificationGroups: state.notificationGroups.filter(
          g => g.groupId !== action.payload.group
        )
      };
    case SET_PUSH_NOTIFICATIONS:
      return {
        ...state,
        pushNotificationGroups: action.payload.groups || []
      };
    case ADD_PUSH_NOTIFICATION:
      const newPushNotificationGroup = {
        groupId: action.payload.group,
        notificationId: action.payload.notificationId
      };

      let pushNotificationGroupUpdated = false;
      let pushNotificationGroups = state.pushNotificationGroups.map(group => {
        if (group.groupId === action.payload.group) {
          pushNotificationGroupUpdated = true;

          return newPushNotificationGroup;
        }
        return group;
      });

      if (!pushNotificationGroupUpdated) {
        pushNotificationGroups = [
          newPushNotificationGroup,
          ...pushNotificationGroups
        ];
      }

      return {
        ...state,
        pushNotificationGroups
      };
    case REMOVE_PUSH_NOTIFICATION:
      return {
        ...state,
        pushNotificationGroups: state.pushNotificationGroups.filter(
          g => g.groupId !== action.payload.group
        )
      };
    case SET_UNSEEN:
      if (state.unseen !== action.payload.value) {
        return {
          ...state,
          unseen: action.payload.value
        };
      } else {
        return state;
      }
    default:
      return state;
  }
}

// actions
export function addNotification(group, notificationId) {
  return {
    type: ADD_NOTIFICATION,
    payload: {
      group,
      notificationId
    }
  };
}

export function removeNotification(group) {
  return {
    type: REMOVE_NOTIFICATION,
    payload: {
      group
    }
  };
}

export function setNotifications(groups) {
  return {
    type: SET_NOTIFICATIONS,
    payload: {
      groups
    }
  };
}

export function addPushNotification(group, notificationId) {
  return {
    type: ADD_PUSH_NOTIFICATION,
    payload: {
      group,
      notificationId
    }
  };
}

export function removePushNotification(group) {
  return {
    type: REMOVE_PUSH_NOTIFICATION,
    payload: {
      group
    }
  };
}

export function setPushNotifications(groups) {
  return {
    type: SET_PUSH_NOTIFICATIONS,
    payload: {
      groups
    }
  };
}

export function setUnreadIndicator(value) {
  return {
    type: SET_UNSEEN,
    payload: {
      value
    }
  };
}
