import { useEffect, useState } from "react";
import { Select } from "antd";
import EntityAvatar from "components/entityAvatar/EntityAvatar";
import { api } from "utils/client";
import { extractFirstLetters } from "utils/string";

export interface SelectTeamInputProps {
  mode?: "multiple";
  value?: any;
}

function SelectTeamInput({ mode, value, ...props }: SelectTeamInputProps) {
  const [teams, setTeams] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    api.teams
      .teamsForFilter()
      .ready.then((response: any) => {
        setTeams(response.data.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Select
      mode={mode}
      filterOption={(input, option) => {
        if (!option) {
          return false;
        }

        return (
          (option.label as string)
            .toLowerCase()
            .indexOf(input.toLocaleLowerCase()) >= 0
        );
      }}
      loading={loading}
      disabled={loading}
      showSearch
      value={teams.length > 0 ? value : null}
      {...props}
    >
      {teams.map((team: any) => (
        <Select.Option key={team.id} value={team.id} label={team.title}>
          <EntityAvatar
            avatarSize={18}
            avatarSrc={team.avatar}
            avatarBackground={team.color}
            avatarTitle={extractFirstLetters(team.title, 1)}
            title={team.title}
          />
        </Select.Option>
      ))}
    </Select>
  );
}

export default SelectTeamInput;
