import React, { useState, useEffect, useCallback } from "react";
import { Redirect } from "react-router-dom";
import { Container, Row, Scrollbar, Col, Preloader } from "design-system-react";
import WidgetSubMenu from "components/WidgetSubMenu";
import WidgetLayout from "./components/WidgetLayout";
import WidgetExampleContainer from "./containers/WidgetExampleContainer";
import WidgetIntroduce from "pages/WidgetIntroduce";
import WidgetStyle from "pages/WidgetStyle";
import WidgetWelcome from "pages/WidgetWelcome";
import WidgetAvailability from "pages/WidgetAvailability";
import WidgetChannels from "pages/WidgetChannels";
import WidgetQualify from "pages/WidgetQualify";
import WidgetGoLive from "pages/WidgetGoLive";
import { api } from "utils/client";
import { api as widget, setToken } from "utils/widgetClient";
import { setSetup } from "modules/widgetSetup";
import { connect } from "react-redux";
import WidgetContainer from "containers/WidgetSetup/components/WidgetContainer";
import WidgetFormSide from "components/Widget/WidgetFormSide";
import { can, CASES_ANALYTICS_PERMISSION } from "utils/permissions";
import useAlertRedirect from "hooks/useAlertRedirect";
import { ALERT_ERROR } from "utils/alertManager";

// let token = localStorage.getItem("widgetToken");

function WidgetSetup({ location, setSetup }) {
  const [isLoading, setLoading] = useState(true);
  const redirect = useAlertRedirect();

  useEffect(() => {
    localStorage.setItem("lastWidgetRoute", location.pathname);

    api.messenger.all().ready.then(response => {
      if (response.data.data.length) {
        setToken(response.data.data[0].info.tokenSecure);
        widget.widget.setupList().ready.then(response => {
          setSetup(response.data.data[0]);
          setLoading(false);
        });
      } else {
        api.messenger.create().ready.then(response => {
          setToken(response.data.data.info.tokenSecure);
          widget.widget.setupList().ready.then(response => {
            setSetup(response.data.data[0]);
            setLoading(false);
          });
        });
      }
    });
  }, [location.pathname, setSetup]);

  if (!can(CASES_ANALYTICS_PERMISSION)) {
    redirect("/", ALERT_ERROR, "Access denied");
  }

  const renderContent = useCallback(() => {
    switch (location.pathname) {
      case "/widget/introduce":
        return <WidgetIntroduce />;
      case "/widget/style":
        return <WidgetStyle />;
      case "/widget/welcome":
        return <WidgetWelcome />;
      case "/widget/availability":
        return <WidgetAvailability />;
      case "/widget/channels":
        return <WidgetChannels />;
      case "/widget/qualify":
        return <WidgetQualify />;
      case "/widget/live":
        return <WidgetGoLive />;
      default:
        return <Redirect to="/" />;
    }
  }, [location.pathname]);

  return (
    <Row fullHeight column>
      <WidgetSubMenu activeMenuItem={location.pathname} />
      <WidgetLayout>
        {isLoading ? (
          <Row fullHeight column>
            <Col align="center" justified>
              <Preloader size="32" color="#807d7f" />
            </Col>
          </Row>
        ) : (
          <Scrollbar>
            <Container>
              <WidgetContainer>
                <WidgetFormSide>{renderContent()}</WidgetFormSide>
                <WidgetExampleContainer
                  isOpen={location.pathname === "/widget/qualify"}
                />
              </WidgetContainer>
            </Container>
          </Scrollbar>
        )}
      </WidgetLayout>
    </Row>
  );
}

const mapDispatchToProps = dispatch => ({
  setSetup: setup => dispatch(setSetup(setup))
});

export default connect(
  null,
  mapDispatchToProps
)(WidgetSetup);
