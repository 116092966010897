import { useState } from "react";
import { api } from "utils/client";
import AssignInput from "components/assignInput/AssignInput";

export interface TeamMemberSubOwnerInputProps {
  defaultSelectedTeam?: any;
  defaultSelectedTeamMember?: any;
  disabledMembers?: string[];
  customTrigger?: any;
  onChange?: (teamId?: any, teamMemberId?: any) => void;
}

function TeamMemberSubOwnerInput({
  defaultSelectedTeam,
  defaultSelectedTeamMember,
  disabledMembers,
  customTrigger,
  onChange
}: TeamMemberSubOwnerInputProps) {
  const [selectedTeam, setSelectedTeam] = useState<any>(defaultSelectedTeam);
  const [selectedTeamMember, setSelectedTeamMember] = useState<any>(
    defaultSelectedTeamMember
  );

  return (
    <AssignInput
      width="100%"
      placeholder="Choose owner"
      assignLabel=""
      value={{ team: selectedTeam, teamMember: selectedTeamMember }}
      disableTeamSelection
      disabledMembers={disabledMembers}
      customTrigger={customTrigger}
      prependOptions={[
        {
          label: <div style={{ pointerEvents: "none" }}>None</div>,
          value: "unassign",
          onSelect: () => {
            setSelectedTeam(null);
            setSelectedTeamMember(null);

            onChange && onChange("none", "none");
          }
        }
      ]}
      onTeamsRequest={() => {
        return api.cases.teamsForAssign().ready.then((response: any) => {
          return response.data.data;
        });
      }}
      onTeamMembersRequest={teamId => {
        return api.cases
          .membersForAssignByTeam(teamId)
          .ready.then((response: any) => {
            return response.data.data;
          });
      }}
      onChange={({ team, teamMember }) => {
        if (team && teamMember) {
          setSelectedTeam(team);
          setSelectedTeamMember(teamMember);

          onChange && onChange(team, teamMember);
        }
      }}
    />
  );
}

export default TeamMemberSubOwnerInput;
