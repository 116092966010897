import React, { useEffect } from "react";
import SettingsSubMenu from "components/settings/SettingsSubMenu";
import SettingsSlackContainer from "./containers/SettingsSlackContainer";
import SettingsLayout from "components/SettingsLayout";

function SettingsSlack({ location }) {
  useEffect(() => {
    document.title = "Slack settings";
    localStorage.setItem("lastSettingRoute", "/settings/slack");
  }, []);

  return (
    <SettingsLayout
      renderSubmenu={() => (
        <SettingsSubMenu activeMenuItem={"/settings/slack"} />
      )}
      renderContent={() => (
        <SettingsSlackContainer locationKey={location.key} />
      )}
    />
  );
}

export default SettingsSlack;
