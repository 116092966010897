import { connect } from "react-redux";
import { logout } from "modules/auth";
import { extractFirstLetters } from "utils/string";
import { Menu, Dropdown, Tooltip } from "antd";
import {
  SettingOutlined,
  UserOutlined,
  BookOutlined,
  ThunderboltOutlined,
  LogoutOutlined
} from "@ant-design/icons";
import styles from "./AccountItem.module.css";
import EntityAvatar from "components/entityAvatar/EntityAvatar";
import { settingsMenu } from "components/settings/SettingsSubMenu";
import { Link } from "react-router-dom";

function AccountItem({ currentUser, logout }) {
  return (
    <div className={styles.accountItemWrapper}>
      <Dropdown
        overlay={
          <Menu style={{ minWidth: "192px" }}>
            <Menu.Item key="profile" icon={<UserOutlined />}>
              <Link to={"/settings/profile"}>My account</Link>
            </Menu.Item>
            <Menu.Item key="settings" icon={<SettingOutlined />}>
              <Link to={settingsMenu(currentUser)[0].linkTo}>Settings</Link>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="help" icon={<BookOutlined />}>
              <a
                href="https://help.hugery.com/"
                target="_blank"
                rel="noreferrer"
              >
                Help & Support
              </a>
            </Menu.Item>
            <Menu.Item key="news" icon={<ThunderboltOutlined />}>
              <a
                href="https://help.hugery.com/whats-new"
                target="_blank"
                rel="noreferrer"
              >
                Whats's new?
              </a>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item
              key="logout"
              icon={<LogoutOutlined />}
              danger
              onClick={logout}
            >
              Log out
            </Menu.Item>
          </Menu>
        }
        placement="topLeft"
        trigger={["click"]}
        arrow
      >
        <Tooltip title="Your Profile and Settings" placement="right">
          <a
            href="/#"
            className="ant-dropdown-link"
            onClick={e => e.preventDefault()}
          >
            <EntityAvatar
              avatarSize={40}
              avatarTextSize={14}
              avatarSrc={currentUser.avatar}
              avatarBackground={"#333"}
              avatarTitle={extractFirstLetters(currentUser.name, 1)}
            />
          </a>
        </Tooltip>
      </Dropdown>
    </div>
  );
}

const mapStateToProps = state => {
  const currentUser = state.entities.teamMembers[state.auth.userId];

  return {
    currentUser: currentUser
  };
};

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountItem);
