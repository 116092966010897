import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Router, Switch, Redirect, Route } from "react-router-dom";
import { unregister as unregisterServiceWorker } from "utils/registerServiceWorker";
import store from "store";
import history from "utils/history";

// styles
import "../node_modules/react-perfect-scrollbar/dist/css/styles.css";
import "./assets/app.css";
// import "../node_modules/design-system/dist/css/design-system.min.css";
import "../node_modules/design-system-react/lib/fonts/icons/css/design-system.css";

// layouts
import AppLayoutRoute from "routes/AppLayoutRoute";
import LoginLayoutRoute from "routes/LoginLayoutRoute";

// pages
import Login from "pages/Login";
import ConfirmInvite from "pages/ConfirmInvite";
import ResetPassword from "pages/ResetPassword";
import CreateNewPassword from "./pages/CreateNewPassword";
import NotFound from "pages/NotFound";
import EndUsers from "pages/EndUsers";
import EndUser from "pages/EndUser";
import TeamMembers from "pages/TeamMembers";
import TeamMember from "pages/TeamMember";
import Teams from "pages/Teams";
import Team from "pages/Team";
import SettingsFacebook from "pages/SettingsFacebook";
import SettingsWhatsApp from "pages/SettingsWhatsApp";
import SettingsTyntecWhatsapp from "pages/SettingsTyntecWhatsapp";
import SettingsTelegram from "pages/SettingsTelegram";
import SettingsVk from "pages/SettingsVk";
import SettingsViber from "pages/SettingsViber";
import SettingsBlingerMessenger from "pages/SettingsBlingerMessenger";
// import SettingsEmail from "pages/SettingsEmail";
import SettingsQuickReplies from "pages/SettingsQuickReplies";
import SettingsNotifications from "pages/SettingsNotifications";
import SettingsProfile from "pages/SettingsProfile";
import SettingsAutoResponses from "pages/SettingsAutoResponses";
import SettingsAutoResponse from "pages/SettingsAutoResponse";
import SettingsTelegramInterface from "pages/SettingsTelegramInterface";
import SettingsPipedrive from "pages/SettingsPipedrive";
import SettingsSalesCardSetups from "pages/SettingsSalesCardSetups";
import SettingsSalesCards from "pages/SettingsSalesCards";
import Cases from "pages/Cases";
import AnalyticsCases from "./pages/AnalyticsCases";
import AnalyticsTeamMembers from "./pages/AnalyticsTeamMembers";
import AnalyticsTeams from "./pages/AnalyticsTeams";
import HomePage from "./pages/HomePage";
import ErrorInvite from "pages/ErrorInvite";
import ErrorAccount from "pages/ErrorAccount";
import ErrorToken from "./pages/ErrorToken";
import SettingsSlack from "./pages/SettingsSlack";
import WidgetSetup from "containers/WidgetSetup";

class App extends Component {
  render() {
    return (
      <Router history={history}>
        <Provider store={store}>
          <React.Fragment>
            <Switch>
              <Route exact path="/">
                <Redirect to="/cases" />
              </Route>
              <AppLayoutRoute
                exact
                path="/"
                component={HomePage}
                layoutProps={{
                  activeNavMenuItem: "home"
                }}
              />
              <AppLayoutRoute
                exact
                path="/cases"
                component={Cases}
                layoutProps={{
                  activeNavMenuItem: "cases"
                }}
              />
              <AppLayoutRoute
                exact
                path="/cases/:view"
                component={Cases}
                layoutProps={{
                  activeNavMenuItem: "cases"
                }}
              />
              <AppLayoutRoute
                exact
                path="/cases/:view/:case"
                component={Cases}
                layoutProps={{
                  activeNavMenuItem: "cases"
                }}
              />
              <AppLayoutRoute
                exact
                path="/end-user/:id"
                component={EndUser}
                layoutProps={{
                  activeNavMenuItem: "endUsers"
                }}
              />
              <AppLayoutRoute
                exact
                path="/end-user/:id/:case"
                component={EndUser}
                layoutProps={{
                  activeNavMenuItem: "endUsers"
                }}
              />
              <AppLayoutRoute
                exact
                path="/end-users/:view(\w+)"
                component={EndUsers}
                layoutProps={{
                  activeNavMenuItem: "endUsers"
                }}
              />
              <AppLayoutRoute
                exact
                path="/team-member/:id"
                component={TeamMember}
                layoutProps={{
                  activeNavMenuItem: "teamMembers"
                }}
              />
              <AppLayoutRoute
                exact
                path="/team-member/:id/:case"
                component={TeamMember}
                layoutProps={{
                  activeNavMenuItem: "teamMembers"
                }}
              />
              <AppLayoutRoute
                exact
                path="/team-members/:view(\w+)"
                component={TeamMembers}
                layoutProps={{
                  activeNavMenuItem: "teamMembers"
                }}
              />
              <AppLayoutRoute
                exact
                path="/teams/:view(\w+)"
                component={Teams}
                layoutProps={{
                  activeNavMenuItem: "teamMembers"
                }}
              />
              <AppLayoutRoute
                exact
                path="/teams/:id"
                component={Team}
                layoutProps={{
                  activeNavMenuItem: "teamMembers"
                }}
              />
              <AppLayoutRoute
                exact
                path="/analytics/cases"
                component={AnalyticsCases}
                layoutProps={{
                  activeNavMenuItem: "analytics"
                }}
              />
              <AppLayoutRoute
                exact
                path="/analytics/team-members"
                component={AnalyticsTeamMembers}
                layoutProps={{
                  activeNavMenuItem: "analytics"
                }}
              />
              <AppLayoutRoute
                exact
                path="/analytics/teams"
                component={AnalyticsTeams}
                layoutProps={{
                  activeNavMenuItem: "analytics"
                }}
              />
              <AppLayoutRoute
                exact
                path="/settings/facebook-messenger"
                component={SettingsFacebook}
                layoutProps={{
                  activeNavMenuItem: "settings"
                }}
              />
              <AppLayoutRoute
                exact
                path="/settings/whatsapp"
                component={SettingsWhatsApp}
                layoutProps={{
                  activeNavMenuItem: "settings"
                }}
              />
              <AppLayoutRoute
                exact
                path="/settings/whatsapp-business"
                component={SettingsTyntecWhatsapp}
                layoutProps={{
                  activeNavMenuItem: "settings"
                }}
              />
              <AppLayoutRoute
                exact
                path="/settings/telegram"
                component={SettingsTelegram}
                layoutProps={{
                  activeNavMenuItem: "settings"
                }}
              />
              <AppLayoutRoute
                exact
                path="/settings/viber"
                component={SettingsViber}
                layoutProps={{
                  activeNavMenuItem: "settings"
                }}
              />
              <AppLayoutRoute
                exact
                path="/settings/vk"
                component={SettingsVk}
                layoutProps={{
                  activeNavMenuItem: "settings"
                }}
              />
              <AppLayoutRoute
                exact
                path="/settings/blinger-messenger"
                component={SettingsBlingerMessenger}
                layoutProps={{
                  activeNavMenuItem: "settings"
                }}
              />
              {/*<AppLayoutRoute*/}
              {/*  exact*/}
              {/*  path="/settings/email"*/}
              {/*  component={SettingsEmail}*/}
              {/*  layoutProps={{*/}
              {/*    activeNavMenuItem: "settings"*/}
              {/*  }}*/}
              {/*/>*/}
              <AppLayoutRoute
                exact
                path="/settings/slack"
                component={SettingsSlack}
                layoutProps={{
                  activeNavMenuItem: "settings"
                }}
              />
              <AppLayoutRoute
                exact
                path="/settings/telegram-interface"
                component={SettingsTelegramInterface}
                layoutProps={{
                  activeNavMenuItem: "settings"
                }}
              />
              <AppLayoutRoute
                exact
                path="/settings/pipedrive"
                component={SettingsPipedrive}
                layoutProps={{
                  activeNavMenuItem: "settings"
                }}
              />
              <AppLayoutRoute
                exact
                path="/settings/sales-card-setups"
                component={SettingsSalesCardSetups}
                layoutProps={{
                  activeNavMenuItem: "settings"
                }}
              />
              <AppLayoutRoute
                exact
                path="/settings/sales-cards"
                component={SettingsSalesCards}
                layoutProps={{
                  activeNavMenuItem: "settings"
                }}
              />
              <AppLayoutRoute
                exact
                path="/settings/quick-replies"
                component={SettingsQuickReplies}
                layoutProps={{
                  activeNavMenuItem: "settings"
                }}
              />
              <AppLayoutRoute
                exact
                path="/settings/notifications"
                component={SettingsNotifications}
                layoutProps={{
                  activeNavMenuItem: "settings"
                }}
              />
              <AppLayoutRoute
                exact
                path="/settings/profile"
                component={SettingsProfile}
                layoutProps={{
                  activeNavMenuItem: "settings"
                }}
              />
              <AppLayoutRoute
                exact
                path="/settings/auto-responses"
                component={SettingsAutoResponses}
                layoutProps={{
                  activeNavMenuItem: "settings"
                }}
              />
              <AppLayoutRoute
                exact
                path="/settings/auto-responses/create"
                component={SettingsAutoResponse}
                layoutProps={{
                  activeNavMenuItem: "settings"
                }}
              />
              <AppLayoutRoute
                exact
                path="/settings/auto-responses/:id"
                component={SettingsAutoResponse}
                layoutProps={{
                  activeNavMenuItem: "settings"
                }}
              />
              <AppLayoutRoute
                exact
                path="/widget/:setup"
                component={WidgetSetup}
                layoutProps={{
                  activeNavMenuItem: "widget"
                }}
              />
              <LoginLayoutRoute exact path="/login" component={Login} />
              <LoginLayoutRoute
                exact
                path="/invite-confirmation/:token"
                component={ConfirmInvite}
              />
              <LoginLayoutRoute
                exact
                path="/reset-password"
                component={ResetPassword}
              />
              <LoginLayoutRoute
                exact
                path="/create-new-password/:token"
                component={CreateNewPassword}
              />
              <LoginLayoutRoute
                exact
                path="/invite-expired"
                component={ErrorInvite}
              />
              <LoginLayoutRoute
                exact
                path="/account-not-exist"
                component={ErrorAccount}
              />
              <LoginLayoutRoute
                exact
                path="/invalid-token"
                component={ErrorToken}
              />
              <AppLayoutRoute component={NotFound} />
            </Switch>
          </React.Fragment>
        </Provider>
      </Router>
    );
  }
}

ReactDOM.render(<App />, document.getElementById("root"));
unregisterServiceWorker();
