import React, { useCallback } from "react";
import popup from "utils/popup";
import { Row, Col, Container } from "design-system-react";
import SettingsChannelHeader from "components/SettingsHeader";
import SettingsVkModalAddContainer from "./SettingsVkModalAddContainer";
import SettingsVkListContainer from "./SettingsVkListContainer";
import { apiUrl } from "utils/client";

function SettingsVkContainer() {
  const openUserOAuthWindow = useCallback(() => {
    popup(apiUrl() + "/channels/vk/oauth", "user-oauth", 700, 500);
  }, []);

  return (
    <Row fullHeight column>
      <SettingsVkModalAddContainer />
      <Container>
        <SettingsChannelHeader
          title="VK"
          logo="/images/vk_100.svg"
          onAction={openUserOAuthWindow}
        >
          Send and receive messages from your customers and prospects by means
          of VK channel integration in Hugery. Hugery
          converts the messages into cases.
        </SettingsChannelHeader>
      </Container>
      <Col justified>
        <SettingsVkListContainer />
      </Col>
    </Row>
  );
}

export default SettingsVkContainer;
