import React from "react";
import connect from "react-redux/es/connect/connect";
import { TableRow, TableColumn, TableColumnMenu } from "design-system-react";
import { Link } from "react-router-dom";
import { DateTime } from "components/DateTime";
import TeamsListContact from "../components/TeamsListContact";
import { teamActionItems } from "helpers/teams";

function TeamsListItemContainer({
  scrollbarHeight,
  view,
  handleActionDropdown,
  teamId,
  teamIds,
  team
}) {
  return (
    <TableRow
      key={team.id}
      {...!team.deletedAt && {
        tag: Link,
        to: "/teams/" + team.id
      }}
    >
      <TableColumn isAvatar justified fixed>
        <TeamsListContact color={team.color} name={team.title} />
      </TableColumn>
      <TableColumn>
        {team.private ? "Private" : "Public"}
      </TableColumn>
      <TableColumn>
        <DateTime time={team.createdAt} />
      </TableColumn>
      <TableColumn>
        <DateTime time={team.updatedAt} />
      </TableColumn>
      {view !== "all" && team.deletedAt && (
        <TableColumn>
          <DateTime time={team.deletedAt} />
        </TableColumn>
      )}
      <TableColumnMenu
        containerHeight={scrollbarHeight}
        items={teamActionItems(team.deletedAt)}
        onItemClick={key => {
          handleActionDropdown(team, key);
        }}
      />
    </TableRow>
  );
}

const mapStateToProps = (state, props) => {
  return {
    team: state.entities.teams[props.teamId]
  };
};

export default connect(mapStateToProps)(TeamsListItemContainer);
