import { useCallback, useEffect, useRef } from "react";
import { Scrollbar } from "design-system-react";

function InfinitePagination({
  pagination,
  scrollOffset,
  topScrolling,
  children,
  scrollbarRef,
  setHeightScrollbar
}) {
  const innerScrollbarRef = useRef(null);

  const handleScroll = useCallback(
    e => {
      if (
        (topScrolling && e.target.scrollTop < scrollOffset) ||
        (!topScrolling &&
          e.target.scrollTop + e.target.clientHeight >
            e.target.scrollHeight - scrollOffset)
      ) {
        pagination.load(pagination.nextCursor);

        innerScrollbarRef.current.removeEventListener("scroll", handleScroll);
      }
    },
    [topScrolling, scrollOffset, pagination]
  );

  const handleInnerRef = useCallback(
    node => {
      innerScrollbarRef.current = node;

      if (scrollbarRef) {
        scrollbarRef.current = node;
      }
    },
    [scrollbarRef]
  );

  useEffect(() => {
    if (innerScrollbarRef.current && setHeightScrollbar) {
      setHeightScrollbar(innerScrollbarRef.current.clientHeight);
    }
  }, [setHeightScrollbar]);

  useEffect(() => {
    if (!pagination.nextLoading && pagination.hasMoreNextItems) {
      innerScrollbarRef.current.addEventListener("scroll", handleScroll);

      return () =>
        innerScrollbarRef.current &&
        innerScrollbarRef.current.removeEventListener("scroll", handleScroll);
    }
  }, [pagination.nextLoading, pagination.hasMoreNextItems, handleScroll]);

  return (
    <Scrollbar innerRef={handleInnerRef} removeTracksWhenNotUsed>
      {children}
    </Scrollbar>
  );
}

InfinitePagination.defaultProps = {
  scrollOffset: 600
};

export default InfinitePagination;
