import React, { useCallback } from "react";
import { connect } from "react-redux";
import { api, setToken } from "../../utils/client";
import { setUserId } from "../../modules/auth";
import { Formik } from "formik";
import { FormError } from "../../utils/formError";
import LoginForm from "./components/LoginForm";
import LoginFormContainer from "pages/Login/containers/LoginFormContainer";

function Login({ setUserId }) {
  const handleSubmit = useCallback(
    (values, { setErrors, setSubmitting }) => {
      setSubmitting(true);

      api.teamMembers
        .signIn(values.email, values.password, values.rememberMe)
        .ready.then(response => {
          const id = response.data.data.id;
          const token = response.data.data.token;

          if (values.rememberMe) {
            localStorage.setItem("token", token);
            localStorage.setItem("userId", id);
          } else {
            sessionStorage.setItem("token", token);
            sessionStorage.setItem("userId", id);
          }

          setToken(token);
          setUserId(id);
        })
        .catch(e => {
          if (e instanceof FormError) {
            setErrors(e.errors);
          }
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [setUserId]
  );

  return (
    <LoginForm>
      <Formik
        initialValues={{
          email: "",
          password: "",
          rememberMe: true
        }}
        onSubmit={handleSubmit}
        component={LoginFormContainer}
      />
    </LoginForm>
  );
}

const mapDispatchToProps = dispatch => ({
  setUserId: id => dispatch(setUserId(id))
});

export default connect(
  null,
  mapDispatchToProps
)(Login);
