import React from "react";
import PropTypes from "prop-types";
import styles from "./TeamMemberHeader.module.css";

function TeamMemberHeader({ children }) {
  return <div className={styles.header}>{children}</div>;
}

TeamMemberHeader.propTypes = {
  status: PropTypes.string
};

export default TeamMemberHeader;
