import React, { useCallback } from "react";
import { Formik, Field } from "formik";
import connect from "react-redux/es/connect/connect";
import {
  Button,
  PopupHeader,
  Popup,
  PopupBody,
  PopupFooter,
  GutterField
} from "design-system-react";
import InputField from "components/InputField";
import { addIntegrationSlackId } from "modules/pages/settingsSlack";
import { api } from "utils/client";
import { FormError } from "utils/formError";

function AddModalSlackContainer({ toggle, isOpen, addIntegrationSlackId }) {
  const initialValues = {};

  const handleSubmit = useCallback(
    (values, { setSubmitting, setErrors, setFieldValue }) => {
      api.slack
        .create(values)
        .ready.then(response => {
          addIntegrationSlackId(response.data.data.id);
          setFieldValue("channelId", "");
          setFieldValue("token", "");
          toggle();
        })
        .catch(e => {
          if (e instanceof FormError) {
            setErrors(e.errors);
          }
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [addIntegrationSlackId, toggle]
  );

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      render={props => (
        <Popup isOpen={isOpen} onClose={toggle}>
          <PopupHeader>Add Slack</PopupHeader>
          <PopupBody>
            <GutterField>
              <Field
                label="Channel ID"
                name="channelId"
                component={InputField}
              />
            </GutterField>
            <Field label="Token" name="token" component={InputField} />
          </PopupBody>
          <PopupFooter>
            <Button onClick={props.submitForm} processing={props.isSubmitting}>
              Add
            </Button>
            <Button outline={true} onClick={toggle}>
              Cancel
            </Button>
          </PopupFooter>
        </Popup>
      )}
    />
  );
}

const mapDispatchToProps = dispatch => ({
  addIntegrationSlackId: ids => dispatch(addIntegrationSlackId(ids))
});

export default connect(
  null,
  mapDispatchToProps
)(AddModalSlackContainer);
