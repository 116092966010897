import React from "react";
import styles from "./DropFile.module.css";
import classNames from "classnames";
import PropTypes from "prop-types";

function DropFile({ children, active }) {
  const classes = classNames(styles.drop, active && styles.active);

  return (
    <div className={classes}>
      <div className={styles.wrapper}>
        <img src="/images/drop-icon.svg" alt="drop-icon" />
        <p className={styles.label}>Drop files here</p>
      </div>
    </div>
  );
}

DropFile.propTypes = {
  active: PropTypes.bool
};

DropFile.defaultProps = {
  active: false
};

export default DropFile;
