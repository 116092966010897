import React, { useContext } from "react";
import NavPanelContent from "components/NavPanel/NavPanelContent";
import NavPanel from "components/NavPanel";
import CollapseButton from "components/NavPanel/CollapseButton";
import { ToggableLayoutContext } from "components/ToggableLayout/ToggableLayoutContext";

function EndUserNavPanel() {
  const context = useContext(ToggableLayoutContext);

  return (
    <NavPanel>
      <NavPanelContent>
        <CollapseButton
          handleClick={context.toggleSubmenu}
          isOpen={context.isSubmenuOpened}
        />
      </NavPanelContent>
    </NavPanel>
  );
}

export default EndUserNavPanel;
