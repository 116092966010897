import { useEffect, useState } from "react";
import { Select } from "antd";
import EntityAvatar from "components/entityAvatar/EntityAvatar";
import { api } from "utils/client";
import { extractFirstLetters } from "utils/string";

export interface SelectTeamMemberInputProps {
  mode?: "multiple";
  value?: any;
}

function SelectTeamMemberInput({
  mode,
  value,
  ...props
}: SelectTeamMemberInputProps) {
  const [teamMembers, setTeamMembers] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    api.teamMembers
      .allForFilter()
      .ready.then((response: any) => {
        setTeamMembers(response.data.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Select
      mode={mode}
      filterOption={(input, option) => {
        if (!option) {
          return false;
        }

        return (
          (option.label as string)
            .toLowerCase()
            .indexOf(input.toLocaleLowerCase()) >= 0
        );
      }}
      loading={loading}
      disabled={loading}
      showSearch
      value={teamMembers.length > 0 ? value : null}
      {...props}
    >
      {teamMembers.map((teamMember: any) => (
        <Select.Option
          key={teamMember.id}
          value={teamMember.id}
          label={teamMember.name}
        >
          <EntityAvatar
            avatarSize={18}
            avatarSrc={teamMember.avatar}
            avatarBackground={teamMember.nameColor}
            avatarTitle={extractFirstLetters(teamMember.name, 1)}
            title={teamMember.name}
          />
        </Select.Option>
      ))}
    </Select>
  );
}

export default SelectTeamMemberInput;
