import React, { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  Table,
  TableHeader,
  TableHeaderColumn,
  TableHeaderColumnMenu,
  TableBody,
  Col,
  Preloader,
  Scrollbar,
  Container
} from "design-system-react";
import {
  MODAL_DEFAULT,
  MODAL_ERROR,
  MODAL_PROCESS,
  MODAL_SUCCESS,
  showConfirmModal,
  updateConfirmModal
} from "utils/modalManager";
import { api } from "utils/client";
import { CommonError } from "utils/commonError";
import EmptyState from "components/EmptyState";
import TableRowContainer from "pages/SettingsSalesCardSetups/containers/TableRowContainer";
import { getPageState } from "pages/SettingsSalesCardSetups/selectors";
import { setSalesCardSetupIds } from "modules/pages/settingsSalesCardSetups";
import EditFormContainer from "pages/SettingsSalesCardSetups/containers/EditFormContainer";

function TableContainer() {
  const [loading, setLoading] = useState(true);
  const [editModal, setEditModal] = useState({
    opened: false,
    selectedSalesCardSetup: null
  });

  const handleEditModal = useCallback((opened, selectedSalesCardSetup) => {
    setEditModal({
      opened,
      selectedSalesCardSetup
    });
  }, []);

  const toggleEditModal = useCallback(() => {
    handleEditModal(!editModal.opened, null);
    // eslint-disable-next-line
  }, [editModal]);

  const closeEditModal = useCallback(() => {
    handleEditModal(false, null);
    // eslint-disable-next-line
  }, [editModal]);

  const dispatch = useDispatch();

  const { ids } = useSelector(getPageState);

  const handleActionDropdown = useCallback(
    (setupId, action) => {
      switch (action) {
        case "delete":
          const modalId = showConfirmModal(
            MODAL_DEFAULT,
            "Are you sure you want to delete setup?",
            {
              actionButtonText: "Delete",
              onAction: () => {
                updateConfirmModal(modalId, MODAL_PROCESS, "Deleting...");

                api.salesCardSetup
                  .delete(setupId)
                  .ready.then(() => {
                    dispatch(
                      setSalesCardSetupIds(ids.filter(id => id !== setupId))
                    );

                    updateConfirmModal(
                      modalId,
                      MODAL_SUCCESS,
                      "Setup was successfully deleted"
                    );
                  })
                  .catch(e => {
                    const errorMessage = "Ooops, something went wrong";

                    updateConfirmModal(
                      modalId,
                      MODAL_ERROR,
                      e instanceof CommonError ? e.error : errorMessage
                    );
                  });
              }
            }
          );
          break;

        case "edit":
          handleEditModal(true, setupId);
          break;

        default:
          break;
      }
    },
    // eslint-disable-next-line
    [ids, setSalesCardSetupIds]
  );

  useEffect(() => {
    setLoading(true);
    dispatch(setSalesCardSetupIds([]));

    const request = api.salesCardSetup.all();
    request.ready
      .then(response => {
        dispatch(
          setSalesCardSetupIds(response.data.data.map(member => member.id))
        );
        setLoading(false);
      })
      .catch(error => {
        if (!axios.isCancel(error)) {
          setLoading(false);
        }
      });

    return () => {
      request.cancelRequest.cancel();
    };
    // eslint-disable-next-line
  }, [setSalesCardSetupIds]);

  if (loading) {
    return (
      <Col align="center" justified>
        <Preloader size="32" color="#807d7f" />
      </Col>
    );
  }

  if (ids.length === 0) {
    return (
      <Col align="center" verticalAlign="center" justified>
        <EmptyState title="No settings yet" />
      </Col>
    );
  }

  return (
    <>
      <Scrollbar>
        <Container>
          <Table headerBorder>
            <TableHeader fixed>
              <TableHeaderColumn justified>Title</TableHeaderColumn>
              <TableHeaderColumn>Edited</TableHeaderColumn>
              <TableHeaderColumnMenu />
            </TableHeader>
            <TableBody>
              {ids.map(id => (
                <TableRowContainer
                  key={id}
                  setupId={id}
                  handleActionDropdown={handleActionDropdown}
                />
              ))}
            </TableBody>
          </Table>
        </Container>
      </Scrollbar>
      <EditFormContainer
        isOpen={editModal.opened}
        toggle={toggleEditModal}
        close={closeEditModal}
        setupId={editModal.selectedSalesCardSetup}
      />
    </>
  );
}

export default TableContainer;
