import { withNamespace } from "utils/namespace";

const type = withNamespace("pages/settingsEmail");

export const SET_CHANNEL_EMAIL_IDS = type("SET_CHANNEL_EMAIL_IDS");
export const ADD_CHANNEL_EMAIL_ID = type("ADD_CHANNEL_EMAIL_ID");

let initialState = {
  ids: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_CHANNEL_EMAIL_IDS:
      return {
        ...state,
        ids: action.payload.ids
      };
    case ADD_CHANNEL_EMAIL_ID:
      return {
        ...state,
        ids: [action.payload.id, ...state.ids]
      };
    default:
      return state;
  }
}

// actions
export function setChannelEmailIds(ids) {
  return {
    type: SET_CHANNEL_EMAIL_IDS,
    payload: {
      ids
    }
  };
}

export function addChannelEmailId(id) {
  return {
    type: ADD_CHANNEL_EMAIL_ID,
    payload: {
      id
    }
  };
}
