import { withNamespace } from "utils/namespace";

const type = withNamespace("pages/settingsBlingerMessenger");

export const SET_CHANNEL_BLINGER_MESSENGER_IDS = type(
  "SET_CHANNEL_BLINGER_MESSENGER_IDS"
);

export const ADD_CHANNEL_BLINGER__ID = type("ADD_CHANNEL_BLINGER__ID");

let initialState = {
  ids: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_CHANNEL_BLINGER_MESSENGER_IDS:
      return {
        ...state,
        ids: action.payload.ids
      };
    case ADD_CHANNEL_BLINGER__ID:
      return {
        ...state,
        ids: [action.payload.id, ...state.ids]
      };
    default:
      return state;
  }
}

// actions
export function setChannelBlingerMessengerIds(ids) {
  return {
    type: SET_CHANNEL_BLINGER_MESSENGER_IDS,
    payload: {
      ids
    }
  };
}

export function addChannelBlingerId(id) {
  return {
    type: ADD_CHANNEL_BLINGER__ID,
    payload: {
      id
    }
  };
}
