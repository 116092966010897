import { useCallback, useState, useMemo } from "react";
import axios, { CancelTokenSource } from "axios";
import useSortArray from "./useSortArray";

interface InfinitePaginationProps {
  query: (
    params: any
  ) => {
    ready: Promise<any>;
    cancelRequest: CancelTokenSource;
  };
  pageSize?: number;
  defaultOrder?: {
    field: string;
    direction: "asc" | "desc";
  };
}

export default function useInfinitePagination({
  query,
  pageSize = 30,
  defaultOrder = {
    field: "updatedAt",
    direction: "desc"
  }
}: InfinitePaginationProps) {
  const [data, setData] = useState<Array<any>>([]);
  const [total, setTotal] = useState<number | null>(null);
  const [nextCursor, setNextCursor] = useState(null);
  const [nextLoading, setNextLoading] = useState(false);
  const [hasMoreNextItems, setHasMoreNextItems] = useState(true);
  const [order, setOrder] = useState(defaultOrder);

  const sorted = useSortArray(data, order.field, order.direction);

  const fetchNext = useCallback(
    (params = null, cursor = null) => {
      setNextLoading(true);
      setTotal(null);

      if (cursor) {
        params = { ...params, cursor: cursor };
      } else {
        setData([]);
        setNextCursor(null);
        setHasMoreNextItems(true);
      }

      const request = query(params);

      request.ready
        .then(response => {
          const count = response.data.data.length;

          if (count < pageSize) {
            setHasMoreNextItems(false);
          }

          setData(prevData => [...prevData, ...response.data.data]);
          setNextCursor(response.data.cursor.next);
          setNextLoading(false);
          setHasMoreNextItems(true);
          setTotal(response.data.total || null);
        })
        .catch((error: any) => {
          if (!axios.isCancel(error)) {
            setNextLoading(false);
          }
        });

      return request;
    },
    [query, pageSize]
  );

  return useMemo(() => {
    return {
      data,
      setData,
      sorted,
      total,
      order,
      nextLoading,
      nextCursor,
      hasMoreNextItems,
      setOrder,
      fetchNext
    };
  }, [
    data,
    sorted,
    total,
    order,
    nextCursor,
    nextLoading,
    hasMoreNextItems,
    fetchNext
  ]);
}
