import { useEffect, useCallback, useMemo } from "react";
import { api } from "utils/client";
import DateTime from "components/DateTime";
import EntityAvatar from "components/entityAvatar/EntityAvatar";
import InfinityScrollPagination from "components/InfinityScrollPagination";
import Table from "components/table/Table";
import { extractFirstLetters } from "utils/string";
import { Tag, Modal, message } from "antd";
import {
  subscribe,
  unsubscribe,
  WS_EVENT_END_USER_UPDATED
} from "utils/websocket";
import {
  getEndUserQualificationColor,
  getEndUserQualificationTitle
} from "helpers/endUsers";
import history from "utils/history";
import { TeamOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { channelSmIcon } from "helpers/channelIcon";
import { merge } from "lodash";
import BulkUpdateEndUsersModal from "components/bulkUpdateEndUsersModal/BulkUpdateEndUsersModal";
import uuidv4 from "utils/uuidv4";
import { useSelector } from "react-redux";
import {
  currentUserRoleNameSelector,
  currentUserTeamIdsSelector
} from "selectors/role";
import { ROLE_AGENT, ROLE_TEAM_MANAGER } from "utils/permissions";
import { currentUserIdSelector } from "selectors/teamMember";

function EndUsersListContainer({
  view,
  filter,
  visibleBulkUpdateModal,
  setVisibleBulkUpdateModal,
  pagination,
  bulkActions,
  onBulkActionsToggle,
  selectedEndUsers,
  onSelect
}) {
  const currentUserId = useSelector(currentUserIdSelector);
  const currentUserRoleName = useSelector(currentUserRoleNameSelector);
  const currentUserTeamIds = useSelector(currentUserTeamIdsSelector);

  const fetchEndUsers = useCallback(
    (cursor = null) => {
      let params = {
        view: view,
        filter: filter && filter.filters
      };

      if (pagination.order.field) {
        params.orderField = pagination.order.field;
        params.orderDirection = pagination.order.direction;
      }

      return pagination.fetchNext(params, cursor);
    },
    // eslint-disable-next-line
    [view, pagination.order.field, pagination.order.direction, filter]
  );

  useEffect(() => {
    const request = fetchEndUsers();

    return () => {
      request.cancelRequest.cancel();
    };
  }, [fetchEndUsers]);

  const showConfirmModal = useCallback((onOk, selectedEndUsersCount) => {
    Modal.confirm({
      title:
        selectedEndUsersCount + " contact(s) will be edited. Are you sure?",
      icon: <ExclamationCircleOutlined />,
      okText: "Confirm",
      cancelText: "Cancel",
      onOk
    });
  }, []);

  const handleWebsocketEndUserUpdated = useCallback(
    data => {
      if (data.id) {
        pagination.setData(prevEndUsers =>
          prevEndUsers.map(endUser => {
            if (endUser.id === data.id) {
              return merge(endUser, data);
            }

            return endUser;
          })
        );
      }
    },
    // eslint-disable-next-line
    [pagination.data]
  );

  const nonSeletableItems = useMemo(() => {
    let items = [];
    pagination.data.map(item => {
      if (item.id) {
        let disabled = false;

        if (currentUserRoleName === ROLE_AGENT) {
          if (
            item.ownerTeamMember &&
            item.ownerTeamMember.id !== currentUserId
          ) {
            disabled = true;
          }
        }

        if (currentUserRoleName === ROLE_TEAM_MANAGER) {
          if (
            item.ownerTeam &&
            currentUserTeamIds.indexOf(item.ownerTeam.id) === -1
          ) {
            disabled = true;
          }
        }

        if (disabled) {
          items.push(item.id);
        }
      }

      return item;
    });

    return items;
  }, [pagination.data, currentUserRoleName, currentUserId, currentUserTeamIds]);

  useEffect(() => {
    subscribe(WS_EVENT_END_USER_UPDATED, handleWebsocketEndUserUpdated);

    return () => {
      unsubscribe(WS_EVENT_END_USER_UPDATED, handleWebsocketEndUserUpdated);
    };
  }, [handleWebsocketEndUserUpdated]);

  const columns = [
    {
      title: "Person",
      field: "name",
      sortable: pagination.order,
      width: "25%",
      render: (name, endUser) => {
        return (
          <EntityAvatar
            avatarSrc={endUser.avatar}
            avatarBackground={endUser.nameColor}
            avatarTitle={extractFirstLetters(endUser.name, 1)}
            avatarSize="large"
            title={endUser.name}
          />
        );
      },
      onClick: item => {
        history.push("/end-user/" + item.id);
      }
    },
    {
      title: "Status",
      field: "qualification",
      sortable: pagination.order,
      width: "15%",
      render: qualification => (
        <Tag color={getEndUserQualificationColor(qualification)}>
          {getEndUserQualificationTitle(qualification)}
        </Tag>
      )
    },
    {
      title: "Channel",
      field: "channels",
      sortable: pagination.order,
      width: "15%",
      render: (channels, endUser) => {
        return (
          <EntityAvatar
            avatarSrc={channelSmIcon(channels[0].type)}
            title={channels[0].title}
          />
        );
      }
    },
    {
      title: "Owner",
      field: "ownerTeamMember",
      sortable: pagination.order,
      width: "15%",
      render: teamMember => {
        return teamMember ? (
          <EntityAvatar
            avatarSrc={teamMember.avatar}
            avatarBackground={teamMember.nameColor}
            avatarTitle={extractFirstLetters(teamMember.name, 1)}
            title={teamMember.name}
          />
        ) : (
          <EntityAvatar title={"None"} />
        );
      },
      onClick: item => {
        if (item.ownerTeamMember) {
          history.push("/team-member/" + item.ownerTeamMember.id);
        }
      }
    },
    {
      title: "Team",
      field: "ownerTeam",
      sortable: pagination.order,
      width: "15%",
      render: team => {
        return team ? (
          <EntityAvatar
            avatarBackground={team.color}
            avatarTitle={extractFirstLetters(team.title, 1)}
            title={team.title}
          />
        ) : (
          <EntityAvatar avatarIcon={<TeamOutlined />} title={"None"} />
        );
      },
      onClick: item => {
        if (item.ownerTeam) {
          history.push("/teams/" + item.ownerTeam.id);
        }
      }
    },
    {
      title: "Last activity",
      field: "lastActivityAt",
      render: lastActivityAt => <DateTime time={lastActivityAt} />,
      sortable: pagination.order,
      width: "15%"
    }
  ];

  return (
    <>
      <BulkUpdateEndUsersModal
        visible={visibleBulkUpdateModal}
        onCancel={() => {
          setVisibleBulkUpdateModal(false);
        }}
        onOk={values => {
          showConfirmModal(() => {
            const id = uuidv4();

            message.loading({
              content: "Action in progress...",
              key: id
            });

            const params = {};

            if (typeof values.owner !== "undefined") {
              if (typeof values.owner === "string") {
                params.team = values.owner;
                params.teamMember = values.owner;
              } else {
                params.team = values.owner.team.id;
                params.teamMember = values.owner.teamMember.id;
              }
            }

            if (typeof values.qualification !== "undefined") {
              params.qualification = values.qualification;
            }

            api.endUsers
              .bulkUpdate(selectedEndUsers, params)
              .ready.then(response => {
                if (response.data > 0) {
                  // TODO: проверить elastic, почему есть задержка в получении данных после записи
                  setTimeout(() => {
                    if (fetchEndUsers) {
                      fetchEndUsers(null);
                    }
                  }, 1000);
                }

                message.success({
                  content:
                    response.data + " contact(s) were edited successfully!",
                  key: id
                });
              })
              .catch(() => {
                message.error({
                  content: "Something went wrong.",
                  key: id
                });
              });

            setVisibleBulkUpdateModal(false);
            onBulkActionsToggle();
          }, selectedEndUsers.length);
        }}
      />
      <InfinityScrollPagination
        nextLoading={pagination.nextLoading}
        hasMoreNextItems={pagination.hasMoreNextItems}
        onFetchNext={() => {
          fetchEndUsers(pagination.nextCursor);
        }}
      >
        <Table
          columns={columns}
          data={pagination.sorted.data}
          loading={pagination.nextLoading}
          hoverable
          fixedHeader
          selectedItems={bulkActions ? selectedEndUsers : null}
          nonSelectableItems={nonSeletableItems}
          onSelect={onSelect}
          onSort={field => {
            pagination.setOrder(prevOrder => {
              let direction = "asc";

              if (prevOrder.field === field) {
                if (prevOrder.direction === "desc") {
                  return { field: "", direction: "" };
                } else {
                  direction = "desc";
                }
              }

              return { field, direction };
            });
          }}
          onDisabledSelectClick={() => {
            message.warning("You can't operate this contact.");
          }}
        />
      </InfinityScrollPagination>
    </>
  );
}

export default EndUsersListContainer;
