import { withNamespace } from "utils/namespace";

const type = withNamespace("pages/settingsSalesCardSetup");

export const SET_SALES_CARD_SETUP_IDS = type("SET_SALES_CARD_SETUP_IDS");
export const ADD_SALES_CARD_SETUP_ID = type("ADD_SALES_CARD_SETUP_ID");
export const REMOVE_SALES_CARD_SETUP_ID = type("REMOVE_SALES_CARD_SETUP_ID");

let initialState = {
  ids: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_SALES_CARD_SETUP_IDS:
      return {
        ...state,
        ids: action.payload.ids
      };
    case ADD_SALES_CARD_SETUP_ID:
      return {
        ...state,
        ids: [action.payload.id, ...state.ids]
      };
    case REMOVE_SALES_CARD_SETUP_ID:
      return {
        ...state,
        ids: state.ids.filter(id => id !== action.payload.id)
      };
    default:
      return state;
  }
}

// actions
export function setSalesCardSetupIds(ids) {
  return {
    type: SET_SALES_CARD_SETUP_IDS,
    payload: {
      ids
    }
  };
}

export function addSalesCardSetupId(id) {
  return {
    type: ADD_SALES_CARD_SETUP_ID,
    payload: {
      id
    }
  };
}

export function removeSalesCardSetupId(id) {
  return {
    type: REMOVE_SALES_CARD_SETUP_ID,
    payload: {
      id
    }
  };
}
