import React from "react";
import PropTypes from "prop-types";
import styles from "./EndUserHeader.module.css";

function EndUserHeader({ children }) {
  return <div className={styles.header}>{children}</div>;
}

EndUserHeader.propTypes = {
  children: PropTypes.node
};

export default EndUserHeader;
