import React from "react";
import { translation_default } from "helpers/translations";
import { connect } from "react-redux";

function WidgetQualifyContainer({
  defaultLanguage,
  qualify,
  companyName,
  brandColor
}) {
  let autoMessage =
    qualify.autoMessage || translation_default.autoMessage[defaultLanguage];

  let qualifyName = (qualify.details && qualify.details.name) || false;
  let qualifyEmail = (qualify.details && qualify.details.email) || false;
  let qualifyPhone = (qualify.details && qualify.details.phone) || false;

  function translate(string) {
    return translation_default[string][defaultLanguage];
  }

  return (
    <div className="chat-container">
      <div className="chat-messages">
        {qualify.rule !== "neverAsk" && (
          <div className="left-messages-scope">
            <div className="left-message bot-left-message">
              <div className="message-avatar">
                <img src="/images/widget/bot-avatar.svg" alt="" />
              </div>
              <div className="message-container">
                <p>{autoMessage}</p>
                <div className="message-container-status">{companyName}</div>
              </div>
            </div>

            <div className="left-message bot-left-message">
              <div className="message-avatar">
                <img src="/images/widget/bot-avatar.svg" alt="" />
              </div>
              <div className="message-container-form">
                <div className="form-message">
                  Please, fill all fields below
                </div>
                <form>
                  {qualifyName && (
                    <div className="form-input-container">
                      <input
                        className="input"
                        type="text"
                        placeholder={translate("name") || "Name"}
                        required
                      />
                      <div className="input-message">Requied filed</div>
                    </div>
                  )}
                  {qualifyPhone && (
                    <div className="form-input-container">
                      <input
                        className="input"
                        type="phone"
                        placeholder={translate("phoneNumber") || "Phone"}
                        required
                      />
                      <div className="input-message">Requied filed</div>
                    </div>
                  )}
                  {qualifyEmail && (
                    <div className="form-input-container">
                      <input
                        className="input"
                        type="email"
                        placeholder={translate("email") || "Email"}
                        required
                      />
                      <div className="input-message">Requied filed</div>
                    </div>
                  )}
                  <div className="agree-checkbox">
                    <label className="agree-checkbox-container">
                      <input type="checkbox" />
                      <div className="agree-checkbox-checkmark">
                        <img src="/images/widget/check-agree.svg" alt="" />
                      </div>
                    </label>
                    <span>
                      {(qualify.privacyPolicy && qualify.privacyPolicy.title) ||
                        translate("privacyPolicy") ||
                        "I agree with the Privacy Policy"}
                    </span>
                  </div>
                  <button className="chat-send-button disabled">Send</button>
                </form>

                <div className="message-container-status">{companyName}</div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className={`powered-by`}>
        <span>Powered by</span>
        <a
          href="https://blinger.io/"
          style={{
            color: brandColor
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg
            className="copy-icon"
            xmlns="http://www.w3.org/2000/svg"
            width="7"
            height="12"
            viewBox="0 0 7 12"
          >
            <path
              fill={brandColor}
              d="M453,1064h-3v-6l-4,6h3v6.01Z"
              transform="translate(-446 -1058)"
            />
          </svg>
          blinger.io
        </a>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    qualify: state.widgetSetup.current.qualify,
    brandColor: state.widgetSetup.current.style.brandColor || "#d4d5d9",
    companyName: state.widgetSetup.current.introduce.company || "Company name"
  };
};

export default connect(mapStateToProps)(WidgetQualifyContainer);
