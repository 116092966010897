import merge from "lodash/merge";
import { withNamespace } from "utils/namespace";

const type = withNamespace("data");

export const UPDATE = type("UPDATE");
export const UPDATE_ATTRIBUTES = type("UPDATE_ATTRIBUTES");
export const UPDATE_RELATIONSHIP = type("UPDATE_RELATIONSHIP");

const initialState = {};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE:
      let updateState = { ...state };

      Object.keys(action.payload.data).map(key => {
        updateState[key] = { ...updateState[key], ...action.payload.data[key] };
        return key;
      });

      return updateState;
    case UPDATE_ATTRIBUTES:
      if (
        state[action.payload.entityType] &&
        state[action.payload.entityType][action.payload.entityId]
      ) {
        return merge({}, state, {
          [action.payload.entityType]: {
            [action.payload.entityId]: {
              attributes: action.payload.attributes
            }
          }
        });
      }

      return state;
    case UPDATE_RELATIONSHIP:
      if (
        state[action.payload.entityType] &&
        state[action.payload.entityType][action.payload.entityId]
      ) {
        return merge({}, state, {
          [action.payload.entityType]: {
            [action.payload.entityId]: {
              relationships: {
                [action.payload.relationshipName]: {
                  data: {
                    id: action.payload.relationshipEntityId,
                    type: action.payload.relationshipType
                  }
                }
              }
            }
          }
        });
      }

      return state;
    default:
      return state;
  }
}

export function updateNormalizedData(data) {
  return {
    type: UPDATE,
    payload: {
      data
    }
  };
}

export function updateEntityAttributes(entityType, entityId, attributes) {
  return {
    type: UPDATE_ATTRIBUTES,
    payload: {
      entityType,
      entityId,
      attributes
    }
  };
}

export function updateEntityRelations(
  entityType,
  entityId,
  relationshipName,
  relationshipType,
  relationshipEntityId
) {
  return {
    type: UPDATE_RELATIONSHIP,
    payload: {
      entityType,
      entityId,
      relationshipName,
      relationshipType,
      relationshipEntityId
    }
  };
}
