import { withNamespace } from "utils/namespace";
const type = withNamespace("pages/team");

// action types
export const SET_TEAM_MEMBER_FOR_ADD_IDS = type("SET_TEAM_MEMBER_FOR_ADD_IDS");
export const SET_TEAM_MEMBER_TEAM_IDS = type("SET_TEAM_MEMBER_TEAM_IDS");
export const REMOVE_TEAM_MEMBER_TEAM_ID = type("REMOVE_TEAM_MEMBER_TEAM_ID");
export const ADD_TEAM_MEMBER_TEAM_ID = type("ADD_TEAM_MEMBER_TEAM_ID");

let initialState = {
  userIds: [],
  forAddIds: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_TEAM_MEMBER_FOR_ADD_IDS:
      return {
        ...state,
        forAddIds: action.payload.ids
      };
    case SET_TEAM_MEMBER_TEAM_IDS:
      return {
        ...state,
        userIds: action.payload.ids
      };
    case ADD_TEAM_MEMBER_TEAM_ID:
      return {
        ...state,
        userIds: [action.payload.id, ...state.userIds]
      };
    case REMOVE_TEAM_MEMBER_TEAM_ID:
      return {
        ...state,
        userIds: state.userIds.filter(id => id !== action.payload.id)
      };
    default:
      return state;
  }
}

// actions
export function setTeamMemberForAddIds(ids) {
  return {
    type: SET_TEAM_MEMBER_FOR_ADD_IDS,
    payload: {
      ids
    }
  };
}

// actions
export function setTeamMemberTeamIds(ids) {
  return {
    type: SET_TEAM_MEMBER_TEAM_IDS,
    payload: {
      ids
    }
  };
}

export function removeTeamMemberTeamId(id) {
  return {
    type: REMOVE_TEAM_MEMBER_TEAM_ID,
    payload: {
      id
    }
  };
}

export function addTeamMemberTeamId(id) {
  return {
    type: ADD_TEAM_MEMBER_TEAM_ID,
    payload: {
      id
    }
  };
}
