import React from "react";
import styles from "./index.module.css";
import { extractFirstLetters } from "../../utils/string";
import { Avatar } from "design-system-react";
import classNames from "classnames";
import LastCaseMessageEmail from "../LastCaseMessageEmail";

function LastCaseMessage({
  type,
  content,
  teamMemberAvatar,
  teamMemberName,
  teamMemberNameColor,
  unread,
  caseItemSubject
}) {
  return (
    <div className={styles.wrapper}>
      {(teamMemberName || teamMemberAvatar) && (
        <div className={styles.avatar}>
          <Avatar
            image={teamMemberAvatar}
            text={extractFirstLetters(teamMemberName, 1)}
            size={"size16"}
            color={teamMemberNameColor}
          />
        </div>
      )}
      <div
        className={classNames(styles.contentWrapper, unread && styles.unread)}
      >
        {["file", "sticker", "contact"].indexOf(type) !== -1 && (
          <>
            <span className={styles.icon}>
              <img src="/images/files/file.svg" alt="" />
            </span>
            <span className={styles.content}>
              {(type === "sticker" && "Sticker") ||
                (type === "contact" && "Contact") ||
                content}
            </span>
          </>
        )}
        {type === "form" && (
          <span className={styles.content}>Qualifying details</span>
        )}
        {type === "location" && (
          <>
            <span className={styles.icon}>
              <img src="/images/files/Google_maps_40.svg" alt="" />
            </span>
            <span className={styles.content}>
              {`https://www.google.com/maps/?q=${
                JSON.parse(content).latitude
              },${JSON.parse(content).longitude}`}
            </span>
          </>
        )}
        {type === "email" && (
          <LastCaseMessageEmail
            content={content}
            caseItemSubject={caseItemSubject}
          />
        )}

        {["text", "note"].indexOf(type) !== -1 && (
          <span className={styles.content}>{content}</span>
        )}
      </div>
    </div>
  );
}

export default LastCaseMessage;
