import React from "react";
import connect from "react-redux/es/connect/connect";
import { Link } from "react-router-dom";
import { TEAM_MEMBER_STATUS_PENDING } from "helpers/users";
import {
  removeTeamMemberId,
  setTeamMemberIds,
  addTeamMemberIds
} from "../../../modules/pages/teamMembers";
import { TableRow, TableColumn, TableColumnMenu } from "design-system-react";
import DateTime from "../../../components/DateTime";
import TeamUsersListContact from "../components/TeamUsersListContact";
import { teamMemberActionItems } from "../../../helpers/teamMembers";
import { setTeamMemberViewIds } from "modules/teamMemberViews";
import TeamMemberTeamsContainer from "./TeamMemberTeamsContainer";
import View from "components/View";

function TeamMembersListItemContainer({
  teamMember,
  role,
  view,
  scrollbarHeight,
  currentUserId,
  handleActionDropdown
}) {
  return (
    <TableRow
      key={teamMember.id}
      {...teamMember.status !== TEAM_MEMBER_STATUS_PENDING && {
        tag: Link,
        to: "/team-member/" + teamMember.id
      }}
    >
      <TableColumn justified fixed>
        <TeamUsersListContact
          avatar={teamMember.avatar}
          status={teamMember.status}
          color={teamMember.nameColor}
          name={teamMember.name}
          email={teamMember.email}
        />
      </TableColumn>
      <TableColumn>
        {teamMember.pin}
        {teamMember.pin && teamMember.pinConfirmed && <View marginLeft="4px" color={"#51b164"}>Confirmed</View>}
      </TableColumn>
      {view !== "deleted" && (
        <TableColumn>
          <TeamMemberTeamsContainer teamIds={teamMember.teams} />
        </TableColumn>
      )}
      <TableColumn>
        <DateTime time={teamMember.createdAt} />
      </TableColumn>
      <TableColumn>{role.title}</TableColumn>
      <TableColumnMenu
        containerHeight={scrollbarHeight}
        isEmpty={teamMember.id === currentUserId}
        items={teamMemberActionItems(teamMember.status, teamMember.deletedAt)}
        onItemClick={key => {
          handleActionDropdown(teamMember, key);
        }}
      />
    </TableRow>
  );
}

const mapStateToProps = (state, props) => {
  const teamMember = state.entities.teamMembers[props.teamMemberId];

  return {
    teamMember,
    role: state.entities.roles[teamMember.role],
    currentUserId: state.auth.userId
  };
};

const mapDispatchToProps = dispatch => ({
  setTeamMemberIds: ids => dispatch(setTeamMemberIds(ids)),
  addTeamMemberIds: ids => dispatch(addTeamMemberIds(ids)),
  removeTeamMemberId: id => dispatch(removeTeamMemberId(id)),
  setTeamMemberViewIds: ids => dispatch(setTeamMemberViewIds(ids))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamMembersListItemContainer);
