import { connect } from "react-redux";
import AssignDropdownToggle from "../components/AssignDropdown/AssignDropdownToggle";

const mapStateToProps = (state, props) => {
  const team = props.teamId && state.entities.teams[props.teamId];
  const teamMember =
    props.teamMemberId && state.entities.teamMembers[props.teamMemberId];

  return {
    teamTitle: team && team.title,
    teamColor: team && team.color,
    teamMemberName: teamMember && teamMember.name,
    teamMemberNameColor: teamMember && teamMember.nameColor,
    teamMemberAvatar: teamMember && teamMember.avatar
  };
};

export default connect(mapStateToProps)(AssignDropdownToggle);
