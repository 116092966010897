import React from "react";
import { TEAM_MEMBER_STATUS_PENDING } from "../../../helpers/users";
import { Link } from "react-router-dom";
import { teamMemberActionItems } from "../../../helpers/teams";
import { connect } from "react-redux";
import TeamUsersListContact from "../../TeamMembers/components/TeamUsersListContact";
import { TableRow, TableColumn, TableColumnMenu } from "design-system-react";
import DateTime from "../../../components/DateTime";

function TeamMemberItemContainer({
  member,
  role,
                                   currentUserRoleName,
                                   currentUserIsMemberTeam,
  handleDeleteFromTeam
}) {
  return (
    <TableRow
      key={member.id}
      {...member.status !== TEAM_MEMBER_STATUS_PENDING && {
        tag: Link,
        to: "/team-member/" + member.id
      }}
    >
      <TableColumn isAvatar justified fixed>
        <TeamUsersListContact
          avatar={member.avatar}
          status={member.status}
          color={member.nameColor}
          name={member.name}
          email={member.email}
        />
      </TableColumn>
      <TableColumn>
        <DateTime time={member.createdAt} />
      </TableColumn>
      <TableColumn>{role && role.title}</TableColumn>
      <TableColumnMenu
        items={teamMemberActionItems(currentUserRoleName, currentUserIsMemberTeam)}
        onItemClick={() =>
          handleDeleteFromTeam(member.id, member.name || member.email)
        }
      />
    </TableRow>
  );
}

function mapStateToProps(state, props) {
  const member = state.entities.teamMembers[props.teamMemberId];
  return {
    member,
    role: state.entities.roles[member.role]
  };
}

export default connect(mapStateToProps)(TeamMemberItemContainer);
