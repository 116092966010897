import React, { useState, useCallback } from "react";
import SettingsChannelHeader from "components/SettingsHeader";
import PipedriveListContainer from "pages/SettingsPipedrive/containers/PipedriveListContainer";
import AddModalContainer from "pages/SettingsPipedrive/containers/AddModalContainer";
import { Row, Col, Container } from "design-system-react";

function SettingsPipedriveContainer() {
  const [addModalOpen, setAddModalOpen] = useState(false);

  const toggleAddModal = useCallback(() => {
    setAddModalOpen(!addModalOpen);
  }, [addModalOpen]);

  return (
    <Row fullHeight column>
      <Container>
        <SettingsChannelHeader
          title="Pipedrive"
          logo="/images/pipedrive-logo.svg"
          onAction={toggleAddModal}
        >
          Pipedrive is a web-based Sales CRM and pipeline management solution that enables businesses to plan their sales activities and monitor deals.
        </SettingsChannelHeader>
      </Container>
      <Col justified>
        <PipedriveListContainer />
      </Col>
      <AddModalContainer
        isOpen={addModalOpen}
        toggle={toggleAddModal}
      />
    </Row>
  );
}

export default SettingsPipedriveContainer;
