import { withNamespace } from "utils/namespace";

const type = withNamespace("analyticsCases");

// action types
export const SET_CHANNEL_IDS = type("SET_CHANNEL_IDS");

let initialState = {
  channelIds: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_CHANNEL_IDS:
      return {
        ...state,
        channelIds: action.payload.channelIds
      };

    default:
      return state;
  }
}

// actions
export function setChannelIds(channelIds) {
  return {
    type: SET_CHANNEL_IDS,
    payload: {
      channelIds
    }
  };
}
