import React from "react";
import TeamUserDetailsContainer from "../containers/TeamUserDetailsContainer";
import styles from "./TeamUserSidebar.module.css";
import PropTypes from "prop-types";
import TeamUserSidebarContent from "./TeamUserSidebarContent";
import { Scrollbar, SidePanel } from "design-system-react";

function TeamUserSidebar({
  role,
  email,
  pin,
  status,
  added,
  updated,
  lastActivityAt,
  userExtraFields,
  userAid,
  teams,
  showInSidePanel,
  isOpenDetailsSidePanel,
  toggleDetailsSidePanel
}) {
  return (
    <>
      {showInSidePanel ? (
        <SidePanel
          isOpen={isOpenDetailsSidePanel}
          onClose={toggleDetailsSidePanel}
          width={320}
          noPadding
        >
          <Scrollbar>
            <div className={styles.sidebar}>
              <TeamUserSidebarContent>
                <TeamUserDetailsContainer
                  role={role}
                  email={email}
                  pin={pin}
                  teams={teams}
                  userAid={userAid}
                  userExtraFields={userExtraFields}
                  status={status}
                  added={added}
                  updated={updated}
                  lastActivityAt={lastActivityAt}
                />
              </TeamUserSidebarContent>
            </div>
          </Scrollbar>
        </SidePanel>
      ) : (
        <div className={styles.sidebar}>
          <Scrollbar>
            <TeamUserSidebarContent>
              <TeamUserDetailsContainer
                role={role}
                email={email}
                pin={pin}
                teams={teams}
                userAid={userAid}
                userExtraFields={userExtraFields}
                status={status}
                added={added}
                updated={updated}
                lastActivityAt={lastActivityAt}
              />
            </TeamUserSidebarContent>
          </Scrollbar>
        </div>
      )}
    </>
  );
}

TeamUserSidebar.propTypes = {
  role: PropTypes.string,
  status: PropTypes.string,
  userId: PropTypes.number,
  lastActivityAt: PropTypes.number,
  updated: PropTypes.number,
  added: PropTypes.number
};

export default TeamUserSidebar;
