import React, { useState, useCallback } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownToggleDefault,
  DropdownMenu,
  DropdownItem
} from "design-system-react";

function SelectField({
  field,
  form,
  label,
  items,
  size,
  disabled,
  placeholder,
  value,
  renderItem
}) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleChange = useCallback(
    value => {
      setIsOpen(false);
      form.setFieldValue(field.name, value);
    },
    [field.name, form]
  );

  const selectedItem = items.find(i => i.value === field.value);
  const error = form.errors[field.name];

  return (
    <Dropdown
      isOpen={isOpen}
      toggle={toggle}
      validation={error && "error"}
      validationMessage={error}
      nonEmpty={Boolean(selectedItem)}
      size={size}
      disabled={disabled || !items.some(i => !i.hide)}
    >
      <DropdownToggle>
        <DropdownToggleDefault label={label} placeholder={placeholder}>
          {selectedItem &&
            (renderItem ? renderItem(selectedItem) : selectedItem.label)}
        </DropdownToggleDefault>
      </DropdownToggle>
      <DropdownMenu>
        {items
          .filter(i => !i.hide)
          .map(item => (
            <DropdownItem
              key={item.value}
              onClick={() => {
                handleChange(item.value);
              }}
              selected={selectedItem && selectedItem.value === item.value}
            >
              {renderItem ? renderItem(item) : item.label}
            </DropdownItem>
          ))}
      </DropdownMenu>
    </Dropdown>
  );
}

export default SelectField;
