import React, { useEffect, useCallback } from "react";
import ToggableLayout from "components/ToggableLayout";
import TeamMemberContainer from "./containers/TeamMemberContainer";
import TeamMembersSubMenuContainer from "../../containers/TeamMembersSubMenuContainer";

function TeamMember({ match }) {
  useEffect(() => {
    document.title = "Team";
  });

  const teamMemberId = match.params.id;
  const activeCaseId = match.params.case;

  const renderSubmenu = useCallback(
    () => <TeamMembersSubMenuContainer activeMenuItem={match.url} />,
    [match.url]
  );

  const renderContent = useCallback(
    () => (
      <TeamMemberContainer
        teamMemberId={teamMemberId}
        activeCaseId={activeCaseId}
      />
    ),
    [teamMemberId, activeCaseId]
  );

  return (
    <ToggableLayout
      renderSubmenu={renderSubmenu}
      renderContent={renderContent}
    />
  );
}

export default TeamMember;
