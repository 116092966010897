import React, { Component } from "react";
import AlertContainer from "../containers/AlertContainer";

class LoginLayout extends Component {
  render() {
    return (
      <React.Fragment>
        {this.props.children}
        <AlertContainer />
      </React.Fragment>
    );
  }
}

export default LoginLayout;
