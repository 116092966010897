import { withNamespace } from "utils/namespace";

const type = withNamespace("newCase");

// action types
export const SET_ENABLED_TEAM_IDS = type("SET_ENABLED_TEAM_IDS");
export const SET_CHANNEL_IDS = type("SET_CHANNEL_IDS");

let initialState = {
  enabledTeamIds: [],
  channelIds: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_ENABLED_TEAM_IDS:
      return {
        ...state,
        c: action.payload.ids
      };
    case SET_CHANNEL_IDS:
      return {
        ...state,
        channelIds: action.payload.ids
      };
    default:
      return state;
  }
}

// actions
export function setEnabledTeamIds(ids) {
  return {
    type: SET_ENABLED_TEAM_IDS,
    payload: {
      ids
    }
  };
}

export function setChannelIds(ids) {
  return {
    type: SET_CHANNEL_IDS,
    payload: {
      ids
    }
  };
}
