import React, { useState, useEffect, useCallback } from "react";
import {
  Table,
  TableHeader,
  TableHeaderColumn,
  TableHeaderColumnMenu,
  TableBody,
  Col,
  Preloader,
  Container,
  Scrollbar
} from "design-system-react";
import {
  MODAL_DEFAULT,
  MODAL_ERROR,
  MODAL_PROCESS,
  MODAL_SUCCESS,
  showConfirmModal,
  updateConfirmModal
} from "utils/modalManager";
import { api } from "utils/client";
import { CommonError } from "utils/commonError";
import { connect } from "react-redux";
import EmptyState from "components/EmptyState";
import axios from "axios";
import { setIntegrationPipedriveIds } from "modules/pages/settingsPipedrive";
import PipedriveListItemContainer from "pages/SettingsPipedrive/containers/PipedriveListItemContainer";

function PipedriveListContainer({
  integrationIds,
  setIntegrationPipedriveIds
}) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    setIntegrationPipedriveIds([]);

    const request = api.pipedrive.all();
    request.ready
      .then(response => {
        setIntegrationPipedriveIds(response.data.data.map(member => member.id));
        setLoading(false);
      })
      .catch(error => {
        if (!axios.isCancel(error)) {
          setLoading(false);
        }
      });

    return () => {
      request.cancelRequest.cancel();
    };
  }, [setIntegrationPipedriveIds]);

  const handleActionDropdown = useCallback((integrationId, action) => {
    if (action === "delete") {
      const modalId = showConfirmModal(
        MODAL_DEFAULT,
        "Are you sure you want to delete integration?",
        {
          actionButtonText: "Delete",
          onAction: () => {
            updateConfirmModal(modalId, MODAL_PROCESS, "Deleting...");

            api.pipedrive
              .delete(integrationId)
              .ready.then(() => {
                setIntegrationPipedriveIds(
                    integrationIds.filter(id => id !== integrationId)
                );

                updateConfirmModal(
                  modalId,
                  MODAL_SUCCESS,
                  "Integration was successfully deleted"
                );
              })
              .catch(e => {
                const errorMessage = "Ooops, something went wrong";

                updateConfirmModal(
                  modalId,
                  MODAL_ERROR,
                  e instanceof CommonError ? e.error : errorMessage
                );
              });
          }
        }
      );
    }
  }, [integrationIds, setIntegrationPipedriveIds]);

  if (loading && !integrationIds.length) {
    return (
      <Col align="center" justified>
        <Preloader size="32" color="#807d7f" />
      </Col>
    );
  }

  if (!integrationIds.length) {
    return (
      <Col align="center" verticalAlign="center" justified>
        <EmptyState title="No pipedrive integrations yet" />
      </Col>
    );
  }

  return (
    <Scrollbar>
      <Container>
        <Table headerBorder>
          <TableHeader fixed>
            <TableHeaderColumn justified>Company name</TableHeaderColumn>
            <TableHeaderColumn>Added</TableHeaderColumn>
            <TableHeaderColumnMenu />
          </TableHeader>
          <TableBody>
            {integrationIds.map(id => (
              <PipedriveListItemContainer
                key={id}
                integrationId={id}
                handleActionDropdown={handleActionDropdown}
              />
            ))}
          </TableBody>
        </Table>
      </Container>
    </Scrollbar>
  );
}

const mapStateToProps = state => {
  return { integrationIds: state.pages.settingsPipedrive.ids || [] };
};

const mapDispatchToProps = dispatch => ({
  setIntegrationPipedriveIds: ids => dispatch(setIntegrationPipedriveIds(ids))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PipedriveListContainer);
