import React from "react";
import styles from "./SettingsSection.module.css";
import PropTypes from "prop-types";

export default function SettingsSection({ title, children }) {
  return (
    <div className={styles.section}>
      <div className={styles.title}>{title}</div>
      <div className={styles.content}>{children}</div>
    </div>
  );
}

SettingsSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node
};
