import React from "react";
import styles from "pages/Cases/components/MessageList.module.css";
import classNames from "classnames";

function MessageList({ children, noPadding }) {
  const classes = classNames(styles.messages, noPadding && styles.noPadding);
  return <div className={classes}>{children}</div>;
}

export default React.memo(MessageList);
