import { message } from "antd";
import { api } from "utils/client";
import uuidv4 from "utils/uuidv4";
import AssignInput from "components/assignInput/AssignInput";

export interface EndUserOwnerInputProps {
  endUserId: string;
  selectedTeam: any;
  selectedTeamMember: any;
}

function EndUserOwnerInput({
  endUserId,
  selectedTeam,
  selectedTeamMember
}: EndUserOwnerInputProps) {
  return (
    <AssignInput
      placeholder="Choose owner"
      assignLabel=""
      value={{ team: selectedTeam, teamMember: selectedTeamMember }}
      disableTeamSelection
      prependOptions={[
        {
          label: <div style={{ pointerEvents: "none" }}>None</div>,
          value: "unassign",
          onSelect: () => {
            const id = uuidv4();

            message.loading({
              content: "Action in progress...",
              key: id
            });

            api.endUsers
              .updateOwner(endUserId, null, null)
              .ready.then(() => {
                message.success({
                  content: "Owner was deleted successfully.",
                  key: id
                });
              })
              .catch(() => {
                message.error({
                  content: "Something went wrong.",
                  key: id
                });
              });
          }
        }
      ]}
      onTeamsRequest={() => {
        return api.cases.teamsForAssign().ready.then((response: any) => {
          return response.data.data;
        });
      }}
      onTeamMembersRequest={teamId => {
        return api.cases
          .membersForAssignByTeam(teamId)
          .ready.then((response: any) => {
            return response.data.data;
          });
      }}
      onChange={({ team, teamMember }) => {
        if (team && teamMember) {
          const id = uuidv4();

          message.loading({
            content: "Action in progress...",
            key: id
          });

          let messageText = "Owner was added successfully.";

          if (selectedTeamMember) {
            messageText = "Owner was changed successfully.";
          }

          api.endUsers
            .updateOwner(endUserId, team.id, teamMember.id)
            .ready.then(() => {
              message.success({
                content: messageText,
                key: id
              });
            })
            .catch(() => {
              message.error({
                content: "Something went wrong.",
                key: id
              });
            });
        }
      }}
    />
  );
}

export default EndUserOwnerInput;
