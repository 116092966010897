import React from "react";
import { connect } from "react-redux";
import { extractFirstLetters } from "utils/string";
import { Avatar } from "design-system-react";
import MessageListItem from "pages/Cases/components/MessageListItem";
import MessageListItemAvatar from "pages/Cases/components/MessageListItemAvatar";
import MessageListItemBubbleWrapper from "../components/MessageListItemBubbleWrapper";
import DefaultMessageBubble from "../../../components/DefaultMessageBubble";
import FileMessageBubble from "../../../components/FileMessageBubble";

function OutgoingMessageListItemContainer({
  currentUserAvatar,
  currentUserName,
  currentUserNameColor,
  caseAid,
  message,
  firstInTimeGroup,
  lastInTimeGroup
}) {
  return (
    <MessageListItem
      id={message.id}
      firstInTimeGroup={firstInTimeGroup}
      lastInTimeGroup={lastInTimeGroup}
      reverse
    >
      <MessageListItemAvatar>
        {lastInTimeGroup && (
          <Avatar
            image={currentUserAvatar}
            text={extractFirstLetters(currentUserName, 2)}
            size="size32"
            color={currentUserNameColor}
          />
        )}
      </MessageListItemAvatar>

      <MessageListItemBubbleWrapper alignRight>
        {message.content && (
          <DefaultMessageBubble
            type={message.type}
            status={message.status}
            text={message.content}
            time={message.createdAt}
            outgoing
          />
        )}

        {message.files &&
          message.files.map(file => (
            <FileMessageBubble
              fileType={file.uploadedFile.type}
              fileWidth={file.uploadedFile.meta.width}
              fileHeight={file.uploadedFile.meta.height}
              fileUrl={file.uploadedFile.url["default"]}
              fileName={file.uploadedFile.info.name}
              fileSize={file.uploadedFile.info.size}
              fileExt={file.uploadedFile.info.name
                .match(/[0-9a-zA-Z]+$/)
                .toString()}
              fileSmallImageUrl={
                file.uploadedFile.type === "image" &&
                file.uploadedFile.url["default"]
              }
              outgoing
              status={message.status}
              time={message.createdAt}
            />
          ))}
      </MessageListItemBubbleWrapper>
    </MessageListItem>
  );
}

const mapStateToProps = (state, props) => {
  const currentUser = state.entities.teamMembers[state.auth.userId];

  return {
    currentUserAvatar: currentUser.avatar,
    currentUserName: currentUser.name,
    currentUserNameColor: currentUser.nameColor
  };
};

export default connect(mapStateToProps)(OutgoingMessageListItemContainer);
