import React, { useState, useCallback, useEffect } from "react";
import { extractFirstCharacters } from "../../../utils/string";
import history from "utils/history";
import { makeAnalyticUrl } from "../../../helpers/analytics";
import format from "date-fns/format";
import NavPanelContent from "components/NavPanel/NavPanelContent";
import NavPanel from "components/NavPanel";
import CollapseButton from "components/NavPanel/CollapseButton";
import {
  Button,
  GroupButtons,
  DatePickerRange,
  Dropdown,
  DropdownToggle,
  DropdownToggleDefault,
  DropdownItemWithAvatar,
  DropdownMenu,
  DropdownItem,
  Avatar,
  DropdownMenuDivider
} from "design-system-react";
import DatePickerWrapper from "../components/DatePickerWrapper";
import PanelDropdownWrapper from "../components/PanelDropdownWrapper";
import { TEAM_MEMBER_STATUS_PENDING } from "../../../helpers/users";
import { api } from "../../../utils/client";
import { setTeamIds } from "../../../modules/pages/analyticsTeams";
import { connect } from "react-redux";
import CollapseMenu from "../../../components/CollapseMenu";
import { createSelector } from "reselect";

const groupButtons = [
  { title: "Day", value: "day" },
  { title: "Week", value: "week" },
  { title: "Month", value: "month" }
];

function AnalyticsNavPanelContainer({
  onSubMenuToggle,
  isSubmenuOpened,
  loadingAnalytics,
  urlParams,
  teams,
  setTeamIds
}) {
  const [periodFrom, setPeriodFrom] = useState(
    urlParams.periodFrom && new Date(urlParams.periodFrom)
  );
  const [periodTo, setPeriodTo] = useState(
    urlParams.periodTo && new Date(urlParams.periodTo)
  );
  const [period, setPeriod] = useState(urlParams.period || "day");
  const [team, setTeam] = useState(urlParams.team || "");
  const [isTeamDropdownOpen, setIsTeamDropdownOpen] = useState(false);

  const periodItem = groupButtons.filter(i => i.value === period)[0];

  const toggleTeamDropdown = useCallback(() => {
    setIsTeamDropdownOpen(!isTeamDropdownOpen);
  }, [isTeamDropdownOpen]);

  const handleChangeDropdown = useCallback(value => {
    setTeam(value);
    setIsTeamDropdownOpen(false);
  }, []);

  const selectedTeam = teams.filter(item => item.id === team)[0];

  useEffect(() => {
    setTeamIds([]);

    api.teams.teamsForFilter().ready.then(response => {
      setTeamIds(response.data.data.map(team => team.id));
    });
  }, [setTeamIds]);

  const handleChangePeriod = useCallback(
    groupButton => {
      setPeriod(groupButton.value);
      setPeriodFrom("");
      setPeriodTo("");

      history.push(
        makeAnalyticUrl("/analytics/teams", {
          ...urlParams,
          period: groupButton.value,
          periodFrom: "",
          periodTo: ""
        })
      );
    },
    [urlParams]
  );

  const handleChangeStartDate = useCallback(
    date => {
      setPeriodFrom(date);
      if (!periodTo && !date) {
        setPeriod("day");
        history.push(
          makeAnalyticUrl("/analytics/teams", {
            ...urlParams,
            period: "day",
            periodFrom: "",
            periodTo: ""
          })
        );
      }
    },
    [periodTo, urlParams]
  );

  const handleChangeEndDate = useCallback(
    date => {
      setPeriodTo(date);
      if (!date && !periodFrom) {
        setPeriod("day");
        history.push(
          makeAnalyticUrl("/analytics/teams", {
            ...urlParams,
            period: "day",
            periodFrom: "",
            periodTo: ""
          })
        );
      }
    },
    [periodFrom, urlParams]
  );

  useEffect(() => {
    if (periodFrom && periodTo && periodFrom <= periodTo) {
      setPeriod("");

      history.push(
        makeAnalyticUrl("/analytics/teams", {
          ...urlParams,
          period: "",
          periodFrom: format(periodFrom, "yyyy-MM-dd"),
          periodTo: format(periodTo, "yyyy-MM-dd")
        })
      );
    }
  }, [periodTo, periodFrom, urlParams]);

  const title =
    "Teams" +
    (!selectedTeam || !selectedTeam.title ? "" : ": " + selectedTeam.title);

  return (
    <NavPanel>
      <NavPanelContent>
        <CollapseButton
          handleClick={onSubMenuToggle}
          isOpen={isSubmenuOpened}
        />

        <CollapseMenu
          title={title}
          withoutDropdown={isSubmenuOpened}
          items={[
            {
              id: "cases",
              to: "/analytics/cases",
              active: false,
              title: "Cases"
            },
            {
              id: "teamMembers",
              to: "/analytics/team-members",
              active: false,
              title: "Team members"
            },
            {
              id: "teams",
              to: "/analytics/teams",
              active: true,
              title: "Teams"
            }
          ]}
        />
      </NavPanelContent>
      <NavPanelContent>
        <GroupButtons>
          {groupButtons.map(groupButton => (
            <Button
              size="sm"
              group={true}
              onClick={() => {
                handleChangePeriod(groupButton);
              }}
              active={periodItem && periodItem.value === groupButton.value}
            >
              {groupButton.title}
            </Button>
          ))}
        </GroupButtons>

        <DatePickerWrapper>
          <DatePickerRange
            now={true}
            size="sm"
            placeholderFrom="Start date"
            placeholderTo="End date"
            noLabel={true}
            startDate={periodFrom}
            endDate={periodTo}
            onChangeStartDate={handleChangeStartDate}
            onChangeEndDate={handleChangeEndDate}
          />
        </DatePickerWrapper>

        <PanelDropdownWrapper>
          <Dropdown
            isOpen={isTeamDropdownOpen}
            toggle={toggleTeamDropdown}
            nonEmpty={selectedTeam}
            size="sm"
          >
            <DropdownToggle>
              <DropdownToggleDefault>
                {!team && !selectedTeam && <>All teams</>}

                {selectedTeam && selectedTeam.title !== "" && (
                  <DropdownItemWithAvatar
                    renderAvatar={
                      <Avatar
                        size="size20"
                        text={extractFirstCharacters(selectedTeam.title, 1)}
                        background={selectedTeam.color}
                        color="#fff"
                        image={
                          selectedTeam.avatar &&
                          selectedTeam.avatar.url["200X200"]
                        }
                        pending={
                          selectedTeam.status === TEAM_MEMBER_STATUS_PENDING
                        }
                      />
                    }
                  >
                    {selectedTeam.title}
                  </DropdownItemWithAvatar>
                )}

                {selectedTeam && selectedTeam.title === "" && (
                  <>{selectedTeam.title}</>
                )}
              </DropdownToggleDefault>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={e => {
                  history.push(
                    makeAnalyticUrl("/analytics/teams", {
                      ...urlParams,
                      team: ""
                    })
                  );
                  handleChangeDropdown("");
                }}
              >
                All teams
              </DropdownItem>
              <DropdownMenuDivider />

              {teams.map(team => (
                <DropdownItem
                  onClick={e => {
                    history.push(
                      makeAnalyticUrl("/analytics/teams", {
                        ...urlParams,
                        team: team.id
                      })
                    );

                    handleChangeDropdown(team.id);
                  }}
                  selected={selectedTeam && selectedTeam.id === team.id}
                >
                  <DropdownItemWithAvatar
                    renderAvatar={
                      <Avatar
                        size="size20"
                        text={extractFirstCharacters(team.title, 1)}
                        background={team.color}
                        color="#fff"
                        pending={team.status === TEAM_MEMBER_STATUS_PENDING}
                      />
                    }
                  >
                    {team.title}
                  </DropdownItemWithAvatar>
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </PanelDropdownWrapper>
      </NavPanelContent>
    </NavPanel>
  );
}

const getTeams = createSelector(
  [state => state.entities.teams, state => state.pages.analyticsTeams.teamIds],
  (team, teamIds) => {
    return teamIds.map(id => team[id]);
  }
);

const mapStateToProps = state => {
  return {
    teams: getTeams(state)
  };
};

// const mapStateToProps = (state, props) => {
//   return {
//     teams: state.pages.analyticsTeams.ids
//   };
// };

const mapDispatchToProps = dispatch => ({
  setTeamIds: ids => dispatch(setTeamIds(ids))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnalyticsNavPanelContainer);
