import React, { useMemo, useCallback } from "react";
import { connect } from "react-redux";
import { useDropzone } from "react-dropzone";
import DropFile from "pages/Cases/components/DropFile";
import DropFileWrapper from "pages/Cases/components/DropFileWrapper";
import { uploadFile } from "modules/pages/cases/messageForm";

function DropFileContainer({ children, uploadFile }) {
  const onDrop = useCallback(
    acceptedFiles => {
      for (let i = 0; i < acceptedFiles.length; i++) {
        uploadFile(acceptedFiles[i]);
      }
      // Do something with the files
    },
    [uploadFile]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop
  });

  const dropZoneProps = useMemo(() => {
    return {
      ...getRootProps({
        onClick: e => e.stopPropagation()
      })
    };
    // eslint-disable-next-line
  }, []);

  return (
    <DropFileWrapper dropZoneProps={dropZoneProps}>
      {children}
      <DropFile active={isDragActive}>
        <input {...getInputProps()} />
        {isDragActive && (
          <div>Drag 'n' drop some files here, or click to select files</div>
        )}
      </DropFile>
    </DropFileWrapper>
  );
}

const mapDispatchToProps = dispatch => ({
  uploadFile: file => dispatch(uploadFile(file))
});

export default connect(
  null,
  mapDispatchToProps
)(DropFileContainer);
