import React, { useEffect } from "react";
import { FastField } from "formik";
import CheckboxField from "components/CheckboxField";
import AvailabilityFields from "pages/WidgetAvailability/components/AvailabilityFields";
import SelectField from "components/SelectField";
import AvailabilityInput from "pages/WidgetAvailability/components/AvailabilityInput";

const contains = [
  "00:00",
  "01:00",
  "02:00",
  "03:00",
  "04:00",
  "05:00",
  "06:00",
  "07:00",
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00",
  "23:59"
];

function WidgetAvailabilityScheduleContainer({
  day,
  initialStart,
  initialEnd,
  setFieldValue,
  isChecked
}) {
  useEffect(() => {
    if (isChecked) {
      setFieldValue(`schedule.${day}.start`, initialStart);
      setFieldValue(`schedule.${day}.end`, initialEnd);
    } else {
      setFieldValue(`schedule.${day}.start`, "");
      setFieldValue(`schedule.${day}.end`, "");
    }
  }, [isChecked, day, initialStart, initialEnd, setFieldValue]);

  return (
    <AvailabilityInput>
      <FastField
        name={`schedule.${day}.enabled`}
        labelText={day[0].toUpperCase() + day.slice(1)}
        isChecked={isChecked}
        component={CheckboxField}
      />
      {isChecked && (
        <AvailabilityFields>
          <FastField
            name={`schedule.${day}.start`}
            size="sm"
            component={SelectField}
            items={contains.map(item => {
              return { label: item, value: item };
            })}
          />
          <FastField
            name={`schedule.${day}.end`}
            size="sm"
            component={SelectField}
            items={contains.map(item => {
              return { label: item, value: item };
            })}
          />
        </AvailabilityFields>
      )}
    </AvailabilityInput>
  );
}

export default WidgetAvailabilityScheduleContainer;
