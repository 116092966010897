import React, { useCallback } from "react";
import { PhoneNumber } from "design-system-react";

function PhoneField({
  label,
  disabled,
  field: { name, value },
  form: { errors, setFieldValue }
}) {
  const handleClear = useCallback(() => {
    setFieldValue(name, "");
  }, [setFieldValue, name]);

  const handleChange = useCallback(
    value => {
      setFieldValue(name, value);
    },
    [setFieldValue, name]
  );

  let error = errors[name];

  return (
    <PhoneNumber
      name={name}
      label={label}
      value={value}
      onChange={handleChange}
      validation={error && "error"}
      message={error}
      onClearClick={handleClear}
      disabled={disabled}
    />
  );
}

export default PhoneField;
