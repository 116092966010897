import React, { useCallback } from "react";
import connect from "react-redux/es/connect/connect";
import {
  PopupHeader,
  Popup,
  PopupBody,
  PopupFooter,
  Button
} from "design-system-react";
import { api } from "utils/client";
import { FormError } from "utils/formError";
import { Field, Formik } from "formik";
import InputField from "components/InputField";
import { addIntegrationTelegramId } from "modules/pages/settingsTelegramInterface";

function ConfirmStepFormContainer({
  toggle,
  isOpen,
  phone,
  appId,
  appHash,
  addIntegrationTelegramId
}) {
  const handleSubmit = useCallback(
    (values, { setSubmitting, setErrors }) => {
      api.telegramInterface
        .confirm({ ...values, phone, appId, appHash })
        .ready.then(response => {
          addIntegrationTelegramId(response.data.data.id);
          toggle();
        })
        .catch(e => {
          if (e instanceof FormError) {
            setErrors(e.errors);
          }
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [addIntegrationTelegramId, phone, toggle, appId, appHash]
  );

  return (
    <Formik
      enableReinitialize
      initialValues={{
        code: ""
      }}
      onSubmit={handleSubmit}
      render={props => (
        <Popup isOpen={isOpen} onClose={toggle}>
          <PopupHeader>Add telegram interface</PopupHeader>
          <PopupBody>
            <Field
              name="code"
              type="text"
              label="Code"
              autoFocus={true}
              component={InputField}
            />
          </PopupBody>
          <PopupFooter>
            <Button
              onClick={props.submitForm}
              processing={props.isSubmitting}
              // disabled={submitDisabled}
            >
              Confirm
            </Button>
            <Button outline={true} onClick={toggle}>
              Cancel
            </Button>
          </PopupFooter>
        </Popup>
      )}
    />
  );
}

const mapDispatchToProps = dispatch => ({
  addIntegrationTelegramId: ids => dispatch(addIntegrationTelegramId(ids))
});

export default connect(
  null,
  mapDispatchToProps
)(ConfirmStepFormContainer);
