import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "pages/Cases/components/CaseLayout.module.css";

function CaseLayout({ renderMessages, renderDetails }) {
  const detailClasses = classNames(
    renderDetails && styles.details,
    "border-left"
  );

  return (
    <div className={styles.layout}>
      <div className={styles.messages}>{renderMessages}</div>
      <div className={detailClasses}>{renderDetails}</div>
    </div>
  );
}

CaseLayout.propTypes = {
  renderMessages: PropTypes.func.isRequired,
  renderDetails: PropTypes.func
};

export default CaseLayout;
