import { withNamespace } from "utils/namespace";
import { SET_CHANNEL_IDS } from "./analyticsCases";

const type = withNamespace("analyticsTeamMembers");

// action types
export const SET_TEAM_MEMBER_IDS = type("SET_TEAM_MEMBER_IDS");

let initialState = {
  teamMemberIds: [],
  channelIds: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_TEAM_MEMBER_IDS:
      return {
        ...state,
        teamMemberIds: action.payload.teamMemberIds
      };

    case SET_CHANNEL_IDS:
      return {
        ...state,
        channelIds: action.payload.channelIds
      };

    default:
      return state;
  }
}

// actions
export function setTeamMemberIds(teamMemberIds) {
  return {
    type: SET_TEAM_MEMBER_IDS,
    payload: {
      teamMemberIds
    }
  };
}

export function setChannelIds(channelIds) {
  return {
    type: SET_CHANNEL_IDS,
    payload: {
      channelIds
    }
  };
}
