import { useContext, useMemo, useState } from "react";
import {
  Menu as BaseMenu,
  MenuFooter,
  MenuList,
  MenuListItem,
  MenuContent,
  Tooltip
} from "design-system-react";
import AccountItem from "components/menu/AccountItem";
import {
  can,
  CASES_ANALYTICS_PERMISSION,
  END_USER_VIEW_PERMISSION,
  TEAM_MEMBER_ANALYTICS_PERMISSION,
  TEAM_MEMBER_VIEW_PERMISSION
} from "utils/permissions";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { ToggableLayoutContext } from "./ToggableLayout/ToggableLayoutContext";
import { CASES_VIEW_PERMISSION } from "../utils/permissions";
import WidgetMenuItem from "components/WidgetMenuItem";
import { SearchOutlined } from "@ant-design/icons";
import SearchModal from "./searchModal/SearchModal";

function Menu({ activeNavMenuItem, counters, currentUser }) {
  const [searchModalVisible, setSearchModalVisible] = useState(false);
  const context = useContext(ToggableLayoutContext);

  const items = useMemo(() => {
    return [
      {
        icon: casesIcon,
        tooltip: "Cases",
        linkTo: localStorage.getItem("/cases/:view") || "/cases/unassigned",
        isActive: activeNavMenuItem === "cases",
        visible: can(CASES_VIEW_PERMISSION)
      },
      {
        icon: usersIcon,
        tooltip: "Contacts",
        linkTo:
          localStorage.getItem("/end-users/:view(\\w+)") || "/end-users/active",
        isActive: activeNavMenuItem === "endUsers",
        visible: can(END_USER_VIEW_PERMISSION)
      },
      {
        icon: agentsIcon,
        tooltip: "Team members",
        linkTo:
          localStorage.getItem("teamMembersRoute") || "/team-members/enabled",
        isActive: activeNavMenuItem === "teamMembers",
        visible: can(TEAM_MEMBER_VIEW_PERMISSION)
      },
      {
        icon: analyticsIcon,
        tooltip: "Analytics",
        linkTo: localStorage.getItem("analiyticsRoute") || "/analytics/cases",
        isActive: activeNavMenuItem === "analytics",
        visible:
          can(CASES_ANALYTICS_PERMISSION) ||
          can(TEAM_MEMBER_ANALYTICS_PERMISSION)
      }
      // {
      //   icon: settingsIcon,
      //   tooltip: "Settings",
      //   linkTo:
      //     localStorage.getItem("lastSettingRoute") ||
      //     settingsMenu(currentUser)[0].linkTo,
      //   isActive: activeNavMenuItem === "settings",
      //   visible: true
      // }
    ].filter(i => i.visible);
    // eslint-disable-next-line
  }, [activeNavMenuItem]);

  return (
    <>
      <SearchModal
        visible={searchModalVisible}
        onClose={() => {
          setSearchModalVisible(false);
        }}
      />
      <BaseMenu>
        <MenuContent>
          <MenuList>
            <div
              style={{
                height: "80px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%"
              }}
            >
              <img src={"/images/logo-white.svg"} alt="" width={"50px"} />
            </div>
            <MenuListItem
              tag={Link}
              to="#"
              onClick={() => {
                setSearchModalVisible(true);
              }}
            >
              <SearchOutlined style={{ fontSize: "20px" }} />
              <Tooltip size="lg" dark position="right" offsetX={-12}>
                Search
              </Tooltip>
            </MenuListItem>
            {items.map(item => {
              return (
                <MenuListItem
                  tag={Link}
                  to={item.linkTo}
                  key={item.linkTo}
                  active={item.isActive}
                  counter={
                    (activeNavMenuItem !== "cases" ||
                      !context.isSubmenuOpened) &&
                    counters &&
                    item.linkTo &&
                    item.linkTo.startsWith("/cases")
                      ? counters.unassigned + counters.assigned
                      : 0
                  }
                >
                  {item.icon}
                  <Tooltip size="lg" dark position="right" offsetX={-12}>
                    {item.tooltip}
                  </Tooltip>
                </MenuListItem>
              );
            })}
          </MenuList>
        </MenuContent>
        <MenuFooter>
          {can(CASES_ANALYTICS_PERMISSION) && (
            <WidgetMenuItem
              to="/widget/introduce"
              active={activeNavMenuItem === "widget"}
            >
              {widgetIcon}
              <Tooltip size="lg" dark position="right" offsetX={-12}>
                Widget settings
              </Tooltip>
            </WidgetMenuItem>
          )}
          <MenuList>
            <AccountItem />
          </MenuList>
        </MenuFooter>
      </BaseMenu>
    </>
  );
}

// const logoIcon = (
//   <svg
//     xmlns="http://www.w3.org/2000/svg"
//     width="40"
//     height="40"
//     viewBox="0 0 40 40"
//   >
//     <g id="Ellipse_1083" data-name="Ellipse 1083">
//       <path
//         d="M2271,658c-21.29,0-20.06-19.368-19.95-20-0.11-.63-1.34-20,19.95-20s20.06,19.365,19.95,20.009C2291.06,638.628,2292.29,658,2271,658Zm-0.03-38c-19.13,0-18.03,17.432-17.93,18-0.1.57-1.2,18,17.93,18s18.03-17.434,17.93-17.992C2289,637.428,2290.1,620,2270.97,620Zm1,27a7.094,7.094,0,0,1-4.99-1.642V646a1,1,0,0,1-2,0v-5.268a4.906,4.906,0,0,1,0-.688v-0.089a4.891,4.891,0,0,1,0-.687V630a1,1,0,0,1,2,0v4.642a7.094,7.094,0,0,1,4.99-1.642c7.71,0,6.98,7.266,6.98,7S2279.68,647,2271.97,647Zm0-12c-4.34,0-4.94,3.215-4.99,4.488v1.024c0.05,1.273.65,4.488,4.99,4.488A5,5,0,0,0,2271.97,635Z"
//         transform="translate(-2251 -618)"
//       />
//     </g>
//   </svg>
// );

export const casesIcon = (
  <svg
    id="Layer_2"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
  >
    <defs />
    <tooltip>Cases</tooltip>
    <g id="Ellipse_1083" data-name="Ellipse 1083">
      <path
        d="M13.5,8h-9a.5.5,0,0,0,0,1h9a.5.5,0,0,0,0-1Zm0-4h-9a.5.5,0,0,0,0,1h9a.5.5,0,0,0,0-1ZM14,0H4A4,4,0,0,0,0,4V14a4,4,0,0,0,4,4H14a4,4,0,0,0,4-4V4A4,4,0,0,0,14,0Zm3,14a3,3,0,0,1-3,3H4a3,3,0,0,1-3-3V13H7a2,2,0,0,0,4,0h6Zm0-2H10.5a.5.5,0,0,0-.5.5V13a1,1,0,0,1-2,0v-.5a.5.5,0,0,0-.5-.5H1V4A3,3,0,0,1,4,1H14a3,3,0,0,1,3,3Z"
        transform="translate(0 0)"
      />
    </g>
  </svg>
);

export const usersIcon = (
  <svg
    id="Layer_3"
    data-name="Layer 3"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="18"
    viewBox="0 0 24 18"
  >
    <defs />
    <tooltip>Users</tooltip>
    <g
      id="Rounded_Rectangle_1080_copy_5"
      data-name="Rounded Rectangle 1080 copy 5"
    >
      <path
        className="cls-3"
        d="M10,10H8a8,8,0,0,0-8,8H1a7,7,0,0,1,7-7h2a7,7,0,0,1,7,7h1A8,8,0,0,0,10,10ZM9,8A4,4,0,1,0,5,4,4,4,0,0,0,9,8ZM9,1A3,3,0,1,1,6,4,3,3,0,0,1,9,1ZM24,15a5.41,5.41,0,0,0-5.31-5H17.33a2.52,2.52,0,0,0-1.52.68c.23.19.94.66.94.66s.35-.33.58-.33h1.34C21.24,11,23,13.24,23,16h1v-.83A1.05,1.05,0,0,0,24,15ZM18,8a3,3,0,1,0-3-3A3,3,0,0,0,18,8Zm0-5a2,2,0,1,1-2,2A2,2,0,0,1,18,3Z"
        transform="translate(0 0)"
      />
    </g>
  </svg>
);

const agentsIcon = (
  <svg
    id="Layer_4"
    data-name="Layer 4"
    xmlns="http://www.w3.org/2000/svg"
    width="17.99"
    height="19"
    viewBox="0 0 17.99 19"
  >
    <defs />
    <tooltip>Agents</tooltip>
    <g id="Ellipse_2754_copy_2" data-name="Ellipse 2754 copy 2">
      <path
        className="cls-4"
        d="M18,7.1A8.07,8.07,0,0,0,10,0H8A8.07,8.07,0,0,0,0,7.1V9.91A.86.86,0,0,0,.24,10l.27,0a1.43,1.43,0,0,0,.58-.12,1.39,1.39,0,0,0,.48-.32A1.51,1.51,0,0,0,2,8.5a1.34,1.34,0,0,0-.07-.4.29.29,0,0,0,0-.09,2.26,2.26,0,0,0-.17-.35,1.16,1.16,0,0,0-.26-.28s-.05,0-.07-.06a1.38,1.38,0,0,0-.33-.2A7,7,0,0,1,8,1h2a7.07,7.07,0,0,1,7,6v.09a1.49,1.49,0,0,0,0,2.82V11a7,7,0,0,1-1.93,3.82A7.91,7.91,0,0,0,10,13H8A8,8,0,0,0,.6,18H1.69A7,7,0,0,1,8,14h2a6.87,6.87,0,0,1,4.3,1.51A6.91,6.91,0,0,1,10.4,17a1.51,1.51,0,1,0,0,1,7.93,7.93,0,0,0,4.64-1.8A7,7,0,0,1,16.31,18H17.4a8.17,8.17,0,0,0-1.61-2.51A8.1,8.1,0,0,0,18,10.91ZM13,7a4,4,0,1,0-4,4A4,4,0,0,0,13,7ZM6,7a3,3,0,1,1,3,3A3,3,0,0,1,6,7Z"
        transform="translate(-0.01 0)"
      />
    </g>
  </svg>
);

const analyticsIcon = (
  <svg
    id="Layer_5"
    data-name="Layer 5"
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="14"
    viewBox="0 0 18 14"
  >
    <title>Analytics</title>
    <g id="Rounded_Rectangle_1082_copy" data-name="Rounded Rectangle 1082 copy">
      <path
        className="cls-5"
        d="M3.49,8a.5.5,0,0,0-.5.5v5a.5.5,0,0,0,.5.5.51.51,0,0,0,.5-.5v-5A.5.5,0,0,0,3.49,8Zm5-3a.49.49,0,0,0-.49.5v8a.5.5,0,1,0,1,0v-8A.5.5,0,0,0,8.46,5Zm5,2a.5.5,0,0,0-.5.5v6a.5.5,0,1,0,1,0v-6A.49.49,0,0,0,13.44,7ZM18,.53h0a.5.5,0,0,0-.5-.5h-3a.49.49,0,0,0-.49.5h0a.5.5,0,0,0,.49.5h1.56L13.46,3,8.84.18h0s0,0,0,0a.5.5,0,0,0-.7,0l-8,5a.46.46,0,0,0,0,.67.47.47,0,0,0,.67,0L8.46,1.07l4.69,2.82a.51.51,0,0,0,.7,0L17,1.52v2a.5.5,0,0,0,.5.5.5.5,0,0,0,.5-.5v-3Z"
        transform="translate(-0.01 -0.01)"
      />
    </g>
  </svg>
);

// const settingsIcon = (
//   <svg
//     id="Layer_6"
//     data-name="Layer 6"
//     xmlns="http://www.w3.org/2000/svg"
//     width="18"
//     height="18"
//     viewBox="0 0 18 18"
//   >
//     <defs />
//     <tooltip>Settings</tooltip>
//     <g id="Shape_1072" data-name="Shape 1072">
//       <path
//         className="cls-6"
//         d="M16.72,10l-.12-.23a8.13,8.13,0,0,0,0-1.56L16.72,8l1-.48L18,6.62l-.95-2.3-.87-.4-1,.34-.25-.08a8.17,8.17,0,0,0-1.1-1.1l-.08-.25.34-1L13.68,1,11.38,0l-.89.33-.48,1-.23.13a7.4,7.4,0,0,0-1.56,0L8,1.28,7.51.34,6.62,0,4.32,1l-.4.87.34,1-.08.25a7.63,7.63,0,0,0-1.1,1.1l-.25.08-1-.34L1,4.32,0,6.62l.33.89,1,.48.13.23a7.4,7.4,0,0,0,0,1.56L1.28,10l-.95.48L0,11.38l1,2.3.87.4,1-.34.25.08a8.17,8.17,0,0,0,1.1,1.1l.08.25-.34,1,.4.87,2.3.95.89-.33.48-1,.23-.13a7.4,7.4,0,0,0,1.56,0l.23.12.48,1,.89.33,2.3-.95.4-.87-.34-1,.08-.25a8.17,8.17,0,0,0,1.1-1.1l.25-.08,1,.34.87-.4.95-2.3-.33-.89Zm-.33,3-.26.12-.95-.32-.76.24-.08.1a6.59,6.59,0,0,1-1.15,1.15l-.1.08-.24.76.32.95-.12.26-1.69.7-.27-.1-.45-.89-.7-.37H9.81a6.27,6.27,0,0,1-1.62,0l-.13,0-.71.37-.44.9-.28.1L5,16.39l-.12-.26.32-.95-.24-.76-.11-.08a6.89,6.89,0,0,1-1.14-1.15l-.08-.1-.76-.24-.95.32-.26-.12-.7-1.68.1-.28.77-.38.14-.1.35-.67V9.81a6.27,6.27,0,0,1,0-1.62V8.06L1.9,7.35,1,6.91l-.1-.28L1.61,5l.26-.12.95.32.76-.24.08-.11A7.23,7.23,0,0,1,4.8,3.66l.11-.08.23-.76-.31-.95L5,1.61,6.63.91l.28.1.44.89.71.37h.13a6.27,6.27,0,0,1,1.62,0h.13l.7-.36.45-.9.28-.1,1.68.69.12.27-.32.95.24.76.1.08A6.89,6.89,0,0,1,14.34,4.8l.08.11.76.24.95-.32.26.12.7,1.68-.1.28-.89.44-.37.71v.13a6.27,6.27,0,0,1,0,1.62v.13l.36.7.9.45.1.27ZM9,5a4,4,0,1,0,4,4A4,4,0,0,0,9,5Zm0,7a3,3,0,1,1,3-3A3,3,0,0,1,9,12Z"
//         transform="translate(0 0)"
//       />
//     </g>
//   </svg>
// );

const widgetIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20.312"
    height="20"
    viewBox="0 0 20.312 20"
  >
    <path
      d="M573,1056a2,2,0,1,1-1.994,2A1.829,1.829,0,0,1,573,1056Zm8,0a2,2,0,1,1-1.994,2A1.829,1.829,0,0,1,581,1056Zm8,0a2,2,0,1,1-1.994,2A1.829,1.829,0,0,1,589,1056Zm-16,8a2,2,0,1,1-1.994,2A1.829,1.829,0,0,1,573,1064Zm8,0a2,2,0,1,1-1.994,2A1.829,1.829,0,0,1,581,1064Zm8,0a2,2,0,1,1-1.994,2A1.829,1.829,0,0,1,589,1064Zm-16,8a2,2,0,1,1-1.994,2A1.829,1.829,0,0,1,573,1072Zm8,0a2,2,0,1,1-1.994,2A1.829,1.829,0,0,1,581,1072Zm8,0a2,2,0,1,1-1.994,2A1.829,1.829,0,0,1,589,1072Z"
      transform="translate(-570.844 -1056)"
    />
  </svg>
);

const mapStateToProps = state => {
  return {
    currentUser: state => state.entities.teamMembers[state.auth.userId],
    counters: state.caseCounters.counters,
    notificationsUnseen: state.notifications.unseen
  };
};

export default connect(mapStateToProps)(Menu);
