import React, { useCallback, useMemo, useEffect, useRef } from "react";
import { connect } from "react-redux";
import {
  Table,
  TableHeader,
  TableHeaderColumn,
  TableBody,
  Preloader,
  Col,
  ScrollableList
} from "design-system-react";
import TeamMemberCaseItemContainer from "./TeamMemberCaseItemContainer";
import { api } from "../../../utils/client";
import { addCaseIds, setCaseIds } from "../../../modules/pages/teamMember";
import usePagination from "../../../hooks/usePagination";
import EmptyState from "components/EmptyState";
import history from "utils/history";
import CaseModalContainer from "../../../containers/CaseModalContainer";
import InfinitePagination from "../../../components/infinitePagination/InfinitePagination";

function TeamMemberCaseListContainer({
  caseIds,
  teamMemberId,
  activeCaseId,
  setCaseIds,
  addCaseIds
}) {
  const scrollbarRef = useRef(null);

  const handleCasesFetch = useCallback(
    request => {
      request.ready.then(response => {
        addCaseIds(response.data.data.map(caseItem => caseItem.id));
      });
    },
    [addCaseIds]
  );

  const casesFetchParams = useMemo(() => {
    return {
      memberId: teamMemberId
    };
  }, [teamMemberId]);

  const casesPagination = usePagination(
    api.teamMembers.cases,
    casesFetchParams,
    30,
    handleCasesFetch
  );

  useEffect(() => {
    if (teamMemberId) {
      setCaseIds([]);

      const request = casesPagination.load();

      return () => {
        request.cancelRequest.cancel();
      };
    }

    // eslint-disable-next-line
  }, [teamMemberId, setCaseIds]);

  const handleCloseCase = useCallback(() => {
    history.replace({
      pathname: "/team-member/" + teamMemberId
    });
  }, [teamMemberId]);

  if (caseIds.length === 0 && casesPagination.nextLoading) {
    return (
      <Col align="center" verticalAlign="center" justified>
        <Preloader size="32" color="#807d7f" />
      </Col>
    );
  }

  if (caseIds.length === 0) {
    return (
      <Col align="center" verticalAlign="center" justified>
        <EmptyState title="No team member cases">
          Currently this team member <br /> has no cases
        </EmptyState>
      </Col>
    );
  }

  return (
    <>
      {activeCaseId && (
        <CaseModalContainer
          caseId={activeCaseId}
          onCloseCase={handleCloseCase}
        />
      )}
      <InfinitePagination
        pagination={casesPagination}
        scrollbarRef={scrollbarRef}
      >
        <Table>
          <TableHeader fixed>
            <TableHeaderColumn justified fixed width={300} />
            <TableHeaderColumn>Status</TableHeaderColumn>
            <TableHeaderColumn>Assignee</TableHeaderColumn>
            <TableHeaderColumn>Updated</TableHeaderColumn>
            <TableHeaderColumn>Channel</TableHeaderColumn>
            <TableHeaderColumn>Case ID</TableHeaderColumn>
          </TableHeader>
          <TableBody>
            <ScrollableList
              rowHeight={80}
              rowCount={caseIds.length}
              scrollbarRef={scrollbarRef}
            >
              {index => (
                <TeamMemberCaseItemContainer
                  key={caseIds[index]}
                  caseItemId={caseIds[index]}
                  teamMemberId={teamMemberId}
                />
              )}
            </ScrollableList>
            {casesPagination.nextLoading && (
              <Col align="center" gutterTop={30} gutterBottom={30}>
                <Preloader size="32" color="#807d7f" />
              </Col>
            )}
          </TableBody>
        </Table>
      </InfinitePagination>
    </>
  );
}

const mapStateToProps = state => {
  return {
    caseIds: state.pages.teamMember.caseIds
  };
};

const mapDispatchToProps = dispatch => ({
  setCaseIds: ids => dispatch(setCaseIds(ids)),
  addCaseIds: ids => dispatch(addCaseIds(ids))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamMemberCaseListContainer);
