import React, { useCallback } from "react";
import { Formik, Field } from "formik";
import {
  Button,
  PopupHeader,
  Popup,
  PopupBody,
  PopupFooter,
  GutterField
} from "design-system-react";
import InputField from "components/InputField";
import { addChannelBlingerId } from "modules/pages/settingsBlingerMessenger";
import { api } from "utils/client";
import { FormError } from "utils/formError";
import connect from "react-redux/es/connect/connect";

function SettingsBlingerMessengerModalAddContainer({
  toggle,
  isOpen,
  addChannelBlingerId
}) {
  const initialValues = {};

  const handleSubmit = useCallback(
    (values, { setSubmitting, setErrors, setFieldValue }) => {
      api.messenger
        .create(values)
        .ready.then(response => {
          setFieldValue("name", "");
          setFieldValue("token", "");
          toggle();
          addChannelBlingerId(response.data.data.id);
        })
        .catch(e => {
          if (e instanceof FormError) {
            setErrors(e.errors);
          }
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [toggle, addChannelBlingerId]
  );

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      render={props => (
        <Popup isOpen={isOpen} onClose={toggle}>
          <PopupHeader>Add Blinger Messenger</PopupHeader>
          <PopupBody>
            <GutterField>
              <Field label="Name" name="name" component={InputField} />
            </GutterField>
            <Field label="Token" name="token" component={InputField} />
          </PopupBody>
          <PopupFooter>
            <Button
              onClick={props.submitForm}
              processing={props.isSubmitting}
              // disabled={submitDisabled}
            >
              Add
            </Button>
            <Button outline={true} onClick={toggle}>
              Cancel
            </Button>
          </PopupFooter>
        </Popup>
      )}
    />
  );
}

const mapDispatchToProps = dispatch => ({
  addChannelBlingerId: id => dispatch(addChannelBlingerId(id))
});

export default connect(
  null,
  mapDispatchToProps
)(SettingsBlingerMessengerModalAddContainer);
