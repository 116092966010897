import React from "react";
import styles from "./SettingsProfilePersonal.module.css";
import { Field } from "formik";
import PropTypes from "prop-types";
import InputField from "components/InputField";
import AvatarFieldContainer from "../containers/AvatarFieldContainer";
import { Input, Avatar, Preloader, GutterField } from "design-system-react";

function SettingsProfilePersonal({
  handleAvatarChange,
  handleChangePasswordClick,
  previewAvatar,
  isAvatarUploading,
  avatar
}) {
  return (
    <div className={styles.personal}>
      <div className={styles.avatar}>
        {isAvatarUploading ? (
          <span className={styles.preloader}>
            <Preloader size="32" color="#807d7f" />
          </span>
        ) : (
          <>
            <i className="ico-add" />
            <label htmlFor="file-upload">
              {previewAvatar ? (
                <Avatar image={previewAvatar} size="200" />
              ) : !previewAvatar && avatar ? (
                <Avatar image={avatar} size="200" />
              ) : (
                <img
                  className={styles.pic}
                  src={"../images/personal-info-avatar.svg"}
                  alt="Avatar"
                />
              )}
            </label>
          </>
        )}
        <Field
          name="avatar"
          type="file"
          onChange={handleAvatarChange}
          component={AvatarFieldContainer}
        />
      </div>
      <div className={styles.inputList}>
        <GutterField>
          <Field
            name="name"
            type="text"
            label="Full name"
            component={InputField}
          />
        </GutterField>
        <GutterField>
          <div className={styles.disabled}>
            <Input
              type="password"
              autoComplete="password"
              label="Password"
              value="password"
              noClearButton
              appendAfter={
                <span
                  className={styles.change}
                  onClick={handleChangePasswordClick}
                >
                  Change
                </span>
              }
            />
          </div>
        </GutterField>
      </div>
    </div>
  );
}

SettingsProfilePersonal.propTypes = {
  handleAvatarChange: PropTypes.func.isRequired,
  isPasswordChange: PropTypes.bool,
  handleChangePasswordClick: PropTypes.func,
  avatar: PropTypes.string
};

SettingsProfilePersonal.defaultTypes = {
  isPasswordChange: false
};

export default SettingsProfilePersonal;
