import React, { useCallback, useEffect, useState, useRef } from "react";
import {
  Popup,
  PopupBody,
  PopupHeader,
  PopupFooter,
  Button,
  Input
} from "design-system-react";
import { defineChannel } from "helpers/defineChannel";
import usePrevious from "hooks/usePrevious";

// const urlMask = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
const emailMask = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function validate(value, type) {
  if (type === "email") {
    return emailMask.test(value);
  } else {
    // return urlMask.test(value);
    return true;
  }
}

function WidgetChannelModalContainer({
  close,
  modalParams,
  isModalOpen,
  setValues
}) {
  const [value, setValue] = useState("");
  const [isDirty, setDirty] = useState(false);
  const [error, setError] = useState(null);
  let prevValue = usePrevious(modalParams.link);
  const inputRef = useRef(null);

  useEffect(() => {
    if (modalParams.link) {
      setValue(modalParams.link);
    } else {
      setValue("");
    }

    inputRef.current.focus();

    if (!isModalOpen) {
      setError(null);
    }
  }, [modalParams, isModalOpen]);

  const handleSubmit = () => {
    if (validate(value, modalParams.type)) {
      setValues({ type: modalParams.type, link: value, enabled: true });
      setError(null);
      setValue("");
      close();
    } else {
      setError("This field is not valid.");
    }
    setDirty(false);
  };

  const handleClear = useCallback(() => {
    setValue("");
    setDirty(false);
    setError(null);
  }, []);

  const handleChange = e => {
    setValue(e.target.value);
    if (e.target.value.match(/^[ ]+$/)) {
      setValue("");
    }
    if (e.target.value.trim() !== prevValue) {
      setError(null);
      setDirty(true);
    } else {
      setDirty(false);
    }
  };

  const handleKeyPress = e => {
    if (e.key === "Enter" && isDirty) {
      handleSubmit();
      e.preventDefault();
    }
  };

  return (
    <Popup isOpen={isModalOpen} onClose={close}>
      <PopupHeader>Add {defineChannel(modalParams.type).name}</PopupHeader>
      <PopupBody>
        <Input
          innerRef={inputRef}
          validation={error && "error"}
          message={error}
          type="text"
          onKeyPress={handleKeyPress}
          label={(modalParams.type === "email" && "Email") || "link"}
          onClearClick={handleClear}
          onChange={handleChange}
          value={value}
        />
      </PopupBody>
      <PopupFooter>
        <Button onClick={handleSubmit} disabled={!isDirty}>
          Save
        </Button>
        <Button outline={true} onClick={close}>
          Cancel
        </Button>
      </PopupFooter>
    </Popup>
  );
}

export default WidgetChannelModalContainer;
