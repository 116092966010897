import React from "react";
import classNames from "classnames";
import styles from "./ConditionField.module.css";

function ConditionField({ isTimeZone, children }) {
  const classes = classNames(styles.field, isTimeZone && styles.timezone);
  return <div className={classes}>{children}</div>;
}

export default ConditionField;
