import React, { useState, useCallback } from "react";
import { api } from "utils/client";
import { FormError } from "utils/formError";
import { ALERT_ERROR, ALERT_SUCCESS, showAlert } from "utils/alertManager";
import SettingsProfilePersonal from "pages/SettingsProfile/components/SettingsProfilePersonal";

function SettingProfilePersonalContainer({
  handleChangePasswordClick,
  avatar
}) {
  const [isAvatarLoading, setAvatarLoading] = useState(false);
  const [previewAvatar, setPreviewAvatar] = useState(null);

  const handleAvatarChange = useCallback(
    e => {
      setAvatarLoading(true);

      api.teamMembers
        .uploadAvatar(e.target.files[0])
        .ready.then(response => {
          setPreviewAvatar(response.data.data.url["200x200"]);

          api.teamMembers
            .updateProfile({
              avatar: response.data.data.id
            })
            .ready.then(() => {
              showAlert(
                ALERT_SUCCESS,
                "Avatar successfully updated"
              );
            })
            .catch(() => {})
            .finally(()=>{
              setAvatarLoading(false);
            });
        })
        .catch(e => {
          if (e instanceof FormError) {
            showAlert(ALERT_ERROR, e.errors.avatar);
          }

          setAvatarLoading(false);
        });
    },
    []
  );

  return (
    <SettingsProfilePersonal
      handleAvatarChange={handleAvatarChange}
      previewAvatar={previewAvatar}
      isAvatarUploading={isAvatarLoading}
      handleChangePasswordClick={handleChangePasswordClick}
      avatar={avatar}
    />
  );
}

export default SettingProfilePersonalContainer;
