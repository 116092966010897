import React, { useCallback } from "react";
import { QuickReplyItem } from "design-system-react";

const titleStyles = { color: "#969799", paddingRight: "12px" };

function QuickReplyItemContainer({ text, title, onReplySelect }) {
  const handleSelect = useCallback(() => {
    onReplySelect(text);
  }, [text, onReplySelect]);

  return (
    <QuickReplyItem onReplySelect={handleSelect} text={text}>
      <span style={titleStyles}>{title}</span>
      {text}
    </QuickReplyItem>
  );
}

export default QuickReplyItemContainer;
