import React from "react";
import classNames from "classnames";
import styles from "pages/Cases/components/MessageListDivider.module.css";

function MessageListDivider({ isDate, children }) {
  const classes = classNames(styles.divider, isDate && styles.isDate);
  return (
    <div className={classes}>
      {isDate && <div className={styles.line} />}
      <div className={styles.children}>{children}</div>
    </div>
  );
}

export default MessageListDivider;
