import React from "react";
import InputField from "components/InputField";
import { Field } from "formik";
import { Button, GutterField } from "design-system-react";

function ConfirmInviteFormContainer({ values, handleSubmit, isSubmitting }) {
  const isEmptyFields = () => {
    return !values.name || !values.password;
  };

  return (
    <form onSubmit={handleSubmit}>
      <h1 className="sign-in-title">Invite Confirmation</h1>

      <GutterField>
        <Field
          name="name"
          type="text"
          label="Full Name"
          component={InputField}
        />
      </GutterField>
      <GutterField>
        <Field
          name="password"
          type="password"
          label="Password"
          component={InputField}
        />
      </GutterField>

      <Button
        type="submit"
        disabled={isEmptyFields()}
        processing={isSubmitting}
      >
        Confirm
      </Button>
    </form>
  );
}

export default ConfirmInviteFormContainer;
