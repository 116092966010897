import React, { useEffect, useMemo } from "react";
import { FastField, Field } from "formik";
import { connect } from "react-redux";
import { setIntroduce } from "modules/widgetSetup";
import SelectField from "components/SelectField";
import InputField from "components/InputField";
import MultiSelectField from "components/MultiSelectField";
import SwitcherField from "components/SwitcherField";
import usePrevious from "hooks/usePrevious";
import { GutterField } from "design-system-react";
import useFormSubmission from "hooks/useFormSubmission";
import CheckboxWrapper from "components/CheckboxWrapper";
import InputUploadContainer from "./InputUploadContainer";

const languages = [
  { label: "Russian", value: "ru" },
  { label: "English", value: "en" },
  { label: "Kazakh", value: "kz" }
];

function WidgetIntroduceFormContainer({
  values,
  dirty,
  setIntroduce,
  submitDisabled,
  setSubmitDisabled,
  handleSubmit
}) {
  const prevDefaultLanguage = usePrevious(values.defaultLanguage);
  const handleSubmitForm = useFormSubmission(submitDisabled, handleSubmit);

  const additionalLanguages = useMemo(
    () => languages.filter(item => item.value !== values.defaultLanguage),
    [values.defaultLanguage]
  );

  // update setup on form changes
  useEffect(() => {
    setIntroduce(values);
  }, [setIntroduce, values]);

  if (prevDefaultLanguage) {
    if (prevDefaultLanguage !== values.defaultLanguage) {
      values.languages = [];
    }
  }

  if (!dirty && !submitDisabled) {
    setSubmitDisabled(true);
  }

  if (dirty && submitDisabled) {
    setSubmitDisabled(false);
  }

  return (
    <form onSubmit={handleSubmitForm}>
      <GutterField>
        <FastField
          name="defaultLanguage"
          label="Default widget language"
          component={SelectField}
          items={languages}
        />
      </GutterField>
      <GutterField>
        <Field
          name="languages"
          label="Additional languages"
          component={MultiSelectField}
          items={additionalLanguages}
        />
      </GutterField>
      <GutterField>
        <FastField
          name="company"
          type="text"
          label="Company name"
          // placeholder="Enter company name"
          component={InputField}
        />
      </GutterField>
      <GutterField>
        <FastField
          name="logo"
          type="file"
          label="Company logo"
          tooltip="The recommended dimensions of the logo are 120px by 120 px. The file size shouldn't exceed 5 mb. The supported file formats are .png and .jpg."
          placeholder="Upload file"
          component={InputUploadContainer}
        />
      </GutterField>
      <GutterField>
        <CheckboxWrapper label="Show team members">
          <FastField name="showAgents" component={SwitcherField} />
        </CheckboxWrapper>
      </GutterField>
    </form>
  );
}

const mapDispatchToProps = dispatch => ({
  setIntroduce: values => dispatch(setIntroduce(values))
});

export default connect(
  null,
  mapDispatchToProps
)(WidgetIntroduceFormContainer);
