import React, { useState, useCallback } from "react";
import WidgetChannelsPageContainer from "./WidgetChannelsPageContainer";
import WidgetChannelModalContainer from "./WidgetChannelModalContainer";
import WidgetFormLayout from "components/Widget/WidgetFormLayout";
import useRenderDelay from "hooks/useRenderDelay";

function WidgetChannelsContainer() {
  const [modalValue, setModalValue] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalParams, setModalParams] = useState({
    link: null,
    type: null
  });
  const renderModal = useRenderDelay(isModalOpen, 50, 350);

  const handleSetModal = useCallback((link, type) => {
    setModalOpen(true);
    setModalParams({
      link,
      type
    });
  }, []);

  const handleSetValues = useCallback(modalValue => {
    setModalValue(modalValue);
  }, []);

  const handleModalClose = useCallback(() => {
    setModalOpen(false);
    setModalParams({
      link: modalParams.link,
      type: modalParams.type
    });
  }, [modalParams]);

  return (
    <WidgetFormLayout>
      {renderModal.isRender && (
        <WidgetChannelModalContainer
          close={handleModalClose}
          modalParams={modalParams}
          isModalOpen={renderModal.isOpen}
          setValues={handleSetValues}
        />
      )}
      <WidgetChannelsPageContainer
        modalValue={modalValue}
        handleSetModal={handleSetModal}
      />
    </WidgetFormLayout>
  );
}

export default WidgetChannelsContainer;
