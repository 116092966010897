import React from "react";
import styles from "./MessageListItemBubble.module.css";
import classNames from "classnames";
import PropTypes from "prop-types";
import { TYPE_FULL_AT } from "utils/dateTime";
import DateTime from "components/DateTime";

function MessageListItemBubble({
  file,
  noPadding,
  noBorder,
  dark,
  note,
  children,
  status,
  time
}) {
  const classes = classNames(
    styles.bubble,
    file && styles.file,
    noPadding && styles.noPadding,
    noBorder && styles.noBorder,
    dark && styles.dark,
    note && styles.note
  );

  const classesContent = classNames(
    noPadding && styles.noPadding,
    status === "error" && styles.error,
    styles.content
  );

  return (
    <div className={classes}>
      <div className={classesContent}>{children}</div>
      <div className={styles.tooltip}>
        <DateTime forceType={TYPE_FULL_AT} time={time} />
      </div>
    </div>
  );
}

MessageListItemBubble.propTypes = {
  noPadding: PropTypes.bool,
  dark: PropTypes.bool,
  note: PropTypes.bool
};

MessageListItemBubble.defalutProps = {
  noPadding: false,
  dark: false,
  note: false
};

export default MessageListItemBubble;
