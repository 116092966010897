import React from "react";
import "./main.css";
import classNames from "classnames";
import { connect } from "react-redux";
import { translation_default } from "helpers/translations";
import WidgetChannelsContainer from "./WidgetChannelsContainer";
import WidgetQualifyContainer from "./WidgetQualifyContainer";
import WidgetWelcomeContainer from "./WidgetWelcomeContainer";
import WidgetIntroduceContainer from "./WidgetIntroduceContainer";
import WidgetExampleLayout from "../components/WidgetExampleLayout";

function WidgetExampleContainer({ style, isOpen, defaultLanguage, isChat }) {
  // Styles
  let brandColor = style.brandColor || "#d4d5d9";
  let position = style.position || "Right";
  let highlightColor = style.highlightColor || "#ffffff";

  function translate(string) {
    return translation_default[string][defaultLanguage];
  }

  const channelsClasses = classNames(
    "channels-wrapper",
    isOpen && "channels-wrapper-chat-show"
  );
  const chatWrapperClasses = classNames(
    "chat-wrapper",
    isOpen && "chat-wrapper-active"
  );

  const buttonClasses = classNames(
    !isChat && "is-no-button",
    "channels-wrapper-chat-button complete " + highlightColor
  );

  return (
    <WidgetExampleLayout>
      <div
        id="blinger-channels"
        className="channels"
        style={{ borderColor: brandColor }}
      >
        <div className={channelsClasses}>
          <div className="channels-wrapper-scroll">
            <WidgetIntroduceContainer />

            <div className="channels-wrapper-container">
              <div className="channels-wrapper-chat">
                <WidgetWelcomeContainer />
              </div>

              <div className={buttonClasses}>
                <div className="channels-wrapper-chat-button-title">
                  {translate("openChat") || "Open chat"}
                </div>
                <div
                  className="channels-wrapper-chat-button-arrow"
                  style={{ background: brandColor }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="6"
                    height="11"
                    viewBox="0 0 6 11"
                  >
                    <path
                      fill={highlightColor}
                      id="arrow"
                      data-name="arrow"
                      className="arrow"
                      d="M573.113,658.787l4.712,4.7-4.712,4.7a0.476,0.476,0,0,0,.674.673l5-4.991a0.281,0.281,0,0,0,.062-0.026,0.529,0.529,0,0,0,0-.708,0.335,0.335,0,0,0-.062-0.025l-5-4.99A0.476,0.476,0,0,0,573.113,658.787Z"
                      transform="translate(-573 -658)"
                    />
                  </svg>
                </div>
                <div
                  className="bg"
                  style={{
                    background: brandColor
                  }}
                />
              </div>

              <WidgetChannelsContainer defaultLanguage={defaultLanguage} />
            </div>
          </div>

          <div className={`powered-by`}>
            <span>Powered by</span>
            <a
              style={{ color: brandColor }}
              href="https://blinger.io/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                className="copy-icon"
                xmlns="http://www.w3.org/2000/svg"
                width="7"
                height="12"
                viewBox="0 0 7 12"
              >
                <path
                  fill={brandColor}
                  d="M453,1064h-3v-6l-4,6h3v6.01Z"
                  transform="translate(-446 -1058)"
                />
              </svg>
              blinger.io
            </a>
          </div>
        </div>
        <div className={chatWrapperClasses}>
          <WidgetIntroduceContainer isOpen />
          <WidgetQualifyContainer defaultLanguage={defaultLanguage} />

          <div className="chat-message-input">
            <textarea
              placeholder={translate("writeMessage") || "Write a message..."}
            />
            <div className="chat-message-input-buttons">
              <div className="chat-message-input-buttons-emogy">
                <img
                  className="emogy-chat-icon"
                  src="/images/widget/emogy.svg"
                  alt=""
                />
              </div>

              <div className="chat-message-input-buttons-add">
                <img
                  className="add-chat-icon"
                  src="/images/widget/add.svg"
                  alt=""
                />
              </div>

              <div className="chat-message-send-button">
                <img
                  className="send-chat-icon"
                  src="/images/widget/send.svg"
                  alt=""
                />
              </div>

              <div className="chat-message-emogy">
                <div className="chat-message-emogy-search">
                  <img
                    className="search-chat-icon"
                    src="/images/widget/search.svg"
                    alt=""
                  />
                  <input type="text" />
                </div>
                <div className="chat-message-emogy-block">
                  <img
                    className="emogies-chat-icon"
                    src="/images/widget/emogy.gif"
                    alt=""
                  />
                  <img
                    className="emogies-chat-icon"
                    src="/images/widget/emogy.gif"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="blinger-launcher" className={classNames("channels", position)}>
        <div
          className={classNames(
            "launcher-button-wrapper",
            isOpen && "launcher-button-open"
          )}
        >
          <div className="launcher-button">
            <div
              className="launcher-shadow-block"
              style={{ background: brandColor }}
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="60"
              height="60"
              viewBox="0 0 60 60"
            >
              <path
                fill={brandColor}
                id="launcher-svg"
                data-name="launcher-svg"
                className="launcher-svg"
                d="M682,931h-2c-17.864,0-29-10.994-29-29v-2c0-18.006,11.136-29,29-29h2c17.864,0,29,10.994,29,29v2C711,920.006,699.864,931,682,931Z"
                transform="translate(-651 -871)"
              />
            </svg>

            <img
              className="launcher-inner"
              src="/images/widget/launcher.svg"
              alt=""
            />

            <div className="widget-menu-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20.312"
                height="20"
                viewBox="0 0 20.312 20"
              >
                <path
                  fill={brandColor}
                  id="widget-menu-icon"
                  data-name="widget-menu-icon"
                  className="widget-menu-icon"
                  d="M573,1056a2,2,0,1,1-1.994,2A1.829,1.829,0,0,1,573,1056Zm8,0a2,2,0,1,1-1.994,2A1.829,1.829,0,0,1,581,1056Zm8,0a2,2,0,1,1-1.994,2A1.829,1.829,0,0,1,589,1056Zm-16,8a2,2,0,1,1-1.994,2A1.829,1.829,0,0,1,573,1064Zm8,0a2,2,0,1,1-1.994,2A1.829,1.829,0,0,1,581,1064Zm8,0a2,2,0,1,1-1.994,2A1.829,1.829,0,0,1,589,1064Zm-16,8a2,2,0,1,1-1.994,2A1.829,1.829,0,0,1,573,1072Zm8,0a2,2,0,1,1-1.994,2A1.829,1.829,0,0,1,581,1072Zm8,0a2,2,0,1,1-1.994,2A1.829,1.829,0,0,1,589,1072Z"
                  transform="translate(-570.844 -1056)"
                />
              </svg>
            </div>

            <img
              className="widget-close-icon"
              src="/images/widget/widget-close.svg"
              alt=""
            />
          </div>
        </div>
      </div>
    </WidgetExampleLayout>
  );
}

const mapStateToProps = state => {
  return {
    defaultLanguage:
      state.widgetSetup.current.introduce.defaultLanguage || "en",
    style: state.widgetSetup.current.style || [],
    isChat:
      String(state.widgetSetup.current.channels.blingerMessenger) === "false"
        ? false
        : true
  };
};

export default connect(mapStateToProps)(WidgetExampleContainer);
