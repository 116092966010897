import { withNamespace } from "utils/namespace";

const type = withNamespace("pages/cases");

export const SET_CASE_IDS = type("SET_CASE_IDS");
export const ADD_CASE_IDS = type("ADD_CASE_IDS");
export const ADD_CASE_ID = type("ADD_CASE_ID");
export const REMOVE_CASE_ID = type("REMOVE_CASE_ID");
export const SET_MESSAGE_IDS = type("SET_MESSAGE_IDS");
export const ADD_MESSAGE_IDS = type("ADD_MESSAGE_IDS");
export const ADD_MESSAGE_ID = type("ADD_MESSAGE_ID");
export const ADD_OUTGOING_MESSAGE = type("ADD_OUTGOING_MESSAGE");
export const REMOVE_OUTGOING_MESSAGE = type("REMOVE_OUTGOING_MESSAGE");
export const UPDATE_OUTGOING_MESSAGE_STATUS = type(
  "UPDATE_OUTGOING_MESSAGE_STATUS"
);
export const ADD_ATTACHMENT = type("ADD_ATTACHMENT");
export const UPDATE_ATTACHMENT = type("UPDATE_ATTACHMENT");
export const REMOVE_ATTACHMENT = type("REMOVE_ATTACHMENT");
export const REMOVE_ALL_ATTACHMENTS = type("REMOVE_ALL_ATTACHMENTS");

// state
let initialState = {
  ids: [],
  messageIds: [],
  outgoingMessagesByCase: {},
  attachmentsByCase: {}
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_CASE_IDS:
      return {
        ...state,
        ids: action.payload.ids
      };
    case ADD_CASE_IDS:
      return {
        ...state,
        ids: [...state.ids, ...action.payload.ids]
      };
    case ADD_CASE_ID:
      if (state.ids.indexOf(action.payload.id) !== -1) {
        return state;
      }

      return {
        ...state,
        ids: [action.payload.id, ...state.ids]
      };
    case REMOVE_CASE_ID:
      return {
        ...state,
        ids: state.ids.filter(id => id !== action.payload.id)
      };
    case SET_MESSAGE_IDS:
      return {
        ...state,
        messageIds: action.payload.ids
      };
    case ADD_MESSAGE_IDS:
      return {
        ...state,
        messageIds: [...action.payload.ids, ...state.messageIds]
      };
    case ADD_MESSAGE_ID:
      if (state.messageIds.indexOf(action.payload.id) !== -1) {
        return state;
      }

      return {
        ...state,
        messageIds: [...state.messageIds, action.payload.id]
      };
    case ADD_OUTGOING_MESSAGE:
      return {
        ...state,
        outgoingMessagesByCase: {
          ...state.outgoingMessagesByCase,
          [action.payload.caseId]: [
            ...(state.outgoingMessagesByCase[action.payload.caseId] || []),
            action.payload.message
          ]
        }
      };
    case REMOVE_OUTGOING_MESSAGE:
      return {
        ...state,
        outgoingMessagesByCase: {
          ...state.outgoingMessagesByCase,
          [action.payload.caseId]: (
            state.outgoingMessagesByCase[action.payload.caseId] || []
          ).filter(message => message.uuid !== action.payload.uuid)
        }
      };
    case UPDATE_OUTGOING_MESSAGE_STATUS:
      return {
        ...state,
        outgoingMessagesByCase: {
          ...state.outgoingMessagesByCase,
          [action.payload.caseId]: state.outgoingMessagesByCase[
            action.payload.caseId
          ].map(message =>
            message.uuid === action.payload.uuid
              ? {
                  ...message,
                  status: action.payload.status
                }
              : message
          )
        }
      };
    case ADD_ATTACHMENT:
      return {
        ...state,
        attachmentsByCase: {
          ...state.attachmentsByCase,
          [action.payload.caseId]: [
            ...(state.attachmentsByCase[action.payload.caseId] || []),
            action.payload.file
          ]
        }
      };
    case UPDATE_ATTACHMENT:
      return {
        ...state,
        attachmentsByCase: {
          ...state.attachmentsByCase,
          [action.payload.caseId]: (
            state.attachmentsByCase[action.payload.caseId] || []
          ).map(attachment =>
            attachment.uuid === action.payload.file.uuid
              ? { ...attachment, ...action.payload.file }
              : attachment
          )
        }
      };
    case REMOVE_ATTACHMENT:
      return {
        ...state,
        attachmentsByCase: {
          ...state.attachmentsByCase,
          [action.payload.caseId]: [
            ...(state.attachmentsByCase[action.payload.caseId] || []).filter(
              attachment => attachment.uuid !== action.payload.uuid
            )
          ]
        }
      };
    case REMOVE_ALL_ATTACHMENTS:
      return {
        ...state,
        attachmentsByCase: {
          ...state.attachmentsByCase,
          [action.payload.caseId]: []
        }
      };

    default:
      return state;
  }
}

// actions
export function setCaseIds(ids) {
  return {
    type: SET_CASE_IDS,
    payload: {
      ids
    }
  };
}

export function addCaseIds(ids) {
  return {
    type: ADD_CASE_IDS,
    payload: {
      ids
    }
  };
}

export function addCaseId(id) {
  return {
    type: ADD_CASE_ID,
    payload: {
      id
    }
  };
}

export function removeCaseId(id) {
  return {
    type: REMOVE_CASE_ID,
    payload: {
      id
    }
  };
}

export function setMessageIds(ids) {
  return {
    type: SET_MESSAGE_IDS,
    payload: {
      ids
    }
  };
}

export function addMessageIds(ids) {
  return {
    type: ADD_MESSAGE_IDS,
    payload: {
      ids
    }
  };
}

export function addMessageId(id) {
  return {
    type: ADD_MESSAGE_ID,
    payload: {
      id
    }
  };
}

export function addOutgoingMessage(caseId, message) {
  return {
    type: ADD_OUTGOING_MESSAGE,
    payload: {
      caseId: caseId,
      message: message
    }
  };
}

export function removeOutgoingMessage(caseId, uuid) {
  return {
    type: REMOVE_OUTGOING_MESSAGE,
    payload: {
      caseId: caseId,
      uuid: uuid
    }
  };
}

export function updateOutgoingMessageStatus(caseId, uuid, status) {
  return {
    type: UPDATE_OUTGOING_MESSAGE_STATUS,
    payload: {
      caseId,
      uuid,
      status
    }
  };
}

export function addAttachment(caseId, file) {
  return {
    type: ADD_ATTACHMENT,
    payload: {
      caseId,
      file
    }
  };
}

export function updateAttachment(caseId, file) {
  return {
    type: UPDATE_ATTACHMENT,
    payload: {
      caseId,
      file
    }
  };
}

export function removeAttachment(caseId, uuid) {
  return {
    type: REMOVE_ATTACHMENT,
    payload: {
      caseId,
      uuid
    }
  };
}

export function removeAllAttachments(caseId) {
  return {
    type: REMOVE_ALL_ATTACHMENTS,
    payload: {
      caseId
    }
  };
}
