import React from "react";
import { printTeamMemberStatus } from "helpers/users";
import { Input, GutterField } from "design-system-react";
import Collapse from "../../../components/Collapse";
import { Dropdown, DropdownToggleMulti } from "design-system-react";
import { TYPE_FULL } from "../../../utils/dateTime";
import EndUserInputTime from "pages/EndUser/components/EndUserInputTime";
import TeamMemberTeamContainer from "./TeamMemberTeamContainer";
import { connect } from "react-redux";
import { createSelector } from "reselect";

function TeamUserDetailsContainer({
  role,
  status,
  email,
  pin,
  added,
  updated,
  lastActivityAt,
  userAid,
  extraFields,
  userExtraFields,
  teams
}) {
  return (
    <div>
      <Collapse header="Team Member details">
        <GutterField gutterBottom={20}>
          <Input
            label="Member ID"
            readOnly
            value={"#" + userAid}
            size="sm"
            disabled
          />
        </GutterField>
        <GutterField gutterBottom={20}>
          <Input label="Role" readOnly value={role} size="sm" disabled />
        </GutterField>
        {pin ? (
          <GutterField gutterBottom={20}>
            <Input label="PIN" readOnly value={pin} size="sm" disabled />
          </GutterField>
        ) : null}
        <GutterField>
          <Dropdown isOpen={false} toggle={() => {}} size="sm" disabled multi>
            <DropdownToggleMulti label="Teams" hideInput={teams.length > 0}>
              {teams.map(teamId => (
                <TeamMemberTeamContainer key={teamId} teamId={teamId} />
              ))}
            </DropdownToggleMulti>
          </Dropdown>
        </GutterField>
        <GutterField gutterBottom={20}>
          <Input
            label="Status"
            readOnly
            value={printTeamMemberStatus(status)}
            size="sm"
            disabled
          />
        </GutterField>
        <GutterField gutterBottom={20}>
          <Input label="Email" readOnly value={email} size="sm" disabled />
        </GutterField>
        <GutterField gutterBottom={20}>
          <Input
            label="Added"
            readOnly
            appendAfter={
              <EndUserInputTime value={added} forceType={TYPE_FULL} />
            }
            size="sm"
            disabled
          />
        </GutterField>
        <GutterField gutterBottom={20}>
          <Input
            label="Updated"
            readOnly
            appendAfter={
              <EndUserInputTime value={updated} forceType={TYPE_FULL} />
            }
            size="sm"
            disabled
          />
        </GutterField>
        <GutterField gutterBottom={20}>
          <Input
            label="Last activity"
            readOnly
            appendAfter={
              <EndUserInputTime value={lastActivityAt} forceType={TYPE_FULL} />
            }
            size="sm"
            disabled
          />
        </GutterField>
      </Collapse>

      {userExtraFields && (
        <Collapse header="Contact details">
          {extraFields.map(field => {
            return (
              <GutterField key={field.label} gutterBottom={20}>
                <Input
                  label={field.label}
                  readOnly
                  value={userExtraFields[field.name] || "Not introduced"}
                  size="sm"
                  disabled
                />
              </GutterField>
            );
          })}
        </Collapse>
      )}
    </div>
  );
}

const getExtraFields = createSelector(
  [
    state => state.entities.extraFields,
    state => state.teamMemberExtraFields.ids
  ],
  (extraFields, ids) => {
    return ids.map(id => extraFields[id]);
  }
);

const mapStateToProps = state => {
  return {
    extraFields: getExtraFields(state)
  };
};

export default connect(mapStateToProps)(TeamUserDetailsContainer);
