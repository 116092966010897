import React, { useState, useCallback } from "react";
import SettingsChannelHeader from "components/SettingsHeader";
import TyntecWhatsappListContainer from "./TyntecWhatsappListContainer";
import AddModalContainer from "./AddModalContainer";
import { Row, Col, Container } from "design-system-react";

function SettingsTyntecWhatsappContainer() {
  const [addModalOpen, setAddModalOpen] = useState(false);

  const toggleAddModal = useCallback(() => {
    setAddModalOpen(!addModalOpen);
  }, [addModalOpen]);

  return (
    <Row fullHeight column>
      <Container>
        <SettingsChannelHeader
          title="WhatsApp Business"
          logo="/images/whatsApp_100.svg"
          onAction={toggleAddModal}
        >
          Communicate with your customers in WhatsApp channel by sending and
          receiving messages in Hugery directly. Process the message
          requests as Hugery cases.
        </SettingsChannelHeader>
      </Container>
      <Col justified>
        <TyntecWhatsappListContainer />
      </Col>
      <AddModalContainer
        isOpen={addModalOpen}
        toggle={toggleAddModal}
      />
    </Row>
  );
}

export default SettingsTyntecWhatsappContainer;
