import React from "react";
import { connect } from "react-redux";
import { channelSmIcon } from "helpers/channelIcon";
import NavPanelEndUser from "../../../components/NavPanelEndUser";

function NavPanelEndUserContainer({ avatar, channel, name, color, aid }) {
  return (
    <NavPanelEndUser
      avatar={avatar}
      channel={channel}
      name={name}
      color={color}
      aid={aid}
    />
  );
}

const mapStateToProps = (state, props) => {
  const endUser = state.entities.endUsers[props.endUserId];

  return {
    avatar: endUser && endUser.avatar,
    channel: endUser && channelSmIcon(endUser.channelType),
    name: endUser && endUser.name,
    color: endUser && endUser.titleColor,
    userAid: endUser && endUser.aid
  };
};

export default connect(mapStateToProps)(NavPanelEndUserContainer);
