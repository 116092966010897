import React from "react";
import { Avatar, Button } from "design-system-react";
import styles from "./GroupItem.module.css";

function GroupItem({ avatar, name, link, handleAddGroup, isAdding, isAdded }) {
  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <Avatar image={avatar} />
        <div className={styles.desc}>
          <span className={styles.name}>{name}</span>
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            {link}
          </a>
        </div>
      </div>
      <Button
        outline
        onClick={handleAddGroup}
        processingText="Adding"
        processing={isAdding}
        disabled={isAdded}
      >
        {isAdded && "Added"}
        {isAdding && "Adding"}
        {!isAdded && !isAdding && "Add"}
      </Button>
    </div>
  );
}

export default GroupItem;
