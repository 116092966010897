import { withNamespace } from "utils/namespace";
import { SET_CHANNEL_IDS } from "./analyticsCases";

const type = withNamespace("analyticsTeams");

// action types
export const SET_TEAM_IDS = type("SET_TEAM_IDS");

let initialState = {
  teamIds: [],
  channelIds: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_TEAM_IDS:
      return {
        ...state,
        teamIds: action.payload.teamIds
      };

    case SET_CHANNEL_IDS:
      return {
        ...state,
        channelIds: action.payload.channelIds
      };

    default:
      return state;
  }
}

// actions
export function setTeamIds(teamIds) {
  return {
    type: SET_TEAM_IDS,
    payload: {
      teamIds
    }
  };
}

export function setChannelIds(channelIds) {
  return {
    type: SET_CHANNEL_IDS,
    payload: {
      channelIds
    }
  };
}
