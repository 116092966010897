import React, { useRef, useState, useCallback, useEffect } from "react";
import connect from "react-redux/es/connect/connect";
import { addTeamMemberTeamId } from "modules/pages/team";
import {
  Popup,
  PopupBody,
  PopupHeader,
  PopupFooter,
  Button
} from "design-system-react";
import { Formik } from "formik";
import { api } from "utils/client";
import AddTeamUserFormContainer from "./AddTeamUserFormContainer";
import { FormError } from "utils/formError";

function AddTeamUserModalContainer({
  isOpen,
  toggle,
  teamId,
  setTeamMemberForAddIds,
  addTeamMemberTeamId
}) {
  const form = useRef(null);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [teamMembers, setTeamMembers] = useState([]);

  useEffect(() => {
    if (isOpen) {
      const request = api.teams.membersForAdd(teamId, null);

      request.ready.then(response => {
        setTeamMembers(
          response.data.data.map(member => ({
            name: member.name || member.email,
            color: member.nameColor,
            status: member.status,
            avatar: member.avatar,
            value: member.id
          }))
        );
      });

      return () => {
        request.cancelRequest.cancel();
      };
    }
    // eslint-disable-next-line
  }, [isOpen]);

  const handleSubmit = useCallback(
    (values, { setSubmitting, setErrors }) => {
      api.teams
        .addMember(teamId, values)
        .ready.then(() => {
          addTeamMemberTeamId(values.teamMember);
          form.current.setFieldValue("teamMember", "");

          setTeamMembers(
            teamMembers.filter(member => member.value !== values.teamMember)
          );
        })
        .catch(e => {
          if (e instanceof FormError) {
            setErrors(e.errors);
          }
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [teamMembers, addTeamMemberTeamId, teamId]
  );

  return (
    <Formik
      ref={form}
      enableReinitialize
      onSubmit={handleSubmit}
      render={props => (
        <Popup isOpen={isOpen} onClose={toggle}>
          <PopupHeader>Add team member</PopupHeader>
          <PopupBody>
            <AddTeamUserFormContainer
              {...props}
              teamMembers={teamMembers}
              submitDisabled={submitDisabled}
              setSubmitDisabled={setSubmitDisabled}
            />
          </PopupBody>
          <PopupFooter>
            <Button
              onClick={props.submitForm}
              processing={props.isSubmitting}
              disabled={submitDisabled}
            >
              Add
            </Button>
            <Button outline={true} onClick={toggle}>
              Cancel
            </Button>
          </PopupFooter>
        </Popup>
      )}
    />
  );
}

const mapDispatchToProps = dispatch => ({
  addTeamMemberTeamId: id => dispatch(addTeamMemberTeamId(id))
});

export default connect(
  null,
  mapDispatchToProps
)(AddTeamUserModalContainer);
