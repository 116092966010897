import React from "react";
import styles from "./FormMessageBubble.module.css";
import MessageListItemBubble from "pages/Cases/components/MessageListItemBubble";

function FormMessageBubble({ status, time, outgoing, content }) {
  const fields = JSON.parse(content);

  return (
    <MessageListItemBubble status={status} dark={outgoing} time={time}>
      <div className={styles.form}>
        {Object.keys(fields)
          .filter(field => fields[field])
          .map(field => {
            return (
              <div className={styles.input}>
                <span>{fields[field]}</span>
                <img src="/images/check.svg" alt="" />
              </div>
            );
          })}
      </div>
    </MessageListItemBubble>
  );
}

export default FormMessageBubble;
