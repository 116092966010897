import { withNamespace } from "utils/namespace";

const type = withNamespace("pages/settingsPipedrive");

export const SET_INTEGRATION_PIPEDRIVE_IDS = type("SET_INTEGRATION_PIPEDRIVE_IDS");
export const ADD_INTEGRATION_PIPEDRIVE_ID = type("ADD_INTEGRATION_PIPEDRIVE_ID");
export const REMOVE_INTEGRATION_PIPEDRIVE_ID = type("REMOVE_INTEGRATION_PIPEDRIVE_ID");

let initialState = {
  ids: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_INTEGRATION_PIPEDRIVE_IDS:
      return {
        ...state,
        ids: action.payload.ids
      };
    case ADD_INTEGRATION_PIPEDRIVE_ID:
      return {
        ...state,
        ids: [action.payload.id, ...state.ids]
      };
    case REMOVE_INTEGRATION_PIPEDRIVE_ID:
      return {
        ...state,
        ids: state.ids.filter(id => id !== action.payload.id)
      };
    default:
      return state;
  }
}

// actions
export function setIntegrationPipedriveIds(ids) {
  return {
    type: SET_INTEGRATION_PIPEDRIVE_IDS,
    payload: {
      ids
    }
  };
}

export function addIntegrationPipedriveId(id) {
  return {
    type: ADD_INTEGRATION_PIPEDRIVE_ID,
    payload: {
      id
    }
  };
}

export function removeIntegrationPipedriveId(id) {
  return {
    type: REMOVE_INTEGRATION_PIPEDRIVE_ID,
    payload: {
      id
    }
  };
}
