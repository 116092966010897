import React from "react";
import styles from "./EndUserSidebar.module.css";
import DateTime from "components/DateTime";

function EndUserInputTime({ value, forceType }) {
  return (
    <span className={styles.time}>
      <DateTime forceType={forceType} time={value} />
    </span>
  );
}

export default EndUserInputTime;
