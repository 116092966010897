import React from "react";
import { Row, Container, Col, Scrollbar } from "design-system-react";
import SettingsChannelHeader from "components/SettingsHeader";
import { useSelector } from "react-redux";
import { api } from "utils/client";
import { List, Switch, Collapse, Image } from "antd";
import SettingsSection from "components/SettingsSection";
import View from "../../../components/View";
import {
  newCaseNotificationSoundSelector,
  newMessageNotificationSoundSelector
} from "selectors/teamMember";

const { Panel } = Collapse;

function SettingsNotificationsContainer() {
  const newCaseNotificationSound = useSelector(
    newCaseNotificationSoundSelector
  );

  const newMessageNotificationSound = useSelector(
    newMessageNotificationSoundSelector
  );

  return (
    <Row fullHeight column>
      <Container>
        <SettingsChannelHeader title="Notifications" noBorder>
          Here you can set up what notifications from us you want to receive.
        </SettingsChannelHeader>
      </Container>
      <Col justified>
        <Scrollbar>
          <Container>
            <View marginBottom={"20px"} marginTop={"20px"}>
              <SettingsSection title="Push notifications">
                <Collapse ghost>
                  <Panel header="See how to enable push notification in your browser">
                    <Image
                      src="https://storage.googleapis.com/hugery-public/notifications.gif"
                      width="60%"
                      style={{ border: "1px solid #eee" }}
                    />
                  </Panel>
                </Collapse>
              </SettingsSection>
            </View>
            <View marginBottom={"20px"}>
              <SettingsSection title="Sound notifications">
                <List itemLayout="horizontal">
                  <List.Item>
                    <List.Item.Meta title={"Play sound when a new case is"} />
                    <Switch
                      defaultChecked={newCaseNotificationSound}
                      onChange={() => {
                        api.teamMembers.updateOption(
                          "newCaseNotificationSound",
                          !newCaseNotificationSound
                        );
                      }}
                    />
                  </List.Item>
                  <List.Item>
                    <List.Item.Meta
                      title={"Play sound when a new message is"}
                    />
                    <Switch
                      defaultChecked={newMessageNotificationSound}
                      onChange={() => {
                        api.teamMembers.updateOption(
                          "newMessageNotificationSound",
                          !newMessageNotificationSound
                        );
                      }}
                    />
                  </List.Item>
                </List>
              </SettingsSection>
            </View>
          </Container>
        </Scrollbar>
      </Col>
    </Row>
  );
}

export default SettingsNotificationsContainer;
