import { useContext, useState } from "react";
import NavPanelContent from "components/NavPanel/NavPanelContent";
import NavPanel from "components/NavPanel";
import CollapseButton from "components/NavPanel/CollapseButton";
import { connect } from "react-redux";
import CollapseMenu from "components/CollapseMenu";
import { ToggableLayoutContext } from "components/ToggableLayout/ToggableLayoutContext";
import { createSelector } from "reselect";
import FilterModal, {
  dropdownOperators,
  nonEmptyDropdownOperators,
  OPERATOR_IN,
  OPERATOR_IS,
  OPERATOR_IS_NOT
} from "components/filterModal/FilterModal";
import {
  Button,
  Dropdown,
  Menu,
  message,
  Select,
  Space,
  Tag,
  Tooltip,
  Typography
} from "antd";
import {
  FilterOutlined,
  EllipsisOutlined,
  InfoCircleOutlined
} from "@ant-design/icons";
import SelectTeamInput from "components/selectTeamInput/SelectTeamInput";
import SelectTeamMemberInput from "components/selectTeamMemberInput/SelectTeamMemberInput";
import SelectChannelInput from "components/selectChannelInput/SelectChannelInput";
import {
  getEndUserQualificationColor,
  getEndUserQualificationTitle
} from "helpers/endUsers";

function filterButtonText(short, count) {
  if (count > 0) {
    return short ? "(" + count + ")" : "Filters (" + count + ")";
  }

  if (short) {
    return;
  }

  return "Filters";
}

const endUsersFilterFields = {
  // eslint-disable-next-line
  ["ownerTeam.id"]: {
    label: "Team",
    operators: dropdownOperators,
    renderInput: operator => {
      let input = null;
      switch (operator) {
        case OPERATOR_IS:
        case OPERATOR_IS_NOT:
          input = <SelectTeamInput />;
          break;
        case OPERATOR_IN:
          input = <SelectTeamInput mode="multiple" />;
          break;
        default:
          return null;
      }
      return input;
    }
  },
  // eslint-disable-next-line
  ["ownerTeamMember.id"]: {
    label: "Owner",
    operators: dropdownOperators,
    renderInput: operator => {
      let input = null;
      switch (operator) {
        case OPERATOR_IS:
        case OPERATOR_IS_NOT:
          input = <SelectTeamMemberInput />;
          break;
        case OPERATOR_IN:
          input = <SelectTeamMemberInput mode="multiple" />;
          break;
        default:
          return null;
      }
      return input;
    }
  },
  qualification: {
    label: "Status",
    operators: nonEmptyDropdownOperators,
    renderInput: operator => {
      let input = null;
      const endUserStatuses = [
        {
          label: (
            <Tag color={getEndUserQualificationColor("visitor")}>
              {getEndUserQualificationTitle("visitor")}
            </Tag>
          ),
          value: "visitor"
        },
        {
          label: (
            <Tag color={getEndUserQualificationColor("lead")}>
              {getEndUserQualificationTitle("lead")}
            </Tag>
          ),
          value: "lead"
        },
        {
          label: (
            <Tag color={getEndUserQualificationColor("customer")}>
              {getEndUserQualificationTitle("customer")}
            </Tag>
          ),
          value: "customer"
        }
      ];
      switch (operator) {
        case OPERATOR_IS:
        case OPERATOR_IS_NOT:
          input = <Select options={endUserStatuses} />;
          break;
        case OPERATOR_IN:
          input = <Select options={endUserStatuses} mode="multiple" />;
          break;
        default:
          return null;
      }
      return input;
    }
  },
  channelId: {
    label: "Channel",
    operators: dropdownOperators,
    renderInput: operator => {
      let input = null;
      switch (operator) {
        case OPERATOR_IS:
        case OPERATOR_IS_NOT:
          input = <SelectChannelInput />;
          break;
        case OPERATOR_IN:
          input = <SelectChannelInput mode="multiple" />;
          break;
        default:
          return null;
      }
      return input;
    }
  }
};

function EndUsersNavPanel({
  views,
  activeView,
  filter,
  onFilter,
  pagination,
  setVisibleBulkUpdateModal,
  bulkActions,
  onBulkActionsToggle,
  selectedEndUsers
}) {
  const [visibleFilterModal, setVisibleFilterModal] = useState(false);

  const context = useContext(ToggableLayoutContext);

  // const view = views.find(view => view.name === activeView);
  return (
    <NavPanel borderBottom>
      <FilterModal
        title={"Filter contacs"}
        visible={visibleFilterModal}
        filter={filter}
        entityFields={endUsersFilterFields}
        onCancel={() => {
          setVisibleFilterModal(false);
        }}
        onFilter={values => {
          setVisibleFilterModal(false);
          onFilter(values);
        }}
      />
      <NavPanelContent>
        <CollapseButton
          handleClick={context.toggleSubmenu}
          isOpen={context.isSubmenuOpened}
        />
        <CollapseMenu
          title={
            "All contacts" +
            (pagination.total ? " (" + pagination.total + ")" : "")
          } //TODO: hardcoded to All while 1 item
          // title={view && view.title}
          withoutDropdown={context.isSubmenuOpened}
          items={views.map(view => {
            let active = activeView === view.name;
            return {
              id: view.id,
              to: "/end-users/" + view.name,
              active,
              title: "All contacts", //TODO: hardcoded to All while 1 item
              // title: view.title,
              counter: view.count
            };
          })}
        />
      </NavPanelContent>
      <NavPanelContent>
        {!bulkActions && (
          <Space>
            <Button
              type={filter.filters.length > 0 ? "primary" : "default"}
              icon={<FilterOutlined />}
              onClick={() => {
                setVisibleFilterModal(true);
              }}
            >
              {filterButtonText(false, filter.filters.length)}
            </Button>
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item
                    onClick={() => {
                      onBulkActionsToggle(true);
                    }}
                  >
                    Bulk edit
                  </Menu.Item>
                </Menu>
              }
              placement={"bottomRight"}
              trigger={["click"]}
            >
              <Button icon={<EllipsisOutlined />} />
            </Dropdown>
          </Space>
        )}
        {bulkActions && (
          <Space>
            {
              <Typography.Text type="secondary">
                <Tooltip
                  placement="bottom"
                  title="You can select up to 30 cases simultaneously"
                >
                  <InfoCircleOutlined />
                </Tooltip>{" "}
                Selected: {selectedEndUsers.length} contact(s){" "}
              </Typography.Text>
            }
            <Button
              onClick={() => {
                if (selectedEndUsers.length === 0) {
                  message.info("Please select at least one contact");

                  return;
                }

                setVisibleBulkUpdateModal(true);
              }}
            >
              Edit
            </Button>
            <Button
              type="primary"
              onClick={() => {
                onBulkActionsToggle(false);
              }}
              danger
            >
              Cancel
            </Button>
          </Space>
        )}
      </NavPanelContent>
    </NavPanel>
  );
}

const getViews = createSelector(
  [state => state.entities.views, state => state.endUserViews.ids],
  (views, ids) => {
    return ids.map(id => views[id]);
  }
);

const mapStateToProps = state => {
  return {
    views: getViews(state)
  };
};

export default connect(mapStateToProps)(EndUsersNavPanel);
