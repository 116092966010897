import React, { useEffect } from "react";
import EndUsersContainer from "./containers/EndUsersContainer";
import ToggableLayout from "components/ToggableLayout";
import EndUsersSubMenuContainer from "./containers/EndUsersSubMenuContainer";

function EndUsers({ match }) {
  useEffect(() => {
    document.title = "Contacts";
  }, []);

  return (
    <ToggableLayout
      renderSubmenu={() => (
        <EndUsersSubMenuContainer activeMenuItem={match.params.view} />
      )}
      renderContent={() => (
        <EndUsersContainer
          view={match.params.view}
          activeMenuItem={match.url}
        />
      )}
    />
  );
}

export default EndUsers;
