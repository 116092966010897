import React, { useEffect, useState, useCallback } from "react";
import { Field, Formik } from "formik";
import {
  Button,
  PopupHeader,
  Popup,
  PopupBody,
  PopupFooter,
  GutterField
} from "design-system-react";
import { api } from "utils/client";
import { FormError } from "utils/formError";
import { ALERT_SUCCESS, showAlert } from "utils/alertManager";
import useFormSubmission from "hooks/useFormSubmission";
import InputField from "components/InputField";

function Form({ submitDisabled, setSubmitDisabled, handleSubmit, values }) {
  const handleFormSubmit = useFormSubmission(submitDisabled, handleSubmit);

  useEffect(() => {
    if (values) {
      if (
        values.currentPassword === "" ||
        values.newPassword === "" ||
        values.confirmPassword === ""
      ) {
        setSubmitDisabled(true);
      } else {
        setSubmitDisabled(false);
      }
    }
  }, [values, setSubmitDisabled]);

  return (
    <form onSubmit={handleFormSubmit}>
      <GutterField>
        <Field
          name="currentPassword"
          type="password"
          label="Current password"
          component={InputField}
        />
      </GutterField>
      <GutterField>
        <Field
          name="newPassword"
          type="password"
          label="New password"
          component={InputField}
        />
      </GutterField>
      <Field
        name="confirmPassword"
        type="password"
        label="Confirm password"
        component={InputField}
      />
    </form>
  );
}

function ChangePasswordModalContainer({ isOpen, toggle }) {
  const [initialSaved, setInitialSaved] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);

  const handleSubmit = useCallback(
    (values, { setSubmitting, setErrors, setFieldValue }) => {
      // if (!values.newPassword.match(/^[a-z0-9_-]{8,50}$/)) {
      //   setErrors({
      //     newPassword: "Password is not valid!"
      //   });
      //   setSubmitting(false);
      //   return;
      // }

      if (values.newPassword !== values.confirmPassword) {
        setErrors({ confirmPassword: "These passwords don't match!" });
        setSubmitting(false);
        return;
      }

      api.teamMembers
        .changePassword(values)
        .ready.then(() => {
          setFieldValue("currentPassword", "");
          setFieldValue("newPassword", "");
          setFieldValue("confirmPassword", "");
          toggle();
          showAlert(ALERT_SUCCESS, "Password was successfully changed");
        })
        .catch(e => {
          if (e instanceof FormError) {
            setErrors(e.errors);
          }
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [toggle]
  );

  return (
    <Formik
      initialValues={{
        currentPassword: "",
        newPassword: "",
        confirmPassword: ""
      }}
      onSubmit={handleSubmit}
      render={props => (
        <Popup isOpen={isOpen} onClose={toggle}>
          <PopupHeader>Change password</PopupHeader>
          <PopupBody>
            <Form
              setInitialSaved={setInitialSaved}
              setSubmitDisabled={setSubmitDisabled}
              {...props}
            />
          </PopupBody>
          <PopupFooter>
            <Button
              onClick={props.submitForm}
              processing={props.isSubmitting}
              disabled={submitDisabled}
            >
              {submitDisabled && initialSaved ? "Saved" : "Save"}
            </Button>
            <Button outline={true} onClick={toggle}>
              Cancel
            </Button>
          </PopupFooter>
        </Popup>
      )}
    />
  );
}

export default ChangePasswordModalContainer;
