import React from "react";
import styles from "pages/Cases/components/MessageListItemTime.module.css";
import { TYPE_TIME } from "utils/dateTime";
import DateTime from "components/DateTime";
import PropTypes from "prop-types";

function MessageListItemTime({ time }) {
  return (
    <div className={styles.time}>
      <DateTime forceType={TYPE_TIME} time={time} />
    </div>
  );
}

MessageListItemTime.propTypes = {
  time: PropTypes.string.isRequired
};

export default MessageListItemTime;
