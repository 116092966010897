import React from "react";
import { connect } from "react-redux";
import EndUserSidebarContent from "../components/EndUserSidebarContent";
import Collapse from "components/Collapse";
import { Input, GutterField } from "design-system-react";
import {
  CHANNEL_TYPE_BLINGER,
  CHANNEL_TYPE_EMAIL,
  CHANNEL_TYPE_FACEBOOK,
  CHANNEL_TYPE_TELEGRAMBOT,
  CHANNEL_TYPE_TYNTEC_WHATSAPP,
  CHANNEL_TYPE_VIBER,
  CHANNEL_TYPE_VK,
  CHANNEL_TYPE_WHATSAPP
} from "helpers/channels";
import EndUserInputTime from "../components/EndUserInputTime";
import { TYPE_FULL } from "utils/dateTime";
import EndUserExtraFieldItemContainer from "pages/EndUser/containers/EndUserExtraFieldItemContainer";

function EndUserSidebarContainer({ endUser, extraFieldIds }) {
  const channelFields = channel => {
    switch (channel) {
      case CHANNEL_TYPE_FACEBOOK:
        return null;
      case CHANNEL_TYPE_WHATSAPP:
        return (
          <Collapse header="WhatsApp">
            {endUser.channelFields.name &&
            endUser.channelFields.name !==
            endUser.channelFields.phoneNumber && (
              <GutterField gutterBottom={20}>
                <Input
                  label="Name"
                  value={endUser.channelFields.name}
                  size="sm"
                  disabled
                />
              </GutterField>
            )}
            <GutterField gutterBottom={20}>
              <Input
                label="Phone"
                value={endUser.channelFields.phoneNumber}
                size="sm"
                disabled
              />
            </GutterField>
          </Collapse>
        );
      case CHANNEL_TYPE_TYNTEC_WHATSAPP:
        return (
          <Collapse header="WhatsApp">
            {endUser.channelFields.name &&
            endUser.channelFields.name !==
            endUser.channelFields.phoneNumber && (
              <GutterField gutterBottom={20}>
                <Input
                  label="Name"
                  value={endUser.channelFields.name}
                  size="sm"
                  disabled
                />
              </GutterField>
            )}
            <GutterField gutterBottom={20}>
              <Input
                label="Phone"
                value={endUser.channelFields.phoneNumber}
                size="sm"
                disabled
              />
            </GutterField>
          </Collapse>
        );
      case CHANNEL_TYPE_TELEGRAMBOT:
        return (
          <Collapse header="Telegram">
            <GutterField gutterBottom={20}>
              <Input
                label="Name"
                value={endUser.channelFields.name}
                size="sm"
                disabled
              />
            </GutterField>
            {endUser.channelFields.language && (
              <GutterField gutterBottom={20}>
                <Input
                  label="Language"
                  value={endUser.channelFields.language}
                  size="sm"
                  disabled
                />
              </GutterField>
            )}
            {endUser.channelFields.userName && (
              <GutterField gutterBottom={20}>
                <Input
                  label="User name"
                  value={"@" + endUser.channelFields.userName}
                  size="sm"
                  disabled
                />
              </GutterField>
            )}
            <GutterField gutterBottom={20}>
              <Input
                label="User ID"
                value={endUser.channelFields.id}
                size="sm"
                disabled
              />
            </GutterField>
          </Collapse>
        );
      case CHANNEL_TYPE_VIBER:
        return (
          <Collapse header="Viber">
            <GutterField gutterBottom={20}>
              <Input
                label="Name"
                value={endUser.channelFields.name}
                size="sm"
                disabled
              />
            </GutterField>
            <GutterField gutterBottom={20}>
              <Input
                label="Language"
                value={endUser.channelFields.language}
                size="sm"
                disabled
              />
            </GutterField>
          </Collapse>
        );
      case CHANNEL_TYPE_VK:
        return (
          <Collapse header="VK">
            <GutterField gutterBottom={20}>
              <Input
                label="Name"
                value={endUser.channelFields.name}
                size="sm"
                disabled
              />
            </GutterField>
            {endUser.channelFields.language && (
              <GutterField gutterBottom={20}>
                <Input
                  label="Language"
                  value={endUser.channelFields.language}
                  size="sm"
                  disabled
                />
              </GutterField>
            )}
            {endUser.channelFields.birth_date && (
              <GutterField gutterBottom={20}>
                <Input
                  label="Birthday"
                  value={endUser.channelFields.birth_date}
                  size="sm"
                  disabled
                />
              </GutterField>
            )}
            {endUser.channelFields.sex && (
              <GutterField gutterBottom={20}>
                <Input
                  label="Gender"
                  value={endUser.channelFields.sex}
                  size="sm"
                  disabled
                />
              </GutterField>
            )}
            {endUser.channelFields.screen_name && (
              <GutterField gutterBottom={20}>
                <Input
                  label="Screen name"
                  value={endUser.channelFields.screen_name}
                  size="sm"
                  disabled
                />
              </GutterField>
            )}
            {endUser.channelFields.company && (
              <GutterField gutterBottom={20}>
                <Input
                  label="Company"
                  value={endUser.channelFields.company}
                  size="sm"
                  disabled
                />
              </GutterField>
            )}

            {endUser.channelFields.site && (
              <GutterField gutterBottom={20}>
                <Input
                  label="Site"
                  value={endUser.channelFields.site}
                  size="sm"
                  disabled
                />
              </GutterField>
            )}
            <GutterField gutterBottom={20}>
              <Input
                label="User ID"
                value={endUser.channelFields.id}
                size="sm"
                disabled
              />
            </GutterField>
          </Collapse>
        );
      case CHANNEL_TYPE_BLINGER:
        return null;
      case CHANNEL_TYPE_EMAIL:
        return (
          <Collapse header="Email">
            {endUser.channelFields.name && (
              <GutterField gutterBottom={20}>
                <Input
                  label="Name"
                  value={endUser.channelFields.name}
                  size="sm"
                  disabled
                />
              </GutterField>
            )}
            {endUser.channelFields.email && (
              <GutterField gutterBottom={20}>
                <Input
                  label="Email"
                  value={endUser.channelFields.email}
                  size="sm"
                  disabled
                />
              </GutterField>
            )}
          </Collapse>
        );
      default:
        return null;
    }
  };

  return (
    <EndUserSidebarContent>
      <Collapse header="User  details">
        <GutterField gutterBottom={20}>
          <Input
            readOnly
            label="User ID"
            value={"#" + endUser.aid}
            size="sm"
            disabled
          />
        </GutterField>
        <GutterField gutterBottom={20}>
          <Input
            label="Created"
            appendAfter={
              <EndUserInputTime
                value={endUser.createdAt}
                forceType={TYPE_FULL}
              />
            }
            size="sm"
            disabled
          />
        </GutterField>
        <GutterField gutterBottom={20}>
          <Input
            label="Updated"
            appendAfter={
              <EndUserInputTime
                value={endUser.updatedAt}
                forceType={TYPE_FULL}
              />
            }
            size="sm"
            disabled
          />
        </GutterField>
        <GutterField gutterBottom={20}>
          <Input
            label="Last activity"
            appendAfter={
              <EndUserInputTime
                value={endUser.lastActivityAt}
                forceType={TYPE_FULL}
              />
            }
            size="sm"
            disabled
          />
        </GutterField>
      </Collapse>
      <Collapse header="Contact details">
        {extraFieldIds.map(id => {
          return (
            <EndUserExtraFieldItemContainer
              key={id}
              extraFieldId={id}
              endUserId={endUser.id}
            />
          );
        })}
      </Collapse>
      {channelFields(endUser.channelType)}
    </EndUserSidebarContent>
  );
}

const mapStateToProps = (state, props) => {
  return {
    endUser:
      state.entities.endUsers && state.entities.endUsers[props.endUserId],
    extraFieldIds: state.endUserExtraFields.ids || []
  };
};

export default connect(mapStateToProps)(EndUserSidebarContainer);
