import "moment-timezone";

export const TYPE_NOW = "type_now";
export const TYPE_AGO = "type_ago";
export const TYPE_FULL = "type_full";
export const TYPE_FULL_WORDS = "type_full_words";
export const TYPE_FULL_YEAR = "type_full_year";
export const TYPE_FULL_ABSOLUTELY = "type_full_absolutely";
export const TYPE_TIME = "type_time";
export const TYPE_DAY = "type_day";
export const TYPE_FULL_AT = "type_full_at";

export function niceDate(time) {
  const DATE_NOW = 60;
  const DATE_AGO = 60 * 60;
  const DATE_FULL = 60 * 60 * 24 * 365;

  const currentTime = Number(new Date().getTime() / 1000);
  const leftTime = currentTime - time;

  let type = TYPE_FULL_YEAR;
  switch (true) {
    case leftTime < DATE_NOW:
      type = TYPE_NOW;
      break;
    case leftTime < DATE_AGO:
      type = TYPE_AGO;
      break;
    case leftTime < DATE_FULL:
      type = TYPE_FULL;
      break;
    default:
      break;
  }

  return type;
}

export function msToMinutesAndSeconds(milliseconds) {
  var minutes = Math.floor(milliseconds / 60000);
  var seconds = ((milliseconds % 60000) / 1000).toFixed(0);

  return (
    (minutes < 10 ? "0" : "") +
    minutes +
    ":" +
    (seconds < 10 ? "0" : "") +
    seconds
  );
}
