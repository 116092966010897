import { connect } from "react-redux";
import CaseChannel from "../components/CaseChannel";

const mapStateToProps = (state, props) => {
  const channel = state.entities.channels[props.channelId];

  return {
    title: channel && channel.title,
    label: channel && channel.label
  };
};

export default connect(mapStateToProps)(CaseChannel);
