import { Alert, Modal, Space, Switch, Typography, Form } from "antd";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import { useCallback, useState, useMemo } from "react";
import { api } from "utils/client";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { AVALABILITY_ACTIVE, AVALABILITY_INACTIVE } from "helpers/teamMembers";
import TeamMemberSubOwnerInput from "./TeamMemberSubOwnerInput";
import View from "./View";
import { message } from "antd";
import uuidv4 from "utils/uuidv4";

export interface TeamMemberActiveSwitcherProps {
  teamMember?: any;
  isCurrentTeamMember?: boolean;
  checked?: boolean;
  label?: string;
  labelPlacement?: "left" | "right";
}

function TeamMemberActiveSwitcher({
  teamMember,
  isCurrentTeamMember,
  checked,
  label = "Switch my activity status",
  labelPlacement = "right"
}: TeamMemberActiveSwitcherProps) {
  const [value, setValue] = useState(checked);
  const [loading, setLoading] = useState(false);

  const updateAvailability = useCallback(
    (
      teamMemberId,
      status,
      subOwnerTeamId?: any,
      subOwnerTeamMemberId?: any
    ) => {
      api.teamMembers
        .updateAvailability(
          teamMemberId,
          status,
          subOwnerTeamId,
          subOwnerTeamMemberId
        )
        .ready.then((response: any) => {
          setValue(response.data.data.availability === AVALABILITY_ACTIVE);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    []
  );

  const handleSwitch = useCallback(
    switchValue => {
      setLoading(true);

      if (switchValue) {
        updateAvailability(teamMember.id, AVALABILITY_ACTIVE);
        return;
      }

      api.views
        .oneByName(teamMember.id, "cases", "assigned_to_me")
        .ready.then(response => {
          const assignedCasesCount = response.data.data.count;

          let selectedTeam: any = null;
          let selectedTeamMember: any = null;

          Modal.confirm({
            title: "Switch status",
            width: "480px",
            content: (
              <>
                <Typography.Paragraph>
                  {isCurrentTeamMember
                    ? "Switch status to non-active?"
                    : "Switch status to non-active for the user account " +
                      teamMember.name +
                      " ?"}
                </Typography.Paragraph>
                <Form layout="horizontal">
                  <Form.Item
                    label="Sub-owner"
                    requiredMark="optional"
                    help="Choose temporary owner of your contacts"
                  >
                    <TeamMemberSubOwnerInput
                      disabledMembers={[teamMember.id]}
                      onChange={(team, teamMember) => {
                        selectedTeam = team;
                        selectedTeamMember = teamMember;
                      }}
                    />
                  </Form.Item>
                </Form>
                {assignedCasesCount > 0 && (
                  <View marginTop="20px">
                    <Alert
                      type="info"
                      message={
                        isCurrentTeamMember
                          ? "You have " +
                            assignedCasesCount +
                            " opened case(s). Before you switch the status to non-active, we'd recommend you reassign or close these cases. Otherwise, very likely, new messages in the cases will be ignored until you turn back."
                          : teamMember.name +
                            " has " +
                            assignedCasesCount +
                            " opened case(s). Before you switch the status to non-active, we'd recommend to reassign or close these cases. Otherwise, very likely, new messages in the cases will be ignored until " +
                            teamMember.name +
                            " turns back."
                      }
                    />
                  </View>
                )}
              </>
            ),
            icon: <ExclamationCircleOutlined />,
            onOk: () => {
              updateAvailability(
                teamMember.id,
                AVALABILITY_INACTIVE,
                selectedTeam &&
                  (selectedTeam === "none" ? "none" : selectedTeam.id),
                selectedTeamMember &&
                  (selectedTeamMember === "none"
                    ? "none"
                    : selectedTeamMember.id)
              );
            },
            onCancel: () => {
              setLoading(false);
            }
          });
        })
        .catch(() => {
          setLoading(false);
        });
    },
    [teamMember.id, teamMember.name, isCurrentTeamMember, updateAvailability]
  );

  const disabledMembers = useMemo(() => {
    return [teamMember.id];
  }, [teamMember.id]);

  const subOwner = useMemo(() => {
    return (
      <Typography.Text type="secondary">
        (Sub-owner:{" "}
        {teamMember.subOwnerTeamMember && teamMember.subOwnerTeamMember.name
          ? teamMember.subOwnerTeamMember.name
          : "None"}
        ,{" "}
        <TeamMemberSubOwnerInput
          disabledMembers={disabledMembers}
          onChange={(subOwnerTeam, subOwnerTeamMember) => {
            const id = uuidv4();

            message.loading({
              content: "Action in progress...",
              key: id
            });

            api.teamMembers
              .updateSubOwner(
                teamMember.id,
                subOwnerTeam.id || "none",
                subOwnerTeamMember.id || "none"
              )
              .ready.then(() => {
                message.success({
                  content: "Sub-owner was changed successfully.",
                  key: id
                });
              })
              .catch(() => {
                message.error({
                  content: "Something went wrong.",
                  key: id
                });
              });
          }}
          customTrigger={<a href="/#">Change</a>}
        />
        )
      </Typography.Text>
    );
  }, [teamMember.id, teamMember.subOwnerTeamMember, disabledMembers]);

  return (
    <div style={{ display: "flex", color: "#000" }}>
      <Space>
        <span>
          {labelPlacement === "left" && (
            <>
              {label}{" "}
              {teamMember.availability !== AVALABILITY_ACTIVE && subOwner}
            </>
          )}
        </span>
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          checked={value}
          onChange={handleSwitch}
          loading={loading}
        />
        {labelPlacement === "right" && (
          <span>
            {label} {teamMember.availability !== AVALABILITY_ACTIVE && subOwner}
          </span>
        )}
      </Space>
    </div>
  );
}

export default TeamMemberActiveSwitcher;
