import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import styles from "./MessageFormInput.module.css";

function MessageFormInput({ innerRef, value, onChange, onKeyDown }) {
  const [rows, setRows] = useState(1);
  const textareaRef = useRef();

  useEffect(() => {
    if (!value && rows > 0) {
      setRows(1);
    }
  }, [value, rows]);

  useLayoutEffect(() => {
    const textareaLineHeight = 20;
    const minRows = 1;
    const maxRows = 5;

    const previousRows = textareaRef.current.rows;
    textareaRef.current.rows = minRows; // reset number of rows in textarea

    const currentRows = Math.floor(
      (textareaRef.current.scrollHeight - 22 - 20) / textareaLineHeight
    );

    if (currentRows === previousRows) {
      textareaRef.current.rows = currentRows;
    }

    if (currentRows >= maxRows) {
      textareaRef.current.rows = maxRows;
      textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
    }

    setRows(Math.min(currentRows, maxRows));

    // onChange(event);
  }, [value]);

  return (
    <div className={styles.formInput}>
      <textarea
        id="message_form_input"
        placeholder="Write a message or type / to add a quick reply..."
        ref={node => {
          textareaRef.current = node;
          if (innerRef) {
            innerRef.current = node;
          }
        }}
        className={styles.formTextarea}
        value={value}
        rows={rows}
        onChange={onChange}
        onKeyDown={onKeyDown}
        autoFocus
      />
    </div>
  );
}

MessageFormInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired
};

export default MessageFormInput;
