import React, { useEffect, useMemo, useCallback } from "react";
import { Formik } from "formik";
import { Button } from "design-system-react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { translation_default } from "helpers/translations";
import { api } from "utils/widgetClient";
import { setSetup, setWelcome } from "modules/widgetSetup";
import useStepPage from "hooks/useStepPage";
import { STEP_AVAILABILITY } from "helpers/steps";
import { ALERT_SUCCESS, showAlert } from "utils/alertManager";
import { FormError } from "utils/formError";
import WidgetFooter from "components/Widget/WidgetFooter";
import WidgetFormLayout from "components/Widget/WidgetFormLayout";
import WidgetWelcomeFormContainer from "./WidgetWelcomeFormContainer";

let noMutableInitial = {};

function WidgetWelcomeContainer({ setWelcome, setSetup, setup }) {
  const page = useStepPage();
  const defaultLang = setup.introduce.defaultLanguage;

  const initialValues = useMemo(() => {
    return {
      teamMessage:
        setup.welcome.teamMessage ||
        translation_default.teamMessage[defaultLang],
      helloMessage:
        setup.welcome.helloMessage ||
        translation_default.helloMessage[defaultLang],
      offlineMessage:
        setup.welcome.offlineMessage ||
        translation_default.offlineMessage[defaultLang],
      translations: {
        teamMessage: setup.translations.teamMessage,
        helloMessage: setup.translations.helloMessage,
        offlineMessage: setup.translations.offlineMessage
      }
    };
  }, [defaultLang, setup.welcome, setup.translations]);

  //Заполнение формы, если они были сохранены
  useEffect(() => {
    document.title = "Welcome your customers";

    noMutableInitial = initialValues;

    return () => {
      setWelcome(noMutableInitial);
    };
    // eslint-disable-next-line
  }, [setWelcome]);

  const handleSubmitForm = useCallback(
    (values, { setErrors, resetForm }) => {
      if (!page.loading) {
        page.setLoading(true);

        api.widget
          .updateWelcome(setup.id, values)
          .ready.then(response => {
            noMutableInitial = response.data.data.welcome;
            setSetup(response.data.data);
            if (response.data.data.step === STEP_AVAILABILITY) {
              page.setRedirectToNextStep(true);
            } else {
              showAlert(
                ALERT_SUCCESS,
                "Welcome your customers saved successfully"
              );
            }
            page.setInitialSaved(true);
            page.setSubmitDisabled(true);
            resetForm(values);
          })
          .catch(e => {
            if (e instanceof FormError) {
              setErrors(e.errors);
            }
          })
          .finally(() => {
            page.setLoading(false);
          });
      }
    },
    [page, setSetup, setup.id]
  );

  if (page.redirectToBackStep) {
    return <Redirect to={"/widget/style"} />;
  }

  if (page.redirectToNextStep) {
    return <Redirect to={"/widget/availability"} />;
  }

  return (
    <WidgetFormLayout>
      <Formik
        ref={page.form}
        onSubmit={handleSubmitForm}
        initialValues={initialValues}
        render={props => (
          <WidgetWelcomeFormContainer
            {...props}
            submitDisabled={page.submitDisabled}
            setSubmitDisabled={page.setSubmitDisabled}
          />
        )}
      />
      <WidgetFooter>
        <Button
          processing={page.loading}
          disabled={page.submitDisabled}
          onClick={page.handleNextClick}
        >
          Save
        </Button>
      </WidgetFooter>
    </WidgetFormLayout>
  );
}

const mapStateToProps = state => {
  return {
    setup: state.widgetSetup.current
  };
};

const mapDispatchToProps = dispatch => ({
  setSetup: setup => dispatch(setSetup(setup)),
  setWelcome: welcome => dispatch(setWelcome(welcome))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WidgetWelcomeContainer);
