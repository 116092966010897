import moment from "moment";

export function autoResponseActionItems() {
  let items = [];

  /*can(CHANNEL_RESTORE_PERMISSION) && */
  items.push({ key: "edit", label: "Edit" });
  /*can(CHANNEL_DELETE_PERMISSION) && */
  items.push({ key: "delete", label: "Delete" });

  return items;
}

export const timezones = [
  { label: "(UTC +14:00) Pacific/Kiritimati", value: "Pacific/Kiritimati" },
  { label: "(UTC +13:00) Pacific/Samoa", value: "Pacific/Samoa" },
  { label: "(UTC +12:45) Pacific/Chatham Islands", value: "Pacific/Chatham" },
  { label: "(UTC +12:00) Pacific/Auckland", value: "Pacific/Auckland" },
  { label: "(UTC +11:00) Pacific/Norfolk", value: "Pacific/Norfolk" },
  {
    label: "(UTC +10:30) Australia/Lord Howe Island",
    value: "Australia/Lord_Howe"
  },
  { label: "(UTC +10:00) Australia/Sydney", value: "Australia/Sydney" },
  { label: "(UTC +9:30) Australia/Adelaide", value: "Australia/Adelaide" },
  { label: "(UTC +9:00) Asia/Tokyo", value: "Asia/Tokyo" },
  { label: "(UTC +8:45) Australia/Eucla", value: "Australia/Eucla" },
  { label: "(UTC +8:00) Asia/Shanghai", value: "Asia/Shanghai" },
  { label: "(UTC +7:00) Asia/Bangkok", value: "Asia/Bangkok" },
  { label: "(UTC +6:30) Asia/Yangon", value: "Asia/Yangon" },
  { label: "(UTC +6:00) Asia/Almaty", value: "Asia/Almaty" },
  { label: "(UTC +5:45) Asia/Kathmandu", value: "Asia/Kathmandu" },
  { label: "(UTC +5:30) Asia/Colombo", value: "Asia/Colombo" },
  { label: "(UTC +5:00) Asia/Tashkent", value: "Asia/Tashkent" },
  { label: "(UTC +4:30) Asia/Kabul", value: "Asia/Kabul" },
  { label: "(UTC +4:00) Asia/Dubai", value: "Asia/Dubai" },
  { label: "(UTC +3:00) Europe/Moscow", value: "Europe/Moscow" },
  { label: "(UTC +2:00) Europe/Kaliningrad", value: "Europe/Kaliningrad" },
  { label: "(UTC +1:00) Europe/Amsterdam", value: "Europe/Amsterdam" },
  { label: "(UTC ±0:00) Europe/London", value: "Europe/London" },
  { label: "(UTC -1:00) Atlantic/Azores Islands", value: "Atlantic/Azores" },
  { label: "(UTC -2:00) America/Noronha", value: "America/Noronha" },
  {
    label: "(UTC -3:00) America/Buenos Aires",
    value: "America/Argentina/Buenos_Aires"
  },
  { label: "(UTC -4:00) America/Santiago", value: "America/Santiago" },
  { label: "(UTC -5:00) America/New York", value: "America/New_York" },
  { label: "(UTC -6:00) America/Chicago", value: "America/Chicago" },
  { label: "(UTC -7:00) America/Phoenix", value: "America/Phoenix" },
  { label: "(UTC -8:00) America/Los Angeles", value: "America/Los_Angeles" },
  { label: "(UTC -9:00) America/Anchorage", value: "America/Anchorage" },
  {
    label: "(UTC -9:30) Pacific/Marquesas Islands",
    value: "Pacific/Marquesas"
  },
  { label: "(UTC -10:00) Pacific/Honolulu", value: "Pacific/Honolulu" },
  { label: "(UTC -11:00) Pacific/Niue", value: "Pacific/Niue" }
];

let timeSeries = [];

export const getTimeSeries = () => {
  if (!timeSeries || timeSeries.length === 0) {
    for (let hour = 0; hour < 24; hour++) {
      timeSeries.push({
        label: moment({ hour }).format("HH:mm"),
        value: moment({ hour }).format("HH:mm:00")
      });
      timeSeries.push({
        label: moment({ hour, minute: 30 }).format("HH:mm"),
        value: moment({ hour, minute: 30 }).format("HH:mm:00")
      });
    }
  }

  return timeSeries;
};
