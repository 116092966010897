import React, { useEffect, useCallback } from "react";
import SettingsSubMenu from "components/settings/SettingsSubMenu";
import SettingsVkContainer from "./containers/SettingsVkContainer";
import SettingsLayout from "components/SettingsLayout";

function SettingsVk() {
  useEffect(() => {
    document.title = "Vk Settings";
    localStorage.setItem("lastSettingRoute", "/settings/vk");
  }, []);

  const renderSubmenu = useCallback(
    () => <SettingsSubMenu activeMenuItem={"/settings/vk"} />,
    []
  );
  const renderContent = useCallback(() => <SettingsVkContainer />, []);

  return (
    <SettingsLayout
      renderSubmenu={renderSubmenu}
      renderContent={renderContent}
    />
  );
}

export default SettingsVk;
