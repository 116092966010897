export const currentUserId = state => {
  return state.auth.userId;
};

export const currentUserRoleSelector = state => {
  const member = state.entities.teamMembers[currentUserId(state)];

  return state.entities.roles[member.role];
};

export const currentUserRoleNameSelector = state => {
  const role = currentUserRoleSelector(state);

  return role.name;
};

export const currentUserTeamIdsSelector = state => {
  const member = state.entities.teamMembers[currentUserId(state)];

  return member.teams;
};
