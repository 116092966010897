import React from "react";
import { Avatar, Preloader } from "design-system-react";
import { extractFirstLetters } from "utils/string";
import classNames from "classnames";
import styles from "pages/Cases/components/MessageListEmailItem.module.css";
import { TYPE_FULL_AT } from "../../../utils/dateTime";
import { DateTime } from "../../../components/DateTime";
import MessageListEmailContentContainer from "../containers/MessageListEmailContentContainer";
import MessageListEmailFileContentContainer from "../containers/MessageListEmailFileContentContainer";
import { connect } from "react-redux";

function MessageListEmailItem({
  messageContentIds,
  updatedAt,
  endUserAvatar,
  endUserName,
  endUserTitleColor,
  endUserChannelFields,
  teamMemberAvatar,
  teamMemberName,
  teamMemberNameColor,
  outgoing,
  caseItem,
  attachmentCount
}) {
  const classes = classNames(
    styles.messageListEmailItem,
    outgoing && styles.outgoing
  );

  return (
    <div className={classNames(styles.messageListEmail)}>
      <div className={classes}>
        <div className={styles.emailHeader}>
          <div className={styles.sendIcon}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="8"
              viewBox="0 0 8 8"
            >
              <path
                id="Rounded_Rectangle_999"
                data-name="Rounded Rectangle 999"
                className="cls-1"
                fill="#7d7e80"
                d="M451.829,746.829a0.494,0.494,0,0,1-.7,0L445,740.7v5.8a0.5,0.5,0,0,1-1,0v-7h0a0.49,0.49,0,0,1,.125-0.321h0a0.32,0.32,0,0,1,.016-0.039c0.01-.01.027-0.006,0.038-0.015h0A0.49,0.49,0,0,1,444.5,739h7a0.5,0.5,0,0,1,0,1h-5.8l6.13,6.13A0.494,0.494,0,0,1,451.829,746.829Z"
                transform="translate(-444 -739)"
              />
            </svg>
          </div>

          <div className={styles.infoWrapper}>
            <div className={styles.avatarBlock}>
              {(endUserAvatar || endUserName) && (
                <Avatar
                  size="size40"
                  color={endUserTitleColor}
                  image={endUserAvatar}
                  text={extractFirstLetters(endUserName, 2)}
                />
              )}

              {(teamMemberAvatar || teamMemberName) && (
                <Avatar
                  size="size40"
                  color={teamMemberNameColor}
                  image={teamMemberAvatar}
                  text={extractFirstLetters(teamMemberName, 2)}
                />
              )}
            </div>

            <div className={styles.infoBlock}>
              {!outgoing && (
                <div className={styles.subject}>
                  {caseItem.defaultSubject &&
                    caseItem.defaultSubject.replace(/Re:/g, "")}
                </div>
              )}

              {outgoing && caseItem.defaultSubject !== null && (
                <div className={styles.subject}>
                  {"Re: " + caseItem.defaultSubject}
                </div>
              )}

              {(endUserAvatar || endUserName) && (
                <div className={styles.senderInfo}>
                  {endUserName}
                  {endUserChannelFields && (
                    <a
                      href={"mailto:" + endUserChannelFields.email}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {"<" + endUserChannelFields.email + ">"}
                    </a>
                  )}
                </div>
              )}

              {(teamMemberAvatar || teamMemberName) && (
                <div className={styles.senderInfo}>
                  {teamMemberName} replied to
                  {endUserChannelFields && (
                    <a
                      href={"mailto:" + endUserChannelFields.email}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {"<" + endUserChannelFields.email + ">"}
                    </a>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className={styles.date}>
            {updatedAt ? (
              <DateTime forceType={TYPE_FULL_AT} time={updatedAt} />
            ) : (
              <Preloader size="12" color="#807d7f" />
            )}
          </div>
        </div>

        {messageContentIds.map(id => (
          <MessageListEmailContentContainer key={id} messageContentId={id} />
        ))}

        <div className={styles.emailFooter}>
          {attachmentCount > 0 && (
            <>
              <div className={styles.attachmentText}>
                <img src="/images/files/file.svg" alt="" />
                <>
                  {attachmentCount}
                  {attachmentCount <= 2 && " Attachment"}
                  {attachmentCount > 2 && " Attachments"}
                </>
              </div>

              <div className={styles.attachments}>
                {messageContentIds.map(id => (
                  <MessageListEmailFileContentContainer
                    key={id}
                    messageContentId={id}
                  />
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state, props) => {
  const endUserId = state.entities.cases[props.caseId].endUser;
  const endUser = state.entities.endUsers[endUserId];
  const caseItem = state.entities.cases[props.caseId];

  return {
    endUserChannelFields: endUser.channelFields,
    caseItem,
    attachmentCount: props.messageContentIds.filter(
      id => state.entities.messageContents[id].type === "file"
    ).length
  };
};

export default connect(mapStateToProps)(MessageListEmailItem);
