import React from "react";
import PropTypes from "prop-types";
import styles from "./TeamHeader.module.css";

function TeamHeader({ children }) {
  return <div className={styles.header}>{children}</div>;
}

TeamHeader.propTypes = {
  status: PropTypes.string
};

export default TeamHeader;
