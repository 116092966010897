import React, { Component } from "react";
import {
  ALERT_PROCESS,
  HIDE_ALERT,
  SHOW_ALERT,
  subscribe,
  UPDATE_ALERT
} from "utils/alertManager";
import { AlertContainer as Container, Alert } from "design-system-react";

export const SUCCESS_DELAY = 2000; //ms
export const HIDE_DELAY = 400; //ms
const MAX_ALERTS = 6;

class AlertContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      alerts: []
    };

    subscribe(this, SHOW_ALERT, this.handleShow);
    subscribe(this, UPDATE_ALERT, this.handleUpdate);
    subscribe(this, HIDE_ALERT, this.handleHide);
  }

  handleShow = alert => {
    this.setState(
      prevState => ({
        alerts: [alert, ...prevState.alerts]
      }),
      () => {
        if (this.state.alerts.length > MAX_ALERTS) {
          this.delete(this.state.alerts[this.state.alerts.length - 1].id);
        }
      }
    );

    if (alert.timeout > 0 && alert.status !== ALERT_PROCESS) {
      setTimeout(() => {
        this.hide(alert.id);
      }, alert.timeout);
    }
  };

  handleUpdate = params => {
    this.setState(prevState => {
      return {
        alerts: prevState.alerts.map(alert => {
          if (alert.id === params.id) {
            const updatedAlert = {
              ...alert,
              ...params
            };

            if (updatedAlert.timeout > 0) {
              setTimeout(() => {
                this.hide(updatedAlert.id);
              }, updatedAlert.timeout);
            }

            return updatedAlert;
          }

          return alert;
        })
      };
    });
  };

  handleHide = id => {
    this.hide(id);
  };

  handleClose = id => {
    this.hide(id);
  };

  hide = id => {
    this.setState(
      prevState => {
        return {
          alerts: prevState.alerts.map(alert => {
            if (alert.id === id) {
              return {
                ...alert,
                hide: true
              };
            }

            return alert;
          })
        };
      },
      () => {
        setTimeout(() => {
          this.delete(id);
        }, HIDE_DELAY);
      }
    );

    // this.setState(
    //   {
    //     alerts: [
    //       { ...this.state.alerts.filter(a => a.id === id)[0], hide: true },
    //       ...this.state.alerts.filter(a => a.id !== id)
    //     ]
    //   },
    //   () => {
    //     setTimeout(() => {
    //       this.setState({
    //         alerts: this.state.alerts.filter(a => a.id !== id)
    //       });
    //     }, HIDE_DELAY);
    //   }
    // );
  };

  delete = id => {
    this.setState({
      alerts: this.state.alerts.filter(a => a.id !== id)
    });
  };

  render() {
    const { alerts } = this.state;

    return (
      <Container show={alerts.length > 0}>
        {this.state.alerts.map(alert => {
          return (
            <Alert
              key={alert.id}
              onClose={() => {
                this.hide(alert.id);
              }}
              status={alert.status}
              message={alert.message}
              hide={alert.hide}
            />
          );
        })}
      </Container>
    );
  }
}

export default AlertContainer;
