import { CALL_API } from "utils/api";
import { withNamespace } from "utils/namespace";

const type = withNamespace("teamUserViews");

// action types
export const FETCH_MEMBERS_VIEWS_SUCCESS = type("FETCH_MEMBERS_VIEWS_SUCCESS");
export const FETCH_TEAMS_VIEWS_SUCCESS = type("FETCH_TEAMS_VIEWS_SUCCESS");

let initialState = {
  listMemberViewsIds: [],
  listTeamViewsIds: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_MEMBERS_VIEWS_SUCCESS:
      return {
        ...state,
        listMemberViewsIds: action.payload.data.map(user => user.id)
      };
    case FETCH_TEAMS_VIEWS_SUCCESS:
      return {
        ...state,
        listTeamViewsIds: action.payload.data.map(user => user.id)
      };
    default:
      return state;
  }
}

// actions
export function fetchMemberViews(params) {
  return dispatch => {
    return dispatch({
      [CALL_API]: {
        endpoint: "/views/team_users",
        options: {
          method: "get",
          params: params
        },
        types: {
          success: FETCH_MEMBERS_VIEWS_SUCCESS
        }
      }
    });
  };
}

export function fetchTeamViews(params) {
  return dispatch => {
    return dispatch({
      [CALL_API]: {
        endpoint: "/views/teams",
        options: {
          method: "get",
          params: params
        },
        types: {
          success: FETCH_TEAMS_VIEWS_SUCCESS
        }
      }
    });
  };
}
