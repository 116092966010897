import React from "react";
import { connect } from "react-redux";
import EmailAttachedFile from "../components/EmailAttachedFile";

function MessageListEmailFileContentContainer({ messageContent }) {
  if (messageContent.type !== "file") {
    return null;
  }

  return (
    <EmailAttachedFile
      type={messageContent.file && messageContent.file.type}
      name={messageContent.file && messageContent.file.info.name}
      file={messageContent.file}
      fileUrl={messageContent.file.url["default"]}
      fileImage={messageContent.file.url["200x200"]}
      ext={
        messageContent.file &&
        messageContent.file.info.name.match(/[0-9a-z]+$/).toString()
      }
      size={messageContent.file && messageContent.file.info.size}
    />
  );
}

const mapStateToProps = (state, props) => {
  return {
    messageContent: state.entities.messageContents[props.messageContentId]
  };
};

export default connect(mapStateToProps)(MessageListEmailFileContentContainer);
