import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "pages/Cases/components/CasesLayout.module.css";

function CasesLayout({ isActiveCase, renderCases, renderCase }) {
  const classes = classNames(styles.layout, isActiveCase && styles.active);

  return (
    <div className={classes}>
      <div className={styles.cases}>{renderCases}</div>
      {isActiveCase && <div className={styles.case}>{renderCase}</div>}
    </div>
  );
}

CasesLayout.propTypes = {
  renderCases: PropTypes.node.isRequired,
  renderCase: PropTypes.node.isRequired,
  isActiveCase: PropTypes.bool
};

CasesLayout.defaultProps = {
  isActiveCase: false
};

export default CasesLayout;
