import React, { useRef, useCallback, useEffect, useState } from "react";
import {
  Row,
  Col,
  Popup,
  PopupBody,
  PopupHeader,
  PopupFooter,
  Button,
  GutterField
} from "design-system-react";
import { Field, Formik } from "formik";
import InputField from "components/InputField";
import TextareaField from "components/TextareaField";
import { api } from "utils/client";
import { FormError } from "utils/formError";
import useFormSubmission from "hooks/useFormSubmission";

function Form({ submitDisabled, setSubmitDisabled, handleSubmit, dirty }) {
  const handleFormSubmit = useFormSubmission(submitDisabled, handleSubmit);

  if (!dirty) {
    if (!submitDisabled) {
      setSubmitDisabled(true);
    }
  } else if (submitDisabled) {
    setSubmitDisabled(false);
  }

  return (
    <form onSubmit={handleFormSubmit}>
      <Row>
        <Col auto justified>
          <GutterField>
            <Field
              name="title"
              type="name"
              label="Title"
              component={InputField}
            />
          </GutterField>
        </Col>
      </Row>
      <Row gutters nowrap>
        <Col auto justified>
          <Field
            name="text"
            label="Message to send"
            component={TextareaField}
          />
        </Col>
      </Row>
    </form>
  );
}

function SettingsQuickRepliesEditModalContainer({
  isOpen,
  toggle,
  formValues
}) {
  const form = useRef(null);
  const [initialSaved, setInitialSaved] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);

  useEffect(() => {
    if (formValues) {
      form.current.resetForm({
        title: formValues.title,
        text: formValues.text
      });
    }
  }, [formValues]);

  const handleSubmit = useCallback(
    (values, { setSubmitting, setErrors, resetForm }) => {
      api.quickReplies
        .update(formValues.id, values)
        .ready.then(response => {
          setInitialSaved(true);
          resetForm({
            title: response.data.data.title,
            text: response.data.data.text
          });
        })
        .catch(e => {
          if (e instanceof FormError) {
            setErrors(e.errors);
          }
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [formValues.id]
  );

  return (
    <Formik
      ref={form}
      initialValues={{ title: "", text: "" }}
      onSubmit={handleSubmit}
      render={props => (
        <Popup isOpen={isOpen} onClose={toggle}>
          <PopupHeader>Edit quick reply</PopupHeader>
          <PopupBody>
            <Form
              submitDisabled={submitDisabled}
              setSubmitDisabled={setSubmitDisabled}
              {...props}
            />
          </PopupBody>
          <PopupFooter>
            <Button
              onClick={props.submitForm}
              processing={props.isSubmitting}
              disabled={submitDisabled}
            >
              {submitDisabled && initialSaved ? "Saved" : "Save"}
            </Button>
            <Button outline={true} onClick={toggle}>
              Cancel
            </Button>
          </PopupFooter>
        </Popup>
      )}
    />
  );
}

export default SettingsQuickRepliesEditModalContainer;
