import styles from "./MessageFormEmojiButton.module.css";
import PropTypes from "prop-types";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart-virtualized";
import { Dropdown } from "antd";

function MessageFormEmojiButton({ onEmojiClick, toggleEmoji, isOpenEmoji }) {
  return (
    <Dropdown
      overlay={
        <Picker
          set="apple"
          showPreview={false}
          showSkinTones={false}
          onSelect={e => {
            console.log(e);
            onEmojiClick(e.native);
          }}
        />
      }
      trigger={["click"]}
      placement="topCenter"
    >
      <div className={styles.button} title="Emoji">
        <div className={styles.buttonWrapper}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
          >
            <path
              id="Rounded_Rectangle_1858_copy_11"
              data-name="Rounded Rectangle 1858 copy 11"
              className="cls-1"
              d="M1442,3749a10,10,0,1,1,10-10A10,10,0,0,1,1442,3749Zm0-18.89a8.89,8.89,0,1,0,8.89,8.89A8.89,8.89,0,0,0,1442,3730.11Zm3,7.89a1,1,0,1,1,1-1A1,1,0,0,1,1445,3738Zm1,3.5a0.6,0.6,0,0,1-.67.5,7.848,7.848,0,0,1-3.33,1,7.236,7.236,0,0,1-3.33-1,0.522,0.522,0,1,1,0-1,7.45,7.45,0,0,0,3.33,1,7.617,7.617,0,0,0,3.33-1A0.6,0.6,0,0,1,1446,3741.5Zm-7-3.5a1,1,0,1,1,1-1A1,1,0,0,1,1439,3738Z"
              transform="translate(-1432 -3729)"
            />
          </svg>
        </div>
        {/* <Button type="primary">Click me</Button> */}

        {/* <Emoji isOpen={isOpenEmoji} onEmojiClick={onEmojiClick} /> */}
      </div>
    </Dropdown>
  );
}

MessageFormEmojiButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default MessageFormEmojiButton;
