import React from "react";
import PropTypes from "prop-types";
import { extractFirstCharacters } from "utils/string";
import { Avatar, Button } from "design-system-react";
import styles from "./TeamCard.module.css";
import {
  can,
  TEAM_ADD_TEAM_MEMBER_PERMISSION
} from "../../../utils/permissions";
import DetailsButtonWrapper from "../../../components/DetailsButtonWrapper/DetailsButtonWrapper";
import ProfileButton from "../../../components/ProfileButton";
import CardProfileButtonContainer from "../../../components/CardProfileButtonContainer";

function TeamCard({
  title,
  avatar,
  color,
  enabled,
  deletedAt,
  toggleAddTeamModal,
  toggleDetailsSidePanel
}) {
  return (
    <div className={styles.header}>
      <div className={styles.info}>
        <Avatar
          image={avatar}
          size="size100"
          color="#fff"
          background={color}
          text={extractFirstCharacters(title, 1)}
        />
        <span className={styles.name}>{title}</span>
      </div>

      <div className={styles.itemBlock}>
        <div className={styles.item}>
          <DetailsButtonWrapper>
            <ProfileButton profileButtonAction={toggleDetailsSidePanel} />
          </DetailsButtonWrapper>
          <CardProfileButtonContainer />
        </div>

        {can(TEAM_ADD_TEAM_MEMBER_PERMISSION) && (
          <div className={styles.item}>
            <div className={styles.button}>
              {!deletedAt && (
                <Button onClick={toggleAddTeamModal}>Add member</Button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

TeamCard.propTypes = {
  title: PropTypes.string,
  avatar: PropTypes.string,
  color: PropTypes.string
};

export default TeamCard;
