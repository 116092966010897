let handlers = [];
let notificationId = 0;
let _component = null;

export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";

export const NOTIFICATION_DEFAULT = "default";
export const NOTIFICATION_SUCCESS = "success";
export const NOTIFICATION_ERROR = "error";

export function subscribe(component, event, handler) {
  _component = component;

  if (!handlers[event]) {
    handlers[event] = [];
  }

  handlers[event].push(handler);
}

export function unsubscribe(handler) {
  _component = null;
  handlers = handlers.filter(h => h !== handler);
}

export function notify(event, data) {
  notificationId++;

  if (!handlers[event] || handlers[event].length < 1) {
    return;
  }

  data["id"] = notificationId;

  handlers[event].forEach(handler => {
    handler.call(_component, {
      ...data
    });
  });

  return notificationId;
}

export function notification(
  message,
  type = "default",
  closable = true,
  timeout = 4000
) {
  return notify(SHOW_NOTIFICATION, {
    message,
    type,
    closable,
    timeout
  });
}

export function deleteNotification(id) {
  return notify(DELETE_NOTIFICATION, {
    deleteNotificationId: id
  });
}
