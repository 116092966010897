import React, { useState, useEffect, useCallback } from "react";
import usePrevious from "hooks/usePrevious";
import PropTypes from "prop-types";
import { PhoneNumber } from "design-system-react";

function EditablePhoneField({ initialValue, label, componentProps, onUpdate }) {
  const [value, setValue] = useState(initialValue || "");
  const [error, setError] = useState(null);
  const [isDirty, setDirty] = useState(false);
  let prevValue = usePrevious(value);

  useEffect(() => {
    if (initialValue) {
      setValue(initialValue);
      setError(null);
    } else {
      setValue("");
    }
  }, [initialValue]);

  const handleBlur = e => {
    if (isDirty) {
      handleUpdate(e.target.value);
    }
  };

  const handleChange = value => {
    setValue(value);
    if (value.match(/^[ ]+$/)) {
      setValue("");
    }
    if (value.trim() !== (prevValue || "").trim()) {
      setError(null);
      setDirty(true);
    } else {
      setDirty(false);
    }
  };

  const handleKeyPress = e => {
    if (e.key === "Enter" && isDirty) {
      handleUpdate(e.target.value);
      e.preventDefault();
    }
  };

  const handleClearClick = () => {
    setDirty(true);
    setValue("");
  };

  const setSuccess = useCallback(isSubmitted => {
    if (isSubmitted) {
      setDirty(false);
      setError(null);
    }
  }, []);

  const setErrors = useCallback(error => {
    setError(error[Object.keys(error)]);
  }, []);

  const handleUpdate = useCallback(
    value => {
      onUpdate(value, {
        setErrors,
        setSuccess
      });
    },
    [onUpdate, setErrors, setSuccess]
  );

  return (
    <PhoneNumber
      label={label}
      name="phoneNumber"
      onBlur={handleBlur}
      onChange={handleChange}
      onKeyPress={handleKeyPress}
      onClearClick={handleClearClick}
      value={value}
      validation={error && "error"}
      message={error && error}
      {...componentProps}
    />
  );
}

EditablePhoneField.propTypes = {
  initialValue: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,
  label: PropTypes.string
};

export default EditablePhoneField;
