import { withNamespace } from "utils/namespace";

const type = withNamespace("caseCounters");

// action types
export const SET_COUNTERS = type("SET_COUNTERS");

let initialState = {
  counters: {}
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_COUNTERS:
      return {
        ...state,
        counters: action.payload.counters
      };
    default:
      return state;
  }
}

// actions
export function setCounters(counters) {
  return {
    type: SET_COUNTERS,
    payload: {
      counters
    }
  };
}

export function markCaseAsUnread(counters) {
  return {
    type: SET_COUNTERS,
    payload: {
      counters
    }
  };
}
