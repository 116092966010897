import { useMemo } from "react";

export default function useSortArray(
  data: any[],
  field: string,
  order: "asc" | "desc"
) {
  const sorted = useMemo(() => {
    return data.sort(function(a, b) {
      if (order === "desc") {
        return b[field] - a[field];
      } else {
        return a[field] - b[field];
      }
    });
  }, [data, order, field]);

  const firstSortedFieldValue = useMemo(() => {
    if (sorted.length > 0) {
      return sorted[0][field];
    }
    return null;
  }, [sorted, field]);

  const lastSortedFieldValue = useMemo(() => {
    if (sorted.length > 0) {
      return sorted[sorted.length - 1][field];
    }
    return null;
  }, [sorted, field]);

  return {
    data,
    firstSortedFieldValue,
    lastSortedFieldValue
  };
}
