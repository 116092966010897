import React, { useState, useCallback } from "react";
import EndUserCard from "pages/EndUser/components/EndUserCard";
import { api } from "utils/client";
import { ALERT_ERROR, ALERT_SUCCESS, showAlert } from "utils/alertManager";
import NewCaseModal from "components/NewCaseModal";
import { addCaseId } from "modules/pages/endUser";
import { connect } from "react-redux";
import useRenderDelay from "hooks/useRenderDelay";

function EndUserCardContainer({
  endUserId,
  userAid,
  email,
  avatar,
  channelType,
  name,
  nameColor,
  qualification,
  ownerTeam,
  ownerTeamMember,
  addCaseId,
  toggleDetailsSidePanel
}) {
  const [currentQualification, setQualification] = useState(qualification);
  const [newCaseModalOpened, setNewCaseModalOpened] = useState(false);
  const renderModal = useRenderDelay(newCaseModalOpened, 10, 250);

  const toggleNewCaseModal = useCallback(() => {
    setNewCaseModalOpened(!newCaseModalOpened);
  }, [newCaseModalOpened]);

  const handleCaseCreated = useCallback(
    caseId => {
      addCaseId(caseId);
      setNewCaseModalOpened(false);
    },
    [addCaseId]
  );

  const handleQualificationChange = useCallback(
    qualification => {
      api.endUsers
        .changeQualification(endUserId, qualification)
        .ready.then(response => {
          setQualification(response.data.data.qualification);
          showAlert(
            ALERT_SUCCESS,
            `Qualification has been changed to ${qualification}`
          );
        })
        .catch(() => {
          showAlert(ALERT_ERROR, "Something bad happened");
        });
    },
    [endUserId]
  );

  return (
    <>
      {renderModal.isRender && (
        <NewCaseModal
          endUserName={name || email}
          endUserColor={nameColor}
          endUserAvatar={avatar}
          isOpen={renderModal.isOpen}
          toggle={toggleNewCaseModal}
          endUserId={endUserId}
          onCaseCreated={handleCaseCreated}
        />
      )}
      <EndUserCard
        avatar={avatar}
        channel={channelType}
        name={name}
        userAid={userAid}
        userId={endUserId}
        color={nameColor}
        qualification={currentQualification}
        ownerTeam={ownerTeam}
        ownerTeamMember={ownerTeamMember}
        onQualificationChange={handleQualificationChange}
        onNewCaseButtonClick={toggleNewCaseModal}
        toggleDetailsSidePanel={toggleDetailsSidePanel}
      />
    </>
  );
}

const mapStateToProps = (state, props) => {
  return {
    avatar: state.entities.endUsers[props.endUserId].avatar,
    channelType: state.entities.endUsers[props.endUserId].channelType,
    name: state.entities.endUsers[props.endUserId].name,
    email: state.entities.endUsers[props.endUserId].email,
    userAid: state.entities.endUsers[props.endUserId].aid,
    nameColor: state.entities.endUsers[props.endUserId].nameColor,
    qualification: state.entities.endUsers[props.endUserId].qualification,
    ownerTeam: state.entities.endUsers[props.endUserId].ownerTeam,
    ownerTeamMember: state.entities.endUsers[props.endUserId].ownerTeamMember
  };
};

const mapDispatchToProps = dispatch => ({
  addCaseId: id => dispatch(addCaseId(id))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EndUserCardContainer);
