import { MutableRefObject } from "react";
import styles from "./Table.module.scss";
import classNames from "classnames";
import TableColumn from "./TableColumn";
import TableHeader from "./TableHeader";
import TableHeaderColumn from "./TableHeaderColumn";
import TableBody from "./TableBody";
import TableRow from "./TableRow";
import ScrollableList from "components/scrollableList/ScrollableList";
import { Checkbox, Empty, message, Spin } from "antd";
import View from "components/View";
import { useState } from "react";
import { useEffect } from "react";

export interface TableProps {
  columns: object[];
  data: any[];
  activeId?: string;
  sorter?: { field: string; direction: "asc" | "desc" };
  virtual?: {
    scrollbarRef: MutableRefObject<any>;
    rowHeight: number;
  };
  loading?: boolean;
  hoverable?: boolean;
  fixedHeader?: boolean;
  selectedItems?: string[];
  maxSelectedItems?: number;
  nonSelectableItems?: string[];
  onSort?: (field: string) => {};
  onSelect?: (items: string[]) => void;
  onDisabledSelectClick?: () => void;
  onStatus?: (item: any) => string | undefined;
}

function Table({
  columns,
  data,
  activeId,
  virtual,
  loading,
  hoverable,
  fixedHeader,
  selectedItems,
  maxSelectedItems = 30,
  nonSelectableItems = [],
  onSort,
  onStatus,
  onSelect,
  onDisabledSelectClick
}: TableProps) {
  const [selectedAll, setSelectedAll] = useState(false);

  useEffect(() => {
    if (
      selectedItems &&
      data.length > 0 &&
      (selectedItems.length >= maxSelectedItems ||
        (data.length - nonSelectableItems.length !== 0 &&
          selectedItems.length === data.length - nonSelectableItems.length))
    ) {
      if (!selectedAll) {
        setSelectedAll(true);
      }
    } else if (selectedAll) {
      setSelectedAll(false);
    }
  }, [
    selectedItems,
    data.length,
    nonSelectableItems,
    selectedAll,
    maxSelectedItems
  ]);

  useEffect(() => {
    if (selectedItems) {
      const ids = data.map(item => item.id);

      let callOnSelect = false;

      const newSelectedItems = selectedItems.filter(id => {
        if (ids.includes(id)) {
          return true;
        }

        callOnSelect = true;
        return false;
      });

      if (callOnSelect) {
        onSelect && onSelect(newSelectedItems);
      }
    }
    // eslint-disable-next-line
  }, [data, selectedAll]);

  const classes = classNames(styles.table, hoverable && styles.hoverable);

  return (
    <div className={classes}>
      <TableHeader fixed={fixedHeader}>
        {selectedItems && (
          <TableHeaderColumn width={40} className={styles.selectColumn}>
            <Checkbox
              indeterminate={selectedItems.length > 0 && !selectedAll}
              checked={selectedAll}
              onChange={e => {
                let ids: any[] = [];

                if (e.target.checked) {
                  let disabledCount = 0;
                  for (let i = 0; i < data.length; i++) {
                    if (i >= maxSelectedItems + disabledCount) {
                      break;
                    }

                    if (nonSelectableItems.indexOf(data[i].id) !== -1) {
                      disabledCount++;
                      continue;
                    }

                    ids.push(data[i].id);
                  }
                }

                setSelectedAll(e.target.checked);
                onSelect && onSelect(ids);
              }}
            />
          </TableHeaderColumn>
        )}
        {columns.map(
          (
            { title, width, minWidth, maxWidth, field, sortable }: any,
            index
          ) => {
            const sortProps = {
              sortable,
              sort: sortable && sortable.field === field && sortable.direction
            };

            return (
              <TableHeaderColumn
                key={index}
                {...sortProps}
                width={width}
                minWidth={minWidth}
                maxWidth={maxWidth}
                onClick={() => {
                  if (sortable) {
                    onSort && onSort(field);
                  }
                }}
              >
                {title}
              </TableHeaderColumn>
            );
          }
        )}
      </TableHeader>
      <TableBody>
        {!virtual &&
          data.map((item: any) => {
            return (
              <TableRow
                key={item.id}
                statusColor={onStatus && onStatus(item)}
                active={activeId === item.id}
                selected={
                  selectedItems && selectedItems.indexOf(item.id) !== -1
                }
              >
                {selectedItems && (
                  <TableColumn width={40} className={styles.selectColumn}>
                    {onDisabledSelectClick &&
                      nonSelectableItems.indexOf(item.id) !== -1 && (
                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            zIndex: 2,
                            cursor: "pointer"
                          }}
                          onClick={() => {
                            if (
                              onDisabledSelectClick &&
                              nonSelectableItems.indexOf(item.id) !== -1
                            )
                              onDisabledSelectClick();
                          }}
                        />
                      )}
                    <Checkbox
                      checked={
                        selectedItems && selectedItems.indexOf(item.id) !== -1
                      }
                      onChange={e => {
                        if (selectedItems && onSelect) {
                          if (e.target.checked) {
                            if (selectedItems.length < maxSelectedItems) {
                              onSelect([...selectedItems, item.id]);
                            } else {
                              message.info(
                                "You have selected the maximum number of rows"
                              );
                            }
                          } else {
                            onSelect(
                              selectedItems.filter(id => id !== item.id)
                            );
                          }
                        }
                      }}
                      disabled={nonSelectableItems.indexOf(item.id) !== -1}
                    />
                  </TableColumn>
                )}
                {columns.map(
                  (
                    { field, width, minWidth, maxWidth, render, onClick }: any,
                    index
                  ) => {
                    return (
                      <TableColumn
                        key={index}
                        width={width}
                        minWidth={minWidth}
                        maxWidth={maxWidth}
                        onClick={
                          onClick
                            ? () => {
                                onClick(item);
                              }
                            : undefined
                        }
                      >
                        {render ? render(item[field], item) : item[field]}
                      </TableColumn>
                    );
                  }
                )}
              </TableRow>
            );
          })}

        {virtual && (
          <ScrollableList
            rowHeight={virtual && virtual.rowHeight}
            rowCount={data.length}
            scrollbarRef={virtual && virtual.scrollbarRef}
          >
            {(index: number) => {
              const render = (item: any) => {
                return (
                  <TableRow
                    key={item.id}
                    statusColor={onStatus && onStatus(item)}
                  >
                    {columns.map(
                      (
                        {
                          field,
                          width,
                          minWidth,
                          maxWidth,
                          render,
                          onClick
                        }: any,
                        index
                      ) => {
                        return (
                          <TableColumn
                            key={index}
                            width={width}
                            minWidth={minWidth}
                            maxWidth={maxWidth}
                            onClick={
                              onClick
                                ? () => {
                                    onClick(item);
                                  }
                                : undefined
                            }
                          >
                            {render ? render(item[field], item) : item[field]}
                          </TableColumn>
                        );
                      }
                    )}
                  </TableRow>
                );
              };

              return render(data[index]);
            }}
          </ScrollableList>
        )}
      </TableBody>
      {loading && (
        <div className={styles.loader}>
          <Spin />
        </div>
      )}
      {!loading && data.length === 0 && (
        <View marginTop={60}>
          <Empty />
        </View>
      )}
    </div>
  );
}

export default Table;
