import { extractFirstLetters } from "utils/string";
import { Avatar, Button } from "design-system-react";
import DetailUserQualification from "pages/Cases/components/DetailUserQualification";
import EndUserEditableNameContainer from "../containers/EndUserEditableNameContainer";
import styles from "./EndUserCard.module.css";
import PropTypes from "prop-types";
import { channelSmIcon } from "helpers/channelIcon";
import DetailsButtonWrapper from "../../../components/DetailsButtonWrapper/DetailsButtonWrapper";
import ProfileButton from "../../../components/ProfileButton";
import CardProfileButtonContainer from "../../../components/CardProfileButtonContainer";
import { isNeedToRenderNewCase } from "../../../helpers/channels";
import { Space } from "antd";
import EndUserOwnerInput from "components/EndUserOwnerInput";

function EndUserCard({
  avatar,
  channel,
  name,
  userAid,
  userId,
  color,
  qualification,
  ownerTeam,
  ownerTeamMember,
  onQualificationChange,
  onNewCaseButtonClick,
  toggleDetailsSidePanel
}) {
  return (
    <div className={styles.card}>
      <div className={styles.container}>
        <div className={styles.avatar}>
          <Avatar
            image={avatar}
            channel={channelSmIcon(channel)}
            text={extractFirstLetters(name, 2)}
            color={color}
            size={"size100"}
          />
        </div>
        <div className={styles.desc}>
          <span className={styles.name}>
            <EndUserEditableNameContainer
              userId={userId}
              name={name || `User #${userAid}`}
              toggleDetailsSidePanel={toggleDetailsSidePanel}
            />
          </span>
          <span className={styles.userId}>{`#${userAid}`}</span>
        </div>
      </div>

      <div className={styles.container}>
        <div className={styles.item}>
          <DetailsButtonWrapper>
            <ProfileButton profileButtonAction={toggleDetailsSidePanel} />
          </DetailsButtonWrapper>
          <CardProfileButtonContainer />
        </div>
        <div className={styles.item}>
          <Space>
            <DetailUserQualification
              qualification={qualification}
              onQualificationChange={onQualificationChange}
            />
            <EndUserOwnerInput
              endUserId={userId}
              selectedTeam={ownerTeam}
              selectedTeamMember={ownerTeamMember}
            />
          </Space>
        </div>
        {isNeedToRenderNewCase(channel) && onNewCaseButtonClick && (
          <div className={styles.item}>
            <Button onClick={onNewCaseButtonClick}>New case</Button>
          </div>
        )}
      </div>
    </div>
  );
}

EndUserCard.propTypes = {
  avatar: PropTypes.string,
  name: PropTypes.string,
  userId: PropTypes.string,
  color: PropTypes.string,
  qualification: PropTypes.string.isRequired,
  onQualificationChange: PropTypes.func.isRequired
};

export default EndUserCard;
