import React from "react";
import { Avatar } from "design-system-react";
import styles from "./SettingsChannelTableInfo.module.css";

function SettingsChannelTableInfo({
  avatar,
  name,
  email,
  channel,
  noGradient,
  channelType,
  channelEmailName
}) {
  return (
    <div className={styles.container}>
      <Avatar
        background={!avatar ? "#eff0f3" : ""}
        image={avatar}
        noGradient={noGradient}
      />
      <div className={styles.info}>
        {channelType !== "slack" && channelType !== "email" && (
          <span className={styles.title}>{name}</span>
        )}

        {channelType === "slack" && (
          <span className={styles.title}>{"#" + name}</span>
        )}

        {channelType === "email" && (
          <span className={styles.title}>{channelEmailName}</span>
        )}

        {channelType !== "email" && (
          <a
            className={styles.desc}
            href={email ? email : channel}
            target="_blank"
            rel="noopener noreferrer"
          >
            {channel}
          </a>
        )}

        {channelType === "email" && (
          <a
            className={styles.desc}
            href={email ? email : channel}
            target="_blank"
            rel="noopener noreferrer"
          >
            {name}
          </a>
        )}
      </div>
    </div>
  );
}

export default SettingsChannelTableInfo;
