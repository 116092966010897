import React from "react";
import TeamsContainer from "pages/Teams/containers/TeamsContainer";
import ToggableLayout from "components/ToggableLayout";
import TeamMembersSubMenuContainer from "../../containers/TeamMembersSubMenuContainer";

function Teams({ location, match }) {
  return (
    <ToggableLayout
      renderSubmenu={() => (
        <TeamMembersSubMenuContainer activeMenuItem={match.url} />
      )}
      renderContent={container => (
        <TeamsContainer
          locationKey={location.key}
          view={match.params.view}
          activeMenuItem={match.url}
          onSubMenuToggle={container.toggleSubmenu}
          isSubmenuOpened={container.isSubmenuOpened}
        />
      )}
    />
  );
}

export default Teams;
