import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { DropdownMenuLabel } from "design-system-react";
import AssignDropdownBackItem from "../components/AssignDropdown/AssignDropdownBackItem";
import AssignDropdownMenuTeamContainer from "./AssignDropdownMenuTeamContainer";
import AssignDropdownTextItem from "../components/AssignDropdown/AssignDropdownTextItem";
import { api } from "../utils/client";

const FilteredTeams = connect(function(state, props) {
  const filter = props.filter.trim().toUpperCase();
  const teamIds = props.teamIds.filter(id =>
    state.entities.teams[id].title.toUpperCase().includes(filter)
  );

  return {
    teamIds
  };
})(({ teamIds, onSelect }) => {
  return (
    <>
      {teamIds &&
        teamIds.map(id => {
          return (
            <AssignDropdownMenuTeamContainer
              key={id}
              teamId={id}
              onSelect={onSelect}
            />
          );
        })}
    </>
  );
});

function AssignDropdownMenuTeamsScreenContainer({
  caseId,
  filter,
  onSelect,
  onBack
}) {
  const [teamIds, setTeamIds] = useState([]);
  const [teamsLoading, setTeamsLoading] = useState(true);

  const fetchTeams = useCallback(() => {
    setTeamsLoading(true);

    const request = api.cases.teamsForAssign();
    request.ready
      .then(response => {
        setTeamIds(response.data.data.map(team => team.id));
      })
      .finally(() => {
        setTeamsLoading(false);
      });

    return request.cancelRequest;
  }, []);

  useEffect(() => {
    fetchTeams();
  }, [fetchTeams]);

  return (
    <>
      <AssignDropdownBackItem onBack={onBack}>BACK</AssignDropdownBackItem>
      <DropdownMenuLabel>TEAMS</DropdownMenuLabel>
      {!teamsLoading && teamIds.length === 0 && (
        <AssignDropdownTextItem>Teams not found</AssignDropdownTextItem>
      )}
      {!teamsLoading && (
        <FilteredTeams teamIds={teamIds} filter={filter} onSelect={onSelect} />
      )}
    </>
  );
}

export default AssignDropdownMenuTeamsScreenContainer;
