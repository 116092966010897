import React, { useCallback } from "react";
import { GhostInput } from "design-system-react";
import EditableField from "components/EditableField";
import { api } from "utils/client";
import { ALERT_SUCCESS, showAlert } from "utils/alertManager";
import { FormError } from "utils/formError";

function EndUserEditableNameContainer({ name, userId }) {
  const handleFieldUpdate = useCallback(
    (value, { setErrors, setSuccess }) => {
      api.endUsers
        .update(userId, { name: value })
        .ready.then(() => {
          setSuccess(true);
          showAlert(ALERT_SUCCESS, "Name was successfully changed");
        })
        .catch(e => {
          if (e instanceof FormError) {
            setErrors(e.errors);
          }
        });
    },
    [userId]
  );

  return (
    <EditableField
      name="name"
      initialValue={name}
      component={GhostInput}
      onUpdate={handleFieldUpdate}
    />
  );
}

export default EndUserEditableNameContainer;
