import React, { useEffect } from "react";
import TeamMembersContainer from "./containers/TeamMembersContainer";
import ToggableLayout from "components/ToggableLayout";
import TeamMembersSubMenuContainer from "../../containers/TeamMembersSubMenuContainer";

function TeamMembers({ location, match }) {
  useEffect(() => {
    document.title = "Team";
  }, []);

  return (
    <ToggableLayout
      renderSubmenu={() => (
        <TeamMembersSubMenuContainer activeMenuItem={match.url} />
      )}
      renderContent={container => (
        <TeamMembersContainer
          locationKey={location.key}
          view={match.params.view}
          activeMenuItem={match.url}
        />
      )}
    />
  );
}

export default TeamMembers;
