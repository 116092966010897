import { CALL_API } from "utils/api";
import uuidv4 from "utils/uuidv4";
import { withNamespace } from "utils/namespace";
import { deleteNotification, notification } from "utils/notificationsManager";

const type = withNamespace("pages/settingsProfile");

// action types
export const UPLOAD_AVATAR_REQUEST = type("UPLOAD_AVATAR_REQUEST");
export const UPLOAD_AVATAR_SUCCESS = type("UPLOAD_AVATAR_SUCCESS");
export const UPLOAD_AVATAR_FAILURE = type("UPLOAD_AVATAR_FAILURE");
export const PROFILE_UPDATE_SUCCESS = type("PROFILE_UPDATE_SUCCESS");

// state
let initialState = {
  uploadedAvatarId: null,
  avatarUploading: false
};

let notificationId = null;

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case UPLOAD_AVATAR_REQUEST:
      return {
        ...state,
        uploadedAvatarId: null,
        avatarUploading: true
      };
    case UPLOAD_AVATAR_SUCCESS:
      return {
        ...state,
        uploadedAvatarId: action.payload.data[0].id,
        avatarUploading: false
      };
    case UPLOAD_AVATAR_FAILURE:
      return {
        ...state,
        uploadedAvatarId: null,
        avatarUploading: false
      };
    case PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        uploadedAvatarId: null
      };
    default:
      return state;
  }
}

export function fetchMe(id, params) {
  return dispatch => {
    return dispatch({
      [CALL_API]: {
        endpoint: "/team_users/" + id,
        options: {
          method: "get",
          params: params
        }
      }
    });
  };
}

export function uploadAvatar(file) {
  const uuid = uuidv4();

  return dispatch => {
    var formData = new FormData();
    formData.append("file", file);

    dispatch({
      [CALL_API]: {
        endpoint: "/file/upload",
        options: {
          method: "post",
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data"
          }
        },
        types: {
          request: UPLOAD_AVATAR_REQUEST,
          success: UPLOAD_AVATAR_SUCCESS,
          failure: UPLOAD_AVATAR_FAILURE
        },
        payload: {
          uuid
        }
      }
    });
  };
}

export function updateProfile(params, form) {
  if (notificationId) {
    deleteNotification(notificationId);
  }

  notificationId = notification("Saving...", "default", false, false);

  return dispatch => {
    const actionResponse = dispatch({
      [CALL_API]: {
        endpoint: "/team_users/update_profile",
        options: {
          method: "post",
          data: params
        },
        types: {
          success: UPLOAD_AVATAR_SUCCESS
        },
        form
      }
    });

    actionResponse.ready.then(action => {
      deleteNotification(notificationId);

      if (action.success) {
        notificationId = notification("Profile saved", "success");
      }
    });

    return actionResponse;
  };
}
