import React from "react";
import { Field } from "formik";
import SelectField from "components/SelectField";
import { Row, Col, GutterField } from "design-system-react";
import MultiSelectWithAvatarField from "components/MultiSelectWithAvatarField";
import { DropdownItemWithAvatar, Avatar } from "design-system-react";
import { extractFirstCharacters } from "utils/string";
import useFormSubmission from "hooks/useFormSubmission";

function FormContainer({
  handleSubmit,
  values,
  initialValues,
  submitDisabled,
  setSubmitDisabled,
  teams,
  roles,
  emailInputRef,
  dirty
}) {
  const handleFormSubmit = useFormSubmission(submitDisabled, handleSubmit);

  if (!dirty) {
    if (!submitDisabled) {
      setSubmitDisabled(true);
    }
  } else if (submitDisabled) {
    setSubmitDisabled(false);
  }

  return (
    <form onSubmit={handleFormSubmit}>
      <Row>
        <Col gutterRight={10}>
          <GutterField>
            <Field
              name="role"
              label="Role"
              component={SelectField}
              items={roles}
            />
          </GutterField>
        </Col>
      </Row>
      <Row>
        <Col gutterRight={10}>
          <GutterField>
            <Field
              name="teams"
              label="Teams"
              component={MultiSelectWithAvatarField}
              items={teams}
              disabled={teams.length <= 1}
              renderItem={item => (
                <DropdownItemWithAvatar
                  renderAvatar={
                    <Avatar
                      size="size24"
                      background={item.color}
                      color="#fff"
                      text={extractFirstCharacters(item.name, 1)}
                    />
                  }
                >
                  {item.name}
                </DropdownItemWithAvatar>
              )}
            />
          </GutterField>
        </Col>
      </Row>
    </form>
  );
}

export default FormContainer;
