import React from "react";
import styles from "./SettingsProfileSubtitle.module.css";
import PropTypes from "prop-types";

export default function SettingsProfileSubtitle({ children }) {
  return <p className={styles.subTitle}>{children}</p>;
}

SettingsProfileSubtitle.propTypes = {
  children: PropTypes.string
};
