import React, { useState, useEffect, useCallback } from "react";
import {
  Table,
  TableHeader,
  TableHeaderColumn,
  TableHeaderColumnMenu,
  TableBody,
  Col,
  Preloader,
  Container,
  Scrollbar
} from "design-system-react";
import {
  MODAL_DEFAULT,
  MODAL_ERROR,
  MODAL_PROCESS,
  MODAL_SUCCESS,
  showConfirmModal,
  updateConfirmModal
} from "utils/modalManager";
import { api } from "utils/client";
import axios from "axios";
import { CommonError } from "utils/commonError";
import history from "utils/history";
import EmptyState from "components/EmptyState";
import AutoResponseListItemContainer from "./AutoResponseListItemContainer";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

function AutoResponseListContainer() {
  const [loading, setLoading] = useState(true);
  const [autoResponseIds, setAutoResponseIds] = useState([]);

  useEffect(() => {
    setLoading(true);

    const request = api.autoResponses.all();
    request.ready
      .then(response => {
        setAutoResponseIds(response.data.data.map(autoReply => autoReply.id));
        setLoading(false);
      })
      .catch(error => {
        if (!axios.isCancel(error)) {
          setLoading(false);
        }
      });

    return () => {
      request.cancelRequest.cancel();
    };
  }, []);

  const handleEdit = useCallback(id => {
    history.push("/settings/auto-responses/" + id);
  }, []);

  const handleDelete = useCallback(
    id => {
      const modalId = showConfirmModal(
        MODAL_DEFAULT,
        "Are you sure you want to delete auto-response?",
        {
          actionButtonText: "Delete",
          onAction: () => {
            updateConfirmModal(modalId, MODAL_PROCESS, "Deleting...");

            api.autoResponses
              .delete(id)
              .ready.then(() => {
                setAutoResponseIds(
                  autoResponseIds.filter(responseId => responseId !== id)
                );

                updateConfirmModal(
                  modalId,
                  MODAL_SUCCESS,
                  "Auto-response successfully deleted"
                );
              })
              .catch(e => {
                const errorMessage = "Ooops, something went wrong";

                updateConfirmModal(
                  modalId,
                  MODAL_ERROR,
                  e instanceof CommonError ? e.error : errorMessage
                );
              });
          }
        }
      );
    },
    [autoResponseIds]
  );

  const handleActionDropdown = useCallback(
    (id, action) => {
      switch (action) {
        case "edit":
          handleEdit(id);
          break;
        case "delete":
          handleDelete(id);
          break;
        default:
          break;
      }
    },
    [handleEdit, handleDelete]
  );

  const [isDrag, setDrag] = useState(false);

  const onDragStart = () => {
    setDrag(true);
  };

  function onDragEnd(result) {
    setDrag(false);
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const list = reorder(
      autoResponseIds,
      result.source.index,
      result.destination.index
    );

    setAutoResponseIds(list);
    api.autoResponses.sort({ ids: list });
  }

  if (loading && !autoResponseIds.length) {
    return (
      <Col align="center" justified>
        <Preloader size="32" color="#807d7f" />
      </Col>
    );
  }

  if (!autoResponseIds.length) {
    return (
      <Col align="center" verticalAlign="center" justified>
        <EmptyState title="No auto-responses">
          Auto-responses not found
        </EmptyState>
      </Col>
    );
  }

  return (
    <Scrollbar>
      <Container>
        <Table headerBorder noHover={isDrag}>
          <TableHeader fixed>
            <TableHeaderColumn justified>Title</TableHeaderColumn>
            <TableHeaderColumn>Updated</TableHeaderColumn>
            <TableHeaderColumnMenu />
          </TableHeader>
          <TableBody>
            <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
              <Droppable droppableId="list">
                {provided => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {autoResponseIds.map((id, index) => {
                      return (
                        <Draggable draggableId={id} key={id} index={index}>
                          {(provided, snapshot) => {
                            return (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                              >
                                <AutoResponseListItemContainer
                                  key={id}
                                  active={snapshot.isDragging}
                                  autoResponseId={id}
                                  handleActionDropdown={handleActionDropdown}
                                  dragHandleProps={provided.dragHandleProps}
                                  isDragging={snapshot.isDragging}
                                />
                              </div>
                            );
                          }}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </TableBody>
        </Table>
      </Container>
    </Scrollbar>
  );
}

export default AutoResponseListContainer;
