import { useCallback } from "react";
import { showAlert } from "../utils/alertManager";
import history from "utils/history";

export default function useAlertRedirect() {
  return useCallback((url, alertStatus, alertMessage) => {
    if (alertStatus && alertMessage) {
      showAlert(alertStatus, alertMessage);
    }

    history.push(url);
  }, []);
}
