import React, { useCallback } from "react";
import { connect } from "react-redux";
import {
  DropdownItem,
  DropdownItemWithAvatar,
  Avatar
} from "design-system-react";
import { extractFirstCharacters } from "../utils/string";

function AssignDropdownMenuTeamContainer({ team, teamMemberId, onSelect }) {
  const handleSelect = useCallback(() => {
    onSelect(team.id, teamMemberId);
  }, [onSelect, team.id, teamMemberId]);

  return (
    <DropdownItem onClick={handleSelect}>
      <DropdownItemWithAvatar
        renderAvatar={
          <Avatar
            size={"size24"}
            background={team.color}
            color="#fff"
            text={extractFirstCharacters(team.title, 1)}
          />
        }
      >
        {team.title}
      </DropdownItemWithAvatar>
    </DropdownItem>
  );
}

const mapStateToProps = (state, props) => {
  const team = state.entities.teams[props.teamId];

  return {
    team
  };
};

export default connect(mapStateToProps)(AssignDropdownMenuTeamContainer);
