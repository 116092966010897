import React from "react";
import { extractFirstCharacters } from "utils/string";
import {
  DropdownToggleSelectedMultiItem,
  DropdownItemWithAvatar,
  Avatar
} from "design-system-react";

function TeamMemberTeam({ teamColor, teamTitle }) {
  return (
    <DropdownToggleSelectedMultiItem>
      <DropdownItemWithAvatar
        renderAvatar={
          <Avatar
            size="size24"
            background={teamColor}
            color="#fff"
            text={extractFirstCharacters(teamTitle, 1)}
          />
        }
      >
        {teamTitle}
      </DropdownItemWithAvatar>
    </DropdownToggleSelectedMultiItem>
  );
}

export default TeamMemberTeam;
