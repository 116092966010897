import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import styles from "./EntityAvatar.module.scss";
import { AvatarSize } from "antd/lib/avatar/SizeContext";

export interface EntityAvatarProps {
  title?: string;
  avatarTitle?: React.ReactNode;
  avatarBackground?: string;
  avatarIcon?: React.ReactNode;
  avatarSrc?: string;
  avatarSize?: AvatarSize;
  avatarTextSize?: number;
}

function EntityAvatar({
  title,
  avatarTitle,
  avatarBackground,
  avatarIcon,
  avatarSrc,
  avatarSize = "small",
  avatarTextSize
}: EntityAvatarProps) {
  const avatarStyles: React.CSSProperties = {
    background: avatarBackground
  };

  if (avatarTextSize) {
    avatarStyles.fontSize = avatarTextSize;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.avatar}>
        <Avatar
          size={avatarSize}
          src={avatarSrc}
          icon={!avatarTitle ? avatarIcon || <UserOutlined /> : null}
          style={avatarStyles}
        >
          {avatarTitle}
        </Avatar>
      </div>
      {title && (
        <div className={styles.title} title={title}>
          {title}
        </div>
      )}
    </div>
  );
}

export default EntityAvatar;
