import React from "react";

function AvatarFieldContainer({ field, onChange }) {
  // const handleClear = useCallback(() => {
  //   form.setFieldValue(field.name, "");
  // }, []);

  // const error = form.errors[field.name];

  return (
    <input
      id="file-upload"
      // onClick={handleClear}
      type="file"
      name={field.name}
      onChange={onChange}
    />
  );
}

export default AvatarFieldContainer;
