export const currentUserIdSelector = state => {
  return state.auth.userId;
};

export const currentUserSelector = state => {
  return state.entities.teamMembers[currentUserIdSelector(state)];
};

export const currentUserOptionsSelector = state => {
  const currentUser = currentUserSelector(state);
  return currentUser.options;
};

export const newCaseNotificationSoundSelector = state => {
  const options = currentUserOptionsSelector(state);
  return options ? options.newCaseNotificationSound : true;
};

export const newMessageNotificationSoundSelector = state => {
  const options = currentUserOptionsSelector(state);
  return options ? options.newMessageNotificationSound : true;
};
