import React, { useEffect } from "react";
import SettingsSubMenu from "components/settings/SettingsSubMenu";
import SettingsPipedriveContainer from "pages/SettingsPipedrive/containers/SettingsPipedriveContainer";
import SettingsLayout from "components/SettingsLayout";

function SettingsPipedrive() {
  useEffect(() => {
    document.title = "Pipedrive integration";
    localStorage.setItem("lastSettingRoute", "/settings/pipedrive");
  }, []);

  return (
    <SettingsLayout
      renderSubmenu={() => (
        <SettingsSubMenu activeMenuItem={"/settings/pipedrive"} />
      )}
      renderContent={() => (
        <SettingsPipedriveContainer />
      )}
    />
  );
}

export default SettingsPipedrive;
