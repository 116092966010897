import React from "react";
import styles from "./QualifySelectItem.module.css";

function QualifySelectItem({ image, label }) {
  return (
    <div className={styles.content}>
      <img src={image} alt="" />
      <span>{label}</span>
    </div>
  );
}

export default QualifySelectItem;
