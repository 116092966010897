import React, { useCallback } from "react";
import { Checkbox } from "design-system-react";

function CheckboxField({ form, field, onValueChange, ...props }) {
  const handleChange = useCallback(async () => {
    await form.setFieldValue(field.name, !field.value);
    onValueChange && onValueChange(field.value);
  }, [form, onValueChange, field.value, field.name]);

  return (
    <Checkbox isChecked={field.value} onChange={handleChange} {...props} />
  );
}

export default CheckboxField;
