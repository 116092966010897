import React, { useState, useCallback } from "react";
import { Row, Col, Container } from "design-system-react";
import SettingsChannelHeader from "components/SettingsHeader";
import SettingsWhatsAppModalAddContainer from "./SettingsWhatsAppModalAddContainer";
import SettingsWhatsAppListContainer from "./SettingsWhatsAppListContainer";

function SettingsWhatsAppContainer() {
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [addModalParams, setAddModalParams] = useState({
    type: null,
    id: null
  });

  const addModal = useCallback(() => {
    setAddModalParams({ type: "add", id: null });
    setAddModalOpen(!addModalOpen);
  }, [addModalOpen]);

  const restoreModal = useCallback(channelId => {
    setAddModalParams({ type: "restore", id: channelId });
    setAddModalOpen(true);
  }, []);

  return (
    <Row fullHeight column>
      <Container>
        <SettingsChannelHeader
          title="WhatsApp"
          logo="/images/whatsApp_100.svg"
          onAction={addModal}
        >
          Communicate with your customers in WhatsApp channel by sending and
          receiving messages in Hugery directly. Process the message
          requests as Hugery cases.
        </SettingsChannelHeader>
      </Container>
      <Col justified>
        <SettingsWhatsAppListContainer restoreModal={restoreModal} />
      </Col>
      <SettingsWhatsAppModalAddContainer
        isOpen={addModalOpen}
        toggle={addModal}
        addModalParams={addModalParams}
      />
    </Row>
  );
}

export default SettingsWhatsAppContainer;
