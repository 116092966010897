export const replaceLink = text => {
  let linkPattern = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#%?=~_|!:,.;/]*[-A-Z0-9+&@#%=~_|/])/gim;
  let wwwLinkPattern = /(^|[^])(www\.[\S]+(\b|$))/gim;
  let emailPattern = /(([a-zA-Z0-9])+@[a-zA-Z]+?(\.[a-zA-Z]{2,6})+)/gim;

  if (text.search(linkPattern) >= 0) {
    return {
      __html: text.replace(linkPattern, '<a href="$1" target="_blank">$1</a>')
    };
  }

  if (text.search(wwwLinkPattern) >= 0) {
    return {
      __html: text.replace(
        wwwLinkPattern,
        '$1<a href="https://$2" target="_blank">$2</a>'
      )
    };
  }

  if (text.search(emailPattern) >= 0) {
    return {
      __html: text.replace(emailPattern, '<a href="mailto:$1">$1</a>')
    };
  }

  return { __html: text };
};
