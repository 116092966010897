export const translation_default = {
  autoMessage: {
    en:
      "Usually we respond immediately. If not, you can expect a reply within a few hours. Use email to be notified about a response.",
    kz:
      "Әдетте біз бірден жауап береміз. Жылдам жауап алмаған жағдайда, жауап туралы хабарлама алу үшін, электрондық поштаны пайдаланыңыз.",
    ru:
      "Обычно мы отвечаем мгновенно. Если вы не получите быстрого ответа, то ожидайте его в течение нескольких часов. Используйте электронную почту, чтобы получить уведомление об ответе."
  },
  chatWithPrefered: {
    en: "Chat with us in preferred messaging apps",
    kz: "Сізге ыңғайлы мессенджер арқылы байланысқа шығыңыз",
    ru: "Свяжитесь с нами в любом удобном мессенджере"
  },
  delivered: {
    en: "Delivered",
    kz: "Жолданды",
    ru: "Доставлено"
  },
  email: {
    en: "Email",
    kz: "Электрондық пошта",
    ru: "Электронная почта"
  },
  formSuccessMessage: {
    en: "Thank you",
    kz: "Рахмет",
    ru: "Спасибо"
  },
  helloMessage: {
    en: "Hello there",
    kz: "Сәлем",
    ru: "Привет"
  },
  iAgreeWith: {
    en: "I agree with the",
    kz: "Мен Құпиялылық",
    ru: "Я согласен с"
  },
  name: { kz: "Атыңыз", en: "Name", ru: "Имя" },
  notDelivered: {
    kz: "Жеткізілмеді",
    en: "Not delivered",
    ru: "Не доставлено"
  },
  offline: { kz: "Offline", en: "Offline", ru: "Офлайн" },
  offlineMessage: {
    en:
      "We are sorry, but we are offline at the moment. Ask us anything, we will reply as soon as possible.",
    kz:
      "Кешірім сұраймыз, қазір біз офлайнбыз. Сұрағыңызды қойсаңыз, біз жақын уақытта жауап береміз.",
    ru:
      "Приносим извинения, но в данный момент мы офлайн. Задайте свой вопрос и мы ответим как можно быстрее."
  },
  online: { kz: "Online", en: "Online", ru: "Онлайн" },
  openChat: { kz: "Бізге жазыңыз", en: "Open chat", ru: "Открыть чат" },
  phoneNumber: { kz: "Нөмір", en: "Phone number", ru: "Телефон" },
  powered: {
    kz: "Платформасында жұмыс жасайды",
    en: "Powered by",
    ru: "Работает на платформе"
  },
  privacyPolicy: {
    kz: "саясатымен келісемін",
    en: "Privacy Policy",
    ru: "Политика Конфиденциальности"
  },
  send: { kz: "Жіберу", en: "Send", ru: "Отправить" },
  sending: { kz: "Жіберіледі", en: "Sending", ru: "Отправляется" },
  teamMessage: {
    en:
      "We are here to help you. Ask us anything, we typically reply immediately.",
    kz:
      "Сізге көмек көрсетуге қуаныштымыз. Сұрағыңызды қойсаңыз, біз тез арада жауап береміз.",
    ru:
      "Мы рады вам помочь. Задайте свой вопрос и мы ответим как можно быстрее."
  },
  viaMessaging: {
    kz: "Біз мессенджерлардамыз",
    en: "Go via messaging apps",
    ru: "Мы есть в мессенджерах"
  },
  writeMessage: {
    kz: "Хабарлама жазу",
    en: "Write a message",
    ru: "Написать сообщение"
  }
};

export const translateWelcome = {
  helloMessage: {
    en: "Hello there",
    kz: "Сәлем",
    ru: "Привет"
  },
  teamMessage: {
    en:
      "We are here to help you. Ask us anything, we typically reply immediately.",
    kz:
      "Сізге көмек көрсетуге қуаныштымыз. Сұрағыңызды қойсаңыз, біз тез арада жауап береміз.",
    ru:
      "Мы рады вам помочь. Задайте свой вопрос и мы ответим как можно быстрее."
  },
  offlineMessage: {
    en:
      "We are sorry, but we are offline at the moment. Ask us anything, we will reply as soon as possible.",
    kz:
      "Кешірім сұраймыз, қазір біз офлайнбыз. Сұрағыңызды қойсаңыз, біз жақын уақытта жауап береміз.",
    ru:
      "Приносим извинения, но в данный момент мы офлайн. Задайте свой вопрос и мы ответим как можно быстрее."
  }
};

export const translateQualify = {
  autoMessage: {
    en:
      "Usually we respond immediately. If not, you can expect a reply within a few hours. Use email to be notified about a response.",
    kz:
      "Әдетте біз бірден жауап береміз. Жылдам жауап алмаған жағдайда, жауап туралы хабарлама алу үшін, электрондық поштаны пайдаланыңыз.",
    ru:
      "Обычно мы отвечаем мгновенно. Если вы не получите быстрого ответа, то ожидайте его в течение нескольких часов. Используйте электронную почту, чтобы получить уведомление об ответе."
  }
};
