// user permissions
let _permissions = [];

// setup user permissions
export function setPermissions(permissions) {
  _permissions = permissions || [];
}

// check permission by name
export function can(permission) {
  return _permissions.filter(p => p === permission)[0] !== undefined;
}

// available roles
export const ROLE_SUPER_ADMIN = "ROLE_SUPER_ADMIN";
export const ROLE_ADMIN = "ROLE_ADMIN";
export const ROLE_TEAM_MANAGER = "ROLE_TEAM_MANAGER";
export const ROLE_AGENT = "ROLE_AGENT";

// available permissions
export const TEAM_MEMBER_VIEW_PERMISSION = "team_member_view";
export const TEAM_MEMBER_UPDATE_PROFILE_PERMISSION =
  "team_member_update_profile";
export const TEAM_MEMBER_CASES_PERMISSION = "team_member_cases";
export const CASES_VIEW_PERMISSION = "cases_view";
export const CASES_MESSAGES_PERMISSION = "cases_messages";
export const CASES_ASSIGN_PERMISSION = "cases_assign";
export const CASES_UNASSIGN_PERMISSION = "cases_unassign";
export const CASES_CLOSE_PERMISSION = "cases_close";
export const CASES_SEND_CHANNEL_MESSAGE_PERMISSION =
  "cases_send_channel_message";
export const FILE_UPLOAD_AVATAR_PERMISSION = "file_upload_avatar";
export const FILE_UPLOAD_FILE_PERMISSION = "file_upload_file";
export const TEAM_VIEW_PERMISSION = "team_view";
export const EXTRA_FIELDS_END_USERS_PERMISSION = "extra_fields_end_users";
export const EXTRA_FIELDS_TEAM_MEMBERS_PERMISSION = "extra_fields_team_members";
export const ROLE_VIEW_PERMISSION = "role_view";
export const END_USER_VIEW_PERMISSION = "end_user_view";
export const END_USER_CASES_PERMISSION = "end_user_cases";
export const END_USER_UPDATE_PERMISSION = "end_user_update";
export const END_USER_UPDATE_EXTRA_FIELD_PERMISSION =
  "end_user_update_extra_field";
export const END_USER_CHANGE_QUALIFICATION_TO_LEAD_PERMISSION =
  "end_user_change_qualification_to_lead";
export const END_USER_CHANGE_QUALIFICATION_TO_CUSTOMER_PERMISSION =
  "end_user_change_qualification_to_customer";
export const QUICK_REPLY_VIEW_PERMISSION = "quick_reply_view";
export const QUICK_REPLY_CREATE_PERMISSION = "quick_reply_create";
export const QUICK_REPLY_UPDATE_PERMISSION = "quick_reply_update";
export const QUICK_REPLY_DELETE_PERMISSION = "quick_reply_delete";
export const VIEW_TEAM_MEMBERS_PERMISSION = "view_team_members";
export const VIEW_END_USERS_PERMISSION = "view_end_users";
export const VIEW_TEAMS_PERMISSION = "view_teams";
export const VIEW_CASES_PERMISSION = "view_cases";
export const TEAM_MEMBER_INVITE_PERMISSION = "team_member_invite";
export const TEAM_MEMBER_DELETE_AGENT_PERMISSION = "team_member_delete_agent";
export const TEAM_MEMBER_ENABLE_AGENT_PERMISSION = "team_member_enable_agent";
export const TEAM_MEMBER_DISABLE_AGENT_PERMISSION = "team_member_disable_agent";
export const TEAM_MEMBER_RESTORE_AGENT_PERMISSION = "team_member_restore_agent";
export const TEAM_MEMBER_UPDATE_AGENT_PERMISSION = "team_member_update_agent";
export const TEAM_MEMBER_ANALYTICS_PERMISSION = "team_member_analytics";
export const CASES_TEAMS_FOR_ASSIGN_PERMISSION = "cases_teams_for_assign";
export const CASES_TEAM_MEMBERS_FOR_ASSIGN_PERMISSION =
  "cases_team_members_for_assign";
export const CASES_CHECK_VIEW_PERMISSION = "cases_check_view";
export const CASES_ANALYTICS_PERMISSION = "cases_analytics";
export const TEAM_CREATE_PERMISSION = "team_create";
export const TEAM_DELETE_PERMISSION = "team_delete";
export const TEAM_RESTORE_PERMISSION = "team_restore";
export const TEAM_DISABLE_PERMISSION = "team_disable";
export const TEAM_ENABLE_PERMISSION = "team_enable";
export const TEAM_UPDATE_PERMISSION = "team_update";
export const TEAM_ADD_TEAM_MEMBER_PERMISSION = "team_add_team_member";
export const TEAM_REMOVE_TEAM_MEMBER_PERMISSION = "team_remove_team_member";
export const TEAM_TEAM_MEMBERS_PERMISSION = "team_team_members";
export const TEAM_TEAM_MEMBERS_FOR_ADD_PERMISSION = "team_team_members_for_add";
export const CHANNEL_VIEW_PERMISSION = "channel_view";
export const CHANNEL_CREATE_PERMISSION = "channel_create";
export const CHANNEL_DELETE_PERMISSION = "channel_delete";
export const CHANNEL_RESTORE_PERMISSION = "channel_restore";
export const CHANNEL_UPDATE_PERMISSION = "channel_update";
export const TEAM_MEMBER_DELETE_ADMIN_PERMISSION = "team_member_delete_admin";
export const TEAM_MEMBER_ENABLE_ADMIN_PERMISSION = "team_member_enable_admin";
export const TEAM_MEMBER_DISABLE_ADMIN_PERMISSION = "team_member_disable_admin";
export const TEAM_MEMBER_RESTORE_ADMIN_PERMISSION = "team_member_restore_admin";
export const TEAM_MEMBER_UPDATE_ADMIN_PERMISSION = "team_member_update_admin";
