import React from "react";
import styles from "./index.module.css";

function CaseChannel({ title, label }) {
  return (
    <div className={styles.wrapper}>
      <div>{label}</div>
      <div className={styles.title}>{title}</div>
    </div>
  );
}

export default CaseChannel;
