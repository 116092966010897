import React from "react";
import PropTypes from "prop-types";
import styles from "../AssignDropdown/index.module.css";

function AssignDropdownBackItem({ children, onBack }) {
  return (
    <div className={styles.backItem} onClick={onBack}>
      <div className={styles.backItemArrow}>
        <i className="ico-arrow-left" />
      </div>
      <div className={styles.backItemLabel}>{children}</div>
    </div>
  );
}

AssignDropdownBackItem.propTypes = {
  onBack: PropTypes.func.isRequired
};

export default AssignDropdownBackItem;
