import React, { useCallback } from "react";
import classNames from "classnames";
import styles from "./MessageFormSendButton.module.css";
import PropTypes from "prop-types";
import { Tooltip } from "design-system-react";

function MessageFormSendButton({ onClick, disabled, className }) {
  const classes = classNames(
    styles.sendButton,
    disabled && styles.disabled,
    className
  );

  const handleClick = useCallback(() => {
    if (!disabled) {
      onClick();
    }
  }, [onClick, disabled]);

  return (
    <div id="form_send_button" className={classes} onClick={handleClick}>
      <div className={styles.sendButtonContainer}>
        <svg
          id="Send_button"
          data-name="Send button"
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          viewBox="0 0 25 25"
        >
          <rect
            id="Rounded_Rectangle_2_copy_21"
            data-name="Rounded Rectangle 2 copy 21"
            className="cls-1"
            width="25"
            height="25"
            rx="12.5"
            ry="12.5"
          />
          <path
            id="Rounded_Rectangle_3_copy_43"
            data-name="Rounded Rectangle 3 copy 43"
            className="cls-2"
            d="M1476.84,3731.13l4.01,4.02a0.5,0.5,0,0,1,0,.7,0.483,0.483,0,0,1-.7,0l-3.15-3.15v11.81a0.5,0.5,0,0,1-1,0v-11.83l-3.16,3.17a0.495,0.495,0,0,1-.7-0.7l4-4.02A0.5,0.5,0,0,1,1476.84,3731.13Z"
            transform="translate(-1464 -3726)"
            style={{ fill: "#fff" }}
          />
        </svg>
        <Tooltip size="sm" position="top" withDelay>
          Send
        </Tooltip>
      </div>
    </div>
  );
}

MessageFormSendButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default MessageFormSendButton;
