import React from "react";
import { connect } from "react-redux";
import LoginLayout from "../layouts/LoginLayout";
import { Redirect, Route } from "react-router-dom";

const LoginLayoutRoute = ({ component: Component, ...props }) => {
  if (props.userId) {
    return <Redirect to="/" />;
  }

  return (
    <Route
      {...props}
      render={matchProps => (
        <LoginLayout>
          <Component {...matchProps} />
        </LoginLayout>
      )}
    />
  );
};

const mapStateToProps = state => {
  return { userId: state.auth.userId };
};

export default connect(mapStateToProps)(LoginLayoutRoute);
