import React, { useMemo, useCallback, useEffect } from "react";
import { Button } from "design-system-react";
import { connect } from "react-redux";
import { setChannels, setSetup } from "modules/widgetSetup";
import { Redirect } from "react-router-dom";
import { Formik } from "formik";
import { STEP_QUALIFY } from "helpers/steps";
import WidgetChannelsFormContainer from "./WidgetChannelsFormContainer";
import useStepPage from "hooks/useStepPage";
import { api } from "utils/widgetClient";
import { ALERT_SUCCESS, showAlert } from "utils/alertManager";
import { FormError } from "utils/formError";
import WidgetFooter from "components/Widget/WidgetFooter";

const channelsDefault = [
  { link: "", type: "facebook", enabled: false },
  { link: "", type: "whatsapp", enabled: false },
  { link: "", type: "telegrambot", enabled: false },
  { link: "", type: "viber", enabled: false },
  { link: "", type: "vk", enabled: false },
  { link: "", type: "email", enabled: false }
  // { link: "", type: "instagram", enabled: false },
  // { link: "", type: "kik", enabled: false },
  // { link: "", type: "line", enabled: false },
  // { link: "", type: "ok", enabled: false },
  // { link: "", type: "skype", enabled: false },
  // { link: "", type: "twitter", enabled: false },
  // { link: "", type: "wechat", enabled: false }
];

let noMutableInitial = {};

function WidgetChannelsPageContainer({
  setup,
  setSetup,
  setChannels,
  handleSetModal,
  modalValue
}) {
  const page = useStepPage();
  const initialValues = useMemo(() => {
    return {
      blingerMessenger:
        String(setup.channels.blingerMessenger) === "false" ? false : true,
      channels: (setup.channels && setup.channels.channels) || channelsDefault
    };
  }, [setup.channels]);

  useEffect(() => {
    document.title = "Setup channels";

    noMutableInitial = initialValues;

    return () => {
      setChannels(noMutableInitial);
    };
    // eslint-disable-next-line
  }, [setChannels]);

  const handleSubmitForm = useCallback(
    (values, { setErrors, resetForm }) => {
      if (!page.loading) {
        page.setLoading(true);

        api.widget
          .updateChannels(setup.id, values)
          .ready.then(response => {
            noMutableInitial = response.data.data.channels;
            setSetup(response.data.data);
            if (response.data.data.step === STEP_QUALIFY) {
              page.setRedirectToNextStep(true);
            } else {
              showAlert(ALERT_SUCCESS, "Channels settings saved successfully");
            }
            page.setInitialSaved(true);
            page.setSubmitDisabled(true);
            resetForm(values);
          })
          .catch(e => {
            if (e instanceof FormError) {
              setErrors(e.errors);
            }
          })
          .finally(() => {
            page.setLoading(false);
          });
      }
    },
    [page, setSetup, setup.id]
  );

  if (page.redirectToBackStep) {
    return <Redirect to={"/widget/availability"} />;
  }

  if (page.redirectToNextStep) {
    return <Redirect to={"/widget/qualify"} />;
  }

  return (
    <>
      <Formik
        ref={page.form}
        onSubmit={handleSubmitForm}
        initialValues={initialValues}
        render={props => (
          <WidgetChannelsFormContainer
            {...props}
            modalValue={modalValue}
            handleSetModal={handleSetModal}
            submitDisabled={page.submitDisabled}
            setSubmitDisabled={page.setSubmitDisabled}
          />
        )}
      />
      <WidgetFooter>
        <Button
          processing={page.loading}
          disabled={page.submitDisabled}
          onClick={page.handleNextClick}
        >
          Save
        </Button>
      </WidgetFooter>
    </>
  );
}

const mapStateToProps = state => {
  return {
    setup: state.widgetSetup.current
  };
};

const mapDispatchToProps = dispatch => ({
  setSetup: setup => dispatch(setSetup(setup)),
  setChannels: values => dispatch(setChannels(values))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WidgetChannelsPageContainer);
