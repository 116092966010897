import React from "react";
import WidgetHeaderTitle from "components/Widget/WidgetHeaderTitle";
import WidgetHeaderDescription from "components/Widget/WidgetHeaderDescription";
import WidgetHeader from "components/Widget/WidgetHeader";
import WidgetQualifyContainer from "./containers/WidgetQualifyContainer";

function WidgetQualify() {
  return (
    <>
      <WidgetHeader>
        <WidgetHeaderTitle>Qualify leads</WidgetHeaderTitle>
        <WidgetHeaderDescription>
          Customize the style your widget to fit your brand guidelines to
          represent your company
        </WidgetHeaderDescription>
      </WidgetHeader>
      <WidgetQualifyContainer />
    </>
  );
}

export default WidgetQualify;
