import { withNamespace } from "utils/namespace";

const type = withNamespace("pages/settingsFacebook");

export const SET_FACEBOOK_CHANNELS = type("SET_FACEBOOK_CHANNELS");
export const ADD_FACEBOOK_CHANNEL = type("ADD_FACEBOOK_CHANNEL");

let initialState = {
  ids: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_FACEBOOK_CHANNELS:
      return {
        ...state,
        ids: action.payload.ids
      };
    case ADD_FACEBOOK_CHANNEL:
      return {
        ...state,
        ids: [action.payload.id, ...state.ids]
      };
    default:
      return state;
  }
}

// actions

export function setFacebookChannels(ids) {
  return {
    type: SET_FACEBOOK_CHANNELS,
    payload: {
      ids
    }
  };
}

export function addFacebookChannel(id) {
  return {
    type: ADD_FACEBOOK_CHANNEL,
    payload: {
      id
    }
  };
}
