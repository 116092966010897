import React from "react";
import MessageListItemBubble from "pages/Cases/components/MessageListItemBubble";
import MessageListItemTime from "pages/Cases/components/MessageListItemTime";
import MessageListItemStatus from "pages/Cases/components/MessageListItemStatus";
import MessageListItemBubbleInfo from "pages/Cases/components/MessageListItemBubbleInfo";
import { printChannel } from "../../helpers/channels";
import MessageListItemBubbleContact from "../../pages/Cases/components/MessageListItemBubbleContact";

function contactLink(type, phone) {
  switch (type) {
    case "whatsapp":
      if (phone) {
        return `https://wa.me/${phone}`;
      }
      return null;
    case "viber":
      if (phone) {
        return `viber://chat?number=${phone}`;
      }
      return null;
    default:
      return null;
  }
}

function ContactMessageBubble({
  content,
  status,
  channelType,
  outgoing,
  time
}) {
  const contact = JSON.parse(content);

  const name =
    contact.name ||
    contact.user_name ||
    contact.first_name ||
    "@" + contact.user_id ||
    null;
  const phone = contact.phone_number || contact.phone || null;

  return (
    <MessageListItemBubble
      status={status}
      file
      noPadding
      dark={outgoing}
      time={time}
    >
      <MessageListItemBubbleContact
        name={name}
        phone={phone}
        link={contactLink(channelType, phone)}
        avatar={contact.avatar}
        channelName={printChannel(channelType)}
        renderStatus={
          <MessageListItemBubbleInfo>
            <MessageListItemTime time={time} />
            {outgoing && <MessageListItemStatus status={status} />}
          </MessageListItemBubbleInfo>
        }
      />
    </MessageListItemBubble>
  );
}

export default ContactMessageBubble;
