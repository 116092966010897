import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import MessengerCheckbox from "../components/MessengerCheckbox";
import { defineChannel } from "helpers/defineChannel";
import WidgetChannelFormWrapper from "../components/WidgetChannelFormWrapper";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const ChannelList = React.memo(function ChannelList({
  list,
  handleSetModal,
  handleDisconnect
}) {
  return list.map((channel, index) => {
    return (
      <Draggable
        isDragDisabled={!channel.enabled}
        draggableId={channel.type}
        key={channel.type}
        index={index}
      >
        {provided => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <MessengerCheckbox
              messenger={channel.type}
              name={defineChannel(channel.type).name}
              connection={channel.enabled}
              link={channel.link}
              handleClickConnect={() => {
                handleSetModal(channel.link, channel.type);
              }}
              handleClickDisconnect={() => {
                handleDisconnect(channel.type);
              }}
            />
          </div>
        )}
      </Draggable>
    );
  });
});

function WidgetChannelListFormContainer({
  channelList,
  changeList,
  handleSubmitForm,
  liveChatConnect,
  handleLiveChatConnect,
  handleSetModal,
  handleDisconnect
}) {
  const sortedList = channelList.sort((a, b) => {
    if (a.enabled > b.enabled) {
      return -1;
    } else if (a.enabled === b.enabled) {
      return 0;
    } else {
      return 1;
    }
  });

  const [isDrag, setDrag] = useState(false);

  const onDragStart = () => {
    setDrag(true);
  };

  const onDragEnd = result => {
    setDrag(false);
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const list = reorder(
      sortedList,
      result.source.index,
      result.destination.index
    );

    changeList({ list });
  };

  return (
    <WidgetChannelFormWrapper isDrag={isDrag}>
      <form onSubmit={handleSubmitForm}>
        <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
          <Droppable droppableId="list">
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <MessengerCheckbox
                  messenger="blinger"
                  name={defineChannel("blinger").name}
                  connection={liveChatConnect}
                  handleClickConnect={() => handleLiveChatConnect(true)}
                  handleClickDisconnect={() => handleLiveChatConnect(false)}
                />
                <ChannelList
                  list={channelList}
                  handleSetModal={handleSetModal}
                  handleDisconnect={handleDisconnect}
                />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </form>
    </WidgetChannelFormWrapper>
  );
}

export default WidgetChannelListFormContainer;
