import React, { useCallback, useState, useEffect } from "react";
import {
  Table,
  TableHeader,
  TableHeaderColumn,
  TableHeaderColumnMenu,
  TableBody,
  Col,
  Preloader,
  Container,
  Scrollbar
} from "design-system-react";
import {
  MODAL_DEFAULT,
  MODAL_ERROR,
  MODAL_PROCESS,
  MODAL_SUCCESS,
  showConfirmModal,
  updateConfirmModal
} from "utils/modalManager";
import { api } from "utils/client";
import { CommonError } from "utils/commonError";
import SettingsChannelListItem from "components/SettingsChannelListItem";
import EmptyState from "components/EmptyState";
import axios from "axios";
import { setFacebookChannels } from "modules/pages/settingsFacebook";
import connect from "react-redux/es/connect/connect";

function SettingsFacebookListContainer({ channelIds, setFacebookChannels }) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    setFacebookChannels([]);

    const request = api.facebook.all();

    request.ready
      .then(response => {
        setFacebookChannels(response.data.data.map(channel => channel.id));
        setLoading(false);
      })
      .catch(error => {
        if (!axios.isCancel(error)) {
          setLoading(false);
        }
      });

    return () => {
      request.cancelRequest.cancel();
    };
  }, [setFacebookChannels]);

  const handleActionDropdown = useCallback((channelId, name, action) => {
    switch (action) {
      case "restore":
        {
          const modalId = showConfirmModal(
            MODAL_DEFAULT,
            "Are you sure you want to restore " + name + "?",
            {
              actionButtonText: "Restore",
              onAction: () => {
                updateConfirmModal(modalId, MODAL_PROCESS, "Restoring...");

                api.facebook
                  .restore(channelId)
                  .ready.then(() => {
                    updateConfirmModal(
                      modalId,
                      MODAL_SUCCESS,
                      "Channel was successfully restored"
                    );
                  })
                  .catch(e => {
                    const errorMessage = "Ooops, something went wrong";

                    updateConfirmModal(
                      modalId,
                      MODAL_ERROR,
                      e instanceof CommonError ? e.error : errorMessage
                    );
                  });
              }
            }
          );
        }
        break;
      case "delete":
        {
          const modalId = showConfirmModal(
            MODAL_DEFAULT,
            "Are you sure you want to delete " + name + "?",
            {
              actionButtonText: "Delete",
              onAction: () => {
                updateConfirmModal(modalId, MODAL_PROCESS, "Deleting...");

                api.facebook
                  .delete(channelId)
                  .ready.then(() => {
                    updateConfirmModal(
                      modalId,
                      MODAL_SUCCESS,
                      "Channel was successfully deleted"
                    );
                  })
                  .catch(e => {
                    const errorMessage = "Ooops, something went wrong";

                    updateConfirmModal(
                      modalId,
                      MODAL_ERROR,
                      e instanceof CommonError ? e.error : errorMessage
                    );
                  });
              }
            }
          );
        }
        break;
      default:
        break;
    }
  }, []);

  if (loading && !channelIds.length) {
    return (
      <Col align="center" justified>
        <Preloader size="32" color="#807d7f" />
      </Col>
    );
  }

  if (!channelIds.length) {
    return (
      <Col align="center" verticalAlign="center" justified>
        <EmptyState title="No channels integrated yet">
          You must add an integration with this channel <br /> to start
          receiving messages
        </EmptyState>
      </Col>
    );
  }

  return (
    <Scrollbar>
      <Container>
        <Table headerBorder>
          <TableHeader fixed>
            <TableHeaderColumn justified fixed>
              Name
            </TableHeaderColumn>
            <TableHeaderColumn>Added</TableHeaderColumn>
            <TableHeaderColumn>Status</TableHeaderColumn>
            <TableHeaderColumnMenu />
          </TableHeader>
          <TableBody>
            {channelIds.map(id => (
              <SettingsChannelListItem
                key={id}
                channelId={id}
                handleActionDropdown={handleActionDropdown}
              />
            ))}
          </TableBody>
        </Table>
      </Container>
    </Scrollbar>
  );
}

const mapStateToProps = state => {
  return { channelIds: state.pages.settingsFacebook.ids || [] };
};

const mapDispatchToProps = dispatch => ({
  setFacebookChannels: ids => dispatch(setFacebookChannels(ids))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsFacebookListContainer);
