import { withNamespace } from "utils/namespace";

const type = withNamespace("quickReplies");

// action types
export const SET_QUICK_REPLY_IDS = type("SET_QUICK_REPLY_IDS");
export const ADD_QUICK_REPLY_ID = type("ADD_QUICK_REPLY_ID");
export const REMOVE_QUICK_REPLY_IDS = type("REMOVE_QUICK_REPLY_IDS");

let initialState = {
  ids: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_QUICK_REPLY_IDS:
      return {
        ...state,
        ids: action.payload.ids
      };
    case ADD_QUICK_REPLY_ID:
      return {
        ...state,
        ids: [...state.ids, action.payload.id]
      };
    case REMOVE_QUICK_REPLY_IDS:
      return {
        ...state,
        ids: state.ids.filter(id => id !== action.payload.id)
      };
    default:
      return state;
  }
}

// actions
export function setQuickReplyIds(ids) {
  return {
    type: SET_QUICK_REPLY_IDS,
    payload: {
      ids
    }
  };
}

export function addQuickReplyId(id) {
  return {
    type: ADD_QUICK_REPLY_ID,
    payload: {
      id
    }
  };
}

export function removeQuickReplyId(id) {
  return {
    type: REMOVE_QUICK_REPLY_IDS,
    payload: {
      id
    }
  };
}
