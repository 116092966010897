import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./index.module.css";

function NavPanel({ children, borderBottom }) {
  const classes = classNames(styles.panel, borderBottom && styles.border);
  return <div className={classes}>{children}</div>;
}

NavPanel.propTypes = {
  children: PropTypes.node.isRequired,
  borderBottom: PropTypes.bool
};

NavPanel.defaultTypes = {
  borderBottom: false
};

export default NavPanel;
