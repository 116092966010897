import { CALL_API } from "utils/api";
import merge from "lodash/merge";
import { withNamespace } from "utils/namespace";
import { login } from "modules/pages/login";

const type = withNamespace("pages/login");

export const CONFIRM_INVITE_REQUEST = type("CONFIRM_INVITE_REQUEST");
export const CONFIRM_INVITE_SUCCESS = type("CONFIRM_INVITE_SUCCESS");
export const CONFIRM_INVITE_FAILURE = type("CONFIRM_INVITE_FAILURE");

let initialState = {
  confirmInviteLoading: false
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CONFIRM_INVITE_REQUEST:
      return merge({}, state, {
        confirmInviteLoading: true
      });
    case CONFIRM_INVITE_SUCCESS:
      return merge({}, state, {
        confirmInviteLoading: false
      });
    case CONFIRM_INVITE_FAILURE:
      return merge({}, state, {
        confirmInviteLoading: false
      });
    default:
      return state;
  }
}

// actions
export function confirmInvite(params, form) {
  return dispatch => {
    const actionResponse = dispatch({
      [CALL_API]: {
        endpoint: "/team_users/invite_confirm",
        options: {
          method: "post",
          data: params
        },
        types: {
          request: CONFIRM_INVITE_REQUEST,
          success: CONFIRM_INVITE_SUCCESS,
          failure: CONFIRM_INVITE_FAILURE
        },
        form
      }
    });

    actionResponse.ready.then(action => {
      if (action.success) {
        const users = action.response.users;
        const user = users[Object.keys(users)[0]];

        dispatch(
          login({
            email: user.attributes.email,
            password: params.password,
            rememberMe: true
          })
        );
      }
    });

    return actionResponse;
  };
}
