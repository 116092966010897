import React, { useMemo, useEffect, useCallback } from "react";
import { Formik } from "formik/dist/index";
import { Button } from "design-system-react";
import { Redirect } from "react-router-dom";
import { translation_default } from "helpers/translations";
import { connect } from "react-redux";
import { api } from "utils/widgetClient";
import { setSetup, setQualify } from "modules/widgetSetup";
import useStepPage from "hooks/useStepPage";
import { STEP_LIVE } from "helpers/steps";
import { ALERT_SUCCESS, showAlert } from "utils/alertManager";
import { FormError } from "utils/formError";
import WidgetQualifyFormContainer from "./WidgetQualifyFormContainer";
import WidgetFormLayout from "components/Widget/WidgetFormLayout";
import WidgetFooter from "components/Widget/WidgetFooter";

let noMutableInitial = {};

function WidgetQualifyContainer({ setQualify, setSetup, setup }) {
  const page = useStepPage();
  const initialValues = useMemo(() => {
    return {
      autoMessage:
        setup.qualify.autoMessage ||
        translation_default.teamMessage[setup.introduce.defaultLanguage],
      translations: {
        autoMessage: setup.translations.autoMessage
      },
      details: setup.qualify.details || {
        name: true,
        phone: true,
        email: false
      },
      rule: setup.qualify.rule || "firstMessage",
      privacyPolicy: setup.qualify.privacyPolicy
        ? {
            title: setup.qualify.privacyPolicy.title || "",
            url: setup.qualify.privacyPolicy.url || ""
          }
        : { title: "", url: "" }
    };
  }, [setup.introduce, setup.qualify, setup.translations]);

  useEffect(() => {
    document.title = "Qualify leads";
    noMutableInitial = initialValues;

    return () => {
      // reset form
      setQualify(noMutableInitial);
    };
    // eslint-disable-next-line
  }, [setQualify]);

  const handleSubmitForm = useCallback(
    (values, { setErrors, resetForm }) => {
      if (!page.loading) {
        page.setLoading(true);
        api.widget
          .updateQualify(setup.id, values)
          .ready.then(response => {
            setSetup(response.data.data);
            noMutableInitial = response.data.data.qualify;
            if (response.data.data.step === STEP_LIVE) {
              page.setRedirectToNextStep(true);
            } else {
              showAlert(
                ALERT_SUCCESS,
                "Availability settings saved successfully"
              );
            }
            page.setInitialSaved(true);
            page.setSubmitDisabled(true);
            resetForm(values);
          })
          .catch(e => {
            if (e instanceof FormError) {
              setErrors(e.errors);
            }
          })
          .finally(() => {
            page.setLoading(false);
          });
      }
    },
    [page, setSetup, setup.id]
  );

  if (page.redirectToBackStep) {
    return <Redirect to={"/widget/update-channels"} />;
  }

  if (page.redirectToNextStep) {
    return <Redirect to={"/widget/live"} />;
  }

  return (
    <WidgetFormLayout>
      <Formik
        ref={page.form}
        onSubmit={handleSubmitForm}
        initialValues={initialValues}
        render={props => (
          <WidgetQualifyFormContainer
            {...props}
            submitDisabled={page.submitDisabled}
            setSubmitDisabled={page.setSubmitDisabled}
          />
        )}
      />
      <WidgetFooter>
        <Button
          processing={page.loading}
          disabled={page.submitDisabled}
          onClick={page.handleNextClick}
        >
          Save
        </Button>
      </WidgetFooter>
    </WidgetFormLayout>
  );
}

const mapStateToProps = state => {
  return {
    setup: state.widgetSetup.current
  };
};

const mapDispatchToProps = dispatch => ({
  setSetup: setup => dispatch(setSetup(setup)),
  setQualify: welcome => dispatch(setQualify(welcome))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WidgetQualifyContainer);
