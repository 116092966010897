import React from "react";
import styles from "./ErrorPageCard.module.css";
import PropTypes from "prop-types";

function ErrorPageCard({ title, image, text }) {
  return (
    <div className={styles.errorPageCardContainer}>
      <div className={styles.errorPageCardWrapper}>
        <div className={styles.errorPageCard}>
          <div className={styles.textBlock}>
            <div className={styles.logo}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="49"
                height="48"
                viewBox="0 0 49 48"
              >
                <image
                  id="Rounded_Rectangle_1_copy"
                  data-name="Rounded Rectangle 1 copy"
                  width="49"
                  height="48"
                  href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAADEAAAAwCAQAAAASyVoyAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAHdElNRQfjCgIKBwdgD5UUAAACfElEQVRYw72Yz08TURDHP7tJacOPtIKJMYYYGwMKBwxBbx70D1CrQQ/q1Ysm3uAkTfwbvBniQS8mVUhI9OSPBI5wwBsa1PIzSkijQMFG+HpYV4WWvtftrjOnTvrms7Mz772ZdaguDqc4Qw/HaKeNJlqAdTZYZZlZpplkkh0CSowLDLOMDPqVx1whVqv7ZgZYNDr/V+cZJGUPuMFSTe59XeEWjs3z5wK59/UFB6oDjjJdF0CIGTr2B6SZqxsgxCLHKwOSfAgFIMQshyohnocG8HJSlviboQKEuL0b0MRC6IhV2jznLgB3OVLr7jRKKwN/fzQE3GomLdDsR3GZw6HHAJDiuo+4FgkA37NDggKJ6v8cB84GQexwkAKcN79VSUHzkQGXvsheE8BpcOmMFNFZA8LhEjnybLFFnhwZm6sBz7vFvpakMe2VMSXNuVgH+GaHkIrKqktxxdWlrIqSXssxQxxs6kKSNtS7y9arDUn95tVJ2LRDDJVZhySNmBEJa8SJMmuHpDkLhIPMZSEgTmmPtYEf/DQ3UCmX7/WUvUXhrrms2TlLV7QsmZYV2XH5bIe4WtEyZVr2CWC4nqLNmNY+A5cZuygameA+3cSIcZJ7TNDIW0ZNy2YAzkV6gGQAEuadMa4JOcoop7xKKimvnDI2h8c2rV4wIzaHSCB9A97d/dQuGwHkj+f/0OSUeBBJDA+928KTSBtOX8Jvm++UhzUaKuDV7255l6RCHGHmK48w4Q1iX+jevwbCGCffm9qmeofil6ah2JOIR3s/lsEaMStka/lA4UmMizyy+szyhH7i+zsyBebQSx89pGmnjRYagU3WWGWBj7xjikm2q7v4BZJ9PP74NZ/OAAAAAElFTkSuQmCC"
                />
              </svg>
            </div>
            <div className={styles.title}>
              <p>
                {title}
                <span>{image}</span>
              </p>
            </div>
            <p className={styles.text}>{text}</p>
          </div>
          <div className={styles.regardsBlock}>
            <div className={styles.divider} />
            <p className={styles.regards}>
              Best regards,
              <br />
              The Hugery Team
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

ErrorPageCard.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.node
};

export default ErrorPageCard;
