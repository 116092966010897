import React from "react";
import styles from "pages/Cases/components/MessageListScrollButton.module.css";
import PropTypes from "prop-types";

function MessageListScrollButton({ onScrollClick }) {
  return (
    <div className={styles.button} onClick={onScrollClick}>
      <i className="ico-down-open-big" />
    </div>
  );
}

MessageListScrollButton.propTypes = {
  onScrollClick: PropTypes.func.isRequired
};

export default MessageListScrollButton;
