import React from "react";
import {
  SubMenu,
  SubMenuHeader,
  SubMenuContent,
  SubMenuList,
  SubMenuListItem
} from "design-system-react";
import { Link } from "react-router-dom";

const settingsMenu = [
  {
    title: "Introduce company",
    linkTo: "introduce"
  },
  {
    title: "Style the widget",
    linkTo: "style"
  },
  {
    title: "Welcome your customers",
    linkTo: "welcome"
  },
  {
    title: "Availability",
    linkTo: "availability"
  },
  {
    title: "Setup channels",
    linkTo: "channels"
  },
  {
    title: "Qualify leads",
    linkTo: "qualify"
  },
  {
    title: "Go live",
    linkTo: "live"
  }
];

function WidgetSubMenu({ activeMenuItem }) {
  return (
    <SubMenu>
      <SubMenuHeader>Widget</SubMenuHeader>
      <SubMenuContent>
        <SubMenuList>
          {settingsMenu.map(link => {
            let isActive = activeMenuItem === "/widget/" + link.linkTo;

            return (
              <SubMenuListItem
                key={link.linkTo}
                active={isActive}
                tag={Link}
                to={"/widget/" + link.linkTo}
                text={link.title}
              />
            );
          })}
        </SubMenuList>
      </SubMenuContent>
    </SubMenu>
  );
}

export default React.memo(WidgetSubMenu);
