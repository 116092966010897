import React, { useEffect } from "react";
import { FastField, FieldArray } from "formik";
import { GutterField } from "design-system-react";
import { connect } from "react-redux";
import SelectField from "components/SelectField";
import SwitcherField from "components/SwitcherField";
import CheckboxWrapper from "components/CheckboxWrapper";
import { setAvailability } from "modules/widgetSetup";
import useFormSubmission from "hooks/useFormSubmission";
import GroupError from "components/GroupError";
import WidgetAvailabilityScheduleContainer from "./WidgetAvailabilityScheduleContainer";

const schedule = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday"
];

const timezones = [
  { label: "UTC - 12:00", value: -43200 },
  { label: "UTC -11:00", value: -39600 },
  { label: "UTC -10:00", value: -36000 },
  { label: "UTC -09:30", value: -9.5 * 3600 },
  { label: "UTC -09:00", value: -9 * 3600 },
  { label: "UTC -08:00", value: -8 * 3600 },
  { label: "UTC -07:00", value: -7 * 3600 },
  { label: "UTC -06:00", value: -6 * 3600 },
  { label: "UTC -05:00", value: -5 * 3600 },
  { label: "UTC -04:00", value: -14400 },
  { label: "UTC -03:00", value: -10800 },
  { label: "UTC -01:00", value: -1 * 3600 },
  { label: "UTC -00:00", value: -0 * 3600 },
  { label: "UTC +01:00", value: 3600 },
  { label: "UTC +03:00", value: 3 * 3600 },
  { label: "UTC +03:30", value: 3.5 * 3600 },
  { label: "UTC +04:00", value: 4 * 3600 },
  { label: "UTC +04:30", value: 4.5 * 3600 },
  { label: "UTC +05:00", value: 5 * 3600 },
  { label: "UTC +05:30", value: 5.5 * 3600 },
  { label: "UTC +05:45", value: 5.75 * 3600 },
  { label: "UTC +06:00", value: 6 * 3600 },
  { label: "UTC +06:30", value: 6.5 * 3600 },
  { label: "UTC +07:00", value: 7 * 3600 },
  { label: "UTC +08:00", value: 8 * 3600 },
  { label: "UTC +08:45", value: 8.75 * 3600 },
  { label: "UTC +09:00", value: 9 * 3600 },
  { label: "UTC +09:30", value: 9.5 * 3600 },
  { label: "UTC +10:00", value: 10 * 3600 },
  { label: "UTC +10:30", value: 10.5 * 3600 },
  { label: "UTC +11:00", value: 11 * 3600 },
  { label: "UTC +12:00", value: 12 * 3600 },
  { label: "UTC +12:45", value: 12.75 * 3600 },
  { label: "UTC +13:00", value: 13 * 3600 },
  { label: "UTC +14:00", value: 14 * 3600 }
];

function WidgetAvailabilityFormContainer({
  values,
  handleSubmit,
  setSubmitDisabled,
  submitDisabled,
  setAvailability,
  errors,
  dirty
}) {
  const handleSubmitForm = useFormSubmission(submitDisabled, handleSubmit);

  // update setup on form changes
  useEffect(() => {
    setAvailability(values);
  }, [setAvailability, values]);

  if (!dirty && !submitDisabled) {
    setSubmitDisabled(true);
  }

  if (dirty && submitDisabled) {
    setSubmitDisabled(false);
  }

  return (
    <form onSubmit={handleSubmitForm}>
      <GutterField>
        <FastField
          name="timezone"
          label="Time zone"
          component={SelectField}
          items={timezones}
        />
      </GutterField>
      <GutterField>
        <FieldArray
          name="schedule"
          render={arrayHelpers => {
            return (
              <>
                {schedule.map(day => {
                  return (
                    <WidgetAvailabilityScheduleContainer
                      key={day}
                      day={day}
                      setFieldValue={arrayHelpers.form.setFieldValue}
                      initialStart={
                        arrayHelpers.form.initialValues.schedule[day].start
                      }
                      initialEnd={
                        arrayHelpers.form.initialValues.schedule[day].end
                      }
                      isChecked={
                        values.schedule[day] && values.schedule[day].enabled
                      }
                    />
                  );
                })}
              </>
            );
          }}
        />
        {errors.schedule && <GroupError>{errors.schedule}</GroupError>}
      </GutterField>
      <CheckboxWrapper label="Show online status" noBorderBottom>
        <FastField name="showOnlineStatus" component={SwitcherField} />
      </CheckboxWrapper>
      <CheckboxWrapper label="Don’t show widget when you are offline">
        <FastField name="hideOffline" component={SwitcherField} />
      </CheckboxWrapper>
    </form>
  );
}

const mapDispatchToProps = dispatch => ({
  setAvailability: values => dispatch(setAvailability(values))
});

export default connect(
  null,
  mapDispatchToProps
)(WidgetAvailabilityFormContainer);
