import React from "react";
import styles from "./CaseReassignedAvatars.module.css";

function CaseReassignedAvatars({ firstAvatar, secondAvatar }) {
  return (
    <div className={styles.content}>
      <span className={styles.first}>{firstAvatar}</span>
      <span className={styles.second}>{secondAvatar}</span>
    </div>
  );
}

export default CaseReassignedAvatars;
