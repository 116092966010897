export const TEAM_MEMBER_STATUS_ENABLED = "enabled";
export const TEAM_MEMBER_STATUS_DISABLED = "disabled";
export const TEAM_MEMBER_STATUS_PENDING = "pending";

export function printTeamMemberStatus(status, deleted) {
  if (deleted) {
    return "Deleted";
  }

  switch (status) {
    case TEAM_MEMBER_STATUS_ENABLED:
      return "Enabled";
    case TEAM_MEMBER_STATUS_DISABLED:
      return "Disabled";
    case TEAM_MEMBER_STATUS_PENDING:
      return "Pending";
    default:
      return "";
  }
}
