import React from "react";
import { connect } from "react-redux";
import { DropdownMenuDivider, DropdownMenuLabel } from "design-system-react";
import AssignDropdownBackItem from "../components/AssignDropdown/AssignDropdownBackItem";
import AssignDropdownMenuTeamContainer from "./AssignDropdownMenuTeamContainer";
import AssignDropdownTextItem from "../components/AssignDropdown/AssignDropdownTextItem";

function AssignDropdownMenuTeamMemberTeamsScreenContainer({
  teamIds,
  teamMemberId,
  onSelect,
  onBack
}) {
  return (
    <>
      <AssignDropdownBackItem onBack={onBack}>BACK</AssignDropdownBackItem>
      <DropdownMenuDivider />
      <DropdownMenuLabel>TEAMS</DropdownMenuLabel>
      {teamIds.length === 0 && (
        <AssignDropdownTextItem>Teams not found</AssignDropdownTextItem>
      )}
      {teamIds &&
        teamIds.map(id => {
          return (
            <AssignDropdownMenuTeamContainer
              teamId={id}
              teamMemberId={teamMemberId}
              onSelect={onSelect}
            />
          );
        })}
    </>
  );
}

const mapStateToProps = (state, props) => {
  const teamMember = state.entities.teamMembers[props.teamMemberId];
  const filter = props.filter.trim().toUpperCase();
  const teamIds =
    teamMember &&
    teamMember.teams.filter(id =>
      state.entities.teams[id].title.toUpperCase().includes(filter)
    );

  return {
    teamIds
  };
};

export default connect(mapStateToProps)(
  AssignDropdownMenuTeamMemberTeamsScreenContainer
);
