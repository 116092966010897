import React, { useCallback, useState } from "react";
import { Input } from "design-system-react";
import LabelWithTooltip from "components/LabelWithTooltip";

function InputNumberField({ form, field, maxValue, label, tooltip, type }) {
  const [isFocus, setFocus] = useState(null);
  const handleClear = useCallback(() => {
    form.setFieldValue(field.name, "");
  }, [form, field.name]);

  const handleChange = useCallback(
    e => {
      form.setFieldValue(field.name, e.target.value.replace(/^0/, ""));

      if (e.target.value === "0") {
        form.setFieldValue(field.name, 0);
      }

      if (Math.sign(e.target.value) === -1) {
        form.setFieldValue(field.name, 0);
      }

      if (e.target.value > maxValue) {
        form.setFieldValue(field.name, Number(maxValue));
      }
    },
    [field.name, form, maxValue]
  );

  let error = form.errors[field.name];

  const handleFocus = useCallback(() => {
    setFocus(true);
  }, []);

  field.onBlur = () => {
    setFocus(false);
  };

  return (
    <>
      <LabelWithTooltip
        label={label}
        fill={!isFocus && (field.value || field.value === Number(0))}
        tooltip={tooltip}
      />
      <Input
        type={type}
        validation={error && "error"}
        message={error}
        value={String(field.value)}
        onChange={handleChange}
        onClearClick={handleClear}
        onFocus={handleFocus}
      />
    </>
  );
}

export default InputNumberField;
