import React from "react";
import styles from "./CaseStatusColumn.module.css";
import classNames from "classnames";
import { CASE_NEW, CASE_OPEN } from "../../helpers/cases";

function CaseStatusColumn({ status }) {
  const classes = classNames(
    (status === CASE_NEW && styles.red) ||
      (status === CASE_OPEN && styles.yellow) ||
      styles.grey,
    styles.status
  );

  return <div className={classes}>{status}</div>;
}

export default CaseStatusColumn;
