import React from "react";
import styles from "./CaseFileAppearance.module.css";
import { renderFilePictureLink } from "helpers/cases";

function CaseFileAppearance({ name, ext, size, path, renderStatus }) {
  return (
    <div className={styles.container}>
      <a
        href={path}
        target="_blank"
        rel="noopener noreferrer"
        className={styles.imageWrap}
      >
        <img src={renderFilePictureLink(ext)} alt={name} />
      </a>
      <a
        href={path}
        target="_blank"
        rel="noopener noreferrer"
        className={styles.info}
      >
        <span className={styles.name}>
          <span className={styles.nameBlock}>{name && name.split(".", 1)}</span>
          .<span className={styles.extBlock}>{ext}</span>
        </span>
        {size > 1024000 && (
          <span className={styles.size}>
            {(size / 1024 / 1024).toFixed(1)} mb
          </span>
        )}
        {size < 1024000 && (
          <span className={styles.size}>{Math.round(size / 1024)} kb</span>
        )}
      </a>
      <span className={styles.status}>{renderStatus}</span>
    </div>
  );
}

export default CaseFileAppearance;
