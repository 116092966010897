import React from "react";
import classNames from "classnames";
import { logoAvatar } from "helpers/logoAvatar";
import { translation_default } from "helpers/translations";
import { connect } from "react-redux";

function WidgetIntroduceContainer({ introduce, showOnlineStatus, isOpen }) {
  const companyName = introduce.company || "Company name";
  const showAgents = String(introduce.showAgents) === "false" ? false : true;
  const defaultLanguage = introduce.defaultLanguage || "en";

  let showOnline = String(showOnlineStatus) === "false" ? false : true;

  function translate(string) {
    return translation_default[string][defaultLanguage];
  }

  if (isOpen) {
    return (
      <div className="chat-wrapper-header">
        <div className="channels-wrapper-header-info">
          <div className="arrow-chat-button">
            <img
              className="arrow-chat-icon"
              src="/images/widget/arrow-chat.svg"
              alt=""
            />
          </div>
          <div
            className={classNames(
              "channels-wrapper-header-info-logo",
              (introduce.logo || introduce.LoadAvatar) && "logo-complete"
            )}
          >
            <img src={logoAvatar(introduce)} alt="" />
          </div>
          <div className="channels-wrapper-header-info-col">
            <div className="channels-wrapper-header-info-title">
              {companyName}
            </div>
            {showOnline && (
              <div className="channels-wrapper-header-info-status channels-status-online">
                {translate("online") || "We are online"}
              </div>
            )}
          </div>
        </div>
        <div className="chat-option">
          <img
            className="arrow-chat-icon"
            src="/images/widget/option.svg"
            alt=""
          />

          <div className="chat-option-dropdown">
            <ul>
              <li>Export the conversation</li>
              <li>Turn off/on sound notifications</li>
            </ul>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="channels-wrapper-header">
      <div className="channels-wrapper-header-info">
        <div
          className={classNames(
            "channels-wrapper-header-info-logo",
            (introduce.logo || introduce.LoadAvatar) && "logo-complete"
          )}
        >
          <img src={logoAvatar(introduce)} alt="" />
        </div>
        <div className="channels-wrapper-header-info-col">
          <div id="companyName" className="channels-wrapper-header-info-title">
            {companyName}
          </div>
          {showOnline && (
            <div className="channels-wrapper-header-info-status channels-status-online">
              {translate("online") || "We are online"}
            </div>
          )}
        </div>
      </div>

      <div
        className={`channels-wrapper-header-avatar ${showAgents &&
          "show-agent"}`}
      >
        <img src="/images/widget/team-avatar.svg" alt="team-avatar" />
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    introduce: state.widgetSetup.current.introduce,
    showOnlineStatus: state.widgetSetup.current.availability.showOnlineStatus
  };
};

export default connect(mapStateToProps)(WidgetIntroduceContainer);
