import React from "react";
import { Field } from "formik";
import { Button, GutterField, Row, Col } from "design-system-react";
import InputField from "components/InputField";
import CheckboxField from "components/CheckboxField";
import { Link } from "react-router-dom";

function LoginFormContainer(props) {
  const isEmptyFields = () => {
    return !props.values.email || !props.values.password;
  };

  const handleSubmit = e => {
    if (!isEmptyFields()) {
      props.handleSubmit(e);
    }

    e.preventDefault();
  };

  return (
    <form onSubmit={handleSubmit}>
      <h1>Sign in</h1>

      <GutterField>
        <Field name="email" type="email" label="Email" component={InputField} />
      </GutterField>
      <GutterField>
        <Field
          name="password"
          type="password"
          label="Password"
          component={InputField}
        />
      </GutterField>
      <Row>
        <Col align="start" verticalAlign="center" gutterBottom={24}>
          <Field
            name="rememberMe"
            labelText="Remember me"
            isChecked={props.values.rememberMe}
            component={CheckboxField}
          />
        </Col>

        <Col align="end" verticalAlign="center" gutterBottom={24}>
          <Link to="/reset-password">Forgot password?</Link>
        </Col>
      </Row>

      <Row>
        <Col align="start" verticalAlign="center">
          <Button
            type="submit"
            disabled={isEmptyFields()}
            processing={props.isSubmitting}
          >
            Sign in
          </Button>
        </Col>
      </Row>
    </form>
  );
}

export default LoginFormContainer;
