import { CALL_API } from "utils/api";
import merge from "lodash/merge";
import { withNamespace } from "utils/namespace";

const type = withNamespace("extraFields");

// action types
export const FETCH_EXTRA_FIELDS_SUCCESS = type("FETCH_EXTRA_FIELDS_SUCCESS");

let initialState = {
  listIds: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_EXTRA_FIELDS_SUCCESS:
      return merge({}, state, {
        listIds: action.payload.data.map(field => field.id)
      });
    default:
      return state;
  }
}

export function fetchExtraFields() {
  return dispatch => {
    return dispatch({
      [CALL_API]: {
        endpoint: "/extra_fields",
        options: {
          method: "get"
        },
        types: {
          success: FETCH_EXTRA_FIELDS_SUCCESS
        }
      }
    });
  };
}
