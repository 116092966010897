import { withNamespace } from "utils/namespace";

const type = withNamespace("teamMember");

// action types
export const SET_CASE_IDS = type("SET_CASE_IDS");
export const ADD_CASE_IDS = type("ADD_CASE_IDS");
export const ADD_CASE_ID = type("ADD_CASE_ID");
export const REMOVE_CASE_ID = type("REMOVE_CASE_ID");

let initialState = {
  caseIds: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_CASE_IDS:
      return {
        ...state,
        caseIds: action.payload.ids
      };
    case ADD_CASE_IDS:
      return {
        ...state,
        caseIds: [...state.caseIds, ...action.payload.ids]
      };
    case REMOVE_CASE_ID:
      return {
        ...state,
        caseIds: state.caseIds.filter(id => id !== action.payload.id)
      };
    case ADD_CASE_ID:
      if (state.caseIds.indexOf(action.payload.id) !== -1) {
        return state;
      }

      return {
        ...state,
        caseIds: [action.payload.id, ...state.caseIds]
      };
    default:
      return state;
  }
}

// actions
export function setCaseIds(ids) {
  return {
    type: SET_CASE_IDS,
    payload: {
      ids
    }
  };
}

export function addCaseIds(ids) {
  return {
    type: ADD_CASE_IDS,
    payload: {
      ids
    }
  };
}

export function addCaseId(id) {
  return {
    type: ADD_CASE_ID,
    payload: {
      id
    }
  };
}

export function removeCaseId(id) {
  return {
    type: REMOVE_CASE_ID,
    payload: {
      id
    }
  };
}
