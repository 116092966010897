import { useState } from "react";
import EndUsersListContainer from "./EndUsersListContainer";
import EndUsersNavPanel from "./EndUsersNavPanel";
import { Row, Col } from "design-system-react";
import useInfinitePagination from "hooks/useInfinitePagination";
import { api } from "utils/client";

function EndUsersContainer({ view }) {
  const [filter, setFilter] = useState({ filters: [] });
  const [selectedEndUsers, setSelectedEndUsers] = useState([]);
  const [bulkActions, setBulkActions] = useState(false);
  const [visibleBulkUpdateModal, setVisibleBulkUpdateModal] = useState(false);

  const pagination = useInfinitePagination({
    query: api.endUsers.query
  });

  return (
    <Row fullHeight column>
      <Col auto>
        <EndUsersNavPanel
          activeView={view}
          filter={filter}
          onFilter={setFilter}
          selectedEndUsers={selectedEndUsers}
          setVisibleBulkUpdateModal={setVisibleBulkUpdateModal}
          pagination={pagination}
          bulkActions={bulkActions}
          onBulkActionsToggle={enabled => {
            setBulkActions(enabled);

            if (!enabled) {
              setSelectedEndUsers([]);
            }
          }}
        />
      </Col>
      <Col justified>
        <EndUsersListContainer
          view={view}
          filter={filter}
          visibleBulkUpdateModal={visibleBulkUpdateModal}
          setVisibleBulkUpdateModal={setVisibleBulkUpdateModal}
          pagination={pagination}
          bulkActions={bulkActions}
          onBulkActionsToggle={enabled => {
            setBulkActions(enabled);

            if (!enabled) {
              setSelectedEndUsers([]);
            }
          }}
          selectedEndUsers={selectedEndUsers}
          onSelect={ids => {
            setSelectedEndUsers(ids);
          }}
        />
      </Col>
    </Row>
  );
}

export default EndUsersContainer;
