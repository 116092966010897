import { Modal, Form, Button, Select, Row, Col, Typography } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import View from "components/View";

export type entityFieldType = {
  label: string;
  operators: Array<any>;
  renderInput?: (operator: string) => React.ReactElement;
};

export interface FilterModalProps {
  visible?: boolean;
  filter?: any;
  title?: string;
  entityFields: { [key: string]: entityFieldType };
  onFilter: (values: any) => void;
  onCancel: () => void;
}

export const OPERATOR_IS = "is";
export const OPERATOR_IS_NOT = "is_not";
export const OPERATOR_IN = "in";
export const OPERATOR_NULL = "null";
export const OPERATOR_NOT_NULL = "not_null";

export const dropdownOperators = [
  {
    label: "is",
    value: OPERATOR_IS
  },
  {
    label: "is not",
    value: OPERATOR_IS_NOT
  },
  {
    label: "any",
    value: OPERATOR_IN
  },
  {
    label: "is empty",
    value: OPERATOR_NULL
  },
  {
    label: "is not empty",
    value: OPERATOR_NOT_NULL
  }
];

export const nonEmptyDropdownOperators = [
  {
    label: "is",
    value: OPERATOR_IS
  },
  {
    label: "is not",
    value: OPERATOR_IS_NOT
  },
  {
    label: "any",
    value: OPERATOR_IN
  }
];

function FilterModal({
  title,
  visible,
  filter,
  entityFields,
  onFilter,
  onCancel
}: FilterModalProps) {
  const [form] = Form.useForm();

  const handleSubmit = (values: any) => {
    onFilter(values);
  };

  useEffect(() => {
    form.setFieldsValue(filter);
  }, [filter, form]);

  const defaultField = Object.keys(entityFields)[0];
  const defaultOperator = entityFields[defaultField].operators[0].value;

  return (
    <Modal
      title={title}
      visible={visible}
      width={634}
      style={{ maxWidth: "unset" }}
      onCancel={() => {
        form.submit();
        onCancel();
      }}
      footer={
        <Row justify="space-between">
          <Col style={{ height: "32px" }} />
        </Row>
      }
    >
      <Form form={form} onFinish={handleSubmit} autoComplete="off">
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => {
            return getFieldValue("filters").length > 0 ? null : (
              <View>
                <Typography.Text type="secondary">
                  Set filter criteria to display required data.
                </Typography.Text>
              </View>
            );
          }}
        </Form.Item>
        <Form.List name="filters">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Row
                  key={field.key}
                  gutter={[8, 0]}
                  style={{ marginBottom: index === fields.length - 1 ? 0 : 10 }}
                >
                  <Col style={{ width: "80px" }}>
                    {index === 0 ? (
                      <div
                        style={{
                          height: "32px",
                          lineHeight: "32px",
                          textAlign: "right"
                        }}
                      >
                        Where
                      </div>
                    ) : (
                      <div
                        style={{
                          height: "32px",
                          lineHeight: "32px",
                          textAlign: "right"
                        }}
                      >
                        and
                      </div>
                    )}
                  </Col>
                  <Col style={{ width: "168px" }}>
                    <Form.Item
                      name={[field.name, "field"]}
                      fieldKey={[field.fieldKey, "field"]}
                      style={{ marginBottom: 0 }}
                    >
                      <Select
                        options={Object.keys(entityFields).map(
                          (entityField: string) => {
                            return {
                              label: entityFields[entityField].label,
                              value: entityField
                            };
                          }
                        )}
                        onChange={() => {
                          const { filters } = form.getFieldsValue();

                          const operators = entityFields[
                            form.getFieldValue(["filters", field.name, "field"])
                          ].operators.map(o => o.value);

                          const currentOperator = form.getFieldValue([
                            "filters",
                            field.name,
                            "operator"
                          ]);

                          Object.assign(filters[field.name], {
                            operator:
                              operators.indexOf(currentOperator) !== -1
                                ? currentOperator
                                : operators[0],
                            value: undefined
                          });

                          form.setFieldsValue({ filters });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col style={{ width: "130px" }}>
                    <Form.Item noStyle shouldUpdate>
                      {({ getFieldValue }) => {
                        const fieldValue = getFieldValue([
                          "filters",
                          field.name,
                          "field"
                        ]);

                        return (
                          <Form.Item
                            {...field}
                            name={[field.name, "operator"]}
                            fieldKey={[field.fieldKey, "operator"]}
                            style={{ marginBottom: 0 }}
                          >
                            <Select
                              options={
                                fieldValue
                                  ? entityFields[
                                      getFieldValue([
                                        "filters",
                                        field.name,
                                        "field"
                                      ])
                                    ].operators
                                  : []
                              }
                              onChange={() => {
                                const fields = form.getFieldsValue();
                                const { filters } = fields;
                                Object.assign(filters[field.name], {
                                  value: undefined
                                });

                                form.setFieldsValue({ filters });
                              }}
                            />
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                  </Col>
                  <Col style={{ width: "168px" }}>
                    <Form.Item noStyle shouldUpdate>
                      {({ getFieldValue }) => {
                        const fieldValue = getFieldValue([
                          "filters",
                          field.name,
                          "field"
                        ]);

                        const operatorValue = getFieldValue([
                          "filters",
                          field.name,
                          "operator"
                        ]);

                        let input = null;

                        if (fieldValue) {
                          const renderInput =
                            entityFields[fieldValue].renderInput;

                          if (renderInput) {
                            input = renderInput(operatorValue);
                          }
                        }

                        return (
                          <Form.Item
                            {...field}
                            name={[field.name, "value"]}
                            fieldKey={[field.fieldKey, "value"]}
                            style={{ marginBottom: 0 }}
                          >
                            {input}
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                  </Col>
                  <Col flex={0}>
                    <Button
                      icon={<DeleteOutlined />}
                      onClick={() => remove(field.name)}
                    />
                  </Col>
                </Row>
              ))}
              <View marginLeft={"-16px"} position="absolute" bottom="10px">
                <Form.Item noStyle>
                  <Button
                    type="text"
                    style={{ paddingLeft: 6, paddingRight: 6 }}
                    onClick={() => {
                      if (fields.length === 0) {
                        add({ field: defaultField, operator: defaultOperator });
                      } else {
                        const field = form.getFieldValue([
                          "filters",
                          fields[fields.length - 1].name,
                          "field"
                        ]);

                        const operator = form.getFieldValue([
                          "filters",
                          fields[fields.length - 1].name,
                          "operator"
                        ]);

                        const value = form.getFieldValue([
                          "filters",
                          fields[fields.length - 1].name,
                          "value"
                        ]);

                        add({ field: field, operator: operator, value: value });
                      }
                    }}
                    icon={<PlusOutlined />}
                  >
                    Add filter
                  </Button>
                </Form.Item>
              </View>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  );
}

export default FilterModal;
