import { Modal, Form, Button, Select, Tag } from "antd";
import AssignInput from "components/assignInput/AssignInput";
import { api } from "utils/client";
import {
  getEndUserQualificationColor,
  getEndUserQualificationTitle
} from "helpers/endUsers";
import { useEffect } from "react";

export interface BulkUpdateEndUsersModalProps {
  visible?: boolean;
  onOk: (values: any) => void;
  onCancel: () => void;
}

function BulkUpdateEndUsersModal({
  visible,
  onOk,
  onCancel
}: BulkUpdateEndUsersModalProps) {
  const [form] = Form.useForm();

  useEffect(() => {
    if (!visible) {
      form.resetFields();
    }
    // eslint-disable-next-line
  }, [visible]);

  return (
    <Modal
      title="Bulk edit"
      visible={visible}
      footer={[
        <Button
          type="primary"
          form="bulk-update-end-users"
          key="submit"
          htmlType="submit"
        >
          Edit
        </Button>
      ]}
      onCancel={onCancel}
    >
      <Form
        form={form}
        name="bulk-update-end-users"
        layout="vertical"
        onFinish={values => {
          onOk(values);
        }}
      >
        <Form.Item label="Owner" name="owner">
          <AssignInput
            placeholder="Keep existing value"
            assignLabel=""
            width="100%"
            disableTeamSelection
            prependOptions={[
              {
                label: (
                  <div style={{ pointerEvents: "none" }}>
                    Keep existing value
                  </div>
                ),
                value: "keep",
                onSelect: () => {
                  form.setFieldsValue({ owner: undefined });
                }
              },
              {
                label: <div style={{ pointerEvents: "none" }}>None</div>,
                value: "none",
                onSelect: () => {
                  form.setFieldsValue({ owner: "none" });
                }
              }
            ]}
            onTeamsRequest={() => {
              return api.cases.teamsForAssign().ready.then((response: any) => {
                return response.data.data;
              });
            }}
            onTeamMembersRequest={teamId => {
              return api.cases
                .membersForAssignByTeam(teamId)
                .ready.then((response: any) => {
                  return response.data.data;
                });
            }}
          />
        </Form.Item>
        <Form.Item label="Status" name="qualification">
          <Select
            placeholder="Keep existing value"
            onSelect={value => {
              if (!value) {
                form.setFieldsValue({ qualification: undefined });
              }
            }}
          >
            <Select.Option value="">Keep existing value</Select.Option>
            <Select.Option value="lead">
              <Tag color={getEndUserQualificationColor("lead")}>
                {getEndUserQualificationTitle("lead")}
              </Tag>
            </Select.Option>
            <Select.Option value="customer">
              <Tag color={getEndUserQualificationColor("customer")}>
                {getEndUserQualificationTitle("customer")}
              </Tag>
            </Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default BulkUpdateEndUsersModal;
