import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo
} from "react";
import usePrevious from "../hooks/usePrevious";
import {
  Dropdown,
  DropdownToggle,
  DropdownToggleMulti,
  DropdownToggleSelectedMultiItem,
  DropdownMenu,
  DropdownItem
} from "design-system-react";

function MultiSelectField({
  field,
  form,
  label,
  items,
  size,
  disabled,
  placeholder
}) {
  const [isOpen, setIsOpen] = useState(false);
  const itemsContainerEl = useRef(null);
  const prevFieldValue = usePrevious(field.value);

  const selectedItems = useMemo(() => {
    const selectedItems = [];

    field.value.map(value => {
      const item = items.filter(i => i.value === value)[0];

      if (item) {
        selectedItems.push(item);
      }

      return value;
    });

    return selectedItems;
  }, [items, field.value]);

  const nonSelectedItems = useMemo(() => {
    return items.filter(i => field.value.indexOf(i.value) === -1);
  }, [items, field.value]);

  const handleSelect = useCallback(
    value => {
      setIsOpen(false);

      form.setFieldValue(field.name, [...field.value, value]);
    },
    [form, field.name, field.value]
  );

  const toggle = useCallback(() => {
    if (nonSelectedItems.length > 0) {
      setIsOpen(!isOpen);
    }
  }, [isOpen, nonSelectedItems]);

  const handleRemove = useCallback(
    value => {
      const values = field.value.filter(
        selectedValue => selectedValue !== value
      );

      form.setFieldValue(field.name, values);
    },
    [form, field.name, field.value]
  );

  // scroll items to bottom
  useEffect(() => {
    if (prevFieldValue && field.value.length > prevFieldValue.length) {
      if (itemsContainerEl) {
        itemsContainerEl.current.scrollTop =
          itemsContainerEl.current.scrollHeight;
      }
    }
  }, [prevFieldValue, field.value, itemsContainerEl]);

  const error = form.errors[field.name];

  return (
    <Dropdown
      isOpen={isOpen}
      toggle={toggle}
      validation={error && "error"}
      validationMessage={error}
      nonEmpty={selectedItems.length > 0}
      size={size}
      disabled={disabled}
      multi
    >
      <DropdownToggle>
        <DropdownToggleMulti
          label={label}
          hideInput={nonSelectedItems.length === 0 && selectedItems.length > 0}
          placeholder={placeholder}
          itemsContainerRef={itemsContainerEl}
        >
          {selectedItems.map(selectedItem => {
            return (
              <DropdownToggleSelectedMultiItem
                key={selectedItem.value}
                onRemove={e => {
                  handleRemove(selectedItem.value);
                  e.stopPropagation();
                }}
                validation={error && "error"}
              >
                {selectedItem.label}
              </DropdownToggleSelectedMultiItem>
            );
          })}
        </DropdownToggleMulti>
      </DropdownToggle>
      <DropdownMenu>
        {nonSelectedItems.map(item => {
          return (
            <DropdownItem
              key={item.value}
              onClick={e => {
                handleSelect(item.value);
              }}
              selected={false}
            >
              {item.label}
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </Dropdown>
  );
}

export default MultiSelectField;
