import React, { useEffect } from "react";
import SettingsSubMenu from "components/settings/SettingsSubMenu";
import SettingsTyntecWhatsappContainer from "./containers/SettingsTyntecWhatsappContainer";
import SettingsLayout from "components/SettingsLayout";

function SettingsTelegram() {
  useEffect(() => {
    document.title = "WhatsApp settings";
    localStorage.setItem("lastSettingRoute", "/settings/whatsapp-business");
  }, []);

  return (
    <SettingsLayout
      renderSubmenu={() => (
        <SettingsSubMenu activeMenuItem={"/settings/whatsapp-business"} />
      )}
      renderContent={() => (
        <SettingsTyntecWhatsappContainer />
      )}
    />
  );
}

export default SettingsTelegram;
