import classNames from "classnames";
import styles from "./Table.module.scss";

interface TableRowProps extends React.HTMLAttributes<HTMLElement> {
  statusColor?: string | undefined;
  active?: boolean;
  disabled?: boolean;
  selected?: boolean;
  children?: React.ReactNode;
}

function TableRow(props: TableRowProps) {
  const {
    statusColor,
    children,
    active,
    disabled,
    selected,
    ...attributes
  } = props;
  const classes = classNames(
    styles.tableRow,
    active && styles.active,
    selected && styles.selected,
    disabled && styles.disabled
  );

  return (
    <div className={classes} {...attributes}>
      {children}
      {statusColor && (
        <div className={styles.status} style={{ background: statusColor }} />
      )}
    </div>
  );
}

export default TableRow;
