import React, { useEffect, useState, useCallback } from "react";
import connect from "react-redux/es/connect/connect";
import { Popup } from "design-system-react";
import { api, dispatchUpdatedDataToStore } from "utils/client";
import { setChannelIds, setEnabledTeamIds } from "modules/newCase";
import { CASE_OPEN, fetchCase } from "modules/pages/cases";
import { setCaseUnread } from "modules/cases";
import { CommonError } from "utils/commonError";
import history from "utils/history";
import EndUserCasePopupBody from "../pages/EndUser/components/EndUserCasePopupBody";
import {
  subscribe,
  unsubscribe,
  WS_EVENT_MESSAGE_CONTENT_UPDATED,
  WS_EVENT_MESSAGE_CREATED
} from "utils/websocket";
import { addMessageId } from "modules/pages/newcases";
import { addCaseId, removeCaseId } from "modules/pages/endUser";
import { updateEntityAttributes, updateEntityRelations } from "modules/data";
import Case from "../pages/Cases/components/Case";
import usePrevious from "../hooks/usePrevious";
import uuidv4 from "../utils/uuidv4";
import axios from "axios";
import { message, messageContent } from "../schemes";

function CaseModalContainer({
  caseId,
  status,
  channelType,
  addCaseId,
  removeCaseId,
  addMessageId,
  updateEntityRelations,
  updateEntityAttributes,
  showMessageForm,
  setCaseUnread,
  onCloseCase
}) {
  const [loading, setLoading] = useState(null);
  const [assignLoading, setAssignLoading] = useState(false);
  const prevCaseId = usePrevious(caseId);

  const handleCaseCloseClick = useCallback(() => {
    api.cases.close(uuidv4(), caseId);
  }, [caseId]);

  const handleAssign = useCallback(
    (caseId, teamId, teamMemberId) => {
      setAssignLoading(true);

      api.cases
        .assign(uuidv4(), caseId, teamId, teamMemberId)
        .ready.then(response => {
          if (caseId) {
            addMessageId(response.data.data.id);
          }
        })
        .finally(() => {
          setAssignLoading(false);
        });
    },
    [addMessageId]
  );

  const handleUnassign = useCallback(
    caseId => {
      setAssignLoading(true);

      api.cases
        .unassign(uuidv4(), caseId)
        .ready.then(response => {
          addMessageId(response.data.data.id);
        })
        .finally(() => {
          setAssignLoading(false);
        });
    },
    [addMessageId]
  );

  useEffect(() => {
    if (caseId) {
      api.cases.read(caseId);

      setCaseUnread(caseId, false);
    }
  }, [caseId, setCaseUnread]);

  useEffect(() => {
    if (caseId) {
      const request = api.cases.one(caseId);
      setLoading(true);

      request.ready
        .then(() => {
          setLoading(false);
        })
        .catch(e => {
          if (e instanceof CommonError) {
            history.push("/cases/unassigned");
          }

          if (!axios.isCancel(e)) {
            setLoading(false);
          }
        });

      return () => {
        request.cancelRequest.cancel();
      };
    }
  }, [caseId]);

  // useEffect(() => {
  //   if (caseId) {
  //     api.cases.read(activeCaseItem.id);
  //     setCaseUnread(caseId, false);
  //   }
  // }, [caseId]);

  // useEffect(() => {
  //   if (caseId) {
  //     const request = api.cases.one(caseId);
  //
  //     request.ready.catch(e => {
  //       if (e instanceof CommonError) {
  //         history.push('/cases/unassigned');
  //       }
  //     });
  //
  //     return () => {
  //       request.cancelRequest.cancel();
  //     };
  //   }
  // }, [caseId]);

  const handleWebsocketMessage = useCallback(
    data => {
      data.cases.lastMessage = {
        id: data.id
      };

      dispatchUpdatedDataToStore(data, message);

      if (caseId === data.cases.id) {
        addMessageId(data.id);
      }
    },
    [caseId, addMessageId]
  );

  const handleWebsocketMessageContentUpdate = useCallback(data => {
    dispatchUpdatedDataToStore(data, messageContent);
  }, []);

  useEffect(() => {
    subscribe(WS_EVENT_MESSAGE_CREATED, handleWebsocketMessage);
    subscribe(
      WS_EVENT_MESSAGE_CONTENT_UPDATED,
      handleWebsocketMessageContentUpdate
    );

    return () => {
      unsubscribe(WS_EVENT_MESSAGE_CREATED, handleWebsocketMessage);
      unsubscribe(
        WS_EVENT_MESSAGE_CONTENT_UPDATED,
        handleWebsocketMessageContentUpdate
      );
    };
  });

  return (
    <Popup
      isOpen={caseId}
      width={800}
      noPadding
      noButtonClose
      onClose={onCloseCase}
    >
      <EndUserCasePopupBody>
        <Case
          caseId={caseId}
          caseLoading={prevCaseId !== caseId || loading}
          assignLoading={assignLoading}
          status={status}
          channelType={channelType}
          showMessageForm={showMessageForm}
          onAssign={handleAssign}
          onUnassign={handleUnassign}
          onClose={handleCaseCloseClick}
          onClosePanel={onCloseCase}
          hideDetails
        />
      </EndUserCasePopupBody>
    </Popup>
  );

  // return (
  //     <Popup
  //         isOpen={caseId}
  //         width={800}
  //         noPadding
  //         noButtonClose
  //         onClose={onCloseCase}
  //     >
  //       <EndUserCasePopupBody>
  //         <Row column fullHeight>
  //           <Col auto>
  //             {caseId && (
  //                 <CaseNavPanelContainer
  //                     caseId={caseId}
  //                     activeViewName="all"
  //                     onCloseClick={onCloseCase}
  //                 />
  //             )}
  //           </Col>
  //           <Col justified>
  //             {activeCaseItem && (
  //                 <CaseLayout
  //                     renderMessages={
  //                       <DropFileContainer>
  //                         <Row fullHeight column>
  //                           <Col justified>
  //                             {caseId && (
  //                                 <MessageListContainer
  //                                     caseId={caseId}
  //                                     activeCaseAid={activeCaseItem.aid}
  //                                     channelType={
  //                                       activeCaseItem.conversation.channel.name
  //                                     }
  //                                 />
  //                             )}
  //                           </Col>
  //                           {activeCaseItem.status === CASE_NEW && (
  //                               <Col auto>
  //                                 <MessageListReplyingMessage/>
  //                               </Col>
  //                           )}
  //                           {showMessageForm && (
  //                               <Col auto>
  //                                 <MessageFormContainer
  //                                     isEndUserModal
  //                                     caseId={caseId}
  //                                 />
  //                               </Col>
  //                           )}
  //                         </Row>
  //                       </DropFileContainer>
  //                     }
  //                 />
  //             )}
  //           </Col>
  //         </Row>
  //       </EndUserCasePopupBody>
  //     </Popup>
  // );
}

const mapStateToProps = (state, props) => {
  const caseItem = state.entities.cases && state.entities.cases[props.caseId];
  const conversation =
    caseItem && state.entities.conversations[caseItem.conversation];
  const channel = conversation && state.entities.channels[conversation.channel];

  let showMessageForm = false;

  if (caseItem && caseItem.status === CASE_OPEN) {
    if (caseItem.assignedTeamMember === state.auth.userId) {
      showMessageForm = true;
    }
  }
  //
  // const activeCaseItem = state.entities.cases[props.caseId];
  // let showMessageForm = false;
  //
  // if (activeCaseItem && activeCaseItem.status === CASE_OPEN) {
  //   if (
  //       activeCaseItem.assignedTeamMember &&
  //       activeCaseItem.assignedTeamMember.id === state.auth.userId
  //   ) {
  //     showMessageForm = true;
  //   }
  // }

  return {
    showMessageForm,
    status: caseItem && caseItem.status,
    channelType: channel && channel.type

    // endUser: build(state.data, 'endUsers', props.endUserId),
    // teams: build(state.data, 'teams', state.newCase.enabledTeamIds) || [],
    // channels: build(state.data, 'channels', state.newCase.channelIds) || [],
  };
};

const mapDispatchToProps = dispatch => ({
  setEnabledTeamIds: ids => dispatch(setEnabledTeamIds(ids)),
  setChannelIds: ids => dispatch(setChannelIds(ids)),
  fetchCase: (caseId, params) => dispatch(fetchCase(caseId, params)),
  addCaseId: id => dispatch(addCaseId(id)),
  removeCaseId: id => dispatch(removeCaseId(id)),
  addMessageId: id => dispatch(addMessageId(id)),
  updateEntityAttributes: (entityType, entityId, attributes) =>
    dispatch(updateEntityAttributes(entityType, entityId, attributes)),
  updateEntityRelations: (
    entityType,
    entityId,
    relationshipName,
    relationshipType,
    relationshipEntityId
  ) =>
    dispatch(
      updateEntityRelations(
        entityType,
        entityId,
        relationshipName,
        relationshipType,
        relationshipEntityId
      )
    ),
  setCaseUnread: (caseId, value) => dispatch(setCaseUnread(caseId, value))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CaseModalContainer);
