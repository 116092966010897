import React, { useEffect } from "react";
import HomePageLayout from "../../components/HomePageLayout";
import HomePageContainer from "./containers/HomePageContainer";
import { Redirect } from "react-router-dom";
import { can, CASES_ANALYTICS_PERMISSION } from "../../utils/permissions";

function HomePage() {
  useEffect(() => {
    document.title = "Home";
  }, []);

  if (can(!CASES_ANALYTICS_PERMISSION)) {
    return <Redirect to={"/cases"} />;
  }

  return <HomePageLayout renderContent={<HomePageContainer />} />;
}

export default HomePage;
