export const QUALIFICATION_CUSTOMER = "customer";
export const QUALIFICATION_LEAD = "lead";
export const QUALIFICATION_VISITOR = "visitor";

export function printQualification(qualification) {
  switch (qualification) {
    case QUALIFICATION_CUSTOMER:
      return "Customer";
    case QUALIFICATION_LEAD:
      return "Lead";
    case QUALIFICATION_VISITOR:
      return "Visitor";
    default:
      return "";
  }
}
