import React from "react";
import { connect } from "react-redux";
import { TableRow, TableColumn, TableColumnMenu } from "design-system-react";
import DateTime from "components/DateTime";
import EllipsisText from "../../EndUsers/components/EllipsisText";

function SettingsQuickRepliesListItem({
  quickReplyId,
  quickReply,
  removeQuickReplyId,
  handleActionDropdown
}) {
  return (
    <TableRow key={quickReply.id} size="48px">
      <TableColumn width={200} fixed>
        <EllipsisText>{quickReply.title}</EllipsisText>
      </TableColumn>
      <TableColumn justified>
        <EllipsisText>{quickReply.text}</EllipsisText>
      </TableColumn>
      <TableColumn>
        <DateTime time={quickReply.updatedAt} />
      </TableColumn>
      <TableColumnMenu
        items={[
          { key: "edit", label: "Edit" },
          { key: "delete", label: "Delete" }
        ]}
        onItemClick={key => {
          handleActionDropdown(
            quickReply.id,
            quickReply.title,
            quickReply.text,
            key
          );
        }}
      />
    </TableRow>
  );
}

const mapStateToProps = (state, props) => {
  return {
    quickReply: state.entities.quickReplies[props.quickReplyId]
  };
};

export default connect(mapStateToProps)(SettingsQuickRepliesListItem);
