import React from "react";
import { Field } from "formik";
import {
  Row,
  Col,
  Table,
  TableHeader,
  GutterField,
  TableBody,
  TableRow,
  TableColumn
} from "design-system-react";
import SwitcherField from "components/SwitcherField";
import InputField from "../../../components/InputField";
import View from "../../../components/View";

function AutoResponseSettingsFormContainer({
  submitDisabled,
  setSubmitDisabled,
  handleSubmit,
  submitForm
}) {
  return (
    <form onSubmit={handleSubmit}>
      <GutterField>
        <Table headerBorder noHover withoutPadding>
          <TableHeader />
          <TableBody>
            <TableRow size="64px">
              <TableColumn justified>
                <View color="#646566">
                  Set the minimum auto-response interval before a customer
                  receive an auto-response message again
                </View>
              </TableColumn>
              <TableColumn width={218} noPadding>
                <Row verticalAlign="center">
                  <View color="#646566">hh</View>
                  <Col gutterLeft={6} gutterRight={10}>
                    <GutterField gutterBottom={0}>
                      <Field
                        name="intervalHours"
                        type="number"
                        max={1000}
                        placeholder=""
                        component={InputField}
                        onBlur={submitForm}
                      />
                    </GutterField>
                  </Col>
                  <View color="#646566">mm</View>
                  <Col gutterLeft={6}>
                    <GutterField gutterBottom={0}>
                      <Field
                        name="intervalMinutes"
                        type="number"
                        max={60}
                        placeholder=""
                        component={InputField}
                        onBlur={submitForm}
                      />
                    </GutterField>
                  </Col>
                </Row>
              </TableColumn>
            </TableRow>
            <TableRow size="64px">
              <TableColumn justified>
                <View color="#646566">
                  Auto-response availability preferences
                </View>
              </TableColumn>
              <TableColumn isSwitcher>
                <Field
                  name="enabled"
                  // size="sm"
                  component={SwitcherField}
                  onValueChange={submitForm}
                />
              </TableColumn>
            </TableRow>
          </TableBody>
        </Table>
      </GutterField>
    </form>
  );
}

export default AutoResponseSettingsFormContainer;
