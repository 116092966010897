import React from "react";
import MessageListItemBubble from "pages/Cases/components/MessageListItemBubble";
import MessageListItemTime from "pages/Cases/components/MessageListItemTime";
import MessageListItemStatus from "pages/Cases/components/MessageListItemStatus";
import MessageListItemBubbleInfo from "pages/Cases/components/MessageListItemBubbleInfo";
import CaseReformattedText from "../../pages/Cases/components/CaseReformattedText";

function DefaultMessageBubble({ text, status, outgoing, time, note }) {
  const textRows = text.split("\n") || [];

  return (
    <MessageListItemBubble
      note={note}
      status={status}
      dark={outgoing}
      time={time}
    >
      {textRows.map((text, index) => (
        <React.Fragment key={index}>
          <CaseReformattedText>{text}</CaseReformattedText>
          {textRows.length - 1 !== index && <br />}
        </React.Fragment>
      ))}
      <MessageListItemBubbleInfo>
        <MessageListItemTime time={time} />
        {outgoing && <MessageListItemStatus status={status} />}
      </MessageListItemBubbleInfo>
    </MessageListItemBubble>
  );
}

export default DefaultMessageBubble;
