import React from "react";
import { Avatar } from "design-system-react";
import styles from "./MessageListItemBubbleContact.module.css";
import { extractFirstLetters } from "utils/string";

function MessageListItemBubbleContact({
  avatar,
  // link,
  // channelName,
  name,
  phone,
  renderStatus
}) {
  return (
    <div className={styles.container}>
      <div className={styles.flex}>
        <div className={styles.avatar}>
          <Avatar
            size="size40"
            text={extractFirstLetters(name, 2)}
            image={avatar}
          />
        </div>
        <div className={styles.info}>
          <span className={styles.name}>{name}</span>
          <span className={styles.phone}>{phone}</span>
        </div>
        <span className={styles.status}>{renderStatus}</span>
      </div>
    </div>
  );
}

export default MessageListItemBubbleContact;
