import React, { PureComponent } from "react";
import Moment from "react-moment";
// import "moment/locale/ru";
import { niceDate } from "utils/dateTime";
import {
  TYPE_NOW,
  TYPE_AGO,
  TYPE_FULL_YEAR,
  TYPE_FULL_WORDS,
  TYPE_FULL,
  TYPE_TIME,
  TYPE_FULL_ABSOLUTELY,
  TYPE_DAY,
  TYPE_FULL_AT
} from "utils/dateTime";

export class DateTime extends PureComponent {
  render() {
    const currentTime = Number(new Date().getTime() / 1000);

    const { time, forceType } = this.props;
    let type = null;
    const locale = "en";

    let result = (
      <Moment locale={locale} unix format="MMM D, HH:mm">
        {time}
      </Moment>
    );

    if (forceType !== undefined) {
      type = forceType;
    } else {
      type = niceDate(time);
    }

    switch (type) {
      case TYPE_NOW:
        result = "Now";
        break;

      case TYPE_AGO:
        result = (
          <Moment locale={locale} unix format="m [min ago]">
            {currentTime - time}
          </Moment>
        );
        break;

      case TYPE_FULL:
        result = (
          <Moment locale={locale} unix format="MMM D, HH:mm">
            {time}
          </Moment>
        );
        break;

      case TYPE_FULL_AT:
        result = (
          <Moment locale={locale} unix format="MMM D [at] HH:mm">
            {time}
          </Moment>
        );
        break;

      case TYPE_FULL_WORDS:
        result = (
          <Moment locale={locale} unix format="[on] MMM D [at] HH:mm">
            {time}
          </Moment>
        );
        break;

      case TYPE_FULL_YEAR:
        result = (
          <Moment locale={locale} unix format="YYYY, MMM D, HH:mm">
            {time}
          </Moment>
        );
        break;

      case TYPE_TIME:
        result = (
          <Moment locale={locale} unix format="HH:mm">
            {time}
          </Moment>
        );
        break;

      case TYPE_FULL_ABSOLUTELY:
        result = (
          <Moment locale={locale} unix format="MMM D, YYYY [at] HH:mm">
            {time}
          </Moment>
        );
        break;

      case TYPE_DAY:
        result = (
          <Moment locale={locale} unix format="MMMM D">
            {time}
          </Moment>
        );
        break;

      default:
        break;
    }

    return result;
  }
}

export default DateTime;
