import React, { useCallback } from "react";
import { connect } from "react-redux";
import AppLayout from "../layouts/AppLayout";
import { Redirect, Route } from "react-router-dom";

const AppLayoutRoute = ({ component: Component, ...props }) => {
  const render = useCallback(
    matchProps => (
      <AppLayout {...props.layoutProps} match={matchProps.match}>
        <Component {...matchProps} />
      </AppLayout>
    ),
    [props.layoutProps]
  );

  if (!props.userId) {
    return <Redirect to="/login" />;
  }

  return <Route {...props} render={render} />;
};

const mapStateToProps = state => {
  return { userId: state.auth.userId };
};

export default connect(mapStateToProps)(AppLayoutRoute);
