import { CALL_API, client as api } from "utils/api";
import { withNamespace } from "utils/namespace";
import merge from "lodash/merge";
import { setPermissions } from "utils/permissions";

const type = withNamespace("pages/login");

export const AUTH_LOGIN_REQUEST = type("AUTH_LOGIN_REQUEST");
export const AUTH_LOGIN_SUCCESS = type("AUTH_LOGIN_SUCCESS");
export const AUTH_LOGIN_FAILURE = type("AUTH_LOGIN_FAILURE");
export const AUTH_LOGOUT = type("AUTH_LOGOUT");

let token = localStorage.getItem("token") || sessionStorage.getItem("token");
let userId = localStorage.getItem("userId") || sessionStorage.getItem("userId");

let initialState = {
  isLoggedIn: !!token && userId,
  userId: userId ? Number(userId) : null
};

if (token) {
  // api.defaults.headers.common["X-AUTH-TOKEN"] = token;
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case AUTH_LOGIN_REQUEST:
      return merge({}, state, {
        isLoggedIn: false
      });
    case AUTH_LOGIN_SUCCESS:
      return merge({}, state, {
        isLoggedIn: true,
        userId: action.payload.userId
      });
    case AUTH_LOGIN_FAILURE:
      return merge({}, state, {
        isLoggedIn: false
      });
    case AUTH_LOGOUT:
      return merge({}, state, {
        isLoggedIn: false
      });
    default:
      return state;
  }
}

// actions
export function login(params, form) {
  return dispatch => {
    const actionResponse = dispatch({
      [CALL_API]: {
        endpoint: "/team-members/sign-in",
        options: {
          method: "post",
          data: params
        },
        types: {
          request: AUTH_LOGIN_REQUEST,
          failure: AUTH_LOGIN_FAILURE
        },
        form
      }
    });

    actionResponse.ready.then(action => {
      if (action.success) {
        const users = action.response.users;
        const user = users[Object.keys(users)[0]];

        if (params.rememberMe) {
          localStorage.setItem("token", user.attributes.token);
          localStorage.setItem("userId", user.id);
        } else {
          sessionStorage.setItem("token", user.attributes.token);
          sessionStorage.setItem("userId", user.id);
        }

        // api.defaults.headers.common["X-AUTH-TOKEN"] = user.attributes.token;

        dispatch({
          type: AUTH_LOGIN_SUCCESS,
          payload: {
            userId: user.id
          }
        });
      }
    });

    return actionResponse;
  };
}

export function logout() {
  return dispatch => {
    localStorage.clear();
    sessionStorage.clear();

    // api.defaults.headers.common["X-AUTH-TOKEN"] = "";

    dispatch({ type: AUTH_LOGOUT });
  };
}

export function fetchUserPermissions() {
  return api({
    method: "get",
    url: "/permissions"
  }).then(
    response => {
      const permissions = response.data.data.map(permission => {
        return {
          id: permission.id,
          title: permission.attributes.title,
          category: permission.attributes.category
        };
      });

      setPermissions(userId, permissions);
    },
    errorResponse => {}
  );
}
