import React from "react";
import PropTypes from "prop-types";
import styles from "./SettingsProfileInformation.module.css";
import {Input, InputTextButton} from "design-system-react";
import {Field} from "formik";
import PhoneField from "components/PhoneField";

function SettingsProfileInformation({pin, pinConfirmed, email, onPinConfirm}) {
  return (
    <div className={styles.container}>
      <div className={styles.emptyCol}/>
      <div className={styles.fields}>
        {!!pin && (
          <div className={styles.field}>
            <Input
              name="pin"
              type="text"
              label="Your personal PIN"
              readOnly
              value={pin}
              appendAfter={
                pinConfirmed ? <div className={styles.confirmed}>Confirmed</div>
                  : onPinConfirm ? <InputTextButton label="Confirm" onClick={onPinConfirm}/>
                  : " "
              }
            />
          </div>
        )}
        <div className={styles.field}>
          <Input
            name="email"
            type="email"
            label="Email"
            appendAfter=" "
            readOnly
            value={email}
          />
        </div>
        <div className={styles.field}>
          <Field
            name="phoneNumber"
            label="Phone number"
            component={PhoneField}
          />
        </div>
      </div>
    </div>
  );
}

SettingsProfileInformation.propTypes = {
  email: PropTypes.string,
  pin: PropTypes.string,
  onPinConfirm: PropTypes.func,
};

export default SettingsProfileInformation;
