import React, { useEffect } from "react";
import SettingsSubMenu from "components/settings/SettingsSubMenu";
import SettingsTelegramContainer from "./containers/SettingsTelegramContainer";
import SettingsLayout from "components/SettingsLayout";

function SettingsTelegram() {
  useEffect(() => {
    document.title = "Telegram settings";
    localStorage.setItem("lastSettingRoute", "/settings/telegram");
  }, []);

  return (
    <SettingsLayout
      renderSubmenu={() => (
        <SettingsSubMenu activeMenuItem={"/settings/telegram"} />
      )}
      renderContent={() => (
        <SettingsTelegramContainer />
      )}
    />
  );
}

export default SettingsTelegram;
