import React from "react";
import styles from "./ContactCheckbox.module.css";

function ContactCheckbox({ name, label, handleDelete }) {
  const icon = () => {
    switch (name) {
      case "name":
        return "/images/widget/user-icon.svg";
      case "phone":
        return "/images/widget/phone-icon.svg";
      case "email":
        return "/images/widget/email-icon.svg";
      default:
        return "/images/widget/user-icon.svg";
    }
  };

  return (
    <label className={styles.check}>
      <img src={icon()} alt="icon" className={styles.icon} />
      <span className={styles.title}>{label}</span>
      <span className={styles.indicate} onClick={() => handleDelete(name)}>
        <i className="icon-close" />
      </span>
    </label>
  );
}

export default ContactCheckbox;
