import React from "react";
import WidgetHeaderTitle from "components/Widget/WidgetHeaderTitle";
import WidgetHeaderDescription from "components/Widget/WidgetHeaderDescription";
import WidgetHeader from "components/Widget/WidgetHeader";
import WidgetChannelsContainer from "./containers/WidgetChannelsContainer";

function WidgetAvailability() {
  return (
    <>
      <WidgetHeader>
        <WidgetHeaderTitle>Set up channels</WidgetHeaderTitle>
        <WidgetHeaderDescription>
          Add messaging channels via which your customers can contact you to the
          widget
        </WidgetHeaderDescription>
      </WidgetHeader>
      <WidgetChannelsContainer />
    </>
  );
}

export default WidgetAvailability;
