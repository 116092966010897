const popup = (url, title, w, h) => {
  let left = window.screen.width / 2 - w / 2;
  let top = window.screen.height / 2 - h / 2;

  return window.open(
    url,
    title,
    "width=" + w + ", height=" + h + ", top=" + top + ", left=" + left
  );
};

export default popup;
