import React, { useState, useCallback, useEffect } from "react";
import NavPanelContent from "components/NavPanel/NavPanelContent";
import NavPanel from "components/NavPanel";
import CollapseButton from "components/NavPanel/CollapseButton";
import { Button, GroupButtons, DatePickerRange } from "design-system-react";
import DatePickerWrapper from "../components/DatePickerWrapper";
import history from "utils/history";
import { makeAnalyticUrl } from "../../../helpers/analytics";
import format from "date-fns/format";
import CollapseMenu from "../../../components/CollapseMenu";

function AnalyticsNavPanelContainer({
  onSubMenuToggle,
  isSubmenuOpened,
  onPeriodChange,
  loading,
  urlParams
}) {
  const groupButtons = [
    { title: "Day", value: "day" },
    { title: "Week", value: "week" },
    { title: "Month", value: "month" }
  ];

  const [periodFrom, setPeriodFrom] = useState(
    urlParams.periodFrom && new Date(urlParams.periodFrom)
  );
  const [periodTo, setPeriodTo] = useState(
    urlParams.periodTo && new Date(urlParams.periodTo)
  );

  const [selectedPeriod, setSelectedPeriod] = useState(
    urlParams.period || "day"
  );

  const handleChangePeriod = useCallback(
    groupButton => {
      setSelectedPeriod(groupButton.value);
      setPeriodFrom("");
      setPeriodTo("");

      history.push(
        makeAnalyticUrl("/analytics/cases", {
          ...urlParams,
          period: groupButton.value,
          periodFrom: "",
          periodTo: ""
        })
      );
    },
    [urlParams]
  );

  const selectedItem = groupButtons.filter(i => i.value === selectedPeriod)[0];

  const handleChangeStartDate = useCallback(
    date => {
      setPeriodFrom(date);
      if (!periodTo && !date) {
        setSelectedPeriod("day");

        history.push(
          makeAnalyticUrl("/analytics/cases", {
            ...urlParams,
            period: "day",
            periodFrom: "",
            periodTo: ""
          })
        );
      }
    },
    [periodTo, urlParams]
  );

  const handleChangeEndDate = useCallback(
    date => {
      setPeriodTo(date);
      if (!date && !periodFrom) {
        setSelectedPeriod("day");

        history.push(
          makeAnalyticUrl("/analytics/cases", {
            ...urlParams,
            period: "day",
            periodFrom: "",
            periodTo: ""
          })
        );
      }
    },
    [periodFrom, urlParams]
  );

  useEffect(() => {
    if (periodFrom && periodTo && periodFrom <= periodTo) {
      setSelectedPeriod("");
      history.push(
        makeAnalyticUrl("/analytics/cases", {
          ...urlParams,
          period: "",
          periodFrom: format(periodFrom, "yyyy-MM-dd"),
          periodTo: format(periodTo, "yyyy-MM-dd")
        })
      );
    }
  }, [periodTo, periodFrom, urlParams]);

  return (
    <NavPanel>
      <NavPanelContent>
        <CollapseButton
          handleClick={onSubMenuToggle}
          isOpen={isSubmenuOpened}
        />
        <CollapseMenu
          title={"Cases"}
          withoutDropdown={isSubmenuOpened}
          items={[
            {
              id: "cases",
              to: "/analytics/cases",
              active: true,
              title: "Cases"
            },
            {
              id: "teamMembers",
              to: "/analytics/team-members",
              active: false,
              title: "Team members"
            },
            {
              id: "teams",
              to: "/analytics/teams",
              active: false,
              title: "Teams"
            }
          ]}
        />
      </NavPanelContent>
      <NavPanelContent>
        <GroupButtons>
          {groupButtons.map(groupButton => (
            <Button
              size="sm"
              group={true}
              onClick={() => {
                handleChangePeriod(groupButton);
              }}
              active={selectedItem && selectedItem.value === groupButton.value}
            >
              {groupButton.title}
            </Button>
          ))}
        </GroupButtons>

        <DatePickerWrapper>
          <DatePickerRange
            now={true}
            size="sm"
            placeholderFrom="Start date"
            placeholderTo="End date"
            noLabel={true}
            startDate={periodFrom}
            endDate={periodTo}
            onChangeStartDate={handleChangeStartDate}
            onChangeEndDate={handleChangeEndDate}
          />
        </DatePickerWrapper>
      </NavPanelContent>
    </NavPanel>
  );
}

export default AnalyticsNavPanelContainer;
