import { withNamespace } from "utils/namespace";

const type = withNamespace("widgetSetup");

// action types
export const SET_SETUP = type("SET_SETUP");
export const SET_INTRODUCE = type("SET_INTRODUCE");
export const SET_STYLE = type("SET_STYLE");
export const SET_WELCOME = type("SET_WELCOME");
export const SET_AVAILABILITY = type("SET_AVAILABILITY");
export const SET_CHANNELS = type("SET_CHANNELS");
export const SET_QUALIFY = type("SET_QUALIFY");

let initialState = {
  current: {
    introduce: [],
    style: [],
    welcome: [],
    channels: [],
    qualify: [],
    availability: [],
    translations: []
  }
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_SETUP:
      return {
        ...state,
        current: action.payload.setup
      };
    case SET_INTRODUCE:
      return {
        ...state,
        current: {
          ...state.current,
          introduce: action.payload.values
        }
      };
    case SET_STYLE:
      return {
        ...state,
        current: {
          ...state.current,
          style: action.payload.values
        }
      };
    case SET_WELCOME:
      return {
        ...state,
        current: {
          ...state.current,
          welcome: action.payload.values
        }
      };
    case SET_AVAILABILITY:
      return {
        ...state,
        current: {
          ...state.current,
          availability: action.payload.values
        }
      };
    case SET_CHANNELS:
      return {
        ...state,
        current: {
          ...state.current,
          channels: action.payload.values
        }
      };
    case SET_QUALIFY:
      return {
        ...state,
        current: {
          ...state.current,
          qualify: action.payload.values
        }
      };
    default:
      return state;
  }
}

export function setSetup(setup) {
  return {
    type: SET_SETUP,
    payload: {
      setup
    }
  };
}

export function setIntroduce(values) {
  return {
    type: SET_INTRODUCE,
    payload: {
      values
    }
  };
}

export function setStyle(values) {
  return {
    type: SET_STYLE,
    payload: {
      values
    }
  };
}

export function setWelcome(values) {
  return {
    type: SET_WELCOME,
    payload: {
      values
    }
  };
}

export function setAvailability(values) {
  return {
    type: SET_AVAILABILITY,
    payload: {
      values
    }
  };
}

export function setChannels(values) {
  return {
    type: SET_CHANNELS,
    payload: {
      values
    }
  };
}

export function setQualify(values) {
  return {
    type: SET_QUALIFY,
    payload: {
      values
    }
  };
}
