import React, { useMemo, useCallback, useEffect } from "react";
import { Formik } from "formik";
import { Button } from "design-system-react";
import { setSetup, setStyle } from "modules/widgetSetup";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import WidgetStyleFormContainer from "./WidgetStyleFormContainer";
import { STEP_WELCOME } from "helpers/steps";
import useStepPage from "hooks/useStepPage";
import { api } from "utils/widgetClient";
import { ALERT_SUCCESS, showAlert } from "utils/alertManager";
import { FormError } from "utils/formError";
import WidgetFormLayout from "components/Widget/WidgetFormLayout";
import WidgetFooter from "components/Widget/WidgetFooter";

let noMutableInitial = {};

function WidgetStyleContainer({ setup, setSetup, setStyle }) {
  const page = useStepPage();

  const initialValues = useMemo(() => {
    return {
      brandColor: setup.style.brandColor || "#d4d5d9",
      highlightColor: setup.style.highlightColor || "White",
      position: setup.style.position || "Right",
      sideSpacing: setup.style.sideSpacing || 0,
      bottomSpacing: setup.style.bottomSpacing || 0,
      includeCopyrights: true
    };
  }, [setup.style]);

  useEffect(() => {
    document.title = "Style the widget";

    noMutableInitial = initialValues;

    return () => {
      setStyle(noMutableInitial);
    };
    // eslint-disable-next-line
  }, [setStyle]);

  const handleSubmitForm = useCallback(
    (values, { setErrors, resetForm }) => {
      const reformValues = {
        ...values,
        sideSpacing: Number(values.sideSpacing),
        bottomSpacing: Number(values.bottomSpacing)
      };

      if (!page.loading) {
        page.setLoading(true);

        api.widget
          .updateStyle(setup.id, reformValues)
          .ready.then(response => {
            noMutableInitial = response.data.data.style;
            setSetup(response.data.data);
            if (response.data.data.step === STEP_WELCOME) {
              page.setRedirectToNextStep(true);
            } else {
              showAlert(ALERT_SUCCESS, "Style your company saved successfully");
            }
            page.setInitialSaved(true);
            page.setSubmitDisabled(true);
            resetForm(values);
          })
          .catch(e => {
            if (e instanceof FormError) {
              setErrors(e.errors);
            }
          })
          .finally(() => {
            page.setLoading(false);
          });
      }
    },
    [page, setSetup, setup.id]
  );

  if (page.redirectToBackStep) {
    return <Redirect to={"/widget/update-introduce"} />;
  }

  if (page.redirectToNextStep) {
    return <Redirect to={"/widget/welcome"} />;
  }

  return (
    <WidgetFormLayout>
      <Formik
        ref={page.form}
        onSubmit={handleSubmitForm}
        initialValues={initialValues}
        render={props => (
          <WidgetStyleFormContainer
            {...props}
            submitDisabled={page.submitDisabled}
            setSubmitDisabled={page.setSubmitDisabled}
          />
        )}
      />
      <WidgetFooter>
        <Button
          processing={page.loading}
          disabled={page.submitDisabled}
          onClick={page.handleNextClick}
        >
          Save
        </Button>
      </WidgetFooter>
    </WidgetFormLayout>
  );
}

const mapStateToProps = state => {
  return {
    setup: state.widgetSetup.current
  };
};

const mapDispatchToProps = dispatch => ({
  setStyle: values => dispatch(setStyle(values)),
  setSetup: setup => dispatch(setSetup(setup))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WidgetStyleContainer);
