import axios from "axios";
import normalize from "json-api-normalizer";
import { apiUrl } from "./client";

export const API_DATA_REQUEST = "API_DATA_REQUEST";
export const API_DATA_SUCCESS = "API_DATA_SUCCESS";
export const API_DATA_FAILURE = "API_DATA_FAILURE";

export const client = axios.create({
  baseURL: apiUrl()
});

const callApi = function(endpoint, options) {
  const onSuccess = function(response) {
    return response;
  };

  const onError = function(error) {
    if (error.response.status === 401) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.replace("/login");
    }

    return Promise.reject(error.response || error.message);
  };

  return client(endpoint, options)
    .then(onSuccess)
    .catch(onError);
};

export const CALL_API = Symbol("Call Api");

const api = store => {
  return function nxt(next) {
    return function call(action) {
      const callAPI = action[CALL_API];

      if (typeof callAPI === "undefined") {
        return next(action);
      }

      let { endpoint, types } = callAPI;
      let { payload } = callAPI;
      const { options, form } = callAPI;

      if (typeof payload === "undefined") {
        payload = {};
      }
      if (typeof types === "undefined") {
        types = {};
      }

      if (typeof endpoint === "function") {
        endpoint = endpoint(store.getState());
      }

      if (typeof endpoint !== "string") {
        throw new Error("Specify a string endpoint URL.");
      }

      const actionWith = data => {
        const finalAction = Object.assign({}, action, data);
        delete finalAction[CALL_API];
        return finalAction;
      };

      const endpointWith = method => {
        return method.toUpperCase() + " " + endpoint;
      };

      const endpointWithMethod = endpointWith(options.method);

      next(
        actionWith({ type: API_DATA_REQUEST, endpoint: endpointWithMethod })
      );

      if (types.request) {
        next(actionWith({ type: types.request, payload }));
      }

      const CancelToken = axios.CancelToken;
      const cancelRequest = CancelToken.source();

      options.cancelToken = cancelRequest.token;

      const ready = callApi(endpoint, options).then(
        response => {
          const normalizedData = normalize(response.data, {
            endpoint: endpointWithMethod
          });

          const successAction = next(
            actionWith({
              response: Object.assign({}, normalizedData),
              type: API_DATA_SUCCESS,
              endpoint: endpointWithMethod,
              success: true
            })
          );

          if (typeof form !== "undefined") {
            if (typeof form.setSubmitting !== "undefined") {
              form.setSubmitting(false);
            }
          }

          if (types.success) {
            let payloadData = Object.assign(
              {},
              payload,
              normalizedData.meta[endpointWithMethod]
            );

            if (response.data.cursor) {
              payloadData.cursor = response.data.cursor;
            }

            next(
              actionWith({
                type: types.success,
                payload: payloadData,
                success: true
              })
            );
          }
          return successAction;
        },
        error => {
          const errorAction = next(
            actionWith({
              type: API_DATA_FAILURE,
              endpoint: endpointWithMethod,
              error:
                (error && error.data && error.data.data) ||
                error.message ||
                "Something bad happened",
              failure: true
            })
          );

          if (typeof form !== "undefined") {
            if (typeof form.setErrors !== "undefined") {
              if (
                error &&
                error.data &&
                error.data.data &&
                error.data.data.errors
              ) {
                let errors = {};

                if (error.data.data.errors.length > 0) {
                  error.data.data.errors.forEach(error => {
                    const attribute = error.source.pointer
                      .substring(1)
                      .split("/");

                    if (attribute.length === 4) {
                      errors[attribute[3] + "[" + attribute[2] + "]"] =
                        error.title;
                    } else if (attribute.length === 3) {
                      errors[attribute[2]] = error.title;
                    }
                  });
                } else {
                  errors.common = error.data.data.errors.title;
                }

                form.setErrors(errors);
              } else {
                form.setErrors({ common: "Something bad happened" });
              }
            }

            if (typeof form.setSubmitting !== "undefined") {
              form.setSubmitting(false);
            }
          }

          if (types.failure) {
            next(
              actionWith({
                type: types.failure,
                payload: Object.assign({}, payload, {
                  error:
                    (error && error.data && error.data.data) ||
                    error.message ||
                    "Something bad happened"
                }),
                failure: true
              })
            );
          }

          return errorAction;
        }
      );

      return { ready, cancelRequest };
    };
  };
};

export default api;
