import React from "react";
import { DateTime } from "components/DateTime";
import { TableRow, TableColumn } from "design-system-react";
import { getCaseStatusColor } from "../../../helpers/cases";
import CaseStatusColumn from "../../../components/CaseStatusColumn";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import CaseListItemEndUserContainer from "../../../containers/CaseListItemEndUserContainer";
import AssignedTeamMemberContainer from "../../../containers/AssignedTeamMemberContainer";
import CaseChannelContainer from "../../../containers/CaseChannelContainer";

function TeamMemberCaseItemContainer({ caseItem, channelId, teamMemberId }) {
  return (
    <TableRow
      tag={Link}
      to={{ pathname: "/team-member/" + teamMemberId + "/" + caseItem.id }}
      status={getCaseStatusColor(caseItem.status)}
      replace
    >
      <TableColumn justified fixed width={300}>
        <CaseListItemEndUserContainer
          endUserId={caseItem.endUser}
          lastMessageId={caseItem.lastMessage}
          unread={caseItem.unread}
        />
      </TableColumn>
      <TableColumn>
        <CaseStatusColumn status={caseItem.status} />
      </TableColumn>
      <TableColumn>
        <AssignedTeamMemberContainer
          teamMemberId={caseItem.assignedTeamMember}
        />
      </TableColumn>
      <TableColumn>
        <DateTime time={caseItem.updatedAt} />
      </TableColumn>
      <TableColumn>
        <CaseChannelContainer channelId={channelId} />
      </TableColumn>
      <TableColumn>#{caseItem.aid}</TableColumn>
    </TableRow>
  );
}

const mapStateToProps = (state, props) => {
  const caseItem = state.entities.cases[props.caseItemId];
  const conversation =
    caseItem && state.entities.conversations[caseItem.conversation];

  return {
    caseItem: state.entities.cases[props.caseItemId],
    channelId: conversation && conversation.channel
  };
};

export default connect(mapStateToProps)(TeamMemberCaseItemContainer);
