import React, { useCallback } from "react";
import styles from "./MessageFormAttachment.module.css";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Preloader } from "design-system-react";
import { renderFilePictureUploadLink } from "../../../helpers/cases";

const isImage = (type, ext) =>
  type === "image" && ["gif", "webp"].indexOf(ext) === -1;

function MessageFormAttachment({
  id,
  name,
  size,
  uploadedSize,
  ext,
  type,
  preview,
  uploaded,
  error,
  onDeleteClick,
  extUploaded,
  typeUploaded,
  channelType
}) {
  const classes = classNames(
    styles.attachment,
    uploaded && styles.uploaded,
    isImage(typeUploaded, extUploaded) && styles.images,
    error && styles.error
  );

  const handleDeleteClick = useCallback(() => {
    onDeleteClick(id);
  }, [id, onDeleteClick]);

  const uploadProgress = Math.min(Math.round((uploadedSize / size) * 100), 100);

  return (
    <>
      {uploaded && !error && (
        <div className={classes}>
          <div className={styles.deleteButton} onClick={handleDeleteClick}>
            <i className="ico-close" />
          </div>
          <div className={styles.fileInfo}>
            <span className={styles.preview}>
              {isImage(typeUploaded, extUploaded) ? (
                <img src={preview} alt={name} />
              ) : (
                <img
                  src={renderFilePictureUploadLink(extUploaded)}
                  alt={name}
                />
              )}
            </span>
            <div className={styles.info}>
              <span className={styles.name}>
                <span className={styles.nameBlock}>{name.split(".", 1)}</span>.
                <span className={styles.extBlock}>{extUploaded}</span>
              </span>
              {size > 1024000 && (
                <span className={styles.size}>
                  {Math.round(size / 1024 / 1024)} mb
                </span>
              )}
              {size < 1024000 && (
                <span className={styles.size}>
                  {Math.round(size / 1024)} kb
                </span>
              )}
            </div>
          </div>
        </div>
      )}

      {!uploaded && !error && (
        <div className={classes}>
          <div className={styles.fileInfoUploaded}>
            <div className={styles.preloaderWrapper}>
              <Preloader size="28" color="#807d7f" />
              <div className={styles.deleteButton} onClick={handleDeleteClick}>
                <i className="ico-close" />
              </div>
            </div>
            <div className={styles.fileLoadingInfo}>
              <div className={styles.upload}>Uploading file...</div>
              <div className={styles.percent}>{uploadProgress}%</div>
            </div>
          </div>
          <span
            className={styles.loadingOverlay}
            style={{ width: uploadProgress + "%" }}
          />
        </div>
      )}

      {!uploaded && error && (
        <div className={classes}>
          <div className={styles.deleteButton} onClick={handleDeleteClick}>
            <i className="ico-close" />
          </div>
          <div className={styles.fileInfo}>
            <span className={styles.preview}>
              {type === "image" && <img src={preview} alt={name} />}
              {type !== "image" && (
                <img src={renderFilePictureUploadLink(ext)} alt={name} />
              )}
            </span>
            <div className={styles.info}>
              <span className={styles.name}>
                <span className={styles.nameBlock}>{name.split(".", 1)}</span>.
                <span className={styles.extBlock}>{ext}</span>
              </span>

              {channelType === "telegrambot" && (
                <span className={styles.size}>{error && "Max size 20 MB"}</span>
              )}

              {channelType !== "telegrambot" && (
                <span className={styles.size}>
                  {error && "Max size 100 MB"}
                </span>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

MessageFormAttachment.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  uploaded: PropTypes.bool.isRequired
};

export default MessageFormAttachment;
