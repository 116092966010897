import { connect } from "react-redux";
import TeamMemberTeams from "../components/TeamMemberTeams";

const mapStateToProps = (state, props) => {
  // TODO: пофиксить лоадер
  const team = props.teamIds && state.entities.teams[props.teamIds[0]];

  return {
    teamTitle: team && team.title
  };
};

export default connect(mapStateToProps)(TeamMemberTeams);
