import React, { useEffect } from "react";
import SettingsSubMenu from "components/settings/SettingsSubMenu";
import SettingsViberContainer from "./containers/SettingsViberContainer";
import SettingsLayout from "components/SettingsLayout";

function SettingsViber({ location }) {
  useEffect(() => {
    document.title = "Viber settings";
    localStorage.setItem("lastSettingRoute", "/settings/viber");
  }, []);

  return (
    <SettingsLayout
      renderSubmenu={() => (
        <SettingsSubMenu activeMenuItem={"/settings/viber"} />
      )}
      renderContent={() => (
        <SettingsViberContainer locationKey={location.key} />
      )}
    />
  );
}

export default SettingsViber;
