import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./Collapse.module.css";
import classNames from "classnames";

function Collapse({ header, children }) {
  let collapseState = localStorage.getItem("collapse " + header);
  const [isOpen, setIsOpen] = useState(
    collapseState ? collapseState !== "false" : true
  );

  const toggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  useEffect(() => {
    localStorage.setItem("collapse " + header, isOpen);
  }, [isOpen, header]);

  return (
    <div className={classNames(styles.collapse, isOpen && styles.open)}>
      <div className={styles.header} onClick={toggle}>
        {header} <i className="ico-down-open-big" />
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
}

Collapse.propTypes = {
  initialIsOpen: PropTypes.bool
};

Collapse.defaultProps = {
  initialIsOpen: true
};

export default Collapse;
