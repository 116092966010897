import React, { useEffect, useCallback } from "react";
import SettingsLayout from "components/SettingsLayout";
import SettingsSubMenu from "components/settings/SettingsSubMenu";
import SettingsAutoResponsesContainer from "./containers/SettingsAutoResponsesContainer";

function SettingsAutoResponses() {
  useEffect(() => {
    document.title = "Auto-response";
    localStorage.setItem("lastSettingRoute", "/settings/auto-responses");
  }, []);

  const renderSubmenu = useCallback(
    () => <SettingsSubMenu activeMenuItem={"/settings/auto-responses"} />,
    []
  );

  const renderContent = useCallback(
    () => <SettingsAutoResponsesContainer />,
    []
  );

  return (
    <SettingsLayout
      renderSubmenu={renderSubmenu}
      renderContent={renderContent}
    />
  );
}

export default SettingsAutoResponses;
