import React from "react";
import classNames from "classnames";
import { Button } from "design-system-react";
import styles from "./index.module.css";

function SettingsHeader({
  logo,
  title,
  children,
  onAction,
  buttonProcessing,
  buttonText,
  noBorder
}) {
  const classes = classNames(styles.container);

  return (
    <div className={classes}>
      {!noBorder && <span className={styles.border} />}
      <div className={styles.channelInfo}>
        {logo && (
          <figure className={styles.logo}>
            <img src={logo} alt={title} />
          </figure>
        )}
        <div className={styles.rightInfo}>
          <div className={styles.title}>{title}</div>
          <div className={styles.description}>{children}</div>
        </div>
      </div>
      {onAction && (
        <Button
          processing={buttonProcessing}
          disabled={buttonProcessing}
          onClick={onAction}
        >
          {buttonText || "Add"}
        </Button>
      )}
    </div>
  );
}

export default SettingsHeader;
