import React, { useState, useCallback } from "react";
import TeamUsersNavPanelContainer from "./TeamUsersNavPanelContainer";
import InviteModalContainer from "./CreateModalContainer";
import TeamMembersListContainer from "./../../../pages/TeamMembers/containers/TeamMembersListContainer";
import { Row, Col } from "design-system-react";

function TeamMembersContainer({
  locationKey,
  onSubMenuToggle,
  view,
  activeMenuItem,
  isSubmenuOpened
}) {
  const [inviteModalOpened, setInviteModalOpened] = useState(false);

  const toggleInviteModal = useCallback(() => {
    setInviteModalOpened(!inviteModalOpened);
  }, [inviteModalOpened]);

  return (
    <Row fullHeight column>
      <Col auto>
        <TeamUsersNavPanelContainer
          onSubMenuToggle={onSubMenuToggle}
          isSubmenuOpened={isSubmenuOpened}
          toggleInviteModal={toggleInviteModal}
          activeView={view}
          activeMenuItem={activeMenuItem}
        />
      </Col>
      <Col justified>
        <TeamMembersListContainer locationKey={locationKey} view={view} />
      </Col>
      <InviteModalContainer
        isOpen={inviteModalOpened}
        toggle={toggleInviteModal}
        activeView={view}
      />
    </Row>
  );
}

export default TeamMembersContainer;
