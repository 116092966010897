import React, { useState, useCallback, useEffect } from "react";
import history from "../../../utils/history";
import { Formik } from "formik";
import { api } from "../../../utils/client";
import { ALERT_SUCCESS, showAlert } from "../../../utils/alertManager";
import { FormError } from "../../../utils/formError";
import { Row, Col, Container } from "design-system-react";
import SettingsChannelHeader from "components/SettingsHeader";
import AutoResponseListContainer from "./AutoResponseListContainer";
import AutoResponseSettingsFormContainer from "./AutoResponseSettingsFormContainer";

function SettingsAutoResponsesContainer() {
  const [settings, setSettings] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleCreateAction = useCallback(() => {
    history.push("/settings/auto-responses/create");
  }, []);

  const handleSettingsSubmit = useCallback((values, { setErrors }) => {
    api.settings
      .updateAutoResponseSettings(values)
      .ready.then(() => {
        showAlert(ALERT_SUCCESS, "Settings saved");
      })
      .catch(e => {
        if (e instanceof FormError) {
          setErrors(e.errors);
        }
      });
  }, []);

  useEffect(() => {
    const request = api.settings.all();
    request.ready
      .then(response => {
        setSettings(response.data.autoResponse);
      })
      .finally(() => {
        setLoading(false);
      });

    return () => {
      request.cancelRequest.cancel();
    };
  }, []);

  return (
    <Row fullHeight column>
      <Container gutterBottom={20}>
        <Row>
          <Col gutterBottom={40}>
            <SettingsChannelHeader
              title="Auto-response"
              onAction={handleCreateAction}
              buttonText="Create"
            >
              Run the advanced auto-responder builder to make sure that each of
              your customers receives an exact response. Set up rules for new
              cases based on specific criteria, such as end-user qualification,
              channel, text message content, language, and time period for each
              new case.
            </SettingsChannelHeader>
          </Col>
        </Row>
        {!loading && (
          <Formik
            initialValues={settings}
            onSubmit={handleSettingsSubmit}
            component={AutoResponseSettingsFormContainer}
          />
        )}
      </Container>
      <Col justified>{!loading && <AutoResponseListContainer />}</Col>
    </Row>
  );
}

export default SettingsAutoResponsesContainer;
