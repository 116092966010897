import { useMemo, useEffect, useState, useCallback } from "react";
import connect from "react-redux/es/connect/connect";
import { api } from "../../../utils/client";
import {
  removeTeamMemberId,
  setTeamMemberIds,
  addTeamMemberIds
} from "../../../modules/pages/teamMembers";
import {
  Preloader,
  Table,
  TableHeader,
  TableHeaderColumn,
  TableHeaderColumnMenu,
  TableBody,
  Col
} from "design-system-react";
import {
  MODAL_DEFAULT,
  MODAL_ERROR,
  MODAL_PROCESS,
  MODAL_SUCCESS,
  showConfirmModal,
  updateConfirmModal
} from "../../../utils/modalManager";
import { CommonError } from "../../../utils/commonError";
import EditTeamMemberModal from "../../../containers/EditTeamMemberModal";
import usePagination from "../../../hooks/usePagination";
import InfinitePagination from "../../../components/infinitePagination/InfinitePagination";
import EmptyState from "components/EmptyState";
import TeamMembersListItemContainer from "./TeamMembersListItemContainer";
import { ALERT_SUCCESS, showAlert } from "../../../utils/alertManager";
import copyToClipboard from "../../../utils/copyToClipboard";
import useDeleteTeamMember from "hooks/useDeleteTeamMember";

function TeamMembersListContainer({
  locationKey,
  view,
  teamMemberIds,
  setTeamMemberIds,
  addTeamMemberIds,
  removeTeamMemberId
}) {
  const [scrollbarHeight, setScrollbarHeight] = useState(null);
  const [editModal, setEditModal] = useState({
    isOpen: false,
    selectedTeamMember: null
  });

  const deleteTeamMember = useDeleteTeamMember();

  const setHeightScrollbar = useCallback(height => {
    setScrollbarHeight(height);
  }, []);

  const handleTeamMembersFetch = useCallback(
    request => {
      request.ready.then(response => {
        addTeamMemberIds(response.data.data.map(member => member.id));
      });
    },
    [addTeamMemberIds]
  );

  const teamMembersFetchParams = useMemo(() => {
    return {
      view
    };
  }, [view]);

  const teamMembersPagination = usePagination(
    api.teamMembers.byView,
    teamMembersFetchParams,
    40,
    handleTeamMembersFetch
  );

  useEffect(() => {
    setTeamMemberIds([]);

    const request = teamMembersPagination.load();

    return () => {
      request.cancelRequest.cancel();
    };

    // eslint-disable-next-line
  }, [locationKey, setTeamMemberIds]);

  const handleEdit = useCallback(
    selectedTeamMember => {
      setEditModal({
        isOpen: !editModal.isOpen,
        selectedTeamMember: selectedTeamMember || editModal.selectedTeamMember
      });
    },
    [editModal]
  );

  const toggleEditModal = useCallback(() => {
    handleEdit(null);
  }, [handleEdit]);

  const handleActionDropdown = useCallback(
    (member, action) => {
      switch (action) {
        case "copy":
          const link =
            window.location.protocol +
            "//" +
            window.location.hostname +
            (window.location.port ? ":" + window.location.port : "") +
            "/invite-confirmation/" +
            member.invitationToken;
          copyToClipboard(link);
          showAlert(ALERT_SUCCESS, "Link copied.");
          break;
        case "edit":
          handleEdit(member);
          break;
        case "enable":
          {
            const modalId = showConfirmModal(
              MODAL_DEFAULT,
              "Are you sure you want to enable " + member.name ||
                member.email + "?",
              {
                actionButtonText: "Enable",
                onAction: () => {
                  updateConfirmModal(modalId, MODAL_PROCESS, "Enabling...");

                  api.teamMembers
                    .enable(member.id)
                    .ready.then(response => {
                      if (view !== "all") {
                        removeTeamMemberId(response.data.data.id);
                      }

                      api.views.teamMembers();

                      updateConfirmModal(
                        modalId,
                        MODAL_SUCCESS,
                        "Team Member was successfully enabled"
                      );
                    })
                    .catch(e => {
                      const errorMessage = "Ooops, something went wrong";

                      updateConfirmModal(
                        modalId,
                        MODAL_ERROR,
                        e instanceof CommonError ? e.error : errorMessage
                      );
                    });
                }
              }
            );
          }
          break;
        case "disable":
          {
            const modalId = showConfirmModal(
              MODAL_DEFAULT,
              "Are you sure you want to disable " + member.name ||
                member.email + "?",
              {
                actionButtonText: "Disable",
                onAction: () => {
                  updateConfirmModal(modalId, MODAL_PROCESS, "Disabling...");

                  api.teamMembers
                    .disable(member.id)
                    .ready.then(response => {
                      if (view !== "all") {
                        removeTeamMemberId(response.data.data.id);
                      }

                      api.views.teamMembers();

                      updateConfirmModal(
                        modalId,
                        MODAL_SUCCESS,
                        "Team Member was successfully disabled"
                      );
                    })
                    .catch(e => {
                      const errorMessage = "Ooops, something went wrong";

                      updateConfirmModal(
                        modalId,
                        MODAL_ERROR,
                        e instanceof CommonError ? e.error : errorMessage
                      );
                    });
                }
              }
            );
          }
          break;
        case "restore":
          {
            const modalId = showConfirmModal(
              MODAL_DEFAULT,
              "Are you sure you want to restore " + member.name ||
                member.email + "?",
              {
                actionButtonText: "Restore",

                onAction: () => {
                  updateConfirmModal(modalId, MODAL_PROCESS, "Restoring...");

                  api.teamMembers
                    .restore(member.id)
                    .ready.then(response => {
                      if (view !== "all" && response.data === true) {
                        removeTeamMemberId(member.id);
                      }

                      api.views.teamMembers();

                      updateConfirmModal(
                        modalId,
                        MODAL_SUCCESS,
                        "Team Member was successfully restored"
                      );
                    })
                    .catch(e => {
                      const errorMessage = "Ooops, something went wrong";

                      updateConfirmModal(
                        modalId,
                        MODAL_ERROR,
                        e instanceof CommonError ? e.error : errorMessage
                      );
                    });
                }
              }
            );
          }
          break;
        case "resend":
          {
            const modalId = showConfirmModal(
              MODAL_DEFAULT,
              "Are you sure you want to resend an invitation to " +
                member.email +
                "?",
              {
                actionButtonText: "Resend",
                onAction: () => {
                  updateConfirmModal(modalId, MODAL_PROCESS, "Sending...");

                  api.teamMembers
                    .resendInvitation(member.id)
                    .ready.then(() => {
                      updateConfirmModal(
                        modalId,
                        MODAL_SUCCESS,
                        "Invitation was successfully resent to " + member.email
                      );
                    })
                    .catch(e => {
                      const errorMessage = "Ooops, something went wrong";

                      updateConfirmModal(
                        modalId,
                        MODAL_ERROR,
                        e instanceof CommonError ? e.error : errorMessage
                      );
                    });
                }
              }
            );
          }
          break;
        case "delete":
          deleteTeamMember(member.id, member.name, () => {
            removeTeamMemberId(member.id);

            api.views.teamMembers();
          });

          break;
        default:
          break;
      }
    },
    [handleEdit, deleteTeamMember, removeTeamMemberId, view]
  );

  if (teamMemberIds.length === 0 && teamMembersPagination.nextLoading) {
    return (
      <Col align="center" justified>
        <Preloader size="32" color="#807d7f" />
      </Col>
    );
  }

  if (teamMemberIds.length === 0) {
    switch (view) {
      case "all":
        return (
          <Col align="center" verticalAlign="center" justified>
            <EmptyState title="All team members is empty">
              Currently you have no team members <br />
              in All team members view
            </EmptyState>
          </Col>
        );
      case "enabled":
        return (
          <Col align="center" verticalAlign="center" justified>
            <EmptyState title="No enabled team members">
              Currently you have no enabled <br /> team members
            </EmptyState>
          </Col>
        );
      case "pending":
        return (
          <Col align="center" verticalAlign="center" justified>
            <EmptyState title="No pending team members">
              Currently you have no pending <br /> team members
            </EmptyState>
          </Col>
        );
      case "deleted":
        return (
          <Col align="center" verticalAlign="center" justified>
            <EmptyState title="No deleted team members">
              Currently you have no deleted <br /> team members
            </EmptyState>
          </Col>
        );
      default:
        return (
          <Col align="center" verticalAlign="center" justified>
            <EmptyState title="Team members is empty">
              Currently you have no <br /> team members
            </EmptyState>
          </Col>
        );
    }
  }

  return (
    <>
      <EditTeamMemberModal
        isOpen={editModal.isOpen}
        toggle={toggleEditModal}
        teamMember={editModal.selectedTeamMember}
      />
      <InfinitePagination
        setHeightScrollbar={setHeightScrollbar}
        pagination={teamMembersPagination}
      >
        <Table>
          <TableHeader>
            <TableHeaderColumn justified fixed />
            <TableHeaderColumn>PIN</TableHeaderColumn>
            {view !== "deleted" && <TableHeaderColumn>Team</TableHeaderColumn>}
            <TableHeaderColumn>Added</TableHeaderColumn>
            <TableHeaderColumn>Role</TableHeaderColumn>
            <TableHeaderColumnMenu />
          </TableHeader>
          <TableBody>
            {teamMemberIds.map(id => {
              return (
                <TeamMembersListItemContainer
                  key={id}
                  teamMemberId={id}
                  view={view}
                  scrollbarHeight={scrollbarHeight}
                  handleActionDropdown={handleActionDropdown}
                />
              );
            })}
            {teamMembersPagination.nextLoading && (
              <Col align="center" gutterTop={30} gutterBottom={30}>
                <Preloader size="32" color="#807d7f" />
              </Col>
            )}
          </TableBody>
        </Table>
      </InfinitePagination>
    </>
  );
}

const mapStateToProps = state => {
  return {
    teamMemberIds: state.pages.teamMembers.ids
  };
};

const mapDispatchToProps = dispatch => ({
  setTeamMemberIds: ids => dispatch(setTeamMemberIds(ids)),
  addTeamMemberIds: ids => dispatch(addTeamMemberIds(ids)),
  removeTeamMemberId: id => dispatch(removeTeamMemberId(id))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamMembersListContainer);
