import React, { useEffect, useCallback, useState } from "react";
import { connect } from "react-redux";
import { Textarea, GutterField, Button } from "design-system-react";
import { api } from "utils/widgetClient";
import { ALERT_SUCCESS, showAlert } from "utils/alertManager";
import { getEnv, REACT_APP_WIDGET_APP_URL } from "utils/env";
import TextareaWrap from "./components/TextareaWrap";
import WidgetHeaderTitle from "components/Widget/WidgetHeaderTitle";
import WidgetHeaderDescription from "components/Widget/WidgetHeaderDescription";
import WidgetHeader from "components/Widget/WidgetHeader";
import WidgetFormLayout from "components/Widget/WidgetFormLayout";

function GoLive({ token_widget, setupId }) {
  const [isLoading, setLoading] = useState(false);
  const [canPublish, setCanPublish] = useState(true);

  useEffect(() => {
    document.title = "Go live";
  }, []);

  const handleSubmitPublish = useCallback(() => {
    setLoading(true);
    api.widget
      .publishSetup(setupId)
      .ready.then(response => {
        if (response.data.data.published) {
          setCanPublish(false);
        }
        showAlert(
          ALERT_SUCCESS,
          "The changes have been published successfully"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setupId]);

  return (
    <>
      <WidgetHeader>
        <WidgetHeaderTitle>Go live</WidgetHeaderTitle>
        <WidgetHeaderDescription>
          Start with adding a company logo and represent your teammates
        </WidgetHeaderDescription>
      </WidgetHeader>
      <WidgetFormLayout>
        <GutterField>
          <TextareaWrap>
            <Textarea
              name="code"
              label="Widget code"
              disabled
              value={
                `<script>var BLINGER_ID="${setupId}";(function (d) {var s = d.createElement("script");s.id="hugery-widget";s.async = true;s.charset="utf-8";s.src = "` +
                getEnv(REACT_APP_WIDGET_APP_URL) +
                `"+"/blinger-widget.js";d.head.appendChild(s);})(window.document);</script>`
              }
            />
          </TextareaWrap>
        </GutterField>
        <Button
          processing={isLoading}
          disabled={!canPublish}
          onClick={handleSubmitPublish}
        >
          Publish
        </Button>
      </WidgetFormLayout>
    </>
  );
}

const mapStateToProps = state => {
  return {
    setupId: state.widgetSetup.current.id || "Null",
    brandColor: state.widgetSetup.current.style.brandColor || "#999"
  };
};

export default connect(mapStateToProps)(GoLive);
