import React from "react";
import {
  Row,
  Col,
  Container,
  Card,
  CardHeader,
  CardBody,
  Scrollbar
} from "design-system-react";
import CardTitle from "../components/CardTitle";
import CardTitleText from "../components/CardTitleText";
import Title from "../components/Title";
import HomeContainer from "../components/HomeContainer";
import BodyTitle from "../components/BodyTitle";
import BodyText from "../components/BodyText";
import CardLinkContainer from "../components/CardLinkContainer";
import HomePageHeader from "../components/HomePageHeader";
import HeaderText from "../components/HeaderText";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

const cards = {
  card: [
    {
      icon: "/images/channels-icon-home.svg",
      name: "Channels",
      title: "Link channels",
      text:
        "Connect all channels that your customers prefer to contact your support Team and start manage your cases",
      link: "/settings/whatsapp-business"
    },
    {
      icon: "/images/team-member-icon-home.svg",
      name: "Your team members",
      title: "Invite team members onboard",
      text:
        "Get your team onboard, so they can start replying to your customers",
      link: "/team-members/enabled"
    },
    {
      icon: "/images/teams-icon-home.svg",
      name: "Teams",
      title: "Create teams",
      text:
        "Manage your teams to provide correct routing of all cases to the right team",
      link: "/teams/enabled"
    },
    {
      icon: "/images/account-icon-home.svg",
      name: "Account",
      title: "Edit your account",
      text: "Manage your personal account settings",
      link: "/settings/profile"
    }
  ]
};

function HomePageContainer({ name }) {
  return (
    <Scrollbar>
      <Container fluid>
        <Row column>
          <HomePageHeader>
            <HeaderText>
              <span role="img" aria-label="hand">
                👋
              </span>
              <div>Welcome, {name}!</div>
            </HeaderText>
          </HomePageHeader>
        </Row>
      </Container>

      <Container>
        <Row column>
          <Col auto>
            <HomeContainer>
              <Title>
                Start creating better customer experiences <br />
                with steps below
              </Title>

              {/*<CardLinkContainer*/}
              {/*tag="a"*/}
              {/*target="_blank"*/}
              {/*href="https://widget.blinger.io"*/}
              {/*>*/}
              {/*<Card hover={true}>*/}
              {/*<CardHeader>*/}
              {/*<CardTitle>*/}
              {/*<img src="/images/widget-icon-home.svg" alt="" />*/}
              {/*<CardTitleText>Widget</CardTitleText>*/}
              {/*</CardTitle>*/}
              {/*<svg*/}
              {/*xmlns="http://www.w3.org/2000/svg"*/}
              {/*width="7.97"*/}
              {/*height="15"*/}
              {/*viewBox="0 0 7.97 15"*/}
              {/*>*/}
              {/*<path*/}
              {/*id="Rounded_Rectangle_526_copy_3"*/}
              {/*data-name="Rounded Rectangle 526 copy 3"*/}
              {/*className="cls-1"*/}
              {/*d="M1742.14,336.787l6.69,6.7-6.69,6.7a0.478,0.478,0,1,0,.68.672l6.97-6.99a0.212,0.212,0,0,0,.06-0.025,0.519,0.519,0,0,0,0-.709,0.212,0.212,0,0,0-.06-0.025l-6.97-6.99A0.478,0.478,0,0,0,1742.14,336.787Z"*/}
              {/*transform="translate(-1742.03 -336)"*/}
              {/*/>*/}
              {/*</svg>*/}
              {/*</CardHeader>*/}
              {/*<CardBody marginTop="24px">*/}
              {/*<BodyTitle>Set up omnichannel widget</BodyTitle>*/}
              {/*<BodyText>*/}
              {/*Configure and enable Blinger Messenger for better way to*/}
              {/*provide live chat support*/}
              {/*</BodyText>*/}
              {/*</CardBody>*/}
              {/*</Card>*/}
              {/*</CardLinkContainer>*/}

              {cards.card.map(card => (
                <CardLinkContainer key={card.link} tag={Link} to={card.link}>
                  <Card hover={true}>
                    <CardHeader>
                      <CardTitle>
                        <img src={card.icon} alt="" />
                        <CardTitleText>{card.name}</CardTitleText>
                      </CardTitle>

                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="7.97"
                        height="15"
                        viewBox="0 0 7.97 15"
                      >
                        <path
                          id="Rounded_Rectangle_526_copy_3"
                          data-name="Rounded Rectangle 526 copy 3"
                          className="cls-1"
                          d="M1742.14,336.787l6.69,6.7-6.69,6.7a0.478,0.478,0,1,0,.68.672l6.97-6.99a0.212,0.212,0,0,0,.06-0.025,0.519,0.519,0,0,0,0-.709,0.212,0.212,0,0,0-.06-0.025l-6.97-6.99A0.478,0.478,0,0,0,1742.14,336.787Z"
                          transform="translate(-1742.03 -336)"
                        />
                      </svg>
                    </CardHeader>
                    <CardBody marginTop="24px">
                      <BodyTitle>{card.title}</BodyTitle>
                      <BodyText>{card.text}</BodyText>
                    </CardBody>
                  </Card>
                </CardLinkContainer>
              ))}
            </HomeContainer>
          </Col>
        </Row>
      </Container>
    </Scrollbar>
  );
}

const mapStateToProps = state => {
  const currentUser = state.entities.teamMembers[state.auth.userId];

  return {
    name: currentUser && currentUser.name
  };
};

export default connect(mapStateToProps)(HomePageContainer);
