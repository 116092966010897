import React from "react";
import { replaceLink } from "helpers/replaceLink";
import styles from "./CaseReformattedText.module.css";

function CaseReformattedText({ children }) {
  return (
    <span
      className={styles.link}
      dangerouslySetInnerHTML={replaceLink(children)}
    />
  );
}

export default CaseReformattedText;
