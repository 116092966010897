import { withNamespace } from "utils/namespace";

const type = withNamespace("teamMembers");

// action types
export const SET_TEAM_MEMBER_IDS = type("SET_TEAM_MEMBER_IDS");
export const ADD_TEAM_MEMBER_IDS = type("ADD_TEAM_MEMBER_IDS");
export const ADD_TEAM_MEMBER_ID = type("ADD_TEAM_MEMBER_ID");
export const REMOVE_TEAM_MEMBER_ID = type("REMOVE_TEAM_MEMBER_ID");
export const SET_TEAM_IDS = type("SET_TEAM_IDS");
export const SET_ROLE_IDS = type("SET_ROLE_IDS");

let initialState = {
  ids: [],
  teamIds: [],
  roleIds: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_TEAM_MEMBER_IDS:
      return {
        ...state,
        ids: action.payload.ids
      };
    case ADD_TEAM_MEMBER_IDS:
      return {
        ...state,
        ids: [...state.ids, ...action.payload.ids]
      };
    case SET_TEAM_IDS:
      return {
        ...state,
        teamIds: action.payload.ids
      };
    case SET_ROLE_IDS:
      return {
        ...state,
        roleIds: action.payload.ids
      };
    case ADD_TEAM_MEMBER_ID:
      return {
        ...state,
        ids: [action.payload.id, ...state.ids]
      };
    case REMOVE_TEAM_MEMBER_ID:
      return {
        ...state,
        ids: state.ids.filter(id => id !== action.payload.id)
      };
    default:
      return state;
  }
}

// actions
export function setTeamMemberIds(ids) {
  return {
    type: SET_TEAM_MEMBER_IDS,
    payload: {
      ids
    }
  };
}

export function addTeamMemberIds(ids) {
  return {
    type: ADD_TEAM_MEMBER_IDS,
    payload: {
      ids
    }
  };
}

export function addTeamMemberId(id) {
  return {
    type: ADD_TEAM_MEMBER_ID,
    payload: {
      id
    }
  };
}

export function removeTeamMemberId(id) {
  return {
    type: REMOVE_TEAM_MEMBER_ID,
    payload: {
      id
    }
  };
}

export function setTeamIds(ids) {
  return {
    type: SET_TEAM_IDS,
    payload: {
      ids
    }
  };
}

export function setRoleIds(ids) {
  return {
    type: SET_ROLE_IDS,
    payload: {
      ids
    }
  };
}
