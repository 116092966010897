import React from "react";
import SettingsChannelTableInfo from "components/SettingsChannelTableInfo";
import DateTime from "components/DateTime";
import { TableRow, TableColumn, TableColumnMenu } from "design-system-react";
import { channelActionItems, printChannelStatus } from "helpers/channels";
import connect from "react-redux/es/connect/connect";

const emptyAvatar = channel => {
  switch (channel) {
    case "facebook":
      return "/images/messengers/grey/Grey_Facebook_48.svg";
    case "whatsapp":
    case "tyntecWhatsapp":
      return "/images/messengers/grey/Grey_WhatsApp_48.svg";
    case "telegrambot":
      return "/images/messengers/grey/Grey_Telegram_48.svg";
    case "viber":
      return "/images/messengers/grey/Grey_Viber_48.svg";
    case "email":
      return "/images/messengers/grey/Grey_Email_48.svg";
    case "slack":
      return "/images/messengers/grey/Grey_Slack_48.svg";
    default:
      return "/images/messengers/grey/Grey_Facebook_48.svg";
  }
};

const channelLink = (type, title, channelId, username) => {
  switch (type) {
    case "facebook":
      return `https://m.me/${channelId}`;
    case "vk":
      return `https://vk.com/public${channelId}`;
    case "whatsapp":
    case "tyntecWhatsapp":
      return `https://wa.me/${channelId}`;
    case "telegrambot":
      return `https://telegram.me/${title}`;
    case "viber":
      return `viber://pa?chatURI=${username}`;
    case "email":
      return `mailto:${title}`;
    case "slack":
      return `https://app.slack.com/client/${channelId}`;
    default:
      return "https://...";
  }
};

function SettingsChannelListItem({ channel, handleActionDropdown }) {
  const disabled = channel.deletedAt && channel.deletedAt > channel.restoredAt;
  return (
    <TableRow disabled={disabled}>
      <TableColumn justified fixed>
        <SettingsChannelTableInfo
          avatar={channel.avatar || emptyAvatar(channel.type)}
          noGradient={!channel.avatar}
          name={channel.title || channel.name}
          channel={channelLink(
            channel.type,
            channel.title,
            channel.channelId,
            channel.info && channel.info.username
          )}
          channelType={channel.type}
          channelEmailName={channel.info && channel.info.name}
        />
      </TableColumn>
      <TableColumn>
        <DateTime time={channel.createdAt} />
      </TableColumn>

      {channel.type !== "slack" && (
        <TableColumn>{printChannelStatus(disabled)}</TableColumn>
      )}

      <TableColumnMenu
        items={channelActionItems(
          channel.type,
          channel.enabled,
          channel.deletedAt
        )}
        onItemClick={key => {
          handleActionDropdown(channel.id, channel.title, key);
        }}
      />
    </TableRow>
  );
}

const mapStateToProps = (state, props) => {
  return { channel: state.entities.channels[props.channelId] };
};

export default connect(mapStateToProps)(SettingsChannelListItem);
