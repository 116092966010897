import React, { useRef, useEffect, useCallback, useState } from "react";
import connect from "react-redux/es/connect/connect";
import { removeTeamIds, setTeamsIds } from "modules/pages/teams";
import {
  Table,
  TableHeader,
  TableHeaderColumn,
  TableHeaderColumnMenu,
  TableBody,
  Preloader,
  Col,
  Scrollbar
} from "design-system-react";
import EditTeamModalContainer from "./EditTeamModalContainer";
import { api } from "utils/client";
import {
  MODAL_DEFAULT,
  MODAL_ERROR,
  MODAL_PROCESS,
  MODAL_SUCCESS,
  showConfirmModal,
  updateConfirmModal
} from "utils/modalManager";
import { CommonError } from "utils/commonError";
import axios from "axios";
import EmptyState from "components/EmptyState";
import TeamsListItemContainer from "./TeamsListItemContainer";

function TeamsListContainer({
  locationKey,
  view,
  setTeamsIds,
  removeTeamIds,
  teamIds
}) {
  const innerScrollbarRef = useRef(null);
  const [isLoading, setLoading] = useState(true);
  const [scrollbarHeight, setScrollbarHeight] = useState(null);
  const [editModal, setEditModal] = useState({
    isOpen: false,
    selectedTeam: null
  });

  const handleInnerRef = useCallback(node => {
    innerScrollbarRef.current = node;
  }, []);

  useEffect(() => {
    if (!isLoading && innerScrollbarRef.current) {
      setScrollbarHeight(innerScrollbarRef.current.clientHeight);
    }
  }, [isLoading]);

  useEffect(() => {
    setLoading(true);
    setTeamsIds([]);
    const request = api.teams.byView(view);

    request.ready
      .then(response => {
        setTeamsIds(response.data.data.map(member => member.id));
        setLoading(false);
      })
      .catch(error => {
        if (!axios.isCancel(error)) {
          setLoading(false);
        }
      });

    return () => {
      request.cancelRequest.cancel();
    };
  }, [locationKey, setTeamsIds, view]);

  const handleDisable = useCallback(
    (teamId, title) => {
      const modalId = showConfirmModal(
        MODAL_DEFAULT,
        "Are you sure you want to disable " + title + "?",
        {
          actionButtonText: "Disable",
          onAction: () => {
            updateConfirmModal(modalId, MODAL_PROCESS, "Disabling...");

            api.teams
              .disable(teamId)
              .ready.then(response => {
                if (view !== "all") {
                  removeTeamIds(response.data.data.id);
                }

                updateConfirmModal(
                  modalId,
                  MODAL_SUCCESS,
                  "Team was successfully disabled"
                );
                api.views.teams();
              })
              .catch(e => {
                const errorMessage = "Ooops, something went wrong";
                updateConfirmModal(
                  modalId,
                  MODAL_ERROR,
                  e instanceof CommonError ? e.error : errorMessage
                );
              });
          }
        }
      );
    },
    [view, removeTeamIds]
  );

  const handleEnable = useCallback(
    (teamId, title) => {
      const modalId = showConfirmModal(
        MODAL_DEFAULT,
        "Are you sure you want to enabled " + title + "?",
        {
          actionButtonText: "Enable",
          onAction: () => {
            updateConfirmModal(modalId, MODAL_PROCESS, "Enabling...");

            api.teams
              .enable(teamId)
              .ready.then(response => {
                if (view !== "all") {
                  removeTeamIds(response.data.data.id);
                }

                api.views.teams();

                updateConfirmModal(
                  modalId,
                  MODAL_SUCCESS,
                  "Team was successfully enabled"
                );
              })
              .catch(e => {
                const errorMessage = "Ooops, something went wrong";

                updateConfirmModal(
                  modalId,
                  MODAL_ERROR,
                  e instanceof CommonError ? e.error : errorMessage
                );
              });
          }
        }
      );
    },
    [view, removeTeamIds]
  );

  const handleDelete = useCallback(
    (teamId, title) => {
      const modalId = showConfirmModal(
        MODAL_DEFAULT,
        "Are you sure you want to delete " + title + "?",
        {
          actionButtonText: "Delete",
          onAction: () => {
            updateConfirmModal(modalId, MODAL_PROCESS, "Deleting...");

            api.teams
              .delete(teamId)
              .ready.then(() => {
                removeTeamIds(teamId);

                api.views.teams();

                updateConfirmModal(
                  modalId,
                  MODAL_SUCCESS,
                  "Team was successfully deleted"
                );
              })
              .catch(e => {
                const errorMessage = "Ooops, something went wrong";

                updateConfirmModal(
                  modalId,
                  MODAL_ERROR,
                  e instanceof CommonError ? e.error : errorMessage
                );
              });
          }
        }
      );
    },
    [removeTeamIds]
  );

  const handleRestore = useCallback(
    (teamId, title) => {
      const modalId = showConfirmModal(
        MODAL_DEFAULT,
        "Are you sure you want to restore " + title + "?",
        {
          actionButtonText: "Restore",
          onAction: () => {
            updateConfirmModal(modalId, MODAL_PROCESS, "Restoring...");

            api.teams
              .restore(teamId)
              .ready.then(response => {
                removeTeamIds(response.data.data.id);

                api.views.teams();

                updateConfirmModal(
                  modalId,
                  MODAL_SUCCESS,
                  "Team was successfully restored"
                );
              })
              .catch(e => {
                const errorMessage = "Ooops, something went wrong";

                updateConfirmModal(
                  modalId,
                  MODAL_ERROR,
                  e instanceof CommonError ? e.error : errorMessage
                );
              });
          }
        }
      );
    },
    [removeTeamIds]
  );

  const handleEdit = useCallback(
    selectedTeam => {
      setEditModal({
        isOpen: !editModal.isOpen,
        selectedTeam: selectedTeam || editModal.selectedTeam
      });
    },
    [editModal]
  );

  const toggleEditModal = useCallback(() => {
    handleEdit(null);
  }, [handleEdit]);

  const handleActionDropdown = useCallback(
    (team, action) => {
      switch (action) {
        case "edit":
          handleEdit(team);
          break;
        case "enable":
          handleEnable(team.id, team.title || team.description);
          break;
        case "disable":
          handleDisable(team.id, team.title || team.description);
          break;
        case "restore":
          handleRestore(team.id, team.title || team.description);
          break;
        case "delete":
          handleDelete(team.id, team.title || team.description);
          break;
        default:
          break;
      }
    },
    [handleEdit, handleEnable, handleDisable, handleRestore, handleDelete]
  );

  if (isLoading) {
    return (
      <Col align="center" justified>
        <Preloader size="32" color="#807d7f" />
      </Col>
    );
  }

  if (teamIds.length === 0) {
    switch (view) {
      case "enabled":
        return (
          <Col align="center" verticalAlign="center" justified>
            <EmptyState title="No enabled teams">
              Currently you have no enabled <br /> teams
            </EmptyState>
          </Col>
        );
      case "deleted":
        return (
          <Col align="center" verticalAlign="center" justified>
            <EmptyState title="No deleted teams">
              Currently you have no deleted <br /> teams
            </EmptyState>
          </Col>
        );
      case "all":
        return (
          <Col align="center" verticalAlign="center" justified>
            <EmptyState title="All teams is empty">
              Currently you have no teams <br />
              in All teams section
            </EmptyState>
          </Col>
        );
      default:
        return (
          <Col align="center" verticalAlign="center" justified>
            <EmptyState title="No teams added">
              Start working by <br /> adding team
            </EmptyState>
          </Col>
        );
    }
  }

  return (
    <Scrollbar innerRef={handleInnerRef}>
      <EditTeamModalContainer
        isOpen={editModal.isOpen}
        toggle={toggleEditModal}
        team={editModal.selectedTeam}
      />
      <Table>
        <TableHeader fixed>
          <TableHeaderColumn justified fixed />
          <TableHeaderColumn>Type</TableHeaderColumn>
          <TableHeaderColumn>Created</TableHeaderColumn>
          <TableHeaderColumn>Updated</TableHeaderColumn>
          {view === "deleted" && <TableHeaderColumn>Deleted</TableHeaderColumn>}
          <TableHeaderColumnMenu />
        </TableHeader>
        <TableBody>
          {teamIds.map(id => {
            return (
              <TeamsListItemContainer
                teamIds={teamIds}
                teamId={id}
                scrollbarHeight={scrollbarHeight}
                view={view}
                handleActionDropdown={handleActionDropdown}
              />
            );
          })}
        </TableBody>
      </Table>
    </Scrollbar>
  );
}

const mapStateToProps = state => {
  return {
    teamIds: state.pages.teams.ids
  };
};

const mapDispatchToProps = dispatch => ({
  setTeamsIds: ids => dispatch(setTeamsIds(ids)),
  removeTeamIds: id => dispatch(removeTeamIds(id))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamsListContainer);
