import React, {useState, useCallback, useEffect} from "react";
import {connect} from "react-redux";
import TeamNavPanel from "../components/TeamNavPanel";
import AddTeamUserModalContainer from "./AddTeamUserModalContainer";
import {Row, Col, SidePanel, Preloader, Scrollbar} from "design-system-react";
import TeamHeader from "../components/TeamHeader";
import TeamCard from "../components/TeamCard";
import {api} from "utils/client";
import {
  MODAL_DEFAULT,
  MODAL_ERROR,
  MODAL_PROCESS,
  MODAL_SUCCESS,
  showConfirmModal,
  updateConfirmModal
} from "utils/modalManager";
import {CommonError} from "utils/commonError";
import {teamActionItems} from "helpers/teams";
import {removeTeamIds} from "modules/pages/teams";
import TeamUserListContainer from "pages/Team/containers/TeamUserListContainer";
import TeamDetailsContainer from "pages/Team/containers/TeamDetailsContainer";
import EditTeamModalContainer from "pages/Teams/containers/EditTeamModalContainer";
import {setTeamViewIds} from "modules/teamViews";
import styles from "../../TeamMember/components/TeamUserSidebar.module.css";
import TeamSidebarContent from "../components/TeamSidebarContent";

const SHOW_SIDE_PANEL_WIDTH = 1365;

function TeamContainer({
                         onSubMenuToggle,
                         isSubmenuOpened,
                         teamId,
                         team,
                         setTeamViewIds,
                         removeTeamIds
                       }) {
  const [isLoading, setLoading] = useState(true);
  const [isAddTeamModalOpen, setAddTeamModalOpen] = useState(false);
  const [editModal, setEditModal] = useState({
    isOpen: false,
    selectedTeam: null
  });

  const [isOpenDetailsSidePanel, setIsOpenDetailsSidePanel] = useState(false);
  const toggleDetailsSidePanel = useCallback(() => {
    setIsOpenDetailsSidePanel(!isOpenDetailsSidePanel);
  }, [isOpenDetailsSidePanel]);

  const [showInSidePanel, setShowInSidePanel] = useState(
    window.innerWidth < SHOW_SIDE_PANEL_WIDTH
  );

  const handleWindowResize = useCallback(
    e => {
      if (e.target.innerWidth < SHOW_SIDE_PANEL_WIDTH) {
        if (!showInSidePanel) {
          setShowInSidePanel(true);
        }
      } else {
        if (showInSidePanel) {
          setShowInSidePanel(false);
        }
      }
    },
    [showInSidePanel]
  );

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleWindowResize]);

  useEffect(() => {
    setLoading(true);
    api.teams
      .one(teamId)
      .ready.catch(e => {
      if (e.response.status === 404) {
      }
    })
      .finally(() => {
        setLoading(false);
      });
  }, [teamId]);

  const handleDisable = useCallback(
    (teamId, title) => {
      const modalId = showConfirmModal(
        MODAL_DEFAULT,
        "Are you sure you want to disable " + title + "?",
        {
          actionButtonText: "Disable",
          onAction: () => {
            updateConfirmModal(modalId, MODAL_PROCESS, "Disabling...");

            api.teams
              .disable(teamId)
              .ready.then(() => {
              api.views.teams().ready.then(response => {
                setTeamViewIds(response.data.data.map(view => view.id));
              });

              updateConfirmModal(
                modalId,
                MODAL_SUCCESS,
                "Team was successfully disabled"
              );
            })
              .catch(e => {
                const errorMessage = "Ooops, something went wrong";

                updateConfirmModal(
                  modalId,
                  MODAL_ERROR,
                  e instanceof CommonError ? e.error : errorMessage
                );
              });
          }
        }
      );
    },
    [setTeamViewIds]
  );

  const handleEnable = useCallback(
    (teamId, title) => {
      const modalId = showConfirmModal(
        MODAL_DEFAULT,
        "Are you sure you want to enabled " + title + "?",
        {
          actionButtonText: "Enable",
          onAction: () => {
            updateConfirmModal(modalId, MODAL_PROCESS, "Enabling...");

            api.teams
              .enable(teamId)
              .ready.then(() => {
              api.views.teams().ready.then(response => {
                setTeamViewIds(response.data.data.map(view => view.id));
              });

              updateConfirmModal(
                modalId,
                MODAL_SUCCESS,
                "Team was successfully enabled"
              );
            })
              .catch(e => {
                const errorMessage = "Ooops, something went wrong";

                updateConfirmModal(
                  modalId,
                  MODAL_ERROR,
                  e instanceof CommonError ? e.error : errorMessage
                );
              });
          }
        }
      );
    },
    [setTeamViewIds]
  );

  const handleDelete = useCallback(
    (teamId, title) => {
      const modalId = showConfirmModal(
        MODAL_DEFAULT,
        "Are you sure you want to delete " + title + "?",
        {
          actionButtonText: "Delete",
          onAction: () => {
            updateConfirmModal(modalId, MODAL_PROCESS, "Deleting...");

            api.teams
              .delete(teamId)
              .ready.then(response => {
              removeTeamIds(response.data.data.id);

              api.views.teams().ready.then(response => {
                setTeamViewIds(response.data.data.map(view => view.id));
              });

              updateConfirmModal(
                modalId,
                MODAL_SUCCESS,
                "Team was successfully deleted"
              );
            })
              .catch(e => {
                const errorMessage = "Ooops, something went wrong";
                updateConfirmModal(
                  modalId,
                  MODAL_ERROR,
                  e instanceof CommonError ? e.error : errorMessage,
                  {cancelButtonText: "Okay"}
                );
              });
          }
        }
      );
    },
    [removeTeamIds, setTeamViewIds]
  );

  const handleRestore = useCallback(
    (teamId, title) => {
      const modalId = showConfirmModal(
        MODAL_DEFAULT,
        "Are you sure you want to restore " + title + "?",
        {
          actionButtonText: "Restore",
          onAction: () => {
            updateConfirmModal(modalId, MODAL_PROCESS, "Restoring...");

            api.teams
              .restore(teamId)
              .ready.then(response => {
              removeTeamIds(response.data.data.id);

              api.views.teams().ready.then(response => {
                setTeamViewIds(response.data.data.map(view => view.id));
              });

              updateConfirmModal(
                modalId,
                MODAL_SUCCESS,
                "Team was successfully restored"
              );
            })
              .catch(e => {
                const errorMessage = "Ooops, something went wrong";

                updateConfirmModal(
                  modalId,
                  MODAL_ERROR,
                  e instanceof CommonError ? e.error : errorMessage
                );
              });
          }
        }
      );
    },
    [removeTeamIds, setTeamViewIds]
  );

  const handleEdit = useCallback(
    selectedTeam => {
      setEditModal({
        isOpen: !editModal.isOpen,
        selectedTeam: selectedTeam || editModal.selectedTeam
      });
    },
    [editModal]
  );

  const toggleEditModal = useCallback(() => {
    handleEdit(null);
  }, [handleEdit]);

  const handleActionDropdown = useCallback(
    (team, action) => {
      switch (action) {
        case "edit":
          handleEdit(team);
          break;
        case "enable":
          handleEnable(team.id, team.title || team.description);
          break;
        case "disable":
          handleDisable(team.id, team.title || team.description);
          break;
        case "restore":
          handleRestore(team.id, team.title || team.description);
          break;
        case "delete":
          handleDelete(team.id, team.title || team.description);
          break;
        default:
          break;
      }
    },
    [handleEdit, handleEnable, handleDisable, handleRestore, handleDelete]
  );

  const toggleAddTeamModal = useCallback(() => {
    setAddTeamModalOpen(!isAddTeamModalOpen);
  }, [isAddTeamModalOpen]);

  if (isLoading) {
    return (
      <Row fullHeight>
        <Col align="center" justified>
          <Preloader size="32" color="#807d7f"/>
        </Col>
      </Row>
    );
  }

  return (
    <Row fullHeight column nowrap>
      <AddTeamUserModalContainer
        isOpen={isAddTeamModalOpen}
        toggle={toggleAddTeamModal}
        teamId={team.id}
      />
      <Col auto>
        <TeamHeader>
          <TeamNavPanel
            handleActionDropdown={handleActionDropdown}
            teamActionItems={teamActionItems}
            onSubMenuToggle={onSubMenuToggle}
            isSubmenuOpened={isSubmenuOpened}
            team={team}
          />
          <Col auto>
            <TeamCard
              enabled={team.enabled}
              toggleAddTeamModal={toggleAddTeamModal}
              deletedAt={team.deletedAt}
              title={team && team.title}
              avatar={team.avatar}
              color={team && team.color}
              toggleDetailsSidePanel={toggleDetailsSidePanel}
              onSubMenuToggle={onSubMenuToggle}
            />
          </Col>
        </TeamHeader>
      </Col>
      <Row fullHeight>
        <Col flex justified>
          <Row fullHeight style={{width: "100%"}}>
            <Col justified>
              <TeamUserListContainer teamId={team.id}/>
            </Col>
            {showInSidePanel ? (
              <SidePanel
                isOpen={isOpenDetailsSidePanel}
                onClose={toggleDetailsSidePanel}
                width={320}
                noPadding
              >
                <Scrollbar>
                  <div className={styles.sidebar}>
                    <TeamSidebarContent>
                      <TeamDetailsContainer
                        isPrivate={team.private}
                        enabled={team.enabled}
                        deletedAt={team.deletedAt}
                        updatedAt={team.updatedAt}
                        createdAt={team.createdAt}
                        showInSidePanel={showInSidePanel}
                        isOpenDetailsSidePanel={isOpenDetailsSidePanel}
                        toggleDetailsSidePanel={toggleDetailsSidePanel}
                      />
                    </TeamSidebarContent>
                  </div>
                </Scrollbar>
              </SidePanel>
            ) : (
              <div className={styles.sidebar}>
                <Scrollbar>
                  <TeamSidebarContent>
                    <TeamDetailsContainer
                      isPrivate={team.private}
                      enabled={team.enabled}
                      deletedAt={team.deletedAt}
                      updatedAt={team.updatedAt}
                      createdAt={team.createdAt}
                      showInSidePanel={showInSidePanel}
                      isOpenDetailsSidePanel={isOpenDetailsSidePanel}
                      toggleDetailsSidePanel={toggleDetailsSidePanel}
                    />
                  </TeamSidebarContent>
                </Scrollbar>
              </div>
            )}
          </Row>
        </Col>
      </Row>
      <EditTeamModalContainer
        isOpen={editModal.isOpen}
        toggle={toggleEditModal}
        team={editModal.selectedTeam}
      />
    </Row>
  );
}

const mapStateToProps = (state, props) => {
  return {
    team: state.entities.teams[props.teamId],
  };
};

const mapDispatchToProps = dispatch => ({
  removeTeamIds: id => dispatch(removeTeamIds(id)),
  setTeamViewIds: ids => dispatch(setTeamViewIds(ids))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamContainer);
