import { useCallback, useState, useMemo } from "react";
import axios from "axios";

export default function usePagination(
  fetch,
  fetchParams,
  pageSize = 30,
  onRequest
) {
  const [nextLoading, setNextLoading] = useState(true);
  const [nextCursor, setNextCursor] = useState(null);
  const [hasMoreNextItems, setHasMoreNextItems] = useState(true);

  const load = useCallback(
    (cursor = null) => {
      setNextLoading(true);

      if (cursor) {
        fetchParams.cursor = cursor;
      } else {
        setNextCursor(null);
        setHasMoreNextItems(true);
      }

      const request = fetch(fetchParams);

      onRequest && onRequest(request, cursor);

      return {
        ready: request.ready
          .then(response => {
            const count = response.data.data.length;

            if (count < pageSize) {
              setHasMoreNextItems(false);
            }

            setNextCursor(response.data.cursor.next);
            setNextLoading(false);

            return response;
          })
          .catch(error => {
            if (!axios.isCancel(error)) {
              setNextLoading(false);
            }
          }),
        cancelRequest: request.cancelRequest
      };
    },
    // eslint-disable-next-line
    [fetchParams]
  );

  return useMemo(() => {
    return {
      load,
      nextLoading,
      nextCursor,
      hasMoreNextItems
    };
  }, [load, nextLoading, nextCursor, hasMoreNextItems]);
}
