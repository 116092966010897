import React from "react";
import styles from "./index.module.css";

function LastCaseMessageEmail({ content, caseItemSubject }) {
  return (
    <>
      {caseItemSubject && (
        <span className={styles.content}>{caseItemSubject}</span>
      )}

      {!caseItemSubject && <span className={styles.content}>no subject</span>}
    </>
  );
}

export default LastCaseMessageEmail;
