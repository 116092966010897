import React from "react";
import DateTime from "components/DateTime";
import { useSelector } from "react-redux";
import {
  getCurrentUser,
  getSalesCard
} from "pages/SettingsSalesCards/selectors";
import { salesCardActionItems } from "helpers/salesCards";
import { TableRow, TableColumn, TableColumnMenu } from "design-system-react";
import UserContact from "components/UserContact";

function TableRowContainer({ cardId, handleActionDropdown }) {
  const card = useSelector(getSalesCard(cardId));
  const currentTeamMember = useSelector(getCurrentUser);
  const canManage = currentTeamMember.id === card.teamMember.id;

  return (
    <TableRow>
      <TableColumn justified>
        <a href={card.link} target="_blank" rel="noopener noreferrer">
          {card.link}
        </a>
      </TableColumn>
      <TableColumn>
        <UserContact
          name={card.teamMember.name}
          nameColor={card.teamMember.nameColor}
          avatar={card.teamMember.avatar}
          link={"/team-member/" + card.teamMember.id}
        />
      </TableColumn>
      <TableColumn>
        <DateTime time={card.createdAt} />
      </TableColumn>
      <TableColumnMenu
        items={canManage ? salesCardActionItems() : []}
        onItemClick={key => {
          handleActionDropdown(card.id, key);
        }}
      />
    </TableRow>
  );
}

export default TableRowContainer;
