import { useState, useEffect, useCallback, useMemo } from "react";
import { throttle, debounce } from "lodash";

function ScrollableList({ rowCount, rowHeight, scrollbarRef, children }) {
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(0);

  const calcCurrentPage = useCallback((rowHeight, rowsPerPage, scrollTop) => {
    const pageHeight = rowHeight * rowsPerPage;
    return Math.floor(scrollTop / pageHeight);
  }, []);

  const handleScroll = useCallback(
    e => {
      const page = calcCurrentPage(rowHeight, rowsPerPage, e.target.scrollTop);

      if (currentPage !== page) {
        setCurrentPage(page);
      }
    },
    // eslint-disable-next-line
    [currentPage, rowHeight, rowsPerPage]
  );

  const handleResize = useCallback(() => {
    if (scrollbarRef.current) {
      const count = Math.round(scrollbarRef.current.clientHeight / rowHeight);
      const page = calcCurrentPage(
        rowHeight,
        count,
        scrollbarRef.current.scrollTop
      );

      setRowsPerPage(count);

      if (currentPage !== page) {
        setCurrentPage(page);
      }
    }
    // eslint-disable-next-line
  }, [scrollbarRef, rowHeight, currentPage]);

  useEffect(() => {
    const handler = debounce(handleResize, 100);
    window.addEventListener("resize", handler);

    return () => {
      window.removeEventListener("resize", handler);
    };
  }, [handleResize]);

  useEffect(() => {
    const scrollbarNode = scrollbarRef.current;

    if (scrollbarNode) {
      const handler = throttle(handleScroll, 70, {
        leading: false,
        trailing: true
      });

      scrollbarNode.addEventListener("scroll", handler);

      return () => {
        if (scrollbarNode) {
          scrollbarNode.removeEventListener("scroll", handler);
        }
      };
    }
  }, [scrollbarRef, handleScroll, handleResize]);

  useEffect(() => {
    const scrollbarNode = scrollbarRef.current;

    if (scrollbarNode) {
      const count = Math.round(scrollbarNode.clientHeight / rowHeight);
      setRowsPerPage(count);
    }
  }, [scrollbarRef, rowHeight]);

  const visibleItems = useMemo(() => {
    const items = [];

    let heightBefore = 0;
    let heightAfter = 0;

    if (rowsPerPage) {
      for (let i = 0; i < rowCount; i++) {
        const itemPage = Math.floor(i / rowsPerPage);

        if (itemPage < currentPage - 1) {
          heightBefore += rowHeight;
        } else if (itemPage > currentPage + 1) {
          heightAfter += rowHeight;
        } else {
          items.push(children(i));
        }
      }

      items.unshift(<div key="top" style={{ height: heightBefore }} />);
      items.push(<div key="bottom" style={{ height: heightAfter }} />);
    }

    return items;
    // eslint-disable-next-line
  }, [currentPage, rowsPerPage, children]);

  return <>{visibleItems}</>;
}

export default ScrollableList;
