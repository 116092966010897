import React from "react";
import { Avatar, Tooltip } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { extractFirstLetters } from "utils/string";
import classNames from "classnames";
import styles from "pages/Cases/components/CaseContact.module.css";
import View from "components/View";
import { printAvailability } from "helpers/teamMembers";

function CaseListItemEndUser({
  collapsed,
  avatar,
  channel,
  name,
  color,
  ownerTeamMember,
  userAid,
  renderMessage
}) {
  const classes = classNames(styles.wrapper, collapsed && styles.isCollapsed);

  return (
    <div className={classes}>
      <div className={styles.avatar}>
        <Avatar
          size={48}
          style={{
            color: "#fff",
            backgroundColor: color || "#000"
          }}
          src={avatar}
        >
          {extractFirstLetters(name, 2)}
        </Avatar>
      </div>
      <div className={styles.content}>
        <div className={styles.name}>
          <span>
            {name || `User #${userAid}`}
            {ownerTeamMember && (
              <View padding="6px" display="inline">
                <Tooltip
                  title={
                    "Owner: " +
                    ownerTeamMember.name +
                    " (" +
                    printAvailability(ownerTeamMember.availability) +
                    ")"
                  }
                >
                  <UserOutlined />
                </Tooltip>
              </View>
            )}
          </span>
        </div>
        <div className={styles.message}>{renderMessage}</div>
      </div>
    </div>
  );
}

export default CaseListItemEndUser;
