import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { api, setToken } from "../../utils/client";
import { setUserId } from "../../modules/auth";
import { Formik } from "formik";
import { FormError } from "../../utils/formError";
import CreateNewPasswordForm from "./components/CreateNewPasswordForm";
import CreateNewPasswordFormContainer from "./containers/CreateNewPasswordFormContainer";
import { ALERT_ERROR, showAlert } from "../../utils/alertManager";
import history from "../../utils/history";

function CreateNewPassword({ match, setUserId }) {
  const [checkLoading, setCheckLoading] = useState(true);
  const token = match.params.token;

  const handleSubmit = useCallback(
    (values, { setErrors, setSubmitting }) => {
      // if (!values.password.match(/^[a-z0-9_-]{8,50}$/)) {
      //   setErrors({
      //     password: "Password is not valid!"
      //   });
      //   setSubmitting(false);
      //   return;
      // }

      if (values.password !== values.confirmPassword) {
        setErrors({ confirmPassword: "These passwords don't match!" });
        setSubmitting(false);
        return;
      }

      api.teamMembers
        .confirmPassword(values.token, values.password)
        .ready.then(response => {
          const email = response.data.data.email;

          api.teamMembers
            .signIn(email, values.password, true)
            .ready.then(response => {
              const id = response.data.data.id;
              const token = response.data.data.token;

              localStorage.setItem("token", token);
              localStorage.setItem("userId", id);

              setToken(token);
              setUserId(id);
            })
            .finally(() => {
              setSubmitting(false);
            });
        })
        .catch(e => {
          if (e instanceof FormError) {
            setErrors(e.errors);

            if (e.errors.invitationToken) {
              showAlert(ALERT_ERROR, "Invitation token is not valid");
            }
          }
          setSubmitting(false);
        });
    },
    [setUserId]
  );

  // invalid token
  useEffect(() => {
    api.teamMembers
      .checkPasswordToken(token)
      .ready.catch(() => {
        history.push({
          pathname: "/invalid-token"
        });
      })
      .finally(() => {
        setCheckLoading(false);
      });
  }, [token]);

  if (checkLoading) {
    return null;
  }

  return (
    <CreateNewPasswordForm>
      <Formik
        initialValues={{
          password: "",
          confirmPassword: "",
          token: match.params.token
        }}
        onSubmit={handleSubmit}
        component={CreateNewPasswordFormContainer}
      />
    </CreateNewPasswordForm>
  );
}

const mapDispatchToProps = dispatch => ({
  setUserId: id => dispatch(setUserId(id))
});

export default connect(
  null,
  mapDispatchToProps
)(CreateNewPassword);
