import React from "react";
import styles from "./MessageFormAttachments.module.css";
import PropTypes from "prop-types";

function MessageFormAttachments({ children, onCloseClick }) {
  return (
    <div className={styles.attachments}>
      <div className={styles.close} onClick={onCloseClick}>
        Remove files
      </div>
      {children}
    </div>
  );
}

MessageFormAttachments.propTypes = {
  onCloseClick: PropTypes.func.isRequired
};

export default MessageFormAttachments;
