import { schema } from "normalizr";

export const message = new schema.Entity("messages");
export const view = new schema.Entity("views");
export const messageContent = new schema.Entity("messageContents");
export const endUser = new schema.Entity("endUsers");
export const role = new schema.Entity("roles");
export const extraField = new schema.Entity("extraFields");
export const channel = new schema.Entity("channels");
export const integration = new schema.Entity("integrations");
export const salesCardSetup = new schema.Entity("salesCardSetup");
export const autoResponse = new schema.Entity("autoResponses");
export const team = new schema.Entity("teams");
export const file = new schema.Entity("files");
export const notification = new schema.Entity("notifications");
export const quickReply = new schema.Entity("quickReplies");
export const teamMember = new schema.Entity("teamMembers", {
  role: role,
  teams: [team]
});
export const conversation = new schema.Entity("conversations", {
  channel: channel
});
export const caseItem = new schema.Entity("cases", {
  conversation: conversation,
  assignedTeamMember: teamMember,
  endUser: endUser,
  lastMessage: message,
  team: team
});
export const caseEvent = new schema.Entity("caseEvents", {
  message: message,
  initiatorTeamMember: teamMember,
  targetTeamMember: teamMember,
  targetTeam: team,
  oldTeam: team
});
export const salesCard = new schema.Entity("salesCard", {
  salesCardSetup: salesCardSetup
});

message.define({
  teamMember,
  endUser,
  cases: caseItem,
  messageContents: [messageContent],
  casesEvents: [caseEvent]
});
