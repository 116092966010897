import React, { useRef, useState, useCallback } from "react";
import connect from "react-redux/es/connect/connect";
import { Formik } from "formik";
import {
  Button,
  Popup,
  PopupHeader,
  PopupBody,
  PopupFooter
} from "design-system-react";
import CreateTeamFormContainer from "./CreateTeamFormContainer";
import { addTeamsId } from "modules/pages/teams";
import { api } from "utils/client";
import { FormError } from "utils/formError";

function TeamsCreateModalContainer({
  isOpen,
  toggle,
  addTeamsId,
  view,
  isLoading,
  emailInput
}) {
  const form = useRef(null);
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const initialValues = {
    title: "",
    color: ""
  };

  const handleSubmit = useCallback(
    (values, { setSubmitting, setErrors, resetForm }) => {
      api.teams
        .create(values)
        .ready.then(response => {
          if (view === "enabled" || view === "all") {
            addTeamsId(response.data.data.id);
          }
          resetForm({
            title: "",
            color: ""
          });
          toggle();
        })
        .catch(e => {
          if (e instanceof FormError) {
            setErrors(e.errors);
          }
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [toggle, view, addTeamsId]
  );

  if (isLoading) {
    toggle = null;
  }

  return (
    <Formik
      enableReinitialize
      ref={form}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      render={props => (
        <Popup isOpen={isOpen} onClose={toggle}>
          <PopupHeader>Create team</PopupHeader>
          <PopupBody>
            <CreateTeamFormContainer
              {...props}
              setSubmitDisabled={setSubmitDisabled}
              submitDisabled={submitDisabled}
              innerEmailInputRef={node => (emailInput = node)}
            />
          </PopupBody>
          <PopupFooter>
            <Button
              onClick={props.submitForm}
              processing={props.isSubmitting}
              disabled={submitDisabled}
            >
              Create
            </Button>
            <Button outline={true} onClick={toggle}>
              Cancel
            </Button>
          </PopupFooter>
        </Popup>
      )}
    />
  );
}

const mapDispatchToProps = dispatch => ({
  addTeamsId: id => dispatch(addTeamsId(id))
});

export default connect(
  null,
  mapDispatchToProps
)(TeamsCreateModalContainer);
