import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import TeamUserSidebar from "../components/TeamUserSidebar";
import TeamUserNavPanel from "./TeamUserNavPanel";
import TeamUserCard from "../components/TeamUserCard";
import Preloader from "components/Preloader";
import { Row, Col } from "design-system-react";
import TeamMemberCasesListContainer from "pages/TeamMember/containers/TeamMemberCasesListContainer";
import { api } from "../../../utils/client";
import useAlertRedirect from "../../../hooks/useAlertRedirect";
import { ALERT_ERROR } from "../../../utils/alertManager";
import TeamMemberHeader from "../components/TeamMemberHeader";
import {
  ROLE_ADMIN,
  ROLE_AGENT,
  ROLE_SUPER_ADMIN,
  ROLE_TEAM_MANAGER
} from "utils/permissions";

const SHOW_SIDE_PANEL_WIDTH = 1365;

function TeamMemberContainer({
  teamMemberId,
  activeCaseId,
  teamMember,
  currentUserRole,
  currentUserId,
  role
}) {
  const [loading, setLoading] = useState(true);
  const redirect = useAlertRedirect();

  useEffect(() => {
    api.teamMembers
      .one(teamMemberId)
      .ready.catch(e => {
        if (e.response.status === 404) {
          redirect(
            "/team-members/enabled",
            ALERT_ERROR,
            "Team Member not found."
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [teamMemberId, redirect]);

  const [isOpenDetailsSidePanel, setIsOpenDetailsSidePanel] = useState(false);

  const toggleDetailsSidePanel = useCallback(() => {
    setIsOpenDetailsSidePanel(
      isOpenDetailsSidePanel => !isOpenDetailsSidePanel
    );
  }, []);

  const [showInSidePanel, setShowInSidePanel] = useState(
    window.innerWidth < SHOW_SIDE_PANEL_WIDTH
  );

  const handleWindowResize = useCallback(
    e => {
      if (e.target.innerWidth < SHOW_SIDE_PANEL_WIDTH) {
        if (!showInSidePanel) {
          setShowInSidePanel(true);
        }
      } else {
        if (showInSidePanel) {
          setShowInSidePanel(false);
        }
      }
    },
    [showInSidePanel]
  );

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleWindowResize]);

  return (
    <Row fullHeight column nowrap>
      <Col auto>
        <TeamMemberHeader>
          <TeamUserNavPanel
            teamMember={teamMember}
            currentUserId={currentUserId}
          />
          {!loading && teamMember && (
            <Col auto>
              <TeamUserCard
                teamMember={teamMember}
                role={role.title}
                availability={teamMember.availability}
                isCurrentUser={teamMember.id === currentUserId}
                showActiveSwitcher={
                  currentUserId === teamMemberId ||
                  [ROLE_SUPER_ADMIN, ROLE_ADMIN].indexOf(
                    currentUserRole.name
                  ) !== -1 ||
                  (currentUserRole.name === ROLE_TEAM_MANAGER &&
                    role.name === ROLE_AGENT)
                }
                toggleDetailsSidePanel={toggleDetailsSidePanel}
              />
            </Col>
          )}
        </TeamMemberHeader>
      </Col>

      <Row fullHeight>
        <Col flex justified>
          {loading ? (
            <Col align="center" verticalAlign="center">
              <Preloader size="32" color="#807d7f" />
            </Col>
          ) : (
            teamMember && (
              <Row fullHeight style={{ width: "100%" }}>
                <Col justified>
                  <TeamMemberCasesListContainer
                    activeCaseId={activeCaseId}
                    teamMemberId={teamMemberId}
                  />
                </Col>
                <TeamUserSidebar
                  userAid={teamMember.aid}
                  teams={teamMember.teams}
                  userExtraFields={teamMember.extraFields}
                  role={role.title}
                  email={teamMember.email}
                  pin={teamMember.pin}
                  status={teamMember.status}
                  added={teamMember.createdAt}
                  updated={teamMember.updatedAt}
                  lastActivityAt={teamMember.lastActivityAt}
                  showInSidePanel={showInSidePanel}
                  isOpenDetailsSidePanel={isOpenDetailsSidePanel}
                  toggleDetailsSidePanel={toggleDetailsSidePanel}
                />
              </Row>
            )
          )}
        </Col>
      </Row>
    </Row>
  );
}

const mapStateToProps = (state, props) => {
  const currentTeamMember = state.entities.teamMembers[state.auth.userId];
  const teamMember = state.entities.teamMembers[props.teamMemberId];

  return {
    teamMember,
    role: teamMember && state.entities.roles[teamMember.role],
    currentUserId: state.auth.userId,
    currentUserRole: teamMember && state.entities.roles[currentTeamMember.role]
  };
};

export default connect(mapStateToProps)(TeamMemberContainer);
