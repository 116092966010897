import React from "react";
import PropTypes from "prop-types";
import styles from "./HomePageLayout.module.css";

function HomePageLayout({ renderContent }) {
  return <div className={styles.layout}>{renderContent}</div>;
}

HomePageLayout.propTypes = {
  renderContent: PropTypes.node
};

export default HomePageLayout;
