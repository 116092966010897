import React from "react";
import PropTypes from "prop-types";
import styles from "./NavPanelContentItem.module.css";

function NavPanelContentItem({ children }) {
  return <div className={styles.item}>{children}</div>;
}

NavPanelContentItem.propTypes = {
  children: PropTypes.node.isRequired
};

export default NavPanelContentItem;
