import React, { useState, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import {
  SmartReplyContainer as Container,
  SmartReply
} from "design-system-react";
import { api } from "../../../utils/client";
import {
  subscribe,
  unsubscribe,
  WS_EVENT_SMART_REPLY_CREATED
} from "../../../utils/websocket";

function SmartReplyContainer({
  caseId,
  lastMessageByEndUser,
  onSmartReplySelect
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [replies, setReplies] = useState([]);

  const handleWebsocketMessage = useCallback(
    data => {
      if (data.data.attributes.casesId === caseId) {
        setReplies(data.data.attributes.content);
      }

      setIsOpen(true);
    },
    [caseId]
  );

  useEffect(() => {
    subscribe(WS_EVENT_SMART_REPLY_CREATED, handleWebsocketMessage);

    return () => {
      unsubscribe(WS_EVENT_SMART_REPLY_CREATED, handleWebsocketMessage);
    };
  }, [caseId, handleWebsocketMessage]);

  useEffect(() => {
    setReplies([]);

    let cancelRequest = null;

    if (lastMessageByEndUser) {
      const request = api.cases.smartReplies(caseId);

      request.ready.then(response => {
        if (response.data.data) {
          setReplies(response.data.data.attributes.content);

          setIsOpen(true);
        }
      });

      cancelRequest = request.cancelRequest;
    }

    return () => {
      if (cancelRequest) {
        cancelRequest.cancel();
      }
    };
  }, [caseId, lastMessageByEndUser]);

  useEffect(() => {
    if (!lastMessageByEndUser) {
      setIsOpen(false);
    }
  }, [lastMessageByEndUser]);

  return (
    <Container isOpen={isOpen}>
      {replies.map(reply => (
        <SmartReply
          onClickReply={() => {
            setIsOpen(false);

            onSmartReplySelect(reply.answer);
          }}
        >
          {reply.answer}
        </SmartReply>
      ))}
    </Container>
  );
}

const mapStateToProps = (state, props) => {
  const caseItem = state.entities.cases[props.caseId];

  return {
    lastMessageByEndUser: Boolean(
      caseItem && caseItem.lastMessage && caseItem.lastMessage.endUser
    )
  };
};

export default connect(mapStateToProps)(SmartReplyContainer);
