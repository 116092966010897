import React from "react";
import styles from "./ProfileButtonWrapper.module.css";
import classNames from "classnames";

function ProfileButtonWrapper({ children, active }) {
  return (
    <div
      className={classNames(
        styles.profileButtonWrapper,
        active && styles.active
      )}
    >
      {children}
    </div>
  );
}

export default ProfileButtonWrapper;
