import React, {useState, useEffect, useRef, useCallback} from "react";
import connect from "react-redux/es/connect/connect";
import {createSelector} from "reselect";
import {Formik} from "formik";
import InviteFormContainer from "./InviteFormContainer";
import {
  Popup,
  PopupBody,
  PopupHeader,
  PopupFooter,
  Button
} from "design-system-react";
import {api} from "../../../utils/client";
import {FormError} from "../../../utils/formError";
import {
  addTeamMemberId,
  setTeamIds,
  setRoleIds
} from "../../../modules/pages/teamMembers";
import {ALERT_SUCCESS, showAlert} from "utils/alertManager";

function CreateModalContainer({
                                isOpen,
                                toggle,
                                teams,
                                roles,
                                activeView,
                                setTeamIds,
                                setRoleIds,
                                addTeamMemberId
                              }) {
  const [initialSaved, setInitialSaved] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [initialValues, setInitialValues] = useState({
    email: "",
    teams: [],
    role: ""
  });
  const emailInput = useRef(null);

  useEffect(() => {
    if (isOpen) {
      api.teamMembers.teamsForInvite().ready.then(response => {
        const teamIds = response.data.data.map(team => team.id);

        if (teamIds.length === 1) {
          setInitialValues(prevInitialValues => {
            return {
              ...prevInitialValues,
              teams: [teamIds[0]]
            }
          });
        }

        setTeamIds(teamIds);
      });

      api.teamMembers.rolesForInvite().ready.then(response => {
        setRoleIds(
          response.data.data
            .filter(role => role.workingRole)
            .map(role => role.id)
        );
      });
    }
  }, [isOpen, setTeamIds, setRoleIds]);

  const handleSubmit = useCallback(
    (values, {setSubmitting, setErrors, setFieldValue}) => {
      api.teamMembers
        .invite(values)
        .ready.then(response => {
        if (activeView === "pending" || activeView === "all") {
          addTeamMemberId(response.data.data.id);
        }

        api.views.teamMembers();

        setInitialSaved(true);
        setInitialValues({
          email: "",
          teams: values.teams,
          role: values.role
        });

        setFieldValue("email", "");

        if (emailInput.current) {
          emailInput.current.focus();
        }

        showAlert(ALERT_SUCCESS, "The invitation has been sent");
      })
        .catch(e => {
          if (e instanceof FormError) {
            setErrors(e.errors);
          }
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [activeView, addTeamMemberId, emailInput]
  );

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      render={props => (
        <Popup isOpen={isOpen} onClose={toggle}>
          <PopupHeader>Invite team member</PopupHeader>
          <PopupBody>
            <InviteFormContainer
              {...props}
              submitDisabled={submitDisabled}
              setSubmitDisabled={setSubmitDisabled}
              teams={teams}
              roles={roles}
              emailInputRef={emailInput}
            />
          </PopupBody>
          <PopupFooter>
            <Button
              onClick={props.submitForm}
              processing={props.isSubmitting}
              disabled={submitDisabled}
            >
              {submitDisabled && initialSaved ? "Invited" : "Invite"}
            </Button>
            <Button outline={true} onClick={toggle}>
              Cancel
            </Button>
          </PopupFooter>
        </Popup>
      )}
    />
  );
}

const getTeams = createSelector(
  [
    state => state.entities.teams,
    state => state.pages.teamMembers.teamIds
  ],
  (teams, teamIds) => {
    return teamIds.map(id => teams[id]);
  }
);

const getRoles = createSelector(
  [state => state.entities.roles, state => state.pages.teamMembers.roleIds],
  (roles, roleIds) => {
    return roleIds.map(id => roles[id]);
  }
);

const mapStateToProps = state => {
  return {
    teams: getTeams(state),
    roles: getRoles(state)
  };
};

const mapDispatchToProps = dispatch => ({
  setTeamIds: ids => dispatch(setTeamIds(ids)),
  setRoleIds: ids => dispatch(setRoleIds(ids)),
  addTeamMemberId: id => dispatch(addTeamMemberId(id))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateModalContainer);
