import React, { useCallback, useEffect, useState } from "react";
import { Popup, PopupBody, PopupHeader } from "design-system-react";
import { api, apiUrl } from "utils/client";
import connect from "react-redux/es/connect/connect";
import { MODAL_ERROR, showConfirmModal } from "utils/modalManager";
import { createSelector } from "reselect";
import { addVkChannel } from "modules/pages/settingsVk";
import popup from "utils/popup";
import GroupItem from "components/GroupItem";
import SettingsVkModalWrap from "../components/SettingsVkModalWrap";

function SettingsVkModalAddContainer({ channels, addVkChannel }) {
  const [addModalOpen, setAddModalOpen] = useState(false);
  // const [isLoading, setLoading] = useState(false);
  const [groups, setGroups] = useState([]);
  const [addingGroups, setAddingGroups] = useState([]);
  const [addedGroups, setAddedGroups] = useState([]);

  const getVkGroups = useCallback(
    e => {
      if (e.data && e.data.code) {
        api.vk
          .groups(e.data.code)
          .ready.then(response => {
            if (response.data.length === 0) {
              showConfirmModal(
                MODAL_ERROR,
                "This account isn't an Owner or Administrator in any public group or\n" +
                  "Community. Or you have no public group or Community created yet. \n" +
                  "Get Administrator permission or create a public group first.",
                {
                  cancelButtonText: "Okay"
                }
              );
            }

            let added = response.data.filter(group => {
              let isExist = false;
              channels.map(channel => {
                if (Number(channel.channelId) === Number(group.id)) {
                  isExist = true;
                }
                return channel;
              });
              return isExist;
            });

            setGroups(response.data);
            setAddedGroups(added.map(group => group.id));
            setAddingGroups([]);

            setAddModalOpen(true);
          })
          .catch(e => {
            console.log(e);
          });
        e.source.close();
      }
    },
    [channels]
  );

  useEffect(() => {
    window.addEventListener("message", getVkGroups);
    return () => {
      window.removeEventListener("message", getVkGroups);
    };
  }, [channels, getVkGroups]);

  const toggleAddModal = useCallback(() => {
    setAddModalOpen(!addModalOpen);
  }, [addModalOpen]);

  const openGroupOAuthWindow = async groupId => {
    popup(
      apiUrl() + "/channels/vk/oauth-group?groupId=" + groupId,
      "group-oauth",
      700,
      500
    );

    await setAddingGroups(prevState => [...prevState, groupId]);

    const addVk = async e => {
      if (e.data && e.data.groupCode) {
        await api.vk
          .create({ code: e.data.groupCode, groupId })
          .ready.then(response => {
            addVkChannel(response.data.data.id);
          });

        e.source.close();

        setAddedGroups(prevState => [...prevState, groupId]);
        setAddingGroups(prevState =>
          prevState.filter(item => item !== groupId)
        );

        window.removeEventListener("message", addVk);
      }
    };

    window.addEventListener("message", addVk);
  };

  return (
    <Popup isOpen={addModalOpen} onClose={toggleAddModal}>
      <PopupHeader>Select a group</PopupHeader>
      <PopupBody>
        <SettingsVkModalWrap
        // isLoading={isLoading}
        >
          {groups.map(group => {
            return (
              <GroupItem
                key={group.id}
                avatar={group.photo_100}
                name={group.name}
                link={"https://vk.com/public" + group.id}
                isAdding={addingGroups.indexOf(group.id) !== -1}
                isAdded={addedGroups.indexOf(group.id) !== -1}
                handleAddGroup={() => openGroupOAuthWindow(group.id)}
              />
            );
          })}
        </SettingsVkModalWrap>
      </PopupBody>
    </Popup>
  );
}

const getChannels = createSelector(
  [state => state.entities.channels, state => state.pages.settingsVk.ids],
  (channels, ids) => {
    return ids.map(id => channels[id]);
  }
);

const mapStateToProps = state => {
  return { channels: getChannels(state) };
};

const mapDispatchToProps = dispatch => ({
  addVkChannel: id => dispatch(addVkChannel(id))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsVkModalAddContainer);
