import React from "react";
import history from "../../../utils/history";
import { channelSmIcon } from "../../../helpers/channelIcon";
import {
  DropdownItemWithAvatar,
  DropdownItem,
  Avatar
} from "design-system-react";
import { printChannel } from "../../../helpers/channels";

function AnalyticsChannelDropdownItem({
  channel,
  urlParams,
  selectedValue,
  makeAnalyticUrl,
  handleChange
}) {
  return (
    <DropdownItem
      onClick={e => {
        history.push(
          makeAnalyticUrl("/analytics/team-members", {
            ...urlParams,
            channel: channel.type
          })
        );
        handleChange(channel.type);
      }}
      selected={selectedValue === channel.type}
    >
      <DropdownItemWithAvatar
        renderAvatar={
          <Avatar size="size20" image={channelSmIcon(channel.type)} />
        }
      >
        {printChannel(channel.type)}
      </DropdownItemWithAvatar>
    </DropdownItem>
  );
}

export default AnalyticsChannelDropdownItem;
