import React, { useState, useCallback } from "react";
import { connect } from "react-redux";
import {
  Popup,
  PopupHeader,
  PopupBody,
  Button,
  GutterField
} from "design-system-react";
import { Formik, Field } from "formik";
import TextareaField from "../../../components/TextareaField";
import uuidv4 from "../../../utils/uuidv4";
// import { MESSAGE_STATUS_SENDING } from "../../../modules/pages/cases";
import { api } from "../../../utils/client";
import {
  addMessageId,
  addOutgoingMessage,
  removeOutgoingMessage
} from "../../../modules/pages/newcases";
import { FormError } from "utils/formError";

function NotePopupContainer({
  isOpen,
  toggle,
  caseId,

  isEndUserModal,
  addMessageId,
  // addOutgoingMessage,
  removeOutgoingMessage
}) {
  const [isDirty, setDirty] = useState(false);
  const handleSubmit = useCallback(
    (values, { setFieldValue, setErrors, setSubmitting }) => {
      const uuid = uuidv4();

      // if (!values.text) {
      //   return;
      // }

      // addOutgoingMessage(caseId, {
      //   caseId,
      //   uuid,
      //   content: values.text,
      //   isNote: true,
      //   status: MESSAGE_STATUS_SENDING,
      //   createdAt: Math.floor(new Date().getTime() / 1000)
      // });

      setSubmitting(true);

      api.cases
        .sendNote(caseId, uuid, values.text)
        .ready.then(response => {
          addMessageId(response.data.data.id);
          removeOutgoingMessage(caseId, uuid);
          setFieldValue("text", "");
          toggle();
        })
        .catch(e => {
          if (e instanceof FormError) {
            setErrors(e.errors);
          }
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [caseId, toggle, addMessageId, removeOutgoingMessage]
  );

  return (
    <Formik
      initialValues={{
        text: ""
      }}
      onSubmit={handleSubmit}
      render={props => {
        setDirty(props.dirty);
        return (
          <Popup isOpen={isOpen} isAuto={isEndUserModal} onClose={toggle}>
            <PopupHeader>Add a note</PopupHeader>
            <PopupBody>
              <form onSubmit={props.handleSubmit}>
                <GutterField>
                  <Field name="text" label="Note" component={TextareaField} />
                </GutterField>
                <Button
                  disabled={!isDirty}
                  processing={props.isSubmitting}
                  type="submit"
                >
                  Add
                </Button>
              </form>
            </PopupBody>
          </Popup>
        );
      }}
    />
  );
}

const mapDispatchToProps = dispatch => ({
  addMessageId: id => dispatch(addMessageId(id)),
  addOutgoingMessage: (caseId, message) =>
    dispatch(addOutgoingMessage(caseId, message)),
  removeOutgoingMessage: (caseId, uuid) =>
    dispatch(removeOutgoingMessage(caseId, uuid))
});

export default connect(
  null,
  mapDispatchToProps
)(NotePopupContainer);
