import React from "react";
import {
  extractFirstCharacters,
  extractFirstLetters
} from "../../utils/string";
import {
  DropdownToggleDefault,
  DropdownToggle,
  Tooltip,
  Avatar
} from "design-system-react";
import styles from "./index.module.css";

function AssignDropdownToggle({
  caseLoaded,
  teamMemberAvatar,
  teamMemberName,
  teamMemberNameColor,
  teamColor,
  teamTitle,
  filterRef,
  filter,
  onFilterChange
}) {
  return (
    <DropdownToggle nodeId="assign_dropdown_toggle">
      <DropdownToggleDefault
        filterRef={filterRef}
        filter={filter}
        onFilterChange={onFilterChange}
      >
        <div className={styles.avatars}>
          {teamTitle && (
            <div className={styles.avatar}>
              <Avatar
                size={"size24"}
                background={teamColor}
                color="#fff"
                text={extractFirstCharacters(teamTitle, 1)}
              />
            </div>
          )}

          {teamMemberName && (
            <div className={styles.avatar}>
              <Avatar
                image={teamMemberAvatar}
                text={extractFirstLetters(teamMemberName, 1)}
                color={teamMemberNameColor}
                size={"size24"}
              />
            </div>
          )}

          {!teamTitle && !teamMemberName && (
            <div className={styles.avatar}>
              <Avatar
                image={"/images/unassigned-person.svg"}
                size={"size24"}
                noGradient
              />
            </div>
          )}
        </div>

        <div className={styles.label}>
          {!teamTitle && !teamMemberName && "Unassigned"}
          {teamMemberName || teamTitle}
        </div>
      </DropdownToggleDefault>
      <Tooltip size="sm" position="bottom">
        Case assignee
      </Tooltip>
    </DropdownToggle>
  );
}

export default AssignDropdownToggle;
