import React, { useState, useMemo, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { connectToWebsocket, subscribe, unsubscribe } from "utils/websocket";
import Menu from "components/Menu";
import { Container } from "design-system-react";
import { api } from "../utils/client";
import { setEndUserExtraFieldIds } from "../modules/endUserExtraFields";
import { setTeamMemberExtraFieldIds } from "../modules/teamMemberExtraFields";
import { setTeamMemberViewIds } from "../modules/teamMemberViews";
import { setEndUserViewIds } from "../modules/endUserViews";
import { setTeamViewIds } from "../modules/teamViews";
import { setCaseViewIds } from "../modules/caseViews";
import { setCounters } from "../modules/caseCounters";
import { setQuickReplyIds } from "../modules/quickReplies";
import AlertContainer from "../containers/AlertContainer";
import ModalContainer from "../containers/ModalContainer";
import {
  WS_EVENT_CASES_COUNTER_CREATED,
  WS_EVENT_ALERT
} from "../utils/websocket";
import { showAlert } from "../utils/alertManager";
import { ToggableLayoutContextProvider } from "../components/ToggableLayout/ToggableLayoutContext";
import { setNotifications } from "../modules/notifications";
import { setCaseUnread } from "../modules/cases";
import { setPermissions } from "../utils/permissions";
import useNotifications from "../hooks/useNotifications";

const submenuOpened = localStorage.getItem("showSubMenu");

function Notifications() {
  useNotifications();

  return null;
}

function AppLayout({
  userId,
  activeNavMenuItem,
  children,
  match,
  setTeamMemberExtraFieldIds,
  setEndUserExtraFieldIds,
  setTeamMemberViewIds,
  setEndUserViewIds,
  setTeamViewIds,
  setCaseViewIds,
  setQuickReplyIds,
  setNotifications,
  setCounters,
  setCaseUnread
}) {
  const [loading, setLoading] = useState(true);
  const [isSubmenuOpened, setIsSubmenuOpened] = useState(
    submenuOpened ? submenuOpened !== "false" : true
  );

  if (match.path) {
    if (
      match.path === "/teams/:view(\\w+)" ||
      match.path === "/team-members/:view(\\w+)"
    ) {
      localStorage.setItem("teamMembersRoute", match.url);
    } else {
      localStorage.setItem(match.path, match.url);
    }
  }

  const toggleSubmenu = useCallback(() => {
    const value = !isSubmenuOpened;
    localStorage.setItem("showSubMenu", value.toString());
    setIsSubmenuOpened(value);
  }, [isSubmenuOpened]);

  const submenuContextValue = useMemo(() => {
    return {
      isSubmenuOpened,
      toggleSubmenu
    };
  }, [isSubmenuOpened, toggleSubmenu]);

  const handleCasesCounter = useCallback(
    data => {
      if (
        match.path === "/cases/:view/:case" &&
        match.params.case === data.casesId
      ) {
        if (data.casesCounterType === "read") {
          setCounters(data.counters);
        } else {
          api.cases.read(data.casesId);
        }
      } else {
        setCounters(data.counters);

        if (data.casesCounterType === "default") {
          setCaseUnread(data.casesId, true);
        }
      }
    },
    [match, setCounters, setCaseUnread]
  );

  const handleAlert = useCallback(data => {
    showAlert(data.type, data.message, 100000);
  }, []);

  useEffect(() => {
    connectToWebsocket();
  }, []);

  useEffect(() => {
    subscribe(WS_EVENT_CASES_COUNTER_CREATED, handleCasesCounter);

    return () => {
      unsubscribe(WS_EVENT_CASES_COUNTER_CREATED, handleCasesCounter);
    };
  }, [handleCasesCounter]);

  useEffect(() => {
    subscribe(WS_EVENT_ALERT, handleAlert);

    return () => {
      unsubscribe(WS_EVENT_ALERT, handleAlert);
    };
  }, [handleAlert]);

  const fetchUserPermissions = useCallback(() => {
    return api.permissions.all().ready.then(response => {
      setPermissions(response.data);

      return response;
    });
  }, []);

  const fetchCurrentUser = useCallback(() => {
    return api.teamMembers.one(userId).ready;
  }, [userId]);

  const fetchTeamMemberExtraFields = useCallback(() => {
    return api.extraFields.teamMembers().ready.then(response => {
      setTeamMemberExtraFieldIds(response.data.data.map(view => view.id));
      return response;
    });
  }, [setTeamMemberExtraFieldIds]);

  const fetchEndUserExtraFields = useCallback(() => {
    return api.extraFields.endUsers().ready.then(response => {
      setEndUserExtraFieldIds(response.data.data.map(view => view.id));
      return response;
    });
  }, [setEndUserExtraFieldIds]);

  const fetchCaseViews = useCallback(() => {
    return api.views.cases().ready.then(response => {
      setCaseViewIds(response.data.data.map(view => view.id));
      return response;
    });
  }, [setCaseViewIds]);

  const fetchTeamMemberViews = useCallback(() => {
    return api.views.teamMembers().ready.then(response => {
      setTeamMemberViewIds(response.data.data.map(view => view.id));
      return response;
    });
  }, [setTeamMemberViewIds]);

  const fetchEndUserViews = useCallback(() => {
    return api.views.endUsers().ready.then(response => {
      setEndUserViewIds(response.data.data.map(view => view.id));
      return response;
    });
  }, [setEndUserViewIds]);

  const fetchTeamViews = useCallback(() => {
    return api.views.teams().ready.then(response => {
      setTeamViewIds(response.data.data.map(view => view.id));
      return response;
    });
  }, [setTeamViewIds]);

  const fetchQuickReplies = useCallback(() => {
    return api.quickReplies.all().ready.then(response => {
      setQuickReplyIds(response.data.data.map(view => view.id));
      return response;
    });
  }, [setQuickReplyIds]);

  const fetchCaseCounters = useCallback(() => {
    return api.cases.counters().ready.then(response => {
      setCounters(response.data);
      return response;
    });
  }, [setCounters]);

  const fetchNotifications = useCallback(() => {
    return api.notifications.all().ready.then(response => {
      setNotifications(
        response.data.data.map(notification => ({
          groupId: notification.id,
          notificationId: notification.id
        }))
      );
      return response;
    });
  }, [setNotifications]);

  useEffect(() => {
    if (loading) {
      fetchCurrentUser().then(() => {
        Promise.all([
          fetchUserPermissions(),
          fetchCaseViews(),
          fetchTeamMemberExtraFields(),
          fetchEndUserExtraFields(),
          fetchEndUserViews(),
          fetchTeamMemberViews(),
          fetchTeamViews()
        ]).then(() => {
          fetchQuickReplies();
          fetchCaseCounters();
          fetchNotifications();

          setLoading(false);
        });
      });
    }
    // eslint-disable-next-line
  }, [loading]);

  if (loading) {
    return (
      <div
        className="loading-container fade"
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <ToggableLayoutContextProvider value={submenuContextValue}>
      <Notifications />
      <Menu activeNavMenuItem={activeNavMenuItem} />
      <Container fluid gutterLeft={60} gutterRight={0} fullHeight>
        {children}
      </Container>
      <AlertContainer />
      <ModalContainer />
    </ToggableLayoutContextProvider>
  );
}

const mapStateToProps = state => {
  return {
    userId: state.auth.userId
  };
};

const mapDispatchToProps = dispatch => ({
  setTeamMemberExtraFieldIds: ids => dispatch(setTeamMemberExtraFieldIds(ids)),
  setEndUserExtraFieldIds: ids => dispatch(setEndUserExtraFieldIds(ids)),
  setEndUserViewIds: ids => dispatch(setEndUserViewIds(ids)),
  setTeamMemberViewIds: ids => dispatch(setTeamMemberViewIds(ids)),
  setTeamViewIds: ids => dispatch(setTeamViewIds(ids)),
  setCaseViewIds: ids => dispatch(setCaseViewIds(ids)),
  setQuickReplyIds: ids => dispatch(setQuickReplyIds(ids)),
  setNotifications: groups => dispatch(setNotifications(groups)),
  setCounters: counters => dispatch(setCounters(counters)),
  setCaseUnread: (caseId, value) => dispatch(setCaseUnread(caseId, value))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppLayout);
