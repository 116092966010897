import React from "react";
import { Field } from "formik";
import InputField from "components/InputField";
import SelectColorField from "components/SelectColorField";
import { Row, Col, GutterField } from "design-system-react";
import useFormSubmission from "hooks/useFormSubmission";
import CheckboxField from "components/CheckboxField";

function CreateTeamForm({
  submitDisabled,
  setSubmitDisabled,
  handleSubmit,
  dirty
}) {
  const handleFormSubmit = useFormSubmission(submitDisabled, handleSubmit);

  if (!dirty) {
    if (!submitDisabled) {
      setSubmitDisabled(true);
    }
  } else if (submitDisabled) {
    setSubmitDisabled(false);
  }

  return (
    <form onSubmit={handleFormSubmit}>
      <GutterField>
      <Row>
        <Col gutterRight={10}>
          <Field name="title" label="Team name" component={InputField} />
        </Col>
        <Col gutterLeft={10}>
          <Field name="color" label="Color" component={SelectColorField} />
        </Col>
      </Row>
      </GutterField>
      <Field name="private" labelText="Private team" component={CheckboxField} />
    </form>
  );
}

export default CreateTeamForm;
