import { useCallback } from "react";
import { connect } from "react-redux";
import { extractFirstLetters } from "utils/string";
import DetailUser from "pages/Cases/components/DetailUser";
import DetailUserAvatar from "pages/Cases/components/DetailUserAvatar";
import DetailUserName from "pages/Cases/components/DetailUserName";
import DetailUserQualification from "pages/Cases/components/DetailUserQualification";
import { channelSmIcon } from "helpers/channelIcon";
import { Link } from "react-router-dom";
import { Avatar, GhostInput } from "design-system-react";
import { api } from "../../../utils/client";
import {
  ALERT_ERROR,
  ALERT_SUCCESS,
  showAlert
} from "../../../utils/alertManager";
import { FormError } from "../../../utils/formError";
import EditableField from "../../../components/EditableField";
import { Space } from "antd";
import EndUserOwnerInput from "../../../components/EndUserOwnerInput";

function DetailUserContainer({ endUser }) {
  const handleQualificationChange = useCallback(
    qualification => {
      api.endUsers
        .changeQualification(endUser.id, qualification)
        .ready.then(() => {
          showAlert(
            ALERT_SUCCESS,
            "Qualification has been changed to " + qualification
          );
        })
        .catch(() => {
          showAlert(ALERT_ERROR, "Something bad happened");
        });
    },
    [endUser.id]
  );

  const handleFieldUpdate = useCallback(
    (value, { setErrors, setSuccess }) => {
      api.endUsers
        .update(endUser.id, { name: value })
        .ready.then(() => {
          setSuccess(true);
          showAlert(ALERT_SUCCESS, "Name was successfully changed");
        })
        .catch(e => {
          if (e instanceof FormError) {
            setErrors(e.errors);
          }
        });
    },
    [endUser.id]
  );

  return (
    <DetailUser>
      <DetailUserAvatar>
        <Link to={"/end-user/" + endUser.id}>
          <Avatar
            image={endUser.avatar}
            channel={channelSmIcon(endUser.channelType)}
            text={extractFirstLetters(endUser.name, 2)}
            color={endUser.titleColor}
            size={"size100"}
          />
        </Link>
      </DetailUserAvatar>
      <DetailUserName>
        <EditableField
          name="name"
          initialValue={endUser.name || endUser.aid}
          component={GhostInput}
          componentProps={{
            style: {
              fontSize: "18px",
              textAlign: "center",
              paddingLeft: "28px"
            }
          }}
          onUpdate={(value, { setErrors, setSuccess }) => {
            handleFieldUpdate(value, { setErrors, setSuccess });
          }}
        />
      </DetailUserName>

      <Space direction="vertical">
        <DetailUserQualification
          qualification={endUser.qualification}
          size="sm"
          onQualificationChange={handleQualificationChange}
        />
        <EndUserOwnerInput
          endUserId={endUser.id}
          selectedTeam={endUser.ownerTeam}
          selectedTeamMember={endUser.ownerTeamMember}
        />
      </Space>
    </DetailUser>
  );
}

const mapStateToProps = (state, props) => {
  return {
    endUser: state.entities.endUsers[props.endUserId]
  };
};

export default connect(mapStateToProps)(DetailUserContainer);
