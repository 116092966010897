import React from "react";
import DateTime from "components/DateTime";
import { TableRow, TableColumn, TableColumnMenu } from "design-system-react";
import connect from "react-redux/es/connect/connect";
import { integrationActionItems } from "helpers/integrations";

function PipedriveListItemContainer({ integration, handleActionDropdown }) {
  return (
    <TableRow>
      <TableColumn justified>{integration.info.companyName}</TableColumn>
      <TableColumn>
        <DateTime time={integration.createdAt} />
      </TableColumn>
      <TableColumnMenu
        items={integrationActionItems()}
        onItemClick={key => {
          handleActionDropdown(integration.id, key);
        }}
      />
    </TableRow>
  );
}

const mapStateToProps = (state, props) => {
  return { integration: state.entities.integrations[props.integrationId] };
};

export default connect(mapStateToProps)(PipedriveListItemContainer);
