export const CHANNEL_TYPE_TELEGRAMBOT = "telegrambot";
export const CHANNEL_TYPE_VK = "vk";

export const CASE_NEW = "new";
export const CASE_OPEN = "open";
export const CASE_CLOSED = "closed";

export function getCaseStatusColor(status) {
  switch (status) {
    case CASE_NEW:
      return "#FF4D4F";
    case CASE_OPEN:
      return "#FAAD14";
    case CASE_CLOSED:
      return "#52C41A";
    default:
      return "";
  }
}

export function getCaseStatusTitle(status) {
  switch (status) {
    case CASE_NEW:
      return "New";
    case CASE_OPEN:
      return "Open";
    case CASE_CLOSED:
      return "Closed";
    default:
      return status;
  }
}

export function caseActionItems() {
  return [
    { key: "assign", label: "Assign to me" },
    { key: "close", label: "Close" }
  ];
  //
  // switch (status) {
  //   case TEAM_MEMBER_STATUS_ENABLED:
  //     return [
  //       { key: "disable", label: "Disable" },
  //       { key: "edit", label: "Edit" },
  //       { key: "delete", label: "Delete" }
  //     ];
  //   case TEAM_MEMBER_STATUS_DISABLED:
  //     return [
  //       { key: "enable", label: "Enable" },
  //       { key: "edit", label: "Edit" },
  //       { key: "delete", label: "Delete" }
  //     ];
  //   case TEAM_MEMBER_STATUS_PENDING:
  //     return [
  //       { key: "edit", label: "Edit" },
  //       { key: "delete", label: "Delete" }
  //     ];
  //   default:
  //     return [];
  // }
}

export const casesCounter = (view, counters) => {
  let counter = 0;

  if (view === "unassigned") {
    counter = counters["unassigned"];
  } else if (view === "assigned_to_me") {
    counter = counters["assigned"];
  }

  return counter;
};

export const casesCounterColor = view => {
  let color = "";

  if (view === "unassigned") {
    color = "rgb(192, 37, 70)";
  } else if (view === "assigned_to_me") {
    color = "rgb(202, 173, 40)";
  }

  return color;
};

export const renderFilePictureLink = extUploaded => {
  switch (extUploaded) {
    case "webp":
      return "/images/files/Image_40.svg";
    // doc
    case "doc":
      return "/images/files/DOC_40.svg";
    case "docx":
      return "/images/files/DOC_40.svg";
    case "txt":
      return "/images/files/TXT_40.svg";
    // exel
    case "xls":
      return "/images/files/XLS_40.svg";
    case "xlsx":
      return "/images/files/XLS_40.svg";
    case "csv":
      return "/images/files/CSV_40.svg";
    // power point
    case "ppt":
      return "/images/files/PPT_40.svg";
    case "pptx":
      return "/images/files/PPT_40.svg";
    // audio
    case "mp3":
      return "/images/files/Audio_40.svg";
    case "mpga":
      return "/images/files/Audio_40.svg";
    case "ac3":
      return "/images/files/Audio_40.svg";
    case "wav":
      return "/images/files/Audio_40.svg";
    case "wma":
      return "/images/files/Audio_40.svg";
    case "ogg":
      return "/images/files/Audio_40.svg";
    case "oga":
      return "/images/files/Audio_40.svg";
    case "opus":
      return "/images/files/Audio_40.svg";
    case "aac":
      return "/images/files/Audio_40.svg";
    case "flac":
      return "/images/files/Audio_40.svg";
    // video
    case "mkv":
      return "/images/files/Video_40.svg";
    case "mp4":
      return "/images/files/Video_40.svg";
    case "MOV":
      return "/images/files/Video_40.svg";
    case "qt":
      return "/images/files/Video_40.svg";
    case "flv":
      return "/images/files/Video_40.svg";
    case "mov":
      return "/images/files/Video_40.svg";
    case "avi":
      return "/images/files/Video_40.svg";
    case "webm":
      return "/images/files/Video_40.svg";
    case "pdf":
      return "/images/files/PDF_40.svg";
    case "zip":
      return "/images/files/ZIP_40.svg";
    default:
      return "/images/files/All_other_40.svg";
  }
};

export const renderFilePictureUploadLink = extUploaded => {
  switch (extUploaded) {
    // doc
    case "doc":
      return "/images/files/doc_24.svg";
    case "docx":
      return "/images/files/doc_24.svg";
    case "txt":
      return "/images/files/txt_24.svg";
    // exel
    case "xls":
      return "/images/files/xls_24.svg";
    case "xlsx":
      return "/images/files/xls_24.svg";
    case "csv":
      return "/images/files/csv_24.svg";
    // power point
    case "ppt":
      return "/images/files/ppt_24.svg";
    case "pptx":
      return "/images/files/ppt_24.svg";
    // audio
    case "mp3":
      return "/images/files/audio_24.svg";
    case "mpga":
      return "/images/files/audio_24.svg";
    case "ac3":
      return "/images/files/audio_24.svg";
    case "wav":
      return "/images/files/audio_24.svg";
    case "wma":
      return "/images/files/audio_24.svg";
    case "ogg":
      return "/images/files/audio_24.svg";
    case "oga":
      return "/images/files/audio_24.svg";
    case "aac":
      return "/images/files/audio_24.svg";
    case "flac":
      return "/images/files/audio_24.svg";
    // video
    case "mp4":
      return "/images/files/video_24.svg";
    case "qt":
      return "/images/files/video_24.svg";
    case "MOV":
      return "/images/files/video_24.svg";
    case "avi":
      return "/images/files/video_24.svg";
    case "bin":
      return "/images/files/video_24.svg";
    case "flv":
      return "/images/files/video_24.svg";
    case "mkv":
      return "/images/files/video_24.svg";
    case "pdf":
      return "/images/files/pdf_24.svg";
    case "zip":
      return "/images/files/zip_24.svg";
    default:
      return "/images/files/all-other_24.svg";
  }
};
