import { Row, Col } from "design-system-react";
import CaseListNavPanelContainer from "pages/Cases/containers/CaseListNavPanelContainer";
import CaseListContainer from "pages/Cases/containers/CaseListContainer";
import { api } from "utils/client";
import useInfinitePagination from "hooks/useInfinitePagination";

function CasesContainer({
  activeCaseId,
  activeViewName,
  toggleSubmenu,
  isSubmenuOpened,
  filter,
  selectedCases,
  bulkActions,
  onFilter,
  onBulkActionsToggle,
  onSelect
}) {
  const pagination = useInfinitePagination({
    query: api.cases.query
  });

  return (
    <Row column fullHeight>
      <Col auto>
        <CaseListNavPanelContainer
          activeCaseId={activeCaseId}
          activeViewName={activeViewName}
          onCollapseButtonClick={toggleSubmenu}
          isSubmenuOpened={isSubmenuOpened}
          filter={filter}
          bulkActions={bulkActions}
          pagination={pagination}
          selectedCases={selectedCases}
          onFilter={onFilter}
          onBulkActionsToggle={onBulkActionsToggle}
        />
      </Col>
      <Col justified column>
        <CaseListContainer
          activeCaseId={activeCaseId}
          activeViewName={activeViewName}
          filter={filter}
          bulkActions={bulkActions}
          pagination={pagination}
          selectedCases={selectedCases}
          onSelect={onSelect}
        />
      </Col>
    </Row>
  );
}

export default CasesContainer;
