import React, { useState, useCallback, useEffect } from "react";
import AnalyticsNavPanelContainer from "./AnalyticsNavPanelContainer";
import AnalyticsCardsContainer from "pages/AnalyticsCases/containers/AnalyticsCardsContainer";
import { Row, Col, Scrollbar } from "design-system-react";
import { api } from "../../../utils/client";

function AnalyticsContainer({
  onSubMenuToggle,
  view,
  isSubmenuOpened,
  urlParams
}) {
  const [loadingData, setLoadingData] = useState(false);
  const [loadingChannelData, setLoadingChannelData] = useState(false);
  const [data, setData] = useState(null);
  const [channelData, setChannelData] = useState(null);

  const handlePeriodChange = useCallback((from, to) => {}, []);

  useEffect(() => {
    setLoadingData(true);
    api.cases
      .analytics(
        urlParams.period,
        urlParams.channel,
        urlParams.periodFrom,
        urlParams.periodTo
      )

      .ready.then(response => {
        setData(response.data.data);
      })

      .finally(() => {
        setLoadingData(false);
      });
  }, [
    urlParams.period,
    urlParams.channel,
    urlParams.periodFrom,
    urlParams.periodTo
  ]);

  useEffect(() => {
    setLoadingChannelData(true);
    api.cases
      .analyticsData(urlParams.period, urlParams.periodFrom, urlParams.periodTo)

      .ready.then(response => {
        setChannelData(response.data.data);
      })
      .finally(() => {
        setLoadingChannelData(false);
      });
  }, [urlParams.period, urlParams.periodFrom, urlParams.periodTo]);

  return (
    <Row fullHeight column>
      <Col auto>
        <AnalyticsNavPanelContainer
          onSubMenuToggle={onSubMenuToggle}
          isSubmenuOpened={isSubmenuOpened}
          activeView={view}
          onPeriodChange={handlePeriodChange}
          loading={loadingData}
          urlParams={urlParams}
        />
      </Col>
      <Col justified>
        <Scrollbar>
          <Row fullHeight column nowrap>
            <AnalyticsCardsContainer
              loadingData={loadingData}
              loadingChannelData={loadingChannelData}
              data={data}
              channelData={channelData}
              urlParams={urlParams}
            />
          </Row>
        </Scrollbar>
      </Col>
    </Row>
  );
}

export default AnalyticsContainer;
