// import {createSelector} from "@reduxjs/toolkit";
// import {denormalize} from 'normalizr';
// import {candidateScheme} from "schema";

export const getPageState = state => state.pages.settingsSalesCards;

export const getSalesCard = cardId => state => state.entities.salesCard[cardId];

export const getCurrentUser = state =>
  state.entities.teamMembers[state.auth.userId];

// export const getCandidates = createSelector([
//     state => state.entities.candidates,
//     state => state.entities.stages,
//     state => state.pages.candidates.candidatesIds
// ], (candidates, stages, ids) => {
//     // return ids.map(id => {
//     return denormalize(ids,
//         [candidateScheme],
//         {
//             candidates,
//             stages,
//         })
//     // });
// });
//
// export const getStages = createSelector([
//     state => state.entities.stages,
//     state => state.pages.candidates.stagesIds
// ], (stages, ids) => ids.map(id => stages[id]));
//
// export const getCandidatesPageState = state => state.pages.candidates;
