import { useCallback, useState } from "react";
import { connect } from "react-redux";
import NavPanel from "components/NavPanel";
import NavPanelContent from "components/NavPanel/NavPanelContent";
import CollapseButton from "components/NavPanel/CollapseButton";
import CollapseMenu from "../../../components/CollapseMenu";
import { casesCounter, casesCounterColor } from "../../../helpers/cases";
import { createSelector } from "reselect";
import {
  Button,
  Select,
  Dropdown,
  Menu,
  Space,
  Typography,
  Modal,
  message,
  Tooltip
} from "antd";
import {
  FilterOutlined,
  EllipsisOutlined,
  ExclamationCircleOutlined,
  CloseOutlined,
  InfoCircleOutlined
} from "@ant-design/icons";
import FilterModal, {
  dropdownOperators,
  nonEmptyDropdownOperators,
  OPERATOR_IN,
  OPERATOR_IS,
  OPERATOR_IS_NOT
} from "components/filterModal/FilterModal";
import SelectTeamMemberInput from "components/selectTeamMemberInput/SelectTeamMemberInput";
import { api } from "utils/client";
import AssignInput from "components/assignInput/AssignInput";
import uuidv4 from "utils/uuidv4";
import SelectTeamInput from "components/selectTeamInput/SelectTeamInput";

function url(caseId, viewName) {
  return caseId ? "/cases/" + viewName + "/" + caseId : "/cases/" + viewName;
}

function filterButtonText(short, count) {
  if (count > 0) {
    return short ? "(" + count + ")" : "Filters (" + count + ")";
  }

  if (short) {
    return;
  }

  return "Filters";
}

const caseFilterFields = {
  team: {
    label: "Team",
    operators: dropdownOperators,
    renderInput: operator => {
      let input = null;

      switch (operator) {
        case OPERATOR_IS:
        case OPERATOR_IS_NOT:
          input = <SelectTeamInput />;
          break;

        case OPERATOR_IN:
          input = <SelectTeamInput mode="multiple" />;
          break;

        default:
          return null;
      }

      return input;
    }
  },
  assignedTeamMember: {
    label: "Asignee",
    operators: dropdownOperators,
    renderInput: operator => {
      let input = null;

      switch (operator) {
        case OPERATOR_IS:
        case OPERATOR_IS_NOT:
          input = <SelectTeamMemberInput />;
          break;

        case OPERATOR_IN:
          input = <SelectTeamMemberInput mode="multiple" />;
          break;

        default:
          return null;
      }

      return input;
    }
  },
  status: {
    label: "Status",
    operators: nonEmptyDropdownOperators,
    renderInput: operator => {
      let input = null;

      const caseStatuses = [
        {
          label: "New",
          value: "new"
        },
        {
          label: "Open",
          value: "open"
        },
        {
          label: "Closed",
          value: "closed"
        }
      ];

      switch (operator) {
        case OPERATOR_IS:
        case OPERATOR_IS_NOT:
          input = <Select options={caseStatuses} />;
          break;

        case OPERATOR_IN:
          input = <Select options={caseStatuses} mode="multiple" />;
          break;

        default:
          return null;
      }

      return input;
    }
  }
};

function CaseListNavPanelContainer({
  views,
  activeCaseId,
  activeViewName,
  activeViewTitle,
  isSubmenuOpened,
  counters,
  pagination,
  selectedCases,
  onCollapseButtonClick,
  filter,
  bulkActions,
  onFilter,
  onBulkActionsToggle
}) {
  const [visibleFilterModal, setVisibleFilterModal] = useState(false);

  const showConfirmModal = useCallback((onOk, selectedCasesCount) => {
    if (selectedCasesCount === 0) {
      message.info("Please select at least one case");

      return;
    }

    Modal.confirm({
      title: selectedCasesCount + " case(s) will be moved. Are you sure?",
      icon: <ExclamationCircleOutlined />,
      // content: 'Bla bla ...',
      okText: "Confirm",
      cancelText: "Cancel",
      onOk
    });
  }, []);

  const performAction = useCallback(
    request => {
      const id = uuidv4();

      message.loading({
        content: "Action in progress...",
        key: id
      });

      request.ready
        .then(response => {
          message.success({
            content: response.data + " case(s) was moved successfully!",
            key: id
          });
        })
        .catch(() => {
          message.error({
            content: "Something went wrong.",
            key: id
          });
        });

      onBulkActionsToggle();
    },
    [onBulkActionsToggle]
  );

  return (
    <NavPanel borderBottom>
      <FilterModal
        title={"Filter cases"}
        visible={visibleFilterModal}
        filter={filter}
        entityFields={caseFilterFields}
        onCancel={() => {
          setVisibleFilterModal(false);
        }}
        onFilter={values => {
          setVisibleFilterModal(false);
          onFilter(values);
        }}
      />
      <NavPanelContent style={{ paddingRight: "12px" }}>
        <CollapseButton
          handleClick={onCollapseButtonClick}
          isOpen={isSubmenuOpened}
        />
        {bulkActions && activeCaseId ? (
          <></>
        ) : (
          <CollapseMenu
            title={activeViewTitle}
            counter={pagination.total}
            withoutDropdown={isSubmenuOpened}
            items={views.map(view => {
              let active = activeViewName === view.name,
                to = {
                  pathname: url(activeCaseId, view.name),
                  state: {
                    fromSubMenu: true
                  }
                };

              return {
                id: view.id,
                to,
                active,
                title: view.title,
                counter: view.count,
                notificationCounter: casesCounter(view.name, counters),
                notificationCounterColor: casesCounterColor(view.name)
              };
            })}
          />
        )}
      </NavPanelContent>
      <NavPanelContent>
        <Space>
          {!bulkActions && (
            <>
              {activeViewName === "all" && (
                <Button
                  type={filter.filters.length > 0 ? "primary" : "default"}
                  icon={<FilterOutlined />}
                  onClick={() => {
                    setVisibleFilterModal(true);
                  }}
                >
                  {filterButtonText(
                    Boolean(activeCaseId),
                    filter.filters.length
                  )}
                </Button>
              )}
              {activeViewName !== "closed" && (
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item
                        onClick={() => {
                          onBulkActionsToggle(true);
                        }}
                      >
                        Bulk actions
                      </Menu.Item>
                    </Menu>
                  }
                  placement={"bottomRight"}
                  trigger={["click"]}
                >
                  <Button icon={<EllipsisOutlined />} />
                </Dropdown>
              )}
            </>
          )}
          {bulkActions && (
            <>
              {!activeCaseId && (
                <Typography.Text type="secondary">
                  <Tooltip
                    placement="bottom"
                    title="You can select up to 30 cases simultaneously"
                  >
                    <InfoCircleOutlined />
                  </Tooltip>{" "}
                  Selected: {selectedCases.length} case(s){" "}
                </Typography.Text>
              )}
              <AssignInput
                width={activeCaseId ? 180 : 215}
                collapsed={Boolean(activeCaseId)}
                placeholder="Select action"
                prependOptions={[
                  {
                    label: (
                      <div style={{ pointerEvents: "none" }}>
                        Back to unassigned
                      </div>
                    ),
                    value: "unassign",
                    onSelect: () => {
                      showConfirmModal(() => {
                        performAction(api.cases.bulkUnassign(selectedCases));
                      }, selectedCases.length);
                    }
                  },
                  {
                    label: (
                      <div style={{ pointerEvents: "none" }}>Close all</div>
                    ),
                    value: "close",
                    onSelect: () => {
                      showConfirmModal(() => {
                        performAction(api.cases.bulkClose(selectedCases));
                      }, selectedCases.length);
                    }
                  }
                ]}
                onTeamsRequest={() => {
                  return api.cases.teamsForAssign().ready.then(response => {
                    return response.data.data;
                  });
                }}
                onTeamMembersRequest={teamId => {
                  return api.cases
                    .membersForAssignByTeam(teamId)
                    .ready.then(response => {
                      return response.data.data;
                    });
                }}
                onChange={({ team, teamMember }) => {
                  if (team) {
                    showConfirmModal(() => {
                      performAction(
                        api.cases.bulkAssign(
                          selectedCases,
                          team && team.id,
                          teamMember && teamMember.id
                        )
                      );
                    }, selectedCases.length);
                  }
                }}
              />
              <Button
                type="primary"
                onClick={() => {
                  onBulkActionsToggle(false);
                }}
                icon={activeCaseId ? <CloseOutlined /> : null}
                danger
              >
                {activeCaseId ? null : "Cancel"}
              </Button>
            </>
          )}
        </Space>
      </NavPanelContent>
    </NavPanel>
  );
}

const getViews = createSelector(
  [state => state.entities.views, state => state.caseViews.ids],
  (views, ids) => {
    return ids.map(id => views[id]);
  }
);

const mapStateToProps = (state, props) => {
  let activeView = null;

  state.caseViews.ids.map(id => {
    const view = state.entities.views[id];
    if (view.name === props.activeViewName) {
      activeView = view;
    }
    return id;
  });

  return {
    activeViewTitle: activeView && activeView.title,
    views: getViews(state),
    counters: state.caseCounters.counters
  };
};

export default connect(mapStateToProps)(CaseListNavPanelContainer);
