import React from "react";
import styles from "pages/Cases/components/MessageListItem.module.css";
import classNames from "classnames";
import PropTypes from "prop-types";

function MessageListItem({
  id,
  reverse,
  firstInTimeGroup,
  lastInTimeGroup,
  children
}) {
  const classes = classNames(
    styles.message,
    reverse && styles.reverse,
    firstInTimeGroup && styles.firstInTimeGroup,
    lastInTimeGroup && styles.lastInTimeGroup
  );

  return (
    <div id={id} className={classes}>
      {children}
    </div>
  );
}

MessageListItem.propTypes = {
  reverse: PropTypes.bool,
  firstInTimeGroup: PropTypes.bool,
  lastInTimeGroup: PropTypes.bool
};

MessageListItem.defalutProps = {
  reverse: false,
  firstInTimeGroup: false,
  lastInTimeGroup: false
};

export default MessageListItem;
