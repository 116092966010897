import React from "react";
import styles from "./MessageFormNoteButton.module.css";
import PropTypes from "prop-types";
import { Tooltip } from "design-system-react";

function MessageFormNoteButton({ onClick }) {
  return (
    <div className={styles.button} onClick={onClick}>
      <div className={styles.buttonWrapper}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
        >
          <path
            id="Rounded_Rectangle_1555_copy"
            data-name="Rounded Rectangle 1555 copy"
            className="cls-1"
            d="M1489,2684h0v10a5,5,0,0,1-5,5h-10a5,5,0,0,1-5-5v-10h0a4.776,4.776,0,0,1,.08-0.76A4.984,4.984,0,0,1,1474,2679h11v0.1a5.01,5.01,0,0,1,3.9,3.9h0.1v1Zm-5,14a4,4,0,0,0,4-4v-10h-2a2.006,2.006,0,0,1-2-2v-2h-10a3.989,3.989,0,0,0-3.93,3.35,3.5,3.5,0,0,0-.07.65v10a4,4,0,0,0,4,4h10Zm1-17.86V2682a1,1,0,0,0,1,1h1.86A3.983,3.983,0,0,0,1485,2680.14Zm-12,12.36a0.5,0.5,0,0,1,.5-0.5h7a0.5,0.5,0,0,1,0,1h-7A0.5,0.5,0,0,1,1473,2692.5Zm0-3a0.5,0.5,0,0,1,.5-0.5h11a0.5,0.5,0,0,1,0,1h-11A0.5,0.5,0,0,1,1473,2689.5Zm7.5-2.5h-7a0.5,0.5,0,0,1,0-1h7A0.5,0.5,0,0,1,1480.5,2687Z"
            transform="translate(-1469 -2679)"
          />
        </svg>
        <Tooltip size="sm" position="top" offsetX={-40} withDelay>
          Add Internal Note
        </Tooltip>
      </div>
    </div>
  );
}

MessageFormNoteButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default MessageFormNoteButton;
