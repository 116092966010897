import React from "react";
import styles from "./AvailabilityInputs.module.css";
import GroupError from "components/GroupError";

function AvailabilityInputs({ children, error }) {
  return (
    <>
      <div className={styles.inputs}>{children}</div>
      {error && <GroupError>{error}</GroupError>}
    </>
  );
}

export default AvailabilityInputs;
