import React, { Component } from "react";
import {
  SHOW_MODAL,
  subscribe,
  unsubscribe,
  UPDATE_MODAL
} from "utils/modalManager";
import { Modal } from "design-system-react";

export const SUCCESS_DELAY = 2000; //ms
export const HIDE_DELAY = 400; //ms

class ModalContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modals: []
    };

    subscribe(this, SHOW_MODAL, this.handleShow);
    subscribe(this, UPDATE_MODAL, this.handleUpdate);
  }

  componentWillUnmount() {
    unsubscribe(this, SHOW_MODAL, this.handleShow);
    unsubscribe(this, UPDATE_MODAL, this.handleUpdate);
  }

  handleShow = data => {
    this.setState(prevState => ({
      modals: [
        ...prevState.modals,
        {
          id: data["id"],
          status: data["status"],
          message: data["message"],
          cancelButtonText: "Cancel",
          actionButtonText: "Confirm",
          retryButtonText: "Try again",
          successButtonText: "Got it",
          ...data["params"],
          isOpen: true
        }
      ]
    }));
  };

  handleUpdate = data => {
    this.setState(prevState => {
      return {
        modals: prevState.modals.map(modal => {
          if (modal.id === data["id"]) {
            return {
              ...modal,
              id: data["id"],
              status: data["status"],
              message: data["message"],
              ...data["params"]
            };
          }

          return modal;
        })
      };
    });
  };

  toggle = id => {
    this.setState(prevState => {
      return {
        modals: prevState.modals.map(modal => {
          if (modal.id === id && modal.status !== "process") {
            return {
              ...modal,
              isOpen: !modal.isOpen
            };
          }

          return modal;
        })
      };
    });
  };

  render() {
    const { modals } = this.state;

    if (modals.length === 0) {
      return null;
    }

    const modal = modals[modals.length - 1];
    const isRetry = modal.message === "Ooops, something went wrong";

    return (
      <Modal
        isOpen={modal.isOpen}
        status={modal.status}
        actionButtonText={modal.actionButtonText}
        retryButtonText={modal.retryButtonText}
        cancelButtonText={modal.cancelButtonText}
        successButtonText={modal.successButtonText}
        onClose={() => {
          this.toggle(modal.id);
        }}
        onRetry={isRetry && modal.onAction} // same as action
        onAction={modal.onAction}
      >
        {modal.message}
      </Modal>
    );
  }
}

export default ModalContainer;
