import React, { useState, useCallback } from "react";
import SettingsChannelHeader from "components/SettingsHeader";
import TelegramListContainer from "./TelegramListContainer";
import AddModalContainer from "./AddModalContainer";
import { Row, Col, Container } from "design-system-react";

function SettingsTelegramContainer() {
  const [addModalOpen, setAddModalOpen] = useState(false);

  const toggleAddModal = useCallback(() => {
    setAddModalOpen(!addModalOpen);
  }, [addModalOpen]);

  return (
    <Row fullHeight column>
      <Container>
        <SettingsChannelHeader
          title="Telegram"
          logo="/images/telegram_100.svg"
          onAction={toggleAddModal}
        >
          Communicate with your leads and customers with the help of the
          integration with Telegram and respond to messages directly in Hugery interface.
        </SettingsChannelHeader>
      </Container>
      <Col justified>
        <TelegramListContainer />
      </Col>
      <AddModalContainer
        isOpen={addModalOpen}
        toggle={toggleAddModal}
      />
    </Row>
  );
}

export default SettingsTelegramContainer;
