import React, { useState, useEffect, useCallback } from "react";
import { CASE_OPEN, fetchCase } from "modules/pages/cases";
import { connect } from "react-redux";
import axios from "axios";
import { api } from "../../../utils/client";
import { CommonError } from "../../../utils/commonError";
import history from "../../../utils/history";
import { setCaseUnread } from "../../../modules/cases";
import uuidv4 from "../../../utils/uuidv4";
import {
  addCaseId,
  addMessageId,
  removeCaseId
} from "../../../modules/pages/newcases";
import { close } from "../../../modules/pages/cases/caseItem";
import { checkCaseView } from "../../../modules/pages/cases/caseList";
import usePrevious from "../../../hooks/usePrevious";
import Case from "../components/Case";

function CaseContainer({
  caseId,
  status,
  channelType,
  activeViewName,
  activeMessageId,
  showMessageForm,
  setCaseUnread,
  onClosePanel,
  addCaseId,
  removeCaseId,
  addMessageId,
  filter,
  bulkActions
}) {
  const [loading, setLoading] = useState(null);
  const [assignLoading, setAssignLoading] = useState(false);
  const [isOpenDetailsSidePanel, setIsOpenDetailsSidePanel] = useState(false);
  const prevCaseId = usePrevious(caseId);

  const checkView = useCallback(
    (caseId, params) => {
      api.cases
        .checkView(caseId, {
          ...params,
          filter: filter ? filter.filters : null
        })
        .ready.then(response => {
          if (response.data === true) {
            addCaseId(caseId);
          } else if (response.data === false) {
            removeCaseId(caseId);
          }
        });
    },
    [addCaseId, removeCaseId, filter]
  );

  const handleCaseCloseClick = useCallback(() => {
    api.cases.close(uuidv4(), caseId).ready.then(() => {
      checkView(caseId, { view: activeViewName });
    });
  }, [caseId, activeViewName, checkView]);

  const handleAssign = useCallback(
    (caseId, teamId, teamMemberId) => {
      setAssignLoading(true);

      api.cases
        .assign(uuidv4(), caseId, teamId, teamMemberId)
        .ready.then(response => {
          if (caseId) addMessageId(response.data.data.id);
        })
        .finally(() => {
          setAssignLoading(false);

          checkView(caseId, { view: activeViewName });
        });
    },
    [activeViewName, addMessageId, checkView]
  );

  const handleUnassign = useCallback(
    caseId => {
      setAssignLoading(true);

      api.cases
        .unassign(uuidv4(), caseId)
        .ready.then(response => {
          addMessageId(response.data.data.id);
        })
        .finally(() => {
          setAssignLoading(false);

          checkView(caseId, { view: activeViewName });
        });
    },
    [activeViewName, addMessageId, checkView]
  );

  const toggleDetailsSidePanel = useCallback(() => {
    setIsOpenDetailsSidePanel(!isOpenDetailsSidePanel);
  }, [isOpenDetailsSidePanel]);

  if (caseId && caseId !== prevCaseId) {
    api.cases.read(caseId);
    setCaseUnread(caseId, false);
  }

  useEffect(() => {
    setLoading(true);

    const request = api.cases.one(caseId);

    request.ready
      .then(() => {
        setLoading(false);
      })
      .catch(e => {
        if (e instanceof CommonError) {
          history.push("/cases/unassigned");
        }

        if (!axios.isCancel(e)) {
          setLoading(false);
        }
      });

    return () => {
      request.cancelRequest.cancel();
    };
  }, [caseId]);

  return (
    <Case
      caseId={caseId}
      caseLoading={prevCaseId !== caseId || loading}
      activeMessageId={activeMessageId}
      assignLoading={assignLoading}
      toggleDetailsSidePanel={toggleDetailsSidePanel}
      status={status}
      channelType={channelType}
      isOpenSidePanel={isOpenDetailsSidePanel}
      toggleSidePanel={toggleDetailsSidePanel}
      showMessageForm={showMessageForm}
      bulkActions={bulkActions}
      onAssign={handleAssign}
      onUnassign={handleUnassign}
      onClose={handleCaseCloseClick}
      onClosePanel={onClosePanel}
    />
  );
}

const mapStateToProps = (state, props) => {
  const caseItem = state.entities.cases && state.entities.cases[props.caseId];
  const conversation =
    caseItem && state.entities.conversations[caseItem.conversation];
  const channel = conversation && state.entities.channels[conversation.channel];

  let showMessageForm = false;

  if (caseItem && caseItem.status === CASE_OPEN) {
    if (caseItem.assignedTeamMember === state.auth.userId) {
      showMessageForm = true;
    }
  }

  return {
    showMessageForm,
    status: caseItem && caseItem.status,
    channelType: channel && channel.type
  };
};

const mapDispatchToProps = dispatch => ({
  fetchCase: (caseId, params) => dispatch(fetchCase(caseId, params)),
  setCaseUnread: (caseId, value) => dispatch(setCaseUnread(caseId, value)),
  addCaseId: id => dispatch(addCaseId(id)),
  removeCaseId: id => dispatch(removeCaseId(id)),
  closeCase: (caseId, uuid) => dispatch(close(caseId, uuid)),
  checkCaseView: (caseId, activeViewName) =>
    dispatch(checkCaseView(caseId, activeViewName)),
  addMessageId: id => dispatch(addMessageId(id))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CaseContainer);
