import React, { useMemo } from "react";
import Rule from "pages/SettingsAutoResponse/components/Rule";
import { Field, FieldArray } from "formik";
import Conditions from "pages/SettingsAutoResponse/components/Conditions";
import Condition from "pages/SettingsAutoResponse/components/Condition";
import ConditionField from "pages/SettingsAutoResponse/components/ConditionField";
import SelectField from "components/SelectField";
import View from "components/View";
import AddConditionButton from "pages/SettingsAutoResponse/components/AddConditionButton";

const weekArray = [
  {
    label: "Monday",
    value: "monday"
  },
  {
    label: "Tuesday",
    value: "tuesday"
  },
  {
    label: "Wednesday",
    value: "wednesday"
  },
  {
    label: "Thursday",
    value: "thursday"
  },
  {
    label: "Friday",
    value: "friday"
  },
  {
    label: "Saturday",
    value: "saturday"
  },
  {
    label: "Sunday",
    value: "sunday"
  }
];

function WeekdaysRuleContainer({ weekDay }) {
  const additionalWeekdays = useMemo(() => {
    return weekArray.filter(i => {
      return weekDay.map(w => {
        if (w.weekDay === i.value) {
          i.hide = true;
        }
        return w;
      });
    });
  }, [weekDay]);

  return (
    <Rule
      title={
        <>
          <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px">
            <path d="M15.000,20.000 L5.000,20.000 C2.239,20.000 0.000,17.761 0.000,15.000 L0.000,7.000 C0.000,4.239 2.239,2.000 5.000,2.000 L5.000,0.500 C5.000,0.224 5.224,-0.000 5.500,-0.000 C5.776,-0.000 6.000,0.224 6.000,0.500 L6.000,2.000 L14.000,2.000 L14.000,0.500 C14.000,0.224 14.224,-0.000 14.500,-0.000 C14.776,-0.000 15.000,0.224 15.000,0.500 L15.000,2.000 C17.761,2.000 20.000,4.239 20.000,7.000 L20.000,15.000 C20.000,17.761 17.761,20.000 15.000,20.000 ZM19.000,7.000 C19.000,4.791 17.209,3.000 15.000,3.000 L15.000,4.500 C15.000,4.776 14.776,5.000 14.500,5.000 C14.224,5.000 14.000,4.776 14.000,4.500 L14.000,3.000 L6.000,3.000 L6.000,4.500 C6.000,4.776 5.776,5.000 5.500,5.000 C5.224,5.000 5.000,4.776 5.000,4.500 L5.000,3.000 C2.791,3.000 1.000,4.791 1.000,7.000 L1.000,15.000 C1.000,17.209 2.791,19.000 5.000,19.000 L15.000,19.000 C17.209,19.000 19.000,17.209 19.000,15.000 L19.000,7.000 ZM13.000,12.000 L15.000,12.000 L15.000,14.000 L13.000,14.000 L13.000,12.000 ZM13.000,8.000 L15.000,8.000 L15.000,10.000 L13.000,10.000 L13.000,8.000 ZM9.000,12.000 L11.000,12.000 L11.000,14.000 L9.000,14.000 L9.000,12.000 ZM9.000,8.000 L11.000,8.000 L11.000,10.000 L9.000,10.000 L9.000,8.000 ZM5.000,12.000 L7.000,12.000 L7.000,14.000 L5.000,14.000 L5.000,12.000 ZM5.000,8.000 L7.000,8.000 L7.000,10.000 L5.000,10.000 L5.000,8.000 Z" />
          </svg>
          WEEKDAYS
        </>
      }
    >
      <FieldArray
        name="weekDay"
        render={arrayHelpers => {
          return (
            <>
              {weekDay.length > 0 && (
                <Conditions>
                  {weekDay.map((item, index) => {
                    return (
                      <Condition onDelete={() => arrayHelpers.remove(index)}>
                        <ConditionField>
                          <Field
                            name={`weekDay.${index}.operator`}
                            component={SelectField}
                            placeholder="Select a condition"
                            items={[
                              { label: "Is", value: "is" },
                              { label: "Is not", value: "isNot" }
                            ]}
                          />
                        </ConditionField>
                        {weekDay[index].operator && (
                          <ConditionField>
                            <Field
                              name={`weekDay.${index}.weekDay`}
                              component={SelectField}
                              placeholder="Select value"
                              items={additionalWeekdays}
                            />
                          </ConditionField>
                        )}
                      </Condition>
                    );
                  })}
                </Conditions>
              )}
              {weekDay.length < 7 && (
                <View display="inline-flex">
                  <AddConditionButton
                    title="Add condition"
                    onClick={() =>
                      arrayHelpers.push({ operator: "", weekDay: "" })
                    }
                  />
                </View>
              )}
            </>
          );
        }}
      />
    </Rule>
  );
}

export default WeekdaysRuleContainer;
