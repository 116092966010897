import { useEffect, useCallback } from "react";
import { api } from "../../../utils/client";
import { getCaseStatusColor, getCaseStatusTitle } from "helpers/cases";
import { Tag } from "antd";
import { DateTime } from "components/DateTime";
import { channelSmIcon } from "../../../helpers/channelIcon";
import { extractFirstLetters } from "utils/string";
import history from "utils/history";
import {
  subscribe,
  unsubscribe,
  WS_EVENT_CASE_CREATED,
  WS_EVENT_CASE_UPDATED,
  WS_EVENT_MESSAGE_CREATED
} from "utils/websocket";
import Table from "../../../components/table/Table";
import InfinityScrollPagination from "../../../components/InfinityScrollPagination";
import CaseListItemEndUserContainer from "../../../containers/CaseListItemEndUserContainer";
import EntityAvatar from "components/entityAvatar/EntityAvatar";
import CollapsedCaseListItem from "../components/CollapsedCaseListItem";
import { merge } from "lodash";
import { TeamOutlined } from "@ant-design/icons";

const DEFAULT_ORDER_FIELD = "updatedAt";
const DEFAULT_ORDER_DIRECTION = "desc";

function CaseListContainer({
  activeViewName,
  activeCaseId,
  filter,
  pagination,
  selectedCases,
  onSelect,
  bulkActions
}) {
  const fetchCases = useCallback(
    (cursor = null) => {
      let params = {
        view: activeViewName,
        filter: filter && filter.filters
      };

      if (pagination.order.field) {
        params.orderField = pagination.order.field;
        params.orderDirection = pagination.order.direction;
      }

      return pagination.fetchNext(params, cursor);
    },
    // eslint-disable-next-line
    [activeViewName, pagination.order.field, pagination.order.direction, filter]
  );

  useEffect(() => {
    const request = fetchCases();

    return () => {
      request.cancelRequest.cancel();
    };
  }, [fetchCases]);

  const handleWebsocketCase = useCallback(
    data => {
      const caseId = data.id;

      //remove (no permissions)
      if (!data.aid) {
        pagination.setData(prevCases =>
          prevCases.filter(caseItem => caseItem.id !== caseId)
        );
      }

      api.cases
        .checkView(caseId, {
          view: activeViewName,
          filter: filter && filter.filters
        })
        .ready.then(response => {
          if (response.data !== true) {
            pagination.setData(prevCases =>
              prevCases.filter(caseItem => caseItem.id !== caseId)
            );
            return;
          }

          const orderDirection =
            pagination.order.direction || DEFAULT_ORDER_DIRECTION;
          const orderField = pagination.order.field || DEFAULT_ORDER_FIELD;

          let addToStart = false;
          let addToEnd = false;

          if (orderDirection === "asc") {
            addToStart =
              data[orderField] <= pagination.sorted.firstSortedFieldValue;
            addToEnd =
              !pagination.hasMoreNextItems &&
              data[orderField] >= pagination.sorted.lastSortedFieldValue;
          } else {
            addToStart =
              data[orderField] <= pagination.sorted.firstSortedFieldValue &&
              !pagination.hasMoreNextItems;
            addToEnd =
              data[orderField] >= pagination.sorted.lastSortedFieldValue;
          }

          if (addToStart || addToEnd) {
            pagination.setData(prevCases => [
              ...prevCases.filter(caseItem => caseItem.id !== caseId),
              data
            ]);
          }
        });
    },
    // eslint-disable-next-line
    [activeViewName, pagination.data, filter]
  );

  const handleWebsocketMessageCreated = useCallback(
    data => {
      if (data.cases) {
        pagination.setData(prevCases =>
          prevCases.map(caseItem => {
            if (caseItem.id === data.cases.id) {
              return merge(caseItem, data.cases);
            }

            return caseItem;
          })
        );
      }
    },
    // eslint-disable-next-line
    [pagination.data]
  );

  useEffect(() => {
    subscribe(WS_EVENT_CASE_CREATED, handleWebsocketCase);
    subscribe(WS_EVENT_CASE_UPDATED, handleWebsocketCase);
    subscribe(WS_EVENT_MESSAGE_CREATED, handleWebsocketMessageCreated);

    return () => {
      unsubscribe(WS_EVENT_CASE_CREATED, handleWebsocketCase);
      unsubscribe(WS_EVENT_CASE_UPDATED, handleWebsocketCase);
      unsubscribe(WS_EVENT_MESSAGE_CREATED, handleWebsocketMessageCreated);
    };
  }, [handleWebsocketCase, handleWebsocketMessageCreated]);

  const isCaseOpened = activeCaseId && activeCaseId.length > 0;

  const columns = isCaseOpened
    ? [
        {
          title: "Case",
          field: "id",
          minWidth: 200,
          render: (id, item) => {
            return (
              <CollapsedCaseListItem
                endUserId={item.endUser.id}
                assignedTeamMemberId={
                  item.assignedTeamMember && item.assignedTeamMember.id
                }
                lastMessageId={item.lastMessage && item.lastMessage.id}
                unread={item.unread}
                time={item.updatedAt}
                caseItemSubject={item.defaultSubject}
              />
            );
          },
          onClick: item => {
            history.push("/cases/" + activeViewName + "/" + item.id);
          }
        }
      ]
    : [
        {
          title: "Case",
          field: "id",
          minWidth: 240,
          render: (id, item) => {
            return (
              <CaseListItemEndUserContainer
                endUserId={item.endUser.id}
                lastMessageId={item.lastMessage && item.lastMessage.id}
                unread={item.unread}
                caseItemSubject={item.defaultSubject}
              />
            );
          },
          onClick: item => {
            history.push("/cases/" + activeViewName + "/" + item.id);
          }
        },
        {
          title: "Status",
          field: "status",
          sortable: pagination.order,
          minWidth: 110,
          maxWidth: 160,
          render: status => (
            <Tag color={getCaseStatusColor(status)}>
              {getCaseStatusTitle(status)}
            </Tag>
          )
        },
        {
          title: "Channel",
          field: "conversation",
          minWidth: 130,
          maxWidth: 160,
          render: conversation => (
            <EntityAvatar
              avatarSrc={channelSmIcon(conversation.channel.type)}
              title={conversation.channel.title}
            />
          )
        },
        {
          title: "Asignee",
          field: "assignedTeamMember",
          minWidth: 130,
          maxWidth: 160,
          render: teamMember => {
            return teamMember ? (
              <EntityAvatar
                avatarSrc={teamMember.avatar}
                avatarBackground={teamMember.nameColor}
                avatarTitle={extractFirstLetters(teamMember.name, 1)}
                title={teamMember.name}
              />
            ) : (
              <EntityAvatar title={"None"} />
            );
          },
          onClick: item => {
            if (item.assignedTeamMember) {
              history.push("/team-member/" + item.assignedTeamMember.id);
            }
          }
        },
        {
          title: "Team",
          field: "team",
          minWidth: 130,
          maxWidth: 160,
          render: team => {
            return team ? (
              <EntityAvatar
                avatarBackground={team.color}
                avatarTitle={extractFirstLetters(team.title, 1)}
                title={team.title}
              />
            ) : (
              <EntityAvatar avatarIcon={<TeamOutlined />} title={"None"} />
            );
          },
          onClick: item => {
            if (item.team) {
              history.push("/teams/" + item.team.id);
            }
          }
        },
        {
          title: "Created",
          field: "createdAt",
          render: createdAt => <DateTime time={createdAt} />,
          sortable: pagination.order,
          minWidth: 130,
          maxWidth: 160
        },
        {
          title: "Updated",
          field: "updatedAt",
          render: updatedAt => <DateTime time={updatedAt} />,
          sortable: pagination.order,
          minWidth: 130,
          maxWidth: 160
        }
      ];

  return (
    <InfinityScrollPagination
      nextLoading={pagination.nextLoading}
      hasMoreNextItems={pagination.hasMoreNextItems}
      onFetchNext={() => {
        fetchCases(pagination.nextCursor);
      }}
    >
      <Table
        columns={columns}
        data={pagination.sorted.data}
        loading={pagination.nextLoading}
        hoverable
        activeId={activeCaseId}
        fixedHeader
        selectedItems={bulkActions ? selectedCases : null}
        onSelect={onSelect}
        onSort={field => {
          pagination.setOrder(prevOrder => {
            let direction = "asc";

            if (prevOrder.field === field) {
              if (prevOrder.direction === "desc") {
                return { field: "", direction: "" };
              } else {
                direction = "desc";
              }
            }

            return { field, direction };
          });
        }}
        onStatus={item => getCaseStatusColor(item.status)}
      />
    </InfinityScrollPagination>
  );
}

export default CaseListContainer;
