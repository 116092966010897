import React from "react";
import MessageListItemBubble from "pages/Cases/components/MessageListItemBubble";
import MessageListItemTime from "pages/Cases/components/MessageListItemTime";
import MessageListItemStatus from "pages/Cases/components/MessageListItemStatus";
import MessageListItemBubbleOverlayInfo from "../../pages/Cases/containers/MessageListItemContentContainer";
import "@lottiefiles/lottie-player/dist/tgs-player";

function StickerMessageBubble({ image, status, outgoing, time }) {
  const imageExt = image.match(/[0-9a-zA-Z]+$/).toString();

  return (
    <MessageListItemBubble status={status} time={time} noBorder noPadding>
      {imageExt === "tgs" ? (
        <tgs-player
          autoplay
          loop
          mode="normal"
          src={image}
          style={{ width: "140px", height: "140px" }}
        />
      ) : (
        <img style={{ maxWidth: 300 }} src={image} alt="" />
      )}

      <MessageListItemBubbleOverlayInfo>
        <MessageListItemTime time={time} />
        {outgoing && <MessageListItemStatus status={status} />}
      </MessageListItemBubbleOverlayInfo>
    </MessageListItemBubble>
  );
}

export default StickerMessageBubble;
