import React, { useState, useEffect } from "react";
import { defineChannel } from "helpers/defineChannel";
import { connect } from "react-redux";
import { translation_default } from "helpers/translations";

let getColClass = (length, index) => {
  const schemes = {
    1: [1],
    2: [2, 2],
    3: [3, 3, 3],
    4: [2, 2, 2, 2],
    5: [3, 3, 3, 2, 2],
    6: [3, 3, 3, 3, 3, 3]
  };

  let scheme = schemes[length] || schemes[6];
  let colSize = scheme[index] || 3;

  return "column-" + colSize;
};

const disabledChannels = [
  {
    img: "/images/widget/messenger.svg",
    name: "Messenger"
  },
  {
    img: "/images/widget/whatsapp.svg",
    name: "WhatsApp"
  },
  {
    img: "/images/widget/telegram.svg",
    name: "Telegram"
  },
  {
    img: "/images/widget/viber.svg",
    name: "Viber"
  },
  {
    img: "/images/widget/vk.svg",
    name: "Vk"
  },
  {
    img: "/images/widget/email.svg",
    name: "Email"
  }
  // {
  //   img: "/images/widget/instagram.svg",
  //   name: "Instagram"
  // },
  // {
  //   img: "/images/widget/kik.svg",
  //   name: "Kik"
  // },
  // {
  //   img: "/images/widget/line.svg",
  //   name: "Line"
  // },
  // {
  //   img: "/images/widget/ok.svg",
  //   name: "Ok"
  // },
  // {
  //   img: "/images/widget/skype.svg",
  //   name: "Skype"
  // },
  // {
  //   img: "/images/widget/twitter.svg",
  //   name: "Twitter"
  // },
  // {
  //   img: "/images/widget/wechat.svg",
  //   name: "Wechat"
  // }
];

function WidgetChannelsContainer({ setupChannels, step, defaultLanguage }) {
  const [channels, setChannels] = useState(null);

  useEffect(() => {
    setupChannels.channels &&
      setupChannels.channels.length !== 0 &&
      setChannels(setupChannels.channels.filter(x => x.enabled === true));
  }, [setupChannels.channels]);

  function translate(string) {
    return translation_default[string][defaultLanguage];
  }

  return (
    <div className={`channels-wrapper-messengers ${step >= 4 && "complete"}`}>
      {step >= 4 && channels && channels.length === 0 ? null : (
        <div className="channels-wrapper-messengers-text-block">
          <div className="channels-wrapper-messengers-title">
            {translate("viaMessaging") || "Go via messaging apps"}
          </div>
          <div className="channels-wrapper-messengers-text">
            {translate("chatWithPrefered") ||
              "Chat with us in preferred messaging apps"}
          </div>
        </div>
      )}

      {/* custom channel*/}
      {/*channel add*/}

      {channels && channels.length > 0 && (
        <div className="channels-wrapper-messengers-block custom-channels">
          {channels
            .filter(i => i.type !== "blinger")
            .map((channel, index) => {
              return (
                <div
                  key={channel.type}
                  className={getColClass(channels.length, index)}
                >
                  <a
                    href={
                      (channel.type === "email" && `mailto:${channel.link}`) ||
                      channel.link
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`channels-wrapper-messenger customizer`}
                  >
                    <img src={defineChannel(channel.type).image} alt="" />
                    <div className="channels-wrapper-messenger-title">
                      {defineChannel(channel.type).name}
                    </div>
                  </a>
                </div>
              );
            })}
        </div>
      )}

      {channels && channels.length < 0 && (
        <div className="channels-wrapper-messengers-block">
          {/*example channels*/}
          {disabledChannels.map(disabledChannel => (
            <div key={disabledChannel.name} className="column-3">
              <div
                className={"channels-wrapper-messenger " + disabledChannel.name}
              >
                <img src={disabledChannel.img} alt={disabledChannel.name} />
                <div className="channels-wrapper-messenger-title">
                  {disabledChannel.name}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    step: state.widgetSetup.current.step,
    setupChannels: state.widgetSetup.current.channels
  };
};

export default connect(mapStateToProps)(WidgetChannelsContainer);
