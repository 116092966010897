import React from "react";
import WidgetHeaderTitle from "components/Widget/WidgetHeaderTitle";
import WidgetHeaderDescription from "components/Widget/WidgetHeaderDescription";
import WidgetHeader from "components/Widget/WidgetHeader";
import WidgetWelcomeContainer from "./containers/WidgetWelocmeContainer";

function WidgetWelcome() {
  return (
    <>
      <WidgetHeader>
        <WidgetHeaderTitle>Welcome your customers</WidgetHeaderTitle>
        <WidgetHeaderDescription>
          Be friendly to your audience, say something about your support and
          time of reply
        </WidgetHeaderDescription>
      </WidgetHeader>
      <WidgetWelcomeContainer />
    </>
  );
}

export default WidgetWelcome;
