import React, { useContext } from "react";
import NavPanelContent from "components/NavPanel/NavPanelContent";
import NavPanel from "components/NavPanel";
import CollapseButton from "components/NavPanel/CollapseButton";
import { TEAM_MEMBER_INVITE_PERMISSION } from "utils/permissions";
import Can from "components/Can";
import { Button } from "design-system-react";
import { connect } from "react-redux";
import CollapseMenu from "../../../components/CollapseMenu";
import { createSelector } from "reselect";
import { ToggableLayoutContext } from "../../../components/ToggableLayout/ToggableLayoutContext";

function TeamUsersNavPanelContainer({
  onSubMenuToggle,
  isSubmenuOpened,
  toggleInviteModal,
  teamMemberViews,
  teamViews,
  activeView,
  activeMenuItem,
  teamMemberView,
  teamView,
  activeViewTitle
}) {
  const context = useContext(ToggableLayoutContext);

  return (
    <NavPanel borderBottom>
      <NavPanelContent>
        <CollapseButton
          handleClick={context.toggleSubmenu}
          isOpen={context.isSubmenuOpened}
        />
        <CollapseMenu
          title={activeViewTitle}
          withoutDropdown={context.isSubmenuOpened}
          items={[
            {
              id: "teamMembers",
              label: "MEMBERS"
            },
            ...teamMemberView.map(view => {
              let active = activeMenuItem === "/team-members/" + view.name;

              return {
                id: view.id,
                to: "/team-members/" + view.name,
                active,
                title: view.title,
                counter: view.count
              };
            }),
            {
              id: "teams",
              label: "TEAMS"
            },
            ...teamView.map(view => {
              let active = activeMenuItem === "/teams/" + view.name;

              return {
                id: view.id,
                to: "/teams/" + view.name,
                active,
                title: view.title,
                counter: view.count
              };
            })
          ]}
        />
      </NavPanelContent>
      <NavPanelContent>
        <Can permission={TEAM_MEMBER_INVITE_PERMISSION}>
          <Button outline onClick={toggleInviteModal}>
            Invite
          </Button>
        </Can>
      </NavPanelContent>
    </NavPanel>
  );
}

const getTeamMemberViews = createSelector(
  [state => state.entities.views, state => state.teamMemberViews.ids],
  (views, ids) => {
    return ids.map(id => views[id]);
  }
);

const getTeamsViews = createSelector(
  [state => state.entities.views, state => state.teamViews.ids],
  (views, ids) => {
    return ids.map(id => views[id]);
  }
);

const mapStateToProps = (state, props) => {
  const teamMemberView = getTeamMemberViews(state);
  const teamView = getTeamsViews(state);

  let activeViewTitle = "";

  teamView.map(view => {
    if (props.activeMenuItem === "/teams/" + view.name) {
      activeViewTitle = view.title;
    }
    return view;
  });

  teamMemberView.map(view => {
    if (props.activeMenuItem === "/team-members/" + view.name) {
      activeViewTitle = view.title;
    }
    return view;
  });

  return {
    activeViewTitle,
    teamMemberView,
    teamView
  };
};

export default connect(mapStateToProps)(TeamUsersNavPanelContainer);
