import React from "react";
import { Avatar } from "design-system-react";
import { extractFirstLetters } from "utils/string";
import MessageListItem from "pages/Cases/components/MessageListItem";
import MessageListItemAvatar from "pages/Cases/components/MessageListItemAvatar";
import MessageListItemContentContainer from "pages/Cases/containers/MessageListItemContentContainer";
import MessageListItemBubbleWrapper from "../components/MessageListItemBubbleWrapper";
import { connect } from "react-redux";
import MessageListEventContainer from "./MessageListEventContainer";
import { DateTime } from "../../../components/DateTime";
import { TYPE_DAY } from "../../../utils/dateTime";
import MessageListDivider from "../components/MessageListDivider";
import MessageListEvent from "../components/MessageListEvent";
import MessageListEmailItem from "../components/MessageListEmailItem";
import OutgoingEmailMessageListItemContainer from "./OutgoingEmailMessageListItemContainer";
import OutgoingMessageListItemContainer from "./OutgoingMessageListItemContainer";

const TIME_FOR_GROUP_MESSAGES = 60;

function getDay(time) {
  return new Date(time * 1000).getDate();
}

function MessageListItemContainer({
  message,
  caseEventId,
  caseAid,
  channelType,
  prevMessageTime,
  nextMessageTime,
  prevMessageUserId,
  nextMessageUserId,
  highlight,
  endUser,
  endUserAvatar,
  endUserName,
  endUserTitleColor,
  endUserChannelFields,
  teamMemberAvatar,
  teamMemberName,
  teamMemberNameColor,
  currentUserId,
  caseId
}) {
  let output = [];

  if (
    !prevMessageTime ||
    getDay(prevMessageTime) !== getDay(message.createdAt)
  ) {
    output.push(
      <MessageListEvent key={message.createdAt}>
        <MessageListDivider isDate>
          <DateTime forceType={TYPE_DAY} time={message.createdAt} />
        </MessageListDivider>
      </MessageListEvent>
    );
  }

  if (caseEventId) {
    output.push(
      <MessageListEventContainer
        key={caseEventId}
        eventId={caseEventId}
        createdAt={message.createdAt}
      />
    );

    return output;
  }

  const messageUserId = message.endUser || message.teamMember || currentUserId;

  const firstInTimeGroup =
    prevMessageUserId !== messageUserId ||
    message.createdAt - prevMessageTime > TIME_FOR_GROUP_MESSAGES;

  const lastInTimeGroup =
    nextMessageUserId !== messageUserId ||
    nextMessageTime - message.createdAt > TIME_FOR_GROUP_MESSAGES;

  // outgoing message
  if (!message.id) {
    if (channelType === "email") {
      output.push(<OutgoingEmailMessageListItemContainer message={message} />);
    } else {
      output.push(
        <OutgoingMessageListItemContainer
          key={message.uuid}
          message={message}
          caseAid={caseAid}
          firstInTimeGroup={firstInTimeGroup}
          lastInTimeGroup={lastInTimeGroup}
        />
      );
    }

    return output;
  }

  if (channelType === "email") {
    output.push(
      <MessageListEmailItem
        messageContentIds={message.messageContents}
        updatedAt={message.updatedAt}
        endUserAvatar={endUserAvatar}
        endUserName={endUserName}
        endUserTitleColor={endUserTitleColor}
        teamMemberAvatar={teamMemberAvatar}
        teamMemberName={teamMemberName}
        teamMemberNameColor={teamMemberNameColor}
        outgoing={message.teamMember}
        caseId={caseId}
      />
    );
  } else {
    output.push(
      <MessageListItem
        id={message.id}
        key={message.id}
        firstInTimeGroup={firstInTimeGroup}
        lastInTimeGroup={lastInTimeGroup}
        reverse={message.teamMember || message.bot}
      >
        {message.endUser && (
          <MessageListItemAvatar>
            {lastInTimeGroup && (
              <Avatar
                image={endUserAvatar}
                text={extractFirstLetters(endUserName, 2)}
                size="size32"
                color={endUserTitleColor}
              />
            )}
          </MessageListItemAvatar>
        )}

        {message.teamMember && (
          <MessageListItemAvatar>
            {lastInTimeGroup && (
              <Avatar
                image={teamMemberAvatar}
                text={extractFirstLetters(teamMemberName, 2)}
                size="size32"
                color={teamMemberNameColor}
              />
            )}
          </MessageListItemAvatar>
        )}

        {message.bot && (
          <MessageListItemAvatar>
            {lastInTimeGroup && (
              <Avatar image="/images/avatar-bot.svg" size="size32" />
            )}
          </MessageListItemAvatar>
        )}

        <MessageListItemBubbleWrapper
          alignRight={message.teamMember || message.bot}
          highlight={highlight}
        >
          {message.messageContents &&
            message.messageContents.map(id => (
              <MessageListItemContentContainer
                key={id}
                messageContentId={id}
                channelType={channelType}
                outgoing={message.teamMember || message.bot}
                time={message.createdAt}
              />
            ))}
        </MessageListItemBubbleWrapper>
      </MessageListItem>
    );
  }

  return output;
}

const getMessage = (state, props) => {
  return typeof props.messageId === "object"
    ? props.messageId
    : state.entities.messages[props.messageId];
};

const getPrevMessage = (state, props) => {
  return typeof props.prevMessage === "object"
    ? props.prevMessage
    : state.entities.messages[props.prevMessage];
};

const getNextMessage = (state, props) => {
  return typeof props.nextMessage === "object"
    ? props.nextMessage
    : state.entities.messages[props.nextMessage];
};

const mapStateToProps = (state, props) => {
  const message = getMessage(state, props);
  const prevMessage = getPrevMessage(state, props);
  const nextMessage = getNextMessage(state, props);
  const caseEventId = message.casesEvents && message.casesEvents[0];
  const prevMessageTime = prevMessage && prevMessage.createdAt;
  const nextMessageTime = nextMessage && nextMessage.createdAt;
  const prevMessageUserId =
    prevMessage &&
    (prevMessage.endUser ||
      prevMessage.teamMember ||
      (!prevMessage.id && state.auth.userId));
  const nextMessageUserId =
    nextMessage &&
    (nextMessage.endUser ||
      nextMessage.teamMember ||
      (!nextMessage.id && state.auth.userId));
  const endUser = message.endUser && state.entities.endUsers[message.endUser];
  const teamMember =
    message.teamMember && state.entities.teamMembers[message.teamMember];

  return {
    currentUserId: state.auth.userId,
    message,
    prevMessageTime,
    nextMessageTime,
    prevMessageUserId,
    nextMessageUserId,
    caseEventId,
    endUser: endUser,
    endUserAvatar: endUser && endUser.avatar,
    endUserName: endUser && endUser.name,
    endUserTitleColor: endUser && endUser.titleColor,
    endUserChannelFields: endUser && endUser.channelFields,
    teamMemberAvatar: teamMember && teamMember.avatar,
    teamMemberName: teamMember && teamMember.name,
    teamMemberNameColor: teamMember && teamMember.nameColor
  };
};

export default connect(mapStateToProps)(React.memo(MessageListItemContainer));
