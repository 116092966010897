import { withNamespace } from "utils/namespace";

const type = withNamespace("pages/settingsVk");

export const SET_VK_CHANNELS = type("SET_VK_CHANNELS");
export const ADD_VK_CHANNEL = type("ADD_VK_CHANNEL");

let initialState = {
  ids: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_VK_CHANNELS:
      return {
        ...state,
        ids: action.payload.ids
      };
    case ADD_VK_CHANNEL:
      return {
        ...state,
        ids: [action.payload.id, ...state.ids]
      };
    default:
      return state;
  }
}

// actions

export function setVkChannels(ids) {
  return {
    type: SET_VK_CHANNELS,
    payload: {
      ids
    }
  };
}

export function addVkChannel(id) {
  return {
    type: ADD_VK_CHANNEL,
    payload: {
      id
    }
  };
}
