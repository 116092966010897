import React from "react";
import classNames from "classnames";
import { Button, Preloader, Row, Col } from "design-system-react";
import styles from "./WhatsAppModalBody.module.css";

function WhatsAppModalBody({ isLoading, qrCode, counter, retry }) {
  const imgClasses = classNames(styles.image, counter < 1 && styles.over);

  return (
    <div className={styles.container}>
      <div className={styles.desc}>
        <div className={styles.text}>
          1. Open your WhatsApp application
          <br />
          on your mobile device
          <br />
          <br />
          2. Open WhatsApp Web/Desktop <br /> settings
          <br />
          <br />
          3. Scan the QR code
        </div>
        {counter < 1 ? (
          <div className={styles.timer}>
            <span className={styles.overText}>QR code is expired</span>
            <Button processing={isLoading} disabled={isLoading} onClick={retry}>
              Get new
            </Button>
          </div>
        ) : (
          <div className={styles.timer}>
            QR code is active {counter < 10 && 0}
            {counter} sec.
          </div>
        )}
      </div>
      <div className={styles.qr}>
        {isLoading ? (
          <Row fullHeight column>
            <Col align="center" justified>
              <Preloader size="32" color="#807d7f" />
            </Col>
          </Row>
        ) : (
          <img src={qrCode} className={imgClasses} alt="QR Code" />
        )}
      </div>
    </div>
  );
}

export default WhatsAppModalBody;
