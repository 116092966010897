import React, { Component } from "react";

class NotFound extends Component {
  render() {
    return (
      <div
        style={{
          margin: "50px"
        }}
      >
        <h1
          style={{
            fontSize: "32px",
            color: "#f44336"
          }}
        >
          404
        </h1>
        <h2>Page is under construction</h2>
      </div>
    );
  }
}
export default NotFound;
