import React from "react";
import styles from "./Rule.module.css";

function Rule({ title, children }) {
  return (
    <div className={styles.rule}>
      <div className={styles.title}>{title}</div>
      <div className={styles.body}>{children}</div>
    </div>
  );
}

export default Rule;
