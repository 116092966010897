import {
  can, ROLE_TEAM_MANAGER,
  TEAM_DELETE_PERMISSION,
  TEAM_REMOVE_TEAM_MEMBER_PERMISSION,
  TEAM_RESTORE_PERMISSION,
  TEAM_UPDATE_PERMISSION
} from "../utils/permissions";

export function printTeamStatus(enabled, deleted) {
  if (deleted) {
    return "Deleted";
  }

  switch (enabled) {
    case true:
      return "Enabled";
    case false:
      return "Disabled";
    default:
      return "";
  }
}

export function teamActionItems(deleted) {
  let items = [];

  const canRestore = can(TEAM_RESTORE_PERMISSION);
  const canEdit = can(TEAM_UPDATE_PERMISSION);
  const canDelete = can(TEAM_DELETE_PERMISSION);

  if (deleted) {
    canRestore && items.push({ key: "restore", label: "Restore" });
  } else {
    canEdit && items.push({ key: "edit", label: "Edit" });
    canDelete && items.push({ key: "delete", label: "Delete" });
  }

  return items;
}

export function teamMemberActionItems(currentUserRoleName, currentUserIsMemberTeam) {
  let items = [];

  const canDelete = currentUserRoleName === ROLE_TEAM_MANAGER ? currentUserIsMemberTeam && can(TEAM_REMOVE_TEAM_MEMBER_PERMISSION) : can(TEAM_REMOVE_TEAM_MEMBER_PERMISSION);
  canDelete && items.push({ key: "delete", label: "Delete" });

  return items;
}
