import React, { useCallback } from "react";
import { connect } from "react-redux";
import {
  DropdownItem,
  DropdownItemWithAvatar,
  Avatar
} from "design-system-react";
import { extractFirstLetters } from "../utils/string";
import { message } from "antd";

function AssignDropdownMenuTeamMemberContainer({ teamMember, me, onSelect }) {
  const handleSelect = useCallback(() => {
    if (teamMember.teams.length === 1) {
      onSelect(teamMember.teams[0], teamMember.id);
    } else {
      onSelect(null, teamMember.id);
    }
  }, [teamMember, onSelect]);

  return (
    <DropdownItem
      id={me && "assign_to_me"}
      onClick={handleSelect}
      arrow={teamMember.teams.length > 1}
    >
      {teamMember.availability === "inactive" && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: "rgba(255,255,255,0.4)",
            zIndex: 1
          }}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();

            message.info("This member is inactive");
          }}
        />
      )}
      <DropdownItemWithAvatar
        renderAvatar={
          <Avatar
            image={teamMember.avatar}
            text={extractFirstLetters(teamMember.name, 1)}
            color={teamMember.nameColor}
            size={"size24"}
          />
        }
      >
        {me ? "Assign to me" : teamMember.name}
      </DropdownItemWithAvatar>
    </DropdownItem>
  );
}

const mapStateToProps = (state, props) => {
  const teamMember = state.entities.teamMembers[props.teamMemberId];

  return {
    teamMember,
    me: teamMember.id === state.auth.userId
  };
};

export default connect(mapStateToProps)(AssignDropdownMenuTeamMemberContainer);
