import { useState, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import DetailUserContainer from "pages/Cases/containers/DetailUserContainer";
import {
  Tabs,
  TabContent,
  Tab,
  GutterField,
  Input,
  Avatar,
  Scrollbar,
  SidePanel
} from "design-system-react";
import EndUserSidebarContainer from "../../EndUser/containers/EndUserSidebarContainer";
import Details from "../../../components/Details";
import DetailsHeader from "../../../components/Details/DetailsHeader";
import DetailsContent from "../../../components/Details/DetailsContent";
import Collapse from "../../../components/Collapse";
import { TYPE_FULL } from "../../../utils/dateTime";
import EndUserInputTime from "pages/EndUser/components/EndUserInputTime";
import { extractFirstCharacters, extractFirstLetters } from "utils/string";
import CaseSubjectField from "./CaseSubjectField";
import CasePriorityDropdown from "./CasePriorityDropdown";

const SHOW_SIDE_PANEL_WIDTH = 1365;

function DetailsContainer({
  caseItem,
  assignedTeamMember,
  team,
  channelLabel,
  channelTitle,
  isOpenSidePanel,
  toggleSidePanel,
  caseLoading
}) {
  const [showInSidePanel, setShowInSidePanel] = useState(
    window.innerWidth < SHOW_SIDE_PANEL_WIDTH
  );

  const handleWindowResize = useCallback(
    e => {
      if (e.target.innerWidth < SHOW_SIDE_PANEL_WIDTH) {
        if (!showInSidePanel) {
          setShowInSidePanel(true);
        }
      } else {
        if (showInSidePanel) {
          setShowInSidePanel(false);
        }
      }
    },
    [showInSidePanel]
  );

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleWindowResize]);

  function upFirst(str) {
    if (!str) return str;

    return str[0].toUpperCase() + str.slice(1);
  }

  const output = (
    <Scrollbar>
      <Details>
        {!caseLoading && (
          <>
            <DetailsHeader>
              <DetailUserContainer endUserId={caseItem.endUser} />
            </DetailsHeader>
            <DetailsContent>
              <Tabs
                activeTab={{
                  id: localStorage.getItem("activeTab") || "cases"
                }}
                sticky
                positionCenter
                onTabChange={e => {
                  localStorage.setItem("activeTab", e.id);
                }}
              >
                <Tab id="cases" title="Cases">
                  <TabContent padding={24}>
                    <Collapse header="Case details">
                      <GutterField gutterBottom={20}>
                        <Input
                          label="Case ID"
                          value={"#" + caseItem.aid}
                          size="sm"
                          disabled
                        />
                      </GutterField>
                      <GutterField gutterBottom={20}>
                        <Input
                          label="Status"
                          value={upFirst(caseItem.status)}
                          size="sm"
                          disabled
                        />
                      </GutterField>
                      {assignedTeamMember && (
                        <GutterField gutterBottom={20}>
                          <Input
                            label="Assignee"
                            appendAvatar={
                              <Avatar
                                image={assignedTeamMember.avatar}
                                color={assignedTeamMember.nameColor}
                                text={extractFirstLetters(
                                  assignedTeamMember.name,
                                  1
                                )}
                                size={"size24"}
                              />
                            }
                            value={assignedTeamMember.name}
                            size="sm"
                            disabled
                          />
                        </GutterField>
                      )}
                      {team && (
                        <GutterField gutterBottom={20}>
                          <Input
                            label="Team"
                            appendAvatar={
                              <Avatar
                                color="#fff"
                                background={team.color}
                                text={extractFirstCharacters(team.title, 1)}
                                size={"size24"}
                              />
                            }
                            value={team.title}
                            size="sm"
                            disabled
                          />
                        </GutterField>
                      )}
                      <GutterField gutterBottom={20}>
                        <Input
                          label="Created"
                          appendAfter={
                            <EndUserInputTime
                              value={caseItem.createdAt}
                              forceType={TYPE_FULL}
                            />
                          }
                          size="sm"
                          disabled
                        />
                      </GutterField>
                      <GutterField gutterBottom={20}>
                        <Input
                          label="Updated"
                          appendAfter={
                            <EndUserInputTime
                              value={caseItem.updatedAt}
                              forceType={TYPE_FULL}
                            />
                          }
                          size="sm"
                          disabled
                        />
                      </GutterField>
                      {caseItem.closedAt && (
                        <GutterField gutterBottom={20}>
                          <Input
                            label="Closed"
                            appendAfter={
                              <EndUserInputTime
                                value={caseItem.closedAt}
                                forceType={TYPE_FULL}
                              />
                            }
                            size="sm"
                            disabled
                          />
                        </GutterField>
                      )}

                      <CaseSubjectField caseItem={caseItem} upFirst={upFirst} />

                      <CasePriorityDropdown
                        caseItem={caseItem}
                        upFirst={upFirst}
                      />

                      <GutterField gutterBottom={20}>
                        <Input
                          label="Channel type"
                          value={channelLabel}
                          size="sm"
                          disabled
                        />
                      </GutterField>
                      <GutterField gutterBottom={20}>
                        <Input
                          label="Channel name"
                          value={channelTitle}
                          size="sm"
                          disabled
                        />
                      </GutterField>
                    </Collapse>
                  </TabContent>
                </Tab>
                <Tab id="endUser" title="Profile">
                  <EndUserSidebarContainer endUserId={caseItem.endUser} />
                </Tab>
              </Tabs>
            </DetailsContent>
          </>
        )}
      </Details>
    </Scrollbar>
  );

  if (showInSidePanel) {
    return (
      <SidePanel
        isOpen={isOpenSidePanel}
        onClose={toggleSidePanel}
        width={320}
        noPadding
      >
        {output}
      </SidePanel>
    );
  }

  return output;
}

function mapStateToProps(state, props) {
  if (props.caseLoading) {
    return {};
  }

  const caseItem = state.entities.cases[props.caseId];
  const conversation =
    caseItem && state.entities.conversations[caseItem.conversation];
  const channel = conversation && state.entities.channels[conversation.channel];

  return {
    caseItem,
    assignedTeamMember:
      caseItem && state.entities.teamMembers[caseItem.assignedTeamMember],
    team: caseItem && state.entities.teams[caseItem.team],
    channelLabel: channel && channel.label,
    channelTitle: channel && channel.title
  };
}

export default connect(mapStateToProps)(DetailsContainer);
