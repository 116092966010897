import React from "react";
import { Field } from "formik";
import { GutterField } from "design-system-react";
import { DropdownItemWithAvatar, Avatar } from "design-system-react";
import {
  extractFirstCharacters,
  extractFirstLetters
} from "../../utils/string";
import useFormSubmission from "../../hooks/useFormSubmission";
import TextareaField from "../TextareaField";
import { channelSmIcon } from "../../helpers/channelIcon";
import SelectField from "../SelectField";

function NewCaseFormContainer({
  handleSubmit,
  submitDisabled,
  setSubmitDisabled,
  teams,
  channels,
  endUsers,
  dirty
}) {
  const handleFormSubmit = useFormSubmission(submitDisabled, handleSubmit);

  if (!dirty) {
    if (!submitDisabled) {
      setSubmitDisabled(true);
    }
  } else if (submitDisabled) {
    setSubmitDisabled(false);
  }

  return (
    <form onSubmit={handleFormSubmit}>
      <GutterField>
        <Field
          name="endUser"
          label="End user"
          component={SelectField}
          items={endUsers}
          disabled
          renderItem={item => (
            <DropdownItemWithAvatar
              renderAvatar={
                <Avatar
                  size="size24"
                  color={item.color}
                  image={item.avatar}
                  text={extractFirstLetters(item.name, 1)}
                />
              }
            >
              {item.name}
            </DropdownItemWithAvatar>
          )}
        />
      </GutterField>
      <GutterField>
        <Field
          name="teams"
          label="Teams"
          component={SelectField}
          items={teams.map(team => ({
            name: team.title,
            color: team.color,
            value: team.id
          }))}
          disabled={teams.length <= 1}
          renderItem={item => (
            <DropdownItemWithAvatar
              renderAvatar={
                <Avatar
                  size="size24"
                  background={item.color}
                  color="#fff"
                  text={extractFirstCharacters(item.name, 1)}
                />
              }
            >
              {item.name}
            </DropdownItemWithAvatar>
          )}
        />
      </GutterField>
      <GutterField>
        <Field
          name="channel"
          label="Channel"
          component={SelectField}
          items={channels.map(channel => ({
            avatar: channel.avatar || channelSmIcon(channel.label),
            name: channel.title,
            color: channel.color,
            value: channel.id
          }))}
          renderItem={item => {
            return (
              <DropdownItemWithAvatar
                renderAvatar={
                  <Avatar
                    size="size24"
                    image={item.avatar}
                    color={item.color}
                    text={extractFirstLetters(item.name, 1)}
                  />
                }
              >
                {item.name}
              </DropdownItemWithAvatar>
            );
          }}
          disabled={channels.length <= 1}
        />
      </GutterField>
      <GutterField>
        <Field name="message" label="Message" component={TextareaField} />
      </GutterField>
    </form>
  );
}

export default NewCaseFormContainer;
