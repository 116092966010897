import React, { useCallback, useState } from "react";
import {
  GutterField,
  Dropdown,
  DropdownToggle,
  DropdownToggleDefault,
  DropdownMenu,
  DropdownItem
} from "design-system-react";
import { api } from "utils/client";
import { ALERT_ERROR, ALERT_SUCCESS, showAlert } from "utils/alertManager";

function CasePriorityDropdown({ caseItem, upFirst }) {
  const items = [
    { id: 1, label: "Low", value: "low" },
    { id: 2, label: "Normal", value: "normal" },
    { id: 3, label: "High", value: "high" }
  ];

  const [isOpenPriorityDropdown, setIsOpenPriorityDropdown] = useState(false);

  const [
    selectedValuePriorityDropdown,
    setSelectedValuePriorityDropdown
  ] = useState(null);

  const togglePriorityDropdown = useCallback(() => {
    setIsOpenPriorityDropdown(!isOpenPriorityDropdown);
  }, [isOpenPriorityDropdown]);

  const handleChange = useCallback(value => {
    setSelectedValuePriorityDropdown(value);
    setIsOpenPriorityDropdown(false);
  }, []);

  const selectedItem = items.filter(
    i => i.value === selectedValuePriorityDropdown
  )[0];

  const handlePriorityDropdownUpdate = useCallback(
    priority => {
      const id = caseItem.id;
      api.cases
        .updateCasePriority(id, { priority })
        .ready.then(() => {
          showAlert(ALERT_SUCCESS, `Priority has been changed to ${priority}`);
        })
        .catch(() => {
          showAlert(ALERT_ERROR, "Something bad happened");
        });
    },
    [caseItem]
  );

  return (
    <GutterField gutterBottom={20}>
      <Dropdown
        isOpen={isOpenPriorityDropdown}
        toggle={togglePriorityDropdown}
        nonEmpty={caseItem.priority}
        size="sm"
      >
        <DropdownToggle>
          <DropdownToggleDefault label="Priority">
            {!selectedItem && upFirst(caseItem.priority)}
            {selectedItem && selectedItem.label}
          </DropdownToggleDefault>
        </DropdownToggle>
        <DropdownMenu>
          {items.map(item => (
            <DropdownItem
              key={item.value}
              onClick={() => {
                handleChange(item.value);
                handlePriorityDropdownUpdate(item.value);
              }}
              selected={selectedItem && selectedItem.value === item.value}
            >
              {item.label}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </GutterField>
  );
}

export default CasePriorityDropdown;
