import React, { useCallback, useState } from "react";
import { Input } from "design-system-react";

function InputField({ field, form, onValueChange, ...props }) {
  const [isClear, setClear] = useState(null);

  const handleClear = useCallback(() => {
    setClear(field.value);
    form.setFieldValue(field.name, "");
  }, [form, field.name, field.value, setClear]);

  const handleKeyDown = useCallback(
    e => {
      if (e.keyCode === 90 && (e.ctrlKey || e.metaKey)) {
        if (isClear) {
          form.setFieldValue(field.name, isClear);
          setClear(null);
        }
      }
    },
    [form, field.name, isClear]
  );

  const handleChange = useCallback(
    e => {
      onValueChange && onValueChange(e);
      if (props.type === "number") {
        if (props.max && Number(e.target.value) > props.max) {
          return;
        }
      }
      field.onChange(e);
    },
    [field, onValueChange, props.max, props.type]
  );

  let error = form.errors[field.name];

  return (
    <Input
      validation={error && "error"}
      message={error}
      onClearClick={handleClear}
      {...field}
      {...props}
      onKeyDown={handleKeyDown}
      onChange={handleChange}
    />
  );
}

export default InputField;
