import React from "react";
import { TableRow, TableColumn } from "design-system-react";
import { DateTime } from "components/DateTime";
import CaseStatusColumn from "components/CaseStatusColumn";
import { getCaseStatusColor } from "helpers/cases";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import CaseListItemEndUserContainer from "containers/CaseListItemEndUserContainer";
import AssignedTeamMemberContainer from "containers/AssignedTeamMemberContainer";
import CaseChannelContainer from "containers/CaseChannelContainer";

function EndUserCasesItemContainer({ caseItem, endUserId, channelId }) {
  return (
    <TableRow
      tag={Link}
      to={{ pathname: "/end-user/" + endUserId + "/" + caseItem.id }}
      status={getCaseStatusColor(caseItem.status)}
      replace
    >
      <TableColumn justified fixed width={300}>
        <CaseListItemEndUserContainer
          endUserId={caseItem.endUser}
          lastMessageId={caseItem.lastMessage}
          unread={caseItem.unread}
        />
      </TableColumn>
      <TableColumn>
        <CaseStatusColumn status={caseItem.status} />
      </TableColumn>
      <TableColumn>
        <AssignedTeamMemberContainer
          teamMemberId={caseItem.assignedTeamMember}
        />
      </TableColumn>
      <TableColumn>
        <DateTime time={caseItem.updatedAt} />
      </TableColumn>
      <TableColumn>
        <CaseChannelContainer channelId={channelId} />
      </TableColumn>
      <TableColumn>#{caseItem.aid}</TableColumn>
    </TableRow>
  );
}

const mapStateToProps = (state, props) => {
  const caseItem = state.entities.cases[props.caseItemId];
  const conversation =
    caseItem && state.entities.conversations[caseItem.conversation];

  return {
    caseItem: caseItem,
    channelId: conversation && conversation.channel
  };
};

export default connect(mapStateToProps)(EndUserCasesItemContainer);
