import React from "react";
import { Button } from "design-system-react";

function ProfileButton({ profileButtonAction }) {
  return (
    <Button onClick={profileButtonAction} outline icon={true}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="16"
        viewBox="0 0 24 16"
      >
        <path
          id="Rounded_Rectangle_1044_copy_59"
          data-name="Rounded Rectangle 1044 copy 59"
          className="cls-1"
          d="M1506.5,3311h-5a0.5,0.5,0,0,1,0-1h5A0.5,0.5,0,0,1,1506.5,3311Zm-2-3h-3a0.5,0.5,0,0,1,0-1h3A0.5,0.5,0,0,1,1504.5,3308Zm-12.5,7h-2a6.265,6.265,0,0,0-6,5,4.773,4.773,0,0,0-1,0v-0.49a0.572,0.572,0,0,0,.07.08c0.44-3.14,3.76-5.59,6.93-5.59h2c3.17,0,6.49,2.45,6.93,5.59,0.02-.02.04-0.05,0.07-0.08V3320h-1.15A6.151,6.151,0,0,0,1492,3315Zm-1-2.99a4.01,4.01,0,1,1,4-4.01A4.007,4.007,0,0,1,1491,3312.01Zm0-7a2.99,2.99,0,1,0,3.02,2.99A3,3,0,0,0,1491,3305.01Zm10.5,7.99h5a0.5,0.5,0,0,1,0,1h-5A0.5,0.5,0,0,1,1501.5,3313Zm0,3h3a0.5,0.5,0,0,1,0,1h-3A0.5,0.5,0,0,1,1501.5,3316Z"
          transform="translate(-1483 -3304)"
          fill="#7d7e80"
        />
      </svg>
    </Button>
  );
}

export default ProfileButton;
