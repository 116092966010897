import React from "react";
import { connect } from "react-redux";
import LastCaseMessage from "../components/LastCaseMessage";

function LastCaseMessageContainer({
  type,
  content,
  teamMemberAvatar,
  teamMemberName,
  teamMemberNameColor,
  unread,
  caseItemSubject
}) {
  return (
    <LastCaseMessage
      type={type}
      content={content}
      teamMemberAvatar={teamMemberAvatar}
      teamMemberName={teamMemberName}
      teamMemberNameColor={teamMemberNameColor}
      unread={unread}
      caseItemSubject={caseItemSubject}
    />
  );
}

const mapStateToProps = (state, props) => {
  const message = state.entities.messages[props.messageId];
  const teamMember = message
    ? state.entities.teamMembers[message.teamMember]
    : null;

  return {
    type: message && message.preview && message.preview.type,
    content: message && message.preview && message.preview.content,
    teamMemberAvatar: teamMember && teamMember.avatar,
    teamMemberName: teamMember && teamMember.name,
    teamMemberNameColor: teamMember && teamMember.nameColor
  };
};

export default connect(mapStateToProps)(LastCaseMessageContainer);
