import React from "react";
import classNames from "classnames";
import styles from "pages/Cases/components/MessageListItemBubbleSmallImage.module.css";

function MessageListItemBubbleSmallImage({
  url,
  width,
  fileName,
  fileExt,
  fileSize,
  renderStatus
}) {
  const classesImg = classNames(styles.image, width <= 40 && styles.sm);

  return (
    <div className={styles.wrapper}>
      <div className={classesImg}>
        <a
          className={styles.link}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={url} alt="" />
        </a>
      </div>
      <a
        href={url}
        target="_blank"
        className={styles.info}
        rel="noopener noreferrer"
      >
        <span className={styles.name}>
          <span className={styles.nameBlock}>
            {fileName && fileName.split(".", 1)}
          </span>
          .<span className={styles.extBlock}>{fileExt}</span>
        </span>

        {fileSize > 1024000 && (
          <div className={styles.size}>
            {Math.round(fileSize / 1024 / 1024)} mb
          </div>
        )}
        {fileSize < 1024000 && (
          <div className={styles.size}>{Math.round(fileSize / 1024)} kb</div>
        )}
      </a>
      <span className={styles.status}>{renderStatus}</span>
    </div>
  );
}

export default MessageListItemBubbleSmallImage;
