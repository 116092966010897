import classNames from "classnames";
import styles from "./Table.module.scss";

interface TableColumnProps extends React.HTMLAttributes<HTMLElement> {
  isAvatar?: boolean;
  noPadding?: boolean;
  width?: number;
  minWidth?: number;
  maxWidth?: number;
  active?: boolean;
  isSwitcher?: boolean;
  children?: React.ReactNode;
  hoverable?: boolean;
}

function TableColumn({
  isAvatar,
  noPadding,
  width,
  minWidth,
  maxWidth,
  active,
  isSwitcher,
  children,
  hoverable,
  onClick,
  className,
  ...attributes
}: TableColumnProps) {
  const classes = classNames(
    styles.tableColumn,
    isAvatar && styles.isAvatar,
    !width && styles.justified,
    noPadding && styles.p0,
    active && styles.active,
    hoverable && styles.hoverable,
    isSwitcher && styles.isSwitcher,
    onClick && styles.clickable,
    className
  );

  return (
    <div
      className={classes}
      style={{ width: width, minWidth: minWidth || width, maxWidth: maxWidth }}
      onClick={onClick}
      {...attributes}
    >
      {children}
    </div>
  );
}

export default TableColumn;
