import React from "react";
import classNames from "classnames";
import styles from "./WidgetChannelFormWrapper.module.css";

function WidgetChannelFormWrapper({ isDrag, children }) {
  const classes = classNames(styles.wrapper, isDrag && styles.isDrag);

  return <div className={classes}>{children}</div>;
}
export default WidgetChannelFormWrapper;
