import { useMemo, useState } from "react";
import { Cascader, message, Spin } from "antd";
import { extractFirstLetters } from "utils/string";
import { CascaderOptionType, CascaderValueType } from "antd/lib/cascader";
import { TeamOutlined } from "@ant-design/icons";
import EntityAvatar from "components/entityAvatar/EntityAvatar";
import styles from "./AssignInput.module.scss";
import { useCallback } from "react";

type TeamsType = { data: Array<any>; loading: boolean; error: string | null };

type TeamMembersType = {
  teamId: string | null;
  data: Array<any>;
  loading: boolean;
  error: string | null;
};

type AssignInputOptionType = CascaderOptionType & {
  onSelect?: () => void;
};

type valueType = { team: any; teamMember: any };

export interface AssignInputProps {
  value?: any;
  prependOptions?: AssignInputOptionType[];
  placeholder?: string;
  assignLabel?: string;
  disableTeamSelection?: boolean;
  collapsed?: boolean;
  disableInactiveMembers?: boolean;
  disabledMembers?: string[];
  width?: number | string;
  customTrigger?: any;
  onChange?: (value: valueType) => void;
  onTeamsRequest: () => Promise<Array<any>>;
  onTeamMembersRequest: (teamId: string) => Promise<Array<any>>;
}

function AssignInput({
  value,
  prependOptions,
  placeholder,
  assignLabel = "ASSIGN TO",
  disableTeamSelection,
  collapsed,
  disableInactiveMembers = true,
  disabledMembers = [],
  width = 215,
  customTrigger,
  onChange,
  onTeamsRequest,
  onTeamMembersRequest
}: AssignInputProps) {
  const [teams, setTeams] = useState<TeamsType>({
    data: [],
    loading: false,
    error: null
  });

  const [teamMembers, setTeamMembers] = useState<TeamMembersType>({
    teamId: null,
    data: [],
    loading: false,
    error: null
  });

  const fetchTeams = useCallback(async () => {
    setTeams({ data: [], loading: true, error: null });

    try {
      const data = await onTeamsRequest();

      setTeams(prev => ({
        ...prev,
        data: data,
        loading: false
      }));
    } catch (e) {
      setTeams(prev => ({
        ...prev,
        loading: false,
        error: "Error while fetching teams"
      }));
    }
  }, [onTeamsRequest]);

  const fetchTeamMembers = useCallback(
    async (teamId: string) => {
      setTeamMembers(prev => ({
        teamId,
        data: [],
        loading: true,
        error: null
      }));

      try {
        const data = await onTeamMembersRequest(teamId);

        setTeamMembers(prev => ({
          ...prev,
          data: data,
          loading: false
        }));
      } catch (e) {
        setTeamMembers(prev => ({
          ...prev,
          loading: false,
          error: "Error while fetching members"
        }));
      }
    },
    [onTeamMembersRequest]
  );

  const preparedOptions = useMemo(() => {
    let result: any = [];

    if (prependOptions) {
      prependOptions.map(prependOption => {
        result.push(prependOption);

        return prependOption;
      });
    }

    if (assignLabel) {
      result.push({
        label: <div className={styles.title}>{assignLabel}</div>,
        value: assignLabel,
        disabled: true
      });
    }

    if (teams.loading) {
      result.push({
        label: (
          <div style={{ pointerEvents: "none" }}>
            Loading teams <Spin size="small" />
          </div>
        ),
        value: "disabled",
        disabled: true
      });
    } else {
      if (teams.data.length === 0) {
        result.push({
          label: (
            <div style={{ pointerEvents: "none" }}>
              {teams.error || "Teams not found"}
            </div>
          ),
          value: "disabled",
          disabled: true
        });
      } else {
        teams.data.map(team => {
          result.push({
            value: "team:" + team.id,
            label: (
              <div
                className={styles.item}
                onClick={e => {
                  fetchTeamMembers(team.id);
                }}
              >
                <EntityAvatar
                  avatarSize={22}
                  avatarTextSize={14}
                  avatarBackground={team.color}
                  avatarTitle={extractFirstLetters(team.title, 1)}
                  title={team.title}
                />
              </div>
            ),
            children: disableTeamSelection
              ? [
                  {
                    label: <div className={styles.title}>TEAM MEMBERS</div>,
                    value: "TEAM MEMBERS",
                    disabled: true
                  }
                ]
              : [
                  {
                    label: (
                      <div style={{ pointerEvents: "none" }}>
                        The entire team
                      </div>
                    ),
                    value: "only-team"
                  }
                ],
            isLeaf: false
          });

          return team;
        });
      }
    }

    if (teamMembers.teamId) {
      const team = result.filter(
        (option: any) => option.value === "team:" + teamMembers.teamId
      )[0];

      if (team) {
        if (teamMembers.loading) {
          team.children.push({
            label: (
              <div style={{ pointerEvents: "none" }}>
                Loading team members <Spin size="small" />
              </div>
            ),
            value: "disabled",
            disabled: true
          });
        } else {
          if (teamMembers.data.length === 0) {
            team.children.push({
              label: (
                <div style={{ pointerEvents: "none" }}>
                  {teamMembers.error || "Team members not found"}
                </div>
              ),
              value: "disabled",
              disabled: true
            });
          } else {
            teamMembers.data.map(teamMember => {
              team.children.push({
                value: "teamMember:" + teamMember.id,
                label: (
                  <div
                    className={styles.item}
                    onClick={() => {
                      if (
                        disableInactiveMembers &&
                        teamMember.availability === "inactive"
                      ) {
                        message.info("This member is inactive");
                      }
                    }}
                  >
                    <EntityAvatar
                      avatarSize={22}
                      avatarTextSize={14}
                      avatarSrc={teamMember.avatar}
                      avatarBackground={teamMember.nameColor}
                      avatarTitle={extractFirstLetters(teamMember.name, 1)}
                      title={teamMember.name}
                    />
                  </div>
                ),
                disabled:
                  (disableInactiveMembers &&
                    teamMember.availability === "inactive") ||
                  (disabledMembers || []).indexOf(teamMember.id) !== -1
              });

              return teamMember;
            });
          }
        }
      }
    }

    return result;
  }, [
    teams,
    teamMembers,
    prependOptions,
    fetchTeamMembers,
    assignLabel,
    disableTeamSelection,
    disableInactiveMembers,
    disabledMembers
  ]);

  const handleChange = (value: CascaderValueType, selectedOptions: any) => {
    if (value.length > 1) {
      let team = null;
      let teamMember = null;

      if (String(value[0]).startsWith("team:")) {
        const teamId = String(value[0]).replace("team:", "");
        team = teams.data.filter(t => t.id === teamId)[0];
      }

      if (String(value[1]).startsWith("teamMember:")) {
        const teamMemberId = String(value[1]).replace("teamMember:", "");

        teamMember = teamMembers.data.filter(t => t.id === teamMemberId)[0];
      }

      onChange && onChange({ team, teamMember });
    } else if (value.length === 1) {
      if (prependOptions) {
        prependOptions.map(prependOption => {
          if (prependOption.value === value[0]) {
            prependOption.onSelect && prependOption.onSelect();
          }

          return prependOption;
        });
      }
    }
  };

  const selectedTeam = value && value.team;
  const selectedTeamMember = value && value.teamMember;

  return (
    <Cascader
      className={styles.assignInput}
      style={{ width: collapsed ? "54px" : width }}
      popupClassName={styles.popup}
      value={
        selectedTeam || selectedTeamMember || collapsed
          ? [""]
          : typeof value === "string"
          ? [value]
          : undefined
      }
      placeholder={placeholder}
      options={preparedOptions}
      onChange={handleChange}
      allowClear={false}
      onPopupVisibleChange={visible => {
        if (visible) {
          fetchTeams();
        }
      }}
      displayRender={(label, selectedOptions) => {
        if (collapsed) {
          return <TeamOutlined />;
        }

        if (typeof value === "string") {
          return label;
        }

        if (selectedTeam && !selectedTeamMember) {
          return (
            <EntityAvatar
              avatarSize={18}
              avatarTextSize={14}
              avatarBackground={selectedTeam.color}
              avatarTitle={extractFirstLetters(selectedTeam.title, 1)}
              title={selectedTeam.title}
            />
          );
        }

        if (selectedTeamMember) {
          return (
            <EntityAvatar
              avatarSize={18}
              avatarTextSize={14}
              avatarSrc={selectedTeamMember.avatar}
              avatarBackground={selectedTeamMember.nameColor}
              avatarTitle={extractFirstLetters(selectedTeamMember.name, 1)}
              title={selectedTeamMember.name}
            />
          );
        }
      }}
    >
      {customTrigger}
    </Cascader>
  );
}

export default AssignInput;
