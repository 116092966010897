import { CALL_API } from "utils/api";
import { withNamespace } from "utils/namespace";
import uuidv4 from "utils/uuidv4";
import {
  notification,
  NOTIFICATION_ERROR,
  NOTIFICATION_SUCCESS
} from "utils/notificationsManager";

const type = withNamespace("pages/cases/attachFile");

export const ATTACH_FILE = type("ATTACH_FILE");
export const REMOVE_ATTACHMENT = type("REMOVE_ATTACHMENT");
export const REMOVE_ALL_ATTACHMENTS = type("REMOVE_ALL_ATTACHMENTS");
export const UPLOAD_FILE_REQUEST = type("UPLOAD_FILE_REQUEST");
export const UPLOAD_FILE_SUCCESS = type("UPLOAD_FILE_SUCCESS");
export const UPLOAD_FILE_FAILURE = type("UPLOAD_FILE_FAILURE");

// state
let initialState = {
  attachedFiles: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ATTACH_FILE:
      return {
        ...state,
        attachedFiles: [...state.attachedFiles, action.payload]
      };
    case REMOVE_ATTACHMENT:
      return {
        ...state,
        attachedFiles: state.attachedFiles.filter(
          attachment => attachment.uuid !== action.payload.uuid
        )
      };
    case REMOVE_ALL_ATTACHMENTS:
      return {
        ...state,
        attachedFiles: []
      };
    case UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        attachedFiles: state.attachedFiles.map(file =>
          file.uuid === action.payload.uuid
            ? {
                ...file,
                uploaded: true,
                id: action.payload.data[0].id
              }
            : file
        )
      };
    case UPLOAD_FILE_FAILURE:
      return {
        ...state,
        attachedFiles: state.attachedFiles.filter(
          file => file.uuid !== action.payload.uuid
        )
      };
    default:
      return state;
  }
}

// actions
export function removeAttachment(uuid) {
  return {
    type: REMOVE_ATTACHMENT,
    payload: {
      uuid
    }
  };
}
export function removeAllAttachments() {
  return {
    type: REMOVE_ALL_ATTACHMENTS
  };
}

export function uploadFile(file) {
  const uuid = uuidv4();

  return dispatch => {
    dispatch({
      type: ATTACH_FILE,
      payload: {
        uuid,
        name: file.name,
        uploaded: false
      }
    });

    var formData = new FormData();
    formData.append("file", file);

    const actionResponse = dispatch({
      [CALL_API]: {
        endpoint: "/file/upload",
        options: {
          method: "post",
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data"
          }
        },
        types: {
          request: UPLOAD_FILE_REQUEST,
          success: UPLOAD_FILE_SUCCESS,
          failure: UPLOAD_FILE_FAILURE
        },
        payload: {
          uuid
        }
      }
    });

    actionResponse.ready.then(action => {
      if (action.success) {
        notification("File " + file.name + " uploaded", NOTIFICATION_SUCCESS);
      } else {
        notification("File " + file.name + " not uploaded", NOTIFICATION_ERROR);
      }
    });

    return actionResponse;
  };
}
