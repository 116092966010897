// import {createSelector} from "@reduxjs/toolkit";
// import {denormalize} from 'normalizr';
// import {candidateScheme} from "schema";

export const getPageState = state => state.pages.settingsSalesCardSetups;

export const getSalesCardSetup = setupId => state =>
  state.entities.salesCardSetup[setupId];

// export const getCandidates = createSelector([
//     state => state.entities.candidates,
//     state => state.entities.stages,
//     state => state.pages.candidates.candidatesIds
// ], (candidates, stages, ids) => {
//     // return ids.map(id => {
//     return denormalize(ids,
//         [candidateScheme],
//         {
//             candidates,
//             stages,
//         })
//     // });
// });
//
// export const getStages = createSelector([
//     state => state.entities.stages,
//     state => state.pages.candidates.stagesIds
// ], (stages, ids) => ids.map(id => stages[id]));
//
// export const getCandidatesPageState = state => state.pages.candidates;
