export const logoAvatar = introduce => {
  if (introduce.logo && !introduce.LoadAvatar) {
    return introduce.logo["120x120"];
  }
  if (introduce.LoadAvatar) {
    return introduce.LoadAvatar;
  }
  if (!introduce.logo && !introduce.LoadAvatar) {
    return "";
  }
};
