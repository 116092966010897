import { useState, useRef, useMemo, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import {
  Button,
  Container,
  Row,
  Col,
  GutterField,
  Scrollbar
} from "design-system-react";
import { Formik } from "formik";
import { api } from "utils/client";
import { ALERT_SUCCESS, showAlert } from "utils/alertManager";
import SettingsProfileFormContainer from "./SettingsProfileFormContainer";
import SettingsChannelHeader from "components/SettingsHeader";
import ChangePasswordModalContainer from "./ChangePasswordModalContainer";
import { MODAL_ERROR, showConfirmModal } from "utils/modalManager";
import { CommonError } from "utils/commonError";
import { createSelector } from "reselect";
import ConfirmPinModalContainer from "pages/SettingsProfile/containers/ConfirmPinModalContainer";
import {
  subscribe,
  unsubscribe,
  WS_EVENT_PIN_CONFIRMED
} from "utils/websocket";
import { updateEntityFields } from "modules/entities";
import View from "components/View";
import TeamMemberActiveSwitcher from "components/TeamMemberActiveSwitcher";

function SettingsProfileContainer({ user, extraFields, updateEntityFields }) {
  const form = useRef(null);
  const [initialSaved, setInitialSaved] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [isPasswordModalOpen, setPasswordModalOpen] = useState(false);
  const [isConfirmPinModalOpen, setConfirmPinModalOpen] = useState(false);
  const [integration, setIntegration] = useState(null);
  const [integrationLoading, setIntegrationLoading] = useState(true);

  const toggleChangePassword = useCallback(() => {
    setPasswordModalOpen(!isPasswordModalOpen);
  }, [isPasswordModalOpen]);

  const toggleConfirmPinModal = useCallback(() => {
    setConfirmPinModalOpen(!isConfirmPinModalOpen);
  }, [isConfirmPinModalOpen]);

  const initialValues = useMemo(() => {
    return {
      name: user.name,
      email: user.email,
      phoneNumber: user.extraFields.phoneNumber || ""
    };
    // eslint-disable-next-line
  }, []);

  const handleSubmit = useCallback(
    (values, { setSubmitting, resetForm }) => {
      const extraArray = [];

      extraFields.map(field => {
        if (values[field.name]) {
          extraArray.push({
            id: field.id,
            value: values[field.name]
          });
        }
        return field;
      });

      let changedValues = {
        avatar: values.avatar,
        name: values.name,
        email: values.email,
        extraFields: extraArray
      };

      api.teamMembers
        .updateProfile(changedValues)
        .ready.then(() => {
          showAlert(
            ALERT_SUCCESS,
            "Account settings was successfully uploaded"
          );
          setInitialSaved(true);
          setSubmitDisabled(true);
          resetForm(values);
        })
        .catch(e => {
          const errorMessage = "Ooops, something went wrong";
          showConfirmModal(
            MODAL_ERROR,
            e instanceof CommonError ? e.error : errorMessage,
            { cancelButtonText: "Okay" }
          );
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [extraFields]
  );

  const validate = useCallback(values => {
    let errors = {};
    if (!values.name.trim()) {
      errors.name = "This value should not be null.";
    }

    let phoneReg = /^\+?[0-9]{8,15}$/;
    let validPhone = phoneReg.test(values.phone);
    if (values.phone && !validPhone) {
      errors.phone = "Phone number is not valid.";
    }
    return errors;
  }, []);

  const handlePinConfirmWebsocket = useCallback(data => {
    if (data.teamMemberId === user.id) {
      updateEntityFields("teamMembers", data.teamMemberId, {
        pinConfirmed: true
      });
      setConfirmPinModalOpen(false);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    api.telegramInterface
      .all()
      .ready.then(response => {
        if (response.data.data.length > 0) {
          setIntegration(response.data.data[0]);
        }
      })
      .finally(() => {
        setIntegrationLoading(false);
      });
  }, []);

  useEffect(() => {
    subscribe(WS_EVENT_PIN_CONFIRMED, handlePinConfirmWebsocket);

    return () => {
      unsubscribe(WS_EVENT_PIN_CONFIRMED, handlePinConfirmWebsocket);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Row fullHeight column>
      <ChangePasswordModalContainer
        toggle={toggleChangePassword}
        isOpen={isPasswordModalOpen}
      />
      <ConfirmPinModalContainer
        toggle={toggleConfirmPinModal}
        isOpen={isConfirmPinModalOpen}
        pin={user.pin}
        telegramUsername={integration && integration.info.username}
      />
      <Container>
        <SettingsChannelHeader title="Account settings" noBorder>
          <View display="flex">
            <TeamMemberActiveSwitcher
              teamMember={user}
              checked={user.availability === "active"}
              isCurrentTeamMember
            />
          </View>
        </SettingsChannelHeader>
      </Container>
      <Col justified>
        <Scrollbar>
          <Formik
            validate={validate}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            ref={form}
            render={props => {
              return (
                <SettingsProfileFormContainer
                  {...props}
                  user={user}
                  submitDisabled={submitDisabled}
                  setSubmitDisabled={setSubmitDisabled}
                  handlePasswordClick={toggleChangePassword}
                  onPinConfirm={
                    !integrationLoading && integration && toggleConfirmPinModal
                  }
                >
                  <GutterField>
                    <Button
                      onClick={props.submitForm}
                      processing={props.isSubmitting}
                      disabled={submitDisabled}
                    >
                      {submitDisabled && initialSaved ? "Saved" : "Save"}
                    </Button>
                  </GutterField>
                </SettingsProfileFormContainer>
              );
            }}
          />
        </Scrollbar>
      </Col>
    </Row>
  );
}

const getExtraFields = createSelector(
  [
    state => state.entities.extraFields,
    state => state.teamMemberExtraFields.ids
  ],
  (extraFields, ids) => {
    return ids.map(id => extraFields[id]);
  }
);

const mapStateToProps = state => {
  return {
    user: state.entities.teamMembers[state.auth.userId],
    extraFields: getExtraFields(state)
  };
};

const mapDispatchToProps = dispatch => ({
  updateEntityFields: (entityType, entityId, attributes) =>
    dispatch(updateEntityFields(entityType, entityId, attributes))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsProfileContainer);
