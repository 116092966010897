import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { setChannels } from "modules/widgetSetup";
import useFormSubmission from "hooks/useFormSubmission";
import WidgetChannelListFormContainer from "./WidgetChannelListFormContainer";
import usePrevious from "../../../hooks/usePrevious";

function WidgetChannelsFormContainer({
  setChannels,
  handleSubmit,
  values,
  dirty,
  submitDisabled,
  setSubmitDisabled,
  handleSetModal,
  modalValue,
  setValues
}) {
  const handleSubmitForm = useFormSubmission(submitDisabled, handleSubmit);
  const prevModalValue = usePrevious(modalValue);

  // update setup on form changes
  useEffect(() => {
    setChannels(values);
  }, [setChannels, values]);

  if (!dirty && !submitDisabled) {
    setSubmitDisabled(true);
  }

  if (dirty && submitDisabled) {
    setSubmitDisabled(false);
  }

  if (modalValue && !prevModalValue) {
    let filterVal = values.channels.map(i => {
      return {
        type: i.type,
        link: i.type === modalValue.type ? modalValue.link : i.link,
        enabled: i.type === modalValue.type ? modalValue.enabled : i.enabled
      };
    });
    setValues({ blingerMessenger: values.blingerMessenger, channels: filterVal });
  }

  const changeList = useCallback(
    val => {
      setValues({ blingerMessenger: values.blingerMessenger, channels: val.list });
    },
    [values, setValues]
  );

  const handleDisconnect = useCallback(
    type => {
      let filterVal = values.channels.map(i => {
        return {
          type: i.type,
          link: i.type === type ? "" : i.link,
          enabled: i.type === type ? false : i.enabled
        };
      });
      setValues({ blingerMessenger: values.blingerMessenger, channels: filterVal });
    },
    [values, setValues]
  );

  const handleLiveChatConnect = useCallback(
    bool => {
      setValues({ blingerMessenger: bool, channels: values.channels });
    },
    [values, setValues]
  );

  return (
    <WidgetChannelListFormContainer
      handleSubmitForm={handleSubmitForm}
      channelList={values.channels}
      handleSetModal={handleSetModal}
      handleDisconnect={handleDisconnect}
      changeList={changeList}
      liveChatConnect={values.blingerMessenger}
      handleLiveChatConnect={handleLiveChatConnect}
    />
  );
}

const mapDispatchToProps = dispatch => ({
  setChannels: values => dispatch(setChannels(values))
});

export default connect(
  null,
  mapDispatchToProps
)(WidgetChannelsFormContainer);
