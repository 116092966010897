import React from "react";
import styles from "pages/Cases/components/MessageListItemBubbleWrapper.module.css";
import classNames from "classnames";

function MessageListItemBubbleWrapper({ children, alignRight, highlight }) {
  return (
    <div
      className={classNames(
        styles.bubbles,
        alignRight && styles.alignRight,
        highlight && styles.highlight
      )}
    >
      {children}
    </div>
  );
}

export default MessageListItemBubbleWrapper;
