import React, { useEffect, useCallback } from "react";
import SettingsSubMenu from "components/settings/SettingsSubMenu";
import SettingsFacebookContainer from "./containers/SettingsFacebookContainer";
import SettingsLayout from "components/SettingsLayout";

function SettingsFacebook() {
  useEffect(() => {
    document.title = "Facebook settings";
    localStorage.setItem("lastSettingRoute", "/settings/facebook-messenger");
  }, []);

  const renderSubmenu = useCallback(
    () => <SettingsSubMenu activeMenuItem={"/settings/facebook-messenger"} />,
    []
  );
  const renderContent = useCallback(() => <SettingsFacebookContainer />, []);

  return (
    <SettingsLayout
      renderSubmenu={renderSubmenu}
      renderContent={renderContent}
    />
  );
}

export default SettingsFacebook;
