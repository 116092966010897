import React, { useEffect, useCallback } from "react";
import SettingsSubMenu from "components/settings/SettingsSubMenu";
import SettingsAutoResponseContainer from "./containers/SettingsAutoResponseContainer";
import SettingsLayout from "components/SettingsLayout";

function SettingsAutoResponse({ match }) {
  useEffect(() => {
    document.title = "Auto-response";
    localStorage.setItem("lastSettingRoute", "/settings/auto-responses");
  }, []);

  const renderSubmenu = useCallback(
    () => <SettingsSubMenu activeMenuItem={"/settings/auto-responses"} />,
    []
  );

  const renderContent = useCallback(
    () => <SettingsAutoResponseContainer autoResponseId={match.params.id} />,
    [match.params.id]
  );

  return (
    <SettingsLayout
      renderSubmenu={renderSubmenu}
      renderContent={renderContent}
    />
  );
}

export default SettingsAutoResponse;
