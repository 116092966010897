import React from "react";
import styles from "pages/Cases/components/MessageListItemStatus.module.css";
import PropTypes from "prop-types";
import {
  MESSAGE_STATUS_ERROR,
  MESSAGE_STATUS_SENDING
} from "../../../modules/pages/cases";

function getStatusIcon(status) {
  if (status === MESSAGE_STATUS_ERROR) {
    return "/images/error-message-ico.svg";
  }

  if (status === MESSAGE_STATUS_SENDING) {
    return "/images/sending-message-ico.svg";
  }

  return "/images/sent-message-ico.svg";
}

function getStatusTitle(status) {
  if (status === MESSAGE_STATUS_ERROR) {
    return "Message not sent";
  }

  if (status === MESSAGE_STATUS_SENDING) {
    return "Sending...";
  }

  return "Sent successful";
}

function MessageListItemStatus({ status }) {
  return (
    <div className={styles.status}>
      <img
        className={styles.icon}
        src={getStatusIcon(status)}
        title={getStatusTitle(status)}
        alt=""
      />
    </div>
  );
}

MessageListItemStatus.propTypes = {
  status: PropTypes.string.isRequired
};

export default MessageListItemStatus;
