// extract first letters from words
export function extractFirstLetters(sentence, wordCount = 1) {
  if (typeof sentence !== "string") return null;

  const words = sentence.split(" ");

  let result = "";

  for (let i = 0; i < wordCount && i < words.length; i++) {
    result += words[i].charAt(0).replace(/[^A-Za-zА-Яа-яЁё]/g, "");
  }

  return result;
}

export function extractFirstCharacters(sentence, wordCount = 1) {
  if (typeof sentence !== "string") return null;

  const words = sentence.split(" ");

  let result = "";

  for (let i = 0; i < wordCount && i < words.length; i++) {
    result += words[i].charAt(0);
  }

  return result;
}

export function replaceCharactersWithDots(string, startIndex, endIndex) {
  if (typeof string !== "string") return null;

  let output = "";

  output += string.substr(0, startIndex);
  output += "...";
  output += string.substr(endIndex, string.length);

  return output;
}