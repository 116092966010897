import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { api } from "../utils/client";
import {
  Avatar,
  DropdownItem,
  DropdownItemWithAvatar,
  DropdownMenuLabel
} from "design-system-react";
import AssignDropdownTextItem from "../components/AssignDropdown/AssignDropdownTextItem";
import AssignDropdownMenuTeamMemberContainer from "./AssignDropdownMenuTeamMemberContainer";

function AssignDropdownMenuMainScreenContainer({
  caseId,
  teamId,
  teamMemberId,
  currentUserId,
  debouncedFilter,
  onSelect,
  onAssignToTeamSelect,
  onUnassign
}) {
  const [teamMembersLoading, setTeamMembersLoading] = useState(true);
  const [teamMemberIds, setTeamMemberIds] = useState([]);

  const fetchTeamMembers = useCallback(debouncedFilter => {
    setTeamMembersLoading(true);

    const request = api.cases.membersForAssign(debouncedFilter);
    request.ready
      .then(response => {
        setTeamMemberIds(response.data.data.map(teamMember => teamMember.id));
      })
      .finally(() => {
        setTeamMembersLoading(false);
      });

    return request.cancelRequest;
  }, []);

  useEffect(() => {
    const cancelRequest = fetchTeamMembers(debouncedFilter);

    return () => {
      cancelRequest.cancel();
    };
  }, [debouncedFilter, fetchTeamMembers]);

  const teamMemberIdsWithoutCurrentUser = teamMemberIds.filter(
    id => id !== currentUserId
  );

  return (
    <>
      {currentUserId && (!teamMemberId || teamMemberId !== currentUserId) && (
        <AssignDropdownMenuTeamMemberContainer
          teamMemberId={currentUserId}
          onSelect={onSelect}
        />
      )}
      {(teamId || teamMemberId) && (
        <DropdownItem onClick={onUnassign}>
          <DropdownItemWithAvatar
            renderAvatar={
              <Avatar image={"/images/unassigned-person.svg"} size={"size24"} />
            }
          >
            To unassigned
          </DropdownItemWithAvatar>
        </DropdownItem>
      )}
      <DropdownItem id="assign_to_team" onClick={onAssignToTeamSelect} arrow>
        <DropdownItemWithAvatar
          renderAvatar={
            <img alt="" style={{ width: "24px" }} src={"/images/team.svg"} />
          }
        >
          Assign to team
        </DropdownItemWithAvatar>
      </DropdownItem>
      <DropdownMenuLabel>TEAM MEMBERS</DropdownMenuLabel>
      {!teamMembersLoading && teamMemberIdsWithoutCurrentUser.length === 0 && (
        <AssignDropdownTextItem>Team members not found</AssignDropdownTextItem>
      )}
      {!teamMembersLoading &&
        teamMemberIdsWithoutCurrentUser.map(id => {
          return (
            <AssignDropdownMenuTeamMemberContainer
              key={id}
              teamMemberId={id}
              onSelect={onSelect}
            />
          );
        })}
    </>
  );
}

const mapStateToProps = state => {
  return {
    currentUserId: state.auth.userId
  };
};

export default connect(mapStateToProps)(AssignDropdownMenuMainScreenContainer);
