import React, { useCallback } from "react";
import {
  PopupHeader,
  Popup,
  PopupBody,
  PopupFooter,
  Button,
  GutterField
} from "design-system-react";
import { api } from "utils/client";
import { FormError } from "utils/formError";
import { Field, Formik } from "formik";
import InputField from "components/InputField";

function AddStepFormContainer({ toggle, isOpen, setStep, setValues }) {
  const handleSubmit = useCallback(
    (values, { setSubmitting, setErrors }) => {
      api.telegramInterface
        .create(values)
        .ready.then(response => {
          setValues(values);
          setStep(2);
        })
        .catch(e => {
          if (e instanceof FormError) {
            setErrors(e.errors);
          }
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [setStep, setValues]
  );

  return (
    <Formik
      enableReinitialize
      initialValues={{
        phone: ""
      }}
      onSubmit={handleSubmit}
      render={props => (
        <Popup isOpen={isOpen} onClose={toggle}>
          <PopupHeader>Add telegram interface</PopupHeader>
          <PopupBody>
            <GutterField>
              <Field
                name="phone"
                type="text"
                label="Phone"
                component={InputField}
              />
            </GutterField>
            <GutterField>
              <Field
                name="appId"
                type="text"
                label="Telegram App Id"
                component={InputField}
              />
            </GutterField>
            <GutterField>
              <Field
                name="appHash"
                type="text"
                label="Telegram App Hash"
                component={InputField}
              />
            </GutterField>
          </PopupBody>
          <PopupFooter>
            <Button
              onClick={props.submitForm}
              processing={props.isSubmitting}
              // disabled={submitDisabled}
            >
              Next step
            </Button>
            <Button outline={true} onClick={toggle}>
              Cancel
            </Button>
          </PopupFooter>
        </Popup>
      )}
    />
  );
}

export default AddStepFormContainer;
