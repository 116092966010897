import React from "react";
import WidgetHeaderTitle from "components/Widget/WidgetHeaderTitle";
import WidgetHeaderDescription from "components/Widget/WidgetHeaderDescription";
import WidgetHeader from "components/Widget/WidgetHeader";
import WidgetStyleContainer from "./containers/WidgetStyleContainer";

function WidgetStyle() {
  return (
    <>
      <WidgetHeader>
        <WidgetHeaderTitle>Style the widget</WidgetHeaderTitle>
        <WidgetHeaderDescription>
          Customize the style your widget to fit your brand guidelines to
          represent your company
        </WidgetHeaderDescription>
      </WidgetHeader>
      <WidgetStyleContainer />
    </>
  );
}

export default WidgetStyle;
