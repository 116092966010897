import React from "react";
import { connect } from "react-redux";
import { SubMenuListItem } from "design-system-react";
import { Link } from "react-router-dom";
import { casesCounter, casesCounterColor } from "../../../helpers/cases";

function url(caseId, viewName) {
  return caseId ? "/cases/" + viewName + "/" + caseId : "/cases/" + viewName;
}

function SubMenuItemContainer({
  viewId,
  viewName,
  viewTitle,
  viewCount,
  counter,
  counterColor,
  active,
  activeCaseId
}) {
  return (
    <SubMenuListItem
      key={viewId}
      active={active}
      tag={Link}
      to={url(activeCaseId, viewName)}
      text={viewTitle}
      counterAll={viewCount}
      counter={counter}
      color={counterColor}
    />
  );
}

const mapStateToProps = (state, props) => {
  const view = state.entities.views[props.viewId];
  const counters = state.caseCounters.counters;

  return {
    viewName: view.name,
    viewTitle: view.title,
    viewCount: view.count,
    counter: casesCounter(view.name, counters),
    counterColor: casesCounterColor(view.name)
  };
};

export default connect(mapStateToProps)(SubMenuItemContainer);
