import React from "react";
import MessageListItemBubble from "pages/Cases/components/MessageListItemBubble";
import MessageListItemTime from "pages/Cases/components/MessageListItemTime";
import MessageListItemStatus from "pages/Cases/components/MessageListItemStatus";
import MessageListItemBubbleOverlayInfo from "../../pages/Cases/components/MessageListItemBubbleOverlayInfo";
import MessageListItemBubbleLocation from "../../pages/Cases/components/MessageListItemBubbleLocation";

function LocationMessageBubble({ content, status, outgoing, time }) {
  const position = JSON.parse(content);
  return (
    <MessageListItemBubble status={status} file dark={outgoing} time={time}>
      <MessageListItemBubbleLocation
        lat={position.latitude}
        lon={position.longitude}
      />
      <MessageListItemBubbleOverlayInfo>
        <MessageListItemTime time={time} />
        {outgoing && <MessageListItemStatus status={status} />}
      </MessageListItemBubbleOverlayInfo>
    </MessageListItemBubble>
  );
}

export default LocationMessageBubble;
