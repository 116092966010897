import React, { useCallback, useEffect, useState } from "react";
import connect from "react-redux/es/connect/connect";
import { Formik } from "formik";
import NewCaseFormContainer from "./NewCaseFormContainer";
import {
  Button,
  Popup,
  PopupBody,
  PopupFooter,
  PopupHeader
} from "design-system-react";
import { api } from "utils/client";
import { FormError } from "utils/formError";
import { setChannelIds } from "modules/newCase";
import uuidv4 from "utils/uuidv4";
import { createSelector } from "reselect";

function NewCaseModal({
  isOpen,
  toggle,
  endUserId,
  endUserName,
  endUserColor,
  endUserAvatar,
  channels,
  setChannelIds,
  onCaseCreated
}) {
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [initialValues, setInitialValues] = useState({
    endUser: endUserId,
    message: "",
    channel: null
  });
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    if (isOpen) {
      api.teams.withMembers().ready.then(response => {
        setTeams(
          response.data.data.map(team => ({
            title: team.title,
            color: team.color,
            id: team.id
          }))
        );
      });
    }

    // eslint-disable-next-line
  }, [isOpen]);

  useEffect(() => {
    if (teams.length === 1) {
      setInitialValues({
        ...initialValues,
        teams: teams[0].id
      });
    }
  }, [teams, initialValues]);

  useEffect(() => {
    if (isOpen) {
      const request = api.endUsers.channels(endUserId);

      request.ready.then(response => {
        const channelIds = response.data.data.map(channel => channel.id);

        if (channelIds.length === 1) {
          setInitialValues({
            ...initialValues,
            channel: channelIds[0]
          });
        }

        setChannelIds(channelIds);
      });

      return () => {
        request.cancelRequest.cancel();
      };
    }
    // eslint-disable-next-line
  }, [isOpen]);

  const handleSubmit = useCallback(
    (values, { setSubmitting, setErrors }) => {
      api.cases
        .create(
          uuidv4(),
          values.team,
          values.endUser,
          values.message,
          values.channel
        )
        .ready.then(response => {
          onCaseCreated(response.data.data.id);
        })
        .catch(e => {
          if (e instanceof FormError) {
            setErrors(e.errors);
          }
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [onCaseCreated]
  );

  const endUsers = endUserName
    ? [
        {
          name: endUserName,
          color: endUserColor,
          avatar: endUserAvatar,
          value: endUserId
        }
      ]
    : [];

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      render={props => (
        <Popup isOpen={isOpen} onClose={toggle}>
          <PopupHeader>New case</PopupHeader>
          <PopupBody>
            <NewCaseFormContainer
              {...props}
              submitDisabled={submitDisabled}
              setSubmitDisabled={setSubmitDisabled}
              teams={teams}
              channels={channels}
              endUsers={endUsers}
            />
          </PopupBody>
          <PopupFooter>
            <Button
              onClick={props.submitForm}
              processing={props.isSubmitting}
              disabled={submitDisabled}
            >
              Create
            </Button>
            <Button outline={true} onClick={toggle}>
              Cancel
            </Button>
          </PopupFooter>
        </Popup>
      )}
    />
  );
}

const getChannels = createSelector(
  [state => state.entities.channels, state => state.newCase.channelIds],
  (channels, channelIds) => {
    return channelIds.map(id => channels[id]);
  }
);

const mapStateToProps = state => {
  return {
    channels: getChannels(state)
  };
};

const mapDispatchToProps = dispatch => ({
  setChannelIds: ids => dispatch(setChannelIds(ids))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewCaseModal);
