import { withNamespace } from "utils/namespace";

const type = withNamespace("assignDropdown");

// action types
export const SET_TEAM_IDS = type("SET_TEAM_IDS");
export const SET_TEAM_MEMBER_IDS = type("SET_TEAM_MEMBER_IDS");
export const SET_TEAM_MEMBER_BY_TEAM_IDS = type("SET_TEAM_MEMBER_BY_TEAM_IDS");

let initialState = {
  teamIds: [],
  teamMemberIds: [],
  teamMemberByTeamIds: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_TEAM_IDS:
      return {
        ...state,
        teamIds: action.payload.ids
      };
    case SET_TEAM_MEMBER_IDS:
      return {
        ...state,
        teamMemberIds: action.payload.ids
      };
    case SET_TEAM_MEMBER_BY_TEAM_IDS:
      return {
        ...state,
        teamMemberByTeamIds: action.payload.ids
      };

    default:
      return state;
  }
}

// actions
export function setTeamIds(ids) {
  return {
    type: SET_TEAM_IDS,
    payload: {
      ids
    }
  };
}

export function setTeamMemberIds(ids) {
  return {
    type: SET_TEAM_MEMBER_IDS,
    payload: {
      ids
    }
  };
}

export function setTeamMemberByTeamIds(ids) {
  return {
    type: SET_TEAM_MEMBER_BY_TEAM_IDS,
    payload: {
      ids
    }
  };
}
