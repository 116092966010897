import React, { useState, useEffect, useCallback } from "react";
import MessageListScrollButton from "pages/Cases/components/MessageListScrollButton";

const PIXEL_OFFSET = 800;

function MessageListScrollButtonContainer({ scrollbar, scrollToBottom }) {
  const [visible, setVisible] = useState(false);

  const onScroll = useCallback(
    e => {
      const scrollPosition = e.srcElement.scrollTop + e.srcElement.clientHeight;
      const value = e.srcElement.scrollHeight - scrollPosition > PIXEL_OFFSET;

      if (visible !== value) {
        setVisible(value);
      }
    },
    [visible]
  );

  useEffect(() => {
    const scrollbarNode = scrollbar.current;

    if (scrollbarNode) {
      scrollbarNode.addEventListener("scroll", onScroll);

      return () => {
        scrollbarNode.removeEventListener("scroll", onScroll);
      };
    }
  }, [scrollbar, onScroll]);

  if (!visible) {
    return null;
  }

  return <MessageListScrollButton onScrollClick={scrollToBottom} />;
}

export default MessageListScrollButtonContainer;
