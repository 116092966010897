import React, { useState, useEffect } from "react";
import AnalyticsNavPanelContainer from "./AnalyticsNavPanelContainer";
import AnalyticsCardsContainer from "pages/AnalyticsTeams/containers/AnalyticsCardsContainer";
import { Row, Col, Scrollbar } from "design-system-react";
import { api } from "../../../utils/client";

function AnalyticsContainer({ onSubMenuToggle, isSubmenuOpened, urlParams }) {
  const [analytics, setAnalytics] = useState(null);
  const [loadingAnalytics, setLoadingAnalytics] = useState(false);

  const [analyticsData, setAnalyticsData] = useState(null);
  const [loadingAnalyticsData, setLoadingAnalyticsData] = useState(false);

  useEffect(() => {
    setLoadingAnalytics(true);
    api.teams
      .analytics(
        urlParams.period,
        urlParams.periodFrom,
        urlParams.periodTo,
        urlParams.channel
      )

      .ready.then(response => {
        setAnalytics(response.data.data);
      })

      .finally(() => {
        setLoadingAnalytics(false);
      });
  }, [
    urlParams.period,
    urlParams.periodFrom,
    urlParams.periodTo,
    urlParams.channel
  ]);

  useEffect(() => {
    setLoadingAnalyticsData(true);
    api.teams
      .analyticsData(
        urlParams.team,
        urlParams.period,
        urlParams.periodFrom,
        urlParams.periodTo
      )

      .ready.then(response => {
        setAnalyticsData(response.data.data);
      })

      .finally(() => {
        setLoadingAnalyticsData(false);
      });
  }, [
    urlParams.team,
    urlParams.period,
    urlParams.periodFrom,
    urlParams.periodTo
  ]);

  return (
    <Row fullHeight column>
      <Col auto>
        <AnalyticsNavPanelContainer
          onSubMenuToggle={onSubMenuToggle}
          isSubmenuOpened={isSubmenuOpened}
          loadingAnalytics={loadingAnalytics}
          urlParams={urlParams}
        />
      </Col>
      <Col justified>
        <Scrollbar>
          <Row fullHeight column nowrap>
            <AnalyticsCardsContainer
              loadingAnalytics={loadingAnalytics}
              loadingAnalyticsData={loadingAnalyticsData}
              analytics={analytics}
              analyticsData={analyticsData}
              urlParams={urlParams}
            />
          </Row>
        </Scrollbar>
      </Col>
    </Row>
  );
}

export default AnalyticsContainer;
