import React from "react";
import styles from "./index.module.css";
import { Preloader } from "design-system-react";

function CenteredPreloader() {
  return (
    <div className={styles.wrapper}>
      <Preloader size="32" color="#807d7f" />
    </div>
  );
}

export default React.memo(CenteredPreloader);
