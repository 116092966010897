import React, { useCallback } from "react";
import { GutterField, Input } from "design-system-react";
import EditableField from "components/EditableField";
import { connect } from "react-redux";
import { api } from "utils/client";
import { ALERT_SUCCESS, showAlert } from "utils/alertManager";
import { FormError } from "utils/formError";
import EditablePhoneField from "components/EditablePhoneField";

function EndUserExtraFieldItemContainer({
  extraField,
  endUserId,
  extraFieldValues
}) {
  const handleFieldUpdate = useCallback(
    (value, { setErrors, setSuccess }) => {
      const validate = () => {
        if (extraField.label === "Phone number") {
          let phoneReg = /^\+?[0-9]{0,25}$/;
          let validPhone = phoneReg.test(value);
          if (value && !validPhone) {
            setErrors({ phone: "Phone number is not valid." });
            return false;
          } else {
            return true;
          }
        }
        return true;
      };

      const id = extraField.id;

      validate() &&
        api.endUsers
          .updateExtraFields(endUserId, { id, value })
          .ready.then(() => {
            setSuccess(true);
            showAlert(
              ALERT_SUCCESS,
              `${extraField.label} was successfully changed`
            );
          })
          .catch(e => {
            if (e instanceof FormError) {
              setErrors(e.errors);
            }
          });
    },
    [endUserId, extraField.id, extraField.label]
  );

  return (
    <GutterField gutterBottom={20}>
      {extraField.name === "phoneNumber" ? (
        <EditablePhoneField
          initialValue={extraFieldValues[extraField.name]}
          label={extraField.label}
          size="sm"
          onUpdate={handleFieldUpdate}
        />
      ) : (
        <EditableField
          initialValue={extraFieldValues[extraField.name]}
          label={extraField.label}
          component={Input}
          size="sm"
          onUpdate={handleFieldUpdate}
        />
      )}
    </GutterField>
  );
}
const mapStateToProps = (state, props) => {
  return {
    extraField: state.entities.extraFields[props.extraFieldId],
    extraFieldValues: state.entities.endUsers[props.endUserId].extraFields || []
  };
};

export default connect(mapStateToProps)(EndUserExtraFieldItemContainer);
