import React from "react";
import classNames from "classnames";
import styles from "./DragHandler.module.css";

const dragIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="6"
    height="18"
    viewBox="0 0 6 18"
  >
    <path
      fill="#7d7e80"
      d="M1003,703h2v2h-2v-2Zm4,0h2v2h-2v-2Zm-4,4h2v2h-2v-2Zm4,0h2v2h-2v-2Zm-4,4h2v2h-2v-2Zm4,0h2v2h-2v-2Zm-4,4h2v2h-2v-2Zm4,0h2v2h-2v-2Zm-4,4h2v2h-2v-2Zm4,0h2v2h-2v-2Z"
      transform="translate(-1003 -703)"
    />
  </svg>
);

function DragHandler({ active }) {
  const classes = classNames(styles.drag, active && styles.active);
  return <div className={classes}>{dragIcon}</div>;
}

export default DragHandler;
