import { CALL_API } from "utils/api";
import { withNamespace } from "utils/namespace";
import { deleteNotification, notification } from "utils/notificationsManager";

const type = withNamespace("pages/teams");

// action types
export const SET_TEAMS_IDS = type("SET_TEAMS_IDS");
export const ADD_TEAMS_ID = type("ADD_TEAMS_ID");
export const REMOVE_TEAMS_IDS = type("REMOVE_TEAMS_IDS");

export const FETCH_TEAMS_SUCCESS = type("FETCH_TEAMS_SUCCESS");
export const DISABLE_TEAM_SUCCESS = type("DISABLE_TEAM_SUCCESS");
export const ENABLE_TEAM_SUCCESS = type("ENABLE_TEAM_SUCCESS");
export const CREATE_TEAM_SUCCESS = type("CREATE_TEAM_SUCCESS");

let initialState = {
  listIds: [],
  ids: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_TEAMS_IDS:
      return {
        ...state,
        ids: action.payload.ids
      };
    case ADD_TEAMS_ID:
      return {
        ...state,
        ids: [action.payload.id, ...state.ids]
      };
    case REMOVE_TEAMS_IDS:
      return {
        ...state,
        ids: state.ids.filter(id => id !== action.payload.ids)
      };
    case FETCH_TEAMS_SUCCESS:
      return {
        ...state,
        listIds: action.payload.data.map(team => team.id)
      };
    case CREATE_TEAM_SUCCESS:
      return {
        ...state,
        listIds: [...state.listIds, action.payload.data[0].id]
      };
    default:
      return state;
  }
}

// actions
export function setTeamsIds(ids) {
  return {
    type: SET_TEAMS_IDS,
    payload: {
      ids
    }
  };
}

export function addTeamsId(id) {
  return {
    type: ADD_TEAMS_ID,
    payload: {
      id
    }
  };
}

export function removeTeamIds(ids) {
  return {
    type: REMOVE_TEAMS_IDS,
    payload: {
      ids
    }
  };
}

export function fetchTeams(params) {
  return {
    [CALL_API]: {
      endpoint: "/teams",
      options: {
        method: "get",
        params
      },
      types: {
        success: FETCH_TEAMS_SUCCESS
      }
    }
  };
}

export function deleteTeam(id) {
  let notificationId = notification(
    "Deleting team...",
    "default",
    false,
    false
  );

  return dispatch => {
    const actionResponse = dispatch({
      [CALL_API]: {
        endpoint: "/teams/" + id,
        options: {
          method: "delete"
        }
      }
    });

    actionResponse.ready.then(action => {
      deleteNotification(notificationId);

      if (action.success) {
        notificationId = notification("Team deleted", "success");
      }
    });

    return actionResponse;
  };
}

export function disableTeam(id) {
  let notificationId = notification(
    "Disabling team...",
    "default",
    false,
    false
  );

  return dispatch => {
    const actionResponse = dispatch({
      [CALL_API]: {
        endpoint: "/teams/" + id + "/disable",
        options: {
          method: "post"
        },
        types: {
          success: DISABLE_TEAM_SUCCESS
        }
      }
    });

    actionResponse.ready.then(action => {
      deleteNotification(notificationId);

      if (action.success) {
        notificationId = notification("Team disabled", "success");
      }
    });

    return actionResponse;
  };
}

export function enableTeam(id) {
  let notificationId = notification(
    "Enabling team...",
    "default",
    false,
    false
  );

  return dispatch => {
    const actionResponse = dispatch({
      [CALL_API]: {
        endpoint: "/teams/" + id + "/enable",
        options: {
          method: "post"
        },
        types: {
          success: ENABLE_TEAM_SUCCESS
        }
      }
    });

    actionResponse.ready.then(action => {
      deleteNotification(notificationId);

      if (action.success) {
        notificationId = notification("Team enabled", "success");
      }
    });

    return actionResponse;
  };
}

export function createTeam(params, form) {
  return dispatch => {
    return dispatch({
      [CALL_API]: {
        endpoint: "/teams",
        options: {
          method: "post",
          data: params
        },
        types: {
          success: CREATE_TEAM_SUCCESS
        },
        form
      }
    });
  };
}

export function restoreTeam(id) {
  return dispatch => {
    return dispatch({
      [CALL_API]: {
        endpoint: `/teams/${id}/restore`,
        options: {
          method: "post"
        }
      }
    });
  };
}
