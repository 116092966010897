export function defineChannel(messenger) {
  switch (messenger) {
    case "blinger":
      return {
        name: "Live chat",
        image: "/images/widget/live-chat-ico.svg"
      };
    case "facebook":
      return {
        name: "Messenger",
        image: "/images/widget/messenger.svg"
      };
    case "vk":
      return { name: "VK", image: "/images/widget/vk.svg" };
    case "email":
      return {
        name: "Email",
        image: "/images/widget/email.svg"
      };
    case "viber":
      return {
        name: "Viber",
        image: "/images/widget/viber.svg"
      };
    case "twitter":
      return {
        name: "Twitter",
        image: "/images/widget/twitter.svg"
      };
    case "whatsapp":
      return {
        name: "WhatsApp",
        image: "/images/widget/whatsapp.svg"
      };
    case "telegrambot":
      return {
        name: "Telegram",
        image: "/images/widget/telegram.svg"
      };
    case "livechat":
      return {
        name: "Live chat",
        image: "/images/widget/live-chat.svg"
      };
    case "wechat":
      return {
        name: "Wechat",
        image: "/images/widget/wechat.svg"
      };
    case "line":
      return {
        name: "Line",
        image: "/images/widget/line.svg"
      };
    case "kik":
      return {
        name: "Kik",
        image: "/images/widget/kik.svg"
      };
    case "qq":
      return { name: "QQ", image: "/images/widget/qq.png" };
    case "ok":
      return { name: "Ok", image: "/images/widget/ok.svg" };
    case "twilio":
      return {
        name: "Twilio",
        image: "/images/widget/twilio.svg"
      };
    case "instagram":
      return {
        name: "Instagram",
        image: "/images/widget/instagram.svg"
      };
    case "skype":
      return {
        image: "/images/widget/skype.svg",
        name: "Skype"
      };
    default:
      return {
        name: "Messenger",
        image: "/images/widget/messenger.svg"
      };
  }
}
