import React, { useEffect } from "react";
import SettingsProfileContainer from "./containers/SettingsProfileContainer";
import SettingsSubMenu from "components/settings/SettingsSubMenu";
import SettingsLayout from "components/SettingsLayout";

function SettingsProfile() {
  useEffect(() => {
    document.title = "Account settings";
    localStorage.setItem("lastSettingRoute", "/settings/profile");
  }, []);
  return (
    <SettingsLayout
      renderSubmenu={() => (
        <SettingsSubMenu activeMenuItem="/settings/profile" />
      )}
      renderContent={() => <SettingsProfileContainer />}
    />
  );
}

export default SettingsProfile;
