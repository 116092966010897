import React, { useState, useCallback, useEffect } from "react";
import {
  Table,
  TableHeader,
  TableHeaderColumn,
  TableHeaderColumnMenu,
  TableBody,
  Container,
  Scrollbar,
  Preloader,
  Col
} from "design-system-react";
import {
  MODAL_DEFAULT,
  MODAL_ERROR,
  MODAL_PROCESS,
  MODAL_SUCCESS,
  showConfirmModal,
  updateConfirmModal
} from "utils/modalManager";
import { api } from "utils/client";
import { CommonError } from "utils/commonError";
import axios from "axios";
import { setVkChannels } from "modules/pages/settingsVk";
import connect from "react-redux/es/connect/connect";
import EmptyState from "components/EmptyState";
import SettingsChannelListItem from "components/SettingsChannelListItem";

function SettingsVkListContainer({ setVkChannels, channelIds }) {
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    setVkChannels([]);

    const request = api.vk.all();

    request.ready
      .then(response => {
        setVkChannels(response.data.data.map(channel => channel.id));
        setLoading(false);
      })
      .catch(error => {
        if (!axios.isCancel(error)) {
          setLoading(false);
        }
      });

    return () => {
      request.cancelRequest.cancel();
    };
  }, [setVkChannels]);

  const handleRestore = useCallback((channelId, name) => {
    const modalId = showConfirmModal(
      MODAL_DEFAULT,
      "Are you sure you want to restore " + name + "?",
      {
        actionButtonText: "Restore",
        onAction: () => {
          updateConfirmModal(modalId, MODAL_PROCESS, "Restoring...");

          api.vk
            .restore(channelId)
            .ready.then(() => {
              updateConfirmModal(
                modalId,
                MODAL_SUCCESS,
                "Channel was successfully restored"
              );
            })
            .catch(e => {
              const errorMessage = "Ooops, something went wrong";

              updateConfirmModal(
                modalId,
                MODAL_ERROR,
                e instanceof CommonError ? e.error : errorMessage
              );
            });
        }
      }
    );
  }, []);

  const handleDelete = useCallback((channelId, name) => {
    const modalId = showConfirmModal(
      MODAL_DEFAULT,
      "Are you sure you want to delete " + name + "?",
      {
        actionButtonText: "Delete",
        onAction: () => {
          updateConfirmModal(modalId, MODAL_PROCESS, "Deleting...");

          api.vk
            .delete(channelId)
            .ready.then(() => {
              updateConfirmModal(
                modalId,
                MODAL_SUCCESS,
                "Channel was successfully deleted"
              );
            })
            .catch(e => {
              const errorMessage = "Ooops, something went wrong";

              updateConfirmModal(
                modalId,
                MODAL_ERROR,
                e instanceof CommonError ? e.error : errorMessage
              );
            });
        }
      }
    );
  }, []);

  const handleActionDropdown = useCallback(
    (channelId, name, action) => {
      switch (action) {
        case "restore":
          handleRestore(channelId, name);
          break;
        case "delete":
          handleDelete(channelId, name);
          break;
        default:
          break;
      }
    },
    [handleRestore, handleDelete]
  );

  if (isLoading && !channelIds.length) {
    return (
      <Col align="center" justified>
        <Preloader size="32" color="#807d7f" />
      </Col>
    );
  }

  if (!channelIds.length) {
    return (
      <Col align="center" verticalAlign="center" justified>
        <EmptyState title="No channels integrated yet">
          You must add an integration with this channel <br /> to start
          receiving messages
        </EmptyState>
      </Col>
    );
  }

  return (
    <Scrollbar>
      <Container>
        <Table headerBorder>
          <TableHeader fixed>
            <TableHeaderColumn justified fixed>
              Name
            </TableHeaderColumn>
            <TableHeaderColumn>Added</TableHeaderColumn>
            <TableHeaderColumn>Status</TableHeaderColumn>
            <TableHeaderColumnMenu />
          </TableHeader>
          <TableBody>
            {channelIds.map(id => (
              <SettingsChannelListItem
                key={id}
                channelId={id}
                handleActionDropdown={handleActionDropdown}
              />
            ))}
          </TableBody>
        </Table>
      </Container>
    </Scrollbar>
  );
}

const mapStateToProps = state => {
  return { channelIds: state.pages.settingsVk.ids || [] };
};

const mapDispatchToProps = dispatch => ({
  setVkChannels: ids => dispatch(setVkChannels(ids))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsVkListContainer);
