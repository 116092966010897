import React, { useState, useCallback } from "react";
import connect from "react-redux/es/connect/connect";
import SettingsChannelHeader from "components/SettingsHeader";
import SettingsQuickRepliesListContainer from "./SettingsQuickRepliesListContainer";
import { Row, Col, Container } from "design-system-react";
import { setChannelTelegramIds } from "modules/pages/settingsTelegram";
import EmptyState from "components/EmptyState";
import CreateQuickReplyContainer from "pages/Cases/containers/CreateQuickReplyContainer";

function SettingsQuickRepliesContainer({ quickRepliesIds }) {
  const [createReplyIsOpen, setCreateReplyIsOpen] = useState(false);

  const toggleCreateReply = useCallback(() => {
    setCreateReplyIsOpen(!createReplyIsOpen);
  }, [createReplyIsOpen]);

  return (
    <Row fullHeight column>
      <Container>
        <SettingsChannelHeader
          title="Quick replies"
          onAction={toggleCreateReply}
          buttonText="Create"
        >
          Quick replies are pre-saved replies, which agent can use at any moment
          of case processing to speed up his work. Quick replies can be called
          upon pressing the keys /
        </SettingsChannelHeader>
      </Container>
      {!quickRepliesIds || quickRepliesIds.length === 0 ? (
        <Col align="center" verticalAlign="center" justified>
          <EmptyState title="No Quick replies yet">
            Currently you have no quick replies <br /> created.
          </EmptyState>
        </Col>
      ) : (
        <Col justified>
          <SettingsQuickRepliesListContainer
            quickRepliesIds={quickRepliesIds}
          />
        </Col>
      )}
      <CreateQuickReplyContainer
        isOpen={createReplyIsOpen}
        toggle={toggleCreateReply}
      />
    </Row>
  );
}

const mapStateToProps = state => {
  const quickRepliesListIds = state.quickReplies.ids || [];

  const sortedQuickReplies = quickRepliesListIds.sort((a, b) => {
    if (a.createdAt > b.createdAt) {
      return -1;
    } else if (a.createdAt === b.createdAt) {
      return 0;
    } else {
      return 1;
    }
  });

  return {
    quickRepliesIds: sortedQuickReplies
  };
};

const mapDispatchToProps = dispatch => ({
  setChannelTelegramIds: ids => dispatch(setChannelTelegramIds(ids))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsQuickRepliesContainer);
