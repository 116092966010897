import { useCallback, useEffect, useState } from "react";
import { Badge, Input, List, Modal, Popover, Tabs, Typography } from "antd";
import {
  EllipsisOutlined,
  SearchOutlined,
  LoadingOutlined
} from "@ant-design/icons";
import View from "components/View";
import { api } from "utils/client";
import SearchResult from "components/searchResult/SearchResult";
import { casesIcon, usersIcon } from "components/Menu";
import useDebounce from "../../hooks/useDebounce";
import axios from "axios";
import styles from "./SearchModal.module.scss";
import { printQualification } from "helpers/qualifications";
import DateTime from "components/DateTime";
import { getCaseStatusTitle } from "helpers/cases";

const { TabPane } = Tabs;

const icon = function(entityType: string) {
  switch (entityType) {
    case "endUser":
      return usersIcon;
    case "case":
    case "message":
      return casesIcon;
    default:
      return null;
  }
};

const extractHighlights = function(highlights: any, entityType: string) {
  switch (entityType) {
    case "endUser":
      return highlights && highlights.name && highlights.name[0];
    case "message":
      return highlights && highlights.text && highlights.text[0];
    case "case":
      return highlights && highlights.endUserName && highlights.endUserName[0];
    default:
      return null;
  }
};

// const extractHits = function(highlights: string) {
//   let hits = "";

//   const parts = highlights.split(/<em>|<\/em>/);

//   parts.map((part, index) => {
//     if (index % 2 === 0 && part !== "") {
//       hits += part;
//     }

//     return part;
//   });

//   return hits;
// };

const renderEndUserResult = (item: any, onClick?: () => void) => {
  const actions = [
    printQualification(item.entity.qualification),
    item.entity.channelLabel
  ];

  if (item.entity.ownerTeamMember) {
    actions.push(item.entity.ownerTeamMember.name);
  }

  actions.push(
    <Popover
      placement="topRight"
      trigger={["hover"]}
      arrowPointAtCenter
      content={
        <>
          <Typography.Title level={5}>Contact</Typography.Title>
          <div>
            <Typography.Text strong>Status: </Typography.Text>
            {printQualification(item.entity.qualification)}
          </div>
          <div>
            <Typography.Text strong>Channel: </Typography.Text>
            {item.entity.channelTitle} ({item.entity.channelLabel})
          </div>
          {item.entity.ownerTeamMember && (
            <div>
              <Typography.Text strong>Owner: </Typography.Text>
              {item.entity.ownerTeamMember.name}
            </div>
          )}
          <div>
            <Typography.Text strong>Last activity: </Typography.Text>
            <DateTime time={item.entity.lastActivityAt} />
          </div>
        </>
      }
    >
      <EllipsisOutlined
        style={{
          padding: "5px 10px",
          margin: "-5px -10px"
        }}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
      />
    </Popover>
  );

  return (
    <SearchResult
      to={"/end-user/" + item.entity.id}
      title={extractHighlights(item.highlights, item.type)}
      actions={actions}
      icon={icon(item.type)}
      onClick={() => {
        onClick && onClick();
      }}
    />
  );
};

const renderMessageResult = (item: any, onClick?: () => void) => {
  const actions = [
    getCaseStatusTitle(item.entity.cases.status),
    item.entity.cases.conversation.channel.label
  ];

  if (item.entity.cases.assignedTeamMember) {
    actions.push(item.entity.cases.assignedTeamMember.name);
  }

  actions.push(
    <Popover
      placement="topRight"
      trigger={["hover"]}
      arrowPointAtCenter
      content={
        <div>
          <Typography.Title level={5}>Case</Typography.Title>
          <div>
            <Typography.Text strong>Status: </Typography.Text>
            {getCaseStatusTitle(item.entity.cases.status)}
          </div>
          <div>
            <Typography.Text strong>Channel: </Typography.Text>
            {item.entity.cases.conversation.channel.title} (
            {item.entity.cases.conversation.channel.label})
          </div>
          {item.entity.cases.assignedTeamMember && (
            <div>
              <Typography.Text strong>Assignee: </Typography.Text>
              {item.entity.cases.assignedTeamMember.name}
            </div>
          )}
          <div>
            <Typography.Text strong>Updated date: </Typography.Text>
            <DateTime time={item.entity.cases.updatedAt} />
          </div>
        </div>
      }
    >
      <EllipsisOutlined
        style={{
          padding: "5px 10px",
          margin: "-5px -10px"
        }}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
      />
    </Popover>
  );

  const highlights = extractHighlights(item.highlights, item.type);

  return (
    <SearchResult
      to={"/cases/all/" + item.entity.cases.id + "?messageId=" + item.entity.id}
      title={item.entity.cases.endUser.name}
      actions={actions}
      icon={icon(item.type)}
      onClick={() => {
        onClick && onClick();
      }}
    >
      {highlights}
    </SearchResult>
  );
};

const renderCaseResult = (item: any, onClick?: () => void) => {
  const actions = [
    getCaseStatusTitle(item.entity.status),
    item.entity.conversation.channel.label
  ];

  if (item.entity.assignedTeamMember) {
    actions.push(item.entity.assignedTeamMember.name);
  }

  actions.push(
    <Popover
      placement="topRight"
      arrowPointAtCenter
      content={
        <>
          <Typography.Title level={5}>Case</Typography.Title>
          <div>
            <Typography.Text strong>Status: </Typography.Text>
            {getCaseStatusTitle(item.entity.status)}
          </div>
          <div>
            <Typography.Text strong>Channel: </Typography.Text>
            {item.entity.conversation.channel.label}
          </div>
          {item.entity.assignedTeamMember && (
            <div>
              <Typography.Text strong>Assignee: </Typography.Text>
              {item.entity.assignedTeamMember.name}
            </div>
          )}
          <div>
            <Typography.Text strong>Updated date: </Typography.Text>
            <DateTime time={item.entity.updatedAt} />
          </div>
        </>
      }
    >
      <EllipsisOutlined />
    </Popover>
  );

  return (
    <SearchResult
      to={"/cases/all/" + item.entity.id}
      title={extractHighlights(item.highlights, item.type)}
      actions={actions}
      icon={icon(item.type)}
      onClick={() => {
        onClick && onClick();
      }}
    />
  );
};

const renderResults = (results: Array<any>, onResultClick?: () => void) => {
  return (
    <List
      dataSource={results}
      renderItem={(item: any) => {
        if (item.type === "endUser") {
          return renderEndUserResult(item, onResultClick);
        }

        if (item.type === "message") {
          return renderMessageResult(item, onResultClick);
        }

        if (item.type === "case") {
          return renderCaseResult(item, onResultClick);
        }
      }}
    />
  );
};

export interface SearchModalProps {
  visible?: boolean;
  onClose?: () => void;
}

let lastRequest: any = null;

function SearchModal({ visible, onClose }: SearchModalProps) {
  const [query, setQuery] = useState<{ query: string; force?: boolean }>({
    query: ""
  });
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [counters, setCounters] = useState<any>({});

  const debouncedQuery = useDebounce(
    query.query,
    query.query.length === 0 || query.force ? 0 : 600
  );

  const search = useCallback(query => {
    if (lastRequest) {
      lastRequest.cancelRequest.cancel();
    }

    if (typeof query !== "string" || query.trim() === "" || query.length < 2) {
      return;
    }

    setLoading(true);
    setResults([]);
    setCounters([]);

    const request = api.search.all(query);

    request.ready
      .then(response => {
        setResults(response.data.data);
        setCounters(response.data.total);
        setLoading(false);
      })
      .catch(e => {
        if (!axios.isCancel(e)) {
          setLoading(false);
        }
      });

    lastRequest = request;
  }, []);

  const clear = () => {
    setResults([]);
    setCounters([]);
    setQuery({ query: "", force: false });
    setLoading(false);
  };

  useEffect(() => {
    if (!visible) {
      clear();
    }
  }, [visible]);

  useEffect(() => {
    search(debouncedQuery);
    // eslint-disable-next-line
  }, [debouncedQuery]);

  return (
    <Modal
      className={styles.modal}
      width={600}
      title="Search"
      footer={null}
      visible={visible}
      onCancel={onClose}
      destroyOnClose
    >
      <View padding="24px">
        <View marginBottom="16px">
          <Input
            size="large"
            placeholder="Input search text"
            autoFocus
            onChange={e => {
              setQuery({ query: e.target.value, force: false });

              if (e.target.value === "") {
                clear();
              }
            }}
            onPressEnter={e => {
              setQuery({ query: query.query, force: true });
            }}
            prefix={<SearchOutlined style={{ opacity: 0.5 }} />}
            allowClear={!loading}
            suffix={
              loading ? <LoadingOutlined style={{ opacity: 0.5 }} /> : null
            }
          />
        </View>
        {loading && results.length === 0 && (
          <Typography.Text type="secondary">Searching...</Typography.Text>
        )}

        {!loading && debouncedQuery.length < 2 && results.length === 0 && (
          <Typography.Text type="secondary">
            Search for cases and contacts using keywords or phrases.
          </Typography.Text>
        )}

        {!loading && debouncedQuery.length >= 2 && results.length === 0 && (
          <Typography.Text type="secondary">
            No results. Try different search terms.
          </Typography.Text>
        )}
      </View>

      {!loading && debouncedQuery.length >= 2 && results.length > 0 && (
        <>
          <Tabs defaultActiveKey="1">
            <TabPane
              tab={
                <>
                  All results{" "}
                  <Badge
                    showZero={true}
                    count={counters["endUsers"] + counters["messages"]}
                    style={{
                      backgroundColor: "#fff",
                      color: "#00000073",
                      borderColor: "#D9D9D9"
                    }}
                  />
                </>
              }
              key="all"
            >
              {renderResults(results, onClose)}
            </TabPane>
            <TabPane
              tab={
                <>
                  Cases{" "}
                  <Badge
                    showZero={true}
                    count={counters["messages"]}
                    style={{
                      backgroundColor: "#fff",
                      color: "#00000073",
                      borderColor: "#D9D9D9"
                    }}
                  />
                </>
              }
              key="cases"
            >
              {renderResults(
                results.filter(
                  (r: any) => r.type === "message" || r.type === "case"
                ),
                onClose
              )}
            </TabPane>
            <TabPane
              tab={
                <>
                  Contacts{" "}
                  <Badge
                    showZero={true}
                    count={counters["endUsers"]}
                    style={{
                      backgroundColor: "#fff",
                      color: "#00000073",
                      borderColor: "#D9D9D9"
                    }}
                  />
                </>
              }
              key="contacts"
            >
              {renderResults(
                results.filter((r: any) => r.type === "endUser"),
                onClose
              )}
            </TabPane>
          </Tabs>
        </>
      )}
    </Modal>
  );
}

export default SearchModal;
