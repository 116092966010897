import React, { useRef, useState, useEffect, useCallback } from "react";
import {
  Popup,
  PopupBody,
  PopupHeader,
  PopupFooter,
  Button
} from "design-system-react";
import { Formik } from "formik";
import { api } from "utils/client";
import EditTeamModalFormContainer from "./EditTeamModalFormContainer";
import { FormError } from "utils/formError";

function EditTeamModalContainer({ isOpen, toggle, team }) {
  const form = useRef(null);
  const [submitDisabled, setSubmitDisabled] = useState(true);

  useEffect(() => {
    if (team) {
      form.current.resetForm({
        title: team.title,
        color: team.color,
        private: team.private,
      });
    }
  }, [team]);

  const handleSubmit = useCallback(
    (values, { setSubmitting, setErrors }) => {
      api.teams
        .update(team.id, values)
        .ready.then(() => {
          toggle();
        })
        .catch(e => {
          if (e instanceof FormError) {
            setErrors(e.errors);
          }
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [team, toggle]
  );

  if (!team) {
    return null;
  }

  return (
    <Formik
      ref={form}
      enableReinitialize
      initialValues={{
        title: "",
        color: "",
        private: null,
      }}
      onSubmit={handleSubmit}
      render={props => (
        <Popup isOpen={isOpen} onClose={toggle}>
          <PopupHeader>Edit team</PopupHeader>
          <PopupBody>
            <EditTeamModalFormContainer
              {...props}
              submitDisabled={submitDisabled}
              setSubmitDisabled={setSubmitDisabled}
            />
          </PopupBody>
          <PopupFooter>
            <Button
              onClick={props.submitForm}
              processing={props.isSubmitting}
              disabled={submitDisabled}
            >
              Save
            </Button>
            <Button outline={true} onClick={toggle}>
              Cancel
            </Button>
          </PopupFooter>
        </Popup>
      )}
    />
  );
}

export default EditTeamModalContainer;
