import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  useReducer,
  useMemo
} from "react";
import { connect } from "react-redux";
import { MESSAGE_STATUS_SENDING } from "modules/pages/cases";
import uuidv4 from "utils/uuidv4";
import MessageForm from "pages/Cases/components/MessageForm";
import MessageFormAttachments from "pages/Cases/components/MessageFormAttachments";
import MessageFormAttachment from "pages/Cases/components/MessageFormAttachment";
import MessageFormInputWrapper from "pages/Cases/components/MessageFormInputWrapper";
import MessageFormInput from "pages/Cases/components/MessageFormInput";
import MessageFormButtons from "pages/Cases/components/MessageFormButtons";
import MessageFormAttachButton from "pages/Cases/components/MessageFormAttachButton";
import MessageFormNoteButton from "pages/Cases/components/MessageFormNoteButton";
import MessageFormSendButton from "pages/Cases/components/MessageFormSendButton";
import { api } from "../../../utils/client";
import {
  addAttachment,
  addMessageId,
  addOutgoingMessage,
  removeOutgoingMessage,
  removeAllAttachments,
  removeAttachment,
  updateAttachment,
  updateOutgoingMessageStatus
} from "../../../modules/pages/newcases";
import NotePopupContainer from "./NotePopupContainer";
import QuickReplyContainer from "./QuickReplyContainer";
import CreateQuickReplyContainer from "./CreateQuickReplyContainer";
import SmartReplyContainer from "./SmartReplyContainer";
import MessageFormEmojiButton from "../components/MessageFormEmojiButton";

import { FormError } from "utils/formError";
import { ALERT_ERROR, showAlert } from "utils/alertManager";
import { createSelector } from "reselect";

function filesProgressReducer(state, action) {
  switch (action.type) {
    case "update":
      return {
        ...state,
        [action.uuid]: {
          ...state[action.uuid],
          uploadedSize: action.size,
          error: action.error
        }
      };
    case "delete":
      const newState = { ...state };

      delete newState[action.uuid];

      return newState;
    default:
      throw new Error();
  }
}

function MessageFormContainer({
  caseId,
  attachments,
  sendMessage,
  isEndUserModal,
  addAttachment,
  updateAttachment,
  removeAttachment,
  removeAllAttachments,
  uploadFile,
  addOutgoingMessage,
  updateOutgoingMessageStatus,
  removeOutgoingMessage,
  addMessageId,
  channelType
}) {
  const [quickReplyIsOpen, setQuickReplyIsOpen] = useState(false);
  const [noteIsOpen, setNoteIsOpen] = useState(false);
  const [createReplyIsOpen, setCreateReplyIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [isOpenEmoji, setIsOpenEmoji] = useState(false);
  const [filesProgress, dispatchFilesProgress] = useReducer(
    filesProgressReducer,
    {}
  );
  const messageInputRef = useRef(null);

  const resetState = useCallback(() => {
    setMessage("");

    removeAllAttachments(caseId);
  }, [caseId, removeAllAttachments]);

  const toggleNotePopup = useCallback(() => {
    setNoteIsOpen(!noteIsOpen);
  }, [noteIsOpen]);

  const toggleQuickReply = useCallback(() => {
    setQuickReplyIsOpen(!quickReplyIsOpen);
  }, [quickReplyIsOpen]);

  const toggleCreateReply = useCallback(() => {
    setCreateReplyIsOpen(!createReplyIsOpen);
  }, [createReplyIsOpen]);

  const toggleEmoji = useCallback(() => {
    setIsOpenEmoji(!isOpenEmoji);
  }, [isOpenEmoji]);

  const submitMessage = useCallback(() => {
    const uuid = uuidv4();

    const fileIds = attachments
      .filter(file => file.uploaded)
      .map(file => file.id);

    if ((!message || message.trim().length === 0) && fileIds.length === 0) {
      return;
    }

    if (isOpenEmoji) {
      toggleEmoji();
    }
    addOutgoingMessage(caseId, {
      caseId,
      uuid: uuid,
      content: message,
      files: attachments.filter(file => file.uploaded),
      status: MESSAGE_STATUS_SENDING,
      createdAt: Math.floor(new Date().getTime() / 1000)
    });

    api.cases
      .sendMessage(caseId, {
        uuid,
        text: message,
        files: fileIds
      })
      .ready.then(response => {
        addMessageId(response.data.data.id);
        removeOutgoingMessage(caseId, uuid);
      })
      .catch(() => {
        updateOutgoingMessageStatus(caseId, uuid, "error");
      });

    resetState();
  }, [
    message,
    attachments,
    caseId,
    resetState,
    isOpenEmoji,
    addMessageId,
    addOutgoingMessage,
    removeOutgoingMessage,
    toggleEmoji,
    updateOutgoingMessageStatus
  ]);

  const handleChange = useCallback(e => {
    setMessage(e.target.value);
  }, []);

  const handleQuickReplySelect = useCallback(message => {
    setMessage(message);

    messageInputRef.current.focus();
  }, []);

  const handleSmartReplySelect = useCallback(message => {
    setMessage(message);

    messageInputRef.current.focus();
  }, []);

  const handleFilesSelected = useCallback(
    e => {
      let files = [...e.currentTarget.files];

      if (files.length + attachments.length > 9) {
        showAlert(ALERT_ERROR, "Allowed maximum 9 attach file");
        files = files.slice(0, 9 - attachments.length);
      }

      for (let i = 0; i < files.length; i++) {
        const uuid = uuidv4();
        const file = files[i];

        const request = api.files.uploadChannelFile(file, channelType, e => {
          dispatchFilesProgress({
            type: "update",
            uuid,
            size: e.loaded
          });
        });

        if (channelType === "telegrambot" && file.size > 100000000) {
          request.cancelRequest.cancel();
          showAlert(ALERT_ERROR, "Max size 20 MB");
        }

        if (channelType !== "telegrambot" && file.size > 100000000) {
          request.cancelRequest.cancel();
          showAlert(ALERT_ERROR, "Max size 100 MB");
        }

        addAttachment(caseId, {
          uuid,
          name: file.name,
          ext: /(?:\.([^.]+))?$/.exec(file.name)[1],
          type: file.type,
          size: file.size,
          uploaded: false,
          cancelRequest: request.cancelRequest
        });

        dispatchFilesProgress({
          type: "update",
          uuid,
          size: 0
        });

        request.ready
          .then(response => {
            updateAttachment(caseId, {
              id: response.data.data.id,
              uuid,
              name: response.data.data.info.name,
              uploaded: true
            });

            dispatchFilesProgress({
              type: "delete",
              uuid
            });

            messageInputRef.current.focus();
          })
          .catch(e => {
            if (e instanceof FormError) {
              dispatchFilesProgress({
                type: "update",
                uuid,
                error: e.errors.file
              });
            } else {
              dispatchFilesProgress({
                type: "delete",
                uuid
              });
              removeAttachment(caseId, uuid);
            }
          });

        e.target.value = null;
      }
    },
    [
      caseId,
      attachments.length,
      addAttachment,
      channelType,
      removeAttachment,
      updateAttachment
    ]
  );

  const handleAttachmentDelete = useCallback(
    uuid => {
      attachments.map(a => {
        if (a.uuid === uuid) {
          a.cancelRequest && a.cancelRequest.cancel();
        }

        return a;
      });

      removeAttachment(caseId, uuid);

      messageInputRef.current.focus();
    },
    [caseId, attachments, removeAttachment]
  );

  const handleAttachmentsClose = useCallback(() => {
    attachments.map(a => {
      a.cancelRequest.cancel();
      return a;
    });

    removeAllAttachments(caseId);

    messageInputRef.current.focus();
  }, [caseId, attachments, removeAllAttachments]);

  const showSendButton = message || attachments.length > 0;

  const filesUploading = useMemo(() => {
    return (
      Object.keys(filesProgress).filter(key => !filesProgress[key].error)
        .length !== 0
    );
  }, [filesProgress]);

  const handleKeyDown = useCallback(
    e => {
      if (e.key === "Enter") {
        if (!e.shiftKey) {
          e.preventDefault();

          if (!filesUploading) {
            submitMessage();
          }
        }
      }
    },
    [submitMessage, filesUploading]
  );

  useEffect(() => {
    // when caseId changed
    resetState();

    if (caseId) {
      messageInputRef.current.focus();
    }
  }, [caseId, resetState]);

  useEffect(() => {
    const trimmedMessage = message.trim();
    if (trimmedMessage.startsWith("/") && !trimmedMessage.startsWith("//")) {
      if (!quickReplyIsOpen) {
        setQuickReplyIsOpen(true);
      }
    } else if (quickReplyIsOpen) {
      setQuickReplyIsOpen(false);
    }
  }, [message, quickReplyIsOpen]);

  useEffect(() => {
    // when caseId changed
    resetState();

    if (caseId) {
      messageInputRef.current.focus();
    }
  }, [caseId, resetState]);

  const handleEmojiClick = useCallback(
    emoji => {
      setMessage(message + emoji);
      messageInputRef.current.focus();
    },
    [message]
  );

  return (
    <>
      <NotePopupContainer
        isOpen={noteIsOpen}
        toggle={toggleNotePopup}
        isEndUserModal={isEndUserModal}
        caseId={caseId}
      />
      <CreateQuickReplyContainer
        isOpen={createReplyIsOpen}
        toggle={toggleCreateReply}
        isEndUserModal={isEndUserModal}
      />
      <MessageForm>
        <SmartReplyContainer
          caseId={caseId}
          onSmartReplySelect={handleSmartReplySelect}
        />
        <QuickReplyContainer
          isOpen={quickReplyIsOpen}
          toggle={toggleQuickReply}
          toggleCreateModal={toggleCreateReply}
          caseId={caseId}
          filter={message}
          onReplySelect={handleQuickReplySelect}
        />
        {attachments.length > 0 && (
          <MessageFormAttachments onCloseClick={handleAttachmentsClose}>
            {attachments.map(attachment => (
              <MessageFormAttachment
                key={attachment.uuid}
                id={attachment.id}
                name={attachment.name}
                size={attachment.size}
                uploadedSize={
                  filesProgress[attachment.uuid] &&
                  filesProgress[attachment.uuid].uploadedSize
                }
                error={
                  filesProgress[attachment.uuid] &&
                  filesProgress[attachment.uuid].error
                }
                ext={attachment.ext}
                type={attachment.type}
                extUploaded={
                  attachment.uploadedFile &&
                  attachment.uploadedFile.info.name
                    .match(/[0-9a-zA-z]+$/)
                    .toString()
                }
                typeUploaded={
                  attachment.uploadedFile && attachment.uploadedFile.type
                }
                preview={
                  attachment.uploadedFile &&
                  attachment.uploadedFile.url["200x200"]
                }
                uploaded={attachment.uploaded}
                onDeleteClick={() => {
                  handleAttachmentDelete(attachment.uuid);
                }}
                channelType={channelType}
              />
            ))}
          </MessageFormAttachments>
        )}
        <MessageFormInputWrapper>
          <MessageFormInput
            innerRef={messageInputRef}
            value={message}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          />
          <MessageFormButtons>
            {!quickReplyIsOpen && (
              <MessageFormAttachButton onSelectFiles={handleFilesSelected} />
            )}
            {!quickReplyIsOpen && (
              <MessageFormEmojiButton
                onEmojiClick={handleEmojiClick}
                toggleEmoji={toggleEmoji}
                isOpenEmoji={isOpenEmoji}
              />
            )}

            {!showSendButton && (
              <MessageFormNoteButton onClick={toggleNotePopup} />
            )}
            {showSendButton && (
              <MessageFormSendButton
                onClick={submitMessage}
                disabled={filesUploading}
              />
            )}
          </MessageFormButtons>
        </MessageFormInputWrapper>
      </MessageForm>
    </>
  );
}

const getAttachments = createSelector(
  [
    (state, props) => state.pages.cases.attachmentsByCase[props.caseId],
    state => state.entities.files
  ],
  (attachments, files) => {
    return (attachments || []).map(file => {
      if (!file.id) {
        return file;
      }

      return {
        ...file,
        uploadedFile: files[file.id]
      };
    });
  }
);

const mapStateToProps = (state, props) => {
  return {
    attachments: getAttachments(state, props)
  };
};

const mapDispatchToProps = dispatch => ({
  addMessageId: id => dispatch(addMessageId(id)),
  addOutgoingMessage: (caseId, message) =>
    dispatch(addOutgoingMessage(caseId, message)),
  updateOutgoingMessageStatus: (caseId, uuid, status) =>
    dispatch(updateOutgoingMessageStatus(caseId, uuid, status)),
  removeOutgoingMessage: (caseId, uuid) =>
    dispatch(removeOutgoingMessage(caseId, uuid)),
  addAttachment: (caseId, file) => dispatch(addAttachment(caseId, file)),
  updateAttachment: (caseId, file) => dispatch(updateAttachment(caseId, file)),
  removeAttachment: (caseId, uuid) => dispatch(removeAttachment(caseId, uuid)),
  removeAllAttachments: caseId => dispatch(removeAllAttachments(caseId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MessageFormContainer);
