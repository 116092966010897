import React, { useState, useCallback, useEffect } from "react";
import { extractFirstLetters } from "../../../utils/string";
import history from "utils/history";
import { makeAnalyticUrl } from "../../../helpers/analytics";
import format from "date-fns/format";
import NavPanelContent from "components/NavPanel/NavPanelContent";
import NavPanel from "components/NavPanel";
import CollapseButton from "components/NavPanel/CollapseButton";
import {
  Button,
  GroupButtons,
  DatePickerRange,
  Dropdown,
  DropdownToggle,
  DropdownToggleDefault,
  DropdownItemWithAvatar,
  DropdownMenu,
  DropdownItem,
  Avatar,
  DropdownMenuDivider
} from "design-system-react";
import DatePickerWrapper from "../components/DatePickerWrapper";
import PanelDropdownWrapper from "../components/PanelDropdownWrapper";
import { TEAM_MEMBER_STATUS_PENDING } from "../../../helpers/users";
import CollapseMenu from "../../../components/CollapseMenu";

function AnalyticsNavPanelContainer({
  onSubMenuToggle,
  isSubmenuOpened,
  onPeriodChange,
  loading,
  urlParams,
  selectedItemDropdown,
  isOpen,
  toggle,
  handleChangeDropdown,
  teamMembers
}) {
  const groupButtons = [
    { title: "Day", value: "day" },
    { title: "Week", value: "week" },
    { title: "Month", value: "month" }
  ];

  const [periodFrom, setPeriodFrom] = useState(
    urlParams.periodFrom && new Date(urlParams.periodFrom)
  );

  const [periodTo, setPeriodTo] = useState(
    urlParams.periodTo && new Date(urlParams.periodTo)
  );

  const [selectedPeriod, setSelectedPeriod] = useState(
    urlParams.period || "day"
  );

  const handleChangePeriod = useCallback(
    groupButton => {
      setSelectedPeriod(groupButton.value);
      setPeriodFrom("");
      setPeriodTo("");

      history.push(
        makeAnalyticUrl("/analytics/team-members", {
          ...urlParams,
          period: groupButton.value,
          periodFrom: "",
          periodTo: ""
        })
      );
    },
    [urlParams]
  );
  const selectedItem = groupButtons.filter(i => i.value === selectedPeriod)[0];

  const handleChangeStartDate = useCallback(
    date => {
      setPeriodFrom(date);
      if (!periodTo && !date) {
        setSelectedPeriod("day");
        history.push(
          makeAnalyticUrl("/analytics/team-members", {
            ...urlParams,
            period: "day",
            periodFrom: "",
            periodTo: ""
          })
        );
      }
    },
    [periodTo, urlParams]
  );

  const handleChangeEndDate = useCallback(
    date => {
      setPeriodTo(date);
      if (!date && !periodFrom) {
        setSelectedPeriod("day");
        history.push(
          makeAnalyticUrl("/analytics/team-members", {
            ...urlParams,
            period: "day",
            periodFrom: "",
            periodTo: ""
          })
        );
      }
    },
    [periodFrom, urlParams]
  );

  useEffect(() => {
    if (periodFrom && periodTo && periodFrom <= periodTo) {
      setSelectedPeriod("");

      history.push(
        makeAnalyticUrl("/analytics/team-members", {
          ...urlParams,
          period: "",
          periodFrom: format(periodFrom, "yyyy-MM-dd"),
          periodTo: format(periodTo, "yyyy-MM-dd")
        })
      );
    }
  }, [periodTo, periodFrom, urlParams]);

  const title =
    "Team members" +
    (!selectedItemDropdown || !selectedItemDropdown.name
      ? ""
      : ": " + selectedItemDropdown.name);

  return (
    <NavPanel>
      <NavPanelContent>
        <CollapseButton
          handleClick={onSubMenuToggle}
          isOpen={isSubmenuOpened}
        />
        <CollapseMenu
          title={title}
          withoutDropdown={isSubmenuOpened}
          items={[
            {
              id: "cases",
              to: "/analytics/cases",
              active: false,
              title: "Cases"
            },
            {
              id: "teamMembers",
              to: "/analytics/team-members",
              active: true,
              title: "Team members"
            },
            {
              id: "teams",
              to: "/analytics/teams",
              active: false,
              title: "Teams"
            }
          ]}
        />
      </NavPanelContent>
      <NavPanelContent>
        <GroupButtons>
          {groupButtons.map(groupButton => (
            <Button
              size="sm"
              group={true}
              onClick={() => {
                handleChangePeriod(groupButton);
              }}
              active={selectedItem && selectedItem.value === groupButton.value}
            >
              {groupButton.title}
            </Button>
          ))}
        </GroupButtons>

        <DatePickerWrapper>
          <DatePickerRange
            now={true}
            size="sm"
            placeholderFrom="Start date"
            placeholderTo="End date"
            noLabel={true}
            startDate={periodFrom}
            endDate={periodTo}
            onChangeStartDate={handleChangeStartDate}
            onChangeEndDate={handleChangeEndDate}
          />
        </DatePickerWrapper>

        <PanelDropdownWrapper>
          <Dropdown
            isOpen={isOpen}
            toggle={toggle}
            nonEmpty={selectedItemDropdown}
            size="sm"
          >
            <DropdownToggle>
              <DropdownToggleDefault>
                {!selectedItemDropdown && <>All team members</>}

                {selectedItemDropdown && selectedItemDropdown.name !== "" && (
                  <DropdownItemWithAvatar
                    renderAvatar={
                      <Avatar
                        size="size20"
                        text={extractFirstLetters(selectedItemDropdown.name, 1)}
                        color={selectedItemDropdown.nameColor}
                        image={selectedItemDropdown.avatar}
                        pending={
                          selectedItemDropdown.status ===
                          TEAM_MEMBER_STATUS_PENDING
                        }
                      />
                    }
                  >
                    {selectedItemDropdown.name}
                  </DropdownItemWithAvatar>
                )}

                {selectedItemDropdown && selectedItemDropdown.name === "" && (
                  <>{selectedItemDropdown.name}</>
                )}
              </DropdownToggleDefault>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={e => {
                  history.push(
                    makeAnalyticUrl("/analytics/team-members", {
                      ...urlParams,
                      teamMember: ""
                    })
                  );
                  handleChangeDropdown("");
                }}
              >
                All team members
              </DropdownItem>
              <DropdownMenuDivider />

              {teamMembers
                .filter(member => member.status !== TEAM_MEMBER_STATUS_PENDING)
                .map(teamMember => (
                  <DropdownItem
                    onClick={e => {
                      history.push(
                        makeAnalyticUrl("/analytics/team-members", {
                          ...urlParams,
                          teamMember: teamMember.id
                        })
                      );

                      handleChangeDropdown(teamMember.id);
                    }}
                    selected={
                      selectedItemDropdown &&
                      selectedItemDropdown.id === teamMember.id
                    }
                  >
                    <DropdownItemWithAvatar
                      renderAvatar={
                        <Avatar
                          size="size20"
                          text={extractFirstLetters(teamMember.name, 1)}
                          color={teamMember.nameColor}
                          image={teamMember.avatar}
                        />
                      }
                    >
                      {teamMember.name}
                    </DropdownItemWithAvatar>
                  </DropdownItem>
                ))}
            </DropdownMenu>
          </Dropdown>
        </PanelDropdownWrapper>
      </NavPanelContent>
    </NavPanel>
  );
}

export default AnalyticsNavPanelContainer;
