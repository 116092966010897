import { connect } from "react-redux";
import { TeamMemberTeam } from "../components/TeamMemberTeams";

const mapStateToProps = (state, props) => {
  const team = state.entities.teams[props.teamId];

  return {
    teamColor: team.color,
    teamTitle: team.title
  };
};

export default connect(mapStateToProps)(TeamMemberTeam);
