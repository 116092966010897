import React from "react";
import styles from "./NavPanelContentTitle.module.css";

function NavPanelContentTitle({ title, counter }) {
  return (
    <p className={styles.wrapper}>
      <span className={styles.title}>{title}</span>
      {counter && <span> ({counter})</span>}
    </p>
  );
}

export default NavPanelContentTitle;
