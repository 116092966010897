import React from "react";
import styles from "./MessageFormAttachButton.module.css";
import PropTypes from "prop-types";
import { Tooltip } from "design-system-react";

function MessageFormAttachButton({ onSelectFiles }) {
  return (
    <div className={styles.button}>
      <label className={styles.label} htmlFor="message-form-file-input">
        <Tooltip size="sm" position="top" withDelay>
          Attach files
        </Tooltip>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
        >
          <path
            className="cls-1"
            d="M1409.89,2699a4.681,4.681,0,0,0,3.45-1.53l10.49-11.2a4.47,4.47,0,0,0,0-6.03,3.828,3.828,0,0,0-5.65,0l-9.32,9.96a3.464,3.464,0,0,0,0,4.68,2.957,2.957,0,0,0,4.38,0l7.64-8.16-0.59-.63-7.64,8.16a2.179,2.179,0,0,1-3.2,0,2.473,2.473,0,0,1-.66-1.71,2.538,2.538,0,0,1,.66-1.71l9.33-9.95a3,3,0,0,1,4.45,0,3.531,3.531,0,0,1,0,4.76l-10.48,11.2a3.921,3.921,0,0,1-2.86,1.26,4.2,4.2,0,0,1-4.05-4.32,4.406,4.406,0,0,1,1.19-3.05l9.64-10.3-0.6-.64-9.64,10.3a5.4,5.4,0,0,0-1.43,3.69A5.065,5.065,0,0,0,1409.89,2699Z"
            transform="translate(-1405 -2679)"
          />
        </svg>
      </label>
      <input
        className={styles.input}
        id="message-form-file-input"
        name="file"
        type="file"
        multiple
        onChange={onSelectFiles}
      />
    </div>
  );
}

MessageFormAttachButton.propTypes = {
  onSelectFiles: PropTypes.func.isRequired
};

export default MessageFormAttachButton;
