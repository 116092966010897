import React, { useState } from "react";
import connect from "react-redux/es/connect/connect";
import { addChannelTelegramId } from "modules/pages/settingsTelegram";
import AddStepFormContainer from "pages/SettingsTelegramInterface/containers/AddStepFormContainer";
import ConfirmStepFormContainer from "pages/SettingsTelegramInterface/containers/ConfirmStepFormContainer";

function AddTelegramInterfaceModalFormContainer({ toggle, isOpen }) {
  const [step, setStep] = useState(1);
  const [values, setValues] = useState(null);

  if (step === 1) {
    return (
      <AddStepFormContainer
        setStep={setStep}
        toggle={toggle}
        isOpen={isOpen}
        setValues={setValues}
      />
    );
  } else {
    return (
      <ConfirmStepFormContainer
        setStep={setStep}
        toggle={toggle}
        isOpen={isOpen}
        phone={values.phone}
        appId={values.appId}
        appHash={values.appHash}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  addChannelTelegramId: ids => dispatch(addChannelTelegramId(ids))
});

export default connect(
  null,
  mapDispatchToProps
)(AddTelegramInterfaceModalFormContainer);
