import { can, END_USER_UPDATE_PERMISSION } from "../utils/permissions";

export const END_USER_VISITOR = "visitor";
export const END_USER_LEAD = "lead";
export const END_USER_CUSTOMER = "customer";

export function endUserActionItems(blocked) {
  let items = [];

  const canEdit = can(END_USER_UPDATE_PERMISSION);

  if (blocked) {
    canEdit && items.push({ key: "block", label: "Block" });
  } else {
    canEdit && items.push({ key: "unblock", label: "Unblock" });
  }

  return items;
}

export function getEndUserQualificationColor(status) {
  switch (status) {
    case END_USER_VISITOR:
      return "#8C8C8C";
    case END_USER_LEAD:
      return "#FA8C16";
    case END_USER_CUSTOMER:
      return "#52C41A";
    default:
      return "";
  }
}

export function getEndUserQualificationTitle(status) {
  switch (status) {
    case END_USER_VISITOR:
      return "Visitor";
    case END_USER_LEAD:
      return "Lead";
    case END_USER_CUSTOMER:
      return "Customer";
    default:
      return status;
  }
}
