import React, { useCallback } from "react";
import { GutterField, Input } from "design-system-react";
import EditableField from "components/EditableField";
import { api } from "utils/client";
import { ALERT_SUCCESS, showAlert } from "utils/alertManager";

function CaseSubjectField({ caseItem, upFirst }) {
  const handleSubjectFieldUpdate = useCallback(
    (subject, { setSuccess }) => {
      const id = caseItem.id;
      api.cases.updateCaseSubjectField(id, { subject }).ready.then(() => {
        setSuccess(true);
        showAlert(ALERT_SUCCESS, `Subject was successfully changed`);
      });
    },
    [caseItem]
  );

  return (
    <GutterField gutterBottom={20}>
      <EditableField
        initialValue={upFirst(caseItem.subject)}
        label={"Subject"}
        component={Input}
        size="sm"
        onUpdate={handleSubjectFieldUpdate}
      />
    </GutterField>
  );
}

export default CaseSubjectField;
