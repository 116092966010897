import styles from "pages/Cases/components/CollapsedCaseListItem.module.css";
import { DateTime } from "../../../components/DateTime";
import CaseListItemEndUserContainer from "../../../containers/CaseListItemEndUserContainer";
import AssignedTeamMemberContainer from "../../../containers/AssignedTeamMemberContainer";

function CollapsedCaseListItem({
  endUserId,
  assignedTeamMemberId,
  lastMessageId,
  unread,
  time,
  caseItemSubject
}) {
  return (
    <div className={styles.wrapper}>
      <CaseListItemEndUserContainer
        endUserId={endUserId}
        lastMessageId={lastMessageId}
        unread={unread}
        caseItemSubject={caseItemSubject}
      />
      <div className={styles.assignedUser}>
        <AssignedTeamMemberContainer
          teamMemberId={assignedTeamMemberId}
          hideName
        />
      </div>
      <div className={styles.date}>
        <DateTime time={time} />
      </div>
    </div>
  );
}

export default CollapsedCaseListItem;
