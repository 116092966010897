import React, { useState, useCallback } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownToggleDefault,
  DropdownMenu
} from "design-system-react";
import { ChromePicker } from "react-color";
import LabelWithTooltip from "components/LabelWithTooltip";
import PickerWrapper from "../components/PickerWrapper";
import PickerColorBox from "../components/PickerColorBox";

function SelectField({
  field,
  form,
  label,
  size,
  disabled,
  placeholder,
  tooltip
}) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleChange = useCallback(
    value => {
      form.setFieldValue(field.name, value.hex);
    },
    [field.name, form]
  );

  const error = form.errors[field.name];

  return (
    <Dropdown
      isOpen={isOpen}
      toggle={toggle}
      validation={error && "error"}
      validationMessage={error}
      nonEmpty={Boolean(field.value)}
      size={size}
      disabled={disabled}
    >
      <DropdownToggle>
        <LabelWithTooltip
          label={label}
          fill={isOpen ? null : field.value}
          tooltip={tooltip}
        />
        <DropdownToggleDefault placeholder={placeholder}>
          <PickerColorBox color={field.value} />
        </DropdownToggleDefault>
      </DropdownToggle>
      <DropdownMenu>
        <PickerWrapper>
          <ChromePicker
            width="100%"
            disableAlpha
            color={field.value}
            onChangeComplete={handleChange}
          />
        </PickerWrapper>
      </DropdownMenu>
    </Dropdown>
  );
}

export default SelectField;
