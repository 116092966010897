import React from "react";
import { connect } from "react-redux";
import DefaultMessageBubble from "../../../components/DefaultMessageBubble";
import ContactMessageBubble from "../../../components/ContactMessageBubble";
import FileMessageBubble from "../../../components/FileMessageBubble";
import StickerMessageBubble from "../../../components/StickerMessageBubble";
import LocationMessageBubble from "../../../components/LocationMessageBubble";
import {
  TYPE_CONTACT,
  TYPE_FILE,
  TYPE_LOCATION,
  TYPE_NOTE,
  TYPE_STICKER,
  TYPE_FORM
} from "../../../helpers/messages";
import FormMessageBubble from "components/FormMessageBubble";

const isSafari =
  /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);

function MessageListItemContentContainer({
  messageContent,
  channelType,
  outgoing,
  time
}) {
  function renderContent(type) {
    switch (type) {
      case TYPE_LOCATION:
        return (
          <LocationMessageBubble
            content={messageContent.content}
            status={messageContent.status}
            outgoing={outgoing}
            time={time}
          />
        );
      case TYPE_FILE:
        return (
          <FileMessageBubble
            fileType={messageContent.file.type}
            fileWidth={messageContent.file.meta.width}
            fileHeight={messageContent.file.meta.height}
            fileUrl={messageContent.file.url["default"]}
            fileName={messageContent.file.info.name}
            fileSize={messageContent.file.info.size}
            fileExt={messageContent.file.info.name
              .match(/[0-9a-zA-Z]+$/)
              .toString()}
            fileSmallImageUrl={
              messageContent.file.type === "image" &&
              messageContent.file.url["default"]
            }
            outgoing={outgoing}
            status={messageContent.status}
            time={time}
          />
        );
      case TYPE_STICKER:
        return (
          <StickerMessageBubble
            image={messageContent.file.url["default"]}
            status={messageContent.status}
            outgoing={outgoing}
            time={time}
          />
        );
      case TYPE_CONTACT:
        return (
          <ContactMessageBubble
            status={messageContent.status}
            outgoing={outgoing}
            content={messageContent.content}
            time={time}
            channelType={channelType}
          />
        );
      case TYPE_FORM:
        return (
          <FormMessageBubble
            status={messageContent.status}
            outgoing={outgoing}
            time={time}
            content={messageContent.content}
          />
        );
      default:
        return (
          <DefaultMessageBubble
            type={messageContent.type}
            status={messageContent.status}
            outgoing={outgoing}
            text={messageContent.content}
            time={time}
            note={messageContent.type === TYPE_NOTE}
          />
        );
    }
  }

  return messageContent ? renderContent(messageContent.type) : <i />;
}

const mapStateToProps = (state, props) => {
  const messageContent = state.entities.messageContents[props.messageContentId];

  return {
    messageContent
  };
};

export default connect(mapStateToProps)(MessageListItemContentContainer);
