import React, { useEffect } from "react";
import SettingsSubMenu from "components/settings/SettingsSubMenu";
import ContentContainer from "pages/SettingsSalesCardSetups/containers/ContentContainer";
import SettingsLayout from "components/SettingsLayout";

function SettingsSalesCardSetups() {
  useEffect(() => {
    document.title = "Business card settings";
    localStorage.setItem("lastSettingRoute", "/settings/sales-card-setups");
  }, []);

  return (
    <SettingsLayout
      renderSubmenu={() => (
        <SettingsSubMenu activeMenuItem={"/settings/sales-card-setups"} />
      )}
      renderContent={() => (
        <ContentContainer />
      )}
    />
  );
}

export default SettingsSalesCardSetups;
