import React from "react";
import ToggableLayout from "components/ToggableLayout";
import TeamContainer from "pages/Team/containers/TeamContainer";
import TeamMembersSubMenuContainer from "../../containers/TeamMembersSubMenuContainer";

function Team({ match }) {
  return (
    <ToggableLayout
      renderSubmenu={() => (
        <TeamMembersSubMenuContainer activeMenuItem={match.url} />
      )}
      renderContent={container => (
        <TeamContainer
          onSubMenuToggle={container.toggleSubmenu}
          isSubmenuOpened={container.isSubmenuOpened}
          teamId={match.params.id}
        />
      )}
    />
  );
}

export default Team;
