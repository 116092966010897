import React from "react";
import { Link } from "react-router-dom";
import { SubMenuListItem } from "design-system-react";
import { connect } from "react-redux";

function EndUserSubMenuItemContainer({
  active,
  viewName,
  viewTitle,
  viewCount
}) {
  return (
    <SubMenuListItem
      active={active}
      tag={Link}
      to={"/end-users/" + viewName}
      //text={viewTitle}
      text={"All contacts"} //TODO: hardcoded to All while 1 item
      counterAll={viewCount}
    />
  );
}

const mapStateToProps = (state, props) => {
  const view = state.entities.views[props.viewId];

  return {
    viewName: view.name,
    viewTitle: view.title,
    viewCount: view.count
  };
};

export default connect(mapStateToProps)(EndUserSubMenuItemContainer);
