import React, { useState, useCallback } from "react";
import SettingsChannelHeader from "components/SettingsHeader";
import TelegramInterfaceListContainer from "pages/SettingsTelegramInterface/containers/TelegramInterfaceListContainer";
import AddTelegramInterfaceModalFormContainer from "pages/SettingsTelegramInterface/containers/AddTelegramInterfaceModalFormContainer";
import { Row, Col, Container } from "design-system-react";

function SettingsTelegramInterfaceContainer() {
  const [addModalOpen, setAddModalOpen] = useState(false);

  const toggleAddModal = useCallback(() => {
    setAddModalOpen(!addModalOpen);
  }, [addModalOpen]);

  return (
    <Row fullHeight column>
      <Container>
        <SettingsChannelHeader
          title="Telegram Interface"
          logo="/images/telegram_100.svg"
          onAction={toggleAddModal}
        >
          Telegram Interface integration
        </SettingsChannelHeader>
      </Container>
      <Col justified>
        <TelegramInterfaceListContainer />
      </Col>
      <AddTelegramInterfaceModalFormContainer
        isOpen={addModalOpen}
        toggle={toggleAddModal}
      />
    </Row>
  );
}

export default SettingsTelegramInterfaceContainer;
