import { withNamespace } from "utils/namespace";

const type = withNamespace("pages/endUsers");

// action types
export const SET_END_USER_IDS = type("SET_END_USER_IDS");
export const ADD_END_USER_IDS = type("ADD_END_USER_IDS");

let initialState = {
  ids: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_END_USER_IDS:
      return {
        ...state,
        ids: action.payload.ids
      };
    case ADD_END_USER_IDS:
      return {
        ...state,
        ids: [...state.ids, ...action.payload.ids]
      };
    default:
      return state;
  }
}

// actions
export function setEndUserIds(ids) {
  return {
    type: SET_END_USER_IDS,
    payload: {
      ids
    }
  };
}

export function addEndUserIds(ids) {
  return {
    type: ADD_END_USER_IDS,
    payload: {
      ids
    }
  };
}
