import { useEffect, useState } from "react";
import { Select } from "antd";
import EntityAvatar from "components/entityAvatar/EntityAvatar";
import { api } from "utils/client";
import { channelSmIcon } from "helpers/channelIcon";

export interface SelectChannelInputProps {
  mode?: "multiple";
  fieldName?: "id" | "type";
  value?: any;
}

function SelectChannelInput({
  mode,
  fieldName = "id",
  value,
  ...props
}: SelectChannelInputProps) {
  const [channels, setChannels] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    api.channels
      .all()
      .ready.then((response: any) => {
        setChannels(response.data.data);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  return (
    <Select
      mode={mode}
      filterOption={(input, option) => {
        if (!option) {
          return false;
        }

        return (
          (option.label as string)
            .toLowerCase()
            .indexOf(input.toLocaleLowerCase()) >= 0
        );
      }}
      loading={loading}
      disabled={loading}
      showSearch
      value={channels.length > 0 ? value : null}
      {...props}
    >
      {channels.map((channel: any) => (
        <Select.Option
          key={fieldName === "type" ? channel.type : channel.id}
          value={fieldName === "type" ? channel.type : channel.id}
          label={fieldName === "type" ? channel.label : channel.title}
        >
          <EntityAvatar
            avatarSrc={channelSmIcon(channel.type)}
            title={fieldName === "type" ? channel.label : channel.title}
          />
        </Select.Option>
      ))}
    </Select>
  );
}

export default SelectChannelInput;
