import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";

import entities from "modules/entities";

import data from "modules/data";
import auth from "modules/auth";
import teamMemberViews from "modules/teamMemberViews";
import teamViews from "modules/teamViews";
import teamMemberExtraFields from "modules/teamMemberExtraFields";
import endUserExtraFields from "modules/endUserExtraFields";
import teamMembers from "modules/pages/teamMembers";
import teamMember from "modules/pages/teamMember";
import caseViews from "modules/caseViews";
import caseCounters from "modules/caseCounters";
import assignDropdown from "modules/assignDropdown";
import analyticsTeamMembers from "modules/pages/analyticsTeamMembers";
import analyticsTeams from "./modules/pages/analyticsTeams";
import quickReplies from "modules/quickReplies";
import notifications from "modules/notifications";
import newCase from "modules/newCase";
import login from "modules/pages/login";
import confirmInvite from "modules/pages/confirmInvite";
import cases from "modules/pages/newcases";
import caseItem from "modules/pages/cases/caseItem";
import casesMessageForm from "modules/pages/cases/messageForm";
import endUsers from "modules/pages/endUsers";
import endUsersViews from "modules/endUserViews";
import endUser from "modules/pages/endUser";
import teamUsersViews from "modules/teamUserViews";
import endUserViews from "modules/endUserViews";
import team from "modules/pages/team";
import teams from "modules/pages/teams";
import settingsProfile from "modules/pages/settingsProfile";
import settingsFacebook from "modules/pages/settingsFacebook";
import settingsWhatsApp from "modules/pages/settingsWhatsApp";
import settingsTelegram from "modules/pages/settingsTelegram";
import settingsViber from "modules/pages/settingsViber";
import settingsBlingerMessenger from "modules/pages/settingsBlingerMessenger";
import settingsEmail from "modules/pages/settingsEmail";
import settingsSlack from "modules/pages/settingsSlack";
import settingsVk from "modules/pages/settingsVk";
import settingsTelegramInterface from "modules/pages/settingsTelegramInterface";
import settingsPipedrive from "modules/pages/settingsPipedrive";
import settingsSalesCardSetups from "modules/pages/settingsSalesCardSetups";
import settingsSalesCards from "modules/pages/settingsSalesCards";
import settingsTyntecWhatsapp from "modules/pages/settingsTyntecWhatsapp";
import extraFields from "modules/extraFields";
import analyticsCases from "./modules/pages/analyticsCases";
import widgetSetup from "modules/widgetSetup";

const rootReducer = combineReducers({
  entities,
  data,
  auth,
  caseViews,
  caseCounters,
  teamMemberViews,
  endUserViews,
  teamViews,
  endUserExtraFields,
  teamMemberExtraFields,
  assignDropdown,
  quickReplies,
  notifications,
  newCase,
  widgetSetup,

  pages: combineReducers({
    teamMembers,
    teamMember,
    endUsers,
    cases,
    analyticsTeamMembers,
    analyticsTeams,
    analyticsCases,

    login,
    confirmInvite,
    casesPage: combineReducers({
      assignDropdown,
      caseViews,
      // caseList,
      caseItem
    }),
    casesMessageForm,
    endUser,
    teamUsersViews,
    team,
    teams,
    settingsProfile,
    settingsFacebook,
    settingsWhatsApp,
    settingsTyntecWhatsapp,
    settingsTelegram,
    settingsViber,
    settingsBlingerMessenger,
    settingsEmail,
    settingsVk,
    settingsSlack,
    settingsTelegramInterface,
    settingsPipedrive,
    settingsSalesCardSetups,
    settingsSalesCards
  }),
  extraFields,
  teamUsersViews,
  endUsersViews
});

const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk]
});

export default store;
