import React, { useState, useCallback, useEffect } from "react";
import {
  Preloader,
  TableHeaderColumn,
  TableBody,
  TableRow,
  TableColumn,
  Col,
  Card,
  CardHeader,
  CardHeaderTitle,
  CardBody,
  TabContent,
  Tab,
  Tabs,
  Metric,
  MetricLabel,
  Legend,
  LegendContainer,
  LegendContainerSection,
  Chart,
  Table,
  TableHeader,
  Dropdown,
  DropdownToggle,
  DropdownToggleDefault,
  DropdownItemWithAvatar,
  DropdownMenu,
  DropdownItem,
  Avatar,
  Tooltip,
  CustomTooltip,
  DropdownMenuDivider,
  CardHeaderTitleWithLabel
} from "design-system-react";

import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip as RechartTooltip,
  AreaChart,
  Area,
  ResponsiveContainer
} from "recharts";
import CardDropdownWrapper from "../components/CardDropdownWrapper";
import { makeAnalyticUrl } from "../../../helpers/analytics";
import history from "../../../utils/history";
import AvatarWrapper from "../../AnalyticsTeamMembers/components/AvatarWrapper";
import NewLabelValue from "../components/NewLabelValue";
import OpenLabelValue from "../components/OpenLabelValue";
import ClosedLabelValue from "../components/ClosedLabelValue";
import {
  extractFirstCharacters,
  extractFirstLetters
} from "../../../utils/string";
import { connect } from "react-redux";
import { api } from "../../../utils/client";
import { channelSmIcon } from "../../../helpers/channelIcon";
import { setChannelIds } from "../../../modules/pages/analyticsTeams";
import { setTeamIds } from "../../../modules/pages/analyticsTeams";
import uniqueArrayItems from "../../../utils/uniqueArrayItems";
import NoItemBlock from "../components/NoItemBlock";
import AnalyticsChannelDropdownItem from "./AnalyticsChannelDropdownItem";
import { printChannel } from "../../../helpers/channels";

// tabsItem
const tabsItems = {
  firstResponseTime: {
    title: "First response time",
    tooltipText:
      "Median Time to First Response for all new cases for a specified period of time. This means that 50% of new cases were responded to within the Median Time to First Response."
  },
  responseTime: {
    title: "Response time",
    tooltipText:
      "Median Response Time in all cases for a specified period of time. This means that 50% of all cases were responded to within the Median Response Time."
  },

  resolutionTime: {
    title: "Resolution time",
    tooltipText:
      "Median Resolution Time of closed cases for a specified period of time. This means that 50% of all closed cases were resolved within the Median Resolution Time."
  },

  repliesToResolution: {
    title: "Replies to resolution",
    tooltipText:
      "Median Replies to resolution in closed cases for a specified period of time. This means that 50% of all closed cases were closed after several replies from an agent."
  },

  firstContactResolution: {
    title: "First contact resolution",
    tooltipText:
      "Median First Contact Resolution is a median amount of all cases for a specified period of time which were closed within one touch of the user."
  }
};

const tabsItemsText = [
  "First response time",
  "Response time",
  "Resolution time",
  "Replies to resolution",
  "First contact resolution"
];

function AnalyticsCardsContainer({
  loadingAnalytics,
  loadingAnalyticsData,
  analytics,
  analyticsData,
  urlParams,
  selectedTeam,
  setTeamIds,
  setChannelIds,
  channels
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(urlParams.channel || "");
  const [title, setTitle] = useState(tabsItems.firstResponseTime.title);
  const [tooltipText, setTooltipText] = useState(
    tabsItems.firstResponseTime.tooltipText
  );

  const toggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleChange = useCallback(value => {
    setSelectedValue(value);
    setIsOpen(false);
  }, []);

  const selectedChannel = channels.filter(i => i.type === selectedValue)[0];

  useEffect(() => {
    setChannelIds([]);

    api.channels.all().ready.then(response => {
      setChannelIds(response.data.data.map(channel => channel.id));
    });
  }, [setChannelIds]);

  const TooltipContent = useCallback(
    props => {
      if (!props.active) {
        return null;
      }

      let payload = [];

      if (props.payload[0]) {
        payload.push({
          name: title,
          value: props.payload[0].payload.current_title || 0
        });

        payload.push({
          name: "Previous period",
          value: props.payload[0].payload.previous_title || 0
        });
      }

      return <CustomTooltip payload={payload} />;
    },
    [title]
  );

  if (
    (loadingAnalytics && loadingAnalyticsData) ||
    !analytics ||
    !analyticsData
  ) {
    return (
      <Col align="center" justified>
        <Preloader size="32" color="#807d7f" />
      </Col>
    );
  }

  return (
    <>
      <Col auto gutterRight={24} gutterLeft={24}>
        <Card>
          <CardHeader>
            {!selectedChannel && (
              <CardHeaderTitle
                title={title}
                descriptionText="Comparing to previous day"
                renderTooltip={
                  <Tooltip dark={true} position="bottom" withWrap={true}>
                    <>{tooltipText}</>
                  </Tooltip>
                }
              />
            )}
            {selectedChannel && selectedChannel.type === "" && (
              <CardHeaderTitle
                title={title + ""}
                descriptionText="Comparing to previous day"
                renderTooltip={
                  <Tooltip dark={true} position="bottom" withWrap={true}>
                    <>{tooltipText}</>
                  </Tooltip>
                }
              />
            )}
            {selectedChannel && selectedChannel.type !== "" && (
              <CardHeaderTitle
                title={
                  title +
                  ": " +
                  ((selectedChannel && printChannel(selectedChannel.type)) ||
                    "")
                }
                descriptionText="Comparing to previous day"
                renderTooltip={
                  <Tooltip dark={true} position="bottom" withWrap={true}>
                    <>{tooltipText}</>
                  </Tooltip>
                }
              />
            )}
            <CardDropdownWrapper>
              <Dropdown
                isOpen={isOpen}
                toggle={toggle}
                nonEmpty={selectedChannel}
                size="sm"
              >
                <DropdownToggle>
                  <DropdownToggleDefault>
                    {!selectedChannel && "All channels"}

                    {selectedChannel && (
                      <DropdownItemWithAvatar
                        renderAvatar={
                          <Avatar
                            size="size20"
                            image={channelSmIcon(selectedChannel.type)}
                          />
                        }
                      >
                        {printChannel(selectedChannel.type)}
                      </DropdownItemWithAvatar>
                    )}
                  </DropdownToggleDefault>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={e => {
                      history.push(
                        makeAnalyticUrl("/analytics/teams", {
                          ...urlParams,
                          channel: ""
                        })
                      );
                      handleChange("");
                    }}
                  >
                    All channels
                  </DropdownItem>
                  <DropdownMenuDivider />

                  {channels.map(channel => (
                    <AnalyticsChannelDropdownItem
                      channel={channel}
                      urlParams={urlParams}
                      selectedValue={selectedValue}
                      makeAnalyticUrl={makeAnalyticUrl}
                      handleChange={handleChange}
                    />
                  ))}
                </DropdownMenu>
              </Dropdown>
            </CardDropdownWrapper>
          </CardHeader>

          <CardBody>
            {loadingAnalytics ? (
              <div
                style={{
                  minHeight: "383px",
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <Col align="center" justified>
                  <Preloader size="32" color="#807d7f" />
                </Col>
              </div>
            ) : (
              <Tabs
                activeTab={{
                  id: urlParams.tab || "firstResponseTime"
                }}
                marginRight="12px"
                width="20%"
                maxWidth="212px"
                minWidth="150px"
                spaceBetween={true}
                onTabChange={tab => {
                  if (urlParams.tab !== tab.id) {
                    window.history.pushState(
                      null,
                      null,
                      makeAnalyticUrl("/analytics/teams", {
                        ...urlParams,
                        tab: tab.id
                      })
                    );
                  }

                  setTimeout(() => {
                    setTitle(tabsItems[tab.id].title);
                    setTooltipText(tabsItems[tab.id].tooltipText);
                  }, 200);
                }}
              >
                <Tab
                  id="firstResponseTime"
                  title={
                    <Metric
                      value={analytics.firstResponseTime.metric.value}
                      text={tabsItemsText[0]}
                      renderMetricLabel={
                        <MetricLabel
                          text={analytics.firstResponseTime.metric.result}
                          status={analytics.firstResponseTime.metric.status}
                          resultDirection={
                            analytics.firstResponseTime.metric.resultDirection
                          }
                        />
                      }
                    />
                  }
                >
                  <TabContent paddings={"0"}>
                    <Chart margins="40px 0 0 0">
                      <ResponsiveContainer>
                        <AreaChart
                          height={240}
                          data={analytics.firstResponseTime.chart.data}
                          margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
                        >
                          <CartesianGrid stroke="#f5f5f5" vertical={false} />
                          <XAxis
                            dataKey="title"
                            height={20}
                            stroke="#969699"
                            tickLine={false}
                            axisLine={false}
                            minTickGap={1}
                            padding={{ right: 10 }}
                          />
                          <YAxis
                            tickCount={6}
                            stroke="#969699"
                            tickLine={false}
                            axisLine={false}
                            textAnchor={"start"}
                            dx={-50}
                            domain={[
                              dataMin => 0,
                              dataMax =>
                                analytics.firstResponseTime.chart.maxChartValue
                            ]}
                            unit={
                              analytics.firstResponseTime.chart.maxChartLabel
                            }
                          />
                          <RechartTooltip
                            labelStyle={{
                              display: "none"
                            }}
                            content={<TooltipContent />}
                          />

                          <Area
                            type="monotone"
                            dataKey="previous"
                            stackId="1"
                            stroke="#000"
                            fill="#ecf0f1"
                            opacity=".8"
                            strokeOpacity={0}
                          />

                          <Area
                            type="monotone"
                            dataKey="current"
                            stackId="2"
                            stroke="#4c57b3"
                            fill="#4c57b3"
                            opacity=".4"
                            strokeOpacity={0}
                          />
                        </AreaChart>
                      </ResponsiveContainer>
                    </Chart>

                    <LegendContainer margins="24px 0 0 0">
                      <LegendContainerSection>
                        <Legend
                          legendText="Metric gets worth"
                          color="#d00045"
                        />
                        <Legend legendText="Metric neutral" color="#d2b00f" />
                        <Legend
                          legendText="Metric gets better"
                          color="#58a44a"
                        />
                      </LegendContainerSection>
                      <LegendContainerSection>
                        <Legend legendText="Period result" color="#8884d8" />
                        <Legend
                          legendText="Comparing to previous period"
                          color="#969799"
                        />
                      </LegendContainerSection>
                    </LegendContainer>
                  </TabContent>
                </Tab>

                <Tab
                  id="responseTime"
                  title={
                    <Metric
                      value={analytics.responseTime.metric.value}
                      text={tabsItemsText[1]}
                      renderMetricLabel={
                        <MetricLabel
                          text={analytics.responseTime.metric.result}
                          status={analytics.responseTime.metric.status}
                          resultDirection={
                            analytics.responseTime.metric.resultDirection
                          }
                        />
                      }
                    />
                  }
                >
                  <TabContent paddings={"0"}>
                    <Chart margins="40px 0 0 0">
                      <ResponsiveContainer>
                        <AreaChart
                          height={240}
                          data={analytics.responseTime.chart.data}
                          margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
                        >
                          <CartesianGrid stroke="#f5f5f5" vertical={false} />
                          <XAxis
                            dataKey="title"
                            height={20}
                            stroke="#969699"
                            tickLine={false}
                            axisLine={false}
                            minTickGap={1}
                            padding={{ right: 10 }}
                          />
                          <YAxis
                            tickCount={6}
                            stroke="#969699"
                            tickLine={false}
                            axisLine={false}
                            textAnchor={"start"}
                            dx={-50}
                            domain={[
                              dataMin => 0,
                              dataMax =>
                                analytics.responseTime.chart.maxChartValue
                            ]}
                            unit={analytics.responseTime.chart.maxChartLabel}
                          />
                          <RechartTooltip
                            labelStyle={{
                              display: "none"
                            }}
                            content={<TooltipContent />}
                          />

                          <Area
                            type="monotone"
                            dataKey="previous"
                            stackId="1"
                            stroke="#000"
                            fill="#ecf0f1"
                            opacity=".8"
                            strokeOpacity={0}
                          />

                          <Area
                            type="monotone"
                            dataKey="current"
                            stackId="2"
                            stroke="#4c57b3"
                            fill="#4c57b3"
                            opacity=".4"
                            strokeOpacity={0}
                          />
                        </AreaChart>
                      </ResponsiveContainer>
                    </Chart>

                    <LegendContainer margins="24px 0 0 0">
                      <LegendContainerSection>
                        <Legend
                          legendText="Metric gets worth"
                          color="#d00045"
                        />
                        <Legend legendText="Metric neutral" color="#d2b00f" />
                        <Legend
                          legendText="Metric gets better"
                          color="#58a44a"
                        />
                      </LegendContainerSection>
                      <LegendContainerSection>
                        <Legend legendText="Period result" color="#8884d8" />
                        <Legend
                          legendText="Comparing to previous period"
                          color="#969799"
                        />
                      </LegendContainerSection>
                    </LegendContainer>
                  </TabContent>
                </Tab>

                <Tab
                  id="resolutionTime"
                  title={
                    <Metric
                      value={analytics.resolutionTime.metric.value}
                      text={tabsItemsText[2]}
                      renderMetricLabel={
                        <MetricLabel
                          text={analytics.resolutionTime.metric.result}
                          status={analytics.resolutionTime.metric.status}
                          resultDirection={
                            analytics.resolutionTime.metric.resultDirection
                          }
                        />
                      }
                    />
                  }
                >
                  <TabContent paddings={"0"}>
                    <Chart margins="40px 0 0 0">
                      <ResponsiveContainer>
                        <AreaChart
                          height={240}
                          data={analytics.resolutionTime.chart.data}
                          margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
                        >
                          <CartesianGrid stroke="#f5f5f5" vertical={false} />
                          <XAxis
                            dataKey="title"
                            height={20}
                            stroke="#969699"
                            tickLine={false}
                            axisLine={false}
                            minTickGap={1}
                            padding={{ right: 10 }}
                          />
                          <YAxis
                            tickCount={6}
                            stroke="#969699"
                            tickLine={false}
                            axisLine={false}
                            textAnchor={"start"}
                            dx={-50}
                            domain={[
                              dataMin => 0,
                              dataMax =>
                                analytics.resolutionTime.chart.maxChartValue
                            ]}
                            unit={analytics.resolutionTime.chart.maxChartLabel}
                          />
                          <RechartTooltip
                            labelStyle={{
                              display: "none"
                            }}
                            content={<TooltipContent />}
                          />

                          <Area
                            type="monotone"
                            dataKey="previous"
                            stackId="1"
                            stroke="#000"
                            fill="#ecf0f1"
                            opacity=".8"
                            strokeOpacity={0}
                          />

                          <Area
                            type="monotone"
                            dataKey="current"
                            stackId="2"
                            stroke="#4c57b3"
                            fill="#4c57b3"
                            opacity=".4"
                            strokeOpacity={0}
                          />
                        </AreaChart>
                      </ResponsiveContainer>
                    </Chart>

                    <LegendContainer margins="24px 0 0 0">
                      <LegendContainerSection>
                        <Legend
                          legendText="Metric gets worth"
                          color="#d00045"
                        />
                        <Legend legendText="Metric neutral" color="#d2b00f" />
                        <Legend
                          legendText="Metric gets better"
                          color="#58a44a"
                        />
                      </LegendContainerSection>
                      <LegendContainerSection>
                        <Legend legendText="Period result" color="#8884d8" />
                        <Legend
                          legendText="Comparing to previous period"
                          color="#969799"
                        />
                      </LegendContainerSection>
                    </LegendContainer>
                  </TabContent>
                </Tab>

                <Tab
                  id="repliesToResolution"
                  title={
                    <Metric
                      value={analytics.repliesToResolution.metric.value}
                      text={tabsItemsText[3]}
                      renderMetricLabel={
                        <MetricLabel
                          text={analytics.repliesToResolution.metric.result}
                          status={analytics.repliesToResolution.metric.status}
                          resultDirection={
                            analytics.repliesToResolution.metric.resultDirection
                          }
                        />
                      }
                    />
                  }
                >
                  <TabContent paddings={0}>
                    <Chart margins="40px 0 0 0">
                      <ResponsiveContainer>
                        <AreaChart
                          height={240}
                          data={analytics.repliesToResolution.chart.data}
                          margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
                        >
                          <CartesianGrid stroke="#f5f5f5" vertical={false} />
                          <XAxis
                            dataKey="title"
                            height={20}
                            stroke="#969699"
                            tickLine={false}
                            axisLine={false}
                            minTickGap={1}
                            padding={{ right: 10 }}
                          />
                          <YAxis
                            tickCount={6}
                            stroke="#969699"
                            tickLine={false}
                            axisLine={false}
                            textAnchor={"start"}
                            dx={-50}
                            domain={[
                              dataMin => 0,
                              dataMax =>
                                analytics.repliesToResolution.chart
                                  .maxChartValue
                            ]}
                            unit={
                              analytics.repliesToResolution.chart.maxChartLabel
                            }
                          />
                          <RechartTooltip
                            labelStyle={{
                              display: "none"
                            }}
                            content={<TooltipContent />}
                          />

                          <Area
                            type="monotone"
                            dataKey="previous"
                            stackId="1"
                            stroke="#000"
                            fill="#ecf0f1"
                            opacity=".8"
                            strokeOpacity={0}
                          />

                          <Area
                            type="monotone"
                            dataKey="current"
                            stackId="2"
                            stroke="#4c57b3"
                            fill="#4c57b3"
                            opacity=".4"
                            strokeOpacity={0}
                          />
                        </AreaChart>
                      </ResponsiveContainer>
                    </Chart>

                    <LegendContainer margins="24px 0 0 0">
                      <LegendContainerSection>
                        <Legend
                          legendText="Metric gets worth"
                          color="#d00045"
                        />
                        <Legend legendText="Metric neutral" color="#d2b00f" />
                        <Legend
                          legendText="Metric gets better"
                          color="#58a44a"
                        />
                      </LegendContainerSection>
                      <LegendContainerSection>
                        <Legend legendText="Period result" color="#8884d8" />
                        <Legend
                          legendText="Comparing to previous period"
                          color="#969799"
                        />
                      </LegendContainerSection>
                    </LegendContainer>
                  </TabContent>
                </Tab>

                <Tab
                  id="firstContactResolution"
                  title={
                    <Metric
                      value={analytics.firstContactResolution.metric.value}
                      text={tabsItemsText[4]}
                      renderMetricLabel={
                        <MetricLabel
                          text={analytics.firstContactResolution.metric.result}
                          status={
                            analytics.firstContactResolution.metric.status
                          }
                          resultDirection={
                            analytics.firstContactResolution.metric
                              .resultDirection
                          }
                        />
                      }
                    />
                  }
                >
                  <TabContent paddings={0}>
                    <Chart margins="40px 0 0 0">
                      <ResponsiveContainer>
                        <AreaChart
                          height={240}
                          data={analytics.firstContactResolution.chart.data}
                          margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
                        >
                          <CartesianGrid stroke="#f5f5f5" vertical={false} />
                          <XAxis
                            dataKey="title"
                            height={20}
                            stroke="#969699"
                            tickLine={false}
                            axisLine={false}
                            minTickGap={1}
                            padding={{ right: 10 }}
                          />
                          <YAxis
                            tickCount={6}
                            stroke="#969699"
                            tickLine={false}
                            axisLine={false}
                            textAnchor={"start"}
                            dx={-50}
                            domain={[
                              dataMin => 0,
                              dataMax =>
                                analytics.firstContactResolution.chart
                                  .maxChartValue
                            ]}
                            unit={
                              analytics.firstContactResolution.chart
                                .maxChartLabel
                            }
                          />
                          <RechartTooltip
                            labelStyle={{
                              display: "none"
                            }}
                            content={<TooltipContent />}
                          />

                          <Area
                            type="monotone"
                            dataKey="previous"
                            stackId="1"
                            stroke="#000"
                            fill="#ecf0f1"
                            opacity=".8"
                            strokeOpacity={0}
                          />

                          <Area
                            type="monotone"
                            dataKey="current"
                            stackId="2"
                            stroke="#4c57b3"
                            fill="#4c57b3"
                            opacity=".4"
                            strokeOpacity={0}
                          />
                        </AreaChart>
                      </ResponsiveContainer>
                    </Chart>

                    <LegendContainer margins="24px 0 0 0">
                      <LegendContainerSection>
                        <Legend
                          legendText="Metric gets worth"
                          color="#d00045"
                        />
                        <Legend legendText="Metric neutral" color="#d2b00f" />
                        <Legend
                          legendText="Metric gets better"
                          color="#58a44a"
                        />
                      </LegendContainerSection>
                      <LegendContainerSection>
                        <Legend legendText="Period result" color="#8884d8" />
                        <Legend
                          legendText="Comparing to previous period"
                          color="#969799"
                        />
                      </LegendContainerSection>
                    </LegendContainer>
                  </TabContent>
                </Tab>
              </Tabs>
            )}
          </CardBody>
        </Card>
      </Col>
      {analyticsData && analyticsData.teams.length !== 0 && (
        <Col auto gutterRight={24} gutterLeft={24}>
          <Card>
            <CardHeader>
              <CardHeaderTitle title="Leaderboard" noInfoLabel={true} />
            </CardHeader>

            <CardBody marginTop="16px">
              <Table noHover={true} headerBorder={true} withoutPadding={true}>
                <TableHeader fixed>
                  <TableHeaderColumn justified fixed>
                    Team
                  </TableHeaderColumn>
                  <TableHeaderColumn>Cases</TableHeaderColumn>
                  <TableHeaderColumn>First response time</TableHeaderColumn>
                  <TableHeaderColumn>Response time</TableHeaderColumn>
                  <TableHeaderColumn>Resolution time</TableHeaderColumn>
                  <TableHeaderColumn>Replies to resolution</TableHeaderColumn>
                  <TableHeaderColumn>
                    First&nbsp;contact&nbsp;resolution
                  </TableHeaderColumn>
                </TableHeader>
                <TableBody>
                  {analyticsData.teams.map(team => {
                    return (
                      <TableRow size="64px">
                        <TableColumn justified fixed>
                          <AvatarWrapper>
                            <Avatar
                              size="size40"
                              text={extractFirstCharacters(team.team, 1)}
                              background={team.color}
                              color="#fff"
                            />
                          </AvatarWrapper>
                          {team.team}
                        </TableColumn>
                        <TableColumn>
                          {team.casesCount.total}
                          <NewLabelValue>{team.casesCount.new}</NewLabelValue>
                          <OpenLabelValue>
                            {team.casesCount.open}
                          </OpenLabelValue>
                          <ClosedLabelValue>
                            {team.casesCount.closed}
                          </ClosedLabelValue>
                        </TableColumn>
                        <TableColumn>
                          {team.firstResponseTime || (
                            <NoItemBlock>None</NoItemBlock>
                          )}
                        </TableColumn>
                        <TableColumn>
                          {team.responseTime || <NoItemBlock>None</NoItemBlock>}
                        </TableColumn>
                        <TableColumn>
                          {team.resolutionTime || (
                            <NoItemBlock>None</NoItemBlock>
                          )}
                        </TableColumn>
                        <TableColumn>
                          {team.repliesToResolution || (
                            <NoItemBlock>None</NoItemBlock>
                          )}
                        </TableColumn>
                        <TableColumn>
                          {team.firstContactResolution || (
                            <NoItemBlock>None</NoItemBlock>
                          )}
                        </TableColumn>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      )}
      {analyticsData && analyticsData.teamMembers.length !== 0 && (
        <Col auto gutterRight={24} gutterLeft={24}>
          <Card>
            <CardHeader>
              <CardHeaderTitleWithLabel
                title="Leaderboard"
                text={selectedTeam && selectedTeam.title}
                color={selectedTeam && selectedTeam.color}
              />
            </CardHeader>

            <CardBody marginTop="16px">
              <Table noHover={true} headerBorder={true} withoutPadding={true}>
                <TableHeader>
                  <TableHeaderColumn justified>Agent</TableHeaderColumn>
                  <TableHeaderColumn>Cases</TableHeaderColumn>
                  <TableHeaderColumn>First response time</TableHeaderColumn>
                  <TableHeaderColumn>Response time</TableHeaderColumn>
                  <TableHeaderColumn>Resolution time</TableHeaderColumn>
                  <TableHeaderColumn>Replies to resolution</TableHeaderColumn>
                  <TableHeaderColumn>
                    First&nbsp;contact&nbsp;resolution
                  </TableHeaderColumn>
                </TableHeader>
                <TableBody>
                  {analyticsData &&
                    analyticsData.teamMembers.map(teamMember => {
                      return (
                        <TableRow size="64px">
                          <TableColumn justified>
                            <AvatarWrapper>
                              <Avatar
                                size="size40"
                                text={extractFirstLetters(
                                  teamMember.teamMember.name,
                                  2
                                )}
                                image={
                                  teamMember.teamMember.avatar &&
                                  teamMember.teamMember.avatar
                                }
                                color={teamMember.teamMember.nameColor}
                              />
                            </AvatarWrapper>
                            {teamMember.teamMember.name}
                          </TableColumn>
                          <TableColumn>
                            {teamMember.casesCount.total}
                            <OpenLabelValue>
                              {teamMember.casesCount.open}
                            </OpenLabelValue>
                            <ClosedLabelValue>
                              {teamMember.casesCount.closed}
                            </ClosedLabelValue>
                          </TableColumn>
                          <TableColumn>
                            {teamMember.firstResponseTime || (
                              <NoItemBlock>None</NoItemBlock>
                            )}
                          </TableColumn>
                          <TableColumn>
                            {teamMember.responseTime || (
                              <NoItemBlock>None</NoItemBlock>
                            )}
                          </TableColumn>
                          <TableColumn>
                            {teamMember.resolutionTime || (
                              <NoItemBlock>None</NoItemBlock>
                            )}
                          </TableColumn>
                          <TableColumn>
                            {teamMember.repliesToResolution || (
                              <NoItemBlock>None</NoItemBlock>
                            )}
                          </TableColumn>
                          <TableColumn>
                            {teamMember.firstContactResolution || (
                              <NoItemBlock>None</NoItemBlock>
                            )}
                          </TableColumn>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      )}
      <Col auto gutterRight={24} gutterLeft={24} gutterBottom={40}>
        <Card>
          <CardHeader>
            <CardHeaderTitle
              title="Cases by channels"
              renderTooltip={
                <Tooltip dark={true} position="bottom" withWrap={true}>
                  <>
                    Distribution of all incoming cases among channels as a
                    percentage between them.
                  </>
                </Tooltip>
              }
            />
          </CardHeader>

          <CardBody marginTop="16px">
            <Table noHover={true} headerBorder={true} withoutPadding={true}>
              <TableHeader>
                <TableHeaderColumn justified>Channel</TableHeaderColumn>
                <TableHeaderColumn>%</TableHeaderColumn>
                <TableHeaderColumn>Total</TableHeaderColumn>
                <TableHeaderColumn>New</TableHeaderColumn>
                <TableHeaderColumn>Opened</TableHeaderColumn>
                <TableHeaderColumn>Closed</TableHeaderColumn>
              </TableHeader>
              <TableBody>
                {analyticsData.channels.map(channel => {
                  return (
                    <TableRow size="40px">
                      <TableColumn justified>{channel.channel}</TableColumn>
                      <TableColumn>{channel.percent}</TableColumn>
                      <TableColumn>{channel.total}</TableColumn>
                      <TableColumn>{channel.new}</TableColumn>
                      <TableColumn>{channel.open}</TableColumn>
                      <TableColumn>{channel.closed}</TableColumn>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </CardBody>
        </Card>
      </Col>
    </>
  );
}

const mapStateToProps = (state, props) => {
  const channels = state.pages.analyticsTeamMembers.channelIds.map(id => {
    const channel = state.entities.channels[id];

    return {
      type: channel.type,
      title: channel.title
    };
  });

  const uniqChannels = uniqueArrayItems(channels, "type");

  return {
    channels: uniqChannels,
    teams: state.pages.analyticsTeams.teamIds,
    selectedTeam: state.entities.teams[props.urlParams.team]
  };
};

const mapDispatchToProps = dispatch => ({
  setChannelIds: channelIds => dispatch(setChannelIds(channelIds)),
  setTeamIds: teamIds => dispatch(setTeamIds(teamIds))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnalyticsCardsContainer);
