import React from "react";
import { Link } from "react-router-dom";
import DateTime from "components/DateTime";
import { TableRow, TableColumn, TableColumnMenu } from "design-system-react";
import connect from "react-redux/es/connect/connect";
import { autoResponseActionItems } from "../../../helpers/autoResponses";
import DragHandler from "pages/SettingsAutoResponses/components/DragHandler";

function AutoResponseListItemContainer({
  autoResponse,
  autoResponseId,
  dragHandleProps,
  isDragging,
  active,
  handleActionDropdown
}) {
  return (
    <TableRow
      tag={Link}
      to={"/settings/auto-responses/" + autoResponseId}
      size="64px"
      active={active}
    >
      <span {...dragHandleProps}>
        <DragHandler active={isDragging} />
      </span>
      <TableColumn justified>{autoResponse.title}</TableColumn>
      <TableColumn>
        <DateTime time={autoResponse.updatedAt} />
      </TableColumn>
      <TableColumnMenu
        items={autoResponseActionItems(autoResponse.enabled)}
        onItemClick={key => {
          handleActionDropdown(autoResponse.id, key);
        }}
      />
    </TableRow>
  );
}

const mapStateToProps = (state, props) => {
  return { autoResponse: state.entities.autoResponses[props.autoResponseId] };
};

export default connect(mapStateToProps)(AutoResponseListItemContainer);
