import {merge} from "lodash";
import {withNamespace} from "utils/namespace";

const type = withNamespace("entities");

export const ADD_ENTITIES = type("ADD_ENTITIES");
export const UPDATE_ENTITY_FIELDS = type("UPDATE_ENTITY_FIELDS");

let initialState = {};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ADD_ENTITIES:
      Object.keys(action.payload).map(key => {
        state[key] = {
          ...state[key]
        };

        Object.keys(action.payload[key]).map(id => {
          state[key][id] = merge({}, state[key][id], action.payload[key][id]);
          return id;
        });

        return key;
      });

      return {...state};
    case UPDATE_ENTITY_FIELDS:
      if (
        state[action.payload.entityType] &&
        state[action.payload.entityType][action.payload.entityId]
      ) {
        return merge({}, state, {
          [action.payload.entityType]: {
            [action.payload.entityId]: {
              ...action.payload.fields
            }
          }
        });
      }

      return state;

    default:
      return state;
  }
}

export function addEntities(entities) {
  return dispatch => {
    dispatch({type: ADD_ENTITIES, payload: entities});
  };
}

export function updateEntityFields(entityType, entityId, fields) {
  return dispatch => {
    dispatch({
      type: UPDATE_ENTITY_FIELDS,
      payload: {
        entityType,
        entityId,
        fields
      }
    });
  };
}
