import React from "react";
import {
  SubMenu,
  SubMenuHeader,
  SubMenuContent,
  SubMenuList,
  SubMenuListCategory,
  SubMenuListItem,
  Scrollbar
} from "design-system-react";
import { can, CHANNEL_VIEW_PERMISSION } from "utils/permissions";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export function settingsMenu(currentUser) {
  return [
    // {
    //   category: "channels",
    //   title: "Facebook Messenger",
    //   linkTo: "/settings/facebook-messenger",
    //   visible: can(CHANNEL_VIEW_PERMISSION)
    // },
    // {
    //   category: "channels",
    //   title: "WhatsApp",
    //   linkTo: "/settings/whatsapp",
    //   visible: can(CHANNEL_VIEW_PERMISSION)
    // },
    {
      category: "channels",
      title: "WhatsApp Business",
      linkTo: "/settings/whatsapp-business",
      visible: can(CHANNEL_VIEW_PERMISSION)
    },
    {
      category: "channels",
      title: "Telegram",
      linkTo: "/settings/telegram",
      visible: can(CHANNEL_VIEW_PERMISSION)
    },
    {
      category: "channels",
      title: "Viber",
      linkTo: "/settings/viber",
      visible: can(CHANNEL_VIEW_PERMISSION)
    },
    // {
    //   category: "channels",
    //   title: "VK",
    //   linkTo: "/settings/vk",
    //   visible: can(CHANNEL_VIEW_PERMISSION)
    // },
    // {
    //   category: "channels",
    //   title: "Blinger Messenger",
    //   linkTo: "/settings/blinger-messenger",
    //   visible: can(CHANNEL_VIEW_PERMISSION)
    // },
    // {
    //   category: "channels",
    //   title: "Email",
    //   linkTo: "/settings/email",
    //   visible: can(CHANNEL_VIEW_PERMISSION)
    // },
    // {
    //   category: "integrations",
    //   title: "Slack",
    //   linkTo: "/settings/slack",
    //   visible: can(CHANNEL_VIEW_PERMISSION)
    // },
    {
      category: "integrations",
      title: "Telegram Interface",
      linkTo: "/settings/telegram-interface",
      visible: can(CHANNEL_VIEW_PERMISSION)
    },
    // {
    //   category: "integrations",
    //   title: "Pipedrive",
    //   linkTo: "/settings/pipedrive",
    //   visible: can(CHANNEL_VIEW_PERMISSION)
    // },
    {
      category: "cases",
      title: "Quick replies",
      linkTo: "/settings/quick-replies",
      visible: true
    },
    {
      category: "cases",
      title: "Auto-response",
      linkTo: "/settings/auto-responses",
      visible: can(CHANNEL_VIEW_PERMISSION)
    },
    {
      category: "cases",
      title: "Business card settings",
      linkTo: "/settings/sales-card-setups",
      visible: can(CHANNEL_VIEW_PERMISSION)
    },
    {
      category: "cases",
      title: "Business cards",
      linkTo: "/settings/sales-cards",
      visible: (currentUser && currentUser.pin) || can(CHANNEL_VIEW_PERMISSION)
    },
    {
      category: "account",
      title: "Notifications",
      linkTo: "/settings/notifications",
      visible: true
    },
    {
      category: "account",
      title: "Account settings",
      linkTo: "/settings/profile",
      visible: true
    }
  ].filter(i => i.visible);
}

function SettingsSubMenu({ activeMenuItem }) {
  const currentUser = useSelector(
    state => state.entities.teamMembers[state.auth.userId]
  );

  const menuItems = settingsMenu(currentUser);

  return (
    <SubMenu noPaddingRight>
      <Scrollbar>
        <SubMenuHeader>Settings</SubMenuHeader>
        {menuItems.filter(i => i.category === "channels").length > 0 && (
          <SubMenuContent>
            <SubMenuListCategory>Channels</SubMenuListCategory>
            <SubMenuList>
              {menuItems
                .filter(i => i.category === "channels")
                .map(link => {
                  let isActive = activeMenuItem === link.linkTo;

                  return (
                    <SubMenuListItem
                      key={link.linkTo}
                      active={isActive}
                      tag={Link}
                      to={link.linkTo}
                      text={link.title}
                    />
                  );
                })}
            </SubMenuList>
          </SubMenuContent>
        )}
        {menuItems.filter(i => i.category === "integrations").length > 0 && (
          <SubMenuContent>
            <SubMenuListCategory>Integrations</SubMenuListCategory>
            <SubMenuList>
              {menuItems
                .filter(i => i.category === "integrations")
                .map(link => {
                  let isActive = activeMenuItem === link.linkTo;
                  return (
                    <SubMenuListItem
                      key={link.linkTo}
                      active={isActive}
                      tag={Link}
                      to={link.linkTo}
                      text={link.title}
                    />
                  );
                })}
            </SubMenuList>
          </SubMenuContent>
        )}
        <SubMenuContent>
          <SubMenuListCategory>Cases</SubMenuListCategory>
          <SubMenuList>
            {menuItems
              .filter(i => i.category === "cases")
              .map(link => {
                let isActive = activeMenuItem === link.linkTo;
                return (
                  <SubMenuListItem
                    key={link.linkTo}
                    active={isActive}
                    tag={Link}
                    to={link.linkTo}
                    text={link.title}
                  />
                );
              })}
          </SubMenuList>
        </SubMenuContent>
        {/*<SubMenuContent>*/}
        {/*  <SubMenuListCategory>Manage</SubMenuListCategory>*/}
        {/*  <SubMenuList>*/}
        {/*    {menuItems*/}
        {/*      .filter(i => i.category === "manage")*/}
        {/*      .map(link => {*/}
        {/*        let isActive = activeMenuItem === link.linkTo;*/}
        {/*        return (*/}
        {/*          <SubMenuListItem*/}
        {/*            key={link.linkTo}*/}
        {/*            active={isActive}*/}
        {/*            tag={Link}*/}
        {/*            to={link.linkTo}*/}
        {/*            text={link.title}*/}
        {/*          />*/}
        {/*        );*/}
        {/*      })}*/}
        {/*  </SubMenuList>*/}
        {/*</SubMenuContent>*/}
        <SubMenuContent>
          <SubMenuListCategory>Account</SubMenuListCategory>
          <SubMenuList>
            {menuItems
              .filter(i => i.category === "account")
              .map(link => {
                let isActive = activeMenuItem === link.linkTo;
                return (
                  <SubMenuListItem
                    key={link.linkTo}
                    active={isActive}
                    tag={Link}
                    to={link.linkTo}
                    text={link.title}
                  />
                );
              })}
          </SubMenuList>
        </SubMenuContent>
      </Scrollbar>
    </SubMenu>
  );
}

export default SettingsSubMenu;
