import React from "react";
import { Avatar } from "design-system-react";
import MessageListEvent from "pages/Cases/components/MessageListEvent";
import MessageListDivider from "pages/Cases/components/MessageListDivider";
import MessageListEventContent from "pages/Cases/components/MessageListEventContent";
import { extractFirstCharacters, extractFirstLetters } from "utils/string";
import { TYPE_FULL_WORDS } from "utils/dateTime";
import { DateTime } from "components/DateTime";
import CaseReassignedAvatars from "../components/CaseReassignedAvatars";
import { connect } from "react-redux";

function render(divider, content) {
  return (
    <MessageListEvent>
      {divider && <MessageListDivider>{divider}</MessageListDivider>}
      <MessageListEventContent>{content}</MessageListEventContent>
    </MessageListEvent>
  );
}

function MessageListEventContainer({
  eventType,
  createdAt,
  oldTeamColor,
  oldTeamTitle,
  targetTeamColor,
  targetTeamTitle,
  targetTeamMemberAvatar,
  targetTeamMemberName,
  targetTeamMemberNameColor,
  initiatorTeamMemberAvatar,
  initiatorTeamMemberName,
  initiatorTeamMemberNameColor
}) {
  switch (eventType) {
    case "assigned":
      if (targetTeamMemberName) {
        return render(
          <CaseReassignedAvatars
            firstAvatar={
              <Avatar
                color="#fff"
                background={targetTeamColor}
                text={extractFirstCharacters(targetTeamTitle, 1)}
                size={"size24"}
              />
            }
            secondAvatar={
              <Avatar
                image={targetTeamMemberAvatar}
                color={targetTeamMemberNameColor}
                text={extractFirstLetters(targetTeamMemberName, 1)}
                size={"size24"}
              />
            }
          />,
          <span>
            Case was assigned to {targetTeamMemberName + " "}
            in {targetTeamTitle}
            <br />
            <DateTime time={createdAt} forceType={TYPE_FULL_WORDS} />
          </span>
        );
      } else if (targetTeamTitle) {
        return render(
          <Avatar
            color="#fff"
            background={targetTeamColor}
            text={extractFirstCharacters(targetTeamTitle, 1)}
            size={"size24"}
          />,
          <span>
            Case was assigned to {targetTeamTitle}
            <br />
            <DateTime time={createdAt} forceType={TYPE_FULL_WORDS} />
          </span>
        );
      }

      break;
    case "unassigned":
      return render(
        <Avatar
          noGradient
          image="/images/unassigned-person.svg"
          size={"size24"}
        />,
        <span>
          Case was unassigned{" "}
          <DateTime time={createdAt} forceType={TYPE_FULL_WORDS} />
        </span>
      );
    case "created":
      return render(
        null,
        <span>
          Case was created <br />
          <DateTime time={createdAt} forceType={TYPE_FULL_WORDS} />
        </span>
      );
    case "closed":
      return render(
        <CaseReassignedAvatars
          firstAvatar={
            oldTeamTitle && (
              <Avatar
                background={oldTeamColor}
                color="#fff"
                text={extractFirstLetters(oldTeamTitle, 1)}
                size={"size24"}
              />
            )
          }
          secondAvatar={
            <Avatar
              image={initiatorTeamMemberAvatar}
              color={initiatorTeamMemberNameColor}
              text={extractFirstLetters(initiatorTeamMemberName, 1)}
              size={"size24"}
            />
          }
        />,
        <span>
          Case was closed by {initiatorTeamMemberName}
          {oldTeamTitle && " in " + oldTeamTitle}
          <br />
          <DateTime time={createdAt} forceType={TYPE_FULL_WORDS} />
        </span>
      );
    default:
      return null;
  }
}

const mapStateToProps = (state, props) => {
  const event = state.entities.caseEvents[props.eventId];
  const oldTeam = event.oldTeam && state.entities.teams[event.oldTeam];
  const targetTeam = event.targetTeam && state.entities.teams[event.targetTeam];
  const targetTeamMember =
    event.targetTeamMember &&
    state.entities.teamMembers[event.targetTeamMember];
  const initiatorTeamMember =
    event.initiatorTeamMember &&
    state.entities.teamMembers[event.initiatorTeamMember];

  return {
    eventType: event.event,
    oldTeamColor: oldTeam && oldTeam.color,
    oldTeamTitle: oldTeam && oldTeam.title,
    targetTeamColor: targetTeam && targetTeam.color,
    targetTeamTitle: targetTeam && targetTeam.title,
    targetTeamMemberAvatar: targetTeamMember && targetTeamMember.avatar,
    targetTeamMemberName: targetTeamMember && targetTeamMember.name,
    targetTeamMemberNameColor: targetTeamMember && targetTeamMember.nameColor,
    initiatorTeamMemberAvatar:
      initiatorTeamMember && initiatorTeamMember.avatar,
    initiatorTeamMemberName: initiatorTeamMember && initiatorTeamMember.name,
    initiatorTeamMemberNameColor:
      initiatorTeamMember && initiatorTeamMember.nameColor
  };
};

export default connect(mapStateToProps)(MessageListEventContainer);
