import React, { useState, useCallback, useEffect } from "react";
import connect from "react-redux/es/connect/connect";
import SettingsChannelHeader from "components/SettingsHeader";
import { Row, Col, Preloader, Container } from "design-system-react";
import { api } from "utils/client";
import axios from "axios";
import { setChannelBlingerMessengerIds } from "modules/pages/settingsBlingerMessenger";
import EmptyState from "components/EmptyState";
import SettingsBlingerMessengerListContainer from "./SettingsBlingerMessengerListContainer";
import SettingsBlingerMessengerModalAddContainer from "./SettingsBlingerMessengerModalAddContainer";

function SettingsBlingerMessengerContainer({
  setChannelBlingerMessengerIds,
  channels
}) {
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    setChannelBlingerMessengerIds([]);

    const request = api.messenger.all();

    request.ready
      .then(response => {
        setChannelBlingerMessengerIds(
          response.data.data.map(member => member.id)
        );
        setLoading(false);
      })
      .catch(error => {
        if (!axios.isCancel(error)) {
          setLoading(false);
        }
      });

    return () => {
      request.cancelRequest.cancel();
    };
  }, [setChannelBlingerMessengerIds]);

  const toggleAddModal = useCallback(() => {
    setAddModalOpen(!addModalOpen);
  }, [addModalOpen]);

  return (
    <Row fullHeight column>
      <Container>
        <SettingsChannelHeader
          title="Blinger Messenger"
          logo="/images/blinger_messenger_100.svg"
          onAction={toggleAddModal}
          buttonProcessing={loading}
        >
          The Blinger Messenger lets your customers talk to your company's sales
          and support agents on your website. It's more than a simple
          online-chat!
        </SettingsChannelHeader>
      </Container>
      {loading ? (
        <Col align="center" justified>
          <Preloader size="32" color="#807d7f" />
        </Col>
      ) : (
        <>
          {!channels || channels.length === 0 ? (
            <Col align="center" verticalAlign="center" justified>
              <EmptyState title="No channels integrated yet">
                You must add an integration with this channel <br /> to start
                receiving messages
              </EmptyState>
            </Col>
          ) : (
            <Col justified>
              <SettingsBlingerMessengerListContainer channels={channels} />
            </Col>
          )}
          <SettingsBlingerMessengerModalAddContainer
            isOpen={addModalOpen}
            toggle={toggleAddModal}
          />
        </>
      )}
    </Row>
  );
}

const getChannels = state => {
  return state.pages.settingsBlingerMessenger.ids.map(id => {
    return state.entities.channels[id];
  });
};

const mapStateToProps = state => {
  return { channels: getChannels(state) };
};

const mapDispatchToProps = dispatch => ({
  setChannelBlingerMessengerIds: ids =>
    dispatch(setChannelBlingerMessengerIds(ids))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsBlingerMessengerContainer);
