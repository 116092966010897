import React, { useState, useCallback, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { Dropdown } from "design-system-react";
import useDebounce from "../hooks/useDebounce";
import AssignDropdownToggleContainer from "./AssignDropdownToggleContainer";
import AssignDropdownMenuContainer from "./AssignDropdownMenuContainer";

function AssignDropdownContainer({
  caseId,
  teamId,
  teamMemberId,
  assignLoading,
  onAssign,
  onUnassign
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState("");
  const debouncedFilter = useDebounce(filter, filter.length > 0 ? 600 : 0);
  const filterRef = useRef(null);

  const handleToggle = useCallback(() => {
    setIsOpen(prevIsOpen => !prevIsOpen);
  }, []);

  const handleScreenChange = useCallback(() => {
    setFilter("");
    filterRef.current.focus();
  }, []);

  const handleAssign = useCallback(
    (caseId, teamId, teamMemberId) => {
      onAssign(caseId, teamId, teamMemberId);
      setIsOpen(false);
    },
    [onAssign]
  );

  const handleUnassign = useCallback(
    (caseId, teamId, teamMemberId) => {
      onUnassign(caseId);
      setIsOpen(false);
    },
    [onUnassign]
  );

  const handleFilterChange = useCallback(e => {
    setFilter(e.target.value);
  }, []);

  useEffect(() => {
    if (isOpen) {
      filterRef.current.focus();
    }
  }, [isOpen]);

  return (
    <Dropdown
      isOpen={isOpen}
      toggle={handleToggle}
      disabled={assignLoading}
      fullHeight
    >
      <AssignDropdownToggleContainer
        teamId={teamId}
        teamMemberId={teamMemberId}
        filter={filter}
        filterRef={filterRef}
        onFilterChange={handleFilterChange}
      />
      <AssignDropdownMenuContainer
        isOpen={isOpen}
        caseId={caseId}
        teamId={teamId}
        teamMemberId={teamMemberId}
        filter={filter}
        filterRef={filterRef}
        debouncedFilter={debouncedFilter}
        onAssign={handleAssign}
        onUnassign={handleUnassign}
        onScreenChange={handleScreenChange}
      />
    </Dropdown>
  );
}

const mapStateToProps = (state, props) => {
  const caseItem = state.entities.cases[props.caseId];

  return {
    teamId: caseItem && caseItem.team,
    teamMemberId: caseItem && caseItem.assignedTeamMember
  };
};

export default connect(mapStateToProps)(AssignDropdownContainer);
