let handlers = [];
let modalId = 0;
let _component = null;

export const SHOW_MODAL = "SHOW_MODAL";
export const UPDATE_MODAL = "UPDATE_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";

export const MODAL_DEFAULT = "default";
export const MODAL_PROCESS = "process";
export const MODAL_SUCCESS = "success";
export const MODAL_WARNING = "warning";
export const MODAL_ERROR = "error";

export function subscribe(component, event, handler) {
  _component = component;

  if (!handlers[event]) {
    handlers[event] = [];
  }

  handlers[event].push(handler);
}

export function unsubscribe(handler) {
  _component = null;

  handlers = handlers.filter(h => h !== handler);
}

export function notify(event, data) {
  if (!handlers[event] || handlers[event].length < 1) {
    return;
  }

  data["id"] = modalId;

  handlers[event].forEach(handler => {
    handler.call(_component, {
      ...data
    });
  });

  return modalId;
}

export function showConfirmModal(status, message, params) {
  const id = ++modalId;

  notify(SHOW_MODAL, { id, status, message, params });

  return id;
}

export function updateConfirmModal(id, status, message, params) {
  return notify(UPDATE_MODAL, { id, status, message, params });
}

export function hideModal(id) {
  return notify(HIDE_MODAL, id);
}
