import React, { useState, useCallback } from "react";
import SettingsChannelHeader from "components/SettingsHeader";
import SlackListContainer from "./SlackListContainer";
import AddModalSlackContainer from "./AddModalSlackContainer";
import { Row, Col, Container } from "design-system-react";

function SettingsSlackContainer({ locationKey }) {
  const [addModalOpen, setAddModalOpen] = useState(false);

  const toggleAddModal = useCallback(() => {
    setAddModalOpen(!addModalOpen);
  }, [addModalOpen]);

  return (
    <Row fullHeight column>
      <Container>
        <SettingsChannelHeader
          title="Slack"
          logo="/images/slack_100.svg"
          onAction={toggleAddModal}
        >
          This integration allow your customers to chat with your team from
          Slack and your team can reply them right from Hugery.
        </SettingsChannelHeader>
      </Container>
      <Col justified>
        <SlackListContainer locationKey={locationKey} />
      </Col>
      <AddModalSlackContainer isOpen={addModalOpen} toggle={toggleAddModal} />
    </Row>
  );
}

export default SettingsSlackContainer;
