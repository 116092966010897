import { useCallback, useState, useContext } from "react";
import NavPanel from "components/NavPanel";
import NavPanelContent from "components/NavPanel/NavPanelContent";
import CollapseButton from "components/NavPanel/CollapseButton";
import ActionDropdown from "../../../components/ActionDropdown";
import { teamMemberActionItems } from "../../../helpers/teamMembers";
import {
  MODAL_DEFAULT,
  MODAL_ERROR,
  MODAL_PROCESS,
  MODAL_SUCCESS,
  showConfirmModal,
  updateConfirmModal
} from "../../../utils/modalManager";
import { api } from "../../../utils/client";
import { CommonError } from "../../../utils/commonError";
import EditTeamMemberModal from "../../../containers/EditTeamMemberModal";
import NavPanelActionItem from "components/NavPanel/NavPanelActionItem";
import { ToggableLayoutContext } from "../../../components/ToggableLayout/ToggableLayoutContext";
import useDeleteTeamMember from "hooks/useDeleteTeamMember";

function TeamUserNavPanel({ teamMember, currentUserId }) {
  const context = useContext(ToggableLayoutContext);

  const deleteTeamMember = useDeleteTeamMember();

  const [editModal, setEditModal] = useState({
    isOpen: false,
    selectedTeamMember: null
  });

  const handleEdit = useCallback(
    selectedTeamMember => {
      setEditModal({
        isOpen: !editModal.isOpen,
        selectedTeamMember: selectedTeamMember || editModal.selectedTeamMember
      });
    },
    [editModal]
  );

  const toggleEditModal = useCallback(() => {
    handleEdit(null);
  }, [handleEdit]);

  const handleEnable = useCallback((memberId, name) => {
    const modalId = showConfirmModal(
      MODAL_DEFAULT,
      "Are you sure you want to enable " + name + "?",
      {
        actionButtonText: "Enable",
        onAction: () => {
          updateConfirmModal(modalId, MODAL_PROCESS, "Enabling...");

          api.teamMembers
            .enable(memberId)
            .ready.then(() => {
              updateConfirmModal(
                modalId,
                MODAL_SUCCESS,
                "Team Member was successfully enabled"
              );
            })
            .catch(e => {
              const errorMessage = "Ooops, something went wrong";

              updateConfirmModal(
                modalId,
                MODAL_ERROR,
                e instanceof CommonError ? e.error : errorMessage
              );
            });
        }
      }
    );
  }, []);

  const handleDisable = useCallback((memberId, name) => {
    const modalId = showConfirmModal(
      MODAL_DEFAULT,
      "Are you sure you want to disable " + name + "?",
      {
        actionButtonText: "Disable",
        onAction: () => {
          updateConfirmModal(modalId, MODAL_PROCESS, "Disabling...");

          api.teamMembers
            .disable(memberId)
            .ready.then(() => {
              updateConfirmModal(
                modalId,
                MODAL_SUCCESS,
                "Team Member was successfully disabled"
              );
            })
            .catch(e => {
              const errorMessage = "Ooops, something went wrong";

              updateConfirmModal(
                modalId,
                MODAL_ERROR,
                e instanceof CommonError ? e.error : errorMessage
              );
            });
        }
      }
    );
  }, []);

  const handleRestore = useCallback((memberId, name) => {
    const modalId = showConfirmModal(
      MODAL_DEFAULT,
      "Are you sure you want to restore " + name + "?",
      {
        actionButtonText: "Restore",

        onAction: () => {
          updateConfirmModal(modalId, MODAL_PROCESS, "Restoring...");

          api.teamMembers
            .restore(memberId)
            .ready.then(() => {
              updateConfirmModal(
                modalId,
                MODAL_SUCCESS,
                "Team Member was successfully restored"
              );
            })
            .catch(e => {
              const errorMessage = "Ooops, something went wrong";

              updateConfirmModal(
                modalId,
                MODAL_ERROR,
                e instanceof CommonError ? e.error : errorMessage
              );
            });
        }
      }
    );
  }, []);

  const handleDelete = useCallback(
    (memberId, name) => {
      deleteTeamMember(memberId, name);
    },
    [deleteTeamMember]
  );

  const handleActionDropdown = useCallback(
    (member, action) => {
      switch (action) {
        case "edit":
          handleEdit(member);
          break;
        case "enable":
          handleEnable(member.id, member.name || member.email);
          break;
        case "disable":
          handleDisable(member.id, member.name || member.email);
          break;
        case "restore":
          handleRestore(member.id, member.name || member.email);
          break;
        case "delete":
          handleDelete(member.id, member.name || member.email);
          break;
        default:
          break;
      }
    },
    [handleEdit, handleEnable, handleDisable, handleRestore, handleDelete]
  );

  const actionItems = teamMember
    ? teamMemberActionItems(
        teamMember.status,
        teamMember.deletedAt,
        teamMember.role.name
      )
    : [];

  return (
    <>
      <EditTeamMemberModal
        isOpen={editModal.isOpen}
        toggle={toggleEditModal}
        teamMember={editModal.selectedTeamMember}
      />
      <NavPanel>
        <NavPanelContent>
          <CollapseButton
            handleClick={context.toggleSubmenu}
            isOpen={context.isSubmenuOpened}
          />
        </NavPanelContent>
        {teamMember && currentUserId !== teamMember.id && (
          <NavPanelContent>
            {actionItems.length > 0 && (
              <NavPanelActionItem>
                <ActionDropdown
                  gutterRight={-15}
                  items={actionItems}
                  direction="left"
                  onItemClick={key => {
                    handleActionDropdown(teamMember, key);
                  }}
                />
              </NavPanelActionItem>
            )}
          </NavPanelContent>
        )}
      </NavPanel>
    </>
  );
}

export default TeamUserNavPanel;
