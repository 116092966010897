import React, { useCallback } from "react";
import { connect } from "react-redux";
import styles from "../components/MessageListEmailItem.module.css";
import dompurify from "dompurify";

function MessageListEmailContentContainer({ messageContent, outgoing }) {
  const sanitize = useCallback((content, addLineBreaks) => {
    let sanitizedContent = dompurify.sanitize(
      content.match(/<body.+?>(.+)<\/body>/gms) || content
    );

    return addLineBreaks
      ? sanitizedContent.replace(/\n/, "<br/>")
      : sanitizedContent;
  }, []);

  if (messageContent.type === "file") {
    return null;
  }

  let content = "";

  try {
    const json = JSON.parse(messageContent.content);
    content = json.html ? json.html : messageContent.content;
  } catch (e) {
    content = messageContent.content;
  }

  return (
    <div
      className={styles.emailBody}
      dangerouslySetInnerHTML={{
        __html: sanitize(content, outgoing)
      }}
    />
  );
}

const mapStateToProps = (state, props) => {
  return {
    messageContent: state.entities.messageContents[props.messageContentId]
  };
};

export default connect(mapStateToProps)(MessageListEmailContentContainer);
