import React from "react";
import styles from "./Condition.module.css";

function Condition({ title, onDelete, children }) {
  return (
    <div className={styles.condition}>
      <div className={styles.or}>OR</div>
      {children}
      <span className={styles.delete} onClick={onDelete}>
        <svg xmlns="http://www.w3.org/2000/svg" width="14px" height="16px">
          <path
            fillRule="evenodd"
            d="M13.500,3.000 L0.500,3.000 C0.224,3.000 -0.000,2.776 -0.000,2.500 L-0.000,2.500 C-0.000,2.224 0.224,2.000 0.500,2.000 L5.000,2.000 L5.000,0.500 C5.000,0.224 5.224,-0.000 5.500,-0.000 L8.500,-0.000 C8.776,-0.000 9.000,0.224 9.000,0.500 L9.000,2.000 L13.500,2.000 C13.776,2.000 14.000,2.224 14.000,2.500 L14.000,2.500 C14.000,2.776 13.776,3.000 13.500,3.000 ZM8.000,1.000 L6.000,1.000 L6.000,2.000 L8.000,2.000 L8.000,1.000 ZM5.500,14.000 C5.224,14.000 5.000,13.776 5.000,13.500 L5.000,4.500 C5.000,4.224 5.224,4.000 5.500,4.000 L5.500,4.000 C5.776,4.000 6.000,4.224 6.000,4.500 L6.000,13.500 C6.000,13.776 5.776,14.000 5.500,14.000 L5.500,14.000 ZM8.000,13.500 L8.000,4.500 C8.000,4.224 8.224,4.000 8.500,4.000 C8.776,4.000 9.000,4.224 9.000,4.500 L9.000,13.500 C9.000,13.776 8.776,14.000 8.500,14.000 C8.224,14.000 8.000,13.776 8.000,13.500 ZM2.500,4.000 C2.776,4.000 3.000,4.224 3.000,4.500 L3.000,15.000 L11.000,15.000 L11.000,4.500 C11.000,4.224 11.224,4.000 11.500,4.000 C11.776,4.000 12.000,4.224 12.000,4.500 L12.000,15.500 C12.000,15.776 11.776,16.000 11.500,16.000 L2.500,16.000 C2.224,16.000 2.000,15.776 2.000,15.500 L2.000,4.500 C2.000,4.224 2.224,4.000 2.500,4.000 Z"
          />
        </svg>
      </span>
    </div>
  );
}

export default Condition;
