import React, { useCallback, useState } from "react";
import { UploadInput } from "design-system-react";
import { api } from "utils/client";
import { FormError } from "utils/formError";

function UploadInputField({ field, form, ...props }) {
  const [preview, setPreview] = useState("");
  const [uploading, setUploading] = useState(false);

  const handleChange = useCallback(
    e => {
      setUploading(true);

      api.files
        .upload(e.target.files[0])
        .ready.then(response => {
          form.setFieldValue(field.name, response.data.data.id);
          setPreview(response.data.data.info.name);
        })
        .catch(e => {
          if (e instanceof FormError) {
            form.setErrors(e.errors);
          }
        })
        .finally(() => {
          setUploading(false);
        });
    },
    // eslint-disable-next-line
    [field]
  );

  let error = form.errors[field.name];

  return (
    <UploadInput
      validation={error && "error"}
      message={error}
      loading={uploading}
      loadingText={"Uploading..."}
      preview={preview}
      {...field}
      {...props}
      onChange={handleChange}
    />
  );
}

export default UploadInputField;
