import React, { useEffect, useCallback, useState } from "react";
import { PopupHeader, Popup, PopupBody } from "design-system-react";
import connect from "react-redux/es/connect/connect";
import { createSelector } from "reselect";
import { addFacebookChannel } from "modules/pages/settingsFacebook";
import GroupItem from "components/GroupItem";
import { api } from "utils/client";

function SettingsVkModalAddContainer({ channels, addFacebookChannel }) {
  const [addModalOpen, setAddModalOpen] = useState(false);
  // const [isLoading, setLoading] = useState(false);
  const [groups, setGroups] = useState([]);
  const [addingGroups, setAddingGroups] = useState([]);
  const [addedGroups, setAddedGroups] = useState([]);

  const getFacebookGroups = useCallback(
    e => {
      if (e.data && e.data.accessToken) {
        let fbData = JSON.parse(e.data.accessToken).data;

        let added = fbData.filter(group => {
          let isExist = false;
          channels.map(channel => {
            if (Number(channel.channelId) === Number(group.id)) {
              isExist = true;
            }
            return channel;
          });
          return isExist;
        });

        let filteredGroups = fbData.filter(group => {
          let isDeleted = true;
          channels.map(channel => {
            if (
              Number(channel.channelId) === Number(group.id) &&
              channel.deletedAt !== null
            ) {
              isDeleted = false;
            }
            return channel;
          });
          return isDeleted;
        });

        setGroups(filteredGroups);
        setAddedGroups(added.map(group => group.id));
        setAddingGroups([]);

        setAddModalOpen(true);

        e.source.close();
      }
    },
    [channels]
  );

  const createChannel = async (pageAccessToken, pageId) => {
    setAddingGroups(prevState => [...prevState, pageId]);

    await api.facebook
      .create({ pageAccessToken, pageId })
      .ready.then(response => {
        addFacebookChannel(response.data.data.id);
      });

    setAddedGroups(prevState => [...prevState, pageId]);
    setAddingGroups(prevState => prevState.filter(item => item !== pageId));
  };

  useEffect(() => {
    window.addEventListener("message", getFacebookGroups);
    return () => {
      window.removeEventListener("message", getFacebookGroups);
    };
  }, [channels, getFacebookGroups]);

  const toggleAddModal = useCallback(() => {
    setAddModalOpen(!addModalOpen);
  }, [addModalOpen]);

  return (
    <Popup isOpen={addModalOpen} onClose={toggleAddModal}>
      <PopupHeader>Select a group</PopupHeader>
      <PopupBody>
        {groups.map(group => {
          return (
            <GroupItem
              key={group.id}
              avatar={group.picture.data.url}
              name={group.name}
              link={`https://fb.com/${group.name.replace(/ /g, "-")}-${
                group.id
              }`}
              isAdding={addingGroups.indexOf(group.id) !== -1}
              isAdded={addedGroups.indexOf(group.id) !== -1}
              handleAddGroup={() => createChannel(group.access_token, group.id)}
            />
          );
        })}
      </PopupBody>
    </Popup>
  );
}

const getChannels = createSelector(
  [state => state.entities.channels, state => state.pages.settingsFacebook.ids],
  (channels, ids) => {
    return ids.map(id => channels[id]);
  }
);

const mapStateToProps = state => {
  return { channels: getChannels(state) };
};

const mapDispatchToProps = dispatch => ({
  addFacebookChannel: id => dispatch(addFacebookChannel(id))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsVkModalAddContainer);
