import React from "react";
import { connect } from "react-redux";
import {
  SubMenu,
  SubMenuHeader,
  SubMenuContent,
  SubMenuList
} from "design-system-react";
import EndUserSubMenuItemContainer from "./EndUsersSubMenuItemContainer";

function EndUsersSubMenuContainer({ viewIds, activeViewId }) {
  return (
    <SubMenu>
      <SubMenuHeader>Contacts</SubMenuHeader>
      <SubMenuContent>
        <SubMenuList>
          {viewIds.map(id => (
            <EndUserSubMenuItemContainer
              key={id}
              viewId={id}
              active={id === activeViewId}
            />
          ))}
        </SubMenuList>
      </SubMenuContent>
    </SubMenu>
  );
}

const mapStateToProps = (state, props) => {
  const activeViewId = state.endUserViews.ids.filter(id => {
    const view = state.entities.views[id];
    return view && view.name === props.activeMenuItem;
  })[0];
  return {
    viewIds: state.endUserViews.ids,
    activeViewId: activeViewId
  };
};

export default connect(mapStateToProps)(EndUsersSubMenuContainer);
