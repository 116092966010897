import React from "react";
import styles from "./DetailsButtonWrapper.module.css";
import classNames from "classnames";

function DetailsButtonWrapper({ children }) {
  return (
    <div className={classNames(styles.detailsButtonWrapper)}>{children}</div>
  );
}

export default DetailsButtonWrapper;
