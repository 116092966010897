import React from "react";
import ReactHowler from "react-howler";
import { Slider, Button } from "antd";
import {
  CaretRightOutlined,
  PauseOutlined,
  LoadingOutlined
} from "@ant-design/icons";
import raf from "raf"; // requestAnimationFrame polyfill
import styles from "./AudioPlayer.module.scss";
import { msToMinutesAndSeconds } from "utils/dateTime";

class AudioPlayer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      playing: false,
      loaded: false,
      loop: false,
      mute: false,
      volume: 1.0,
      seek: 0.0,
      rate: 1,
      isSeeking: false
    };

    this.handleToggle = this.handleToggle.bind(this);
    this.handleOnLoad = this.handleOnLoad.bind(this);
    this.handleOnEnd = this.handleOnEnd.bind(this);
    this.handleOnPlay = this.handleOnPlay.bind(this);
    this.handleStop = this.handleStop.bind(this);
    this.renderSeekPos = this.renderSeekPos.bind(this);
    this.handleLoopToggle = this.handleLoopToggle.bind(this);
    this.handleMuteToggle = this.handleMuteToggle.bind(this);
    this.handleMouseDownSeek = this.handleMouseDownSeek.bind(this);
    this.handleMouseUpSeek = this.handleMouseUpSeek.bind(this);
    this.handleSeekingChange = this.handleSeekingChange.bind(this);
    this.handleRate = this.handleRate.bind(this);
  }

  componentWillUnmount() {
    this.clearRAF();
  }

  handleToggle() {
    if (this.state.loaded) {
      this.setState({
        playing: !this.state.playing
      });
    }
  }

  handleOnLoad() {
    this.setState({
      loaded: true,
      duration: this.player.duration()
    });
  }

  handleOnPlay() {
    this.setState({
      playing: true
    });
    this.renderSeekPos();
  }

  handleOnEnd() {
    this.setState({
      playing: false
    });
    this.clearRAF();
  }

  handleStop() {
    this.player.stop();
    this.setState({
      playing: false // Need to update our local state so we don't immediately invoke autoplay
    });
    this.renderSeekPos();
  }

  handleLoopToggle() {
    this.setState({
      loop: !this.state.loop
    });
  }

  handleMuteToggle() {
    this.setState({
      mute: !this.state.mute
    });
  }

  handleMouseDownSeek() {
    this.setState({
      isSeeking: true
    });
  }

  handleMouseUpSeek(value) {
    this.setState({
      isSeeking: false
    });

    this.player.seek(value);
  }

  handleSeekingChange(value) {
    this.setState({
      seek: parseFloat(value)
    });
  }

  renderSeekPos() {
    if (!this.state.isSeeking) {
      this.setState({
        seek: this.player.seek()
      });
    }
    if (this.state.playing) {
      this._raf = raf(this.renderSeekPos);
    }
  }

  handleRate(e) {
    const rate = parseFloat(e.target.value);
    this.player.rate(rate);
    this.setState({ rate });
  }

  clearRAF() {
    raf.cancel(this._raf);
  }

  render() {
    return (
      <div className="full-control">
        <div className={styles.wrapper}>
          <Button
            type="text"
            className={styles.play}
            shape="circle"
            onClick={this.handleToggle}
            icon={
              this.state.loaded ? (
                this.state.playing ? (
                  <PauseOutlined style={{ fontSize: "16px" }} />
                ) : (
                  <CaretRightOutlined />
                )
              ) : (
                <LoadingOutlined />
              )
            }
            size={"large"}
          />
          <div className={styles.sliderWrapper}>
            <Slider
              className={styles.slider}
              min={0}
              max={this.state.duration ? this.state.duration.toFixed(2) : 0}
              step={0.05}
              tipFormatter={null}
              value={this.state.seek}
              onChange={value => {
                this.handleMouseDownSeek(value);
                this.handleSeekingChange(value);
              }}
              onAfterChange={value => {
                this.handleMouseUpSeek(value);
              }}
            />
            <span className={styles.status}>
              {this.state.loaded ? (
                <>
                  {this.state.seek >= 0
                    ? msToMinutesAndSeconds(this.state.seek * 1000)
                    : "--"}
                  {" / "}
                  {this.state.duration >= 0
                    ? msToMinutesAndSeconds(this.state.duration * 1000)
                    : "--"}
                </>
              ) : (
                "Loading..."
              )}
            </span>
          </div>
          <ReactHowler
            src={this.props.src}
            playing={this.state.playing}
            onLoad={this.handleOnLoad}
            onPlay={this.handleOnPlay}
            onEnd={this.handleOnEnd}
            loop={this.state.loop}
            mute={this.state.mute}
            volume={this.state.volume}
            ref={ref => (this.player = ref)}
          />
        </div>
      </div>
    );
  }
}

export default AudioPlayer;
