import React from "react";
import { Avatar } from "design-system-react";
import { extractFirstLetters } from "../../utils/string";
import styles from "./index.module.css";
import {Link} from "react-router-dom";

function UserContact({ name, nameColor, avatar, hideName, link }) {
  const content = (<>
    <span className={styles.avatar}>
      <Avatar
        size="size24"
        color={nameColor}
        image={avatar}
        text={extractFirstLetters(name, 2)}
      />
    </span>
    {!hideName && <span className={styles.assignName}>{name}</span>}
    </>);

  if (link) {
    return (
      <Link to={link} className={styles.wrapper}>
        {content}
      </Link>
    );
  }

  return (<div className={styles.wrapper}>
    {content}
  </div>)

}

export default UserContact;
