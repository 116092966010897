import { updateEntityAttributes } from "./data";

export function setCaseUnread(caseId, value) {
  return dispatch => {
    dispatch(
      updateEntityAttributes("cases", caseId, {
        unread: value
      })
    );
  };
}

export function setCaseLastMessage(caseId, value) {
  return dispatch => {
    dispatch(
      updateEntityAttributes("cases", caseId, {
        unread: value
      })
    );
  };
}
