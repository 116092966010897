import React, { useState, useCallback, useEffect } from "react";
import {
  Table,
  Col,
  TableHeader,
  TableHeaderColumn,
  TableHeaderColumnMenu,
  TableBody,
  Container,
  Preloader,
  Scrollbar
} from "design-system-react";
import {
  MODAL_DEFAULT,
  MODAL_ERROR,
  MODAL_PROCESS,
  MODAL_SUCCESS,
  showConfirmModal,
  updateConfirmModal
} from "utils/modalManager";
import { api } from "utils/client";
import { CommonError } from "utils/commonError";
import EmptyState from "components/EmptyState";
import { setWhatsAppChannels } from "modules/pages/settingsWhatsApp";
import connect from "react-redux/es/connect/connect";
import axios from "axios";
import SettingsChannelListItem from "components/SettingsChannelListItem";

function SettingsWhatsAppListContainer({
  restoreModal,
  locationKey,
  setWhatsAppChannels,
  channelIds
}) {
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    setWhatsAppChannels([]);

    const request = api.whatsApp.all();

    request.ready
      .then(response => {
        setWhatsAppChannels(response.data.data.map(channel => channel.id));
        setLoading(false);
      })
      .catch(error => {
        if (!axios.isCancel(error)) {
          setLoading(false);
        }
      });

    return () => {
      request.cancelRequest.cancel();
    };
  }, [locationKey, setWhatsAppChannels]);

  const handleDelete = useCallback((channelId, name) => {
    const modalId = showConfirmModal(
      MODAL_DEFAULT,
      "Are you sure you want to delete " + name + "?",
      {
        actionButtonText: "Delete",
        onAction: () => {
          updateConfirmModal(modalId, MODAL_PROCESS, "Deleting...");

          api.whatsApp
            .delete(channelId)
            .ready.then(() => {
              updateConfirmModal(
                modalId,
                MODAL_SUCCESS,
                "Channel was successfully deleted"
              );
            })
            .catch(e => {
              const errorMessage = "Ooops, something went wrong";

              updateConfirmModal(
                modalId,
                MODAL_ERROR,
                e instanceof CommonError ? e.error : errorMessage
              );
            });
        }
      }
    );
  }, []);

  const handleRestore = useCallback(
    channelId => {
      restoreModal(channelId);
    },
    [restoreModal]
  );

  const handleActionDropdown = useCallback(
    (channelId, name, action) => {
      switch (action) {
        case "restore":
          handleRestore(channelId);
          break;
        case "delete":
          handleDelete(channelId, name);
          break;
        default:
          break;
      }
    },
    [handleRestore, handleDelete]
  );

  if (isLoading && !channelIds.length) {
    return (
      <Col align="center" justified>
        <Preloader size="32" color="#807d7f" />
      </Col>
    );
  }

  if (!channelIds.length) {
    return (
      <Col align="center" verticalAlign="center" justified>
        <EmptyState title="No channels integrated yet">
          You must add an integration with this channel <br /> to start
          receiving messages
        </EmptyState>
      </Col>
    );
  }

  return (
    <Scrollbar>
      <Container>
        <Table headerBorder>
          <TableHeader fixed>
            <TableHeaderColumn justified fixed>
              Name
            </TableHeaderColumn>
            <TableHeaderColumn>Added</TableHeaderColumn>
            <TableHeaderColumn>Status</TableHeaderColumn>
            <TableHeaderColumnMenu />
          </TableHeader>
          <TableBody>
            {channelIds.map(id => {
              return (
                <SettingsChannelListItem
                  key={id}
                  channelId={id}
                  handleActionDropdown={handleActionDropdown}
                />
              );
            })}
          </TableBody>
        </Table>
      </Container>
    </Scrollbar>
  );
}

const mapStateToProps = state => {
  return { channelIds: state.pages.settingsWhatsApp.ids || [] };
};

const mapDispatchToProps = dispatch => ({
  setWhatsAppChannels: ids => dispatch(setWhatsAppChannels(ids))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsWhatsAppListContainer);
