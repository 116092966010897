import React, { useState, useCallback, useEffect } from "react";
import { DropdownMenu, Scrollbar } from "design-system-react";
import AssignDropdownMenuTeamMemberTeamsScreenContainer from "./AssignDropdownMenuTeamMemberTeamsScreenContainer";
import AssignDropdownMenuTeamsScreenContainer from "./AssignDropdownMenuTeamsScreenContainer";
import AssignDropdownMenuMainScreenContainer from "./AssignDropdownMenuMainScreenContainer";

export const SELECT_TEAM_SCREEN = "SELECT_TEAM_SCREEN";
export const SELECT_USER_TEAM_SCREEN = "SELECT_USER_TEAM_SCREEN";

function AssignDropdownMenuContainer({
  isOpen,
  onScreenChange,
  caseId,
  teamId,
  teamMemberId,
  debouncedFilter,
  filter,
  scrollbarRef,
  onAssign,
  onUnassign
}) {
  const [screen, setScreen] = useState(null);
  const [selectedTeamMemberId, setSelectedTeamMemberId] = useState(null);

  const changeScreen = useCallback(
    screen => {
      setScreen(screen);
      onScreenChange(screen);
    },
    [onScreenChange]
  );

  const handleSelect = useCallback(
    (teamId, teamMemberId) => {
      if (!teamId && teamMemberId) {
        changeScreen(SELECT_USER_TEAM_SCREEN);
        setSelectedTeamMemberId(teamMemberId);
      } else if (teamId) {
        onAssign(caseId, teamId, teamMemberId);
      }
    },
    [caseId, changeScreen, onAssign]
  );

  const handleBack = useCallback(() => {
    changeScreen(null);
    setSelectedTeamMemberId(null);
  }, [changeScreen]);

  const handleUnassign = useCallback(() => {
    onUnassign(caseId);
  }, [caseId, onUnassign]);

  const handleAssignToTeamSelect = useCallback(() => {
    changeScreen(SELECT_TEAM_SCREEN);
  }, [changeScreen]);

  useEffect(() => {
    if (isOpen) {
      changeScreen(null);
      setSelectedTeamMemberId(null);
    }
  }, [isOpen, changeScreen]);

  return (
    <DropdownMenu fullHeight>
      <Scrollbar innerRef={scrollbarRef} height="310px">
        {!screen && (
          <AssignDropdownMenuMainScreenContainer
            caseId={caseId}
            teamId={teamId}
            teamMemberId={teamMemberId}
            debouncedFilter={debouncedFilter}
            onSelect={handleSelect}
            onAssignToTeamSelect={handleAssignToTeamSelect}
            onUnassign={handleUnassign}
          />
        )}
        {screen === SELECT_USER_TEAM_SCREEN && selectedTeamMemberId && (
          <AssignDropdownMenuTeamMemberTeamsScreenContainer
            teamMemberId={selectedTeamMemberId}
            filter={filter}
            onSelect={handleSelect}
            onBack={handleBack}
          />
        )}
        {screen === SELECT_TEAM_SCREEN && (
          <AssignDropdownMenuTeamsScreenContainer
            caseId={caseId}
            filter={filter}
            onSelect={handleSelect}
            onBack={handleBack}
          />
        )}
      </Scrollbar>
    </DropdownMenu>
  );
}

export default AssignDropdownMenuContainer;
