import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { extractFirstCharacters } from "utils/string";
import { Avatar, Tooltip } from "design-system-react";
import styles from "./TeamMemberTeams.module.css";
import TeamMemberTeamContainer from "../containers/TeamMemberTeamContainer";

export function TeamMemberTeam({ teamColor, teamTitle, onlyTitle }) {
  if (onlyTitle) {
    return <p className={styles.nextTool}>{teamTitle}</p>;
  }

  return (
    <>
      <Avatar
        size={"size24"}
        background={teamColor}
        color="#fff"
        text={extractFirstCharacters(teamTitle, 1)}
      />
      <Tooltip size="small" position="bottom">
        {teamTitle}
      </Tooltip>
    </>
  );
}

function TeamMemberTeams({ teamIds, teamTitle }) {
  const countClasses = classNames(
    styles.next,
    teamIds.length > 12 && styles.two,
    teamIds.length > 102 && styles.three
  );

  const isAlone = teamIds.length === 1;

  return (
    <div
      onClick={e => e.preventDefault()}
      className={classNames(styles.teams, isAlone && styles.teamsAlone)}
    >
      {teamIds.slice(0, 3).map((id, index) => {
        return (
          <div key={id} className={styles.team} style={{ left: -(index * 4) }}>
            <TeamMemberTeamContainer teamId={id} />
          </div>
        );
      })}

      {isAlone && <span className={styles.title}>{teamTitle}</span>}

      {teamIds.length > 3 && (
        <span className={countClasses}>
          <span>
            +{teamIds.length - 3}
            <div className={styles.tool}>
              <Tooltip withWrap size="small" position="bottom">
                <div>
                  {teamIds.slice(3).map(id => {
                    return <TeamMemberTeamContainer teamId={id} onlyTitle />;
                  })}
                </div>
              </Tooltip>
            </div>
          </span>
        </span>
      )}
    </div>
  );
}

TeamMemberTeams.propTypes = {
  teams: PropTypes.array
};

TeamMemberTeams.defaultTypes = {
  teams: []
};

export default TeamMemberTeams;
