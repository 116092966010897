import { withNamespace } from "utils/namespace";

const type = withNamespace("pages/settingsSlack");

export const SET_INTEGRATION_SLACK_IDS = type("SET_INTEGRATION_SLACK_IDS");
export const ADD_INTEGRATION_SLACK_ID = type("ADD_INTEGRATION_SLACK_ID");
export const REMOVE_INTEGRATION_SLACK_ID = type("REMOVE_INTEGRATION_SLACK_ID");

let initialState = {
  ids: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_INTEGRATION_SLACK_IDS:
      return {
        ...state,
        ids: action.payload.ids
      };
    case ADD_INTEGRATION_SLACK_ID:
      return {
        ...state,
        ids: [action.payload.id, ...state.ids]
      };
    case REMOVE_INTEGRATION_SLACK_ID:
      return {
        ...state,
        ids: state.ids.filter(id => id !== action.payload.id)
      };
    default:
      return state;
  }
}

// actions
export function setIntegrationSlackIds(ids) {
  return {
    type: SET_INTEGRATION_SLACK_IDS,
    payload: {
      ids
    }
  };
}

export function addIntegrationSlackId(id) {
  return {
    type: ADD_INTEGRATION_SLACK_ID,
    payload: {
      id
    }
  };
}

export function removeIntegrationSlackId(id) {
  return {
    type: REMOVE_INTEGRATION_SLACK_ID,
    payload: {
      id
    }
  };
}
