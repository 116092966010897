import React, { useCallback } from "react";
import popup from "utils/popup";
import { Row, Col, Container } from "design-system-react";
import SettingsChannelHeader from "components/SettingsHeader";
import SettingsFacebookModalAddContainer from "./SettingsFacebookModalAddContainer";
import SettingsFacebookListContainer from "./SettingsFacebookListContainer";
import { apiUrl } from "utils/client";

function SettingsFacebookContainer() {
  const openUserOAuthWindow = useCallback(() => {
    popup(
      apiUrl() +
        "/channels/facebook/oauth?callbackUrl=" +
        apiUrl() +
        "/channels/facebook/get-pages",
      "user-oauth",
      700,
      500
    );
  }, []);

  return (
    <Row fullHeight column>
      <SettingsFacebookModalAddContainer />
      <Container>
        <SettingsChannelHeader
          title="Facebook Messenger"
          logo="/images/facebook_100.svg"
          onAction={openUserOAuthWindow}
        >
          Facebook integration lets you use Facebook Messenger as a
          communication channel to engage with your customers and prospects in
          the unified interface of Hugery.
        </SettingsChannelHeader>
      </Container>
      <Col justified>
        <SettingsFacebookListContainer />
      </Col>
    </Row>
  );
}

export default SettingsFacebookContainer;
