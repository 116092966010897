import React, { useCallback } from "react";
import {
  Table,
  TableHeader,
  TableHeaderColumn,
  TableHeaderColumnMenu,
  TableBody,
  TableRow,
  TableColumn,
  TableColumnMenu,
  Container,
  Scrollbar
} from "design-system-react";
import DateTime from "components/DateTime";
import SettingsChannelTableInfo from "components/SettingsChannelTableInfo";
import { channelActionItems, printChannelStatus } from "helpers/channels";
import {
  MODAL_DEFAULT,
  MODAL_ERROR,
  MODAL_PROCESS,
  MODAL_SUCCESS,
  showConfirmModal,
  updateConfirmModal
} from "utils/modalManager";
import { api } from "utils/client";
import { CommonError } from "utils/commonError";

function SettingsBlingerMessengerListContainer({ channels }) {
  const handleRestore = useCallback((channelId, name) => {
    const modalId = showConfirmModal(
      MODAL_DEFAULT,
      "Are you sure you want to restore " + name + "?",
      {
        actionButtonText: "Restore",
        onAction: () => {
          updateConfirmModal(modalId, MODAL_PROCESS, "Restoring...");

          api.messenger
            .restore(channelId)
            .ready.then(() => {
              updateConfirmModal(
                modalId,
                MODAL_SUCCESS,
                "Channel was successfully Restored"
              );
            })
            .catch(e => {
              const errorMessage = "Ooops, something went wrong";

              updateConfirmModal(
                modalId,
                MODAL_ERROR,
                e instanceof CommonError ? e.error : errorMessage
              );
            });
        }
      }
    );
  }, []);

  const handleDelete = useCallback((channelId, name) => {
    const modalId = showConfirmModal(
      MODAL_DEFAULT,
      "Are you sure you want to delete " + name + "?",
      {
        actionButtonText: "Delete",
        onAction: () => {
          updateConfirmModal(modalId, MODAL_PROCESS, "Deleting...");

          api.messenger
            .delete(channelId)
            .ready.then(() => {
              updateConfirmModal(
                modalId,
                MODAL_SUCCESS,
                "Channel was successfully deleted"
              );
            })
            .catch(e => {
              const errorMessage = "Ooops, something went wrong";

              updateConfirmModal(
                modalId,
                MODAL_ERROR,
                e instanceof CommonError ? e.error : errorMessage
              );
            });
        }
      }
    );
  }, []);

  const handleActionDropdown = useCallback(
    (channelId, name, action) => {
      switch (action) {
        case "restore":
          handleRestore(channelId, name);
          break;
        case "delete":
          handleDelete(channelId, name);
          break;
        default:
          break;
      }
    },
    [handleRestore, handleDelete]
  );

  return (
    <Scrollbar>
      <Container>
        <Table headerBorder>
          <TableHeader fixed>
            <TableHeaderColumn justified fixed>
              Name
            </TableHeaderColumn>
            <TableHeaderColumn>Added</TableHeaderColumn>
            <TableHeaderColumn>Status</TableHeaderColumn>
            <TableHeaderColumnMenu />
          </TableHeader>
          <TableBody>
            {channels.map(channel => (
              <TableRow disabled={channel.deletedAt}>
                <TableColumn justified fixed>
                  {channel.avatar && (
                    <SettingsChannelTableInfo
                      avatar={channel.avatar && channel.avatar.url["200X200"]}
                      name={channel.title}
                      // channel={`https://blinger.widget.io/${channel.id}`}
                    />
                  )}

                  {!channel.avatar && (
                    <SettingsChannelTableInfo
                      avatar={"/images/messengers/grey/Grey_Blinger_48.svg"}
                      name={channel.title}
                      noGradient
                      // channel={`https://blinger.widget.io/${channel.id}`}
                    />
                  )}
                </TableColumn>
                <TableColumn>
                  <DateTime time={channel.createdAt} />
                </TableColumn>
                <TableColumn>
                  {printChannelStatus(channel.deletedAt)}
                </TableColumn>
                <TableColumnMenu
                  items={channelActionItems(
                    channel.type,
                    channel.enabled,
                    channel.deletedAt
                  )}
                  onItemClick={key => {
                    handleActionDropdown(channel.id, channel.info.name, key);
                  }}
                />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Container>
    </Scrollbar>
  );
}

export default SettingsBlingerMessengerListContainer;
