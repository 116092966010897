import React, { useCallback, useState } from "react";
import {
  Table,
  TableHeader,
  TableHeaderColumn,
  TableHeaderColumnMenu,
  TableBody,
  Container,
  Scrollbar
} from "design-system-react";
import {
  MODAL_DEFAULT,
  MODAL_ERROR,
  MODAL_PROCESS,
  MODAL_SUCCESS,
  showConfirmModal,
  updateConfirmModal
} from "utils/modalManager";
import { api } from "utils/client";
import { CommonError } from "utils/commonError";
import { removeQuickReplyId } from "modules/quickReplies";
import { connect } from "react-redux";
import SettingsQuickRepliesEditModalContainer from "./SettingsQuickRepliesEditModalContainer";
import SettingsQuickRepliesListItem from "./SettingsQuickRepliesListItem";

function SettingsQuickRepliesListContainer({
  removeQuickReplyId,
  quickRepliesIds
}) {
  const [createReplyIsOpen, setCreateReplyIsOpen] = useState(false);
  const [formValues, setFormValues] = useState({});

  const toggleCreateReply = useCallback(() => {
    setCreateReplyIsOpen(!createReplyIsOpen);
  }, [createReplyIsOpen]);

  const handleEdit = useCallback(
    (id, title, text) => {
      setFormValues({ id, title, text });
      toggleCreateReply();
    },
    [toggleCreateReply]
  );

  const handleDelete = useCallback(
    (id, name) => {
      const modalId = showConfirmModal(
        MODAL_DEFAULT,
        "Are you sure you want to delete " + name + "?",
        {
          actionButtonText: "Delete",
          onAction: () => {
            updateConfirmModal(modalId, MODAL_PROCESS, "Deleting...");

            api.quickReplies
              .delete(id)
              .ready.then(() => {
                removeQuickReplyId(id);
                updateConfirmModal(
                  modalId,
                  MODAL_SUCCESS,
                  "Quick reply was successfully deleted"
                );
              })
              .catch(e => {
                const errorMessage = "Ooops, something went wrong";

                updateConfirmModal(
                  modalId,
                  MODAL_ERROR,
                  e instanceof CommonError ? e.error : errorMessage
                );
              });
          }
        }
      );
    },
    [removeQuickReplyId]
  );

  const handleActionDropdown = useCallback(
    (id, title, text, action) => {
      switch (action) {
        case "edit":
          handleEdit(id, title, text);
          break;
        case "delete":
          handleDelete(id, title);
          break;
        default:
          break;
      }
    },
    [handleDelete, handleEdit]
  );

  return (
    <Scrollbar>
      <SettingsQuickRepliesEditModalContainer
        isOpen={createReplyIsOpen}
        toggle={toggleCreateReply}
        formValues={formValues}
      />
      <Container>
        <Table headerBorder>
          <TableHeader fixed>
            <TableHeaderColumn width={200} fixed>
              Title
            </TableHeaderColumn>
            <TableHeaderColumn justified>Text</TableHeaderColumn>
            <TableHeaderColumn>Added</TableHeaderColumn>
            <TableHeaderColumnMenu />
          </TableHeader>
          <TableBody>
            {quickRepliesIds.map(id => {
              return (
                <SettingsQuickRepliesListItem
                  quickReplyId={id}
                  removeQuickReplyId={removeQuickReplyId}
                  handleActionDropdown={handleActionDropdown}
                />
              );
            })}
          </TableBody>
        </Table>
      </Container>
    </Scrollbar>
  );
}

const mapDispatchToProps = dispatch => ({
  removeQuickReplyId: id => dispatch(removeQuickReplyId(id))
});

export default connect(
  null,
  mapDispatchToProps
)(SettingsQuickRepliesListContainer);
