import { Divider, Space, Typography } from "antd";
import { Link } from "react-router-dom";
import styles from "./SearchResult.module.scss";

export interface SearchResultProps extends React.HTMLAttributes<HTMLElement> {
  icon?: React.ReactNode;
  title?: string;
  actions?: Array<any>;
  to?: any;
  children?: string;
}

const splitHighlight = function(str: string): React.ReactNode {
  const parts = str.split(/<em>|<\/em>/);

  if (parts.length < 2) {
    return parts;
  }

  const output: any = [];

  parts.map((part, index) => {
    if (index % 2 !== 0) {
      output.push(<Typography.Text mark>{part}</Typography.Text>);
    } else {
      output.push(part);
    }

    return part;
  });

  return output;
};

function SearchResult({
  icon,
  title,
  actions,
  to,
  children,
  ...props
}: SearchResultProps) {
  return (
    <Link className={styles.wrapper} to={to} {...props}>
      <div className={styles.icon}>{icon}</div>
      <div className={styles.content}>
        <div className={styles.title}>{title && splitHighlight(title)}</div>
        {actions && (
          <div className={styles.actions}>
            <Space split={<Divider type="vertical" />}>
              {actions.map(action => {
                return <div className={styles.action}>{action}</div>;
              })}
            </Space>
          </div>
        )}
        <div className={styles.description}>
          {children && splitHighlight(children)}
        </div>
      </div>
    </Link>
  );
}

export default SearchResult;
