import React from "react";
import WidgetHeaderTitle from "components/Widget/WidgetHeaderTitle";
import WidgetHeaderDescription from "components/Widget/WidgetHeaderDescription";
import WidgetHeader from "components/Widget/WidgetHeader";
import WidgetAvailabilityContainer from "./containers/WidgetAvailabilityContainer";

function WidgetAvailability() {
  return (
    <>
      <WidgetHeader>
        <WidgetHeaderTitle>Availability</WidgetHeaderTitle>
        <WidgetHeaderDescription>
          Set working hours of your company and replies when your support is
          offline
        </WidgetHeaderDescription>
      </WidgetHeader>
      <WidgetAvailabilityContainer />
    </>
  );
}

export default WidgetAvailability;
