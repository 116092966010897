import React, { useEffect, useMemo } from "react";
import AnalyticsSubMenu from "components/AnalyticsSubMenu";
import AnalyticsContainer from "./containers/AnalyticsContainer";
import ToggableLayout from "components/ToggableLayout";
import queryString from "query-string";

function AnalyticsTeams({ match, location }) {
  useEffect(() => {
    document.title = "Analytics";
    localStorage.setItem("analiyticsRoute", match.url);
  }, [match.url]);

  const urlParams = useMemo(() => {
    return queryString.parse(location.search);
  }, [location.search]);

  return (
    <ToggableLayout
      renderSubmenu={() => {
        return <AnalyticsSubMenu activeMenuItem={match.url} />;
      }}
      renderContent={container => (
        <AnalyticsContainer
          onSubMenuToggle={container.toggleSubmenu}
          isSubmenuOpened={container.isSubmenuOpened}
          urlParams={urlParams}
        />
      )}
    />
  );
}

export default AnalyticsTeams;
