import styles from "./Table.module.scss";

interface TableBodyProps {
  children?: React.ReactNode;
}

function TableBody({ children }: TableBodyProps) {
  return <div className={styles.tableBody}>{children}</div>;
}

export default TableBody;
