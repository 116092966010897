import React from "react";
import InputField from "components/InputField";
import { Field } from "formik";
import { Button, GutterField } from "design-system-react";

function CreateNewPasswordFormContainer({
  values,
  handleSubmit,
  isSubmitting
}) {
  const isEmptyFields = () => {
    return !values.password || !values.confirmPassword;
  };

  return (
    <form onSubmit={handleSubmit}>
      <h1 className="sign-in-title">Create a new password</h1>

      <GutterField>
        <Field
          name="password"
          type="password"
          label="New password"
          component={InputField}
        />
      </GutterField>

      <GutterField>
        <Field
          name="confirmPassword"
          type="password"
          label="Confirm password"
          component={InputField}
        />
      </GutterField>

      <Button
        type="submit"
        disabled={isEmptyFields()}
        processing={isSubmitting}
      >
        Submit
      </Button>
    </form>
  );
}

export default CreateNewPasswordFormContainer;
