import React from "react";
import CaseListItemEndUser from "../components/CaseListItemEndUser";
import { connect } from "react-redux";
import { channelSmIcon } from "../helpers/channelIcon";
import LastCaseMessageContainer from "./LastCaseMessageContainer";

function CaseListItemEndUserContainer({
  collapsed,
  avatar,
  channel,
  name,
  color,
  ownerTeamMember,
  lastMessageId,
  userAid,
  caseItemSubject
}) {
  return (
    <CaseListItemEndUser
      collapsed={collapsed}
      avatar={avatar}
      channel={channel}
      name={name}
      color={color}
      userAid={userAid}
      ownerTeamMember={ownerTeamMember}
      renderMessage={
        <LastCaseMessageContainer
          messageId={lastMessageId}
          caseItemSubject={caseItemSubject}
        />
      }
    />
  );
}

const mapStateToProps = (state, props) => {
  const endUser = state.entities.endUsers[props.endUserId];

  return {
    avatar: endUser && endUser.avatar,
    channel: endUser && channelSmIcon(endUser.channelType),
    name: endUser && endUser.name,
    color: endUser && endUser.titleColor,
    ownerTeamMember: endUser && endUser.ownerTeamMember,
    userAid: endUser && endUser.aid
  };
};

export default connect(mapStateToProps)(CaseListItemEndUserContainer);
