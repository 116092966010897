import React, { useEffect, useCallback, useMemo, useRef } from "react";
import { connect } from "react-redux";
import {
  Table,
  TableHeader,
  TableHeaderColumn,
  TableBody,
  Col,
  Preloader,
  ScrollableList
} from "design-system-react";
import EndUserCasesItemContainer from "./EndUserCasesItemContainer";
import { api } from "utils/client";
import { setCaseIds, addCaseIds } from "modules/pages/endUser";
import usePagination from "hooks/usePagination";
import EmptyState from "components/EmptyState";
import history from "utils/history";
import CaseModalContainer from "../../../containers/CaseModalContainer";
import InfinitePagination from "../../../components/infinitePagination/InfinitePagination";

function EndUserCasesListContainer({
  setCaseIds,
  addCaseIds,
  userId,
  activeCaseId,
  caseIds
}) {
  const scrollbarRef = useRef(null);

  const handleCasesFetch = useCallback(
    request => {
      request.ready.then(response => {
        addCaseIds(response.data.data.map(caseItem => caseItem.id));
      });
    },
    [addCaseIds]
  );

  const casesFetchParams = useMemo(() => {
    return {
      userId
    };
  }, [userId]);

  const casesPagination = usePagination(
    api.endUsers.cases,
    casesFetchParams,
    30,
    handleCasesFetch
  );

  useEffect(() => {
    if (userId) {
      setCaseIds([]);

      const request = casesPagination.load();

      return () => {
        request.cancelRequest.cancel();
      };
    }
    // eslint-disable-next-line
  }, [userId, setCaseIds]);

  const handleCloseCase = useCallback(() => {
    history.replace({
      pathname: "/end-user/" + userId
    });
  }, [userId]);

  if (caseIds.length === 0 && casesPagination.nextLoading) {
    return (
      <Col align="center" verticalAlign="center" justified>
        <Preloader size="32" color="#807d7f" />
      </Col>
    );
  }

  if (caseIds.length === 0) {
    return (
      <Col align="center" verticalAlign="center" justified>
        <EmptyState title="No end user cases">
          Currently this user has no <br /> cases
        </EmptyState>
      </Col>
    );
  }

  return (
    <React.Fragment>
      {
        <CaseModalContainer
          caseId={activeCaseId}
          onCloseCase={handleCloseCase}
        />
      }
      <InfinitePagination
        pagination={casesPagination}
        scrollbarRef={scrollbarRef}
      >
        <Table>
          <TableHeader fixed>
            <TableHeaderColumn width={300} justified fixed />
            <TableHeaderColumn>Status</TableHeaderColumn>
            <TableHeaderColumn>Assignee</TableHeaderColumn>
            <TableHeaderColumn>Updated</TableHeaderColumn>
            <TableHeaderColumn>Channel</TableHeaderColumn>
            <TableHeaderColumn>Case ID</TableHeaderColumn>
          </TableHeader>
          <TableBody>
            <ScrollableList
              rowHeight={80}
              rowCount={caseIds.length}
              scrollbarRef={scrollbarRef}
            >
              {index => {
                return (
                  <EndUserCasesItemContainer
                    key={caseIds[index].id}
                    caseItemId={caseIds[index]}
                    endUserId={userId}
                  />
                );
              }}
            </ScrollableList>
            {casesPagination.nextLoading && (
              <Col align="center" gutterTop={30} gutterBottom={30}>
                <Preloader size="32" color="#807d7f" />
              </Col>
            )}
          </TableBody>
        </Table>
      </InfinitePagination>
    </React.Fragment>
  );
}

function mapStateToProps(state) {
  return {
    caseIds: state.pages.endUser.caseIds
  };
}

const mapDispatchToProps = dispatch => ({
  setCaseIds: ids => dispatch(setCaseIds(ids)),
  addCaseIds: id => dispatch(addCaseIds(id))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EndUserCasesListContainer);
