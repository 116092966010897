export function makeAnalyticUrl(url, params) {
  var esc = encodeURIComponent;

  return (
    url +
    "?" +
    Object.keys(params)
      .filter(k => params[k])
      .map(k => esc(k) + "=" + esc(params[k]))
      .join("&")
  );
}
