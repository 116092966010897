import { CALL_API, client as api } from "utils/api";
import { withNamespace } from "utils/namespace";

const type = withNamespace("pages/cases/caseList");

// action types
export const FETCH_CASES_REQUEST = type("FETCH_CASES_REQUEST");
export const FETCH_CASES_SUCCESS = type("FETCH_CASES_SUCCESS");
export const FETCH_CASES_FAILURE = type("FETCH_CASES_FAILURE");
export const ADD_CASE = type("ADD_CASE");
export const DELETE_CASE = type("DELETE_CASE");
export const DELETE_ALL_CASES = type("DELETE_ALL_CASES");
export const ADD_EVENT_PREVIEW = type("ADD_EVENT_PREVIEW");
export const DELETE_EVENT_PREVIEW = type("DELETE_EVENT_PREVIEW");

let initialState = {
  listIds: [],
  eventPreviews: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CASES_REQUEST:
      return {
        ...state,
        listIds: [],
        eventPreviews: []
      };
    case FETCH_CASES_SUCCESS:
      return {
        ...state,
        listIds: action.payload.data.map(caseItem => caseItem.id)
      };
    case FETCH_CASES_FAILURE:
      return {
        ...state,
        listIds: []
      };
    case ADD_CASE:
      if (state.listIds.indexOf(action.payload.caseId) !== -1) {
        return state;
      }

      return {
        ...state,
        listIds: [action.payload.caseId, ...state.listIds]
      };
    case DELETE_CASE:
      return {
        ...state,
        listIds: state.listIds.filter(id => {
          return id !== action.payload.caseId;
        })
      };
    case DELETE_ALL_CASES:
      return {
        ...state,
        listIds: []
      };
    case ADD_EVENT_PREVIEW:
      return {
        ...state,
        eventPreviews: [
          ...state.eventPreviews.filter(
            e => e.caseId !== action.payload.caseId
          ),
          action.payload
        ]
      };
    case DELETE_EVENT_PREVIEW:
      return {
        ...state,
        eventPreviews: state.eventPreviews.filter(
          e => e.caseId !== action.payload.caseId
        )
      };
    default:
      return state;
  }
}

// actions
export function fetchCases(params = {}) {
  return dispatch => {
    dispatch({ type: DELETE_ALL_CASES });

    return dispatch({
      [CALL_API]: {
        endpoint: "/cases",
        options: {
          method: "get",
          params: params
        },
        types: {
          request: FETCH_CASES_REQUEST,
          success: FETCH_CASES_SUCCESS,
          failure: FETCH_CASES_FAILURE
        }
      }
    });
  };
}

export function addCase(caseId) {
  return {
    type: ADD_CASE,
    payload: {
      caseId
    }
  };
}

export function deleteCase(caseId) {
  return {
    type: DELETE_CASE,
    payload: {
      caseId
    }
  };
}

export function addEventPreview(caseId, text, icon) {
  return {
    type: ADD_EVENT_PREVIEW,
    payload: {
      caseId,
      text,
      icon
    }
  };
}

export function deleteEventPreview(caseId) {
  return {
    type: DELETE_EVENT_PREVIEW,
    payload: {
      caseId
    }
  };
}

export function checkCaseView(caseId, viewName) {
  return dispatch => {
    return api({
      method: "get",
      url: "/cases/" + caseId + "/check_view",
      params: {
        view: viewName
      }
    }).then(response => {
      if (response.data === true) {
        dispatch(addCase(caseId));
      } else if (response.data === false) {
        setTimeout(() => {
          dispatch(deleteCase(caseId));
        }, 2000);
      }

      setTimeout(() => {
        dispatch(deleteEventPreview(caseId));
      }, 2000);
    });
  };
}
