import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import EndUserNavPanel from "../components/EndUserNavPanel";
import EndUserHeader from "../components/EndUserHeader";
import { Row, Col, Preloader, Scrollbar, SidePanel } from "design-system-react";
import EndUserCasesListContainer from "./EndUserCasesListContainer";
import EndUserSidebar from "../components/EndUserSidebar";
import EndUserSidebarContainer from "./EndUserSidebarContainer";
import { api } from "utils/client";
import { ALERT_ERROR } from "utils/alertManager";
import { addCaseId } from "modules/pages/endUser";
import useAlertRedirect from "hooks/useAlertRedirect";
import EndUserCardContainer from "./EndUserCardContainer";

const SHOW_SIDE_PANEL_WIDTH = 1365;

function EndUserContainer({ activeCaseId, endUserId }) {
  const [loading, setLoading] = useState(true);
  const [isOpenDetailsSidePanel, setIsOpenDetailsSidePanel] = useState(false);
  const toggleDetailsSidePanel = useCallback(() => {
    setIsOpenDetailsSidePanel(!isOpenDetailsSidePanel);
  }, [isOpenDetailsSidePanel]);

  const redirect = useAlertRedirect();

  useEffect(() => {
    setLoading(true);

    api.endUsers
      .one(endUserId)
      .ready.catch(e => {
        if (e.response.status === 404) {
          redirect("/end-users/enabled", ALERT_ERROR, "End user not found.");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [endUserId, redirect]);

  const [showInSidePanel, setShowInSidePanel] = useState(
    window.innerWidth < SHOW_SIDE_PANEL_WIDTH
  );

  const handleWindowResize = useCallback(
    e => {
      if (e.target.innerWidth < SHOW_SIDE_PANEL_WIDTH) {
        if (!showInSidePanel) {
          setShowInSidePanel(true);
        }
      } else {
        if (showInSidePanel) {
          setShowInSidePanel(false);
        }
      }
    },
    [showInSidePanel]
  );

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleWindowResize]);

  if (loading) {
    return (
      <Row fullHeight>
        <Col align="center" verticalAlign="center" justified>
          <Preloader size="32" color="#807d7f" />
        </Col>
      </Row>
    );
  }

  return (
    <Row fullHeight column nowrap>
      <Col auto>
        <EndUserHeader>
          <EndUserNavPanel />
          <Col auto>
            <EndUserCardContainer
              endUserId={endUserId}
              toggleDetailsSidePanel={toggleDetailsSidePanel}
            />
          </Col>
        </EndUserHeader>
      </Col>
      <Row fullHeight>
        <Col flex justified>
          <Row fullHeight style={{ width: "100%" }}>
            <Col justified>
              <EndUserCasesListContainer
                activeCaseId={activeCaseId}
                userId={endUserId}
              />
            </Col>
            {showInSidePanel ? (
              <SidePanel
                isOpen={isOpenDetailsSidePanel}
                onClose={toggleDetailsSidePanel}
                width={320}
                noPadding
              >
                <Scrollbar>
                  <EndUserSidebarContainer endUserId={endUserId} />
                </Scrollbar>
              </SidePanel>
            ) : (
              <EndUserSidebar>
                <Scrollbar>
                  <EndUserSidebarContainer endUserId={endUserId} />
                </Scrollbar>
              </EndUserSidebar>
            )}
          </Row>
        </Col>
      </Row>
    </Row>
  );
}

const mapDispatchToProps = dispatch => ({
  addCaseId: id => dispatch(addCaseId(id))
});

export default connect(
  null,
  mapDispatchToProps
)(EndUserContainer);
