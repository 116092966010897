import { CALL_API } from "utils/api";
import { withNamespace } from "utils/namespace";
import { removeAllAttachments } from "modules/pages/cases/messageForm";

const type = withNamespace("pages/cases");

export const FETCH_CASES_REQUEST = type("FETCH_CASES_REQUEST");
export const FETCH_CASES_SUCCESS = type("FETCH_CASES_SUCCESS");
export const FETCH_CASES_FAILURE = type("FETCH_CASES_FAILURE");
export const FETCH_USERS_FOR_ASSIGN_SUCCESS = type(
  "FETCH_USERS_FOR_ASSIGN_SUCCESS"
);
export const CLEAR_USERS_FOR_ASSIGN = type("CLEAR_USERS_FOR_ASSIGN");
export const ADD_CASE = type("ADD_CASE");
export const REMOVE_CASE = type("REMOVE_CASE");
export const FETCH_CASE_VIEWS_SUCCESS = type("FETCH_CASE_VIEWS_SUCCESS");
export const FETCH_MESSAGES_REQUEST = type("FETCH_MESSAGES_REQUEST");
export const FETCH_MESSAGES_SUCCESS = type("FETCH_MESSAGES_SUCCESS");
export const FETCH_MESSAGES_FAILURE = type("FETCH_MESSAGES_FAILURE");
export const START_NEW_CASE = type("START_NEW_CASE");
export const START_NEW_CASE_FAILURE = type("START_NEW_CASE_FAILURE");
export const ADD_MESSAGE = type("ADD_MESSAGE");
export const ADD_OUTGOING_MESSAGE = type("ADD_OUTGOING_MESSAGE");
export const REMOVE_OUTGOING_MESSAGE = type("REMOVE_OUTGOING_MESSAGE");
export const UPDATE_OUTGOING_MESSAGE_STATUS = type(
  "UPDATE_OUTGOING_MESSAGE_STATUS"
);
export const SET_CURRENT_VIEW = type("SET_CURRENT_VIEW");
export const SET_CURRENT_CASE = type("SET_CURRENT_CASE");
export const CLOSED_CASE_REQUEST = type("CLOSED_CASE_REQUEST");
export const CLOSED_CASE_SUCCESS = type("CLOSED_CASE_SUCCESS");
export const CLOSED_CASE_FAILURE = type("CLOSED_CASE_FAILURE");
export const OPEN_CREATE_NOTE_MODAL = type("OPEN_CREATE_NOTE_MODAL");
export const CLOSE_CREATE_NOTE_MODAL = type("CLOSE_CREATE_NOTE_MODAL");
export const SET_TEXT_FOR_EVENT_ANIMATION = type(
  "SET_TEXT_FOR_EVENT_ANIMATION"
);

// const
export const UNASSIGNED_VIEW_NAME = "unassigned";

export const CASE_NEW = "new";
export const CASE_OPEN = "open";
export const CASE_CLOSED = "closed";

export const MESSAGE_STATUS_SENDING = "sending";
export const MESSAGE_STATUS_SENT = "sent";
export const MESSAGE_STATUS_DELIVERED = "delivered";
export const MESSAGE_STATUS_READ = "read";
export const MESSAGE_STATUS_ERROR = "error";

export const TIME_FOR_MICROANIMATION = 1.5 * 1000;

// state
let initialState = {
  caseViewListIds: [],
  caseListIds: [],
  messageListIds: [],
  membersForAssign: [],
  outgoingMessagesByCase: {},
  currentViewName: null,
  currentCaseId: null,
  startLoadingCasesForviewName: null,
  isDoneCaseLoading: false,
  isCreateNoteModalOpen: false,
  casesLoading: false,
  casesEventAnimation: {}
};

// websocket listener
// subscribe(WS_EVENT_MESSAGE_CREATED, data => {
//   store.dispatch(
//     removeOutgoingMessage(
//       data.data.relationships.cases.data.id,
//       data.data.attributes.uuid
//     )
//   );
//
//   store.dispatch({
//     type: ADD_MESSAGE,
//     payload: {
//       caseId: data.data.relationships.cases.data.id,
//       data: [data.data]
//     }
//   });
// });

export default function reducer(state = initialState, action) {
  switch (action.type) {
    // case FETCH_CASES_REQUEST:
    //   return {
    //     ...state,
    //     startLoadingCasesForviewName: action.payload.viewName,
    //     casesLoading: true
    //   };
    // case FETCH_CASES_SUCCESS:
    //   if (state.startLoadingCasesForviewName !== action.payload.viewName) {
    //     return {
    //       ...state,
    //       casesLoading: false
    //     };
    //   }
    //
    //   return {
    //     ...state,
    //     casesLoading: false,
    //     caseListIds: action.payload.data.map(caseItem => caseItem.id)
    //   };
    // case FETCH_CASES_FAILURE:
    //   return {
    //     ...state,
    //     casesLoading: false
    //   };
    case FETCH_USERS_FOR_ASSIGN_SUCCESS:
      return {
        ...state,
        membersForAssign: action.payload.data.map(user => user.id)
      };
    case CLEAR_USERS_FOR_ASSIGN:
      return {
        ...state,
        membersForAssign: []
      };
    case SET_CURRENT_CASE:
      return {
        ...state,
        currentCaseId: action.payload.caseId
      };
    case SET_CURRENT_VIEW:
      return {
        ...state,
        currentViewName: action.payload.viewName
      };
    case FETCH_CASE_VIEWS_SUCCESS:
      return {
        ...state,
        caseViewListIds: action.payload.data.map(caseItem => caseItem.id)
      };
    case FETCH_MESSAGES_REQUEST:
      return {
        ...state,
        messageListIds: []
      };
    case FETCH_MESSAGES_SUCCESS:
      return {
        ...state,
        messageListIds: action.payload.data.map(message => message.id)
      };
    case START_NEW_CASE:
      return {
        ...state,
        currentCaseId: action.payload.data[0].id,
        currentViewName: UNASSIGNED_VIEW_NAME
      };
    case START_NEW_CASE_FAILURE:
      return {
        ...state
      };
    case ADD_MESSAGE:
      if (state.messageListIds.indexOf(action.payload.data[0].id) !== -1) {
        return state;
      }

      return {
        ...state,
        messageListIds: [...state.messageListIds, action.payload.data[0].id]
      };
    case ADD_OUTGOING_MESSAGE:
      return {
        ...state,
        outgoingMessagesByCase: {
          ...state.outgoingMessagesByCase,
          [action.payload.caseId]: [
            ...(state.outgoingMessagesByCase[action.payload.caseId] || []),
            action.payload.message
          ]
        }
      };
    case REMOVE_OUTGOING_MESSAGE:
      return {
        ...state,
        outgoingMessagesByCase: {
          ...state.outgoingMessagesByCase,
          [action.payload.caseId]: (
            state.outgoingMessagesByCase[action.payload.caseId] || []
          ).filter(message => message.uuid !== action.payload.uuid)
        }
      };
    case UPDATE_OUTGOING_MESSAGE_STATUS:
      return {
        ...state,
        outgoingMessagesByCase: {
          ...state.outgoingMessagesByCase,
          [action.payload.caseId]: state.outgoingMessagesByCase[
            action.payload.caseId
          ].map(message =>
            message.uuid === action.payload.uuid
              ? {
                  ...message,
                  status: action.payload.status
                }
              : message
          )
        }
      };

    // ...state,
    // outgoingMessagesByCase: {
    //   ...state.outgoingMessagesByCase,
    //   [action.payload.caseId]: [
    //     ...(state.outgoingMessagesByCase[action.payload.caseId] || []).filter(message => {
    //
    //     }),
    //       state.outgoingMessagesByCase[action.payload.caseId][action.payload.message.uuid]: action.payload.message
    //   ]
    // }
    // };

    // case ASSIGN_SUCCESS:
    //   return {
    //     ...state
    //   };
    // case UNASSIGN_SUCCESS:
    //   return {
    //     ...state
    //   };
    case CLOSED_CASE_REQUEST:
      return {
        ...state,
        isDoneCaseLoading: true
      };
    case CLOSED_CASE_SUCCESS:
      return {
        ...state,
        isDoneCaseLoading: false
      };
    case CLOSED_CASE_FAILURE:
      return {
        ...state,
        isDoneCaseLoading: false
      };
    case SET_TEXT_FOR_EVENT_ANIMATION:
      return {
        ...state,
        casesEventAnimation: {
          caseId: Number(action.payload.caseId),
          ico: action.payload.ico,
          text: action.payload.text
        }
      };
    case OPEN_CREATE_NOTE_MODAL:
      return {
        ...state,
        isCreateNoteModalOpen: true
      };
    case CLOSE_CREATE_NOTE_MODAL:
      return {
        ...state,
        isCreateNoteModalOpen: false
      };
    default:
      return state;
  }
}

// actions
export function fetchCaseViews(params = {}) {
  return dispatch => {
    return dispatch({
      [CALL_API]: {
        endpoint: "/views/cases",
        options: {
          method: "get",
          params: params
        },
        types: {
          success: FETCH_CASE_VIEWS_SUCCESS
        }
      }
    });
  };
}

export function fetchCase(caseId, params) {
  return dispatch => {
    return dispatch({
      [CALL_API]: {
        endpoint: "/cases/" + caseId,
        options: {
          method: "get",
          params: params
        }
      }
    });
  };
}

export function fetchMessages(caseId, params) {
  return dispatch => {
    return dispatch({
      [CALL_API]: {
        endpoint: "/cases/" + caseId + "/messages",
        options: {
          method: "get",
          params: params
        },
        types: {
          request: FETCH_MESSAGES_REQUEST,
          success: FETCH_MESSAGES_SUCCESS
        },
        payload: {
          caseId
        }
      }
    });
  };
}

export function fetchUsersForAssign(caseId) {
  return dispatch => {
    return dispatch({
      [CALL_API]: {
        endpoint: "/cases/" + caseId + "/users_for_assign",
        options: {
          method: "get"
        },
        types: {
          success: FETCH_USERS_FOR_ASSIGN_SUCCESS
        },
        payload: {
          caseId
        }
      }
    });
  };
}

export function addMessage(caseId, message) {
  return {
    type: ADD_MESSAGE,
    payload: {
      caseId: caseId,
      data: message
    }
  };
}

export function addOutgoingMessage(caseId, message) {
  return {
    type: ADD_OUTGOING_MESSAGE,
    payload: {
      caseId: caseId,
      message: message
    }
  };
}

export function removeOutgoingMessage(caseId, uuid) {
  return {
    type: REMOVE_OUTGOING_MESSAGE,
    payload: {
      caseId: caseId,
      uuid: uuid
    }
  };
}

export function updateOutgoingMessageStatus(caseId, uuid, status) {
  return {
    type: UPDATE_OUTGOING_MESSAGE_STATUS,
    payload: {
      caseId,
      uuid,
      status
    }
  };
}

export function sendMessage(channelType, caseId, params) {
  return dispatch => {
    if (params.text || (params.files && params.files.length !== 0)) {
      dispatch(
        addOutgoingMessage(caseId, {
          caseId,
          uuid: params.uuid,
          text: params.text,
          files: params.files,
          status: MESSAGE_STATUS_SENDING,
          createdAt: Math.floor(new Date().getTime() / 1000)
        })
      );
    }

    dispatch(removeAllAttachments());

    const actionResponse = dispatch({
      [CALL_API]: {
        endpoint: "/cases/" + caseId + "/messages/" + channelType,
        options: {
          method: "post",
          data: params
        },
        types: {
          success: ADD_MESSAGE
        },
        payload: {
          caseId
        }
      }
    });

    actionResponse.ready.then(action => {
      if (action.success) {
        dispatch(removeOutgoingMessage(caseId, params.uuid));

        const messageId = Object.keys(action.response.messages)[0];

        dispatch({
          type: ADD_MESSAGE,
          payload: {
            data: [action.response.messages[messageId]],
            caseId
          }
        });
      } else {
        dispatch(
          updateOutgoingMessageStatus(caseId, params.uuid, MESSAGE_STATUS_ERROR)
        );
      }
    });

    return actionResponse;
  };
}

export function sendNote(caseId, params) {
  return dispatch => {
    dispatch(
      addOutgoingMessage(caseId, {
        caseId,
        uuid: params.uuid,
        text: params.text,
        type: "note",
        status: MESSAGE_STATUS_SENDING,
        createdAt: Math.floor(new Date().getTime() / 1000)
      })
    );

    const actionResponse = dispatch({
      [CALL_API]: {
        endpoint: "/cases/" + caseId + "/note",
        options: {
          method: "post",
          data: params
        },
        types: {
          success: ADD_MESSAGE
        },
        payload: {
          caseId
        }
      }
    });

    actionResponse.ready.then(action => {
      dispatch(removeOutgoingMessage(caseId, params.uuid));

      if (action.success) {
        const messageId = Object.keys(action.response.messages)[0];

        dispatch({
          type: ADD_MESSAGE,
          payload: {
            data: [action.response.messages[messageId]],
            caseId
          }
        });
      }
    });

    return null;
  };
}

export function setCurrentCase(id) {
  return {
    type: SET_CURRENT_CASE,
    payload: {
      caseId: id
    }
  };
}

export function startNewCase(oldCaseId) {
  return dispatch => {
    const actionResponse = dispatch({
      [CALL_API]: {
        endpoint: "/cases/" + oldCaseId + "/create",
        options: {
          method: "post"
        },
        types: {
          success: START_NEW_CASE,
          failure: START_NEW_CASE_FAILURE
        }
      }
    });

    actionResponse.ready.then(action => {
      if (action.success) {
        dispatch(fetchCaseViews());
        // dispatch(fetchCases(1));
      }
    });

    return actionResponse;
  };
}

export function setCasesEventAnimationText(caseId, ico, text) {
  return {
    type: SET_TEXT_FOR_EVENT_ANIMATION,
    payload: {
      caseId: caseId,
      ico: ico,
      text: text
    }
  };
}
