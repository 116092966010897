import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Popup,
  PopupBody,
  PopupHeader,
  PopupFooter,
  Button,
  GutterField
} from "design-system-react";
import { Field, Formik } from "formik";
import InputField from "../../../components/InputField";
import TextareaField from "../../../components/TextareaField";
import { addQuickReplyId } from "../../../modules/quickReplies";
import { api } from "../../../utils/client";
import { FormError } from "../../../utils/formError";
import uuidv4 from "../../../utils/uuidv4";
import useFormSubmission from "../../../hooks/useFormSubmission";

function Form({ submitDisabled, setSubmitDisabled, handleSubmit, dirty }) {
  const handleFormSubmit = useFormSubmission(submitDisabled, handleSubmit);

  if (!dirty) {
    if (!submitDisabled) {
      setSubmitDisabled(true);
    }
  } else if (submitDisabled) {
    setSubmitDisabled(false);
  }

  return (
    <form onSubmit={handleFormSubmit}>
      <Row>
        <Col auto justified>
          <GutterField>
            <Field
              name="title"
              type="name"
              label="Title"
              component={InputField}
            />
          </GutterField>
        </Col>
      </Row>
      <Row gutters nowrap>
        <Col auto justified>
          <Field
            name="text"
            type="text"
            label="Message to send"
            component={TextareaField}
          />
        </Col>
      </Row>
    </form>
  );
}

function CreateQuickReplyContainer({
  isOpen,
  toggle,
  isEndUserModal,
  addQuickReplyId
}) {
  const [initialSaved, setInitialSaved] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);

  const handleSubmit = useCallback(
    (values, { setSubmitting, setErrors, resetForm }) => {
      api.quickReplies
        .create(uuidv4(), values.title, values.text)
        .ready.then(response => {
          addQuickReplyId(response.data.data.id);
          setInitialSaved(true);
          resetForm();
          if (isOpen) {
            toggle();
          }
        })
        .catch(e => {
          if (e instanceof FormError) {
            setErrors(e.errors);
          }
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [isOpen, addQuickReplyId, toggle]
  );

  return (
    <Formik
      initialValues={{
        title: "",
        text: ""
      }}
      onSubmit={handleSubmit}
      render={props => (
        <Popup isOpen={isOpen} isAuto={isEndUserModal} onClose={toggle}>
          <PopupHeader>Create quick reply</PopupHeader>
          <PopupBody>
            <Form
              submitDisabled={submitDisabled}
              setSubmitDisabled={setSubmitDisabled}
              {...props}
            />
          </PopupBody>
          <PopupFooter>
            <Button
              onClick={props.submitForm}
              processing={props.isSubmitting}
              disabled={submitDisabled}
            >
              {submitDisabled && initialSaved ? "Created" : "Create"}
            </Button>
            <Button outline={true} onClick={toggle}>
              Cancel
            </Button>
          </PopupFooter>
        </Popup>
      )}
    />
  );
}

const mapDispatchToProps = dispatch => ({
  addQuickReplyId: id => dispatch(addQuickReplyId(id))
});

export default connect(
  null,
  mapDispatchToProps
)(CreateQuickReplyContainer);
