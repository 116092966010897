import React, { useMemo } from "react";
import Rule from "pages/SettingsAutoResponse/components/Rule";
import { Field, FieldArray } from "formik";
import Conditions from "pages/SettingsAutoResponse/components/Conditions";
import Condition from "pages/SettingsAutoResponse/components/Condition";
import ConditionField from "pages/SettingsAutoResponse/components/ConditionField";
import SelectField from "components/SelectField";
import View from "components/View";
import AddConditionButton from "pages/SettingsAutoResponse/components/AddConditionButton";

const operatorArray = [
  {
    label: "Is",
    value: "is"
  },
  {
    label: "Is not",
    value: "isNot"
  }
];

const qualificationArray = [
  {
    label: "Lead",
    value: "lead"
  },
  {
    label: "Visitor",
    value: "visitor"
  },
  {
    label: "Customer",
    value: "customer"
  }
];

function QualificationRuleContainer({ qualification }) {
  const additionalOperators = useMemo(() => {
    const filteredIsNot = qualification.filter(i => i.operator === "isNot");
    if (filteredIsNot.length >= 2) {
      operatorArray[1].hide = true;
    } else {
      operatorArray[1].hide = false;
    }

    return operatorArray;
  }, [qualification]);

  const additionalQualification = useMemo(() => {
    return qualificationArray.filter(i => {
      return qualification.map(q => {
        if (q.qualification === i.value) {
          i.hide = true;
        }
        return q;
      });
    });
  }, [qualification]);

  return (
    <Rule
      title={
        <>
          <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px">
            <path d="M10.000,20.000 C-0.643,20.000 -0.031,10.316 0.023,10.000 C-0.031,9.684 -0.643,-0.000 10.000,-0.000 C20.643,-0.000 20.031,9.682 19.977,10.005 C20.031,10.314 20.643,20.000 10.000,20.000 ZM16.767,16.503 C16.550,16.735 16.311,16.957 16.048,17.168 C16.311,16.957 16.550,16.735 16.767,16.503 ZM16.048,17.168 C14.773,15.837 12.988,13.999 11.001,13.999 L9.000,13.999 C7.005,13.999 5.216,15.843 3.941,17.183 C5.273,18.245 7.188,19.000 9.985,19.000 C12.795,19.000 14.715,18.238 16.048,17.168 ZM9.985,1.000 C0.420,1.000 0.971,9.716 1.020,10.000 C0.985,10.201 0.718,14.613 3.941,17.183 C3.677,16.972 3.437,16.752 3.218,16.520 C4.674,14.981 6.713,13.000 9.000,13.000 L11.001,13.000 C13.279,13.000 15.312,14.973 16.767,16.503 C19.206,13.903 18.981,10.185 18.950,10.004 C18.998,9.714 19.548,1.000 9.985,1.000 ZM10.001,12.000 C7.792,12.000 6.000,10.209 6.000,8.000 C6.000,5.790 7.792,3.999 10.001,3.999 C12.210,3.999 14.001,5.790 14.001,8.000 C14.001,10.209 12.210,12.000 10.001,12.000 ZM10.001,5.000 C8.344,5.000 6.999,6.343 6.999,8.000 C6.999,9.656 8.344,11.000 10.001,11.000 C11.657,11.000 13.000,9.656 13.000,8.000 C13.000,6.343 11.657,5.000 10.001,5.000 Z" />
          </svg>
          END USER QUALIFICATION
        </>
      }
    >
      <FieldArray
        name="qualification"
        render={arrayHelpers => {
          return (
            <>
              {qualification && (
                <Conditions>
                  {qualification.map((item, index) => {
                    return (
                      <Condition onDelete={() => arrayHelpers.remove(index)}>
                        <ConditionField>
                          <Field
                            name={`qualification.${index}.operator`}
                            component={SelectField}
                            placeholder="Select a condition"
                            items={additionalOperators}
                          />
                        </ConditionField>
                        {qualification[index].operator && (
                          <ConditionField>
                            <Field
                              name={`qualification.${index}.qualification`}
                              component={SelectField}
                              placeholder="Select value"
                              items={additionalQualification}
                            />
                          </ConditionField>
                        )}
                      </Condition>
                    );
                  })}
                </Conditions>
              )}
              {qualification.length < 3 && (
                <View display="inline-flex">
                  <AddConditionButton
                    title="Add condition"
                    onClick={() =>
                      arrayHelpers.push({ operator: "", qualification: "" })
                    }
                  />
                </View>
              )}
            </>
          );
        }}
      />
    </Rule>
  );
}

export default QualificationRuleContainer;
