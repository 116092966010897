import React, { useCallback, useEffect, useState } from "react";
import {
  PopupHeader,
  Popup,
  PopupBody,
  PopupFooter,
  Button,
  GutterField
} from "design-system-react";
import { useDispatch, useSelector } from "react-redux";
import { api } from "utils/client";
import { FormError } from "utils/formError";
import { Field, Formik } from "formik";
import InputField from "components/InputField";
import { addSalesCardId } from "modules/pages/settingsSalesCards";
import SelectField from "components/SelectField";

function AddFormContainer({ toggle, isOpen, close }) {
  const [setups, setSetups] = useState([]);

  const dispatch = useDispatch();
  const name = useSelector(
    state => state.entities.teamMembers[state.auth.userId].name
  );

  const handleSubmit = useCallback(
    (values, { setSubmitting, setErrors, resetForm }) => {
      api.salesCard
        .create(values)
        .ready.then(response => {
          dispatch(addSalesCardId(response.data.data.id));
          close();
          resetForm();
        })
        .catch(e => {
          if (e instanceof FormError) {
            setErrors(e.errors);
          }
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    // eslint-disable-next-line
    [addSalesCardId, close]
  );

  useEffect(() => {
    if (isOpen) {
      api.salesCardSetup.all().ready.then(response => {
        setSetups(response.data.data);
      });
    }
  }, [isOpen]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        salesCardSetup: "",
        name: name
      }}
      onSubmit={handleSubmit}
      render={props => (
        <Popup isOpen={isOpen} onClose={toggle}>
          <PopupHeader>Add business card</PopupHeader>
          <PopupBody>
            <GutterField>
              <GutterField>
                <Field
                  name="salesCardSetup"
                  label="Business card settings"
                  component={SelectField}
                  items={setups.map(setups => ({
                    label: setups.title,
                    value: setups.id
                  }))}
                  renderItem={item => <>{item.label}</>}
                />
              </GutterField>
            </GutterField>
            <Field
              name="name"
              type="text"
              label="Display name"
              component={InputField}
            />
          </PopupBody>
          <PopupFooter>
            <Button
              onClick={props.submitForm}
              processing={props.isSubmitting}
              disabled={setups.length === 0}
            >
              Add
            </Button>
            <Button outline={true} onClick={toggle}>
              Cancel
            </Button>
          </PopupFooter>
        </Popup>
      )}
    />
  );
}

export default AddFormContainer;
