import { withNamespace } from "utils/namespace";

const type = withNamespace("pages/settingsWhatsApp");

export const SET_WHATS_APP_CHANNELS = type("SET_WHATS_APP_CHANNELS");
export const ADD_WHATS_APP_CHANNEL = type("ADD_WHATS_APP_CHANNEL");

let initialState = {
  ids: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_WHATS_APP_CHANNELS:
      return {
        ...state,
        ids: action.payload.ids
      };
    case ADD_WHATS_APP_CHANNEL:
      return {
        ...state,
        ids: [...state.ids, action.payload.id]
      };
    default:
      return state;
  }
}

// actions

export function setWhatsAppChannels(ids) {
  return {
    type: SET_WHATS_APP_CHANNELS,
    payload: {
      ids
    }
  };
}

export function addWhatsAppChannel(id) {
  return {
    type: ADD_WHATS_APP_CHANNEL,
    payload: {
      id
    }
  };
}
