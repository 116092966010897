import React, { useContext } from "react";
import ProfileButton from "./ProfileButton";
import { ToggableLayoutContext } from "./ToggableLayout/ToggableLayoutContext";
import ProfileButtonWrapper from "./ProfileButtonWrapper/ProfileButtonWrapper";

function CardProfileButtonContainer() {
  const context = useContext(ToggableLayoutContext);

  return (
    <ProfileButtonWrapper active={context.isSubmenuOpened}>
      <ProfileButton profileButtonAction={context.toggleSubmenu} />
    </ProfileButtonWrapper>
  );
}

export default CardProfileButtonContainer;
