import { withNamespace } from "utils/namespace";

const type = withNamespace("endUserViews");

// action types
export const SET_END_USER_VIEW_IDS = type("SET_END_USER_VIEW_IDS");

let initialState = {
  ids: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_END_USER_VIEW_IDS:
      return {
        ...state,
        ids: action.payload.ids
      };
    default:
      return state;
  }
}

// actions
export function setEndUserViewIds(ids) {
  return {
    type: SET_END_USER_VIEW_IDS,
    payload: {
      ids
    }
  };
}
