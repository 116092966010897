import React, { useCallback, useEffect, useState } from "react";
import {
  PopupHeader,
  Popup,
  PopupBody,
  PopupFooter,
  Button,
  GutterField
} from "design-system-react";
import { api } from "utils/client";
import { FormError } from "utils/formError";
import { Field, Formik } from "formik";
import InputField from "components/InputField";
import TextareaField from "components/TextareaField";
import UploadInputField from "components/UploadInputField";
import View from "components/View";

function EditFormContainer({ toggle, isOpen, setupId, close }) {
  const [loading, setLoading] = useState(false);
  const [setup, setSetup] = useState(null);

  const handleSubmit = useCallback(
    (values, { setSubmitting, setErrors }) => {
      api.salesCardSetup
        .update(setupId, values)
        .ready.then(() => {
          close();
        })
        .catch(e => {
          if (e instanceof FormError) {
            setErrors(e.errors);
          }
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [setupId, close]
  );

  useEffect(() => {
    if (setupId) {
      setLoading(true);
      const ready = api.salesCardSetup.one(setupId).ready;

      ready
        .then(response => {
          setSetup(response.data.data);
        })
        .finally(() => {
          setLoading(false);
        });
    }

    return function() {
      setSetup(null);
      setLoading(false);
    };
  }, [setupId]);

  if (!setup || loading) {
    return null;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        title: setup.title,
        companyName: setup.companyName,
        logo: setup.logo && setup.logo.id,
        greetingTitle: setup.greetingTitle,
        greetingMessage: setup.greetingMessage,
        whatsappTemplateMessage: setup.whatsappTemplateMessage
      }}
      onSubmit={handleSubmit}
      render={props => (
        <Popup isOpen={isOpen} onClose={toggle}>
          <PopupHeader>Edit business card settings</PopupHeader>
          <PopupBody>
            <GutterField>
              <Field
                name="title"
                type="text"
                label="Settings title (e.g. My settings)"
                component={InputField}
              />
            </GutterField>
            <GutterField>
              <Field
                name="companyName"
                type="text"
                label="Company name"
                component={InputField}
              />
            </GutterField>
            <GutterField>
              <Field
                name="logo"
                label="Company logo"
                component={UploadInputField}
              />
            </GutterField>
            <GutterField>
              <Field
                name="greetingTitle"
                type="text"
                label="Greeting"
                component={InputField}
              />
            </GutterField>
            <Field
              name="greetingMessage"
              type="text"
              label="Message"
              component={TextareaField}
            />
            <View
              fontSize="12px"
              color="#aaa"
              paddingTop={6}
              paddingBottom={12}
            >
              Placeholders: {"{{TEAM_MEMBER_NAME}}"}
            </View>
            <Field
              name="whatsappTemplateMessage"
              type="text"
              label="WhatsApp Template Message"
              component={TextareaField}
            />
            <View fontSize="12px" color="#aaa" paddingTop={6}>
              Placeholders: {"{{TEAM_MEMBER_PIN}}"}, {"{{TEAM_MEMBER_NAME}}"}
            </View>
          </PopupBody>
          <PopupFooter>
            <Button
              onClick={props.submitForm}
              processing={props.isSubmitting}
              // disabled={submitDisabled}
            >
              Save
            </Button>
            <Button outline={true} onClick={toggle}>
              Cancel
            </Button>
          </PopupFooter>
        </Popup>
      )}
    />
  );
}

export default EditFormContainer;
