import React, { useContext } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./ToggableLayout.module.css";
import { ToggableLayoutContext } from "./ToggableLayoutContext";

function ToggableLayout({ renderSubmenu, renderContent }) {
  const context = useContext(ToggableLayoutContext);

  const container = {
    isSubmenuOpened: context.isSubmenuOpened,
    toggleSubmenu: context.toggleSubmenu
  };

  const classes = classNames(
    styles.layout,
    context.isSubmenuOpened && styles.active
  );

  return (
    <>
      {renderSubmenu(container)}

      <div className={classes}>{renderContent(container)}</div>
    </>
  );
}

ToggableLayout.propTypes = {
  renderSubmenu: PropTypes.func.isRequired,
  renderContent: PropTypes.func.isRequired
};

export default ToggableLayout;
