import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Popup,
  PopupBody,
  PopupHeader,
  PopupFooter,
  Button
} from "design-system-react";
import { Formik } from "formik";
import { api } from "../../utils/client";
import FormContainer from "./FormContainer";
import { FormError } from "../../utils/formError";

function EditTeamMemberModal({ isOpen, toggle, teamMember }) {
  const [teams, setTeams] = useState([]);
  const [roles, setRoles] = useState([]);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const form = useRef();

  useEffect(() => {
    if (isOpen) {
      api.teams.enabled().ready.then(response => {
        setTeams(
          response.data.data.map(team => ({
            name: team.title,
            color: team.color,
            value: team.id
          }))
        );
      });

      api.roles.all().ready.then(response => {
        setRoles(
          response.data.data
            .filter(role => role.workingRole)
            .map(role => ({
              label: role.title,
              value: role.id
            }))
        );
      });
    }
  }, [isOpen]);

  useEffect(() => {
    if (teamMember) {
      form.current.resetForm({
        role: teamMember.role,
        teams: teamMember.teams
      });
    }
  }, [teamMember]);

  const handleSubmit = useCallback(
    (values, { setSubmitting, setErrors }) => {
      api.teamMembers
        .update(teamMember.id, values)
        .ready.then(() => {
          toggle();
        })
        .catch(e => {
          if (e instanceof FormError) {
            setErrors(e.errors);
          }
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [teamMember, toggle]
  );

  if (!teamMember) {
    return null;
  }

  return (
    <Formik
      ref={form}
      enableReinitialize
      initialValues={{
        role: "",
        teams: []
      }}
      onSubmit={handleSubmit}
      render={props => (
        <Popup isOpen={isOpen} onClose={toggle}>
          <PopupHeader>Edit team member</PopupHeader>
          <PopupBody>
            <FormContainer
              {...props}
              submitDisabled={submitDisabled}
              setSubmitDisabled={setSubmitDisabled}
              teams={teams}
              roles={roles}
            />
          </PopupBody>
          <PopupFooter>
            <Button
              onClick={props.submitForm}
              processing={props.isSubmitting}
              disabled={submitDisabled}
            >
              Save
            </Button>
            <Button outline={true} onClick={toggle}>
              Cancel
            </Button>
          </PopupFooter>
        </Popup>
      )}
    />
  );
}

export default EditTeamMemberModal;
