import React, { useEffect } from "react";
import SettingsSubMenu from "components/settings/SettingsSubMenu";
import SettingsTelegramInterfaceContainer from "pages/SettingsTelegramInterface/containers/SettingsTelegramInterfaceContainer";
import SettingsLayout from "components/SettingsLayout";

function SettingsTelegramInterface({ location }) {
  useEffect(() => {
    document.title = "Telegram integration";
    localStorage.setItem("lastSettingRoute", "/settings/telegram-interface");
  }, []);

  return (
    <SettingsLayout
      renderSubmenu={() => (
        <SettingsSubMenu activeMenuItem={"/settings/telegram-interface"} />
      )}
      renderContent={() => (
        <SettingsTelegramInterfaceContainer />
      )}
    />
  );
}

export default SettingsTelegramInterface;
