import React, { useState, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import AnalyticsNavPanelContainer from "./AnalyticsNavPanelContainer";
import AnalyticsCardsContainer from "pages/AnalyticsTeamMembers/containers/AnalyticsCardsContainer";
import { Row, Col, Scrollbar } from "design-system-react";
import { api } from "../../../utils/client";
import history from "utils/history";
import { makeAnalyticUrl } from "../../../helpers/analytics";
import { setTeamMemberIds } from "../../../modules/pages/analyticsTeamMembers";
import { createSelector } from "reselect";

function AnalyticsContainer({
  onSubMenuToggle,
  view,
  isSubmenuOpened,
  onPeriodChange,
  urlParams,
  setSelectedPeriod,
  setPeriodFrom,
  setPeriodTo,
  selectedPeriod,
  teamMembers,
  setTeamMemberIds
}) {
  const [loadingData, setLoadingData] = useState(false);
  const [loadingChannelData, setLoadingChannelData] = useState(false);
  const [data, setData] = useState(null);
  const [channelData, setChannelData] = useState(null);
  const handlePeriodChange = useCallback((from, to) => {}, []);

  // dropdown
  const [selectedValueDropdown, setSelectedValueDropdown] = useState(null);

  const [isOpen, setIsOpen] = useState(false);

  const toggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleChangeDropdown = useCallback(value => {
    setSelectedValueDropdown(value);
    setIsOpen(false);
  }, []);

  const selectedItemDropdown = teamMembers.filter(
    i => i.id === selectedValueDropdown
  )[0];
  // dropdown end

  // team members
  useEffect(() => {
    setTeamMemberIds([]);
    api.teamMembers
      .allForAnalyticsFilter(
        urlParams.period,
        urlParams.periodFrom,
        urlParams.periodTo
      )

      .ready.then(response => {
        setTeamMemberIds(response.data.data.map(member => member.id));
      })

      .finally(() => {
        setLoadingData(false);
      });
  }, [
    urlParams.period,
    urlParams.periodFrom,
    urlParams.periodTo,
    setTeamMemberIds
  ]);

  const handleChangePeriod = useCallback(
    groupButton => {
      setSelectedPeriod(groupButton.value);
      setPeriodFrom("");
      setPeriodTo("");

      history.push(
        makeAnalyticUrl("/analytics/team-members", {
          ...urlParams,
          period: groupButton.value,
          periodFrom: "",
          periodTo: ""
        })
      );
    },
    [urlParams, setPeriodFrom, setPeriodTo, setSelectedPeriod]
  );
  // team members end

  useEffect(() => {
    setLoadingData(true);
    api.teamMembers
      .analytics(
        urlParams.period,
        urlParams.periodFrom,
        urlParams.periodTo,
        urlParams.channel
      )

      .ready.then(response => {
        setData(response.data.data);
      })

      .finally(() => {
        setLoadingData(false);
      });
  }, [
    urlParams.channel,
    urlParams.period,
    urlParams.periodFrom,
    urlParams.periodTo
  ]);

  useEffect(() => {
    setLoadingChannelData(true);
    api.teamMembers
      .analyticsData(
        urlParams.teamMember,
        urlParams.period,
        urlParams.periodFrom,
        urlParams.periodTo
      )

      .ready.then(response => {
        setChannelData(response.data.data);
      })
      .finally(() => {
        setLoadingChannelData(false);
      });
  }, [
    urlParams.teamMember,
    urlParams.period,
    urlParams.periodFrom,
    urlParams.periodTo
  ]);

  return (
    <Row fullHeight column>
      <Col auto>
        <AnalyticsNavPanelContainer
          onSubMenuToggle={onSubMenuToggle}
          isSubmenuOpened={isSubmenuOpened}
          activeView={view}
          onPeriodChange={handlePeriodChange}
          loading={loadingData}
          urlParams={urlParams}
          selectedItemDropdown={selectedItemDropdown}
          isOpen={isOpen}
          toggle={toggle}
          handleChangeDropdown={handleChangeDropdown}
          teamMembers={teamMembers}
          handleChangePeriod={handleChangePeriod}
        />
      </Col>
      <Col justified>
        <Scrollbar>
          <Row fullHeight column nowrap>
            <AnalyticsCardsContainer
              loadingData={loadingData}
              loadingChannelData={loadingChannelData}
              data={data}
              channelData={channelData}
              urlParams={urlParams}
              teamMembers={teamMembers}
            />
          </Row>
        </Scrollbar>
      </Col>
    </Row>
  );
}

const getTeamMembers = createSelector(
  [
    state => state.entities.teamMembers,
    state => state.pages.analyticsTeamMembers.teamMemberIds
  ],
  (teamMembers, teamMemberIds) => {
    return teamMemberIds.map(id => teamMembers[id]);
  }
);

const mapStateToProps = state => {
  return {
    teamMembers: getTeamMembers(state)
  };
};

const mapDispatchToProps = dispatch => ({
  setTeamMemberIds: ids => dispatch(setTeamMemberIds(ids))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnalyticsContainer);
