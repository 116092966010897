import React from "react";
import styles from "./index.module.css";
import classNames from "classnames";

function NavPanelIconButton({ onClick, left, children }) {
  return (
    <span
      className={classNames(styles.button, left && styles.left)}
      onClick={onClick}
    >
      {children}
    </span>
  );
}

export default NavPanelIconButton;
