import React, {useCallback, useEffect, useState} from "react";
import {
  PopupHeader,
  Popup,
  PopupBody,
  PopupFooter,
  Button, GutterField
} from "design-system-react";
import {api} from "utils/client";
import {FormError} from "utils/formError";
import {Field, Formik} from "formik";
import InputField from "components/InputField";
import SelectField from "components/SelectField";

function EditFormContainer({toggle, isOpen, close, cardId}) {
  const [setups, setSetups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [card, setCard] = useState(null);

  const handleSubmit = useCallback(
    (values, {setSubmitting, setErrors}) => {
      api.salesCard
        .update(cardId, values)
        .ready
        .then(() => {
          close();
        })
        .catch(e => {
          if (e instanceof FormError) {
            setErrors(e.errors);
          }
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [cardId, close]
  );

  useEffect(()=>{
    if (cardId) {
      setLoading(true);
      const ready = api.salesCard.one(cardId).ready;

      ready.then(response => {
        setCard(response.data.data);
      }).finally(()=>{
        setLoading(false);
      })
    }

    return function () {
      setCard(null);
      setLoading(false);
    }
  }, [cardId]);

  useEffect(() => {
    if (isOpen) {
      api.salesCardSetup.all().ready.then(response => {
        setSetups(response.data.data);
      });
    }
  }, [isOpen]);

  if (!card || loading) {
    return  null;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        salesCardSetup: card.salesCardSetup && card.salesCardSetup.id,
        name: card.name,
      }}
      onSubmit={handleSubmit}
      render={props => (
        <Popup isOpen={isOpen} onClose={toggle}>
          <PopupHeader>Edit business card</PopupHeader>
          <PopupBody>
            <GutterField>
              <GutterField>
                <Field
                  name="salesCardSetup"
                  label="Business card settings"
                  component={SelectField}
                  items={setups.map(setups => ({
                    label: setups.title,
                    value: setups.id
                  }))}
                  renderItem={item => <>{item.label}</>}
                />
              </GutterField>
            </GutterField>
            <Field
              name="name"
              type="text"
              label="Display name"
              component={InputField}
            />
          </PopupBody>
          <PopupFooter>
            <Button
              onClick={props.submitForm}
              processing={props.isSubmitting}
              disabled={setups.length === 0}
            >
              Edit
            </Button>
            <Button outline={true} onClick={toggle}>
              Cancel
            </Button>
          </PopupFooter>
        </Popup>
      )}
    />
  );
}

export default EditFormContainer;
