import React, {useCallback, useState} from "react";
import SettingsChannelHeader from "components/SettingsHeader";
import { Row, Col, Container } from "design-system-react";
import TableContainer from "pages/SettingsSalesCardSetups/containers/TableContainer";
import AddFormContainer from "pages/SettingsSalesCardSetups/containers/AddFormContainer";

function ContentContainer() {
  const [addModalOpened, setAddModalOpened] = useState(false);

  const toggleAddModal = useCallback(() => {
    setAddModalOpened(!addModalOpened);
  }, [addModalOpened]);

  const closeAddModal = useCallback(() => {
    setAddModalOpened(false);
  }, []);

  return (
    <Row fullHeight column>
      <Container>
        <SettingsChannelHeader
          title="Business cards settings"
          onAction={toggleAddModal}
        />
      </Container>
      <Col justified>
        <TableContainer />
      </Col>
      <AddFormContainer
        isOpen={addModalOpened}
        toggle={toggleAddModal}
        close={closeAddModal}
      />
    </Row>
  );
}

export default ContentContainer;
