import React, { useState, useCallback } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownToggleColor,
  DropdownMenuColor,
  DropdownToggleDefault
} from "design-system-react";

function SelectColorField({ field, form, label, placeholder, size, disabled }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleChange = useCallback(
    value => {
      setIsOpen(false);
      form.setFieldValue(field.name, value);
    },
    [field.name, form]
  );

  const error = form.errors[field.name];

  return (
    <Dropdown
      isOpen={isOpen}
      toggle={toggle}
      validation={error && "error"}
      validationMessage={error}
      nonEmpty={field.value}
      size={size}
      disabled={disabled}
    >
      <DropdownToggle>
        <DropdownToggleDefault label={label} placeholder={placeholder}>
          {field.value && <DropdownToggleColor color={field.value} />}
        </DropdownToggleDefault>
      </DropdownToggle>
      <DropdownMenuColor selectedColor={field.value} onChange={handleChange} />
    </Dropdown>
  );
}

export default SelectColorField;
