import React from "react";
import {Input, GutterField} from "design-system-react";
import Collapse from "components/Collapse";
import { TYPE_FULL } from "../../../utils/dateTime";
import EndUserInputTime from "pages/EndUser/components/EndUserInputTime";

function TeamDetailsContainer({ updatedAt, deletedAt, createdAt, isPrivate, enabled }) {
  return (
    <div>
      <Collapse header="Team details">
        <GutterField>
          <Input
            label="Type"
            value={
              isPrivate ? "Private" : "Public"
            }
            disabled
            size="sm"
          />
        </GutterField><GutterField>
        <Input
          label="Created"
          appendAfter={
            <EndUserInputTime value={createdAt} forceType={TYPE_FULL} />
          }
          disabled
          size="sm"
        />
      </GutterField>
        <GutterField>
          <Input
            label="Updated"
            appendAfter={
              <EndUserInputTime value={updatedAt} forceType={TYPE_FULL} />
            }
            disabled
            size="sm"
          />
        </GutterField>
        {deletedAt && (
          <GutterField>
            <Input
              label="Deleted"
              appendAfter={
                <EndUserInputTime value={deletedAt} forceType={TYPE_FULL} />
              }
              disabled
              size="sm"
            />
          </GutterField>
        )}
      </Collapse>
    </div>
  );
}

export default TeamDetailsContainer;
