import React, { useState, useCallback, useEffect } from "react";
import { PopupHeader, Popup, PopupBody } from "design-system-react";
import connect from "react-redux/es/connect/connect";
import { addWhatsAppChannel } from "modules/pages/settingsWhatsApp";
import WhatsAppModalBody from "../components/WhatsAppModalBody";
import {
  subscribe,
  unsubscribe,
  WS_EVENT_CHANNEL_RESTORED,
  WS_EVENT_CHANNEL_VALIDATION_ERROR,
  WS_EVENT_CHANNEL_CREATED
} from "utils/websocket";
import { MODAL_ERROR, showConfirmModal } from "utils/modalManager";
import useInterval from "hooks/useInterval";
import { api, dispatchUpdatedDataToStore } from "utils/client";
import { channel } from "schemes";
import usePrevious from "../../../hooks/usePrevious";

const QR_TIME = 20;

function SettingsWhatsAppModalAddContainer({
  toggle,
  isOpen,
  addModalParams,
  addWhatsAppChannel
}) {
  const [qrCode, setQrCode] = useState(null);
  const [counter, setCounter] = useState(QR_TIME);
  const [isLoading, setLoading] = useState(false);

  const prevIsOpen = usePrevious(isOpen);

  const handleAddChannel = useCallback(params => {
    setLoading(true);
    if (params.type === "add") {
      api.whatsApp.generateQR().ready.then(response => {
        startInterval(response.data);
      });
    } else {
      api.whatsApp.restore(params.id).ready.then(response => {
        startInterval(response.data);
      });
    }

    function startInterval(imageQR) {
      const interval = setInterval(() => {
        let img = new Image();
        img.src = imageQR;
        img.onload = function() {
          setQrCode(imageQR);
          setLoading(false);
          setCounter(QR_TIME);
          clearInterval(interval);
        };
      }, 300);
    }
  }, []);

  useEffect(() => {
    if (isOpen && !prevIsOpen) {
      handleAddChannel(addModalParams);
    }
  });

  useInterval(
    () => {
      setCounter(counter - 1);
    },
    qrCode && counter ? 1000 : null
  );

  const handleWsError = useCallback(
    data => {
      setCounter(QR_TIME);
      setQrCode(null);
      showConfirmModal(
        MODAL_ERROR,
        data.data.info[0].title || "Something bad happened",
        {
          cancelButtonText: "Okay"
        }
      );
      toggle();
    },
    [toggle]
  );

  const handleWsWhatsAppCreated = useCallback(
    data => {
      setCounter(QR_TIME);
      setQrCode(null);
      dispatchUpdatedDataToStore(data, channel);
      addWhatsAppChannel(data.id);
      toggle();
    },
    [addWhatsAppChannel, toggle]
  );

  const handleWsWhatsAppRestored = useCallback(
    data => {
      setCounter(QR_TIME);
      setQrCode(null);
      dispatchUpdatedDataToStore(data, channel);
      toggle();
    },
    [toggle]
  );

  useEffect(() => {
    subscribe(WS_EVENT_CHANNEL_VALIDATION_ERROR, handleWsError);

    return () => {
      unsubscribe(WS_EVENT_CHANNEL_VALIDATION_ERROR, handleWsError);
    };
  }, [handleWsError]);

  useEffect(() => {
    subscribe(WS_EVENT_CHANNEL_CREATED, handleWsWhatsAppCreated);

    return () => {
      unsubscribe(WS_EVENT_CHANNEL_CREATED, handleWsWhatsAppCreated);
    };
  }, [handleWsWhatsAppCreated]);

  useEffect(() => {
    subscribe(WS_EVENT_CHANNEL_RESTORED, handleWsWhatsAppRestored);

    return () => {
      unsubscribe(WS_EVENT_CHANNEL_RESTORED, handleWsWhatsAppRestored);
    };
  }, [handleWsWhatsAppRestored]);

  return (
    <Popup isOpen={isOpen} onClose={toggle}>
      <PopupHeader>
        {addModalParams.type === "add" ? "Add WhatsApp" : "Restore WhatsApp"}
      </PopupHeader>
      <PopupBody>
        <WhatsAppModalBody
          isLoading={isLoading}
          counter={counter}
          retry={() => handleAddChannel(addModalParams)}
          qrCode={qrCode}
        />
      </PopupBody>
    </Popup>
  );
}

const mapDispatchToProps = dispatch => ({
  addWhatsAppChannel: id => dispatch(addWhatsAppChannel(id))
});

export default connect(
  null,
  mapDispatchToProps
)(SettingsWhatsAppModalAddContainer);
