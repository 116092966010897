import React, { useEffect, useCallback } from "react";
import SettingsSubMenu from "components/settings/SettingsSubMenu";
import SettingsNotificationsContainer from "./containers/SettingsNotificationsContainer";
import SettingsLayout from "components/SettingsLayout";

function SettingsNotifications() {
  useEffect(() => {
    document.title = "Notifications";
    localStorage.setItem("lastSettingRoute", "/settings/notifications");
  });

  const renderSubmenu = useCallback(
    () => <SettingsSubMenu activeMenuItem={"/settings/notifications"} />,
    []
  );

  const renderContent = useCallback(
    () => <SettingsNotificationsContainer />,
    []
  );

  return (
    <SettingsLayout
      renderSubmenu={renderSubmenu}
      renderContent={renderContent}
    />
  );
}

export default SettingsNotifications;
