import React, { useEffect, useCallback, useMemo } from "react";
import { FastField } from "formik/dist/index";
import ContactCheckbox from "../components/ContactCheckbox";
import SubtitleForm from "../components/SubtitleForm";
import { GutterField } from "design-system-react";
import { connect } from "react-redux";
import useFormSubmission from "hooks/useFormSubmission";
import { setSetup, setQualify } from "modules/widgetSetup";
import RadioButtonField from "components/RadioButtonField";
import RadiobuttonFieldGroup from "components/RadiobuttonFieldGroup";
import WidgetQualifySelectContainer from "./WidgetQualifySelectContainer";
import QualifySelectItem from "pages/WidgetQualify/components/QualifySelectItem";
import View from "components/View";
import InputField from "components/InputField";

const detailsArray = [
  { label: "Full name", value: "name", image: "/images/widget/user-icon.svg" },
  { label: "Email", value: "email", image: "/images/widget/email-icon.svg" },
  {
    label: "Phone number",
    value: "phone",
    image: "/images/widget/phone-icon.svg"
  }
];

function WidgetQualifyFormContainer({
  values,
  dirty,
  errors,
  submitDisabled,
  setSubmitDisabled,
  handleSubmit,
  setValues,
  setQualify
}) {
  const handleSubmitForm = useFormSubmission(submitDisabled, handleSubmit);

  const additionalDetails = useMemo(
    () =>
      detailsArray.map(item => {
        if (values.details[item.value]) {
          item.hide = true;
        } else {
          item.hide = false;
        }

        return item;
      }),
    [values.details]
  );

  useEffect(() => {
    setQualify(values);
  }, [setQualify, values]);

  if (!dirty && !submitDisabled) {
    setSubmitDisabled(true);
  }

  if (dirty && submitDisabled) {
    setSubmitDisabled(false);
  }

  const handleChangeSelect = useCallback(
    value => {
      setValues({
        autoMessage: values.autoMessage,
        rule: values.rule,
        details: { ...values.details, [value]: true }
      });
    },
    [values, setValues]
  );

  const handleDeleteDetail = useCallback(
    value => {
      setValues({
        autoMessage: values.autoMessage,
        rule: values.rule,
        details: { ...values.details, [value]: false }
      });
    },
    [values, setValues]
  );

  return (
    <form onSubmit={handleSubmitForm}>
      <GutterField>
        <SubtitleForm>Automation rule</SubtitleForm>
        <RadiobuttonFieldGroup description="Choose when to ask your customers about contact information">
          <FastField
            name="rule"
            label="Ask lead data after first customer message"
            id="firstMessage"
            component={RadioButtonField}
          />
          <FastField
            name="rule"
            label="Ask lead data at once"
            id="atOnce"
            component={RadioButtonField}
          />
          <FastField
            name="rule"
            label="Never ask lead data"
            id="neverAsk"
            component={RadioButtonField}
          />
        </RadiobuttonFieldGroup>
      </GutterField>
      {values.rule !== "neverAsk" && (
        <>
          <SubtitleForm>Add qualifying details</SubtitleForm>
          {detailsArray.map(i => {
            if (values.details[i.value] === true) {
              return (
                <ContactCheckbox
                  name={i.value}
                  label={i.label}
                  key={i.value}
                  handleDelete={handleDeleteDetail}
                />
              );
            }
            return null;
          })}
          <GutterField>
            <WidgetQualifySelectContainer
              placeholder="Choose qualification field"
              items={additionalDetails}
              renderItem={item => (
                <QualifySelectItem image={item.image} label={item.label} />
              )}
              error={errors.details}
              handleChange={handleChangeSelect}
            />
          </GutterField>
          <SubtitleForm>Personal data processing</SubtitleForm>
          <View color="#646566" marginBottom={20}>
            Specify the title and URL of your personal data processing document
          </View>
          <GutterField>
            <FastField
              name="privacyPolicy.title"
              type="text"
              label="Title"
              component={InputField}
            />
          </GutterField>
          <GutterField>
            <FastField
              name="privacyPolicy.url"
              type="text"
              label="URL"
              component={InputField}
            />
          </GutterField>
        </>
      )}
    </form>
  );
}

const mapStateToProps = state => {
  return {
    setup: state.widgetSetup.current
  };
};

const mapDispatchToProps = dispatch => ({
  setSetup: setup => dispatch(setSetup(setup)),
  setQualify: welcome => dispatch(setQualify(welcome))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WidgetQualifyFormContainer);
