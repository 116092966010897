import React, { useMemo, useCallback, useEffect } from "react";
import { Formik } from "formik";
import { Button } from "design-system-react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { setAvailability, setSetup } from "modules/widgetSetup";
import useStepPage from "hooks/useStepPage";
import { STEP_CHANNELS } from "helpers/steps";
import { api } from "utils/widgetClient";
import { ALERT_SUCCESS, showAlert } from "utils/alertManager";
import { FormError } from "utils/formError";
import WidgetAvailabilityFormContainer from "./WidgetAvailabilityFormContainer";
import WidgetFooter from "components/Widget/WidgetFooter";
import WidgetFormLayout from "components/Widget/WidgetFormLayout";

const defaultSchedule = {
  monday: { end: "", start: "", enabled: false },
  tuesday: { end: "", start: "", enabled: false },
  wednesday: { end: "", start: "", enabled: false },
  thursday: { end: "", start: "", enabled: false },
  friday: { end: "", start: "", enabled: false },
  saturday: { end: "", start: "", enabled: false },
  sunday: { end: "", start: "", enabled: false }
};

let date = new Date();
let timezone = date.getTimezoneOffset() * -60;

let noMutableInitial = {};

function WidgetAvailabilityContainer({ setup, setSetup, setAvailability }) {
  const page = useStepPage();

  const initialValues = useMemo(() => {
    return {
      schedule: setup.availability.schedule || defaultSchedule,
      showOnlineStatus:
        // set it to true by default if "showOnlineStatus" equal undefined
        // and if showOnlineStatus available set true
        String(setup.availability.showOnlineStatus) === "false" ? false : true,
      hideOffline: setup.availability.hideOffline || false,
      timezone: setup.availability.timezone || { utc: timezone }
    };
  }, [setup.availability]);

  useEffect(() => {
    document.title = "Availability";
    noMutableInitial = initialValues;

    return () => {
      // reset form
      setAvailability(noMutableInitial);
    };
    // eslint-disable-next-line
  }, [setAvailability]);

  const handleSubmitForm = useCallback(
    (values, { setErrors, resetForm }) => {
      if (!page.loading) {
        page.setLoading(true);

        api.widget
          .updateAvailability(setup.id, values)
          .ready.then(response => {
            setSetup(response.data.data);
            noMutableInitial = response.data.data.availability;
            if (response.data.data.step === STEP_CHANNELS) {
              page.setRedirectToNextStep(true);
            } else {
              showAlert(
                ALERT_SUCCESS,
                "Availability settings saved successfully"
              );
            }
            page.setInitialSaved(true);
            page.setSubmitDisabled(true);
            resetForm(values);
          })
          .catch(e => {
            if (e instanceof FormError) {
              setErrors(e.errors);
            }
          })
          .finally(() => {
            page.setLoading(false);
          });
      }
    },
    [page, setSetup, setup.id]
  );

  if (page.redirectToBackStep) {
    return <Redirect to={"/widget/welcome"} />;
  }

  if (page.redirectToNextStep) {
    return <Redirect to={"/widget/channels"} />;
  }

  return (
    <WidgetFormLayout>
      <Formik
        ref={page.form}
        onSubmit={handleSubmitForm}
        initialValues={initialValues}
        render={props => (
          <WidgetAvailabilityFormContainer
            {...props}
            submitDisabled={page.submitDisabled}
            setSubmitDisabled={page.setSubmitDisabled}
          />
        )}
      />
      <WidgetFooter>
        <Button
          processing={page.loading}
          disabled={page.submitDisabled}
          onClick={page.handleNextClick}
        >
          Save
        </Button>
      </WidgetFooter>
    </WidgetFormLayout>
  );
}

const mapStateToProps = state => {
  return {
    setup: state.widgetSetup.current
  };
};

const mapDispatchToProps = dispatch => ({
  setSetup: setup => dispatch(setSetup(setup)),
  setAvailability: values => dispatch(setAvailability(values))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WidgetAvailabilityContainer);
