import React from "react";
import PropTypes from "prop-types";
import NavPanel from "components/NavPanel";
import NavPanelContent from "components/NavPanel/NavPanelContent";
import NavPanelActionItem from "components/NavPanel/NavPanelActionItem";
import CollapseButton from "components/NavPanel/CollapseButton";
import ActionDropdown from "components/ActionDropdown";

function TeamsNavPanel({
  onSubMenuToggle,
  isSubmenuOpened,
  team,
  handleActionDropdown,
  teamActionItems
}) {
  const actionItems = teamActionItems(team.deletedAt);

  return (
    <NavPanel>
      <NavPanelContent>
        <CollapseButton
          handleClick={onSubMenuToggle}
          isOpen={isSubmenuOpened}
        />
      </NavPanelContent>
      {actionItems.length > 0 && (
        <NavPanelActionItem>
          <ActionDropdown
            items={actionItems}
            direction="left"
            gutterTop={5}
            gutterRight={-15}
            onItemClick={key => {
              handleActionDropdown(team, key);
            }}
          />
        </NavPanelActionItem>
      )}
    </NavPanel>
  );
}

TeamsNavPanel.propTypes = {
  onSubMenuToggle: PropTypes.func.isRequired,
  isSubmenuOpened: PropTypes.bool
};

TeamsNavPanel.defaultTypes = {
  TeamsNavPanel: false
};

export default TeamsNavPanel;
