import React, { useCallback, useState } from "react";
import InputUpload from "../components/InputUpload";

function InputUploadContainer({ errors, tooltip, ...props }) {
  const [name, setName] = useState(null);

  const handleAvatarChange = useCallback(
    e => {
      const img = e.target.files[0];
      const reader = new FileReader();
      reader.onloadend = function() {
        setName(img.name);
        props.form.setFieldValue("LoadAvatar", reader.result);
        props.form.setFieldValue(props.field.name, img);
      };
      if (img) {
        reader.readAsDataURL(img);
      } else {
        props.form.setFieldValue(props.field.name, "");
      }
    },
    [props.field.name, props.form]
  );

  const clearAvatar = useCallback(() => {
    setName(null);
    props.form.setFieldValue(props.field.name, "");
    props.form.setFieldValue("LoadAvatar", "");
  }, [props.field.name, props.form]);

  return (
    <InputUpload
      handleAvatarChange={handleAvatarChange}
      value={name}
      placeholder={name ? name : props.placeholder}
      type={props.type}
      label={props.label}
      tooltip={tooltip}
      clearAvatar={clearAvatar}
      errors={props.form.errors[props.field.name]}
    />
  );
}

export default InputUploadContainer;
