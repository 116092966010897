import React, { useCallback } from "react";
import { Switcher } from "design-system-react";

function SwitcherField({ form, field, onValueChange, ...props }) {
  // async/await because setFieldValue does not have promise/callback
  const handleChange = useCallback(async () => {
    await form.setFieldValue(field.name, !field.value);
    onValueChange && onValueChange(field.value);
  }, [field.value, field.name, onValueChange, form]);

  return (
    <Switcher isChecked={field.value} onChange={handleChange} {...props} />
  );
}

export default SwitcherField;
