import React, { useEffect } from "react";
import SettingsSubMenu from "components/settings/SettingsSubMenu";
import SettingsLayout from "components/SettingsLayout";
import SettingsBlingerMessengerContainer from "./containers/SettingsBlingerMessengerContainer";

function SettingsBlingerMessenger() {
  useEffect(() => {
    document.title = "Blinger Messenger settings";
    localStorage.setItem("lastSettingRoute", "/settings/blinger-messenger");
  }, []);

  return (
    <SettingsLayout
      renderSubmenu={() => (
        <SettingsSubMenu activeMenuItem={"/settings/blinger-messenger"} />
      )}
      renderContent={() => <SettingsBlingerMessengerContainer />}
    />
  );
}

export default SettingsBlingerMessenger;
