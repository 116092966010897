import axios from "axios";
import normalizer from "json-api-normalizer";
import { normalize } from "normalizr";
import store from "store";
import { updateNormalizedData } from "modules/data";
import { FormError } from "./formError";
import { ALERT_ERROR, showAlert } from "./alertManager";
import { logout } from "../modules/auth";
import { CommonError } from "./commonError";
import { addEntities } from "../modules/entities";
import {
  caseItem,
  team,
  channel,
  teamMember,
  message,
  endUser,
  extraField,
  role,
  view,
  file,
  notification,
  quickReply,
  autoResponse,
  integration,
  salesCard
} from "../schemes";
import { isMatch, merge } from "lodash";
import history from "utils/history";
import { salesCardSetup } from "schemes";
import {
  getEnv,
  REACT_APP_API_URL,
  REACT_APP_WIDGET_APP_URL,
  REACT_APP_WIDGET_DEV_HOST
} from "./env";

const UNAUTHORIZED_ERROR_CODE = 401;

const FIELD_ERROR_CODE = 10001;
// const COMMON_ERROR_CODE = 10101;
const DELETED_TEAM_MEMBER = 20026;
const EXPIRED_TEAM_MEMBER_LINK = 20020;
const INVALID_TEAM_MEMBER_LINK = 20023;

export const apiUrl = () => {
  return (
    (process.env.NODE_ENV !== "production"
      ? getEnv(REACT_APP_API_URL)
      : window.location.origin) + "/api/secure/v1"
  );
};

const widgetHost =
  process.env.NODE_ENV !== "production"
    ? getEnv(REACT_APP_WIDGET_DEV_HOST)
    : getEnv(REACT_APP_WIDGET_APP_URL);
export const widgetUrl = `${widgetHost}/api/secure/v1`;

const client = axios.create({
  baseURL: apiUrl()
});

export function setToken(token) {
  if (!token) {
    return;
  }

  client.defaults.headers.common["Authorization"] = token
    ? "Bearer " + token
    : null;
}

export const request = (
  endpoint,
  options = {},
  withoutAlert = false,
  normalize = true
) => {
  const CancelToken = axios.CancelToken;
  const cancelRequest = CancelToken.source();

  options.cancelToken = cancelRequest.token;

  const ready = client(endpoint, options)
    .then(response => {
      if (normalize) {
        const normalizedData = normalizer(response.data);
        store.dispatch(updateNormalizedData(normalizedData));
      }

      return response;
    })
    .catch(error => {
      if (!error.response || !error.response.data.data) {
        throw error;
      }

      const errorCode = error.response.data.data.code;

      if (error.response) {
        if (error.response.status === UNAUTHORIZED_ERROR_CODE) {
          store.dispatch(logout());
        }

        if (errorCode === DELETED_TEAM_MEMBER) {
          withoutAlert = true;

          store.dispatch(logout());
          history.push({
            pathname: "/account-not-exist"
          });
        }

        if (errorCode === EXPIRED_TEAM_MEMBER_LINK) {
          withoutAlert = true;
          store.dispatch(logout());
          history.push({
            pathname: "/invite-expired"
          });
        }

        if (errorCode === INVALID_TEAM_MEMBER_LINK) {
          withoutAlert = true;
          store.dispatch(logout());
          history.push({
            pathname: "/invalid-token"
          });
        }

        if (error.response.data.data) {
          if (errorCode === FIELD_ERROR_CODE) {
            const fieldErrors = error.response.data.data.info;

            if (fieldErrors) {
              let preparedErrors = {};

              fieldErrors.map(e => {
                preparedErrors[e.source.pointer.replace(/\//g, ".")] = e.title;
                return e;
              });

              throw new FormError(preparedErrors);
            }
          }

          if (errorCode) {
            const errorMessage =
              error.response.data.data.message || "Server error";

            if (!withoutAlert) {
              showAlert(ALERT_ERROR, errorMessage);
            }

            throw new CommonError(errorMessage, error.response.status);
          }
        }
      }

      if (error.message === "Network Error") {
        showAlert(ALERT_ERROR, "Check your network connection status");
      }

      throw error;
    });

  return {
    ready,
    cancelRequest
  };
};

let token = localStorage.getItem("token") || sessionStorage.getItem("token");

if (token) {
  setToken(token);
}

export function dispatchUpdatedDataToStore(data, scheme) {
  const state = store.getState();
  const normalizedData = normalize(data, scheme);

  let updatedData = {};

  Object.keys(normalizedData.entities).map(type => {
    if (!state.entities[type]) {
      updatedData[type] = normalizedData.entities[type];
      return type;
    }

    Object.keys(normalizedData.entities[type]).map(id => {
      let entityUpdated = false;

      if (!state.entities[type][id]) {
        entityUpdated = true;
      } else {
        entityUpdated = !isMatch(
          state.entities[type][id],
          normalizedData.entities[type][id]
        );
      }

      if (entityUpdated) {
        if (!updatedData[type]) {
          updatedData[type] = {};
        }

        updatedData[type][id] = merge(
          state.entities[type][id],
          normalizedData.entities[type][id]
        );
      }

      return id;
    });

    return type;
  });

  if (Object.keys(updatedData).length > 0) {
    store.dispatch(addEntities(updatedData));
  }
}

function normalizeResponse(request, scheme) {
  request.ready.then(response => {
    dispatchUpdatedDataToStore(response.data.data, scheme);
  });

  return request;
}

export const api = {
  cases: {
    one: id => {
      return normalizeResponse(request("/cases/" + id), caseItem);
    },
    query: params => {
      return normalizeResponse(
        request("/cases/query", { method: "post", data: params }),
        [caseItem]
      );
    },
    messages: params => {
      return normalizeResponse(
        request("/cases/" + params.caseId + "/messages", {
          params
        }),
        [message]
      );
    },
    membersForAssign: filter => {
      return normalizeResponse(
        request("/cases/members-for-assign?name=" + filter),
        [teamMember]
      );
    },
    membersForAssignByTeam: (teamId, filter) => {
      let url = "/cases/members-for-assign?team=" + teamId;

      if (filter) {
        url += "&name=" + filter;
      }

      return request(url);
    },
    teamsForAssign: () => {
      return normalizeResponse(request("/cases/teams-for-assign"), [team]);
    },
    assign: (uuid, caseId, teamId, teamMemberId) => {
      return normalizeResponse(
        request("/cases/" + caseId + "/assign", {
          method: "post",
          data: {
            uuid,
            team: teamId,
            teamMember: teamMemberId
          }
        }),
        message
      );
    },
    bulkAssign: (cases, teamId, teamMemberId) => {
      return request("/cases/bulk-assign", {
        method: "post",
        data: {
          cases,
          team: teamId,
          teamMember: teamMemberId
        }
      });
    },
    unassign: (uuid, caseId) => {
      return normalizeResponse(
        request("/cases/" + caseId + "/unassign", {
          method: "post",
          data: {
            uuid
          }
        }),
        message
      );
    },
    bulkUnassign: cases => {
      return request("/cases/bulk-unassign", {
        method: "post",
        data: {
          cases
        }
      });
    },
    close: (uuid, caseId) => {
      return normalizeResponse(
        request("/cases/" + caseId + "/close", {
          method: "post",
          data: {
            uuid
          }
        }),
        message
      );
    },
    bulkClose: cases => {
      return request("/cases/bulk-close", {
        method: "post",
        data: {
          cases
        }
      });
    },
    analytics: (period, channel, dateFrom, dateTo, timezone) => {
      if (!timezone && Intl) {
        timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      }

      return request(
        "/cases/analytics",
        {
          params: {
            period,
            channel,
            dateFrom,
            dateTo,
            timezone
          }
        },
        false,
        false
      );
    },
    analyticsData: (period, dateFrom, dateTo, timezone) => {
      if (!timezone && Intl) {
        timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      }

      return request(
        "/cases/analytics-data",
        {
          params: {
            period,
            dateFrom,
            dateTo,
            timezone
          }
        },
        false,
        false
      );
    },
    checkView: (caseId, params) => {
      return request("/cases/" + caseId + "/check-view", {
        method: "post",
        data: params
      });
    },
    sendMessage: (caseId, params) => {
      return normalizeResponse(
        request("/cases/" + caseId + "/messages", {
          method: "post",
          data: params
        }),
        message
      );
    },
    sendNote: (caseId, uuid, text) => {
      return normalizeResponse(
        request("/cases/" + caseId + "/note", {
          method: "post",
          data: {
            uuid,
            text
          }
        }),
        message
      );
    },

    updateCaseSubjectField: (id, params) => {
      return normalizeResponse(
        request(
          "/cases/" + id + "/update-subject",
          {
            method: "post",
            data: params
          },
          true,
          false
        ),
        endUser
      );
    },

    updateCasePriority: (id, params) => {
      return normalizeResponse(
        request(
          "/cases/" + id + "/update-priority",
          {
            method: "post",
            data: params
          },
          true,
          false
        ),
        endUser
      );
    },

    counters: () => {
      return request("/cases/counters");
    },
    read: caseId => {
      return request("/cases/" + caseId + "/read", {
        method: "post"
      });
    },
    smartReplies: caseId => {
      return request("/cases/" + caseId + "/smart-reply", {}, false, false);
    },
    create: (uuid, team, endUser, message, channel) => {
      return normalizeResponse(
        request("/cases", {
          method: "post",
          data: {
            uuid,
            team,
            endUser,
            message,
            channel
          }
        }),
        caseItem
      );
    }
  },

  search: {
    all: query => {
      return request("/search?query=" + query);
    }
  },

  teamMembers: {
    websocketConnect: () => {
      return request(
        "/team-members/websocket-connect",
        {
          method: "post"
        },
        false,
        false
      );
    },
    signIn: (email, password, rememberMe) => {
      return normalizeResponse(
        request("/team-members/sign-in", {
          method: "post",
          data: {
            email,
            password,
            rememberMe
          }
        }),
        teamMember
      );
    },
    confirmInvite: (invitationToken, name, password) => {
      return normalizeResponse(
        request("/team-members/invite-confirm", {
          method: "post",
          data: {
            invitationToken,
            name,
            password
          }
        }),
        teamMember
      );
    },
    one: id => {
      return normalizeResponse(request("/team-members/" + id), teamMember);
    },
    all: () => {
      return normalizeResponse(request("/team-members"), [teamMember]);
    },
    allForAnalyticsFilter: (period, dateFrom, dateTo, timezone) => {
      if (!timezone && Intl) {
        timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      }

      return normalizeResponse(
        request("/team-members/analytics/filter", {
          params: {
            period,
            dateFrom,
            dateTo,
            timezone
          }
        }),
        [teamMember]
      );
    },
    byView: params => {
      return normalizeResponse(
        request("/team-members", {
          params
        }),
        [teamMember]
      );
    },
    allForFilter: params => {
      return normalizeResponse(
        request("/team-members/for-filter", {
          params
        }),
        [teamMember]
      );
    },
    ownerCount: id => {
      return request("/team-members/" + id + "/owner-count");
    },
    cases: params => {
      return normalizeResponse(
        request("/team-members/" + params.memberId + "/cases", {
          params
        }),
        [caseItem]
      );
    },
    updateProfile: params => {
      return normalizeResponse(
        request("/team-members/update-profile", {
          method: "post",
          data: params
        }),
        teamMember
      );
    },
    updateOption: (option, value) => {
      return normalizeResponse(
        request("/team-members/update-option", {
          method: "post",
          data: {
            option,
            value
          }
        }),
        teamMember
      );
    },
    updateAvailability: (
      teamMemberId,
      status,
      subOwnerTeam,
      subOwnerTeamMember
    ) => {
      return normalizeResponse(
        request("/team-members/" + teamMemberId + "/update-availability", {
          method: "post",
          data: {
            status,
            team: subOwnerTeam,
            teamMember: subOwnerTeamMember
          }
        }),
        teamMember
      );
    },
    updateSubOwner: (teamMemberId, subOwnerTeam, subOwnerTeamMember) => {
      return normalizeResponse(
        request("/team-members/" + teamMemberId + "/update-sub-owner", {
          method: "post",
          data: {
            team: subOwnerTeam,
            teamMember: subOwnerTeamMember
          }
        }),
        teamMember
      );
    },
    uploadAvatar: params => {
      const formData = new FormData();
      formData.append("avatar", params);
      return request(`/files/upload-avatar`, {
        method: "post",
        data: formData
      });
    },
    teamsForInvite: () => {
      return normalizeResponse(request("/team-members/teams-for-invite"), [
        team
      ]);
    },
    rolesForInvite: () => {
      return normalizeResponse(request("/team-members/roles-for-invite"), [
        role
      ]);
    },
    invite: params => {
      return normalizeResponse(
        request("/team-members/invite", {
          method: "post",
          data: params
        }),
        teamMember
      );
    },
    enable: id => {
      return request(
        "/team-members/" + id + "/enable",
        {
          method: "post"
        },
        true
      );
    },
    disable: id => {
      return request(
        "/team-members/" + id + "/disable",
        {
          method: "post"
        },
        true
      );
    },
    restore: id => {
      return request(
        "/team-members/" + id + "/restore",
        {
          method: "post"
        },
        true
      );
    },
    delete: id => {
      return request(
        "/team-members/" + id,
        {
          method: "delete"
        },
        true
      );
    },
    update: (id, params) => {
      return normalizeResponse(
        request(
          "/team-members/" + id,
          {
            method: "put",
            data: params
          },
          true
        ),
        teamMember
      );
    },
    resendInvitation: id => {
      return request(
        "/team-members/" + id + "/resend-invitation",
        {
          method: "post"
        },
        true
      );
    },
    changePassword: params => {
      return request("/team-members/change-password", {
        method: "post",
        data: params
      });
    },
    analytics: (period, dateFrom, dateTo, channel, timezone) => {
      if (!timezone && Intl) {
        timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      }

      return request(
        "/team-members/analytics",
        {
          params: {
            period,
            dateFrom,
            dateTo,
            channel,
            timezone
          }
        },
        false,
        false
      );
    },
    analyticsData: (teamMember, period, dateFrom, dateTo, timezone) => {
      if (!timezone && Intl) {
        timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      }

      return request(
        "/team-members/analytics-data",
        {
          params: {
            teamMember,
            period,
            dateFrom,
            dateTo,
            timezone
          }
        },
        false,
        false
      );
    },
    resetPassword: email => {
      return request("/team-members/reset-password", {
        method: "post",
        data: {
          email
        }
      });
    },

    checkPasswordToken: resetPasswordToken => {
      return request("/team-members/check-reset-password-token", {
        method: "post",
        data: {
          resetPasswordToken
        }
      });
    },

    confirmPassword: (resetPasswordToken, password) => {
      return request("/team-members/confirm-password", {
        method: "post",
        data: {
          resetPasswordToken,
          password
        }
      });
    },

    checkToken: invitationToken => {
      return request(
        "/team-members/check-invitation-token",
        {
          method: "post",
          data: {
            invitationToken
          }
        },
        true
      );
    }
  },

  endUsers: {
    one: id => {
      return normalizeResponse(request("/end-users/" + id), endUser);
    },
    query: params => {
      return request("/end-users/query", {
        method: "post",
        data: params
      });
    },
    cases: params => {
      return normalizeResponse(
        request("/end-users/" + params.userId + "/cases", {
          params
        }),
        [caseItem]
      );
    },
    block: id => {
      return request(
        "/пока/нету/такого" + id,
        {
          method: "post"
        },
        true
      );
    },
    changeQualification: (userId, qualification) => {
      const endpoint =
        qualification === "customer"
          ? `/end-users/${userId}/change-qualification-to-customer`
          : `/end_users/${userId}/change_qualification_to_lead`;
      return normalizeResponse(
        request(
          endpoint,
          {
            method: "post"
          },
          true,
          false
        ),
        endUser
      );
    },
    update: (id, params) => {
      return normalizeResponse(
        request(
          "/end-users/" + id + "/update",
          {
            method: "post",
            data: params
          },
          true
        ),
        endUser
      );
    },
    updateExtraFields: (id, params) => {
      return normalizeResponse(
        request(
          "/end-users/" + id + "/update-extra-field",
          {
            method: "post",
            data: params
          },
          true,
          false
        ),
        endUser
      );
    },
    updateOwner: (id, team, teamMember) => {
      return normalizeResponse(
        request(
          "/end-users/" + id + "/update-owner",
          {
            method: "post",
            data: {
              team,
              teamMember
            }
          },
          true
        ),
        endUser
      );
    },
    bulkUpdate: (endUsers, params) => {
      return request("/end-users/bulk-update", {
        method: "post",
        data: {
          endUsers,
          ...params
        }
      });
    },
    channels: id => {
      return normalizeResponse(
        request("/end-users/" + id + "/channels"),
        channel
      );
    }
  },

  extraFields: {
    teamMembers: () => {
      return normalizeResponse(request("/extra-fields/team-members"), [
        extraField
      ]);
    },
    endUsers: () => {
      return normalizeResponse(request("/extra-fields/end-users"), [
        extraField
      ]);
    }
  },

  quickReplies: {
    all: () => {
      return normalizeResponse(request("/quick-replies"), [quickReply]);
    },
    create: (uuid, title, text) => {
      return normalizeResponse(
        request("/quick-replies", {
          method: "post",
          data: {
            uuid,
            title,
            text
          }
        }),
        quickReply
      );
    },
    update: (id, params) => {
      return normalizeResponse(
        request("/quick-replies/" + id + "/update", {
          method: "post",
          data: params
        }),
        quickReply
      );
    },
    delete: id => {
      return request("/quick-replies/" + id, {
        method: "delete"
      });
    }
  },

  views: {
    endUsers: () => {
      return normalizeResponse(request("/views/end-users"), [view]);
    },
    teamMembers: () => {
      return normalizeResponse(request("/views/team-members"), [view]);
    },
    teams: () => {
      return normalizeResponse(request("/views/teams"), [view]);
    },
    cases: () => {
      return normalizeResponse(request("/views/cases"), [view]);
    },
    oneByName: (teamMember, type, name) => {
      return request(
        "/views/by-name?type=" +
          type +
          "&name=" +
          name +
          "&teamMember=" +
          teamMember
      );
    }
  },

  teams: {
    one: id => {
      return normalizeResponse(request("/teams/" + id), team);
    },
    all: () => {
      return normalizeResponse(request("/teams/all"), [team]);
    },
    teamsForFilter: () => {
      return normalizeResponse(request("/teams/for-filter"), [team]);
    },
    withMembers: () => {
      return normalizeResponse(request("/teams/with-members"), [team]);
    },
    byView: view => {
      return normalizeResponse(
        request("/teams", {
          params: { view }
        }),
        [team]
      );
    },
    members: id => {
      return normalizeResponse(request("/teams/" + id + "/members"), [
        teamMember
      ]);
    },
    membersForAdd: (id, params) => {
      return normalizeResponse(
        request("/teams/" + id + "/members-for-add", {
          params: params
        }),
        [teamMember]
      );
    },
    enabled: () => {
      return normalizeResponse(request("/teams?view=enabled"), [team]);
    },
    enable: id => {
      return normalizeResponse(
        request(
          "/teams/" + id + "/enable",
          {
            method: "post"
          },
          true
        ),
        [team]
      );
    },
    create: params => {
      return normalizeResponse(
        request(
          "/teams",
          {
            method: "post",
            data: params
          },
          true
        ),
        team
      );
    },
    update: (id, params) => {
      return normalizeResponse(
        request(
          "/teams/" + id,
          {
            method: "put",
            data: params
          },
          true
        ),
        team
      );
    },
    disable: id => {
      return request(
        "/teams/" + id + "/disable",
        {
          method: "post"
        },
        true
      );
    },
    restore: id => {
      return request(
        "/teams/" + id + "/restore",
        {
          method: "post"
        },
        true
      );
    },
    delete: id => {
      return normalizeResponse(
        request(
          "/teams/" + id,
          {
            method: "delete"
          },
          true
        ),
        team
      );
    },
    addMember: (id, params) => {
      return normalizeResponse(
        request(
          "teams/" + id + "/add-member",
          {
            method: "post",
            data: params
          },
          true
        ),
        teamMember
      );
    },
    removeMember: (id, params) => {
      return request(
        "teams/" + id + "/remove-member",
        {
          method: "post",
          data: params
        },
        true
      );
    },
    analytics: (period, dateFrom, dateTo, channel, timezone) => {
      if (!timezone && Intl) {
        timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      }

      return request(
        "/teams/analytics",
        {
          params: {
            period,
            dateFrom,
            dateTo,
            timezone,
            channel
          }
        },
        false,
        false
      );
    },
    analyticsData: (team, period, dateFrom, dateTo, timezone) => {
      if (!timezone && Intl) {
        timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      }

      return request(
        "/teams/analytics-data",
        {
          params: {
            team,
            period,
            dateFrom,
            dateTo,
            timezone
          }
        },
        false,
        false
      );
    }
  },

  facebook: {
    all: () => {
      return normalizeResponse(request("/channels/facebook"), [channel]);
    },
    create: params => {
      return normalizeResponse(
        request("/channels/facebook", {
          method: "post",
          data: params
        }),
        channel
      );
    },
    restore: id => {
      return normalizeResponse(
        request(
          "/channels/facebook/" + id + "/restore",
          {
            method: "post"
          },
          true
        ),
        channel
      );
    },
    delete: id => {
      return normalizeResponse(
        request(
          "/channels/facebook/" + id,
          {
            method: "delete"
          },
          true
        ),
        channel
      );
    }
  },

  whatsApp: {
    all: () => {
      return normalizeResponse(request("/channels/whatsapp"), [channel]);
    },
    generateQR: () => {
      return request("/channels/whatsapp", {
        method: "post"
      });
    },
    restore: id => {
      return request(
        "/channels/whatsapp/" + id + "/restore",
        {
          method: "post"
        },
        true
      );
    },
    delete: id => {
      return normalizeResponse(
        request(
          "/channels/whatsapp/" + id,
          {
            method: "delete"
          },
          true
        ),
        channel
      );
    }
  },

  telegram: {
    all: () => {
      return normalizeResponse(request("/channels/telegrambot"), [channel]);
    },
    create: params => {
      return normalizeResponse(
        request("/channels/telegrambot", {
          method: "post",
          data: params
        }),
        channel
      );
    },
    restore: id => {
      return normalizeResponse(
        request(
          "/channels/telegrambot/" + id + "/restore",
          {
            method: "post"
          },
          true
        ),
        channel
      );
    },
    delete: id => {
      return normalizeResponse(
        request(
          "/channels/telegrambot/" + id,
          {
            method: "delete"
          },
          true
        ),
        channel
      );
    }
  },

  tyntecWhatsapp: {
    all: () => {
      return normalizeResponse(request("/channels/tyntec-whatsapp"), [channel]);
    },
    create: params => {
      return normalizeResponse(
        request("/channels/tyntec-whatsapp", {
          method: "post",
          data: params
        }),
        channel
      );
    },
    restore: id => {
      return normalizeResponse(
        request(
          "/channels/tyntec-whatsapp/" + id + "/restore",
          {
            method: "post"
          },
          true
        ),
        channel
      );
    },
    delete: id => {
      return normalizeResponse(
        request(
          "/channels/tyntec-whatsapp/" + id,
          {
            method: "delete"
          },
          true
        ),
        channel
      );
    }
  },

  viber: {
    all: () => {
      return normalizeResponse(request("/channels/viber"), [channel]);
    },
    create: params => {
      return normalizeResponse(
        request("/channels/viber", {
          method: "post",
          data: params
        }),
        channel
      );
    },
    restore: id => {
      return normalizeResponse(
        request(
          "/channels/viber/" + id + "/restore",
          {
            method: "post"
          },
          true
        ),
        channel
      );
    },
    delete: id => {
      return normalizeResponse(
        request(
          "/channels/viber/" + id,
          {
            method: "delete"
          },
          true
        ),
        channel
      );
    }
  },

  vk: {
    all: () => {
      return normalizeResponse(request("/channels/vk"), [channel]);
    },
    groups: code => {
      return request("/channels/vk/groups?code=" + code, {}, true, false);
    },
    create: params => {
      return normalizeResponse(
        request("/channels/vk", {
          method: "post",
          data: params
        }),
        channel
      );
    },
    restore: id => {
      return normalizeResponse(
        request(
          "/channels/vk/" + id + "/restore",
          {
            method: "post"
          },
          true
        ),
        channel
      );
    },
    delete: id => {
      return normalizeResponse(
        request(
          "/channels/vk/" + id,
          {
            method: "delete"
          },
          true
        ),
        channel
      );
    }
  },

  messenger: {
    all: () => {
      return request("/channels/messenger");
    },
    create: params => {
      return request("/channels/messenger", {
        method: "post",
        data: params
      });
    },
    restore: id => {
      return request(
        "/channels/messenger/" + id + "/restore",
        {
          method: "post"
        },
        true
      );
    },
    delete: id => {
      return request(
        "/channels/messenger/" + id,
        {
          method: "delete"
        },
        true
      );
    }
  },

  email: {
    all: () => {
      return normalizeResponse(request("/channels/email"), [channel]);
    },
    create: params => {
      return normalizeResponse(
        request("/channels/email", {
          method: "post",
          data: params
        }),
        channel
      );
    },
    restore: id => {
      return normalizeResponse(
        request(
          "/channels/email/" + id + "/restore",
          {
            method: "post"
          },
          true
        ),
        channel
      );
    },

    update: (id, params) => {
      return normalizeResponse(
        request(
          "/channels/email/" + id + "/update",
          {
            method: "post",
            data: params
          },
          true
        ),
        channel
      );
    },

    delete: id => {
      return normalizeResponse(
        request(
          "/channels/email/" + id,
          {
            method: "delete"
          },
          true
        ),
        channel
      );
    }
  },

  slack: {
    all: () => {
      return normalizeResponse(request("/integrations/slack"), [channel]);
    },
    create: params => {
      return normalizeResponse(
        request("/integrations/slack", {
          method: "post",
          data: params
        }),
        channel
      );
    },
    delete: id => {
      return request(
        "/integrations/slack/" + id,
        {
          method: "delete"
        },
        true
      );
    }
  },

  telegramInterface: {
    all: () => {
      return normalizeResponse(request("/integrations/telegram"), [
        integration
      ]);
    },
    create: params => {
      return request("/integrations/telegram", {
        method: "post",
        data: params
      });
    },
    confirm: params => {
      return normalizeResponse(
        request("/integrations/telegram/confirm", {
          method: "post",
          data: params
        }),
        integration
      );
    },
    delete: id => {
      return request(
        "/integrations/telegram/" + id,
        {
          method: "delete"
        },
        true
      );
    }
  },

  pipedrive: {
    all: () => {
      return normalizeResponse(request("/integrations/pipedrive"), [
        integration
      ]);
    },
    create: params => {
      return normalizeResponse(
        request("/integrations/pipedrive", {
          method: "post",
          data: params
        }),
        integration
      );
    },
    delete: id => {
      return request(
        "/integrations/pipedrive/" + id,
        {
          method: "delete"
        },
        true
      );
    }
  },

  salesCardSetup: {
    all: () => {
      return normalizeResponse(request("/sales-card-setups"), [salesCardSetup]);
    },
    one: id => {
      return normalizeResponse(
        request("/sales-card-setups/" + id),
        salesCardSetup
      );
    },
    create: params => {
      return normalizeResponse(
        request("/sales-card-setups", {
          method: "post",
          data: params
        }),
        salesCardSetup
      );
    },
    update: (id, params) => {
      return normalizeResponse(
        request("/sales-card-setups/" + id, {
          method: "put",
          data: params
        }),
        salesCardSetup
      );
    },
    delete: id => {
      return request(
        "/sales-card-setups/" + id,
        {
          method: "delete"
        },
        true
      );
    }
  },

  salesCard: {
    all: () => {
      return normalizeResponse(request("/sales-cards"), [salesCard]);
    },
    one: id => {
      return normalizeResponse(request("/sales-cards/" + id), salesCard);
    },
    create: params => {
      return normalizeResponse(
        request("/sales-cards", {
          method: "post",
          data: params
        }),
        salesCard
      );
    },
    update: (id, params) => {
      return normalizeResponse(
        request("/sales-cards/" + id, {
          method: "put",
          data: params
        }),
        salesCard
      );
    },
    delete: id => {
      return request(
        "/sales-cards/" + id,
        {
          method: "delete"
        },
        true
      );
    }
  },

  roles: {
    all: () => {
      return normalizeResponse(request("/roles"), [role]);
    }
  },

  files: {
    uploadChannelFile: (fileObject, channelType, onProgress) => {
      let formData = new FormData();
      formData.append("file", fileObject);
      formData.append("channelType", channelType);

      return normalizeResponse(
        request("/files/upload-channel-file", {
          method: "post",
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data"
          },
          onUploadProgress: onProgress
        }),
        file
      );
    },
    upload: (fileObject, channelType, onProgress) => {
      let formData = new FormData();
      formData.append("file", fileObject);

      return normalizeResponse(
        request("/files/upload-file", {
          method: "post",
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data"
          },
          onUploadProgress: onProgress
        }),
        file
      );
    }
  },

  notifications: {
    settings: () => {
      return request("/notification-settings");
    },
    slackList: () => {
      return request("/integrations/slack");
    },
    changeSettings: params => {
      return request("/notification-settings/update", {
        method: "post",
        data: params
      });
    },
    all: () => {
      return normalizeResponse(request("/notifications"), [notification]);
    },
    delete: id => {
      return request("/notifications/" + id, {
        method: "delete"
      });
    },
    deleteAll: () => {
      return request("/notifications/delete-all", {
        method: "post"
      });
    }
  },

  autoResponses: {
    all: () => {
      return normalizeResponse(request("/auto-responses"), [autoResponse]);
    },
    one: id => {
      return normalizeResponse(request("/auto-responses/" + id), autoResponse);
    },
    create: params => {
      return normalizeResponse(
        request("/auto-responses", { method: "post", data: params }),
        autoResponse
      );
    },
    update: (id, params) => {
      return normalizeResponse(
        request("/auto-responses/" + id, { method: "put", data: params }),
        autoResponse
      );
    },
    delete: id => {
      return request("/auto-responses/" + id, { method: "delete" });
    },
    sort: params => {
      return request("/auto-responses/sort", { method: "post", data: params });
    }
  },

  settings: {
    all: () => {
      return request("/settings");
    },
    updateAutoResponseSettings: params => {
      return request("/settings/auto-responses", {
        method: "post",
        data: params
      });
    }
  },

  channels: {
    all: () => {
      return normalizeResponse(request("/channels"), [channel]);
    }
  },

  permissions: {
    all: () => {
      return request("/permissions");
    }
  },

  widget: {
    setupList: token => {
      return request("/customers/setups", {
        baseURL: widgetUrl,
        headers: {
          Authorization: "Bearer " + token
        }
      });
    },
    updateIntroduce: (id, values) => {
      return request("/setups/" + id + "/update-introduce", {
        baseURL: widgetUrl,
        method: "post",
        data: values
      });
    },
    updateStyle: (id, values) => {
      return request("/setups/" + id + "/update-style", {
        baseURL: widgetUrl,
        method: "post",
        data: values
      });
    },
    updateWelcome: (id, values) => {
      return request("/setups/" + id + "/update-welcome", {
        baseURL: widgetUrl,
        method: "post",
        data: values
      });
    },
    updateAvailability: (id, values) => {
      return request("/setups/" + id + "/update-availability", {
        baseURL: widgetUrl,
        method: "post",
        data: values
      });
    },
    updateChannels: (id, values) => {
      return request("/setups/" + id + "/update-channels", {
        baseURL: widgetUrl,
        method: "post",
        data: values
      });
    },
    publishSetup: () => {},
    updateQualify: (id, values) => {
      return request("/setups/" + id + "/update-qualify", {
        baseURL: widgetUrl,
        method: "post",
        data: values
      });
    },
    updateTranslations: () => {}
  }
};
