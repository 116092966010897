import React, { useMemo, useState, useEffect } from "react";
import Rule from "pages/SettingsAutoResponse/components/Rule";
import { Field, FieldArray } from "formik";
import Conditions from "pages/SettingsAutoResponse/components/Conditions";
import View from "components/View";
import AddConditionButton from "pages/SettingsAutoResponse/components/AddConditionButton";
import uniqueArrayItems from "utils/uniqueArrayItems";
import { printChannel } from "helpers/channels";
import { api } from "utils/client";
import Condition from "pages/SettingsAutoResponse/components/Condition";
import ConditionField from "pages/SettingsAutoResponse/components/ConditionField";
import SelectField from "components/SelectField";
import SelectItem from "pages/SettingsAutoResponse/components/SelectItem";

function ChannelRuleContainer({ channel, setFieldValue }) {
  const [channels, setChannels] = useState([]);
  const [subArrays, setSubArrays] = useState({});

  useEffect(() => {
    api.channels.all().ready.then(response => {
      setChannels(response.data.data.filter(i => !i.deletedAt));
    });
  }, []);

  useEffect(() => {
    if (channels.length && channel.some(i => i.channelType === "messenger")) {
      const obj = channels.find(i => i.type === "messenger");
      setFieldValue(
        `channel.${channel.indexOf(
          channel.find(i => i.channelType === "messenger")
        )}.channelId`,
        obj.id
      );
    }

    let sub = {};
    const category = uniqueArrayItems(channels, "type");

    category.map(cat => {
      sub[cat.type] = channels
        .filter(i => i.type === cat.type)
        .map(c => ({
          label: c.title,
          value: c.id,
          hide: channel.some(i => i.channelId === c.id)
        }));
      return cat;
    });

    setSubArrays(sub);
  }, [setFieldValue, channel, channels]);

  const channelList = useMemo(() => {
    return Object.keys(subArrays).map(i => {
      return {
        label: printChannel(i),
        value: i,
        hide: subArrays[i].filter(i => !i.hide).length < 1
      };
    });
  }, [subArrays]);

  return (
    <Rule
      title={
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="20"
            viewBox="0 0 19 20"
          >
            <path
              id="Ellipse_1084_copy_10"
              data-name="Ellipse 1084 copy 10"
              className="cls-1"
              d="M684.5,3651a2.5,2.5,0,1,1,2.5-2.5A2.5,2.5,0,0,1,684.5,3651Zm0-4a1.5,1.5,0,1,0,1.5,1.5A1.5,1.5,0,0,0,684.5,3647Zm-7,16a2.5,2.5,0,1,1,2.5-2.5A2.5,2.5,0,0,1,677.5,3663Zm0-4a1.5,1.5,0,1,0,1.5,1.5A1.5,1.5,0,0,0,677.5,3659Zm0-11a2.5,2.5,0,1,1,2.5-2.5A2.5,2.5,0,0,1,677.5,3648Zm0-4a1.5,1.5,0,1,0,1.5,1.5A1.5,1.5,0,0,0,677.5,3644Zm-7,16a2.5,2.5,0,1,1,2.5-2.5A2.5,2.5,0,0,1,670.5,3660Zm0-4a1.5,1.5,0,1,0,1.5,1.5A1.5,1.5,0,0,0,670.5,3656Zm0-5a2.5,2.5,0,1,1,2.5-2.5A2.5,2.5,0,0,1,670.5,3651Zm0-4a1.5,1.5,0,1,0,1.5,1.5A1.5,1.5,0,0,0,670.5,3647Zm14,8a2.5,2.5,0,1,1-2.5,2.5A2.5,2.5,0,0,1,684.5,3655Zm0,4a1.5,1.5,0,1,0-1.5-1.5A1.5,1.5,0,0,0,684.5,3659Z"
              transform="translate(-668 -3643)"
            />
          </svg>
          CHANNEL
        </>
      }
    >
      <FieldArray
        name="channel"
        render={arrayHelpers => {
          return (
            <>
              {channel.length > 0 && (
                <Conditions>
                  {channel.map((item, index) => {
                    return (
                      <Condition
                        key={index}
                        onDelete={() => arrayHelpers.remove(index)}
                      >
                        <ConditionField>
                          <Field
                            name={`channel.${index}.operator`}
                            component={SelectField}
                            placeholder="Select a condition"
                            items={[
                              { label: "Is", value: "is" },
                              { label: "Is not", value: "isNot" }
                            ]}
                          />
                        </ConditionField>
                        {item.operator && (
                          <ConditionField>
                            <Field
                              name={`channel.${index}.channelType`}
                              component={SelectField}
                              placeholder="Select value"
                              items={channelList}
                              renderItem={item => (
                                <SelectItem>{item.label}</SelectItem>
                              )}
                            />
                          </ConditionField>
                        )}
                        {item.channelType && item.channelType !== "messenger" && (
                          <ConditionField>
                            <Field
                              name={`channel.${index}.channelId`}
                              component={SelectField}
                              placeholder="Select value"
                              items={subArrays[item.channelType] || []}
                              renderItem={item => (
                                <SelectItem>{item.label}</SelectItem>
                              )}
                            />
                          </ConditionField>
                        )}
                      </Condition>
                    );
                  })}
                </Conditions>
              )}
              {channel.length < (channels.length || 1) && (
                <View display="inline-flex">
                  <AddConditionButton
                    title="Add condition"
                    onClick={() =>
                      arrayHelpers.push({ channelType: "", channelId: "" })
                    }
                  />
                </View>
              )}
            </>
          );
        }}
      />
    </Rule>
  );
}

export default ChannelRuleContainer;
