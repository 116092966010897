import React, { useEffect, useCallback, useMemo } from "react";
import { connect } from "react-redux";
import { Button } from "design-system-react";
import { Redirect } from "react-router-dom";
import { setIntroduce, setSetup } from "modules/widgetSetup";
import WidgetIntroduceFormContainer from "./WidgetIntroduceFormContainer";
import { Formik } from "formik";
import { api } from "utils/widgetClient";
import { STEP_STYLE } from "helpers/steps";
import { ALERT_SUCCESS, showAlert } from "utils/alertManager";
import { FormError } from "utils/formError";
import useStepPage from "hooks/useStepPage";
import WidgetFormLayout from "components/Widget/WidgetFormLayout";
import WidgetFooter from "components/Widget/WidgetFooter";

let noMutableInitial = {};

function WidgetIntroduceContainer({ setup, setIntroduce, setSetup }) {
  const page = useStepPage();

  //initial form values
  const initialValues = useMemo(() => {
    return {
      defaultLanguage: setup.introduce.defaultLanguage || "en",
      languages: setup.introduce.languages || [],
      company: setup.introduce.company || "Company name",
      logo: setup.introduce.logo || "",
      showAgents:
        // set it to true by default if "showOnlineStatus" equal undefined
        // and if showOnlineStatus available set true
        String(setup.introduce.showAgents) === "false" ? false : true
    };
  }, [setup.introduce]);

  // on mount/unmount
  useEffect(() => {
    document.title = "Introduce";

    noMutableInitial = initialValues;

    return () => {
      // reset form
      setIntroduce(noMutableInitial);
    };
    // eslint-disable-next-line
  }, [setIntroduce]);

  // submit form
  const handleSubmitForm = useCallback(
    (values, { setErrors, resetForm }) => {
      if (!page.loading) {
        page.setLoading(true);

        const formData = new FormData();
        formData.append("defaultLanguage", values.defaultLanguage);
        for (let i = 0; i < values.languages.length; i++) {
          formData.append("languages[]", values.languages[i]);
        }
        formData.append("logo", values.logo);
        formData.append("company", values.company);
        formData.append("showAgents", values.showAgents);

        api.widget
          .updateIntroduce(setup.id, formData, setup.step > 0)
          .ready.then(response => {
            setSetup(response.data.data);
            noMutableInitial = response.data.data.introduce;
            if (response.data.data.step === STEP_STYLE) {
              page.setRedirectToNextStep(true);
            } else {
              showAlert(
                ALERT_SUCCESS,
                "Introduce your company saved successfully"
              );
            }
            page.setInitialSaved(true);
            page.setSubmitDisabled(true);
            resetForm(values);
          })
          .catch(e => {
            if (e instanceof FormError) {
              setErrors(e.errors);
            }
          })
          .finally(() => {
            page.setLoading(false);
          });
      }
    },
    [page, setSetup, setup.id, setup.step]
  );

  if (page.redirectToNextStep) {
    return <Redirect to={"/widget/style"} />;
  }

  return (
    <WidgetFormLayout>
      <Formik
        ref={page.form}
        onSubmit={handleSubmitForm}
        initialValues={initialValues}
        render={props => (
          <WidgetIntroduceFormContainer
            {...props}
            submitDisabled={page.submitDisabled}
            setSubmitDisabled={page.setSubmitDisabled}
          />
        )}
      />
      <WidgetFooter>
        <Button
          processing={page.loading}
          disabled={page.submitDisabled}
          onClick={page.handleNextClick}
        >
          Save
        </Button>
      </WidgetFooter>
    </WidgetFormLayout>
  );
}

const mapStateToProps = state => {
  return {
    setup: state.widgetSetup.current
  };
};

const mapDispatchToProps = dispatch => ({
  setSetup: setup => dispatch(setSetup(setup)),
  setIntroduce: introduce => dispatch(setIntroduce(introduce))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WidgetIntroduceContainer);
