import React from "react";
import styles from "./AddConditionButton.module.css";

function AddConditionButton({ title, onClick }) {
  return (
    <span className={styles.button} onClick={onClick}>
      <div className={styles.icon}>
        <svg xmlns="http://www.w3.org/2000/svg" width="11px" height="11px">
          <path
            fillRule="evenodd"
            d="M10.500,6.000 L6.000,6.000 L6.000,10.501 C6.000,10.777 5.777,11.000 5.501,11.000 L5.499,11.000 C5.223,11.000 5.000,10.777 5.000,10.501 L5.000,6.000 L0.500,6.000 C0.224,6.000 -0.000,5.776 -0.000,5.500 C-0.000,5.224 0.224,5.000 0.500,5.000 L5.000,5.000 L5.000,0.499 C5.000,0.224 5.223,-0.000 5.499,-0.000 L5.501,-0.000 C5.777,-0.000 6.000,0.224 6.000,0.499 L6.000,5.000 L10.500,5.000 C10.776,5.000 11.000,5.224 11.000,5.500 C11.000,5.776 10.776,6.000 10.500,6.000 Z"
          />
        </svg>
      </div>
      {title}
    </span>
  );
}

export default AddConditionButton;
