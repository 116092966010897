import React, { useState, useCallback } from "react";
import LanguageField from "components/FieldGroup/LanguageField";
import LanguageFieldLabel from "components/FieldGroup/LanguageFieldLabel";
import LanguageFieldContent from "components/FieldGroup/LanguageFieldContent";
import {
  Dropdown,
  DropdownToggle,
  DropdownToggleDefault,
  DropdownMenu,
  Button
} from "design-system-react";

function LanguageDropdown({ label, children, defaultLanguage, handleUpdate }) {
  const [isOpen, setOpen] = useState(false);

  const toggle = useCallback(() => {
    setOpen(!isOpen);
  }, [isOpen]);

  const handleClick = useCallback(() => {
    handleUpdate({ toggle });
  }, [handleUpdate, toggle]);

  return (
    <LanguageField>
      <Dropdown isOpen={isOpen} toggle={toggle}>
        <LanguageFieldLabel>
          <DropdownToggle>
            <DropdownToggleDefault label={label}>
              {defaultLanguage}
            </DropdownToggleDefault>
          </DropdownToggle>
        </LanguageFieldLabel>
        <LanguageFieldContent>
          <DropdownMenu>
            {children}
            <Button onClick={handleClick}>Save</Button>
          </DropdownMenu>
        </LanguageFieldContent>
      </Dropdown>
    </LanguageField>
  );
}
export default LanguageDropdown;
