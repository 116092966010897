import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import styles from "./WidgetMenuItem.module.css";

function WidgetMenuItem({ to, active, children }) {
  const classes = classNames(styles.link, active && styles.active);
  return (
    <Link to={to} className={classes}>
      {children}
    </Link>
  );
}

export default WidgetMenuItem;
