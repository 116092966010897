import MessageListItemBubble from "pages/Cases/components/MessageListItemBubble";
import MessageListItemTime from "pages/Cases/components/MessageListItemTime";
import MessageListItemStatus from "pages/Cases/components/MessageListItemStatus";
import MessageListItemBubbleInfo from "pages/Cases/components/MessageListItemBubbleInfo";
import CaseFileAppearance from "../../pages/Cases/components/CaseFileAppearance";
import { relativeImageSize } from "../../utils/relativeImageSize";
import MessageListItemBubbleOverlayInfo from "../../pages/Cases/components/MessageListItemBubbleOverlayInfo";
import MessageListItemBubbleSmallImage from "../../pages/Cases/components/MessageListItemBubbleSmallImage";
import AudioPlayer from "components/audioPlayer/AudioPlayer";
import "@lottiefiles/lottie-player/dist/tgs-player";

function FileMessageBubble({
  fileType,
  fileWidth,
  fileHeight,
  fileUrl,
  fileSmallImageUrl,
  fileName,
  fileExt,
  fileSize,
  status,
  outgoing,
  time
}) {
  if (fileExt === "tgs") {
    return (
      <MessageListItemBubble status={status} dark={outgoing} time={time}>
        <tgs-player
          autoplay
          loop
          mode="normal"
          src={fileUrl}
          style={{ width: "140px", height: "140px" }}
        />
      </MessageListItemBubble>
    );
  }

  if (fileType === "image") {
    const { width, height } = relativeImageSize(
      fileWidth,
      fileHeight,
      360,
      380
    );

    if (width < 100 || height < 40) {
      return (
        <MessageListItemBubble status={status} dark={outgoing} time={time}>
          <MessageListItemBubbleSmallImage
            width={fileWidth}
            url={fileUrl}
            fileName={fileName}
            fileSize={fileSize}
            fileExt={fileExt}
            renderStatus={
              <MessageListItemBubbleInfo>
                <MessageListItemTime time={time} />
                {outgoing && <MessageListItemStatus status={status} />}
              </MessageListItemBubbleInfo>
            }
          />
        </MessageListItemBubble>
      );
    }

    return (
      <MessageListItemBubble status={status} time={time} noPadding>
        <a href={fileUrl} target="_blank" rel="noopener noreferrer">
          <img
            src={fileSmallImageUrl}
            style={{
              width: width,
              height: height
            }}
            alt=""
          />
        </a>
        <MessageListItemBubbleOverlayInfo>
          <MessageListItemTime time={time} />

          {outgoing && <MessageListItemStatus status={status} />}
        </MessageListItemBubbleOverlayInfo>
      </MessageListItemBubble>
    );
  }

  if (fileType === "audio" && fileExt.indexOf("oga", "ogg") !== -1) {
    return (
      <MessageListItemBubble status={status} file dark={outgoing} time={time}>
        {fileExt.indexOf("oga", "ogg") !== -1 && <AudioPlayer src={fileUrl} />}
      </MessageListItemBubble>
    );
  }

  if (fileType === "video" && fileExt === "mp4" && fileSize < 500000) {
    return (
      <MessageListItemBubble
        status={status}
        dark={outgoing}
        time={time}
        noPadding
      >
        <video
          loop
          autoPlay
          muted
          controls={false}
          style={{ width: "200px", height: "auto" }}
        >
          <source src={fileUrl} type="video/mp4" />
        </video>
      </MessageListItemBubble>
    );
  }

  return (
    <MessageListItemBubble status={status} file dark={outgoing} time={time}>
      <CaseFileAppearance
        name={fileName}
        ext={fileExt}
        size={fileSize}
        path={fileUrl}
        renderStatus={
          <MessageListItemBubbleInfo>
            <MessageListItemTime time={time} />
            {outgoing && <MessageListItemStatus status={status} />}
          </MessageListItemBubbleInfo>
        }
      />
    </MessageListItemBubble>
  );
}

export default FileMessageBubble;
