import classNames from "classnames";
import styles from "./Table.module.scss";

interface TableHeaderProps {
  fixed?: boolean;
  children?: React.ReactNode;
}

function TableHeader({ fixed, children }: TableHeaderProps) {
  const classes = classNames(styles.tableHeader, fixed && styles.fixed);

  return <div className={classes}>{children}</div>;
}

export default TableHeader;
