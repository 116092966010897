import React from "react";
import styles from "./MessengerCheckbox.module.css";
import { defineChannel } from "helpers/defineChannel";
import classNames from "classnames";

function MessengerCheckbox({
  handleClickConnect,
  handleClickDisconnect,
  connection,
  messenger,
  link,
  name
}) {
  const connect = classNames(
    styles.icon,
    !connection && styles.disconnection,
    !connection && "disconnection-icon"
  );

  return (
    <div className={styles.container}>
      <div
        onClick={handleClickConnect}
        className={styles.messenger + " " + name}
      >
        <img src={defineChannel(messenger).image} alt="" className={connect} />
        <div className={styles.desc}>
          <span className={styles.name}>{name}</span>
          {connection && <span className={styles.link}>{link}</span>}
        </div>
      </div>
      {connection ? (
        <div className={styles.status} onClick={handleClickDisconnect}>
          <span>Disconnect</span>
        </div>
      ) : (
        <div className={styles.status} onClick={handleClickConnect}>
          <span>Connect</span>
        </div>
      )}
    </div>
  );
}

export default MessengerCheckbox;
