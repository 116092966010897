import React, {useState, useCallback} from "react";
import {
  Button,
  PopupHeader,
  Popup,
  PopupBody,
  PopupFooter,
} from "design-system-react";

function ConfirmPinModalContainer({isOpen, toggle, pin, telegramUsername}) {
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(() => {
    setLoading(true);
    window.open("https://t.me/" + telegramUsername, '_blank').focus();

    setTimeout(() => {
      setLoading(false)
    }, 15000);
  }, [telegramUsername]);

  return (
    <Popup isOpen={isOpen} onClose={toggle}>
      <PopupHeader>Confirm your PIN</PopupHeader>
      <PopupBody>
        Send your PIN <b>{pin}</b> to telegram account
      </PopupBody>
      <PopupFooter>
        <Button
          onClick={handleSubmit}
          processing={loading}
        >
          Open telegram
        </Button>
        <Button outline={true} onClick={toggle}>
          Cancel
        </Button>
      </PopupFooter>
    </Popup>
  );
}

export default ConfirmPinModalContainer;
