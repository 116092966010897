import React, { useState, useCallback, useEffect } from "react";
import {
  Preloader,
  TableHeaderColumn,
  TableBody,
  TableRow,
  TableColumn,
  Col,
  Card,
  CardHeader,
  CardHeaderTitle,
  CardBody,
  TabContent,
  Tab,
  Tabs,
  Metric,
  MetricLabel,
  Legend,
  LegendContainer,
  LegendContainerSection,
  Chart,
  Table,
  TableHeader,
  Dropdown,
  DropdownToggle,
  DropdownToggleDefault,
  DropdownItemWithAvatar,
  DropdownMenu,
  DropdownItem,
  Avatar,
  Tooltip,
  CustomTooltip,
  DropdownMenuDivider
} from "design-system-react";

import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip as RechartTooltip,
  AreaChart,
  Area,
  ResponsiveContainer
} from "recharts";
import CardDropdownWrapper from "../components/CardDropdownWrapper";
import { makeAnalyticUrl } from "../../../helpers/analytics";
import history from "../../../utils/history";
import AvatarWrapper from "../../AnalyticsTeams/components/AvatarWrapper";
import ClosedCaseWrapper from "../components/ClosedCaseWrapper";
import { extractFirstLetters } from "utils/string";
import OpenCaseWrapper from "../components/OpenCaseWrapper";
import { connect } from "react-redux";
import { api } from "../../../utils/client";
import { channelSmIcon } from "../../../helpers/channelIcon";
import { setChannelIds } from "../../../modules/pages/analyticsTeamMembers";
import { setTeamMemberIds } from "../../../modules/pages/analyticsTeamMembers";
import uniqueArrayItems from "../../../utils/uniqueArrayItems";
import NoItemBlock from "../../AnalyticsTeams/components/NoItemBlock";
import AnalyticsChannelDropdownItem from "./AnalyticsChannelDropdownItem";
import { createSelector } from "reselect";
import { printChannel } from "../../../helpers/channels";

// tabsItem
const tabsItems = {
  firstResponseTime: {
    title: "First response time",
    tooltipText:
      "Median Time to First Response for all new cases for a specified period of time. This means that 50% of new cases were responded to within the Median Time to First Response."
  },
  responseTime: {
    title: "Response time",
    tooltipText:
      "Median Response Time in all cases for a specified period of time. This means that 50% of all cases were responded to within the Median Response Time."
  },

  resolutionTime: {
    title: "Resolution time",
    tooltipText:
      "Median Resolution Time of closed cases for a specified period of time. This means that 50% of all closed cases were resolved within the Median Resolution Time."
  },

  repliesToResolution: {
    title: "Replies to resolution",
    tooltipText:
      "Median Replies to resolution in closed cases for a specified period of time. This means that 50% of all closed cases were closed after several replies from an agent."
  },

  firstContactResolution: {
    title: "First contact resolution",
    tooltipText:
      "Median First Contact Resolution is a median amount of all cases for a specified period of time which were closed within one touch of the user."
  }
};

const tabsItemsText = [
  "First response time",
  "Response time",
  "Resolution time",
  "Replies to resolution",
  "First contact resolution"
];

function AnalyticsCardsContainer({
  loadingData,
  loadingChannelData,
  data,
  channelData,
  urlParams,
  setTeamMemberIds,
  setChannelIds,
  channels
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(urlParams.channel || "");
  const [title, setTitle] = useState(tabsItems.firstResponseTime.title);
  const [tooltipText, setTooltipText] = useState(
    tabsItems.firstResponseTime.tooltipText
  );

  const toggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleChange = useCallback(value => {
    setSelectedValue(value);
    setIsOpen(false);
  }, []);

  const selectedChannel = channels.filter(i => i.type === selectedValue)[0];

  useEffect(() => {
    setChannelIds([]);

    api.channels.all().ready.then(response => {
      setChannelIds(response.data.data.map(channel => channel.id));
    });
  }, [setChannelIds]);

  const TooltipContent = useCallback(
    props => {
      if (!props.active) {
        return null;
      }

      let payload = [];

      if (props.payload[0]) {
        payload.push({
          name: title,
          value: props.payload[0].payload.current_title || 0
        });

        payload.push({
          name: "Previous period",
          value: props.payload[0].payload.previous_title || 0
        });
      }

      return <CustomTooltip payload={payload} />;
    },
    [title]
  );
  // custom tooltip end

  if ((loadingData && loadingChannelData) || !data || !channelData) {
    return (
      <Col align="center" justified>
        <Preloader size="32" color="#807d7f" />
      </Col>
    );
  }
  // let channelsType = uniq(channels);
  // chart data
  return (
    <>
      <Col auto gutterRight={24} gutterLeft={24}>
        <Card>
          <CardHeader>
            {!selectedChannel && (
              <CardHeaderTitle
                title={title}
                descriptionText="Comparing to previous day"
                renderTooltip={
                  <Tooltip dark={true} position="bottom" withWrap={true}>
                    <>{tooltipText}</>
                  </Tooltip>
                }
              />
            )}
            {selectedChannel && selectedChannel.type === "" && (
              <CardHeaderTitle
                title={title + ""}
                descriptionText="Comparing to previous day"
                renderTooltip={
                  <Tooltip dark={true} position="bottom" withWrap={true}>
                    <>{tooltipText}</>
                  </Tooltip>
                }
              />
            )}
            {selectedChannel && selectedChannel.type !== "" && (
              <CardHeaderTitle
                title={
                  title +
                  ": " +
                  ((selectedChannel && printChannel(selectedChannel.type)) ||
                    "")
                }
                descriptionText="Comparing to previous day"
                renderTooltip={
                  <Tooltip dark={true} position="bottom" withWrap={true}>
                    <>{tooltipText}</>
                  </Tooltip>
                }
              />
            )}
            <CardDropdownWrapper>
              <Dropdown
                isOpen={isOpen}
                toggle={toggle}
                nonEmpty={selectedChannel}
                size="sm"
              >
                <DropdownToggle>
                  <DropdownToggleDefault>
                    {!selectedChannel && "All channels"}

                    {selectedChannel && (
                      <DropdownItemWithAvatar
                        renderAvatar={
                          <Avatar
                            size="size20"
                            image={channelSmIcon(selectedChannel.type)}
                          />
                        }
                      >
                        {printChannel(selectedChannel.type)}
                      </DropdownItemWithAvatar>
                    )}
                  </DropdownToggleDefault>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={e => {
                      history.push(
                        makeAnalyticUrl("/analytics/team-members", {
                          ...urlParams,
                          channel: ""
                        })
                      );
                      handleChange("");
                    }}
                  >
                    All channels
                  </DropdownItem>
                  <DropdownMenuDivider />

                  {channels.map(channel => (
                    <AnalyticsChannelDropdownItem
                      channel={channel}
                      urlParams={urlParams}
                      selectedValue={selectedValue}
                      makeAnalyticUrl={makeAnalyticUrl}
                      handleChange={handleChange}
                    />
                  ))}
                </DropdownMenu>
              </Dropdown>
            </CardDropdownWrapper>
          </CardHeader>

          <CardBody>
            {loadingData ? (
              <div
                style={{
                  minHeight: "383px",
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <Col align="center" justified>
                  <Preloader size="32" color="#807d7f" />
                </Col>
              </div>
            ) : (
              <Tabs
                activeTab={{
                  id: urlParams.tab || "firstResponseTime"
                }}
                marginRight="12px"
                width="20%"
                maxWidth="212px"
                minWidth="150px"
                spaceBetween={true}
                onTabChange={tab => {
                  if (urlParams.tab !== tab.id) {
                    window.history.pushState(
                      null,
                      null,
                      makeAnalyticUrl("/analytics/team-members", {
                        ...urlParams,
                        tab: tab.id
                      })
                    );
                  }

                  setTimeout(() => {
                    setTitle(tabsItems[tab.id].title);
                    setTooltipText(tabsItems[tab.id].tooltipText);
                  }, 200);
                }}
              >
                <Tab
                  id="firstResponseTime"
                  title={
                    <Metric
                      value={data.firstResponseTime.metric.value}
                      text={tabsItemsText[0]}
                      renderMetricLabel={
                        <MetricLabel
                          text={data.firstResponseTime.metric.result}
                          status={data.firstResponseTime.metric.status}
                          resultDirection={
                            data.firstResponseTime.metric.resultDirection
                          }
                        />
                      }
                    />
                  }
                >
                  <TabContent paddings={"0"}>
                    <Chart margins="40px 0 0 0">
                      <ResponsiveContainer>
                        <AreaChart
                          height={240}
                          data={data.firstResponseTime.chart.data}
                          margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
                        >
                          <CartesianGrid stroke="#f5f5f5" vertical={false} />
                          <XAxis
                            dataKey="title"
                            height={20}
                            stroke="#969699"
                            tickLine={false}
                            axisLine={false}
                            minTickGap={1}
                            padding={{ right: 10 }}
                          />
                          <YAxis
                            tickCount={6}
                            stroke="#969699"
                            tickLine={false}
                            axisLine={false}
                            textAnchor={"start"}
                            dx={-50}
                            domain={[
                              dataMin => 0,
                              dataMax =>
                                data.firstResponseTime.chart.maxChartValue
                            ]}
                            unit={data.firstResponseTime.chart.maxChartLabel}
                          />
                          <RechartTooltip
                            labelStyle={{
                              display: "none"
                            }}
                            content={<TooltipContent />}
                          />

                          <Area
                            type="monotone"
                            dataKey="previous"
                            stackId="1"
                            stroke="#000"
                            fill="#ecf0f1"
                            opacity=".8"
                            strokeOpacity={0}
                          />

                          <Area
                            type="monotone"
                            dataKey="current"
                            stackId="2"
                            stroke="#4c57b3"
                            fill="#4c57b3"
                            opacity=".4"
                            strokeOpacity={0}
                          />
                        </AreaChart>
                      </ResponsiveContainer>
                    </Chart>

                    <LegendContainer margins="24px 0 0 0">
                      <LegendContainerSection>
                        <Legend
                          legendText="Metric gets worth"
                          color="#d00045"
                        />
                        <Legend legendText="Metric neutral" color="#d2b00f" />
                        <Legend
                          legendText="Metric gets better"
                          color="#58a44a"
                        />
                      </LegendContainerSection>
                      <LegendContainerSection>
                        <Legend legendText="Period result" color="#8884d8" />
                        <Legend
                          legendText="Comparing to previous period"
                          color="#969799"
                        />
                      </LegendContainerSection>
                    </LegendContainer>
                  </TabContent>
                </Tab>

                <Tab
                  id="responseTime"
                  title={
                    <Metric
                      value={data.responseTime.metric.value}
                      text={tabsItemsText[1]}
                      renderMetricLabel={
                        <MetricLabel
                          text={data.responseTime.metric.result}
                          status={data.responseTime.metric.status}
                          resultDirection={
                            data.responseTime.metric.resultDirection
                          }
                        />
                      }
                    />
                  }
                >
                  <TabContent paddings={"0"}>
                    <Chart margins="40px 0 0 0">
                      <ResponsiveContainer>
                        <AreaChart
                          height={240}
                          data={data.responseTime.chart.data}
                          margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
                        >
                          <CartesianGrid stroke="#f5f5f5" vertical={false} />
                          <XAxis
                            dataKey="title"
                            height={20}
                            stroke="#969699"
                            tickLine={false}
                            axisLine={false}
                            minTickGap={1}
                            padding={{ right: 10 }}
                          />
                          <YAxis
                            tickCount={6}
                            stroke="#969699"
                            tickLine={false}
                            axisLine={false}
                            textAnchor={"start"}
                            dx={-50}
                            domain={[
                              dataMin => 0,
                              dataMax => data.responseTime.chart.maxChartValue
                            ]}
                            unit={data.responseTime.chart.maxChartLabel}
                          />
                          <RechartTooltip
                            labelStyle={{
                              display: "none"
                            }}
                            content={<TooltipContent />}
                          />

                          <Area
                            type="monotone"
                            dataKey="previous"
                            stackId="1"
                            stroke="#000"
                            fill="#ecf0f1"
                            opacity=".8"
                            strokeOpacity={0}
                          />

                          <Area
                            type="monotone"
                            dataKey="current"
                            stackId="2"
                            stroke="#4c57b3"
                            fill="#4c57b3"
                            opacity=".4"
                            strokeOpacity={0}
                          />
                        </AreaChart>
                      </ResponsiveContainer>
                    </Chart>

                    <LegendContainer margins="24px 0 0 0">
                      <LegendContainerSection>
                        <Legend
                          legendText="Metric gets worth"
                          color="#d00045"
                        />
                        <Legend legendText="Metric neutral" color="#d2b00f" />
                        <Legend
                          legendText="Metric gets better"
                          color="#58a44a"
                        />
                      </LegendContainerSection>
                      <LegendContainerSection>
                        <Legend legendText="Period result" color="#8884d8" />
                        <Legend
                          legendText="Comparing to previous period"
                          color="#969799"
                        />
                      </LegendContainerSection>
                    </LegendContainer>
                  </TabContent>
                </Tab>

                <Tab
                  id="resolutionTime"
                  title={
                    <Metric
                      value={data.resolutionTime.metric.value}
                      text={tabsItemsText[2]}
                      renderMetricLabel={
                        <MetricLabel
                          text={data.resolutionTime.metric.result}
                          status={data.resolutionTime.metric.status}
                          resultDirection={
                            data.resolutionTime.metric.resultDirection
                          }
                        />
                      }
                    />
                  }
                >
                  <TabContent paddings={"0"}>
                    <Chart margins="40px 0 0 0">
                      <ResponsiveContainer>
                        <AreaChart
                          height={240}
                          data={data.resolutionTime.chart.data}
                          margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
                        >
                          <CartesianGrid stroke="#f5f5f5" vertical={false} />
                          <XAxis
                            dataKey="title"
                            height={20}
                            stroke="#969699"
                            tickLine={false}
                            axisLine={false}
                            minTickGap={1}
                            padding={{ right: 10 }}
                          />
                          <YAxis
                            tickCount={6}
                            stroke="#969699"
                            tickLine={false}
                            axisLine={false}
                            textAnchor={"start"}
                            dx={-50}
                            domain={[
                              dataMin => 0,
                              dataMax => data.resolutionTime.chart.maxChartValue
                            ]}
                            unit={data.resolutionTime.chart.maxChartLabel}
                          />
                          <RechartTooltip
                            labelStyle={{
                              display: "none"
                            }}
                            content={<TooltipContent />}
                          />

                          <Area
                            type="monotone"
                            dataKey="previous"
                            stackId="1"
                            stroke="#000"
                            fill="#ecf0f1"
                            opacity=".8"
                            strokeOpacity={0}
                          />

                          <Area
                            type="monotone"
                            dataKey="current"
                            stackId="2"
                            stroke="#4c57b3"
                            fill="#4c57b3"
                            opacity=".4"
                            strokeOpacity={0}
                          />
                        </AreaChart>
                      </ResponsiveContainer>
                    </Chart>

                    <LegendContainer margins="24px 0 0 0">
                      <LegendContainerSection>
                        <Legend
                          legendText="Metric gets worth"
                          color="#d00045"
                        />
                        <Legend legendText="Metric neutral" color="#d2b00f" />
                        <Legend
                          legendText="Metric gets better"
                          color="#58a44a"
                        />
                      </LegendContainerSection>
                      <LegendContainerSection>
                        <Legend legendText="Period result" color="#8884d8" />
                        <Legend
                          legendText="Comparing to previous period"
                          color="#969799"
                        />
                      </LegendContainerSection>
                    </LegendContainer>
                  </TabContent>
                </Tab>

                <Tab
                  id="repliesToResolution"
                  title={
                    <Metric
                      value={data.repliesToResolution.metric.value}
                      text={tabsItemsText[3]}
                      renderMetricLabel={
                        <MetricLabel
                          text={data.repliesToResolution.metric.result}
                          status={data.repliesToResolution.metric.status}
                          resultDirection={
                            data.repliesToResolution.metric.resultDirection
                          }
                        />
                      }
                    />
                  }
                >
                  <TabContent paddings={0}>
                    <Chart margins="40px 0 0 0">
                      <ResponsiveContainer>
                        <AreaChart
                          height={240}
                          data={data.repliesToResolution.chart.data}
                          margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
                        >
                          <CartesianGrid stroke="#f5f5f5" vertical={false} />
                          <XAxis
                            dataKey="title"
                            height={20}
                            stroke="#969699"
                            tickLine={false}
                            axisLine={false}
                            minTickGap={1}
                            padding={{ right: 10 }}
                          />
                          <YAxis
                            tickCount={6}
                            stroke="#969699"
                            tickLine={false}
                            axisLine={false}
                            textAnchor={"start"}
                            dx={-50}
                            domain={[
                              dataMin => 0,
                              dataMax =>
                                data.repliesToResolution.chart.maxChartValue
                            ]}
                            unit={data.repliesToResolution.chart.maxChartLabel}
                          />
                          <RechartTooltip
                            labelStyle={{
                              display: "none"
                            }}
                            content={<TooltipContent />}
                          />

                          <Area
                            type="monotone"
                            dataKey="previous"
                            stackId="1"
                            stroke="#000"
                            fill="#ecf0f1"
                            opacity=".8"
                            strokeOpacity={0}
                          />

                          <Area
                            type="monotone"
                            dataKey="current"
                            stackId="2"
                            stroke="#4c57b3"
                            fill="#4c57b3"
                            opacity=".4"
                            strokeOpacity={0}
                          />
                        </AreaChart>
                      </ResponsiveContainer>
                    </Chart>

                    <LegendContainer margins="24px 0 0 0">
                      <LegendContainerSection>
                        <Legend
                          legendText="Metric gets worth"
                          color="#d00045"
                        />
                        <Legend legendText="Metric neutral" color="#d2b00f" />
                        <Legend
                          legendText="Metric gets better"
                          color="#58a44a"
                        />
                      </LegendContainerSection>
                      <LegendContainerSection>
                        <Legend legendText="Period result" color="#8884d8" />
                        <Legend
                          legendText="Comparing to previous period"
                          color="#969799"
                        />
                      </LegendContainerSection>
                    </LegendContainer>
                  </TabContent>
                </Tab>

                <Tab
                  id="firstContactResolution"
                  title={
                    <Metric
                      value={data.firstContactResolution.metric.value}
                      text={tabsItemsText[4]}
                      renderMetricLabel={
                        <MetricLabel
                          text={data.firstContactResolution.metric.result}
                          status={data.firstContactResolution.metric.status}
                          resultDirection={
                            data.firstContactResolution.metric.resultDirection
                          }
                        />
                      }
                    />
                  }
                >
                  <TabContent paddings={0}>
                    <Chart margins="40px 0 0 0">
                      <ResponsiveContainer>
                        <AreaChart
                          height={240}
                          data={data.firstContactResolution.chart.data}
                          margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
                        >
                          <CartesianGrid stroke="#f5f5f5" vertical={false} />
                          <XAxis
                            dataKey="title"
                            height={20}
                            stroke="#969699"
                            tickLine={false}
                            axisLine={false}
                            minTickGap={1}
                            padding={{ right: 10 }}
                          />
                          <YAxis
                            tickCount={6}
                            stroke="#969699"
                            tickLine={false}
                            axisLine={false}
                            textAnchor={"start"}
                            dx={-50}
                            domain={[
                              dataMin => 0,
                              dataMax =>
                                data.firstContactResolution.chart.maxChartValue
                            ]}
                            unit={
                              data.firstContactResolution.chart.maxChartLabel
                            }
                          />
                          <RechartTooltip
                            labelStyle={{
                              display: "none"
                            }}
                            content={<TooltipContent />}
                          />

                          <Area
                            type="monotone"
                            dataKey="previous"
                            stackId="1"
                            stroke="#000"
                            fill="#ecf0f1"
                            opacity=".8"
                            strokeOpacity={0}
                          />

                          <Area
                            type="monotone"
                            dataKey="current"
                            stackId="2"
                            stroke="#4c57b3"
                            fill="#4c57b3"
                            opacity=".4"
                            strokeOpacity={0}
                          />
                        </AreaChart>
                      </ResponsiveContainer>
                    </Chart>

                    <LegendContainer margins="24px 0 0 0">
                      <LegendContainerSection>
                        <Legend
                          legendText="Metric gets worth"
                          color="#d00045"
                        />
                        <Legend legendText="Metric neutral" color="#d2b00f" />
                        <Legend
                          legendText="Metric gets better"
                          color="#58a44a"
                        />
                      </LegendContainerSection>
                      <LegendContainerSection>
                        <Legend legendText="Period result" color="#8884d8" />
                        <Legend
                          legendText="Comparing to previous period"
                          color="#969799"
                        />
                      </LegendContainerSection>
                    </LegendContainer>
                  </TabContent>
                </Tab>
              </Tabs>
            )}
          </CardBody>
        </Card>
      </Col>

      {channelData && channelData.teamMembers.length !== 0 && (
        <Col auto gutterRight={24} gutterLeft={24} gutterBottom={40}>
          <Card>
            <CardHeader>
              <CardHeaderTitle title="Leaderboard" noInfoLabel={true} />
            </CardHeader>

            <CardBody marginTop="16px">
              <Table noHover={true} headerBorder={true} withoutPadding={true}>
                <TableHeader>
                  <TableHeaderColumn justified>Agent</TableHeaderColumn>
                  <TableHeaderColumn>Cases</TableHeaderColumn>
                  <TableHeaderColumn>First response time</TableHeaderColumn>
                  <TableHeaderColumn>Response time</TableHeaderColumn>
                  <TableHeaderColumn>Resolution time</TableHeaderColumn>
                  <TableHeaderColumn>Replies to resolution</TableHeaderColumn>
                  <TableHeaderColumn>
                    First&nbsp;contact&nbsp;resolution
                  </TableHeaderColumn>
                </TableHeader>
                <TableBody>
                  {channelData &&
                    channelData.teamMembers.map(teamMember => {
                      return (
                        <TableRow size="64px">
                          <TableColumn justified>
                            <AvatarWrapper>
                              <Avatar
                                size="size40"
                                image={
                                  teamMember.teamMember.avatar &&
                                  teamMember.teamMember.avatar
                                }
                                text={extractFirstLetters(
                                  teamMember.teamMember.name,
                                  2
                                )}
                                color={teamMember.teamMember.nameColor}
                              />
                            </AvatarWrapper>
                            {teamMember.teamMember.name}
                          </TableColumn>

                          <TableColumn>
                            {teamMember.casesCount.total}
                            <OpenCaseWrapper>
                              {teamMember.casesCount.open}
                            </OpenCaseWrapper>
                            <ClosedCaseWrapper>
                              {teamMember.casesCount.closed}
                            </ClosedCaseWrapper>
                          </TableColumn>
                          <TableColumn>
                            {teamMember.firstResponseTime || (
                              <NoItemBlock>None</NoItemBlock>
                            )}
                          </TableColumn>
                          <TableColumn>
                            {teamMember.responseTime || (
                              <NoItemBlock>None</NoItemBlock>
                            )}
                          </TableColumn>
                          <TableColumn>
                            {teamMember.resolutionTime || (
                              <NoItemBlock>None</NoItemBlock>
                            )}
                          </TableColumn>
                          <TableColumn>
                            {teamMember.repliesToResolution || (
                              <NoItemBlock>None</NoItemBlock>
                            )}
                          </TableColumn>
                          <TableColumn>
                            {teamMember.firstContactResolution || (
                              <NoItemBlock>None</NoItemBlock>
                            )}
                          </TableColumn>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      )}

      {channelData && channelData.channels.length !== 0 && (
        <Col auto gutterRight={24} gutterLeft={24} gutterBottom={40}>
          <Card>
            <CardHeader>
              <CardHeaderTitle
                title="Cases by channels"
                renderTooltip={
                  <Tooltip dark={true} position="bottom" withWrap={true}>
                    <>
                      Distribution of all incoming cases among channels as a
                      percentage between them.
                    </>
                  </Tooltip>
                }
              />
            </CardHeader>

            <CardBody marginTop="16px">
              <Table noHover={true} headerBorder={true} withoutPadding={true}>
                <TableHeader>
                  <TableHeaderColumn justified>Channel</TableHeaderColumn>
                  <TableHeaderColumn>%</TableHeaderColumn>
                  <TableHeaderColumn>Total</TableHeaderColumn>
                  <TableHeaderColumn>New</TableHeaderColumn>
                  <TableHeaderColumn>Opened</TableHeaderColumn>
                  <TableHeaderColumn>Closed</TableHeaderColumn>
                </TableHeader>
                <TableBody>
                  {channelData.channels.map(channel => {
                    return (
                      <TableRow size="40px">
                        <TableColumn justified>{channel.channel}</TableColumn>
                        <TableColumn>{channel.percent}</TableColumn>
                        <TableColumn>{channel.total}</TableColumn>
                        <TableColumn>{channel.new}</TableColumn>
                        <TableColumn>{channel.open}</TableColumn>
                        <TableColumn>{channel.closed}</TableColumn>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      )}
    </>
  );
}

const getUniqChannels = createSelector(
  [
    state => state.entities.channels,
    state => state.pages.analyticsTeamMembers.channelIds
  ],
  (channels, channelIds) => {
    const channelItems = channelIds.map(id => {
      const channel = channels[id];

      return {
        type: channel.type,
        title: channel.title
      };
    });
    return uniqueArrayItems(channelItems, "type");
  }
);

const mapStateToProps = state => {
  return {
    channels: getUniqChannels(state)
  };
};

const mapDispatchToProps = dispatch => ({
  setChannelIds: channelIds => dispatch(setChannelIds(channelIds)),
  setTeamMemberIds: teamMemberIds => dispatch(setTeamMemberIds(teamMemberIds))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnalyticsCardsContainer);
