import React from "react";
import DateTime from "components/DateTime";
import {useSelector} from "react-redux";
import {getSalesCardSetup} from "pages/SettingsSalesCardSetups/selectors";
import { TableRow, TableColumn, TableColumnMenu } from "design-system-react";
import {salesCardSetupActionItems} from "helpers/salesCards";

function TableRowContainer({ setupId, handleActionDropdown }) {
  const setup = useSelector(getSalesCardSetup(setupId));

  return (
    <TableRow>
      <TableColumn justified>{setup.title}</TableColumn>
      <TableColumn>
        <DateTime time={setup.createdAt} />
      </TableColumn>
      <TableColumnMenu
        items={salesCardSetupActionItems()}
        onItemClick={key => {
          handleActionDropdown(setup.id, key);
        }}
      />
    </TableRow>
  );
}

export default TableRowContainer;
