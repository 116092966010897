import React from "react";
import { Avatar  } from "design-system-react";
import styles from "./TeamsListContact.module.css";
import classNames from "classnames";
import PropTypes from "prop-types";
import { extractFirstCharacters } from "../../../utils/string";

function TeamsListContact({ color, name }) {
  const avatarClass = classNames(styles.avatar);

  return (
    <div className={styles.content}>
      <div className={avatarClass}>
        <Avatar
          size="size48"
          text={extractFirstCharacters(name, 1)}
          background={color}
          color="#ffffff"
        />
      </div>
      <div className={styles.info}>
        <div className={styles.name}>{name}</div>
      </div>
    </div>
  );
}

TeamsListContact.propTypes = {
  name: PropTypes.string,
  color: PropTypes.string
};

export default TeamsListContact;
