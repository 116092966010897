import React from "react";
import styles from "pages/Cases/components/MessageListReplyingMessage.module.css";
import PropTypes from "prop-types";

function MessageListReplyingMessage({ onScrollClick }) {
  return (
    <div className={styles.replyingMessage} onClick={onScrollClick}>
      <p>
        To start replying in this case, you must assign it to <br /> yourself
        first
      </p>
    </div>
  );
}

MessageListReplyingMessage.propTypes = {
  onScrollClick: PropTypes.func.isRequired
};

export default MessageListReplyingMessage;
