import React, { useState, useCallback } from "react";
import {
  Dropdown,
  DropdownToggleIcon,
  DropdownMenu,
  DropdownItem
} from "design-system-react";

function ActionDropdown({
  items,
  direction,
  gutterTop,
  gutterRight,
  onItemClick
}) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  return (
    <Dropdown isOpen={isOpen} toggle={toggle}>
      <DropdownToggleIcon>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="2"
          height="14"
          viewBox="0 0 2 14"
        >
          <path
            fill={isOpen ? "#000" : "#7d7e80"}
            d="M2471,497a1,1,0,1,1-1,1A1,1,0,0,1,2471,497Zm0-12a1,1,0,1,1-1,1A1,1,0,0,1,2471,485Zm0,6a1,1,0,1,1-1,1A1,1,0,0,1,2471,491Z"
            transform="translate(-2470 -485)"
          />
        </svg>
      </DropdownToggleIcon>
      <DropdownMenu
        gutterTop={gutterTop}
        gutterRight={gutterRight}
        direction={direction}
      >
        {items.map(item => (
          <DropdownItem
            key={item.key}
            onClick={() => {
              setIsOpen(false);
              onItemClick(item.key);
            }}
          >
            {item.label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
}

export default ActionDropdown;
