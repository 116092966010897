import cn from "classnames";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import styles from "./Table.module.scss";

interface TableHeaderColumnProps extends React.HTMLAttributes<HTMLElement> {
  width?: number;
  minWidth?: number;
  maxWidth?: number;
  fixed?: boolean;
  sortable?: boolean;
  sort?: "asc" | "desc" | undefined;
  children?: React.ReactNode;
}

function TableHeaderColumn({
  width,
  minWidth,
  maxWidth,
  fixed,
  sortable,
  sort = "asc",
  children,
  className,
  ...attributes
}: TableHeaderColumnProps) {
  const classes = cn(
    styles.tableHeaderColumn,
    !width && styles.justified,
    fixed && styles.fixed,
    sortable && styles.sortable,
    className
  );

  return (
    <div
      className={classes}
      style={{ width: width, minWidth: minWidth || width, maxWidth: maxWidth }}
      {...attributes}
    >
      {children}
      {sortable && (
        <div className={styles.sorter}>
          <CaretUpOutlined
            className={cn(styles.up, sort === "asc" && styles.active)}
          />
          <CaretDownOutlined
            className={cn(styles.down, sort === "desc" && styles.active)}
          />
        </div>
      )}
    </div>
  );
}

export default TableHeaderColumn;
