export function relativeImageSize(width, height, visibleWidth, visibleHeight) {
  const widthRatio = visibleWidth / width;
  const heightRatio = visibleHeight / height;
  const ratio = Math.min(widthRatio, heightRatio);
  const useWidthRatio = widthRatio < heightRatio ? 1 : 0;

  let resultWidth = useWidthRatio ? visibleWidth : Math.ceil(width * ratio);
  resultWidth = width < resultWidth ? width : resultWidth;

  let resultHeight = !useWidthRatio ? visibleHeight : Math.ceil(height * ratio);
  resultHeight = height < resultHeight ? height : resultHeight;

  return { width: resultWidth, height: resultHeight };
}
