import React, { useEffect } from "react";
import SettingsSubMenu from "components/settings/SettingsSubMenu";
import SettingsQuickRepliesContainer from "./containers/SettingsQuickRepliesContainer";
import SettingsLayout from "components/SettingsLayout";

function SettingsQuickReplies() {
  useEffect(() => {
    document.title = "Quick replies settings";
    localStorage.setItem("lastSettingRoute", "/settings/quick-replies");
  }, []);

  return (
    <SettingsLayout
      renderSubmenu={() => (
        <SettingsSubMenu activeMenuItem={"/settings/quick-replies"} />
      )}
      renderContent={() => <SettingsQuickRepliesContainer />}
    />
  );
}

export default SettingsQuickReplies;
