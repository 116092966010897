import {
  can,
  TEAM_MEMBER_DELETE_ADMIN_PERMISSION,
  TEAM_MEMBER_DELETE_AGENT_PERMISSION,
  TEAM_MEMBER_RESTORE_ADMIN_PERMISSION,
  TEAM_MEMBER_RESTORE_AGENT_PERMISSION,
  TEAM_MEMBER_UPDATE_ADMIN_PERMISSION,
  TEAM_MEMBER_UPDATE_AGENT_PERMISSION
} from "../utils/permissions";

export const TEAM_MEMBER_STATUS_ENABLED = "enabled";
export const TEAM_MEMBER_STATUS_DISABLED = "disabled";
export const TEAM_MEMBER_STATUS_PENDING = "pending";

export const AVALABILITY_ACTIVE = "active";
export const AVALABILITY_INACTIVE = "inactive";

export function teamMemberActionItems(status, deleted) {
  let items = [];

  // const canEnable =
  //   can(TEAM_MEMBER_ENABLE_ADMIN_PERMISSION) ||
  //   can(TEAM_MEMBER_ENABLE_AGENT_PERMISSION);
  //
  // const canDisable =
  //   can(TEAM_MEMBER_DISABLE_ADMIN_PERMISSION) ||
  //   can(TEAM_MEMBER_DISABLE_AGENT_PERMISSION);

  const canCopyLink =
    can(TEAM_MEMBER_UPDATE_ADMIN_PERMISSION) ||
    can(TEAM_MEMBER_UPDATE_AGENT_PERMISSION);

  const canEdit =
    can(TEAM_MEMBER_UPDATE_ADMIN_PERMISSION) ||
    can(TEAM_MEMBER_UPDATE_AGENT_PERMISSION);

  const canDelete =
    can(TEAM_MEMBER_DELETE_ADMIN_PERMISSION) ||
    can(TEAM_MEMBER_DELETE_AGENT_PERMISSION);

  const canRestore =
    can(TEAM_MEMBER_RESTORE_ADMIN_PERMISSION) ||
    can(TEAM_MEMBER_RESTORE_AGENT_PERMISSION);

  if (deleted) {
    canRestore && items.push({ key: "restore", label: "Restore" });
  } else {
    switch (status) {
      case TEAM_MEMBER_STATUS_ENABLED:
        // canEnable && items.push({ key: "disable", label: "Disable" });
        canEdit && items.push({ key: "edit", label: "Edit" });
        canDelete && items.push({ key: "delete", label: "Delete" });

        break;
      case TEAM_MEMBER_STATUS_DISABLED:
        // canDisable && items.push({ key: "enable", label: "Enable" });
        canEdit && items.push({ key: "edit", label: "Edit" });
        canDelete && items.push({ key: "delete", label: "Delete" });
        break;

      case TEAM_MEMBER_STATUS_PENDING:
        items.push({ key: "resend", label: "Resend invitation" });
        canCopyLink &&
          items.push({ key: "copy", label: "Copy invitation link" });
        canEdit && items.push({ key: "edit", label: "Edit" });
        canDelete && items.push({ key: "delete", label: "Delete" });
        break;

      default:
        break;
    }
  }

  return items;
}

export function printAvailability(availability) {
  switch (availability) {
    case AVALABILITY_ACTIVE:
      return "Active";
    case AVALABILITY_INACTIVE:
      return "Inactive";
    default:
      return "";
  }
}
