import React, { useCallback, useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownToggleDefault,
  DropdownMenu,
  DropdownItem
} from "design-system-react";

function WidgetQualifySelectContainer({
  label,
  items,
  size,
  disabled,
  error,
  placeholder,
  handleChange,
  renderItem
}) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleChangeValue = useCallback(
    value => {
      setIsOpen(false);
      handleChange(value);
    },
    [handleChange]
  );

  return (
    <Dropdown
      isOpen={isOpen}
      toggle={toggle}
      validation={error && "error"}
      validationMessage={error}
      size={size}
      disabled={disabled || !items.some(i => !i.hide)}
    >
      <DropdownToggle>
        <DropdownToggleDefault label={label} placeholder={placeholder} />
      </DropdownToggle>
      <DropdownMenu>
        {items
          .filter(i => !i.hide)
          .map(item => (
            <DropdownItem
              key={item.value}
              onClick={() => {
                handleChangeValue(item.value);
              }}
            >
              {renderItem ? renderItem(item) : item.label}
            </DropdownItem>
          ))}
      </DropdownMenu>
    </Dropdown>
  );
}

export default WidgetQualifySelectContainer;
