// actions
import Centrifuge from "centrifuge";
import { api } from "./client";

export const WS_EVENT_MESSAGE_CREATED = "messageCreated";
export const WS_EVENT_ALERT = "alert";
export const WS_EVENT_MESSAGE_CONTENT_UPDATED = "messageContentUpdated";
export const WS_EVENT_CASE_CREATED = "casesCreated";
export const WS_EVENT_CASE_UPDATED = "casesUpdated";
export const WS_EVENT_VIEW_UPDATED = "viewsUpdated";
export const WS_EVENT_CASES_COUNTER_CREATED = "casesCounterCreated";
export const WS_EVENT_NOTIFICATION_CREATED = "notificationCreated";
export const WS_EVENT_NOTIFICATION_DELETED = "notificationDeleted";
export const WS_EVENT_SMART_REPLY_CREATED = "smartReplyCreated";
export const WS_EVENT_END_USER_UPDATED = "endUserUpdated";
export const WS_EVENT_CHANNEL_CREATED = "channelCreated";
export const WS_EVENT_CHANNEL_RESTORED = "channelRestored";
export const WS_EVENT_CHANNEL_VALIDATION_ERROR = "validationError";
export const WS_EVENT_PIN_CONFIRMED = "pinConfirmed";

let handlers = {};

export function subscribe(event, handler) {
  if (!handlers[event]) {
    handlers[event] = [];
  }

  handlers[event].push(handler);
}

export function unsubscribe(event, handler) {
  handlers[event] = handlers[event].filter(h => h !== handler);
}

function publish(event, data) {
  if (!handlers[event] || handlers[event].length < 1) {
    return;
  }

  handlers[event].forEach(handler => {
    handler(data);
  });
}

// actions
export function connectToWebsocket() {
  api.teamMembers.websocketConnect().ready.then(response => {
    let centrifuge = new Centrifuge(response.data.url, {
      subscribeEndpoint:
        response.data.subscribeEndpoint + localStorage.getItem("token") ||
        sessionStorage.getItem("token")
    });

    centrifuge.setToken(response.data.token);

    centrifuge.subscribe(
      "$" +
        response.data.prefix +
        "--" +
        (localStorage.getItem("userId") || sessionStorage.getItem("userId")),
      message => {
        publish(message.data.type, message.data.data);
      }
    );

    centrifuge.on("connect", function(context) {
      console.log("Hugery WS: connected", context);
    });

    centrifuge.on("disconnect", function(context) {
      console.log("Hugery WS: disconnected", context);
    });

    centrifuge.on("error", function(error) {
      console.log("Hugery WS: error", error);
    });

    centrifuge.connect();
  });
}
