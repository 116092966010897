
import React from "react";
import { Container } from "design-system-react";
import SettingsProfileInformation from "../components/SettingsProfileInformation";
import useFormSubmission from "hooks/useFormSubmission";
import SettingsProfileSubtitle from "../components/SettingsProfileSubtitle";
import SettingProfilePersonalContainer from "pages/SettingsProfile/containers/SettingProfilePersonalContainer";

function SettingsProfileFormContainer({
  handlePasswordClick,
  user,
  handleSubmit,
  submitDisabled,
  setSubmitDisabled,
  setFieldValue,
  children,
  onPinConfirm,
  dirty
}) {
  const handleSubmitForm = useFormSubmission(submitDisabled, handleSubmit);

  if (!dirty && !submitDisabled) {
    setSubmitDisabled(true);
  }

  if (dirty && submitDisabled) {
    setSubmitDisabled(false);
  }

  return (
    <Container>
      <form onSubmit={handleSubmitForm}>
        <SettingsProfileSubtitle>Personal Details</SettingsProfileSubtitle>
        <SettingProfilePersonalContainer
          setFieldValue={setFieldValue}
          avatar={user.avatar}
          handleChangePasswordClick={handlePasswordClick}
        />
        <SettingsProfileSubtitle>Contact Details</SettingsProfileSubtitle>
        <SettingsProfileInformation email={user.email} pin={user.pin} pinConfirmed={user.pinConfirmed} onPinConfirm={onPinConfirm} />
        {children}
      </form>
    </Container>
  );
}

export default SettingsProfileFormContainer;
