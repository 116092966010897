import React, { useState, useCallback } from "react";
import {
  Dropdown,
  DropdownMenuNavItem,
  DropdownToggleContent,
  DropdownMenu,
  DropdownMenuLabel
} from "design-system-react";
import { Link } from "react-router-dom";
import NavPanelContentTitle from "../NavPanel/NavPanelContentTitle";

function CollapseMenu({ items, title, counter, withoutDropdown }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  return (
    <>
      {withoutDropdown && (
        <NavPanelContentTitle title={title} counter={counter} />
      )}
      {!withoutDropdown && (
        <Dropdown isOpen={isOpen} toggle={toggle}>
          <DropdownToggleContent isOpen={isOpen}>
            <NavPanelContentTitle title={title} counter={counter} />
          </DropdownToggleContent>
          <DropdownMenu gutterTop={0} gutterRight={-173}>
            {items.map(item => {
              if (item.label) {
                return (
                  <DropdownMenuLabel key={item.id}>
                    {item.label}
                  </DropdownMenuLabel>
                );
              }

              return (
                <DropdownMenuNavItem
                  onClick={toggle}
                  key={item.id}
                  active={item.active}
                  tag={Link}
                  to={item.to}
                  text={item.title}
                  counter={item.notificationCounter}
                  counterColor={item.notificationCounterColor}
                  counterAll={item.counter}
                />
              );
            })}
          </DropdownMenu>
        </Dropdown>
      )}
    </>
  );
}

export default CollapseMenu;
