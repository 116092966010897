import React from "react";
import {
  SubMenu,
  SubMenuHeader,
  SubMenuContent,
  SubMenuList,
  SubMenuListItem
} from "design-system-react";
import { Link } from "react-router-dom";
import {
  can,
  CASES_ANALYTICS_PERMISSION,
  TEAM_MEMBER_ANALYTICS_PERMISSION
} from "../utils/permissions";

function AnalyticsSubMenu({ activeMenuItem }) {
  return (
    <SubMenu>
      <SubMenuHeader>Analytics</SubMenuHeader>

      <SubMenuContent>
        <SubMenuList>
          {can(CASES_ANALYTICS_PERMISSION) && (
            <SubMenuListItem
              key={"cases"}
              tag={Link}
              to={"/analytics/cases"}
              text={"Cases"}
              active={activeMenuItem === "/analytics/cases"}
            />
          )}
          {can(TEAM_MEMBER_ANALYTICS_PERMISSION) && (
            <SubMenuListItem
              key={"team-members"}
              tag={Link}
              to={"/analytics/team-members"}
              text={"Team members"}
              active={activeMenuItem === "/analytics/team-members"}
            />
          )}
          {can(TEAM_MEMBER_ANALYTICS_PERMISSION) && (
            <SubMenuListItem
              key={"teams"}
              tag={Link}
              to={"/analytics/teams"}
              text={"Teams"}
              active={activeMenuItem === "/analytics/teams"}
            />
          )}
        </SubMenuList>
      </SubMenuContent>
    </SubMenu>
  );
}

export default AnalyticsSubMenu;
