import React, { useState, useCallback } from "react";
import SettingsChannelHeader from "components/SettingsHeader";
import { Row, Col, Container } from "design-system-react";
import SettingsViberListContainer from "./SettingsViberListContainer";
import AddModalContainer from "./AddModalContainer";

function SettingsViberContainer({ locationKey }) {
  const [addModalOpen, setAddModalOpen] = useState(false);

  const toggleAddModal = useCallback(() => {
    setAddModalOpen(!addModalOpen);
  }, [addModalOpen]);

  return (
    <Row fullHeight column>
      <Container>
        <SettingsChannelHeader
          title="Viber"
          logo="/images/viber_100.svg"
          onAction={toggleAddModal}
        >
          Conduct customer communication using Viber messaging app integration
          with Hugery, send and receive messages directly in the
          unified interface of Hugery.
        </SettingsChannelHeader>
      </Container>
      <Col justified>
        <SettingsViberListContainer locationKey={locationKey} />
      </Col>
      <AddModalContainer
        isOpen={addModalOpen}
        toggle={toggleAddModal}
      />
    </Row>
  );
}

export default SettingsViberContainer;
