import axios from "axios";
import normalizer from "json-api-normalizer";
import { normalize } from "normalizr";
import store from "store";
import { updateNormalizedData } from "modules/data";
import { FormError } from "./formError";
import { ALERT_ERROR, showAlert } from "./alertManager";
import { logout } from "../modules/auth";
import { CommonError } from "./commonError";
import { addEntities } from "../modules/entities";
import { isMatch, merge } from "lodash";
import history from "utils/history";
import { widgetUrl } from "./client";

const UNAUTHORIZED_ERROR_CODE = 401;

const FIELD_ERROR_CODE = 10001;
// const COMMON_ERROR_CODE = 10101;
const DELETED_TEAM_MEMBER = 20015;

const baseURL = widgetUrl;
let token = localStorage.getItem("widgetToken");

let client = axios.create({
  baseURL,
  headers: { Authorization: "Bearer " + token }
});

export function setToken(key) {
  localStorage.setItem("widgetToken", key);
  client = axios.create({
    baseURL,
    headers: { Authorization: "Bearer " + key }
  });
}

export const request = (
  endpoint,
  options = {},
  withoutAlert = false,
  normalize = true
) => {
  const CancelToken = axios.CancelToken;
  const cancelRequest = CancelToken.source();

  options.cancelToken = cancelRequest.token;

  const ready = client(endpoint, options)
    .then(response => {
      if (normalize) {
        const normalizedData = normalizer(response.data);
        store.dispatch(updateNormalizedData(normalizedData));
      }

      return response;
    })
    .catch(error => {
      if (!error.response) {
        throw error;
      }

      const errorCode = error.response.data.data.code;

      if (error.response) {
        if (error.response.status === UNAUTHORIZED_ERROR_CODE) {
          store.dispatch(logout());
        }

        if (errorCode === DELETED_TEAM_MEMBER) {
          withoutAlert = true;

          store.dispatch(logout());
          history.push({
            pathname: "/account-not-exist"
          });
        }

        if (error.response.data.data) {
          if (errorCode === FIELD_ERROR_CODE) {
            const fieldErrors = error.response.data.data.info;

            if (fieldErrors) {
              let preparedErrors = {};

              fieldErrors.map(e => {
                preparedErrors[e.source.pointer] = e.title;
                return e;
              });

              throw new FormError(preparedErrors);
            }
          }

          if (errorCode) {
            const errorMessage =
              error.response.data.data.message || "Server error";

            if (!withoutAlert) {
              showAlert(ALERT_ERROR, errorMessage);
            }

            throw new CommonError(errorMessage, error.response.status);
          }
        }
      }

      if (error.message === "Network Error") {
        showAlert(ALERT_ERROR, "Check your network connection status");
      }

      throw error;
    });

  return {
    ready,
    cancelRequest
  };
};

export function dispatchUpdatedDataToStore(data, scheme) {
  const state = store.getState();
  const normalizedData = normalize(data, scheme);

  let updatedData = {};

  Object.keys(normalizedData.entities).map(type => {
    if (!state.entities[type]) {
      updatedData[type] = normalizedData.entities[type];
      return type;
    }

    Object.keys(normalizedData.entities[type]).map(id => {
      let entityUpdated = false;

      if (!state.entities[type][id]) {
        entityUpdated = true;
      } else {
        entityUpdated = !isMatch(
          state.entities[type][id],
          normalizedData.entities[type][id]
        );
      }

      if (entityUpdated) {
        if (!updatedData[type]) {
          updatedData[type] = {};
        }

        updatedData[type][id] = merge(
          state.entities[type][id],
          normalizedData.entities[type][id]
        );
      }
      return id;
    });
    return type;
  });

  if (Object.keys(updatedData).length > 0) {
    store.dispatch(addEntities(updatedData));
  }
}

export const api = {
  widget: {
    setupList: () => {
      return request("/customers/setups");
    },
    updateIntroduce: (id, values, isSimple) => {
      return request(
        "/setups/" +
          id +
          `${isSimple ? "/update-introduce-simple" : "/update-introduce"}`,
        {
          method: "post",
          data: values
        }
      );
    },
    updateStyle: (id, values) => {
      return request("/setups/" + id + "/update-style", {
        method: "post",
        data: values
      });
    },
    updateWelcome: (id, values) => {
      return request("/setups/" + id + "/update-welcome", {
        method: "post",
        data: values
      });
    },
    updateAvailability: (id, values) => {
      return request("/setups/" + id + "/update-availability", {
        method: "post",
        data: values
      });
    },
    updateChannels: (id, values) => {
      return request("/setups/" + id + "/update-channels", {
        method: "post",
        data: values
      });
    },
    updateQualify: (id, values) => {
      return request("/setups/" + id + "/update-qualify", {
        method: "post",
        data: values
      });
    },
    publishSetup: id => {
      return request("/setups/" + id + "/publish", {
        method: "post"
      });
    },
    updateTranslations: (id, values) => {
      return request("/setups/" + id + "/update-translations", {
        method: "post",
        data: values
      });
    }
  }
};
