import React, { useState, useCallback } from "react";
import { Field, FieldArray } from "formik";
import InputField from "../../../components/InputField";
import SelectField from "../../../components/SelectField";
import Rule from "../components/Rule";
import Condition from "../components/Condition";
import AddConditionButton from "../components/AddConditionButton";
import ConditionField from "../components/ConditionField";
import View from "../../../components/View";
import Conditions from "../components/Conditions";
import { getTimeSeries, timezones } from "../../../helpers/autoResponses";
import QualificationRuleContainer from "./QualificationRuleContainer";
import ChannelRuleContainer from "./ChannelRuleContainer";
import LanguageRuleContainer from "./LanguageRuleContainer";
import WeekdaysRuleContainer from "./WeekdaysRuleContainer";
import useFormSubmission from "hooks/useFormSubmission";

function RulesContainer({
  values,
  setFieldValue,
  submitDisabled,
  setSubmitDisabled,
  dirty,
  handleSubmit
}) {
  const handleFormSubmit = useFormSubmission(submitDisabled, handleSubmit);

  if (!dirty) {
    if (!submitDisabled) {
      setSubmitDisabled(true);
    }
  } else if (submitDisabled) {
    setSubmitDisabled(false);
  }

  const [timePeriodEnabled, setTimePeriodEnabled] = useState(
    Boolean(values.timePeriod && values.timePeriod.timezone)
  );

  const toggleTimePeriod = useCallback(
    value => {
      if (!value) {
        setFieldValue("timePeriod.timezone", "");
        setFieldValue("timePeriod.from", "");
        setFieldValue("timePeriod.to", "");
      }
      setTimePeriodEnabled(value);
    },
    [setFieldValue]
  );

  return (
    <form onSubmit={handleFormSubmit}>
      {/*End user qualification*/}

      <QualificationRuleContainer qualification={values.qualification} />

      {/*Channel*/}

      <ChannelRuleContainer
        setFieldValue={setFieldValue}
        channel={values.channel}
      />

      {/*Language*/}

      <LanguageRuleContainer language={values.language} />

      {/*Text message*/}
      <Rule
        title={
          <>
            <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px">
              <path d="M15.000,16.000 L9.000,16.000 L9.000,16.000 L9.000,16.000 L4.000,20.000 L4.000,17.000 L4.000,16.000 L4.000,15.899 C1.718,15.436 0.000,13.419 0.000,11.000 L0.000,5.000 C0.000,2.239 2.238,0.000 5.000,0.000 L15.000,0.000 C17.761,0.000 20.000,2.239 20.000,5.000 L20.000,11.000 C20.000,13.761 17.761,16.000 15.000,16.000 ZM19.000,5.000 C19.000,2.791 17.209,1.001 15.000,1.001 L5.000,1.001 C2.790,1.001 1.000,2.791 1.000,5.000 L1.000,11.000 C1.000,12.862 2.278,14.413 4.000,14.858 L4.000,15.000 L5.000,15.000 L5.000,18.000 L9.000,15.000 L9.000,15.000 L15.000,15.000 C17.209,15.000 19.000,13.209 19.000,11.000 L19.000,5.000 Z" />
            </svg>
            TEXT MESSAGE
          </>
        }
      >
        <FieldArray
          name="message"
          render={arrayHelpers => {
            return (
              <>
                {values.message.length > 0 && (
                  <Conditions>
                    {values.message.map((message, index) => {
                      return (
                        <Condition onDelete={() => arrayHelpers.remove(index)}>
                          <ConditionField>
                            <Field
                              name={`message.${index}.operator`}
                              component={SelectField}
                              placeholder="Select a condition"
                              items={[
                                { label: "Is", value: "is" },
                                { label: "Is not", value: "isNot" },
                                {
                                  label: "Contains",
                                  value: "contains"
                                },
                                {
                                  label: "Not contains",
                                  value: "notContains"
                                }
                              ]}
                            />
                          </ConditionField>
                          {values.message[index].operator && (
                            <ConditionField>
                              <Field
                                name={`message.${index}.message`}
                                component={InputField}
                                placeholder="Message"
                              />
                            </ConditionField>
                          )}
                        </Condition>
                      );
                    })}
                  </Conditions>
                )}
                {values.message.length < 10 && (
                  <View display="inline-flex">
                    <AddConditionButton
                      title="Add condition"
                      onClick={() =>
                        arrayHelpers.push({ operator: "", message: "" })
                      }
                    />
                  </View>
                )}
              </>
            );
          }}
        />
      </Rule>

      {/*Weekdays*/}
      <WeekdaysRuleContainer weekDay={values.weekDay} />

      {/*Time period*/}
      <Rule
        title={
          <>
            <svg xmlns="http://www.w3.org/2000/svg" width="19px" height="19px">
              <path d="M9.500,19.000 C4.253,19.000 0.000,14.747 0.000,9.500 C0.000,4.253 4.253,-0.000 9.500,-0.000 C14.747,-0.000 19.000,4.253 19.000,9.500 C19.000,14.747 14.747,19.000 9.500,19.000 ZM9.500,1.000 C4.806,1.000 1.000,4.806 1.000,9.500 C1.000,14.195 4.806,18.000 9.500,18.000 C14.194,18.000 18.000,14.195 18.000,9.500 C18.000,4.806 14.194,1.000 9.500,1.000 ZM12.179,13.855 L9.241,10.918 C9.108,10.835 9.016,10.696 9.006,10.529 C8.997,10.479 8.993,10.438 9.000,10.388 L9.000,4.500 C9.000,4.224 9.224,4.000 9.500,4.000 C9.776,4.000 10.000,4.224 10.000,4.500 L10.000,10.321 L12.856,13.177 C13.043,13.364 13.043,13.668 12.856,13.855 C12.669,14.042 12.366,14.042 12.179,13.855 Z" />
            </svg>
            TIME PERIOD
          </>
        }
      >
        {timePeriodEnabled && (
          <Conditions>
            <Condition onDelete={() => toggleTimePeriod(false)}>
              <ConditionField isTimeZone>
                <Field
                  name={`timePeriod.timezone`}
                  component={SelectField}
                  placeholder="Select a timezone"
                  items={timezones}
                />
              </ConditionField>
              {values.timePeriod.timezone && (
                <>
                  <ConditionField>
                    <Field
                      name={`timePeriod.from`}
                      component={SelectField}
                      placeholder="from"
                      items={getTimeSeries()}
                    />
                  </ConditionField>
                  <ConditionField>
                    <Field
                      name={`timePeriod.to`}
                      component={SelectField}
                      placeholder="to"
                      items={getTimeSeries()}
                    />
                  </ConditionField>
                </>
              )}
            </Condition>
          </Conditions>
        )}
        {!timePeriodEnabled && (
          <View display="inline-flex">
            <AddConditionButton
              title="Add condition"
              onClick={() => toggleTimePeriod(true)}
            />
          </View>
        )}
      </Rule>
    </form>
  );
}

export default RulesContainer;
