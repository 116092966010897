import React from "react";
import styles from "./index.module.css";
import { Avatar } from "design-system-react";
import { extractFirstLetters } from "utils/string";

function NavPanelEndUser({ avatar, channel, name, color, aid }) {
  return (
    <div className={styles.user}>
      <Avatar
        size="size40"
        color={color}
        text={extractFirstLetters(name, 2)}
        image={avatar}
        channel={channel}
      />
      <div className={styles.name}>{name || `User #${aid}`}</div>
    </div>
  );
}

export default NavPanelEndUser;
