import React, { useState, useCallback, useEffect } from "react";
import moment from "moment";
import history from "../../../utils/history";
import {
  Row,
  Col,
  Container,
  Button,
  GutterField,
  Dropdown,
  DropdownToggleIcon,
  DropdownMenu,
  DropdownItem,
  Scrollbar
} from "design-system-react";
import { Field, Formik } from "formik";
import InputField from "../../../components/InputField";
import TextareaField from "../../../components/TextareaField";
import { api } from "../../../utils/client";
import NavPanel from "components/NavPanel";
import NavPanelContent from "components/NavPanel/NavPanelContent";
import NavPanelActionItem from "components/NavPanel/NavPanelActionItem";
import RulesContainer from "./RulesContainer";
import NavPanelIconButton from "../../../components/NavPanelIconButton";
import View from "../../../components/View";
import SectionTitle from "../components/SectionTitle";
import Header from "../components/Header";
import CenteredPreloader from "../../../components/CenteredPreloader";
import { connect } from "react-redux";
import { DateTime } from "../../../components/DateTime";
import { TYPE_FULL_ABSOLUTELY } from "../../../utils/dateTime";
import {
  MODAL_DEFAULT,
  MODAL_ERROR,
  MODAL_PROCESS,
  MODAL_SUCCESS,
  showConfirmModal,
  updateConfirmModal
} from "../../../utils/modalManager";
import { CommonError } from "../../../utils/commonError";
import { FormError } from "../../../utils/formError";
import { ALERT_SUCCESS, showAlert } from "../../../utils/alertManager";

function SettingsAutoResponsesContainer({ autoResponseId, autoResponse }) {
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [initialValues, setInitialValues] = useState({
    qualification: [],
    channel: [],
    language: [],
    message: [],
    weekDay: [],
    timePeriod: {
      timezone: moment.tz.guess() || "",
      from: "",
      to: ""
    }
  });

  const toggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleSubmit = useCallback(
    (values, { setSubmitting, setErrors, resetForm }) => {
      ["qualification", "channel", "language", "message", "weekDay"].map(
        rule => {
          values[rule] = values[rule].filter(item => item.operator);
          return rule;
        }
      );

      if (autoResponseId) {
        api.autoResponses
          .update(autoResponseId, values)
          .ready.then(() => {
            showAlert(ALERT_SUCCESS, "Auto-response saved");
            resetForm(values);
          })
          .catch(e => {
            if (e instanceof FormError) {
              setErrors(e.errors);
            }
          })
          .finally(() => {
            setSubmitting(false);
          });
      } else {
        api.autoResponses
          .create(values)
          .ready.then(response => {
            history.replace(
              "/settings/auto-responses/" + response.data.data.id
            );
          })
          .catch(e => {
            if (e instanceof FormError) {
              setErrors(e.errors);
            }
          })
          .finally(() => {
            setSubmitting(false);
          });
      }
    },
    [autoResponseId]
  );

  const handleBack = useCallback(() => {
    history.push("/settings/auto-responses");
  }, []);

  const handleDelete = useCallback(() => {
    const modalId = showConfirmModal(
      MODAL_DEFAULT,
      "Are you sure you want to delete auto-response?",
      {
        actionButtonText: "Delete",
        onAction: () => {
          updateConfirmModal(modalId, MODAL_PROCESS, "Deleting...");

          api.autoResponses
            .delete(autoResponseId)
            .ready.then(() => {
              history.replace("/settings/auto-responses");

              updateConfirmModal(
                modalId,
                MODAL_SUCCESS,
                "Auto-response successfully deleted"
              );
            })
            .catch(e => {
              const errorMessage = "Ooops, something went wrong";

              updateConfirmModal(
                modalId,
                MODAL_ERROR,
                e instanceof CommonError ? e.error : errorMessage
              );
            });
        }
      }
    );
  }, [autoResponseId]);

  useEffect(() => {
    if (autoResponseId) {
      setLoading(true);

      api.autoResponses.one(autoResponseId).ready.then(response => {
        setInitialValues({
          title: response.data.data.title,
          text: response.data.data.text,
          qualification: response.data.data.criteria.qualification,
          channel: response.data.data.criteria.channel,
          language: response.data.data.criteria.language,
          message: response.data.data.criteria.message,
          weekDay: response.data.data.criteria.weekDay,
          timePeriod: response.data.data.criteria.timePeriod || null
        });

        setLoading(false);
      });
    }
  }, [autoResponseId]);

  return (
    <Row fullHeight column>
      <Container>
        <NavPanel>
          <NavPanelContent>
            <NavPanelIconButton left onClick={handleBack}>
              <svg xmlns="http://www.w3.org/2000/svg" width="9px" height="15px">
                <path
                  fillRule="evenodd"
                  d="M7.154,0.136 L0.176,7.103 C0.164,7.113 0.147,7.109 0.137,7.119 C0.036,7.219 -0.011,7.352 -0.007,7.484 C-0.011,7.617 0.036,7.749 0.137,7.849 C0.147,7.861 0.164,7.857 0.176,7.865 L7.154,14.834 C7.351,15.028 7.668,15.028 7.864,14.834 C8.061,14.638 8.061,14.322 7.864,14.126 L1.211,7.484 L7.864,0.842 C8.061,0.647 8.061,0.331 7.864,0.136 C7.668,-0.059 7.351,-0.059 7.154,0.136 Z"
                />
              </svg>
            </NavPanelIconButton>
          </NavPanelContent>
          {autoResponseId && (
            <View position="relative" right="-24px">
              <NavPanelActionItem>
                <Dropdown isOpen={isOpen} toggle={toggle}>
                  <DropdownToggleIcon>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="2"
                      height="14"
                      viewBox="0 0 2 14"
                    >
                      <path
                        fill={isOpen ? "#000" : "#7d7e80"}
                        d="M2471,497a1,1,0,1,1-1,1A1,1,0,0,1,2471,497Zm0-12a1,1,0,1,1-1,1A1,1,0,0,1,2471,485Zm0,6a1,1,0,1,1-1,1A1,1,0,0,1,2471,491Z"
                        transform="translate(-2470 -485)"
                      />
                    </svg>
                  </DropdownToggleIcon>
                  <DropdownMenu direction="left" gutterRight={-10}>
                    <DropdownItem onClick={handleDelete}>Delete</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </NavPanelActionItem>
            </View>
          )}
        </NavPanel>
      </Container>
      <Col justified>
        {loading ? (
          <CenteredPreloader />
        ) : (
          <Scrollbar>
            <Container gutterBottom={120}>
              {autoResponse && (
                <Header
                  title={autoResponse.title}
                  desc={
                    <>
                      {autoResponse.createdAt !== autoResponse.updatedAt
                        ? "Updated"
                        : "Created"}{" "}
                      <DateTime
                        forceType={TYPE_FULL_ABSOLUTELY}
                        time={autoResponse.createdAt}
                      />
                    </>
                  }
                />
              )}
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                render={props => (
                  <div>
                    <Row>
                      <Col md={6} column>
                        <SectionTitle title="Name your auto response" />
                        <Field
                          label="Title"
                          name="title"
                          component={InputField}
                        />
                      </Col>
                    </Row>

                    <SectionTitle
                      title="Set up rules"
                      desc="Rules are used to automatically perform
                      auto-responses
                      when certain conditions are met."
                    />

                    <RulesContainer
                      submitDisabled={submitDisabled}
                      setSubmitDisabled={setSubmitDisabled}
                      {...props}
                    />

                    <SectionTitle title="Auto-response message" />

                    <Row>
                      <Col md={6}>
                        <GutterField>
                          <Field
                            label="Text message"
                            name="text"
                            component={TextareaField}
                          />
                          <View color="#969799" marginTop="10px">
                            You can add {"{NAME}"} attribute to the message for
                            your leads and customers*
                          </View>
                        </GutterField>
                      </Col>
                    </Row>
                    <Button
                      onClick={props.handleSubmit}
                      processing={props.isSubmitting}
                      disabled={submitDisabled}
                    >
                      {autoResponseId ? "Save" : "Create"}
                    </Button>
                  </div>
                )}
              />
            </Container>
          </Scrollbar>
        )}
      </Col>
    </Row>
  );
}

const mapStateToProps = (state, props) => {
  return {
    autoResponse:
      state.entities.autoResponses &&
      state.entities.autoResponses[props.autoResponseId]
  };
};

export default connect(mapStateToProps)(SettingsAutoResponsesContainer);
