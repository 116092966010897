import React from "react";
import PropTypes from "prop-types";
import styles from "./CollapseButton.module.css";
import classNames from "classnames";

function CollapseButton({ handleClick, isOpen }) {
  const classes = classNames(styles.stripes, isOpen && styles.rotate);
  return (
    <div className={styles.burger} onClick={handleClick}>
      <div className={classes}>
        <div className={styles.firstStripe} />
        <div className={styles.secondStripe} />
        <div className={styles.thirdStripe} />
      </div>
    </div>
  );
}

CollapseButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool
};

CollapseButton.defaultTypes = {
  isOpen: true
};

export default CollapseButton;
