import React from "react";
import { connect } from "react-redux";
import { SubMenuListItem } from "design-system-react";
import { Link } from "react-router-dom";

function TeamMembersSubMenuItem({
  viewId,
  viewName,
  viewTitle,
  viewCount,
  counter,
  link,
  activeMenuItem
}) {
  const active = activeMenuItem === link + viewName;
  const to = {
    pathname: link + viewName,
    state: {
      fromSubMenu: true
    }
  };

  return (
    <SubMenuListItem
      key={viewId}
      active={active}
      tag={Link}
      to={to}
      text={viewTitle}
      counterAll={viewCount}
    />
  );
}

const mapStateToProps = (state, props) => {
  const view = state.entities.views[props.viewId];

  return {
    viewName: view.name,
    viewTitle: view.title,
    viewCount: view.count
  };
};

export default connect(mapStateToProps)(TeamMembersSubMenuItem);
