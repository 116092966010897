import React from "react";
import PropTypes from "prop-types";
import styles from "./SettingsLayout.module.css";

function SettingsLayout({ renderSubmenu, renderContent }) {
  return (
    <>
      {renderSubmenu()}
      <div className={styles.layout}>{renderContent()}</div>
    </>
  );
}

SettingsLayout.propTypes = {
  renderSubmenu: PropTypes.func.isRequired,
  renderContent: PropTypes.func.isRequired
};

export default SettingsLayout;
