import { connect } from "react-redux";
import { extractFirstLetters } from "utils/string";
import EntityAvatar from "components/entityAvatar/EntityAvatar";

const mapStateToProps = (state, props) => {
  const teamMember = state.entities.teamMembers[props.teamMemberId];

  return {
    avatarSrc: teamMember && teamMember.avatar,
    avatarTitle: teamMember && extractFirstLetters(teamMember.name, 1),
    avatarBackground: teamMember && teamMember.nameColor,
    title: !props.hideName && teamMember && teamMember.name
  };
};

export default connect(mapStateToProps)(EntityAvatar);
