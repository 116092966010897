import React from "react";
import { connect } from "react-redux";
import {
  SubMenu,
  SubMenuHeader,
  SubMenuContent,
  SubMenuList
} from "design-system-react";
import SubMenuItemContainer from "./SubMenuItemContainer";

function SubMenuContainer({ viewIds, activeViewId, activeCaseId }) {
  return (
    <SubMenu>
      <SubMenuHeader>Cases</SubMenuHeader>
      <SubMenuContent>
        <SubMenuList>
          {viewIds.map(id => (
            <SubMenuItemContainer
              key={id}
              viewId={id}
              active={id === activeViewId}
              activeCaseId={activeCaseId}
            />
          ))}
        </SubMenuList>
      </SubMenuContent>
    </SubMenu>
  );
}

const mapStateToProps = (state, props) => {
  const activeViewId = state.caseViews.ids.filter(id => {
    const view = state.entities.views[id];
    return view && view.name === props.activeViewName;
  })[0];

  return {
    viewIds: state.caseViews.ids,
    activeViewId: activeViewId
  };
};

export default connect(mapStateToProps)(SubMenuContainer);
