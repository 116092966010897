import React, { useEffect, useCallback } from "react";
import EndUsersSubMenuContainer from "pages/EndUsers/containers/EndUsersSubMenuContainer";
import ToggableLayout from "components/ToggableLayout";
import EndUserContainer from "./containers/EndUserContainer";

function EndUser({ match }) {
  useEffect(() => {
    document.title = "Contacts";
  }, []);

  const endUserId = match.params.id;
  const activeCaseId = match.params.case;

  const renderSubmenu = useCallback(() => <EndUsersSubMenuContainer />, []);
  const renderContent = useCallback(
    () => (
      <EndUserContainer endUserId={endUserId} activeCaseId={activeCaseId} />
    ),
    [endUserId, activeCaseId]
  );

  return (
    <ToggableLayout
      renderSubmenu={renderSubmenu}
      renderContent={renderContent}
    />
  );
}

export default EndUser;
