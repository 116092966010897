import {
  CHANNEL_TYPE_FACEBOOK,
  CHANNEL_TYPE_BLINGER,
  CHANNEL_TYPE_EMAIL,
  CHANNEL_TYPE_VIBER,
  CHANNEL_TYPE_WHATSAPP,
  CHANNEL_TYPE_TELEGRAMBOT,
  CHANNEL_TYPE_VK, CHANNEL_TYPE_TYNTEC_WHATSAPP
} from "./channels";

export function channelIcon(channel) {
  switch (channel) {
    case CHANNEL_TYPE_TELEGRAMBOT:
      return "/images/telegram.svg";
    case CHANNEL_TYPE_VK:
      return "/images/vk.svg";
    default:
      return "";
  }
}

export function channelSmIcon(channel) {
  switch (channel) {
    case CHANNEL_TYPE_FACEBOOK:
      return "/images/messengers/facebook_20.svg";
    case CHANNEL_TYPE_BLINGER:
      return "/images/messengers/blinger-messenger_20.svg";
    case CHANNEL_TYPE_EMAIL:
      return "/images/messengers/email_20.svg";
    case CHANNEL_TYPE_VIBER:
      return "/images/messengers/viber_20.svg";
    case CHANNEL_TYPE_WHATSAPP:
    case CHANNEL_TYPE_TYNTEC_WHATSAPP:
      return "/images/messengers/whatsapp_20.svg";
    case CHANNEL_TYPE_TELEGRAMBOT:
      return "/images/messengers/telegram_20.svg";
    case CHANNEL_TYPE_VK:
      return "/images/messengers/vk_20.svg";
    default:
      return "";
  }
}
