import React from "react";
import styles from "./CardLinkContainer.module.css";

function CardLinkContainer({ children, tag: Tag, ...attributes }) {
  return (
    <div className={styles.cardLinkContainer}>
      <Tag {...attributes}>{children}</Tag>
    </div>
  );
}

export default CardLinkContainer;
