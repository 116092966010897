import React from "react";
import styles from "./MessageListItemBubbleLocation.module.css";

function MessageListItemBubbleLocation({ lat, lon }) {
  return (
    <div className={styles.wrapper}>
      <img
        className={styles.icon}
        src="/images/files/Google_maps_40.svg"
        alt="Google maps"
      />
      <a
        href={`https://www.google.com/maps/?q=${lat},${lon}`}
        target="_blank"
        rel="noopener noreferrer"
        className={styles.link}
      >
        <span className={styles.content}>
          {`https://www.google.com/maps/?q=${lat},${lon}`}
        </span>
        <span className={styles.label}>maps.google.com</span>
      </a>
    </div>
  );
}

export default MessageListItemBubbleLocation;
