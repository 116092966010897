import React, {
  useEffect,
  useLayoutEffect,
  useState,
  useCallback,
  useMemo,
  useRef
} from "react";
import { connect } from "react-redux";
import MessageList from "pages/Cases/components/MessageList";
import MessageListItemContainer from "pages/Cases/containers/MessageListItemContainer";
import { Col, Preloader } from "design-system-react";
import usePrevious from "hooks/usePrevious";
import { setMessageIds, addMessageIds } from "../../../modules/pages/newcases";
import { api } from "../../../utils/client";
import InfinitePagination from "../../../components/infinitePagination/InfinitePagination";
import usePagination from "../../../hooks/usePagination";
import MessageListScrollButtonContainer from "./MessageListScrollButtonContainer";

let prevScrollHeight = null;

function MessageListContainer({
  caseId,
  activeMessageId,
  setMessageIds,
  addMessageIds,
  messageIds,
  outgoingMessages,
  currentUser,
  channelType
}) {
  const [scrollAfterPagination, setScrollAfterPagination] = useState(false);
  const scrollbar = useRef(null);

  const prevMessageLength = usePrevious(messageIds.length);
  const prevOutgoingMessageLength = usePrevious(
    outgoingMessages ? outgoingMessages.length : 0
  );

  const scrollToBottom = useCallback(() => {
    if (scrollbar && scrollbar.current) {
      scrollbar.current.scrollTop = scrollbar.current.scrollHeight;
    }
  }, [scrollbar]);

  const handleMessagesFetch = useCallback(
    (request, cursor) => {
      request.ready.then(response => {
        if (cursor) {
          setScrollAfterPagination(true);
        }

        addMessageIds(response.data.data.map(message => message.id), false);
      });
    },
    [addMessageIds]
  );

  const messagesFetchParams = useMemo(() => {
    return {
      caseId
    };
  }, [caseId]);

  const messagesPagination = usePagination(
    api.cases.messages,
    messagesFetchParams,
    30,
    handleMessagesFetch
  );

  if (messageIds.length > prevMessageLength && scrollAfterPagination) {
    if (scrollbar.current && scrollbar.current) {
      prevScrollHeight = scrollbar.current.scrollHeight;
    }
  }

  useEffect(() => {
    if (caseId) {
      setMessageIds([]);

      const request = messagesPagination.load(null);

      return () => {
        request.cancelRequest.cancel();
      };
    }

    // eslint-disable-next-line
  }, [caseId, setMessageIds]);

  useEffect(() => {
    if (
      messageIds.length > prevMessageLength &&
      scrollAfterPagination &&
      prevScrollHeight > 0
    ) {
      scrollbar.current.scrollTop +=
        scrollbar.current.scrollHeight - prevScrollHeight;

      setScrollAfterPagination(false);
      prevScrollHeight = null;
    }

    // eslint-disable-next-line
  }, [messageIds.length, prevScrollHeight]);

  useLayoutEffect(() => {
    if (prevMessageLength === 0 && messageIds.length > 0) {
      scrollToBottom();
    }

    if (messageIds.length > prevMessageLength && !scrollAfterPagination) {
      if (
        scrollbar.current.scrollHeight - scrollbar.current.offsetHeight <
        scrollbar.current.scrollTop + 600
      ) {
        scrollToBottom();
      }
    }

    if (
      prevOutgoingMessageLength <
      (outgoingMessages ? outgoingMessages.length : 0)
    ) {
      scrollToBottom();
    }

    // eslint-disable-next-line
  }, [messageIds.length, outgoingMessages ? outgoingMessages.length : 0]);

  if (messageIds.length === 0 && messagesPagination.nextLoading) {
    return (
      <Col align="center" verticalAlign="center" justified>
        <Preloader size="32" color="#807d7f" />
      </Col>
    );
  }

  const allMessages = [...messageIds, ...(outgoingMessages || [])];

  return (
    <>
      <InfinitePagination
        scrollbarRef={scrollbar}
        pagination={messagesPagination}
        topScrolling
      >
        {messagesPagination.nextLoading && (
          <Col align="center" gutterTop={30} gutterBottom={30}>
            <Preloader size="32" color="#807d7f" />
          </Col>
        )}

        <MessageList noPadding={channelType === "email"}>
          {allMessages.map((message, index) => {
            return (
              <MessageListItemContainer
                key={
                  typeof message === "object"
                    ? message.uuid
                    : "outgoing-" + message
                }
                messageId={message}
                highlight={activeMessageId === message}
                prevMessage={index > 0 && allMessages[index - 1]}
                nextMessage={
                  index < allMessages.length - 1 && allMessages[index + 1]
                }
                channelType={channelType}
                caseId={caseId}
              />
            );
          })}
        </MessageList>
      </InfinitePagination>

      {channelType !== "email" && (
        <MessageListScrollButtonContainer
          scrollbar={scrollbar}
          scrollToBottom={scrollToBottom}
        />
      )}
    </>
  );
}

const mapStateToProps = (state, props) => {
  return {
    currentUser: state.entities.teamMembers[state.auth.userId],
    messageIds: state.pages.cases.messageIds,
    outgoingMessages: state.pages.cases.outgoingMessagesByCase[props.caseId]
  };
};

const mapDispatchToProps = dispatch => ({
  setMessageIds: ids => dispatch(setMessageIds(ids)),
  addMessageIds: ids => dispatch(addMessageIds(ids))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MessageListContainer);
