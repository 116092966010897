import React from "react";
import WidgetHeaderTitle from "components/Widget/WidgetHeaderTitle";
import WidgetHeaderDescription from "components/Widget/WidgetHeaderDescription";
import WidgetHeader from "components/Widget/WidgetHeader";
import WidgetIntroduceContainer from "./containers/WidgetIntroduceContainer";

function WidgetIntroduce() {
  return (
    <>
      <WidgetHeader>
        <WidgetHeaderTitle>Introduce your Company and Team</WidgetHeaderTitle>
        <WidgetHeaderDescription>
          Start with adding a company logo and represent your teammates
        </WidgetHeaderDescription>
      </WidgetHeader>
      <WidgetIntroduceContainer />
    </>
  );
}

export default WidgetIntroduce;
