import React from "react";
import { extractFirstLetters } from "utils/string";
import { Avatar } from "design-system-react";
import PropTypes from "prop-types";
import styles from "./TeamUserCard.module.css";
import DetailsButtonWrapper from "../../../components/DetailsButtonWrapper/DetailsButtonWrapper";
import ProfileButton from "../../../components/ProfileButton";
import CardProfileButtonContainer from "../../../components/CardProfileButtonContainer";
import TeamMemberActiveSwitcher from "components/TeamMemberActiveSwitcher";

function TeamUserCard({
  teamMember,
  role,
  isCurrentUser,
  availability,
  showActiveSwitcher,
  toggleDetailsSidePanel
}) {
  return (
    <div className={styles.header}>
      <div className={styles.card}>
        <Avatar
          size="size100"
          text={extractFirstLetters(teamMember.name, 2)}
          color={teamMember.nameColor}
          image={teamMember.avatar}
        />
        <div className={styles.desc}>
          <span className={styles.name}>{teamMember.name}</span>
          <span className={styles.role}>{role}</span>
        </div>
      </div>
      <div className={styles.extra}>
        {showActiveSwitcher && (
          <TeamMemberActiveSwitcher
            teamMember={teamMember}
            checked={availability === "active"}
            label="Switch activity status"
            labelPlacement="left"
            isCurrentTeamMember={isCurrentUser}
          />
        )}

        <DetailsButtonWrapper>
          <ProfileButton profileButtonAction={toggleDetailsSidePanel} />
        </DetailsButtonWrapper>
        <CardProfileButtonContainer />
      </div>
    </div>
  );
}

TeamUserCard.propTypes = {
  avatar: PropTypes.string,
  name: PropTypes.string,
  color: PropTypes.string,
  role: PropTypes.string
};

export default React.memo(TeamUserCard);
