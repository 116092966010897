import React, { useState, useEffect, useCallback } from "react";
import {
  Table,
  TableHeader,
  TableHeaderColumn,
  TableHeaderColumnMenu,
  TableBody,
  Col,
  Preloader,
  Container,
  Scrollbar
} from "design-system-react";
import {
  MODAL_DEFAULT,
  MODAL_ERROR,
  MODAL_PROCESS,
  MODAL_SUCCESS,
  showConfirmModal,
  updateConfirmModal
} from "utils/modalManager";
import { api } from "utils/client";
import { CommonError } from "utils/commonError";
import {
  setIntegrationSlackIds,
  removeIntegrationSlackId
} from "modules/pages/settingsSlack";
import { connect } from "react-redux";
import EmptyState from "components/EmptyState";
import SettingsChannelListItem from "components/SettingsChannelListItem";
import axios from "axios";

function SlackListContainer({
  integrationIds,
  setIntegrationSlackIds,
  removeIntegrationSlackId,
  locationKey
}) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    setIntegrationSlackIds([]);

    const request = api.slack.all();
    request.ready
      .then(response => {
        setIntegrationSlackIds(response.data.data.map(member => member.id));
        setLoading(false);
      })
      .catch(error => {
        if (!axios.isCancel(error)) {
          setLoading(false);
        }
      });

    return () => {
      request.cancelRequest.cancel();
    };
  }, [locationKey, setIntegrationSlackIds]);

  const handleActionDropdown = useCallback(
    (channelId, name, action) => {
      switch (action) {
        case "delete":
          const modalId = showConfirmModal(
            MODAL_DEFAULT,
              "Are you sure you want to delete integration?",
            {
              actionButtonText: "Delete",
              onAction: () => {
                updateConfirmModal(modalId, MODAL_PROCESS, "Deleting...");

                api.slack
                  .delete(channelId)
                  .ready.then(() => {
                    updateConfirmModal(
                      modalId,
                      MODAL_SUCCESS,
                      "Channel was successfully deleted"
                    );
                    removeIntegrationSlackId(channelId);
                  })
                  .catch(e => {
                    const errorMessage = "Ooops, something went wrong";

                    updateConfirmModal(
                      modalId,
                      MODAL_ERROR,
                      e instanceof CommonError ? e.error : errorMessage
                    );
                  });
              }
            }
          );
          break;

        default:
          break;
      }
    },
    [removeIntegrationSlackId]
  );

  if (loading && !integrationIds.length) {
    return (
      <Col align="center" justified>
        <Preloader size="32" color="#807d7f" />
      </Col>
    );
  }

  if (!integrationIds.length) {
    return (
      <Col align="center" verticalAlign="center" justified>
        <EmptyState title="No integrations yet">
          You must add an integration to start <br />
          receiving messages
        </EmptyState>
      </Col>
    );
  }

  return (
    <Scrollbar>
      <Container>
        <Table headerBorder>
          <TableHeader fixed>
            <TableHeaderColumn justified fixed>
              Name
            </TableHeaderColumn>
            <TableHeaderColumn>Added</TableHeaderColumn>
            <TableHeaderColumnMenu />
          </TableHeader>
          <TableBody>
            {integrationIds.map(id => (
              <SettingsChannelListItem
                key={id}
                channelId={id}
                handleActionDropdown={handleActionDropdown}
              />
            ))}
          </TableBody>
        </Table>
      </Container>
    </Scrollbar>
  );
}

const mapStateToProps = state => {
  return { integrationIds: state.pages.settingsSlack.ids || [] };
};

const mapDispatchToProps = dispatch => ({
  setIntegrationSlackIds: ids => dispatch(setIntegrationSlackIds(ids)),
  removeIntegrationSlackId: ids => dispatch(removeIntegrationSlackId(ids))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SlackListContainer);
